import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { filter, firstValueFrom } from 'rxjs';
import { GlobalActions, GlobalSelectors, RootGlobalState } from './store';

export async function initializeGlobalState(
  store: Store<RootGlobalState>,
  logger: LogService,
): Promise<void> {
  store.dispatch(GlobalActions.startGlobalInitialization());
  await firstValueFrom(
    store.select(GlobalSelectors.selectIsGlobalInitializationComplete).pipe(filter(Boolean)),
  );
  logger.info()('Global Initialization Complete');
}
