import { LanguageCodeEnum } from '@remberg/global/common/core';

export interface RembergUsersPrefillingQueryParams {
  firstName?: string;
  lastName?: string;
  email?: string;
  jobPosition?: string;
  lang?: LanguageCodeEnum;
}

export const REMBERG_USER_QUERY_PARAM = 'rembergUser' as const;
