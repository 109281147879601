export enum PartListInputDeliveryToEnum {
  NONE = 'none',
  CUSTOMER = 'customer',
  TECHNICIAN = 'technician',
}

export enum PartListInputInvoiceToEnum {
  NONE = 'none',
  CUSTOMER = 'customer',
  TECHNICIAN = 'technician',
}
