import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RembergTimestamp } from '@remberg/global/common/core';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
} from '@remberg/global/ui';
import { WorkOrderBasic } from '@remberg/work-orders/common/base';
import {
  WORK_ORDER_FIND_UPDATED_AT_HEADERS,
  WorkOrder,
  WorkOrderChecklistItem,
  WorkOrderChecklistItemCreateOneBody,
  WorkOrderChecklistItemUpdateOneBody,
  WorkOrderCreateOneBody,
  WorkOrderCreateOneResponse,
  WorkOrderFindManyBasicByIdsBody,
  WorkOrderFindManyHierarchyResponse,
  WorkOrderFindManyQuery,
  WorkOrderFindManyResponse,
  WorkOrderUpdateOneBody,
} from '@remberg/work-orders/common/main';
import { Observable, from, map } from 'rxjs';
import {
  WORK_ORDER_2_OFFLINE_SERVICE,
  WorkOrder2OfflineServiceInterface,
} from './work-order.offline.service.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {
  private readonly WORK_ORDER_URL = `${API_URL_PLACEHOLDER}/workorders/v2`;

  constructor(
    private readonly http: HttpClient,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(WORK_ORDER_2_OFFLINE_SERVICE)
    private readonly workOrder2OfflineService: WorkOrder2OfflineServiceInterface,
  ) {}

  public findUpdatedAt(id: string): Observable<RembergTimestamp | undefined> {
    return this.http
      .head(`${this.WORK_ORDER_URL}/${id}`, { observe: 'response' })
      .pipe(map((r) => r.headers.get(WORK_ORDER_FIND_UPDATED_AT_HEADERS.updatedAt) ?? undefined));
  }

  public findOne(id: string): Observable<WorkOrder> {
    if (!this.connectivityService.getConnected()) {
      return from(this.workOrder2OfflineService.findOne(id));
    }

    return this.http.get<WorkOrder>(`${this.WORK_ORDER_URL}/${id}`);
  }

  public findMany(options?: WorkOrderFindManyQuery): Observable<WorkOrderFindManyResponse> {
    if (!this.connectivityService.getConnected()) {
      return from(this.workOrder2OfflineService.findMany(options));
    }

    let params = new HttpParams();

    if (options?.page !== undefined) {
      params = params.set('page', String(options.page));
    }
    if (options?.limit !== undefined) {
      params = params.set('limit', String(options.limit));
    }
    if (options?.sortDirection !== undefined) {
      params = params.set('sortDirection', options.sortDirection);
    }
    if (options?.sortField !== undefined) {
      params = params.set('sortField', options.sortField);
    }
    if (options?.search !== undefined) {
      params = params.set('search', options.search);
    }
    if (options?.filterObject !== undefined) {
      params = params.set('filterObject', JSON.stringify(options.filterObject));
    }
    if (options?.staticFilters?.length) {
      params = params.set('staticFilters', JSON.stringify(options.staticFilters));
    }
    for (const p of options?.populate || []) {
      params = params.append('populate', p);
    }

    return this.http.get<WorkOrderFindManyResponse>(this.WORK_ORDER_URL, {
      params,
    });
  }

  public findManyBasicByIds(body: WorkOrderFindManyBasicByIdsBody): Observable<WorkOrderBasic[]> {
    if (!this.connectivityService.getConnected()) {
      return this.workOrder2OfflineService.findManyBasicByIds(body);
    }

    return this.http.put<WorkOrderBasic[]>(`${this.WORK_ORDER_URL}`, body);
  }

  public findManyHierarchy(
    options?: WorkOrderFindManyQuery,
  ): Observable<WorkOrderFindManyHierarchyResponse> {
    if (!this.connectivityService.getConnected()) {
      throw new Error('Offline mode not supported for: findManyHierarchy');
    }

    let params = new HttpParams();

    if (options?.page !== undefined) {
      params = params.set('page', String(options.page));
    }
    if (options?.limit !== undefined) {
      params = params.set('limit', String(options.limit));
    }
    if (options?.sortDirection !== undefined) {
      params = params.set('sortDirection', options.sortDirection);
    }
    if (options?.sortField !== undefined) {
      params = params.set('sortField', options.sortField);
    }
    if (options?.search !== undefined) {
      params = params.set('search', options.search);
    }
    if (options?.filterObject?.filters?.length) {
      params = params.set('filterObject', JSON.stringify(options.filterObject));
    }
    if (options?.staticFilters?.length) {
      params = params.set('staticFilters', JSON.stringify(options.staticFilters));
    }
    for (const p of options?.populate || []) {
      params = params.append('populate', p);
    }

    return this.http.get<WorkOrderFindManyHierarchyResponse>(`${this.WORK_ORDER_URL}/hierarchy`, {
      params,
    });
  }

  public deleteOne(id: string): Observable<void> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: deleteOne id ${id}`);
    }

    return this.http.delete<void>(`${this.WORK_ORDER_URL}/${id}`);
  }

  public create(body: WorkOrderCreateOneBody): Observable<WorkOrderCreateOneResponse> {
    if (!this.connectivityService.getConnected()) {
      throw new Error('Offline mode not supported for: create');
    }

    return this.http.post<WorkOrderCreateOneResponse>(this.WORK_ORDER_URL, body);
  }

  public update(id: string, body: WorkOrderUpdateOneBody): Observable<WorkOrder> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: update id ${id}`);
    }

    return this.http.patch<WorkOrder>(`${this.WORK_ORDER_URL}/${id}`, body);
  }

  public createCheckListItem(
    workOrderId: string,
    item: WorkOrderChecklistItemCreateOneBody,
  ): Observable<WorkOrderChecklistItem> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(
        `Offline mode not supported for: createCheckListItem workOrderId ${workOrderId}`,
      );
    }

    return this.http.post<WorkOrderChecklistItem>(
      `${this.WORK_ORDER_URL}/${workOrderId}/checklist`,
      item,
    );
  }

  public updateCheckListItem(
    workOrderId: string,
    checklistItemId: string,
    item: WorkOrderChecklistItemUpdateOneBody,
  ): Observable<void> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(
        `Offline mode not supported for: updateCheckListItem workOrderId ${workOrderId} checklistItemId ${checklistItemId}`,
      );
    }

    return this.http.patch<void>(
      `${this.WORK_ORDER_URL}/${workOrderId}/checklist/${checklistItemId}`,
      item,
    );
  }

  public removeCheckListItem(workOrderId: string, checklistItemId: string): Observable<void> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(
        `Offline mode not supported for: removeCheckListItem workOrderId ${workOrderId} checklistItemId ${checklistItemId}`,
      );
    }

    return this.http.delete<void>(
      `${this.WORK_ORDER_URL}/${workOrderId}/checklist/${checklistItemId}`,
    );
  }
}
