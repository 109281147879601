import { MainRoutes } from './main-routes';

export enum PublicRoutes {
  ASSETS = 'assets',
  REQUEST = 'request',
  TICKETS = 'servicecases',
}

export enum PublicAssetsSubRoutes {
  DETAIL = 'detail',
}

export enum PublicTicketsSubRoutes {
  CONFIRMATION = 'confirmation',
  DETAIL = 'detail',
}

export enum PublicAssetsRoutes {
  DETAIL = `${MainRoutes.PUBLIC}/${PublicRoutes.ASSETS}/${PublicAssetsSubRoutes.DETAIL}`,
}

export enum PublicTicketsRoutes {
  REQUEST = `${MainRoutes.PUBLIC}/${PublicRoutes.REQUEST}`,
  REQUEST_CONFIRMATION = `${MainRoutes.PUBLIC}/${PublicRoutes.REQUEST}/${PublicTicketsSubRoutes.CONFIRMATION}`,
  DETAIL = `${MainRoutes.PUBLIC}/${PublicRoutes.TICKETS}/${PublicTicketsSubRoutes.DETAIL}`,
}
