<div fxLayout="column">
  <div>
    <ng-template #titleTemplate>
      <!-- Title -->
      <h2 class="text-title-center" i18n="@@youHaveUnsavedChanges">You have unsaved changes!</h2>
    </ng-template>

    <div class="header-wrapper">
      <!-- Icon -->
      <div>
        <mat-icon class="icon-primary">warning_amber</mat-icon>
      </div>
      <ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
    </div>

    <!-- Description -->
    <div>
      <p class="text-description-center" i18n="@@pressCancelToSaveOrOKToDiscardTheseChanges">
        Press 'Abort' to go back and save you changes first. Or press 'Discard Changes' to leave
        this page and loose the changes.
      </p>
    </div>
  </div>

  <div fxLayout="column wrap">
    <button mat-raised-button style="margin-top: 10px" (click)="abort()" i18n="@@abort">
      Abort
    </button>
    <button
      mat-raised-button
      style="margin-top: 10px"
      color="primary"
      (click)="confirm()"
      i18n="@@discardChanges"
    >
      Discard Changes
    </button>
  </div>
</div>
