import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { AssetCustomProperty } from '@remberg/assets/common/main';
import { CustomProperty, CustomPropertyModelEnum } from '@remberg/custom-properties/common/main';
import { CustomPropertyService as CustomPropertyService2 } from '@remberg/custom-properties/ui/clients';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
} from '@remberg/global/ui';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomPropertyService {
  public readonly customPropertiesUrl = `${API_URL_PLACEHOLDER}/products/customproperties`;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly customPropertyService2: CustomPropertyService2,
  ) {}

  public findAll(
    isAssetsTemporaryEnabled: boolean,
  ): Observable<AssetCustomProperty[] | CustomProperty[]> {
    if (isAssetsTemporaryEnabled) {
      return this.customPropertyService2.findAll(CustomPropertyModelEnum.ASSET);
    }
    return this.getCustomProperties();
  }

  // standard calls
  public getCustomPropertiesBase(
    tenantId?: string,
    populate?: boolean,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
  ): Observable<any> {
    let params = new HttpParams();
    if (populate !== undefined && populate) {
      params = params.set('populate', populate.toString());
    }
    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', encodeURIComponent(String(searchValue)));
    }
    if (filterValue) {
      params = params.set('filter', JSON.stringify(filterValue));
    }
    if (tenantId) {
      params = params.set('tenantId', String(tenantId));
    }
    return this.http.get<any>(this.customPropertiesUrl, { params: params });
  }

  public getCustomProperties(
    tenantId?: string,
    populate?: boolean,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
  ): Observable<AssetCustomProperty[]> {
    if (this.connectivityService.getConnected()) {
      return this.getCustomPropertiesBase(
        tenantId,
        populate,
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        searchValue,
        filterValue,
      ).pipe(map((res) => res.data));
    } else {
      this.logger.debug()('Offline asset custom properties are not supported...');
      return of([]);
    }
  }

  public updateCustomPropertiesSortOrder(
    tenantId: string,
    sortOrder: string[],
  ): Observable<string[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.customPropertiesUrl}/sort/${tenantId}`;
    return this.http
      .post<any>(url, { sortOrder: sortOrder }, httpOptions)
      .pipe(map((res) => res.data));
  }

  public getCustomProperty(
    customProperty: string,
    populate?: boolean,
  ): Observable<AssetCustomProperty> {
    const url = `${this.customPropertiesUrl}/${customProperty}`;
    let params = new HttpParams();
    if (populate !== undefined) {
      params = params.set('populate', populate.toString());
    }
    return this.http.get<any>(url, { params: params }).pipe(map((res) => res.data));
  }

  public addCustomProperty(customProperty: AssetCustomProperty): Observable<AssetCustomProperty> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post<any>(this.customPropertiesUrl, customProperty, httpOptions)
      .pipe(map((res) => res.data));
  }

  public updateCustomProperty(customProperty: AssetCustomProperty): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.customPropertiesUrl}/${customProperty._id}`;
    return this.http.put<any>(url, customProperty, httpOptions).pipe(map((res) => res.data));
  }

  public deleteCustomProperty(
    customProperty: AssetCustomProperty | string,
  ): Observable<AssetCustomProperty> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const id = typeof customProperty === 'string' ? customProperty : customProperty._id;
    const url = `${this.customPropertiesUrl}/${id}`;

    return this.http.delete<any>(url, httpOptions);
  }
}
