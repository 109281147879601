import { MatDialog } from '@angular/material/dialog';
import {
  filter,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  OperatorFunction,
  withLatestFrom,
} from 'rxjs';
import { getConfirmationCancelMoveAway } from '../helpers';

/**
 * Allow flow only if canDeactivate is true or dialog option "keepEditing" was not selected
 *
 * @param matDialog Reference to MatDialog service
 * @param canDeactivate$ observable deciding if dialog should be shown
 */
export function filterCannotDeactivateUnlessDiscarded<T>(
  matDialog: MatDialog,
  canDeactivate$: Observable<boolean>,
): OperatorFunction<T, T> {
  return (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      withLatestFrom(canDeactivate$),
      mergeMap(async ([payload, canDeactivate]): Promise<[T, boolean]> => {
        if (!canDeactivate) {
          const dialogRef = getConfirmationCancelMoveAway(matDialog);
          const result = await firstValueFrom(dialogRef.afterClosed());
          return [payload, !!result?.isConfirmed];
        }
        return [payload, false];
      }),
      filter(([, keepEditing]) => !keepEditing),
      map(([payload]) => payload),
    );
}
