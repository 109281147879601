export function getValue(obj: any, path: string[]): any {
  if (path.length > 1) {
    return getValue(obj[path[0]], path.slice(1));
  } else {
    return obj[path[0]];
  }
}

export function setValue(obj: any, path: string[], value: any): void {
  if (path.length > 1) {
    // set intermediary value if it doesnt exist
    if (!obj[path[0]]) {
      obj[path[0]] = {};
    }
    setValue(obj[path[0]], path.slice(1), value);
  } else {
    obj[path[0]] = value;
  }
}

export function deleteValue(obj: any, path: string[]): any {
  if (path.length > 1) {
    deleteValue(obj[path[0]], path.slice(1));
    // if obj[path[0]] has no more keys left, delete it
    if (Object.keys(obj[path[0]]).length === 0) {
      delete obj[path[0]];
    }
  } else {
    delete obj[path[0]];
  }
  return obj;
}
