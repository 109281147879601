<!-- ngClass not updating in selects -->
<mat-chip
  class="priority-indicator {{ 'priority-' + priorityClass }}"
  color="primary"
  disableRipple
  [removable]="false"
  [class.displayflex]="displayFlex && 'flex'"
  [class.large]="size === PriorityIndicatorSizeEnum.LARGE"
  [class.small]="size === PriorityIndicatorSizeEnum.SMALL"
  [class.listpadding]="asList"
>
  <div
    *ngIf="!icon && (colorIndicator || priorityClass)"
    matChipAvatar
    class="color-indicator"
    [style.backgroundColor]="colorIndicator"
  >
    &nbsp;
  </div>
  <mat-icon
    matChipAvatar
    *ngIf="icon"
    [style.color]="iconColor"
    [style.--mdc-chip-label-text-color]="textColor"
    >{{ icon }}</mat-icon
  >
  <div
    [style.color]="textColor"
    [style.--mdc-chip-label-text-color]="textColor"
    class="chip-text"
    [style.font-weight]="fontWeight"
    [attr.data-test-id]="dataTestId + '-priorityLabel'"
  >
    {{ label }}
  </div>
</mat-chip>
