import { isObjectId } from '@remberg/global/common/core';

export enum CaseCustomPropertyTypesEnum {
  INPUT_TEXT = 'INPUT_TEXT',
  TEXTAREA = 'TEXTAREA',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  URL = 'URL',
  REMBERG_ASSET_SELECT = 'REMBERG_ASSET_SELECT', // asset-select.component
  REMBERG_ORGANIZATION_SELECT = 'REMBERG_ORGANIZATION_SELECT', // organization-select.component
  REMBERG_CONTACT_SELECT = 'REMBERG_CONTACT_SELECT', // contact-select.component
  REMBERG_DATE = 'REMBERG_DATE', // date.component
  REMBERG_DATETIME = 'REMBERG_DATETIME', // datetime.component
}

export interface CaseCustomProperty {
  _id: string;
  tenantId: string;
  label: string;
  externalEdit?: boolean;
  public?: boolean;
  enabled?: boolean;
  propertyId: number;
  fieldType: CaseCustomPropertyTypesEnum;
  inputNumberDisplayPrecision?: number;
  options?: {
    options?: any[];
    multiple?: boolean;
  };
  sortIndex: number;
}

export interface CaseCustomPropertyValue {
  _id?: string;
  property: CaseCustomProperty;
  propertyId: number;
  value: any;
  public?: boolean;
}

export interface CaseCustomPropertyPayloadValue {
  _id?: string;
  property: string;
  propertyId: number;
  value: any;
  public?: boolean;
}

export type CaseCustomPropertyKey = `${'cProp-'}${CaseCustomPropertyTypesEnum}-${string}`;

export function isCaseCustomPropertyKey(propertyKey: string): propertyKey is CaseCustomPropertyKey {
  const cProp = propertyKey.split('-');
  return (
    cProp.length === 3 &&
    cProp[0] === 'cProp' &&
    isCaseCustomPropertyTypeEnum(cProp[1]) &&
    isObjectId(cProp[2])
  );
}

export function isCaseCustomPropertyTypeEnum(x?: string): x is CaseCustomPropertyTypesEnum {
  return Object.values(CaseCustomPropertyTypesEnum).includes(x as CaseCustomPropertyTypesEnum);
}
