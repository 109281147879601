import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  FormTemplateCreateBody,
  FormTemplateListQuery,
  FormTemplateListResponse,
  FormTemplateUpdateBody,
  FormTemplateUpdateHeaderFooterBody,
} from '@remberg/forms/common/dtos';
import { FormTemplate } from '@remberg/forms/common/main';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
} from '@remberg/global/ui';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FORM_TEMPLATE_OFFLINE_SERVICE, FormTemplateOfflineServiceInterface } from './definitions';
@Injectable({
  providedIn: 'root',
})
export class FormTemplateService {
  public readonly formsUrl = `${API_URL_PLACEHOLDER}/forms/v2/types`;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(FORM_TEMPLATE_OFFLINE_SERVICE)
    private readonly formTemplateOfflineService: FormTemplateOfflineServiceInterface,
  ) {}

  public getFormTemplatesByTenant({
    tenantId,
    populateInProgressCount,
    populateFormTemplateVersion,
    limit,
    page,
    searchQuery,
    searchLocale,
  }: FormTemplateListQuery): Observable<FormTemplateListResponse> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online form templates by account request...');

      let params = new HttpParams();

      if (tenantId) {
        params = params.set('tenantId', tenantId);
      }
      if (limit) {
        params = params.set('limit', String(limit));
      }
      if (page) {
        params = params.set('page', String(page));
      }
      if (searchQuery && searchLocale) {
        params = params.set('searchQuery', searchQuery);
        params = params.set('searchLocale', searchLocale);
      }

      // HttpParams is immutable: https://angular.io/api/common/http/HttpParams so passing by reference will not work
      params = this.addPopulateParams(
        undefined,
        populateFormTemplateVersion,
        populateInProgressCount,
        params,
      );

      return this.http.get<FormTemplateListResponse>(this.formsUrl, { params: params });
    } else {
      this.logger.debug()('Offline form templates by account fallback...');

      if (!tenantId) {
        return of({ data: [], count: 0 });
      }

      return from(
        this.formTemplateOfflineService.getManyPopulatedWithCount(
          limit,
          page,
          searchQuery,
          searchLocale,
          populateInProgressCount,
          populateFormTemplateVersion,
        ),
      );
    }
  }

  public getFormTemplate(
    formTemplateId: string,
    populateFormTemplateConfig: boolean,
    populateFormTemplateVersion: boolean,
    populateInProgressCount?: boolean,
  ): Observable<FormTemplate> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online form template request...');
      const url = `${this.formsUrl}/${formTemplateId}`;
      let params = new HttpParams();

      // HttpParams is immutable: https://angular.io/api/common/http/HttpParams so passing by reference will not work
      params = this.addPopulateParams(
        populateFormTemplateConfig,
        populateFormTemplateVersion,
        populateInProgressCount,
        params,
      );

      return this.http.get<FormTemplate>(url, { params: params });
    } else {
      this.logger.debug()('Offline form template fallback...');

      return from(
        this.formTemplateOfflineService.getInstancePopulated(
          formTemplateId,
          populateFormTemplateConfig,
          populateFormTemplateVersion,
          populateInProgressCount,
        ),
      );
    }
  }

  public addFormTemplate(formTemplate: FormTemplateCreateBody): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post<{ _id: string }>(this.formsUrl, formTemplate, httpOptions)
      .pipe(map((data) => data._id));
  }

  public updateFormTemplate(
    formTemplateId: string,
    update: FormTemplateUpdateBody,
  ): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.formsUrl}/${formTemplateId}`;
    return this.http.put<any>(url, update, httpOptions);
  }

  public updateFormTemplateHeaderFooterAndBinary(
    formTemplateId: string,
    headerFooterConfig: FormTemplateUpdateHeaderFooterBody,
    binaryFile: File | undefined,
  ): Observable<{
    headerFooterFileId: string;
  }> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(headerFooterConfig));
    if (binaryFile) {
      formData.append('file', binaryFile);
    }
    const url = `${this.formsUrl}/${formTemplateId}/header`;
    return this.http.put<any>(url, formData);
  }

  public updateFormTemplateTrailer(
    formTemplateId: string,
    binaryFile: File,
  ): Observable<{
    trailerFileId: string;
  }> {
    const formData = new FormData();
    formData.append('file', binaryFile);
    const url = `${this.formsUrl}/${formTemplateId}/trailer`;
    return this.http.put<any>(url, formData);
  }

  public deleteTrailerBinary(formTemplateId: string): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.formsUrl}/${formTemplateId}/trailer`;
    return this.http.delete<any>(url, httpOptions);
  }

  public deleteFormTemplate(formTemplateId: string): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.formsUrl}/${formTemplateId}`;
    return this.http.delete<any>(url, httpOptions);
  }

  private addPopulateParams(
    populateFormTemplateConfig: boolean | undefined,
    populateFormTemplateVersion: boolean | undefined,
    populateInProgressCount: boolean | undefined,
    params: HttpParams,
  ): HttpParams {
    if (populateFormTemplateConfig !== undefined) {
      params = params.set('populateFormTemplateConfig', populateFormTemplateConfig);
    }
    if (populateFormTemplateVersion !== undefined) {
      params = params.set('populateFormTemplateVersion', populateFormTemplateVersion);
    }
    if (populateInProgressCount !== undefined) {
      params = params.set('populateInProgressCount', populateInProgressCount);
    }

    return params;
  }
}
