import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { SEARCH_DEBOUNCE_TIME } from '@remberg/global/ui';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { Observable, catchError, map, of, switchMap, timer } from 'rxjs';

@Injectable()
export class RembergUsersUniqueEmailValidator {
  constructor(private readonly rembergUsersService: RembergUsersService) {}
  protected initialValue: string | undefined;

  public createValidator(initialValue?: string, tenantId?: string): AsyncValidatorFn {
    this.initialValue = initialValue;
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (this.initialValue === control.value) {
        return of(null);
      }
      return timer(SEARCH_DEBOUNCE_TIME).pipe(
        switchMap(() =>
          this.rembergUsersService.findOneByEmail({ email: control.value, tenantId }).pipe(
            map((user) => (user ? { rembergUsersUniqueEmailValidator: true } : null)),
            catchError(() => of(null)),
          ),
        ),
      );
    };
  }
}
