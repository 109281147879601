<div [ngClass]="{ 'display-inline': showInline, 'date-time-show-in-forms': showInForms }">
  <div
    class="label"
    *ngIf="!isLabelHidden"
    [matTooltipDisabled]="!isTooltipVisible || !isTooltipLabelVisible"
    [matTooltip]="label"
  >
    {{ label }}<span class="required" *ngIf="required">*</span>
  </div>
  <span *ngIf="showInline" class="fill-remaining-space"></span>
  <div
    *ngIf="value"
    class="value"
    [matTooltipDisabled]="!isTooltipVisible"
    [matTooltip]="
      showElapsedTimeInTooltip
        ? dateTimeService.getElapsedTime(value)
        : (value
          | rembergDateTimeDisplay
            : {
                timezoneDisplayMode: showTimezone
                  ? 'alwaysShowTimezone'
                  : 'showTimezoneOnlyIfDifferent',
                convertToUserTimezone: !preserveTimezone,
              })
    "
  >
    {{
      value
        | rembergDateTimeDisplay
          : {
              timezoneDisplayMode: showTimezone
                ? 'alwaysShowTimezone'
                : 'showTimezoneOnlyIfDifferent',
              convertToUserTimezone: !preserveTimezone,
              overrideFormat: hideDate ? 'p' : hideTime ? 'P' : undefined,
            }
    }}
  </div>
  <div *ngIf="!value" class="value">-</div>
</div>
