import { UnknownObject, UnknownOr } from '../definitions';
import { ObjectTypeEnum } from '../enums';

export function createUnknownObject(id: string): UnknownObject {
  return {
    _id: id,
    _type: ObjectTypeEnum.UNKNOWN,
  };
}

export function isUnknownObject(input: unknown): input is UnknownObject {
  return (input as UnknownObject)?._type === ObjectTypeEnum.UNKNOWN;
}

export function mapDetailsMapToUnknownOr<T extends { _id: string }>(
  detailsMap: Record<string, T>,
  ids: string[],
): UnknownOr<T>[] {
  return ids.map((id) => detailsMap[id] ?? createUnknownObject(id));
}
