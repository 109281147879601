import { HttpClient } from '@angular/common/http';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { isPlatform } from '@ionic/angular';
import { PUBLIC_FILE_SOURCE_TYPES, PlatformFile } from '@remberg/files/common/main';
import {
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  FILE_SYNC_DIRECTORY,
  LogService,
  SecureImagePipe,
  getStringID,
} from '@remberg/global/ui';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ServerConfigurationService } from '../services/server-configuration.service';

/** ServerFilePaths must be lowercase so when using it remember to add toLowerCase since FileSourceTypeEnum are camelCase */
export type ServerFilePaths = (typeof PUBLIC_FILE_SOURCE_TYPES)[number];

export type IMAGE_SIZE_OPTION = 'small' | 'medium' | 'large' | 'default';

@Pipe({
  name: 'fileUrl',
})
export class FileUrlPipe implements PipeTransform {
  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private logger: LogService,
    private serverConfigurationService: ServerConfigurationService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
  ) {}

  public transform(
    file: string | PlatformFile | undefined,
    serverPath?: ServerFilePaths,
    size?: IMAGE_SIZE_OPTION,
    unsafe = false,
    shouldDownloadAsBlob = false, // TODO this should be removed completely (fileUrl: .*: .*: .*:)
  ): Observable<string | null> {
    const fileID = getStringID(file);
    if (!fileID) return of(null);

    // in case of browser or in online mode just use the url to do a http query
    if (!isPlatform('capacitor') || this.connectivityService.getConnected()) {
      this.logger.silly()('Return online image URL for ' + fileID);
      return this.serverConfigurationService.filesDownloadUrl.pipe(
        take(1),
        map(
          (filesDownloadUrl) =>
            filesDownloadUrl +
            (serverPath ? serverPath.toLowerCase() + '/' : '') +
            fileID +
            (size ? '?size=' + size : ''),
        ),
        mergeMap((url) => {
          if (shouldDownloadAsBlob) {
            return new SecureImagePipe(this.http, this.sanitizer).transform(
              url,
            ) as Observable<string>;
          }
          return of(url);
        }),
        catchError(() => of('')),
      );
    }
    // in the app offline mode, try to fetch the file from the local disc
    try {
      this.logger.silly()('Return offline image URL for ' + fileID);
      return from(
        Filesystem.getUri({
          path: FILE_SYNC_DIRECTORY + '/' + fileID,
          directory: Directory.Data,
        }),
      ).pipe(
        map((result) => {
          const capacitorURL = Capacitor.convertFileSrc(result?.uri);
          if (unsafe) {
            return capacitorURL;
          }
          // We need to bypassSecurity since Angular thinks capacitor storage urls are unsafe
          return this.sanitizer.bypassSecurityTrustUrl(capacitorURL) as string;
        }),
      );
    } catch (error) {
      this.logger.error()('Could not get local offline image url: ', error);
      return of(null);
    }
  }
}
