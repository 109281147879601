<button
  mat-button
  *ngIf="!isHidden"
  class="button-list-add-entry"
  [style.border-style]="borderStyle"
  [class.button-list-add-entry-error]="borderColorRequired"
  type="button"
  (click)="onButtonClick.emit()"
>
  <span [style.color]="borderColorRequired ? 'var(--warnColor)' : 'inherit'">{{ label }}</span>
</button>
