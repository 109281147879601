<!-- Main Loader -->
<ng-template #fgsSpinner>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>

<div *ngIf="isUpdateInProgress$ | async; else interfaceTmpl" class="update-loader-wrapper">
  <app-loader></app-loader>
</div>

<ng-template #interfaceTmpl>
  <ng-container *ngrxLet="isSideNavOpen$; let isSideNavOpen">
    <mat-toolbar
      [ngClass]="{
        'beta-banner':
          (theme$ | async)?.isBetaBannerDisplayed && (layout.isXSmallOrSmallView | async) === false,
        'show-banner': showBrowserWarningBanner$ | async,
      }"
      class="mat-elevation-z4 remberg-toolbar"
    >
      <!-- --------------- -->
      <!-- Menu Open replaces toolbar on Mobile -->
      <!-- --------------- -->
      <div
        class="menu-replace-toolbar"
        *ngIf="(layout.isXSmallOrSmallView | async) && isSideNavOpen"
      >
        <div class="menu-text" i18n="@@menu">Menu</div>
        <span class="fill-remaining-space"></span>
        <button class="menu-close-icon" mat-icon-button (click)="toggleSideNav()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <!-- --------------- -->
      <!-- Regular Toolbar -->
      <!-- --------------- -->
      <div
        class="header-toolbar"
        *ngIf="
          ((layout.isXSmallOrSmallView | async) && !isSideNavOpen) ||
          (layout.isXSmallOrSmallView | async) === false
        "
      >
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="toggleSideNav()"
          class="burger-menu-toggle"
          *ngIf="layout.isXSmallOrSmallView | async"
          data-test-id="toggle-side-nav"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span *ngIf="!(theme$ | async)?.logoFileId" class="logo-container">
          <a (click)="redirectToDefaultPage()" class="application-icon">
            <img
              class="toolbar-element brand-logo"
              [ngStyle]="(layout.isXSmallOrSmallView | async) ? { 'margin-left': '10px' } : {}"
              src="/assets/img/remberg-darkblue.png"
              [src-fallback]="IMAGE_FALLBACK_URL.organization_transparent"
            />
          </a>
        </span>
        <span *ngIf="(theme$ | async)?.logoFileId; let logoFileId" class="logo-container">
          <a (click)="redirectToDefaultPage()" class="application-icon">
            <img
              class="toolbar-element brand-logo"
              [ngStyle]="(layout.isXSmallOrSmallView | async) ? { 'margin-left': '10px' } : {}"
              [attr.src]="logoFileId | getIDString | fileUrl: FileSourceTypeEnum.LOGO | async"
              [src-fallback]="IMAGE_FALLBACK_URL.organization_transparent"
            />
          </a>
        </span>

        <div class="breadcrumb-wrapper">
          <span *ngIf="(layout.isXSmallView | async) === false">
            <span
              *ngFor="let breadcrumbLink of (breadcrumbs$ | async) ?? []"
              class="toolbar-element"
            >
              <mat-icon class="breadcrumb-icon" [style.color]="(theme$ | async)?.primaryThemeColor"
                >keyboard_arrow_right</mat-icon
              >
              <button
                mat-button
                [routerLink]="[breadcrumbLink.link]"
                color="primary"
                class="breadcrumb-button"
                [style.color]="(theme$ | async)?.primaryThemeColor"
              >
                {{ breadcrumbLink.label }}
              </button>
            </span>
          </span>
        </div>

        <!-- This fills the remaining space of the current row -->
        <span class="fill-remaining-space"></span>

        <div
          class="flex-row align-items-center"
          *ngIf="(isLoggedIn$ | async) === true && (isRembergAdmin$ | async) === false"
        >
          <!-- Global Loading Indicator only used for form instance (for now) -->
          <app-global-loading-indicator />

          <!-- Sync Settings  -->
          <span *ngIf="syncUiService.offlineModeEnabled">
            <button
              mat-button
              (click)="syncUiService.toggleSync()"
              [disabled]="(isGlobalLoadingSpinning$ | async) === true"
              class="toolbar-button"
              data-test-id="offline-toggle-button"
            >
              <!-- Show when Synchronization is happening  -->
              <mat-icon
                *ngIf="
                  (offlinePrefetchService.prefetching | async) ||
                    (offlinePushService.pushing | async);
                  else iconsDuringNoSync
                "
                class="mat-icon-spin"
                [matTooltip]="translations.syncInProgress"
              >
                sync
              </mat-icon>

              <!-- Distinguish cases when there is no sync happening -->
              <ng-template #iconsDuringNoSync>
                <!-- Show when offline and no changes  -->
                <mat-icon
                  *ngIf="
                    !syncUiService.internalModeOnline &&
                    !!!(offlinePushService.outstandingChangesCount | async)
                  "
                  [matTooltip]="translations.syncAndOffline"
                  data-test-id="offline-indication-icon"
                >
                  offline_pin
                </mat-icon>

                <!-- Show when offline and pending changes  -->
                <mat-icon
                  *ngIf="
                    !syncUiService.internalModeOnline &&
                    (offlinePushService.outstandingChangesCount | async)
                  "
                  [matTooltip]="translations.changesAndOffline"
                  matBadge="{{ offlinePushService.outstandingChangesCount | async }}"
                  matBadgePosition="above after"
                  matBadgeHidden="{{ (offlinePushService.outstandingChangesCount | async) === 0 }}"
                  data-test-id="offline-indication-icon-pending-changes"
                >
                  cloud_upload
                </mat-icon>

                <!-- Show when online and internet connection -->
                <mat-icon
                  *ngIf="
                    syncUiService.internalModeOnline &&
                    (connectivityService.externalConnection$ | async)
                  "
                  [matTooltip]="translations.syncAndOnline"
                  data-test-id="online-indication-icon"
                >
                  cloud_download
                </mat-icon>

                <!-- Show when online and no internet connection -->
                <mat-icon
                  *ngIf="
                    syncUiService.internalModeOnline &&
                    (connectivityService.externalConnection$ | async) !== true
                  "
                  [matTooltip]="translations.noConnection"
                  style="color: var(--statusRed)"
                  data-test-id="online-indication-icon-no-internet"
                >
                  warning
                </mat-icon>
              </ng-template>
            </button>
          </span>

          <app-ask-ai-button class="align-items-center" *ngIf="isAskAiButtonShown$ | async" />

          <!-- -------------------- -->
          <!-- Notification Center  -->
          <!-- -------------------- -->
          <app-notifications class="align-items-center" />
        </div>

        <!-- --------------- -->
        <!-- Profile Center  -->
        <!-- --------------- -->
        <span
          *ngIf="(layout.isXSmallOrSmallView | async) === false && (contact$ | async); let contact"
          class="profile-menu"
          data-test-id="profile-menu"
        >
          <button
            *ngIf="!contact.profilePictureId"
            [matTooltip]="translations.profile"
            mat-icon-button
            color="primary"
            [matMenuTriggerFor]="menuProfile"
          >
            <img
              *ngIf="!contact.profilePictureId"
              class="profile-menu-button-image"
              [src]="IMAGE_FALLBACK_URL.user"
            />
          </button>
          <button
            *ngIf="contact.profilePictureId"
            [matTooltip]="translations.profile"
            mat-icon-button
            color="primary"
            [matMenuTriggerFor]="menuProfile"
          >
            <img
              class="mat-card-avatar profile-menu-button-image"
              [src]="
                contact.profilePictureId
                  | getIDString
                  | fileUrl: FileSourceTypeEnum.USER_PROFILE_IMAGE : 'small'
                  | async
              "
              src-fallback="{{ IMAGE_FALLBACK_URL.contact }}"
            />
          </button>
          <mat-menu #menuProfile="matMenu" xPosition="before">
            <button
              [rbRouterLink]="'/profile'"
              [class.highlighted]="isRouteActive('/profile')"
              mat-menu-item
              class="nav-link"
              data-test-id="profile-settings"
              data-product-tour-id="profile-settings"
            >
              <mat-icon>person</mat-icon>
              <ng-container i18n="@@profileSettings">Profile Settings</ng-container>
            </button>
            <mat-divider></mat-divider>
            <button
              [rbRouterLink]="'/about'"
              [class.highlighted]="isRouteActive('/about')"
              mat-menu-item
              class="nav-link"
            >
              <mat-icon>error_outline</mat-icon>
              <ng-container i18n="@@aboutRembergSoftwareLink">About</ng-container>
            </button>
            <a
              *ngIf="isIntercomInitialized$ | async"
              (click)="showIntercom()"
              intercomShow
              mat-menu-item
            >
              <mat-icon>feedback</mat-icon>
              <ng-container i18n="@@rembergSupport">remberg Support</ng-container>
            </a>
            <a *ngIf="isIntercomInitialized$ | async" mat-menu-item (click)="openHelpCenter()">
              <mat-icon>help_outline</mat-icon>
              <ng-container i18n="@@helpCenter">Help Center</ng-container>
            </a>
            <button
              name="logoutButton"
              class="logout-button"
              (click)="logout()"
              mat-menu-item
              data-test-id="logout"
            >
              <mat-icon>exit_to_app</mat-icon>
              <ng-container i18n="@@logout">Log out</ng-container>
            </button>
          </mat-menu>
        </span>
      </div>
    </mat-toolbar>

    <ng-template #matNavContent>
      <mat-nav-list
        *ngFor="let navLink of (navLinks$ | async) ?? []"
        [class.hover-menu-group]="navLink.children?.length"
        [class.hover-menu-item]="!navLink.children?.length"
        [class.filler]="navLink.label === NavlinkTypesEnum.FILLER"
        [class.hover-menu-group-show-filler]="!isSideNavOpen"
        [class.hover-menu-group-several-children]="(navLink.children?.length ?? 0) > 1"
      >
        <a
          *ngIf="
            navLink.label !== NavlinkTypesEnum.FILLER && navLink.label !== NavlinkTypesEnum.DIVIDER
          "
          class="nav-link"
          mat-list-item
          [rbRouterLink]="[navLink.link]"
          (click)="reloadRouteIfActive($event, navLink)"
          [class.parent-link]="navLink.children?.length"
          [class.highlighted]="isRouteActive(navLink.link)"
          [class.child-active]="isSideNavOpen"
          attr.data-test-id="{{ navLink.dataTestId ? navLink.dataTestId : '' }}"
        >
          <mat-icon
            *ngIf="navLink.children?.length === 0"
            class="nav-link-icon"
            [class.flipped-icon]="navLink.flipped"
            [class.material-icons-filled]="navLink.filled"
            [class.rotate-right-icon]="navLink.rotateRight"
            [class.rotate-left-icon]="navLink.rotateLeft"
            [svgIcon]="navLink.svg ? navLink.icon : ''"
          >
            {{ navLink.svg ? '' : navLink.icon }}
          </mat-icon>
          <mat-icon
            *ngIf="navLink.children?.length"
            class="nav-link-icon"
            [class.material-icons-filled]="navLink.filled"
            [class.flipped-icon]="navLink.flipped"
            [class.rotate-right-icon]="navLink.rotateRight"
            [class.rotate-left-icon]="navLink.rotateLeft"
          >
            {{ navLink.icon }}
          </mat-icon>
          <span *ngIf="isSideNavOpen" class="nav-link-label">{{ navLink.label }}</span>
        </a>

        <mat-divider
          *ngIf="
            navLink.label === NavlinkTypesEnum.DIVIDER ||
            (navLink.label === NavlinkTypesEnum.FILLER && (layout.isXSmallOrSmallView | async))
          "
          class="nav-link-divider"
        ></mat-divider>

        <div
          class="expanded-links"
          [class.child-links]="!isSideNavOpen"
          *ngIf="
            navLink.label !== NavlinkTypesEnum.FILLER &&
            navLink.label !== NavlinkTypesEnum.DIVIDER &&
            isRouteActive(navLink.link) &&
            isSideNavOpen
          "
        >
          <div [class.expanded-links-flyout-formatting]="!isSideNavOpen">
            <ng-container *ngFor="let childLink of navLink.children">
              <a
                *ngIf="
                  !childLink.newTab &&
                  childLink.label !== NavlinkTypesEnum.DIVIDER &&
                  !childLink.hideIfOpen
                "
                mat-list-item
                class="nav-link-child"
                [class.flyout]="!isSideNavOpen"
                [routerLink]="childLink.link"
                [routerLinkActive]="['highlighted']"
                [class.highlighted]="isRouteActive(childLink.link)"
                (click)="reloadRouteIfActive($event, childLink)"
                attr.data-test-id="{{ childLink.dataTestId ? childLink.dataTestId : '' }}"
              >
                <div *ngIf="isSideNavOpen" class="link-dot">&nbsp;</div>
                <span class="nav-link-child-label" [class.flyout]="!isSideNavOpen">{{
                  childLink.label
                }}</span>
              </a>
              <a
                *ngIf="
                  childLink.newTab &&
                  childLink.label !== NavlinkTypesEnum.DIVIDER &&
                  !childLink.hideIfOpen
                "
                mat-list-item
                class="nav-link-child"
                [class.flyout]="!isSideNavOpen"
                [href]="childLink.link"
                [routerLinkActive]="['highlighted']"
                target="_blank"
              >
                <div
                  *ngIf="isSideNavOpen"
                  class="link-dot"
                  attr.data-test-id="{{ childLink.dataTestId ? childLink.dataTestId : '' }}"
                >
                  &nbsp;
                </div>
                <span class="nav-link-child-label" [class.flyout]="!isSideNavOpen">{{
                  childLink.label
                }}</span>
              </a>
            </ng-container>
          </div>
        </div>

        <div
          class="expanded-links"
          [class.child-links]="!isSideNavOpen"
          *ngIf="
            navLink.label !== NavlinkTypesEnum.FILLER &&
            navLink.label !== NavlinkTypesEnum.DIVIDER &&
            !isSideNavOpen
          "
        >
          <div [class.expanded-links-flyout-formatting]="!isSideNavOpen">
            <ng-container *ngFor="let childLink of navLink.children">
              <mat-divider
                *ngIf="childLink.label === NavlinkTypesEnum.DIVIDER"
                class="nav-link-divider child-link-divider"
              >
              </mat-divider>
              <a
                *ngIf="childLink.label !== NavlinkTypesEnum.DIVIDER"
                mat-list-item
                class="nav-link-child"
                [class.flyout]="!isSideNavOpen"
                [routerLink]="childLink.link"
                [routerLinkActive]="['highlighted']"
                [class.highlighted]="isRouteActive(childLink.link)"
                (click)="reloadRouteIfActive($event, childLink)"
                attr.data-test-id="{{ childLink.dataTestId ? childLink.dataTestId : '' }}"
              >
                <div *ngIf="isSideNavOpen" class="link-dot">&nbsp;</div>
                <span class="nav-link-child-label" [class.flyout]="!isSideNavOpen">{{
                  childLink.label
                }}</span>
              </a>
            </ng-container>
          </div>
        </div>
      </mat-nav-list>

      <mat-nav-list class="mobile-profile-menu" *ngIf="layout.isXSmallOrSmallView | async">
        <ng-container>
          <mat-divider class="nav-link-divider"></mat-divider>
          <a
            class="nav-link"
            routerLink="/profile"
            [routerLinkActive]="['highlighted']"
            mat-list-item
            data-test-id="profile-menu"
            (click)="closeSideNavIfSmallView()"
          >
            <mat-icon class="material-icons-outlined">person</mat-icon>
            <span class="nav-link-label" i18n="@@profileSettings" data-test-id="profile-settings"
              >Profile Settings</span
            >
          </a>
        </ng-container>
        <mat-divider class="nav-link-divider"></mat-divider>
        <a
          class="nav-link"
          routerLink="/about"
          [routerLinkActive]="['highlighted']"
          mat-list-item
          data-test-id="about-menu"
          (click)="closeSideNavIfSmallView()"
        >
          <mat-icon>error_outline</mat-icon>
          <span class="nav-link-label" i18n="@@aboutRembergSoftwareLink">About</span>
        </a>
        <a
          class="nav-link"
          *ngIf="isIntercomInitialized$ | async"
          mat-list-item
          (click)="showIntercom()"
          intercomShow
        >
          <mat-icon>feedback</mat-icon>
          <span class="nav-link-label" i18n="@@rembergSupport">remberg Support</span>
        </a>
        <a
          class="nav-link"
          *ngIf="isIntercomInitialized$ | async"
          mat-list-item
          (click)="openHelpCenter()"
        >
          <mat-icon>help_outline</mat-icon>
          <span class="nav-link-label" i18n="@@helpCenter">Help Center</span>
        </a>
        <a
          class="nav-link logout-button"
          [routerLinkActive]="['highlighted']"
          mat-list-item
          (click)="logout()"
          data-test-id="logout"
        >
          <mat-icon>exit_to_app</mat-icon>
          <span class="nav-link-label" i18n="@@logout">Log out</span>
        </a>
      </mat-nav-list>
    </ng-template>

    <mat-sidenav-container
      class="interface-sidenav-container"
      hasBackdrop="false"
      autosize="true"
      fullscreen
      [ngClass]="{ 'show-banner': showBrowserWarningBanner$ | async }"
    >
      <!-- --------------- -->
      <!-- Side Navigation -->
      <!-- --------------- -->
      <mat-sidenav
        #sidenav
        fixedInViewport="true"
        [mode]="(layout.isXSmallOrSmallView | async) ? 'over' : 'side'"
        [opened]="(layout.isXSmallOrSmallView | async) === false"
        fixedTopGap="64"
        [class.mat-sidenav-responsive]="layout.isXSmallOrSmallView | async"
        [ngClass]="{
          'show-banner': showBrowserWarningBanner$ | async,
          'full-width-sidenav': (layout.isXSmallOrSmallView | async),
        }"
        class="sidenav-detail"
      >
        <mat-nav-list class="hover-menu" ngStyle.lt-md="padding-bottom: 0px;">
          <div [class.nav-list-item-wrapper]="(layout.isXSmallOrSmallView | async) === false">
            <ng-container *ngTemplateOutlet="matNavContent"></ng-container>
          </div>
          <a
            mat-list-item
            *ngIf="(layout.isXSmallOrSmallView | async) === false"
            [ngClass]="isSideNavOpen ? 'sidenav-toggle-collapse' : 'sidenav-toggle-expand'"
            (click)="toggleSideNav()"
          >
            <mat-icon *ngIf="!isSideNavOpen">last_page</mat-icon>
            <mat-icon *ngIf="isSideNavOpen">first_page</mat-icon>
            <span *ngIf="isSideNavOpen" i18n="@@collapse" class="collapse">&nbsp;Collapse</span>
          </a>
        </mat-nav-list>
      </mat-sidenav>

      <!-- --------------- -->
      <!-- Main Content -->
      <!-- --------------- -->
      <mat-sidenav-content
        #sidenavContent
        class="interface-sidenav-content"
        (click)="closeSideNavIfSmallView()"
      >
        <div
          class="interface-padded"
          [class.padded]="(routeHasOwnMainContentPadding$ | async) === false"
        >
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>
</ng-template>
