import { OrganizationBasic } from '@remberg/crm/common/base';
import { MultiLanguageText, UnknownOr } from '@remberg/global/common/core';
import { ConfigWithDynamicActions, ConfigWithPrefilling } from '../../../automation';
import { ConfigWithFilterSource } from '../config-with-filter-source';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrganizationSingleSelectField
  extends Field<
    FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT,
    OrganizationSingleSelectFieldConfig
  > {}

export interface OrganizationSingleSelectFieldConfig
  extends ConfigWithPrefilling<OrganizationSingleSelectPrefillTargetsEnum>,
    ConfigWithDynamicActions,
    ConfigWithFilterSource {
  disabled?: boolean;
  required?: boolean;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  label: {
    text: MultiLanguageText;
    uiHide?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value?: {
    disableCreation?: boolean;
    pdfPrintCompanyNumber?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
  };
}

export enum OrganizationSingleSelectPrefillTargetsEnum {
  SELECTED_ORGANIZATION = 'selectedCompany',
}

export interface OrganizationSingleSelectFieldData<Populated extends boolean = false> {
  selectedCompany?: Populated extends true ? UnknownOr<OrganizationBasic> : string;
}
