import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
} from '@remberg/global/ui';
import { WorkOrderStatus } from '@remberg/work-orders/common/base';
import {
  WorkOrderStatusCreateOneBody,
  WorkOrderStatusCreateOneResponse,
  WorkOrderStatusFindManyByIdsBody,
  WorkOrderStatusFindManyQuery,
  WorkOrderStatusFindManyResponse,
  WorkOrderStatusUpdateOneBody,
} from '@remberg/work-orders/common/main';
import { Observable } from 'rxjs';
import {
  WORK_ORDER_STATUS_2_OFFLINE_SERVICE,
  WorkOrderStatus2OfflineServiceInterface,
} from './work-order-status.offline.service.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderStatusService {
  private readonly WORK_ORDER_STATUS_URL = `${API_URL_PLACEHOLDER}/workorders/v2/status`;

  constructor(
    private readonly http: HttpClient,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(WORK_ORDER_STATUS_2_OFFLINE_SERVICE)
    private readonly workOrderStatus2OfflineService: WorkOrderStatus2OfflineServiceInterface,
  ) {}

  public findMany(
    options?: WorkOrderStatusFindManyQuery,
  ): Observable<WorkOrderStatusFindManyResponse> {
    if (!this.connectivityService.getConnected()) {
      return this.workOrderStatus2OfflineService.findMany(options);
    }

    let params = new HttpParams();

    if (options?.page !== undefined) {
      params = params.set('page', String(options.page));
    }
    if (options?.limit !== undefined) {
      params = params.set('limit', String(options.limit));
    }

    return this.http.get<WorkOrderStatusFindManyResponse>(this.WORK_ORDER_STATUS_URL, {
      params,
    });
  }

  public findAll(): Observable<WorkOrderStatus[]> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: findAll`);
    }

    return this.http.get<WorkOrderStatus[]>(`${this.WORK_ORDER_STATUS_URL}/all`);
  }

  public findDefault(): Observable<WorkOrderStatus | undefined> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: findDefault`);
    }

    return this.http.get<WorkOrderStatus | undefined>(`${this.WORK_ORDER_STATUS_URL}/default`);
  }

  public findManyByIds(body: WorkOrderStatusFindManyByIdsBody): Observable<WorkOrderStatus[]> {
    if (!this.connectivityService.getConnected()) {
      return this.workOrderStatus2OfflineService.findManyByIds(body);
    }

    return this.http.put<WorkOrderStatus[]>(`${this.WORK_ORDER_STATUS_URL}/sync`, body);
  }

  public deleteOne(id: string): Observable<void> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: deleteOne ${id}`);
    }

    return this.http.delete<void>(`${this.WORK_ORDER_STATUS_URL}/${id}`);
  }

  public create(body: WorkOrderStatusCreateOneBody): Observable<WorkOrderStatusCreateOneResponse> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: create body ${JSON.stringify(body)}`);
    }

    return this.http.post<WorkOrderStatusCreateOneResponse>(this.WORK_ORDER_STATUS_URL, body);
  }

  public update(id: string, body: WorkOrderStatusUpdateOneBody): Observable<WorkOrderStatus> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for update id ${id}`);
    }

    return this.http.patch<WorkOrderStatus>(`${this.WORK_ORDER_STATUS_URL}/${id}`, body);
  }

  public updateDefault(id: string): Observable<void> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: updateDefault id ${id}`);
    }

    return this.http.patch<void>(`${this.WORK_ORDER_STATUS_URL}/${id}/default`, {});
  }
}
