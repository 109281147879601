import { MultiLanguageText } from '@remberg/global/common/core';
import { ConfigWithDynamicActions, ConfigWithPrefilling } from '../../../automation';
import { SelectOptionDictionary } from '../../../common';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StaticSingleSelectField
  extends Field<FormFieldTypesEnum.STATIC_SINGLE_SELECT, StaticSingleSelectFieldConfig> {}

export interface StaticSingleSelectFieldConfig
  extends ConfigWithPrefilling<StaticSingleSelectPrefillTargetsEnum>,
    ConfigWithDynamicActions {
  required?: boolean;
  disabled?: boolean;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  label: {
    text: MultiLanguageText;
    uiHide?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value: {
    options: SelectOptionDictionary;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
  };
}

export enum StaticSingleSelectPrefillTargetsEnum {
  VALUE = 'value',
}

export interface StaticSingleSelectFieldData {
  selectedValue?: string;
}
