import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LogService } from '@remberg/global/ui';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
  constructor(private logger: LogService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.logger.silly()(
              `Request succesful: ${request.method} [${event.status}] ${request.urlWithParams}`,
            );
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // don't log the not found files to not clutter the console
            if (
              !err.message.includes('api/files/v2/download') ||
              err.status !== HttpStatusCode.NotFound
            ) {
              this.logger.error()(err);
              this.logger.error()(`Request failed: ${request.method} [${err.status}] ${
                err.message
              } \
                            - Params: ${request.params.toString()}`);
            }
          }
        },
      ),
    );
  }
}
