import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { DynamicProgressBarConfig, LogService, SyncDataTypesEnum } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { WorkOrderOfflineColumnNamesEnum } from '../offline/work-orders/work-order-2-offline.definitions';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';
import { addColumnIfMissing } from './columnHelpers';

const VERSION = 27;

export async function migrateToV27(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()(`Starting DB migration to V${VERSION}...`);

  await addWorkOrderEstimatedDurationColumn(db, logger);

  await db.executeSql(`PRAGMA user_version = ${VERSION};`, []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()(`Completed DB migration to V${VERSION}.`);
}

async function addWorkOrderEstimatedDurationColumn(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  try {
    const table = SyncDataTypesEnum.WORKORDERS2;

    const tableExistsQuery = await db.executeSql(
      `SELECT name FROM sqlite_master WHERE type='table' AND name='${table}';`,
      [],
    );

    if (!tableExistsQuery.rows?.length) {
      logger.debug()(`${table} table does not exist. Skipping migration.`);
      return;
    }

    logger.debug()(`FOUND ${table} table`);

    await addColumnIfMissing(
      db,
      logger,
      table,
      WorkOrderOfflineColumnNamesEnum.ESTIMATED_DURATION,
      'INTEGER',
    );
  } catch (error) {
    logger.error()('Error adding columns!', error);
  }
}
