import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { FileSourceTypeEnum } from '@remberg/files/common/main';
import { LogService } from '@remberg/global/ui';
import {
  GlobalSelectors,
  RootGlobalState,
  ServerConfigurationService,
} from '@remberg/ui-core/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  protected theme$ = this.store.select(GlobalSelectors.selectTheme);
  protected numLogoClicks = 0;
  protected readonly FileSourceTypeEnum = FileSourceTypeEnum;

  constructor(
    private readonly logger: LogService,
    @Inject(DOCUMENT) private readonly _document: HTMLDocument,
    private readonly serverConfigurationService: ServerConfigurationService,
    private readonly store: Store<RootGlobalState>,
  ) {}

  public ngOnInit(): void {
    this.logger.info()('Landed on url:', this._document.location.href);
  }

  protected enterDebugMode(): void {
    this.numLogoClicks++;
    if (this.numLogoClicks === 7) {
      this.serverConfigurationService.showDebugInformation();
    }
  }
}
