import { Address, AddressFormatEnum, getStringID } from '@remberg/global/ui';

/** Tells where a work order should be executed. Extracted from asset addresses and billing addresses. */
export class WorkOrderPrefillAddress {
  /** indicator to users what type of address this is this */
  description?: string;
  /** Actual address object */
  address?: Address;
  /** Is this address a company billing address*/
  isBillingAddress: boolean;
  /** Sets default in array of work order prefill address*/
  isDefault: boolean;
  /** Convenience attribute. Usually address._id */
  value?: string;
  /** Convenience attribute. Usually address.street */
  label?: string;

  constructor(
    description: string,
    isDefault: boolean,
    address: Address,
    isBillingAddress: boolean,
  ) {
    this.description = description;
    this.isDefault = isDefault;
    this.isBillingAddress = isBillingAddress;
    const [_address, _value, _label] = WorkOrderPrefillAddress.getAddressValues(address);
    this.address = _address;
    this.value = _value;
    this.label = _label;
  }

  private static getAddressValues(address: Address): [Address?, string?, string?] {
    let _address, _value, _label;
    const street = address?.street
      ? address.street +
        (address.streetNumber ? ' ' + address.streetNumber : '') +
        ', ' +
        (address.zipPostCode ? address.zipPostCode + ', ' : '') +
        (address.city ? address.city + ', ' : '') +
        (address.country ? address.country + ' ' : '')
      : null;
    if (street) {
      _label = street;
      _value = getStringID(address);
      _address = address;
    } else if (Address.getAddressString(address, AddressFormatEnum.Short)) {
      _label = Address.getAddressString(address, AddressFormatEnum.Short);
      _value = getStringID(address);
      _address = address;
    } else {
      _label = $localize`:@@noAddressSet:No address set`;
      _value = undefined;
      _address = undefined;
      _address = undefined;
    }
    return [_address, _value, _label];
  }
}
