export enum LanguageCodeEnum {
  /** American English */
  EN_US = 'en-US',
  /** German */
  DE = 'de-DE',
  /** Turkish */
  TR = 'tr-TR',
  /** French */
  FR = 'fr-FR',
  /** Spanish */
  ES = 'es-ES',
  /** Italian */
  IT = 'it-IT',
  /** Greek */
  EL = 'el-GR',
  /** Thai */
  TH = 'th-TH',
}

export const DEFAULT_LANGUAGE_CODE = LanguageCodeEnum.DE;

export enum IsoLanguageCodesEnum { // two letter ISO_639
  /** American English */
  EN = 'en',
  /** German */
  DE = 'de',
  /** Turkish */
  TR = 'tr',
  /** French */
  FR = 'fr',
  /** Spanish */
  ES = 'es',
  /** Italian */
  IT = 'it',
  /** Greek */
  EL = 'el',
  /** Thai */
  TH = 'th',
}

export const LANGUAGE_CODE_MAPPING_INTERN_TO_ISO: {
  [key in LanguageCodeEnum]: IsoLanguageCodesEnum;
} = {
  [LanguageCodeEnum.EN_US]: IsoLanguageCodesEnum.EN,
  [LanguageCodeEnum.DE]: IsoLanguageCodesEnum.DE,
  [LanguageCodeEnum.TR]: IsoLanguageCodesEnum.TR,
  [LanguageCodeEnum.FR]: IsoLanguageCodesEnum.FR,
  [LanguageCodeEnum.ES]: IsoLanguageCodesEnum.ES,
  [LanguageCodeEnum.IT]: IsoLanguageCodesEnum.IT,
  [LanguageCodeEnum.EL]: IsoLanguageCodesEnum.EL,
  [LanguageCodeEnum.TH]: IsoLanguageCodesEnum.TH,
};
