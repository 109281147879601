import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';

import { WorkOrderRightsEnum } from '@remberg/global/ui';
import { ToastrService } from 'ngx-toastr';
import { isAccountFeatureFlagEnabled } from '../../helpers/checkFeatureHelper';
import { AppStateService } from '../../services/app-state.service';
import { UserRightsService } from '../../services/user-rights.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulingBoardGuard {
  constructor(
    private userRightsService: UserRightsService,
    private router: Router,
    private logger: LogService,
    private toastr: ToastrService,
    private appState: AppStateService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (
        this.userRightsService.getPermission(WorkOrderRightsEnum.WORK_ORDER_ENABLED) &&
        // Additional Feature Flag check here
        isAccountFeatureFlagEnabled(FeatureFlagEnum.WORKORDERS_SCHEDULING, this.appState) &&
        this.userRightsService.getPermission(WorkOrderRightsEnum.WORK_ORDER_VIEW_SCHEDULING)
      ) {
        return resolve(true);
      }
      this.logger.info()('Scheduling Board Guard: Redirecting from: ' + state.url);
      this.toastr.warning(
        $localize`:@@noSchedulingBoardFeature:Account feature "Scheduling Board" is not granted.`,
        $localize`:@@warning:Warning`,
      );
      this.router.navigate(['/']);
      return resolve(false);
    });
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
