import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalSelectors, RootGlobalState, RouterSelectors } from '@remberg/ui-core/core';
import { interval, throttle } from 'rxjs';

const MIN_SPINNING_DURATION = 500;

@Component({
  selector: 'app-global-loading-indicator',
  templateUrl: './global-loading-indicator.component.html',
  styleUrls: ['./global-loading-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalLoadingIndicatorComponent {
  @Input() public inProgressTooltipText = $localize`:@@savingChanges:Saving changes`;
  @Input() public completionTooltipText = $localize`:@@allChangesSaved:All changes saved`;
  @Input() public spinnerDiameter = 18;

  protected readonly isSpinning$ = this.store
    .select(GlobalSelectors.selectGlobalLoadingIndicatorIsSpinning)
    .pipe(
      // 1. emits new value (leading: true)
      // 2. starts timer if isSpinning is true and ignores all values for MIN_SPINNING_DURATION ms
      // 3. and then emits the most recent value (trailing: true)
      throttle((isSpinning) => interval(isSpinning ? MIN_SPINNING_DURATION : 0), {
        leading: true,
        trailing: true,
      }),
    );

  protected readonly isEnabled$ = this.store.select(RouterSelectors.selectIsFormInstanceDetailPage);

  constructor(private readonly store: Store<RootGlobalState>) {}
}
