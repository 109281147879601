import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  FilterType2,
  SharedDialogData,
  SharedDialogTypeEnum,
  generateObjectId,
} from '@remberg/global/ui';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { FilteringDialogData } from '../../../dialogs/dialogs';
import { FilterField, FilterFieldOption } from '../../../helpers/filters';
import { SimpleSelectDialogComponent } from '../../../remberg-simple-select/modal-simple-select/simple-select-dialog.component';
import { DeviceTypeState, GlobalSelectors, RootGlobalState } from '../../../store';

interface FilterType2Trackable<T extends string> extends FilterType2<T> {
  id: string;
}

@Component({
  selector: 'app-filter-mobile-dialog',
  templateUrl: './filter-mobile-dialog.component.html',
  styleUrls: ['./filter-mobile-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterMobileDialogComponent implements OnInit, OnDestroy {
  filterFieldOptions: FilterField<string>;

  currentFilterFields$ = new BehaviorSubject<FilterType2Trackable<string>[]>([]);
  availableFilterFieldOptions$ = new ReplaySubject<FilterField<string>>();
  availableFilterFieldOptions?: FilterField<string>;

  doneButtonDisabled$ = new BehaviorSubject<boolean>(false);
  addFilterDisabled$ = new BehaviorSubject<boolean>(false);
  deviceType$?: Observable<DeviceTypeState | undefined>;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<FilterMobileDialogComponent>,
    private matDialog: MatDialog,
    private store: Store<RootGlobalState>,
    @Inject(MAT_DIALOG_DATA) public config: { data: FilteringDialogData<string> },
  ) {
    this.filterFieldOptions = config.data.filterFieldOptions;

    this.subscriptions.add(
      this.currentFilterFields$.subscribe((currentFilterFields: FilterType2<string>[]) => {
        this.computeAvailableFilterFieldOptions(currentFilterFields);
        this.computeDoneButtonStatus(currentFilterFields);
        this.cdRef.markForCheck();
      }),
    );
    this.subscriptions.add(
      this.availableFilterFieldOptions$.subscribe((options: FilterField<string>) => {
        this.availableFilterFieldOptions = options;
        this.cdRef.markForCheck();
      }),
    );
    this.currentFilterFields$.next(
      config.data.filterFields.map((filter) => ({ ...filter, id: generateObjectId() })),
    );
  }

  ngOnInit(): void {
    this.deviceType$ = this.store.select(GlobalSelectors.selectDeviceType);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  dismiss(): void {
    this.dialogRef.close();
  }
  done(): void {
    this.dialogRef.close(
      this.currentFilterFields$.value.map((filter) => ({
        identifier: filter.identifier,
        valueString: filter.valueString,
        value: filter.value,
      })),
    );
  }

  protected clearAll(): void {
    this.currentFilterFields$.next([]);
  }

  protected async addFilter(): Promise<void> {
    const dialogConfig: SharedDialogData = {
      dialogId: SharedDialogTypeEnum.ModalSimpleSelectDialog,
      data: {
        options: Object.values(this.availableFilterFieldOptions ?? {})
          .filter((option): option is FilterFieldOption<string> => !!option)
          .sort((a: FilterFieldOption<string>, b: FilterFieldOption<string>) =>
            a.label.localeCompare(b.label),
          ),
      },
    };

    const dialogRef = this.matDialog.open(SimpleSelectDialogComponent, {
      panelClass: 'mobile-device-full-screen',
      data: dialogConfig,
    });

    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (!dialogResult) {
      return;
    }

    const selectedFilter = this.filterFieldOptions[dialogResult.selectedOption];

    this.currentFilterFields$.next([
      ...this.currentFilterFields$.value,
      {
        identifier: dialogResult.selectedOption,
        value: undefined,
        valueString: selectedFilter?.label ?? '',
        id: generateObjectId(),
      },
    ]);
  }

  protected filterChange(filter: FilterType2<string>, index: number): void {
    const newCurrentFilters = [...this.currentFilterFields$.value];
    newCurrentFilters[index] = { ...filter, id: newCurrentFilters[index].id };
    this.currentFilterFields$.next(newCurrentFilters);
  }

  protected removeFilter(identifier: string): void {
    const newCurrentFilters = [...this.currentFilterFields$.value];
    newCurrentFilters.splice(
      newCurrentFilters.findIndex((f) => f.identifier === identifier),
      1,
    );
    this.currentFilterFields$.next(newCurrentFilters);
  }

  /**
   * Computes the filter field options to not show the ones already selected and avoid filtering by the same identifier multiple times
   */
  private computeAvailableFilterFieldOptions(currentFilterFields: FilterType2<string>[]): void {
    const availableFilterFieldOptions = { ...this.filterFieldOptions };
    if (currentFilterFields?.length > 0) {
      for (const filterField of currentFilterFields) {
        delete availableFilterFieldOptions[filterField.identifier];
      }
    }
    this.availableFilterFieldOptions$.next(availableFilterFieldOptions);

    if (Object.keys(availableFilterFieldOptions)?.length === 0) {
      this.addFilterDisabled$.next(true);
    } else {
      this.addFilterDisabled$.next(false);
    }
  }

  private computeDoneButtonStatus(currentFilterFields: FilterType2<string>[]): void {
    let disabled = false;
    for (const filterField of currentFilterFields) {
      if (filterField.value === null || filterField.value === undefined) {
        disabled = true;
      }
    }
    this.doneButtonDisabled$.next(disabled);
  }

  protected trackEntriesBy = (_: number, filter: FilterType2Trackable<string>): string => filter.id;
}
