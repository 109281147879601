export interface ContactsPrefillingQueryParams {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  shouldSendActivationMail: boolean;
  jobPosition: string;
}

export const CONTACT_QUERY_PARAM = 'contact';
