import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  LogService,
  SQLQueryParams,
  SQLSortDirection,
  SyncDataTypesEnum,
} from '@remberg/global/ui';
import { WorkOrderStatus } from '@remberg/work-orders/common/base';
import {
  WorkOrderStatusFindManyByIdsBody,
  WorkOrderStatusFindManyQuery,
  WorkOrderStatusFindManyResponse,
} from '@remberg/work-orders/common/main';
import { WorkOrderStatus2OfflineServiceInterface } from '@remberg/work-orders/ui/clients';
import { Observable, from, map } from 'rxjs';
import { RootGlobalState } from '../../../store';
import { BaseOfflineService } from '../../base.offline.service';
import { SqlDBService } from '../../sqlDB.service';

enum ColumnNamesEnum {
  ORDER_INDEX = 'orderIndex',
}

const params: SQLQueryParams<ColumnNamesEnum> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.WORKORDERSTATI2,
  columns: {
    [ColumnNamesEnum.ORDER_INDEX]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderStatus) => val.order,
    },
  },
};

@Injectable()
export class WorkOrderStatus2OfflineService
  extends BaseOfflineService<WorkOrderStatus, never>
  implements WorkOrderStatus2OfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, params, logger, store);
  }

  public findMany(
    options?: WorkOrderStatusFindManyQuery,
  ): Observable<WorkOrderStatusFindManyResponse> {
    return from(
      this.getInstancesWithCount(
        options?.page,
        options?.limit,
        ColumnNamesEnum.ORDER_INDEX,
        SQLSortDirection.ASC,
      ),
    ).pipe(map((res) => ({ data: res.data, count: res.count as number })));
  }

  public findManyByIds(body: WorkOrderStatusFindManyByIdsBody): Observable<WorkOrderStatus[]> {
    if (!body.workOrderStatusIds?.length) {
      return from([]);
    }
    const filterString = `${params.tableName}._id IN (${body.workOrderStatusIds.map((id) => `'${id}'`).join(',')})`;
    return from(this.getInstances(undefined, undefined, undefined, undefined, filterString));
  }
}
