export enum CustomPropertyTypesEnum {
  INPUT_TEXT = 'INPUT_TEXT',
  TEXTAREA = 'TEXTAREA',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  URL = 'URL',
  INPUT_NUMBER = 'INPUT_NUMBER',
  REMBERG_ASSET_SELECT = 'REMBERG_ASSET_SELECT',
  REMBERG_ORGANIZATION_SELECT = 'REMBERG_ORGANIZATION_SELECT',
  REMBERG_CONTACT_SELECT = 'REMBERG_CONTACT_SELECT',
  REMBERG_DATE = 'REMBERG_DATE',
  REMBERG_DATETIME = 'REMBERG_DATETIME',
}
