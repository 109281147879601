/* eslint-disable no-restricted-syntax */
/* eslint-disable max-params */
// We only want to import the cordova-sqlite-storage types and not the whole module!
/// <reference types="cordova-sqlite-storage" />
import { EnvironmentInjector, inject, Injectable } from '@angular/core';
import { SQLite, SQLiteDatabaseConfig, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  assertDefined,
  BaseModel,
  DataResponse,
  getStringID,
  isObjectID,
  LogService,
  OfflinePopulateType,
  OnlineStatusDataTypeEnum,
  SQLDataValues,
  SQLQueryParams,
  SQLSortDirection,
  SyncDataTypesEnum,
} from '@remberg/global/ui';
import { BehaviorSubject, firstValueFrom, ReplaySubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import type initSqlJs from 'sql.js/dist/sql-wasm.js';
import { LATEST_DB_VERSION } from '../helpers/sqlDbHelper';
import { GlobalSelectors, RootGlobalState } from '../store';
import { AppStateService } from './app-state.service';
import { BaseOfflineService } from './base.offline.service';
import { MigrationService } from './migrations/migration.service';
import { SqlDBMockService } from './sqlite-mock/sqlDBMock.service';
import { SQLiteObjectMock } from './sqlite-mock/sqlite-object-mock';

export const dataTypeToServiceMap: {
  [dataType in SyncDataTypesEnum]: BaseOfflineService<BaseModel, string>;
} = {} as any;

const POPULATE_DATA_TYPE = 'populateDataType';

export async function populateArray(
  dataType: SyncDataTypesEnum,
  arrayValue: string[] | BaseModel[] | (string | BaseModel)[] | undefined,
  logger: LogService,
): Promise<BaseModel[]> {
  const result: BaseModel[] = [];
  const service = dataTypeToServiceMap[dataType];
  assertDefined(service, `Service for the dataType ${dataType} is not found`);

  if (arrayValue) {
    const populatedInstances: Record<string, boolean> = {};
    for (const element of arrayValue) {
      const id = getStringID(element);
      if (isObjectID(id)) {
        // skip array elements that are duplicates and were already populated
        if (!populatedInstances[id]) {
          try {
            result.push(await service.getInstance(id, undefined));
          } catch (error) {
            logger.warn()('Failed to populate array instance: ' + element);
            result.push(element as BaseModel);
          }
          populatedInstances[id] = true;
        }
      } else {
        result.push(element as BaseModel);
      }
    }
  }

  return result;
}

@Injectable({
  providedIn: 'root',
})
export class SqlDBService {
  /** Subject to track whether the SQL connection itself is ready.  */
  private environmentInjector = inject(EnvironmentInjector);
  public isDbReady = new BehaviorSubject<boolean>(false);
  public dbReadySubject = new ReplaySubject<boolean>(1);

  // TODO: rework this service to handle race conditions based on uninitialized storage engine.
  private storage!: SQLiteObjectMock | SQLiteObject;

  private createdTables: {
    [key in SyncDataTypesEnum]?: SQLQueryParams;
  } = {};

  constructor(
    private readonly platform: Platform,
    private readonly sqlite: SQLite,
    private readonly sqlDBMockService: SqlDBMockService,
    private readonly logger: LogService,
    private readonly migrationService: MigrationService,
    private readonly appState: AppStateService,
    private readonly store: Store<RootGlobalState>,
  ) {}

  public async initializeDatabaseAndRunMobileMigrations(): Promise<void> {
    await this.platform.ready();
    const simulatedIonicType = (
      await firstValueFrom(
        this.store.select(GlobalSelectors.selectDeviceType).pipe(filter(Boolean)),
      )
    ).simulatedIonicType;

    const config: SQLiteDatabaseConfig = {
      name: 'remberg_db.db',
      location: 'default',
    };

    try {
      this.storage = simulatedIonicType
        ? await this.sqlDBMockService.create()
        : await this.sqlite.create(config);

      await this.appState.getReadyState();

      // migrate
      const result = await this.storage.executeSql('PRAGMA user_version;', []);
      this.logger.debug()('Retrieve DB version');
      let currentDBVersion: number = result?.rows?.item(0)?.user_version;
      this.logger.debug()(`Current DB version ${currentDBVersion}`);

      if (!currentDBVersion) {
        this.logger.debug()('Application is opened for the first time => currentDBVersion === 0');
        this.logger.debug()(
          `Hardcoding version to ${LATEST_DB_VERSION} (latest) to skip DB reset next time`,
        );
        await this.storage.executeSql(`PRAGMA user_version = ${LATEST_DB_VERSION};`, []);
        currentDBVersion = LATEST_DB_VERSION;
      } else if (currentDBVersion < 17) {
        this.logger.debug()('Deleting DB for reseting due to version lower than 17');

        if (!simulatedIonicType) {
          await this.sqlite.deleteDatabase(config);
        }

        this.logger.debug()('Creating DB again');

        if (!simulatedIonicType) {
          this.storage = await this.sqlite.create(config);
        } else {
          // Since this is a mock db, we can ignore empty initialization errors
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.storage = await this.sqlDBMockService.createSQLiteObject()!;
        }

        this.logger.debug()(`Hardcoding version to ${LATEST_DB_VERSION} (latest) to skip DB reset`);
        await this.storage.executeSql(`PRAGMA user_version = ${LATEST_DB_VERSION};`, []);
      }

      await this.migrationService.migrateSQLiteDB(this.storage);

      // Signal other services that the DB is ready
      this.isDbReady.next(true);
    } catch (error) {
      this.logger.error()(`Database could not be initialized ${JSON.stringify(error)}`);
    }
  }

  public async importDBFromFileInput(event: Event): Promise<void> {
    const sqlObj = await this.sqlDBMockService.importDB(event);
    this.storage = sqlObj;
  }

  public async reRunMigrations(): Promise<{ versionBefore: number; versionAfter: number }> {
    const curDBVersionResult = await this.storage.executeSql('PRAGMA user_version;', []);
    const curDBVersion = curDBVersionResult?.rows?.item(0)?.user_version;
    await this.migrationService.migrateSQLiteDB(this.storage);
    const newDBVersionResult = await this.storage.executeSql('PRAGMA user_version;', []);
    const newDBVersion = newDBVersionResult?.rows?.item(0)?.user_version;
    return { versionBefore: curDBVersion, versionAfter: newDBVersion };
  }

  private async execute(
    statement: string,
    params?: initSqlJs.SqlValue[],
  ): Promise<SQLitePlugin.Results> {
    try {
      await this.dbReadySubject.pipe(take(1)).toPromise();
      this.logger.debug()(`Execute query: ${statement}`);
      const modifiedParams = params ? replaceUndefinedWithNull(params) : [];
      const queryResult = await this.storage.executeSql(statement, modifiedParams);
      this.logger.debug()(`Executed query: ${statement} with result: `, queryResult);

      return queryResult;
    } catch (error) {
      this.logger.error()(error);

      throw error;
    }
  }

  private async batchExecute(
    statements: [string, initSqlJs.SqlValue[]][],
  ): Promise<SQLitePlugin.Results> {
    await this.dbReadySubject.pipe(take(1)).toPromise();

    const cleanFromUndefined: [string, initSqlJs.SqlValue[]][] = statements.map(
      ([statement, params]) => [statement, params ? replaceUndefinedWithNull(params) : []],
    );

    return this.storage.sqlBatch(cleanFromUndefined);
  }

  public async resetTables(): Promise<void> {
    const promises = [];
    const createdTablesCopy = this.createdTables;
    this.createdTables = {};
    this.dbReadySubject.next(false);
    for (const table of Object.values(createdTablesCopy)) {
      promises.push(this.deleteTable(table));
      promises.push(await this.createTable(table));
    }
    await Promise.all(promises);
  }

  public async createTable(
    params: SQLQueryParams<string, string, string>,
    service?: BaseOfflineService<BaseModel, string>,
  ): Promise<void> {
    try {
      const columnDefinitions = this.generateColumnDefinitions(params);
      await this.storage.executeSql(
        `CREATE TABLE IF NOT EXISTS ${params.tableName} (${columnDefinitions})`,
        [],
      );
      this.createdTables[params.tableName] = params;
      if (service) {
        dataTypeToServiceMap[params.tableName] = service;
      }
      this.logger.debug()(`${params.tableName} table was created.`);
      // check if all tables were created to determine the dbReady state
      let complete = true;
      for (const type of Object.values(SyncDataTypesEnum)) {
        if (!this.createdTables[type]) {
          complete = false;
        }
      }
      if (complete) {
        this.dbReadySubject.next(true);
        this.logger.info()('All tables initialized - sqlDB.service is now ready!');
      }
    } catch (e) {
      this.logger.error()(`Error in ${params.tableName} table creation ` + JSON.stringify(e));
    }
  }

  public async deleteTable(params: SQLQueryParams): Promise<void> {
    try {
      await this.storage.executeSql(`DROP TABLE IF EXISTS ${params.tableName}`, []);
      this.logger.debug()(`${params.tableName} table was deleted.`);
    } catch (e) {
      this.logger.error()(`Error in ${params.tableName} table deletion ` + JSON.stringify(e));
    }
  }

  // Generic Functions

  private async getGenericObjectHelper(
    data: string | BaseModel,
    params: SQLQueryParams<string, string, string>,
    instanceColumn: 'instance' | 'originalInstance' = 'instance',
    populate?: OfflinePopulateType,
  ): Promise<{ instance: BaseModel; onlineStatus: OnlineStatusDataTypeEnum }> {
    // wait until DB is completely initialized
    await this.dbReadySubject.pipe(take(1)).toPromise();

    const columns = [params.tableName + '.*'];
    const populateFields: {
      sourceKey: string;
      targetKey: string;
      populateDataType: SyncDataTypesEnum;
    }[] = [];
    const serviceParams = dataTypeToServiceMap[params.tableName]?.params;
    const populates = serviceParams?.populates ?? {};

    // population
    if (populate) {
      // determine populateFields
      for (const populateKey of Object.keys(populates)) {
        const populateConfig = populates[populateKey];
        if (POPULATE_DATA_TYPE in populateConfig) {
          const { sourceKey, targetKey, populateDataType } = populateConfig;
          if (populate === true || populate[populateKey]) {
            populateFields.push({ sourceKey, targetKey, populateDataType });
            columns.push(sourceKey + '_populate.instance as populated_' + targetKey);
          }
        }
      }
    }
    let query = `SELECT ${columns.join(', ')} FROM ${params.tableName}`;
    for (const populateField of populateFields) {
      query += ` LEFT JOIN ${populateField.populateDataType} AS ${populateField.sourceKey}_populate`;
      query += ` ON ${params.tableName}.${populateField.sourceKey} = ${populateField.sourceKey}_populate._id`;
    }
    query += ` WHERE ${params.tableName}.${params.idString} = ?`;

    const dataId = getStringID(data);
    const queryResult = await this.execute(query, [dataId]);
    const item = queryResult?.rows.item(0);
    if (item?.[instanceColumn]) {
      const instance = JSON.parse(item[instanceColumn]);
      this.logger.debug()('Found generic object and returning.');
      // override populated instance fields
      for (const populateKey of Object.keys(populates)) {
        const populateConfig = populates[populateKey];

        if (populate === true || (typeof populate === 'object' && populate?.[populateKey])) {
          // we only support populating the standard PopulateTypes via SQL so far!

          if (POPULATE_DATA_TYPE in populateConfig) {
            const { targetKey } = populateConfig;
            if (item['populated_' + targetKey]) {
              instance[targetKey] = JSON.parse(item['populated_' + targetKey]);
            }
          } else {
            // asynchronously populate AdvancedPopulateType fields
            const { targetKey, populateFunction } = populateConfig;
            if (populateFunction) {
              try {
                await this.environmentInjector.runInContext(async () => {
                  instance[targetKey] = await populateFunction(instance, this.logger);
                });
              } catch (error) {
                this.logger.warn()('Population failed', error);
                instance[targetKey] = dataId;
              }
            }
          }
        }
      }
      return {
        instance: instance,
        onlineStatus: item.onlineStatus,
      };
    } else {
      this.logger.warn()('Did not find a generic object: ', dataId, params);
      throw { message: 'Instance not found: ' + dataId + ' (' + params.tableName + ')' };
    }
  }

  public async getGenericObject(
    data: string | BaseModel,
    params: SQLQueryParams<string, string, string>,
    instanceColumn: 'instance' | 'originalInstance' = 'instance',
    populate?: OfflinePopulateType,
  ): Promise<BaseModel> {
    return (await this.getGenericObjectHelper(data, params, instanceColumn, populate)).instance;
  }

  public async getGenericObjectAsDataResponse<T extends BaseModel = BaseModel>(
    data: string | T,
    params: SQLQueryParams<string, string, string>,
    instanceColumn: 'instance' | 'originalInstance' = 'instance',
    populate?: OfflinePopulateType,
  ): Promise<DataResponse<T>> {
    const result = await this.getGenericObjectHelper(data, params, instanceColumn, populate);
    return new DataResponse<T>(result.instance as T, result.onlineStatus);
  }

  private async getGenericObjectsHelper(
    params: SQLQueryParams<string, string, string>,
    pageSize?: number,
    pageIndex?: number,
    sortColumn?: string,
    sortDirection?: SQLSortDirection,
    sqlFilter?: string,
    populate?: OfflinePopulateType,
  ): Promise<{ instance: BaseModel; onlineStatus: OnlineStatusDataTypeEnum }[]> {
    // wait until DB is completely initialized
    await this.dbReadySubject.pipe(take(1)).toPromise();

    const columns = [params.tableName + '.*'];
    const populateFields: {
      sourceKey: string;
      targetKey: string;
      populateDataType: SyncDataTypesEnum;
    }[] = [];
    const serviceParams = dataTypeToServiceMap[params.tableName]?.params;
    const populates = serviceParams?.populates ?? {};

    // population
    if (populate) {
      // determine populateFields
      for (const populateKey of Object.keys(populates)) {
        const populateConfig = populates[populateKey];
        if (POPULATE_DATA_TYPE in populateConfig) {
          const { sourceKey, targetKey, populateDataType } = populateConfig;

          if (populate === true || populate[populateKey]) {
            populateFields.push({ sourceKey, targetKey, populateDataType });
            columns.push(sourceKey + '_populate.instance as populated_' + targetKey);
          }
        }
      }
    }

    let query = `SELECT ${columns.join(', ')} FROM ${params.tableName}`;
    for (const populateField of populateFields) {
      query += ` LEFT JOIN ${populateField.populateDataType} AS ${populateField.sourceKey}_populate`;
      query += ` ON ${params.tableName}.${populateField.sourceKey} = ${populateField.sourceKey}_populate._id`;
    }
    if (sqlFilter) {
      query = query + ` WHERE ${sqlFilter}`;
    }
    if (sortColumn) {
      // prepend tableName to sortColumn name
      sortColumn = params.tableName + '.' + sortColumn;
      query = query + ` ORDER BY ${sortColumn}`;
      // assure that sorting for some text properties is case insensitive
      if (params.columns?.[sortColumn]?.sortNoCase) {
        query = query + ' COLLATE NOCASE';
      }
      query = sortDirection ? query + ` ${sortDirection}` : query;
    }
    if (pageSize) {
      query = query + ` LIMIT ${pageSize}`;
    }
    const offset = (pageSize ?? 0) * (pageIndex ?? 0);
    if (offset) {
      query = query + ` OFFSET ${offset}`;
    }

    const queryResult = await this.execute(query, []);
    const results: { instance: BaseModel; onlineStatus: OnlineStatusDataTypeEnum }[] = [];
    if (queryResult.rows.length > 0) {
      for (let i = 0; i < queryResult.rows.length; i++) {
        const item = queryResult.rows.item(i);
        const instance = JSON.parse(item.instance);
        // override populated instance fields
        for (const populateKey of Object.keys(populates)) {
          const populateConfig = populates[populateKey];

          if (populate === true || (typeof populate === 'object' && populate?.[populateKey])) {
            // we only support populating the standard PopulateTypes via SQL so far!

            if (POPULATE_DATA_TYPE in populateConfig) {
              const { targetKey } = populateConfig;
              if (item['populated_' + targetKey]) {
                instance[targetKey] = JSON.parse(item['populated_' + targetKey]);
              }
            } else {
              // asynchronously populate AdvancedPopulateType fields
              const { targetKey, populateFunction } = populateConfig;
              if (populateFunction) {
                try {
                  await this.environmentInjector.runInContext(async () => {
                    instance[targetKey] = await populateFunction(instance, this.logger);
                  });
                } catch (error) {
                  this.logger.warn()('Population failed', error);
                }
              }
            }
          }
        }
        results.push({
          instance: instance,
          onlineStatus: item.onlineStatus,
        });
      }
    }
    return results;
  }

  /**
   *
   * @param params The table specific sql parameter.
   * @param pageSize How man elements per page?
   * @param pageIndex Which page?
   * @param sortColumn Which column should we sort by?
   * @param sortDirection Which direction should we sort, asc or desc?
   * @param sqlFilter This gets passed to the WHERE SQL clause and can be used to filter for a
   * specific value or using a REGEXP
   */
  public async getGenericObjects(
    params: SQLQueryParams<string, string, string>,
    pageSize?: number,
    pageIndex?: number,
    sortColumn?: string,
    sortDirection?: SQLSortDirection,
    sqlFilter?: string,
    populate?: OfflinePopulateType,
  ): Promise<BaseModel[]> {
    const queryResult = await this.getGenericObjectsHelper(
      params,
      pageSize,
      pageIndex,
      sortColumn,
      sortDirection,
      sqlFilter,
      populate,
    );
    return queryResult.map((obj) => obj.instance);
  }

  public async getGenericObjectsAsDataResponse(
    params: SQLQueryParams<string, string, string>,
    pageSize?: number,
    pageIndex?: number,
    sortColumn?: string,
    sortDirection?: SQLSortDirection,
    sqlFilter?: string,
    populate?: OfflinePopulateType,
  ): Promise<DataResponse<BaseModel>[]> {
    const queryResult = await this.getGenericObjectsHelper(
      params,
      pageSize,
      pageIndex,
      sortColumn,
      sortDirection,
      sqlFilter,
      populate,
    );
    return queryResult.map((obj) => new DataResponse<BaseModel>(obj.instance, obj.onlineStatus));
  }

  public async countGenericObjects(
    params: SQLQueryParams<string, string, string>,
    sqlFilter?: string,
  ): Promise<number> {
    let query = `SELECT COUNT(${params.idString}) FROM ${params.tableName}`;
    if (sqlFilter) {
      query = query + ` WHERE ${sqlFilter}`;
    }
    const queryResult = await this.execute(query, []);
    return queryResult.rows.item(0)[`COUNT(${params.idString})`];
  }

  public async getGenericObjectIDs(
    params: SQLQueryParams<string, string, string>,
    sqlFilter?: string,
    pageSize?: number,
    pageIndex?: number,
  ): Promise<string[]> {
    let query =
      `SELECT ${params.idString} FROM ${params.tableName}` +
      (sqlFilter ? ` WHERE ${sqlFilter}` : '');

    const offset = (pageIndex ?? 0) * (pageSize ?? 0);
    if (pageSize) {
      query += ` LIMIT ${pageSize} OFFSET ${offset}`;
    }

    const queryResult = await this.execute(query);
    const results = [];
    if (queryResult.rows.length > 0) {
      for (let i = 0; i < queryResult.rows.length; i++) {
        results.push(queryResult.rows.item(i)._id);
      }
    }
    return results;
  }

  public async addGenericObject(
    dataValues: SQLDataValues,
    params: SQLQueryParams<string, string, string>,
  ): Promise<void> {
    const columnNames = this.generateColumnNames(params);
    const valuesPlaceholder = this.generateValuesPlaceholder(params);
    await this.execute(
      `INSERT INTO ${params.tableName} (${columnNames}) VALUES (${valuesPlaceholder})`,
      dataValues,
    );
  }

  public async bulkAddUpdateGenericObjects(
    dataValuesArray: SQLDataValues[],
    params: SQLQueryParams<string, string, string>,
  ): Promise<void> {
    const insertRows = [];
    const columnNames = this.generateColumnNames(params);
    const valuesPlaceholder = this.generateValuesPlaceholder(params);
    for (const dataValues of dataValuesArray) {
      insertRows.push([
        `REPLACE INTO ${params.tableName} (${columnNames}) VALUES (${valuesPlaceholder})`,
        dataValues,
      ]);
    }
    try {
      await this.batchExecute(insertRows as any);
    } catch (error) {
      this.logger.error()('Error during bulkAddUpdate: ', error);
      throw error;
    }
  }

  public async deleteGenericObject(
    data: string | BaseModel,
    params: SQLQueryParams<string, string, string>,
  ): Promise<void> {
    const dataId = getStringID(data);
    await this.execute(`DELETE FROM ${params.tableName} WHERE ${params.idString} = ?`, [dataId]);
  }

  public async deleteGenericObjects(
    data: string[] | BaseModel[],
    params: SQLQueryParams<string, string, string>,
  ): Promise<void> {
    const deleteRows: any = [];
    for (const element of data) {
      deleteRows.push([
        `DELETE FROM ${params.tableName} WHERE ${params.idString} = ?`,
        [getStringID(element)],
      ]);
    }
    await this.batchExecute(deleteRows);
  }

  public async dropGenericTable(params: SQLQueryParams<string, string, string>): Promise<void> {
    await this.execute(`DELETE FROM ${params.tableName}`);
  }

  private generateColumnDefinitions(params: SQLQueryParams<string, string, string>): string {
    let columnDefinitions =
      `${params.idString} TEXT PRIMARY KEY, onlineStatus TEXT,` +
      ' instance TEXT, originalInstance TEXT';
    for (const key of Object.keys(params.columns ?? {})) {
      if (Object.prototype.hasOwnProperty.call(params.columns, key)) {
        columnDefinitions += `, ${key} ${params.columns?.[key]?.type}`;
      }
    }
    return columnDefinitions;
  }

  private generateColumnNames(params: SQLQueryParams): string {
    let columnNames = `${params.idString}, onlineStatus, instance, originalInstance`;
    for (const key of Object.keys(params.columns ?? {})) {
      if (key) {
        columnNames += `, ${key}`;
      }
    }
    return columnNames;
  }

  private generateValuesPlaceholder(params: SQLQueryParams): string {
    let valuesPlaceholder = '?, ?, ?, ?';
    for (const column of Object.keys(params.columns ?? {})) {
      valuesPlaceholder += ', ?';
    }
    return valuesPlaceholder;
  }
}

function replaceUndefinedWithNull<T>(array: (T | undefined)[]): (T | null)[] {
  return array.map((item) => (item === undefined ? null : item));
}
