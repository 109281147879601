import { AbstractControl, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export function addErrors(control: AbstractControl, errors: Record<string, boolean>): void {
  const resultErrors = control?.errors ?? {};
  for (const key of Object.keys(errors)) {
    if (errors[key]) {
      resultErrors[key] = true;
    }
  }
  control?.setErrors(Object.keys(resultErrors).length > 0 ? resultErrors : null);
}

export function removeErrors(control: AbstractControl, errors: Record<string, boolean>): void {
  const resultErrors = control?.errors ?? {};
  for (const key of Object.keys(errors)) {
    if (!errors[key] && resultErrors[key]) {
      delete resultErrors[key];
    }
  }
  control?.setErrors(Object.keys(resultErrors).length > 0 ? resultErrors : null);
}

export class InstantErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null): boolean {
    return !!control && control.invalid && (control.dirty || control.touched);
  }
}
