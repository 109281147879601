import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogConfigBase, DialogResultBase } from '@remberg/global/ui';

export type PreviewServerInputDialogResult = DialogResultBase & {
  dialogId: string;
  previewServerURL?: string;
};

export type PreviewServerInputDialogData = DialogConfigBase<
  string,
  PreviewServerInputDialogResult
> & {
  dialogId: string;
  confirmButtonIcon?: string;
};

@Component({
  selector: 'app-preview-server-input-dialog',
  templateUrl: './preview-server-input-dialog.component.html',
  styleUrls: ['./preview-server-input-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandIn', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('200ms ease-out', style({ opacity: 1, height: '*' })),
      ]),
      transition(':leave', [animate('200ms ease-out', style({ opacity: 0, height: 0 }))]),
    ]),
  ],
})
export class PreviewServerInputDialogComponent {
  protected readonly dialogId: string;
  protected readonly confirmButtonText: string;
  protected readonly cancelButtonText?: string;
  protected readonly confirmButtonIcon?: string;
  protected readonly contentText?: string;
  protected readonly headerText?: string;
  protected readonly headerIcon: string;
  protected readonly footerText?: string;
  protected readonly footerIcon?: string;

  protected label: string = $localize`:@@previewNumber:Preview number`;
  protected previewServerNumFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d+$/),
  ]);

  protected previewServerURL?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: PreviewServerInputDialogData,
    private dialogRef: MatDialogRef<
      PreviewServerInputDialogComponent,
      PreviewServerInputDialogResult
    >,
  ) {
    this.dialogId = this.data.dialogId;
    this.confirmButtonText = this.data.confirmButtonText ?? '';
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonIcon = this.data.confirmButtonIcon;
    this.contentText = this.data.contentText;
    this.headerText = this.data.headerText;
    this.headerIcon = this.data.headerIcon ?? 'warning_amber';
    this.footerText = this.data.footerText;
    this.footerIcon = this.data.footerIcon;

    this.dialogRef.beforeClosed().subscribe((dialogResult) => this.dialogRef.close(dialogResult));

    this.previewServerNumFormControl.valueChanges.subscribe((value) => {
      this.previewServerURL = value !== null ? `https://preview-${value}.remberg.dev` : '';
    });
  }

  protected get inputIsValid(): boolean {
    return !this.previewServerNumFormControl?.errors && !!this.previewServerURL;
  }

  protected confirm(): void {
    if (!this.inputIsValid) {
      return;
    }

    const dialogResult: PreviewServerInputDialogResult = {
      dialogId: this.dialogId,
      previewServerURL: this.previewServerURL,
    };
    this.dialogRef.close(dialogResult);
  }

  protected close(): void {
    this.dialogRef.close(undefined);
  }
}
