import { KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AdvancedFilterTypeEnum } from '@remberg/advanced-filters/common/main';
import { FilterType2 } from '@remberg/global/ui';
import { Subscription } from 'rxjs';
import { FilterField, FilterFieldOption, FilterTypeEnum } from '../../../../helpers/filters';

@Component({
  selector: 'app-filter-desktop-dialog-entry',
  templateUrl: './filter-desktop-dialog-entry.component.html',
  styleUrls: ['./filter-desktop-dialog-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDesktopDialogEntryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public first?: boolean;
  @Input() public filter?: FilterType2<string>;
  @Input() public filterFieldOptions?: FilterField<string>;
  @Input() public availableFilterFieldOptions?: FilterField<string>;

  protected filteredAvailableFilterFieldOptions?: FilterFieldOption<string>[];
  protected FilterTypeEnum = FilterTypeEnum;
  protected readonly AdvancedFilterTypeEnum = AdvancedFilterTypeEnum;

  @Output() protected remove = new EventEmitter<void>();
  @Output() protected filterChange = new EventEmitter<FilterType2<string>>();

  protected readonly translations = {
    search: $localize`:@@searchDotDotDot:Search ...`,
    noEntriesFound: $localize`:@@noEntriesFound:No Entries Found`,
  };
  protected filterCtrl: UntypedFormControl = new UntypedFormControl();
  protected booleanFilterValueOptions = [
    {
      label: $localize`:@@yes:Yes`,
      value: 'true',
    },
    {
      label: $localize`:@@no:No`,
      value: 'false',
    },
  ];

  private readonly subscriptions = new Subscription();

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['availableFilterFieldOptions']?.currentValue && this.filter) {
      this.availableFilterFieldOptions = { ...changes['availableFilterFieldOptions'].currentValue };
      if (this.availableFilterFieldOptions) {
        this.availableFilterFieldOptions[this.filter.identifier] =
          this.filterFieldOptions?.[this.filter.identifier];
      }
      this.filteredAvailableFilterFieldOptions = Object.values({
        ...this.availableFilterFieldOptions,
      })
        .filter((option): option is FilterFieldOption<string> => !!option)
        .sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.filterCtrl.valueChanges.subscribe((value) => {
        this.filteredAvailableFilterFieldOptions = Object.values({
          ...this.availableFilterFieldOptions,
        })
          .filter((option): option is FilterFieldOption<string> => !!option)
          .filter((option) => option.label.toLowerCase().includes(value.toLowerCase()))
          .sort((a, b) => a.label.localeCompare(b.label));
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected filterIdentifierChange(identifier: string): void {
    this.filter = {
      identifier,
      value: undefined,
    };
    this.filterChange.emit(this.filter);
  }

  protected filterValueChange(value: string): void {
    if (this.filter) {
      this.filter = {
        identifier: this.filter.identifier,
        value,
      };
      this.filterChange.emit(this.filter);
    }
  }

  protected orderByLabel(
    a: KeyValue<string, FilterFieldOption<string>>,
    b: KeyValue<string, FilterFieldOption<string>>,
  ): number {
    return a.value.label.localeCompare(b.value.label);
  }
}
