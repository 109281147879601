import { Pipe, PipeTransform } from '@angular/core';
import { RembergTime } from '@remberg/global/common/core';
import { DatetimeService, isRembergTime } from '@remberg/ui-core/core';

@Pipe({
  name: 'rembergTimeDisplay',
})
export class RembergTimeDisplayPipe implements PipeTransform {
  constructor(private datetimeService: DatetimeService) {}

  public transform(value?: RembergTime): string {
    if (!isRembergTime(value)) {
      return value ?? '';
    }
    return this.datetimeService.formatRembergTime(value);
  }
}
