import { Assert, FeatureFlagEnum, TwoWayCompareStringType } from '@remberg/global/common/core';
import { PartPermissions } from '@remberg/users/common/main';

export enum PartPermissionsEnum {
  PARTS_ENABLED = 'parts_enabled',
  PARTS_CREATE = 'parts_create',
  PARTS_UPDATE = 'parts_update',
  PARTS_DELETE = 'parts_delete',
  PARTS_EXPORT = 'parts_export',
  PARTS_VIEW_IN_OVERVIEW = 'parts_viewInOverview',
  PARTS_VIEW_IN_ASSET_DETAIL = 'parts_viewInAssetDetail',
}

export type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `${FeatureFlagEnum.PARTS}_${keyof PartPermissions}`,
    `${PartPermissionsEnum}`
  >
>;
