<mat-form-field
  [appearance]="'outline'"
  [ngClass]="{
    'search-bar-form-field-has-value':
      (searchString | castToString) && (searchString | castToString).length > 0,
  }"
>
  <mat-icon *ngIf="!isIconHidden" ngClass.xs="hide" matPrefix>search</mat-icon>
  <input
    #searchInputElement
    class="placeholder-truncate-text"
    type="text"
    autocomplete="off"
    matInput
    placeholder="{{ inputPlaceholder }}"
    [(ngModel)]="searchString"
    [maxLength]="100"
    (input)="onValueChange($event)"
    (focus)="inputFocused.next()"
    data-test-id="search-input"
  />
  <mat-icon
    [ngClass]="{ hide: !searchString }"
    matSuffix
    (click)="clearValue()"
    data-test-id="clear-input"
    >close</mat-icon
  >
</mat-form-field>
