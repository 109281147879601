import { LanguageCodeEnum } from '@remberg/global/common/core';

export type LanguageCodeLabels = { [id in LanguageCodeEnum]?: string };

export enum LocalStorageKeys {
  OFFLINE_LAST_UPDATED_ASSETS = 'remberg-offline-lastUpdated-assets',
  OFFLINE_LAST_UPDATED_ASSETS2 = 'remberg-offline-lastUpdated-assets2',
  OFFLINE_LAST_UPDATED_ASSETTYPES = 'remberg-offline-lastUpdated-assetTypes',
  OFFLINE_LAST_UPDATED_ASSETTYPES2 = 'remberg-offline-lastUpdated-assetTypes2',
  OFFLINE_LAST_UPDATED_ORGANIZATIONS = 'remberg-offline-lastUpdated-organizations',
  OFFLINE_LAST_UPDATED_FORMINSTANCES = 'remberg-offline-lastUpdated-formInstances',
  OFFLINE_LAST_UPDATED_FORMTEMPLATES = 'remberg-offline-lastUpdated-formTemplates',
  OFFLINE_LAST_UPDATED_FORMTEMPLATEVERSIONS = 'remberg-offline-lastUpdated-formTemplateVersions',
  OFFLINE_LAST_UPDATED_EMAILSTATUSES = 'remberg-offline-lastUpdated-emailStatuses',
  OFFLINE_LAST_UPDATED_CONTACTS = 'remberg-offline-lastUpdated-contacts',
  OFFLINE_LAST_UPDATED_FILES = 'remberg-offline-lastUpdated-files',
  OFFLINE_LAST_UPDATED_WORKORDERS = 'remberg-offline-lastUpdated-workOrders',
  OFFLINE_LAST_UPDATED_WORKORDERSTATI = 'remberg-offline-lastUpdated-workOrderStati',
  OFFLINE_LAST_UPDATED_WORKORDERTYPES = 'remberg-offline-lastUpdated-workOrderTypes',
  OFFLINE_LAST_UPDATED_WORKORDERS2 = 'remberg-offline-lastUpdated-workOrders2',
  OFFLINE_LAST_UPDATED_WORKORDERSTATI2 = 'remberg-offline-lastUpdated-workOrderStati2',
  OFFLINE_LAST_UPDATED_WORKORDERTYPES2 = 'remberg-offline-lastUpdated-workOrderTypes2',
  OFFLINE_LAST_UPDATED_USERGROUPS = 'remberg-offline-lastUpdated-userGroups',
  OFFLINE_LAST_UPDATED_ICONS = 'remberg-offline-lastUpdated-icons',
  OFFLINE_LAST_UPDATED_SERVICECASES = 'remberg-offline-lastUpdated-serviceCases',
  OFFLINE_LAST_UPDATED_PARTS = 'remberg-offline-lastUpdated-parts',
  OFFLINE_FORM_DRAFT_COUNT_DICT = 'remberg-offline-form-draft-count-dict',
  OFFLINE_FORM_DELETED_INSTANCES = 'remberg-offline-form-deleted-instances',
  OFFLINE_LAST_UPDATED = 'remberg-offline-lastUpdated',

  TOKEN = 'remberg-token',
  UI_STATE = 'remberg-uiState',
  SUBDOMAIN = 'remberg-subdomain',
  CASES = 'remberg-cases',
  CK_EDITOR = 'remberg-ckEditor',

  INTERNAL_CONNECTIVITY_STATUS = 'remberg-internal-connectivity-status',
  IONIC_DATA_SYNC_STATUS = 'remberg-ionic-dataSyncStatus',

  // We can clear these upon logout
  IONIC_FORMS_FILES = 'remberg-offline-forms-files',
  IONIC_OFFLINE_FORM_DELETIONS = 'remberg-offline-formDeletions',
  IONIC_OFFLINE_CHANGES_BACKUP = 'remberg-offline-changes-backup',
  IONIC_INTERCOM_HASHES = 'remberg-ionic-intercomHashes',
  IONIC_CURRENT_TENANT = 'remberg-ionic-currentTenant',
  IONIC_CURRENT_REMBERG_USER = 'remberg-ionic-currentRembergUser',
  IONIC_CURRENT_USER_ROLE = 'remberg-ionic-currentUserRole',

  // We have to keep these upon logout
  IONIC_CURRENT_SERVER_NAME = 'remberg-ionic-currentServerName',
  IONIC_CURRENT_SERVER_URL = 'remberg-ionic-currentServerUrl',
  IONIC_PUBLIC_TENANT = 'remberg-ionic-tenantPublic', // TODO: how do we deal with this being missing on older mobile apps?

  // Debugging
  SIMULATE_IONIC = 'simulate-ionic',
  SIMULATE_IONIC_PRELOAD_DB = 'simulate-ionic-preload-db',

  IONIC_APP_VERSION_INVALID = 'remberg-ionic-app-version-invalid',

  APP_VERSION = 'remberg-app-version',
  FEATURES_UPDATED_TENANT_ID = 'remberg-features-updated-tenant-id',
  PERMISSIONS_UPDATED_USER_ID = 'remberg-permissions-updated-user-id',

  // ORGANIZATIONS
  ORGANIZATIONS_IS_ASSOCIATIONS_SIDEBAR_OPENED = 'remberg-organizations-is-associations-sidebar-opened',

  // ASSETS
  ASSETS_IS_ASSOCIATIONS_SIDEBAR_OPENED = 'remberg-assets-is-associations-sidebar-opened',
  ASSETS_IS_PARTS_SECTION_OPENED = 'remberg-assets-is-parts-section-opened',
  ASSETS_IS_FILES_SECTION_OPENED = 'remberg-assets-is-files-section-opened',
  ASSETS_IS_CASES_SECTION_OPENED = 'remberg-assets-is-cases-section-opened',
  ASSETS_IS_TASKS_SECTION_OPENED = 'remberg-assets-is-tasks-section-opened',
  ASSETS_IS_WO_SECTION_OPENED = 'remberg-assets-is-wo-section-opened',
  ASSETS_IS_WO2_SECTION_OPENED = 'remberg-assets-is-wo2-section-opened',
  ASSETS_IS_FORMS_SECTION_OPENED = 'remberg-assets-is-forms-section-opened',
  ASSETS_IS_NOTES_SECTION_OPENED = 'remberg-assets-is-notes-section-opened',
  ASSETS_IS_TREE_SECTION_OPENED = 'remberg-assets-is-tree-section-opened',

  // GLOBAL MAP
  GLOBAL_MAP_CONFIG = 'remberg-global-map-config',

  // WORK ORDERS 2
  WORK_ORDERS_ACTIVE_TAB = 'remberg-work-orders-active-tab',
  WORK_ORDERS_IS_ASSOCIATIONS_SIDEBAR_OPENED = 'remberg-work-orders-is-associations-sidebar-opened',
  WORK_ORDERS_IS_SCHEDULING_BOARD_SIDEBAR_OPENED = 'remberg-work-orders-is-scheduling-board-sidebar-opened',

  // Deprecated (needed for compatibility)
  /**
   * @deprecated
   */
  IONIC_CURRENT_THEME = 'remberg-ionic-currentTheme',
  /**
   * @deprecated not beeing read any where @hagen should we get rid of it ?
   */
  IONIC_CURRENT_TENANT_ID = 'remberg-ionic-currentTenantId',
  /**
   * @deprecated not beeing read any where @hagen should we get rid of it ?
   */
  IONIC_CURRENT_USER_PROFILE = 'remberg-ionic-currentUserProfile',
  /**
   * @deprecated Only used on the logout effect to clean up local storage devices
   */
  USER_RIGHTS = 'remberg-userRights',
  /**
   * @deprecated Only used on the logout effect to clean up local storage devices
   */
  USER_SETTINGS = 'remberg-userSettings',
  /**
   * @deprecated Only used on the logout effect to clean up local storage devices
   */
  ACCOUNT = 'remberg-account',
  /**
   * @deprecated Only used on the logout effect to clean up local storage devices
   */
  USER_GROUPS = 'remberg-userGroups',
}

export enum EmptyValueEnum {
  TEXT = 'TEXT',
  DASH = 'DASH',
}

export enum DataViewEnum {
  CARDS = 'CARDS',
  TABLE = 'TABLE',
  BOARD = 'BOARD',
  SPLIT = 'SPLIT',
  MAP = 'MAP',
  HIERARCHY_TABLE = 'HIERARCHY_TABLE',
}

export enum NavlinkTypesEnum {
  DIVIDER = 'Divider',
  FILLER = 'Filler',
}

export enum AutoSavingStateEnum {
  SAVING = 'saving',
  DONE = 'done',
  CLEAR = 'clear',
}

export enum SharedDialogTypeEnum {
  GenericConfirmation = 'GenericConfirmation',
  PartListInputEdit = 'PartListInputEdit',
  GenericPdfPreview = 'GenericPdfPreview',
  ModalSimpleSelectDialog = 'ModalSimpleSelectDialog',
  PreviewServerInput = 'PreviewServerInput',
}

export enum DialogButtonConfig {
  ConfirmCancel = 'ConfirmCancel',
}

export enum StatusIndicatorSizeEnum {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum PriorityIndicatorSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum InlineEditInputSizeEnum {
  SMALL = 'small',
  LARGE = 'large',
}

export enum BreakpointEnum {
  XSMALL = 'isXSmallView',
  SMALL = 'isSmallView',
  MEDIUM = 'isMediumView',
  LARGE = 'isLargeView',
  XLARGE = 'isXLargeView',
}

export enum OverviewPageEnum {
  WORK_ORDER = 'workOrder',
  MAINTENANCE_PLAN = 'maintenancePlan',
  CASE = 'case',
  ASSET = 'asset',
  ASSET_TYPE = 'assetType',
  FORM_INSTANCE = 'formInstance',
  PARTS = 'parts',
  TASKS = 'tasks',
  ORGANIZATIONS = 'organizations',
  CONTACTS = 'contacts',
}

export enum FrontendFeatureFlagEnum {
  ORGANIZATIONS = 'organizations',
  USERS = 'users',
  SETTINGS = 'settings',
}

export enum RouteDataPropertyEnum {
  TRACKING_EVENT = 'trackingEvent',
  TRACKING_EVENT_URL_PARAM_MAP = 'trackingEventUrlParamMap',
  TRACKING_EVENT_QUERY_PARAM_MAP = 'trackingEventQueryParamMap',
  REQUEST_INTENDED_TRACKING_EVENT = 'requestIntendedTrackingEvent',
  SECONDARY_SIDEBAR_CATEGORY = 'secondarySidebarCategory',
  HAS_OWN_SECONDARY_SIDEBAR = 'hasOwnSecondarySidebar',
  HAS_OWN_TERTIARY_SIDEBAR = 'hasOwnTertiarySidebar',
  HAS_OWN_MAIN_CONTENT_PADDING = 'hasOwnMainContentPadding',
  IS_CREATION_PAGE = 'isCreationPage',
}

export enum SimulateIonicPlatformEnum {
  IOS = 'ios',
  ANDROID = 'android',
}

export enum StaticSelectOptionEnum {
  NONE = 'none',
  ANY = 'any',
}
