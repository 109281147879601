import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'materialIcons',
})
export class MaterialIconsPipe implements PipeTransform {
  /**
   * The iconString parameter used to be called userRight as this component was able to get the correct icons for all of the different user rights.
   * We've expanded this as the same Pipe is now also used for other icons. In general this is now just a string -> icon mapping.
   */
  public transform(iconString: string): string | undefined {
    switch (iconString) {
      case 'addAnswer':
      case 'asset_new':
      case 'userGroup_new':
      case 'userRole_new':
      case 'asset_import':
      case 'asset_addFiles':
      case 'assetType_new':
      case 'assetType_addFiles':
      case 'assetType_import':
      case 'asset_appendActivity':
      case 'createNotes':
      case 'task_new':
      case 'task_import':
      case 'ticket_new':
      case 'ticketTree_new':
      case 'order_new':
      case 'user_new':
      case 'apiKey_new':
      case 'formData_new':
      case 'qrCode_new':
      case 'form_new':
      case 'qrCode_order':
      case 'create':
      case 'createType':
      case 'createExternal':
      case 'createOwn':
      case 'createItem':
      case 'newType':
      case 'duplicateAllForms':
      case 'duplicateOwnForms':
        return 'add';
      case 'asset_updateLocation':
      case 'asset_updateCustomer':
      case 'asset_updateFiles':
      case 'asset_updateManufacturerPerson':
      case 'asset_updateCustomerPerson':
      case 'asset_updateSerialNumber':
      case 'asset_updateInventoryNumber':
      case 'asset_updateActivity':
      case 'asset_updateQRCode':
      case 'asset_updateUserGroups':
      case 'asset_update':
      case 'assetType_update':
      case 'assetType_updateFiles':
      case 'userGroup_update':
      case 'userRole_update':
      case 'task_update':
      case 'task_updateStatus':
      case 'task_updateName':
      case 'task_updateAssignee':
      case 'task_updateDueDate':
      case 'task_updateDescription':
      case 'task_updateEstimatedTime':
      case 'task_updateRecurringDuration':
      case 'ticket_update':
      case 'ticketTree_update':
      case 'ticketTree_updateCategory':
      case 'ticket_updateStatus':
      case 'ticket_updatePriority':
      case 'ticket_updateAssignee':
      case 'ticket_updateCategory':
      case 'ticket_updateDescription':
      case 'ticket_updateOEMPriority':
      case 'ticket_updateResultingProductStatus':
      case 'ticket_appendActivity':
      case 'order_update':
      case 'user_updateContact':
      case 'user_updateSettings':
      case 'user_updateRole':
      case 'user_updateGroups':
      case 'user_updatePassword':
      case 'user_update':
      case 'formData_update':
      case 'formData_updateAssignee':
      case 'qrCode_update':
      case 'form_update':
      case 'form_updateAssignee':
      case 'form_publish':
      case 'form_revert':
      case 'manageGeneralInformation':
      case 'updateLocation':
      case 'updateAttributes':
      case 'updateOwnContactPerson':
      case 'updateExtOrganization':
      case 'updateExtContactPerson':
      case 'updateType':
      case 'updateAssignee':
      case 'updateExternal':
      case 'updateOwn':
      case 'update':
      case 'updateItem':
      case 'manageAppearance':
      case 'manageUsers':
      case 'manageUserAccessRequests':
      case 'manageUserRoles':
      case 'manageUserGroups':
      case 'manageLocalization':
      case 'manageCases':
      case 'manageAppointments':
      case 'manageDataExport':
      case 'managePassword':
      case 'manageApiKeys':
      case 'updateScheduling':
      case 'updateAll':
        return 'edit';
      case 'asset_sortFiles':
      case 'assetType_sortFiles':
        return 'sort';
      case 'assetType_view':
      case 'asset_view':
      case 'asset_viewQRCode':
      case 'userGroup_view':
      case 'userRole_view':
      case 'task_view':
      case 'ticket_view':
      case 'ticketTree_view':
      case 'order_view':
      case 'user_view':
      case 'apiKey_view':
      case 'formData_view':
      case 'qrCode_view':
      case 'form_view':
      case 'form_viewAll':
      case 'viewOwn':
      case 'viewAll':
      case 'viewExternal':
      case 'viewDetails':
      case 'viewScheduling':
      case 'enableHierarchy':
      case 'showOnlyInAssetDetails':
      case 'viewInOverview':
      case 'viewInAssetDetail':
        return 'visibility';
      case 'asset_deleteFiles':
      case 'asset_delete':
      case 'asset_deleteActivity':
      case 'deleteNotesAll':
      case 'deleteNotesOwn':
      case 'assetType_delete':
      case 'assetType_deleteFiles':
      case 'userGroup_delete':
      case 'userRole_delete':
      case 'task_delete':
      case 'ticket_delete':
      case 'ticketTree_delete':
      case 'order_delete':
      case 'user_delete':
      case 'apiKey_delete':
      case 'formData_delete':
      case 'qrCode_delete':
      case 'form_delete':
      case 'form_delete_own':
      case 'deleteType':
      case 'deleteExternal':
      case 'deleteOwn':
      case 'delete':
      case 'deleteOwnNote':
      case 'deleteAllNote':
      case 'removeFromAsset':
      case 'removeAll':
      case 'removeOwn':
      case 'deleteItem':
      case 'deleteAll':
        return 'delete';
      case 'user_sendWelcome':
      case 'formData_send':
      case 'formData_finalize':
        return 'mail';
      case 'renameAtAsset':
      case 'renameAll':
      case 'renameOwn':
      case 'fill':
      case 'createNote':
        return 'text_format';
      case 'assetsAndTypes':
      case 'organizations':
      case 'users':
      case 'cases':
      case 'export':
      case 'upload':
        return 'cloud_upload';
      case 'send':
        return 'email';
      case 'assign':
      case 'assignAll':
      case 'assignOwn':
      case 'setPublic':
      case 'moveAll':
      case 'moveOwn':
        return 'import_export';
      case 'manage':
      case 'configure':
        return 'settings';
      case 'assets':
      case 'companies':
      case 'qrCodes':
      case 'serviceCases':
      case 'workOrders':
        return 'cloud_download';
      case 'toast-info':
        return 'info_outline';
      case 'toast-error':
        return 'report';
      case 'toast-success':
        return 'done';
      case 'toast-warning':
        return 'warning_amber';
      case 'move':
        return 'move_down';
      case 'enableAiChatWithX':
      case 'manageAiKnowledgeBase':
      case 'enableAi':
      case 'enableAiChatWithFile':
        return 'auto_fix_high';
    }
    return undefined;
  }
}
