import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { ApiResponse, OfflineService } from '@remberg/global/ui';
import { Part, PartsFilterEnum } from '@remberg/parts/common/main';
import { PartsAdvancedFilterConfigKeys, PartsAdvancedStaticFilter } from './parts-advanced-filters';
import { PartsFindManyOfflineQueryParams } from './parts-find-many-offline-query-params';

export interface PartsOfflineServiceInterface
  extends OfflineService<
    Part,
    AdvancedFilterQuery<PartsFilterEnum>,
    AdvancedFilter<PartsFilterEnum>
  > {
  getPartsWithCount(
    partsFindManyOfflineQueryParams: PartsFindManyOfflineQueryParams,
  ): Promise<ApiResponse<Part[]>>;

  getStaticFiltersString(staticFilters: PartsAdvancedStaticFilter[] | undefined): string[];

  getAdvancedFilterString(filter: AdvancedFilter<PartsAdvancedFilterConfigKeys>): string;
}

export const PARTS_OFFLINE_SERVICE = new InjectionToken<PartsOfflineServiceInterface>(
  'Token for injecting parts offline service without circular dependencies',
);
