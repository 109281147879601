import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { FormTemplateListResponse } from '@remberg/forms/common/dtos';
import { FormTemplate } from '@remberg/forms/common/main';
import { IsoLanguageCodesEnum } from '@remberg/global/common/core';
import { OfflineService } from '@remberg/global/ui';

export interface FormTemplateOfflineServiceInterface
  extends OfflineService<FormTemplate, AdvancedFilterQuery<never>, AdvancedFilter<never>> {
  getInstancePopulated(
    formTemplateId: string,
    populateFormTemplateConfig: boolean,
    populateFormTemplateVersion: boolean,
    populateInProgressCount?: boolean,
  ): Promise<FormTemplate>;

  getManyPopulatedWithCount(
    pageSize?: number,
    pageIndex?: number,
    searchQuery?: string,
    searchLocale?: IsoLanguageCodesEnum,
    populateInProgressCount?: boolean,
    populateFormTemplateVersion?: boolean,
  ): Promise<FormTemplateListResponse>;
}

export const FORM_TEMPLATE_OFFLINE_SERVICE =
  new InjectionToken<FormTemplateOfflineServiceInterface>(
    'Token for injecting form templates offline service without circular dependencies',
  );
