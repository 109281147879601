import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { LanguageCodeEnum } from '@remberg/global/common/core';

import { ApiResponse, OfflineService } from '@remberg/global/ui';
import { WorkOrderType } from '@remberg/work-orders-legacy/common/main';

export interface WorkOrderTypeOfflineServiceInterface
  extends OfflineService<WorkOrderType, AdvancedFilterQuery<never>, AdvancedFilter<never>> {
  getWorkOrderTypesWithCount(
    limit?: number,
    offset?: number,
    sortColumn?: string,
    sortDirection?: string,
    searchValue?: string,
    searchLocale?: LanguageCodeEnum,
  ): Promise<ApiResponse<WorkOrderType[]>>;
}

export const WORK_ORDER_TYPE_OFFLINE_SERVICE =
  new InjectionToken<WorkOrderTypeOfflineServiceInterface>(
    'Token for injecting work order type offline service without circular dependencies',
  );
