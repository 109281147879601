import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { TrackingCategoryEnum } from '@remberg/analytics/common/main';
import { AnalyticsTrackingServiceInterface, TRACKING_SERVICE } from '@remberg/analytics/ui/clients';
import { RouteDataPropertyEnum } from '@remberg/global/ui';

type TrackingParams = {
  redirectUrl: string;
};

export const trackingRedirectGuardFn =
  ({ redirectUrl }: TrackingParams): CanActivateFn =>
  ({ queryParams, data }: ActivatedRouteSnapshot, { url }: RouterStateSnapshot) => {
    const analyticsTrackingService: AnalyticsTrackingServiceInterface = inject(TRACKING_SERVICE);
    const router = inject(Router);
    const event = data[RouteDataPropertyEnum.TRACKING_EVENT];
    const urlWithoutQuery = url.split('?')[0];

    if (!event) {
      throw new Error('Route param "TRACKING_EVENT" is missing');
    }

    void analyticsTrackingService.trackEvent(event, {
      category: TrackingCategoryEnum.APPLICATION,
      url,
    });

    const finalSegments = [
      ...redirectUrl.split('/').filter(Boolean),
      ...urlWithoutQuery.split('/').filter(Boolean).slice(1),
    ];

    return router.navigate(finalSegments, { queryParams });
  };
