import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AllColors,
  COLOR_PALETTE_OPTIONS,
  COLOR_PALETTE_OPTIONS_10,
  COLOR_PALETTE_OPTIONS_20,
  ColorPaletteOption,
} from '@remberg/global/ui';

@Component({
  selector: 'app-color-swatch-picker',
  templateUrl: './color-swatch-picker.component.html',
  styleUrls: ['./color-swatch-picker.component.scss'],
})
export class ColorSwatchPickerComponent {
  @Input() public set selectedColor(value: ColorPaletteOption<AllColors> | undefined) {
    if (value) {
      this.selectedColorPalette = value;
    }
  }
  @Input() public set amountOfOptions(value: 10 | 20 | 30 | undefined) {
    const options = value ?? 10;
    this.colorPaletteOptions = this.COLOR_PALETTE_PER_OPTIONS[options];
    this.rowsPerOptions = options === 30 ? 3 : 2;
  }
  @Input() public height = '35px';

  @Output() public readonly colorChanged = new EventEmitter<ColorPaletteOption<AllColors>>();

  protected colorPaletteOptions: ColorPaletteOption<AllColors>[] = [];
  protected rowsPerOptions: number = 2;
  protected selectedColorPalette: ColorPaletteOption<AllColors> = COLOR_PALETTE_OPTIONS_10[0];

  private COLOR_PALETTE_PER_OPTIONS: Record<10 | 20 | 30, ColorPaletteOption<AllColors>[]> = {
    10: COLOR_PALETTE_OPTIONS_10,
    20: COLOR_PALETTE_OPTIONS_20,
    30: COLOR_PALETTE_OPTIONS,
  };

  protected selectColor(color: ColorPaletteOption<AllColors>): void {
    this.selectedColorPalette = color;
    this.colorChanged.next(color);
  }
}
