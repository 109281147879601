<div class="dialog-header-container">
  <div class="dialog-title-container">
    <h2 class="dialog-title-header" i18n="@@addFilters">Add filters</h2>
  </div>
  <div class="dialog-actions clear-all-button">
    <button
      mat-button
      *ngIf="(currentFilterFields$ | async)?.length"
      color="warn"
      i18n="@@clearAll"
      (click)="clearAll()"
      data-test-id="clear-all"
    >
      Clear all
    </button>
  </div>
</div>

<mat-dialog-content #filterContent class="mat-typography dialog-content" rbDialogContentMaxHeight>
  <p
    class="empty-list"
    *ngIf="!(currentFilterFields$ | async)?.length"
    data-test-id="no-filters"
    i18n="@@noFiltersSelected"
  >
    There are currently no filters selected.
  </p>

  <ng-container
    *ngFor="
      let filter of currentFilterFields$ | async;
      index as filterIndex;
      first as first;
      trackBy: trackEntriesBy
    "
  >
    <app-filter-desktop-dialog-entry
      [first]="first"
      [filterFieldOptions]="filterFieldOptions"
      [availableFilterFieldOptions]="(availableFilterFieldOptions$ | async) ?? undefined"
      [filter]="filter"
      (filterChange)="filterChange($event, filterIndex)"
      (remove)="removeFilter(filter.identifier)"
    >
    </app-filter-desktop-dialog-entry>
  </ng-container>
</mat-dialog-content>

<div class="dialog-footer-container filter-footer">
  <button
    mat-raised-button
    color="primary"
    data-test-id="add-filter"
    i18n="@@addFilter"
    (click)="addFilter()"
    [disabled]="addFilterDisabled$ | async"
  >
    + Add a filter
  </button>
  <span class="fill-remaining-space"></span>
  <div>
    <button mat-stroked-button [mat-dialog-close]="null" data-test-id="cancel" i18n="@@cancel">
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      data-test-id="apply"
      [disabled]="applyButtonDisabled$ | async"
      i18n="@@apply"
      [mat-dialog-close]="{ filters: getCurrentOutput() }"
      class="apply-button"
    >
      Apply
    </button>
  </div>
</div>
