export enum AssetsFilterEnum {
  ASSET_TYPE = 'assetType',
  RELATED_ORGANIZATION = 'relatedOrganization',
  RELATED_CONTACT = 'relatedContact',
  TENANT_OWNER_CONTACT = 'tenantOwnerContact',
  INVOLVED_CONTACT = 'involvedContact',
  ANCESTOR_ASSET = 'ancestorAsset',
  PARENT_ASSET = 'parentAsset',
  QR_CODE = 'qrCode',
  STATUS = 'status',
  CREATED = 'created',
  UPDATED = 'updated',
  RELATED_PART = 'relatedPart',
  USER_GROUP = 'userGroup',
  ZIP_POST_CODE = 'zipPostCode',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  CITY = 'city',
  BOUNDING_BOX = 'boundingBox',
}
