import { MultiLanguageText } from '@remberg/global/common/core';
import { ConfigWithPrefilling } from '../../../automation';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TaskListInputField
  extends Field<FormFieldTypesEnum.TASK_LIST_INPUT, TaskListInputFieldConfig> {}

export interface TaskListInputFieldConfig
  extends ConfigWithPrefilling<TaskListInputPrefillTargetsEnum> {
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  requiredAmountOfEntries?: number;
  label: {
    text: MultiLanguageText;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  fields?: {
    checkboxBooleanInput?: {
      checkedByDefault: boolean;
      pdfHide?: boolean;
    };
    titleSingleLineTextInput?: {
      required?: boolean;
      pdfHide?: boolean;
    };
    commentMultiLineTextInput?: {
      disabled?: boolean;
      required?: boolean;
      pdfHide?: boolean;
    };
    highPrioBooleanInput?: {
      disabled?: boolean;
      pdfHide?: boolean;
    };
    notNecessaryBooleanInput?: {
      disabled?: boolean;
      pdfHide?: boolean;
    };
  };
}

export enum TaskListInputPrefillTargetsEnum {
  ENTRIES = 'entries',
}

export interface TaskListInputFieldData {
  entries: TaskListItem[];
}

export interface TaskListItem {
  title?: string;
  comment?: string;
  done?: boolean;
  necessary?: boolean;
  highPriority?: boolean;
}
