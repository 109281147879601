export enum WorkOrderPopulateEnum {
  TYPE = 'type',
  STATUS = 'status',
  PARENT_WORK_ORDER = 'parentWorkOrder',
  RELATED_CONTACTS = 'relatedContacts',
  RESPONSIBLE_CONTACT = 'responsibleContact',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  RESPONSIBLE_GROUP = 'responsibleGroup',
  RELATED_ASSETS = 'relatedAssets',
  RELATED_ORGANIZATION = 'relatedOrganization',
  RELATED_CASE = 'relatedCase',
  CUSTOM_PROPERTIES = 'customProperties',
  PLANNING = 'planning',
}
