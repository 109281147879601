import { Assert, TwoWayCompareStringType } from '@remberg/global/common/core';
import { OrganizationPermissions } from '@remberg/users/common/main';

export enum OrganizationsPermissionEnum {
  CREATE_EXTERNAL = 'organizations_createExternal',
  VIEW_EXTERNAL = 'organizations_viewExternal',
  UPDATE_EXTERNAL = 'organizations_updateExternal',
  UPDATE_OWN = 'organizations_updateOwn',
  DELETE_EXTERNAL = 'organizations_deleteExternal',
}

export type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `organizations_${keyof OrganizationPermissions}`,
    `${OrganizationsPermissionEnum}`
  >
>;
