import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SortDirectionEnum } from '@remberg/global/common/core';
import {
  SortField,
  SortingDialogData,
  SortingDialogResult,
  assertDefined,
} from '@remberg/global/ui';
import { isDefined } from 'class-validator';
import { filter, firstValueFrom } from 'rxjs';
import { getDialogConfigNextToTarget } from '../../../dialogs/dialogs';
import { GlobalSelectors, RootGlobalState } from '../../../store';
import { SortingDesktopDialogComponent } from '../desktop-dialog/sorting-desktop-dialog.component';
import { SortingMobileDialogComponent } from '../mobile-dialog/sorting-mobile-dialog.component';
@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortButtonComponent<T extends string> {
  @Input() public sortField?: T;
  @Input() public sortDirection?: SortDirectionEnum;
  @Input() public sortFields?: SortField<T>;

  @Output() public sortChanged = new EventEmitter<SortingDialogResult<T>>();

  constructor(
    private readonly store: Store<RootGlobalState>,
    private readonly matDialog: MatDialog,
  ) {}

  protected async onSortClicked(): Promise<void> {
    assertDefined(this.sortFields, 'SortFields are not defined');

    const isXSmallView = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsXSmallView),
    );
    const dialogData: SortingDialogData<T> = {
      currentSortDirection: this.sortDirection,
      currentSortField: this.sortField,
      sortFields: this.sortFields,
    };

    const dialogRef = isXSmallView
      ? this.openOnMobileView(dialogData)
      : this.openOnDesktopView(dialogData);

    dialogRef
      .afterClosed()
      .pipe(filter(isDefined))
      .subscribe((result) => {
        this.sortChanged.emit(result);
      });
  }

  private openOnMobileView(
    data: SortingDialogData<T>,
  ): MatDialogRef<SortingMobileDialogComponent, SortingDialogResult<T>> {
    return this.matDialog.open<
      SortingMobileDialogComponent,
      SortingDialogData<T>,
      SortingDialogResult<T>
    >(SortingMobileDialogComponent, {
      panelClass: 'mobile-device-full-screen',
      disableClose: false,
      autoFocus: false,
      data,
    });
  }

  private openOnDesktopView(
    dialogData: SortingDialogData<T>,
  ): MatDialogRef<SortingDesktopDialogComponent, SortingDialogResult<T>> {
    const dialogConfig = getDialogConfigNextToTarget(dialogData, 'sortButton');
    return this.matDialog.open<
      SortingDesktopDialogComponent,
      { data: SortingDialogData<T> },
      SortingDialogResult<T>
    >(SortingDesktopDialogComponent, dialogConfig);
  }
}
