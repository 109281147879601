import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { LanguageCodeEnum } from '@remberg/global/common/core';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
} from '@remberg/global/ui';
import { WorkOrderType } from '@remberg/work-orders-legacy/common/main';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  WORK_ORDER_TYPE_OFFLINE_SERVICE,
  WorkOrderTypeOfflineServiceInterface,
} from '../definitions';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderTypeService {
  public readonly workOrdersUrl = `${API_URL_PLACEHOLDER}/workorders`;

  constructor(
    private http: HttpClient,
    private logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(WORK_ORDER_TYPE_OFFLINE_SERVICE)
    private workOrderTypeOfflineService: WorkOrderTypeOfflineServiceInterface,
  ) {}

  // WorkOrder Types
  getWorkOrderTypesBase(
    tenantId?: string,
    pageSize?: number,
    pageIndex?: number,
    sortDirection?: string,
    sortField?: string,
    searchString: string = '',
    searchLocale?: LanguageCodeEnum,
  ): Observable<any> {
    const url = `${this.workOrdersUrl}/types`;
    let params = new HttpParams();
    if (tenantId !== undefined) {
      params = params.set('tenantId', tenantId);
    }
    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchString && searchLocale) {
      params = params.set('search', searchString);
      params = params.set('searchLocale', searchLocale);
    }
    return this.http.get<any>(url, { params: params });
  }

  getWorkOrderTypesWithCount(
    tenantId?: string,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchString: string = '',
    searchLocale?: LanguageCodeEnum,
  ): Observable<ApiResponse<WorkOrderType[]>> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online work order type instances request...');
      return this.getWorkOrderTypesBase(
        tenantId,
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        searchString,
        searchLocale,
      ).pipe(map((res) => new ApiResponse<WorkOrderType[]>(res.data, res.count)));
    } else {
      this.logger.debug()('Offline work order type instances fallback...');
      return from(
        this.workOrderTypeOfflineService.getWorkOrderTypesWithCount(
          pageSize,
          pageIndex,
          sortField,
          searchString,
          searchLocale,
        ),
      );
    }
  }

  patchWorkOrderType(payload: WorkOrderType): Observable<WorkOrderType> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    const url = `${this.workOrdersUrl}/types/${payload._id}`;
    return this.http.patch<any>(url, payload, httpOptions).pipe(map((res) => res.data));
  }

  deleteWorkOrderType(wOrder: WorkOrderType | string): Observable<WorkOrderType> {
    const wOrder_id = typeof wOrder === 'string' ? wOrder : wOrder._id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.workOrdersUrl}/types/${wOrder_id}`;
    return this.http.delete<any>(url, httpOptions).pipe(map((res) => res.data));
  }

  addWorkOrderType(wOrder: WorkOrderType): Observable<WorkOrderType> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    return this.http
      .post<any>(this.workOrdersUrl + '/types', wOrder, httpOptions)
      .pipe(map((res) => res.data));
  }

  updateWorkOrderTypesSortOrder(sortOrder: string[], tenantId?: string): Observable<string[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (tenantId !== undefined) {
      httpOptions.params = httpOptions.params.set('tenantId', tenantId);
    }
    return this.http
      .post<any>(this.workOrdersUrl + '/types/sorting', { sortOrder: sortOrder }, httpOptions)
      .pipe(map((res) => res.data));
  }
}
