import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ask-ai-button',
  templateUrl: './ask-ai-button.component.html',
  styleUrls: ['./ask-ai-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AskAiButtonComponent {
  protected translation: string = $localize`:@@askAi:Ask AI`;

  constructor(private readonly router: Router) {}

  protected handleClick(): void {
    this.router.navigate(['copilot']);
  }
}
