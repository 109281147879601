export enum AssetsSortFieldsEnum {
  SERIAL_NUMBER = 'serialNumber',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ASSET_TYPE_LABEL = 'assetTypeLabel',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  CITY = 'city',
  ZIP_POST_CODE = 'zipPostCode',
  STREET = 'street',
}
