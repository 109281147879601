import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';

import { OfflineService } from '@remberg/global/ui';
import { WorkOrderType } from '@remberg/work-orders/common/base';
import {
  WorkOrderTypeFindManyByIdsBody,
  WorkOrderTypeFindManyQuery,
  WorkOrderTypeFindManyResponse,
} from '@remberg/work-orders/common/main';
import { Observable } from 'rxjs';

export interface WorkOrderType2OfflineServiceInterface
  extends OfflineService<WorkOrderType, AdvancedFilterQuery<never>, AdvancedFilter<never>> {
  findMany(options?: WorkOrderTypeFindManyQuery): Observable<WorkOrderTypeFindManyResponse>;
  findManyByIds(body?: WorkOrderTypeFindManyByIdsBody): Observable<WorkOrderType[]>;
}

export const WORK_ORDER_TYPE_2_OFFLINE_SERVICE =
  new InjectionToken<WorkOrderType2OfflineServiceInterface>(
    'Token for injecting work order 2 type offline service without circular dependencies',
  );
