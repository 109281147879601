import { Locale } from 'date-fns/locale';
import { LanguageCodesDateFns, MultiLanguageText, ShortLanguageCodeMapper } from '../definitions';
import { IsoLanguageCodesEnum, LanguageCodeEnum } from '../enums';

export function getLanguageCodesDateFns(languageCode?: string): Locale {
  const code = getDefaultLanguageCodeEnumForString(languageCode);

  return LanguageCodesDateFns[code];
}

export function getDefaultLanguageCodeEnumForString(languageCode?: string): LanguageCodeEnum {
  const twoLetterCultureCode = (languageCode ?? '').split(
    '-',
  )[0] as keyof typeof ShortLanguageCodeMapper;

  return ShortLanguageCodeMapper[twoLetterCultureCode] ?? LanguageCodeEnum.EN_US;
}

/**
 *
 * @param languageCodeShort This is the language short code returned by the LOCALE_ID injectable in angular.
 * @returns IsoLanguageCodesEnum
 */
export function getIsoLanguageCodeForShortString(languageCodeShort: string): IsoLanguageCodesEnum {
  return getIsoLanguageCodeFromLanguageCode(getDefaultLanguageCodeEnumForString(languageCodeShort));
}

/**
 * Takes an input like 'en-US' - and return only the first part of the string. E.g. 'en'.
 * @param languageCode The LanguageCodeEnum you wish to convert.
 * @returns string
 */
export function getShortLanguageCode(languageCode?: LanguageCodeEnum): string {
  if (languageCode) {
    return languageCode.split('-')[0] as string;
  } else {
    throw new Error('The languageCode parameter cannot be undefined.');
  }
}

/**
 * Takes an input like 'en-US' - and return only the second part of the string. E.g. 'US'.
 * @param languageCode The LanguageCodeEnum you wish to convert.
 */
export function getShortCountryCode(languageCode: LanguageCodeEnum): string {
  if (languageCode) {
    return languageCode.split('-')[1] as string;
  } else {
    throw new Error('The languageCode parameter cannot be undefined.');
  }
}

export function getIsoLanguageCodeFromLanguageCode(
  languageCode?: LanguageCodeEnum,
): IsoLanguageCodesEnum {
  if (
    Object.values(IsoLanguageCodesEnum).indexOf(
      getShortLanguageCode(languageCode) as IsoLanguageCodesEnum,
    ) > -1
  ) {
    return getShortLanguageCode(languageCode) as IsoLanguageCodesEnum;
  }
  throw new Error('The languageCode parameter cannot be found in the IsoLanguageCodesEnum.');
}

export function getIsoLanguageCodesFromLanguageCodes(
  languageCodes?: LanguageCodeEnum[],
): IsoLanguageCodesEnum[] {
  const isoLanguageList: IsoLanguageCodesEnum[] = [];
  for (const languageCode of languageCodes ?? []) {
    isoLanguageList.push(getIsoLanguageCodeFromLanguageCode(languageCode));
  }
  return isoLanguageList;
}

export function getMultiLanguageTextValue(
  value: MultiLanguageText,
  lang: IsoLanguageCodesEnum,
): string {
  const valueInLang = value[lang];
  if (valueInLang?.trim()) {
    return valueInLang;
  }

  const valueInEnglish = value[IsoLanguageCodesEnum.EN];
  if (valueInEnglish?.trim()) {
    return valueInEnglish;
  }

  const valueInGerman = value[IsoLanguageCodesEnum.DE];
  if (valueInGerman?.trim()) {
    return valueInGerman;
  }

  return Object.values(value)[0] ?? 'Missing translation';
}
