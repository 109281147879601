export const SUPPORTED_IMAGE_FORMATS = [
  'image/heic',
  'image/heif',
  'image/avif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/raw',
  // Our current setup seems to have problems with some variations of tif(f) files: "Old-style JPEG compression support is not configured"
  // We need investigate why and if we can fix it but are disabling rotation for those files for now.
  // 'image/tiff',
  // 'image/tif',
  'image/webp',
  'image/jp2',
  'image/jpx',
  'image/j2k',
  'image/j2c',
  'image/jp2',
];

export const SUPPORTED_IMAGE_FORMATS_REGEX = new RegExp(
  /^image\/(heic|heif|avif|jpg|jpeg|png|raw|bmp|webp|jp2|jpx|j2k|j2c|jp2)$/,
);
