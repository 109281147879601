import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PriorityIndicatorSizeEnum } from '@remberg/global/ui';

@Component({
  selector: 'app-priority-indicator',
  templateUrl: './priority-indicator.component.html',
  styleUrls: ['./priority-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityIndicatorComponent {
  /** label: actual string to show inside indiator */
  @Input() label?: string;

  /** priorityClass: use for predefined styles */
  @Input() priorityClass?: string = '';

  /** color: style string to overwrite color */
  @Input() iconColor: string = 'var(--iconColor)';

  /** textColor: style string to overwrite text color */
  @Input() textColor?: string;

  /** icon: use Material icon string */
  @Input() icon?: string;

  /** colorIndicator: instead of an icon, you can also show only a colored circle */
  @Input() colorIndicator?: string;

  /** size: the enum that sets height, font-size and icon-size (SMALL, MEDIUM, LARGE) */
  @Input() size?: PriorityIndicatorSizeEnum;

  /** maxWidth: limit width */
  @Input() maxWidth?: string;

  /** displayFlex: allows to set flex status e.g. for mat-option usage */
  @Input() displayFlex: boolean = false;

  /** asList: use when want to show several after each other e.g. with mat-chip-list */
  @Input() asList: boolean = false;

  @Input() fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

  @Input() dataTestId? = 'priority-indicator';

  PriorityIndicatorSizeEnum = PriorityIndicatorSizeEnum;
}
