import { MatDialog } from '@angular/material/dialog';
import {
  Observable,
  OperatorFunction,
  filter,
  firstValueFrom,
  map,
  mergeMap,
  of,
  withLatestFrom,
} from 'rxjs';
import { SharedDialogConfig } from '../definitions';
import { getConfirmationCancelMoveAway } from '../helpers';

/**
 * Specialized operator function based on filterCannotDeactivateUnlessDiscarded
 * for handling blocking actions based on user confirmation
 *
 * @param matDialog Reference to MatDialog service
 * @param dialogData Dialog config object for manipulating appearance and text of the
 * constituent elements
 * @param canDeactivate$ optional bypass for the warning
 */
export function filterUnlessConfirmed<T>(
  matDialog: MatDialog,
  dialogData?: Omit<SharedDialogConfig, 'dialogId'> & { panelClasses?: string[] },
  canDeactivate$ = of(false),
): OperatorFunction<T, T> {
  return (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      withLatestFrom(canDeactivate$),
      mergeMap(async ([payload, canDeactivate]): Promise<[T, boolean]> => {
        if (!canDeactivate) {
          const dialogRef = getConfirmationCancelMoveAway(matDialog, dialogData);
          const result = await firstValueFrom(dialogRef.afterClosed());
          return [payload, !!result?.isConfirmed];
        }
        return [payload, true];
      }),
      filter(([, proceed]) => !!proceed),
      map(([payload]) => payload),
    );
}
