import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LogService,
  MustMatch,
  PASSWORD_ERROR_MESSAGES,
  PASSWORD_VALIDATORS_REQUIRED,
} from '@remberg/global/ui';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { ToastrService } from 'ngx-toastr';
import { Subscription, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  animations: [fadeInOnEnterAnimation()],
  styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnInit, OnDestroy {
  protected submittedForm = false;
  protected sent = false;
  protected tokenIsValid = true;
  protected tokenCheckSent = false;
  protected changeEmail = false;
  protected token?: string;

  private readonly subscriptions = new Subscription();

  protected activationFormGroup = new FormGroup(
    {
      email: new FormControl<string>({
        value: '',
        disabled: true,
      }),
      password: new FormControl<string>('', {
        validators: PASSWORD_VALIDATORS_REQUIRED,
        nonNullable: true,
      }),
      passwordConfirm: new FormControl<string>('', {
        validators: Validators.required,
        nonNullable: true,
      }),
    },
    {
      validators: [MustMatch('password', 'passwordConfirm')],
    },
  );

  protected passwordErrorMessages = PASSWORD_ERROR_MESSAGES;

  constructor(
    private readonly toastr: ToastrService,
    private readonly rembergUsersService: RembergUsersService,
    private readonly router: Router,
    private readonly logger: LogService,
    private readonly route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.route.queryParams.subscribe(async (params) => {
        const token = params['token'];
        if (token) {
          this.token = token;
          try {
            const result = await firstValueFrom(
              this.rembergUsersService.checkActivationToken({ token }),
            );
            this.tokenIsValid = true;
            this.logger.debug()('E-Mail: ' + result.email);
            this.activationFormGroup.patchValue({
              email: result.email,
            });
          } catch (error) {
            if ((error as HttpErrorResponse).status === HttpStatusCode.Unauthorized) {
              this.toastr.error(
                $localize`:@@activationLinkHasExpiredDotPleaseContactYourAdmin:This activation link has expired. Please contact your admin.`,
                $localize`:@@activationError:Activation Error`,
              );
            } else {
              this.toastr.error(
                $localize`:@@activationLinkIsNotValidDotPleaseContactYourAdmin:This activation link is not valid. Please contact your admin.`,
                $localize`:@@activationError:Activation Error`,
              );
            }
            this.logger.error()('Invalid token.');
            this.router.navigateByUrl('/');
          }
        } else {
          this.logger.error()('Missing token');
          this.router.navigateByUrl('/');
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected async activate(): Promise<void> {
    if (!this.token) return;

    this.sent = true;
    try {
      await firstValueFrom(
        this.rembergUsersService.activateUser({
          token: this.token,
          password: this.activationFormGroup.controls.password.value,
        }),
      );
      this.logger.debug()('Activation successful.');
      this.toastr.success(
        $localize`:@@userWasSuccessfullyActivated:User was successfully activated.`,
        $localize`:@@success:Success`,
      );
    } catch (error) {
      this.logger.error()('There was an error while activation your account.');
      this.toastr.error(
        $localize`:@@userActivationFailed:User activation failed`,
        $localize`:@@error:Error`,
      );
    }

    this.router.navigateByUrl('/login');
  }
}
