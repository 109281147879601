/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createReducer, on } from '@ngrx/store';
import { createSharedTableActionsFor } from './create-shared-table-actions-for';
import { SharedTablesState } from './definitions';

export const createSharedTableReducerFor = <T, F, C>(
  actions: ReturnType<typeof createSharedTableActionsFor<T, F, C>>,
) =>
  createReducer<SharedTablesState<T, F, C>>(
    // initial state
    {},
    on(
      actions.initialize,
      (state, action): SharedTablesState<T, F, C> => ({
        ...state,
        [action.id]: {
          loading: true,
          initialized: true,
          params: action.params,
          config: action.config,
        },
      }),
    ),
    on(
      actions.load,
      (state, action): SharedTablesState<T, F, C> => ({
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: true,
          items: [],
          count: 0,
          params: action.params,
        },
      }),
    ),
    on(
      actions.loadSuccess,
      (state, action): SharedTablesState<T, F, C> => ({
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: false,
          items: action.items,
          count: action.count,
          params: action.params,
        },
      }),
    ),
    on(
      actions.loadError,
      (state, action): SharedTablesState<T, F, C> => ({
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: false,
          error: action.error,
        },
      }),
    ),
    on(actions.updateParams, (state, action): SharedTablesState<T, F, C> => {
      const oldParams = state[action.id]?.params || {};
      const params = action.params ? ({ ...oldParams, ...action.params } as F) : undefined;

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          params,
        },
      };
    }),
    on(
      actions.selectOne,
      (state, action): SharedTablesState<T, F, C> => ({
        ...state,
        [action.id]: {
          ...state[action.id],
          selectedItems: {
            [action.itemId]: true,
          },
        },
      }),
    ),
    on(
      actions.clearSelectedItems,
      (state, action): SharedTablesState<T, F, C> => ({
        ...state,
        [action.id]: {
          ...state[action.id],
          selectedItems: {},
        },
      }),
    ),
  );
