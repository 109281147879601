import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CustomProperty,
  CustomPropertyCreateOneBody,
  CustomPropertyCreateOneResponse,
  CustomPropertyFindAllQuery,
  CustomPropertyModelEnum,
  CustomPropertyUpdateOneBody,
} from '@remberg/custom-properties/common/main';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomPropertyService {
  private readonly CUSTOM_PROPERTIES_URL = `${API_URL_PLACEHOLDER}/customproperties/v1`;

  constructor(private readonly http: HttpClient) {}

  public findAll(
    model: CustomPropertyModelEnum,
    options?: CustomPropertyFindAllQuery,
  ): Observable<CustomProperty[]> {
    let params = new HttpParams();

    if (options?.includeDisabled !== undefined) {
      params = params.set('includeDisabled', options.includeDisabled);
    }

    return this.http.get<CustomProperty[]>(`${this.CUSTOM_PROPERTIES_URL}/all/${model}`, {
      params,
    });
  }

  public deleteOne(id: string): Observable<void> {
    return this.http.delete<void>(`${this.CUSTOM_PROPERTIES_URL}/${id}`);
  }

  public create(body: CustomPropertyCreateOneBody): Observable<CustomPropertyCreateOneResponse> {
    return this.http.post<CustomPropertyCreateOneResponse>(this.CUSTOM_PROPERTIES_URL, body);
  }

  public update(id: string, body: CustomPropertyUpdateOneBody): Observable<CustomProperty> {
    return this.http.patch<CustomProperty>(`${this.CUSTOM_PROPERTIES_URL}/${id}`, body);
  }
}
