import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  public override shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    return (
      super.shouldReuseRoute(future, curr) &&
      (!!future.firstChild || !future.root.firstChild?.data.forceRerender)
    );
  }
}
