import { ContactCompatibility } from '@remberg/crm/common/main';
import { PlatformFile } from '@remberg/files/common/main';
import { WorkOrder } from './work-order';

export interface WorkOrderActivity {
  kind: WorkOrderActivityType;
  timeStamp: Date;
  creator?: ContactCompatibility | string;
  propertyLabel?: string;
  tenantId: string;
}

export interface WorkOrderChangeActivity extends WorkOrderActivity {
  propertyName: string;
  oldValue: any;
  newValue: any;
  propertyLabel?: string;
}

export interface WorkOrderNoteActivity extends WorkOrderActivity {
  files: PlatformFile[] | string[];
  text?: string;
  html?: string;
}

export interface WorkOrderCreateActivity extends WorkOrderActivity {
  files: File[] | string[];
  initialValues: WorkOrder;
}

export enum WorkOrderActivityType {
  'Note' = 'note',
  'Change' = 'change',
  'Creation' = 'creation',
}
