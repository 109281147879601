import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { IsoLanguageCodesEnum, MultiLanguageText } from '@remberg/global/common/core';
import assert from 'assert';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { MaterialModule } from '../../material.module';
import { TranslateIsoLanguageCodesEnumPipe } from '../../pipes/translate-iso-language-codes-enum.pipe';
import { MultiLanguageEditDialogComponent } from './multi-language-edit-dialog/multi-language-edit-dialog.component';
import { MultiLanguageEditData } from './multi-language-edit-dialog/multi-language-edit-dialog.definitions';

@Component({
  selector: 'app-multi-language-input',
  templateUrl: './multi-language-input.component.html',
  styleUrls: ['./multi-language-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateIsoLanguageCodesEnumPipe,

    MultiLanguageEditDialogComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiLanguageInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MultiLanguageInputComponent,
      multi: true,
    },
  ],
})
export class MultiLanguageInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input() public label = $localize`:@@multiLanguageInput:Multi Language Input`;
  @Input() public currentLanguage?: IsoLanguageCodesEnum;
  @Input() public languages?: IsoLanguageCodesEnum[];
  @Input() public hideTranslationsButton?: boolean;
  @Output() public readonly focusOut = new EventEmitter<void>();

  protected translations: { editTranslations: string } = {
    editTranslations: $localize`:@@editTranslations:Edit Translations`,
  };

  protected readonly formGroup = new FormGroup({
    singleLanguageLabel: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    multiLanguageLabel: new FormControl<MultiLanguageText | undefined>(undefined, {
      nonNullable: true,
    }),
  });

  private readonly subscription = new Subscription();
  private readonly touched$ = new Subject<void>();

  constructor(private readonly matDialog: MatDialog) {}

  public ngOnInit(): void {
    this.subscription.add(this.formGroup.valueChanges.subscribe(() => this.touched$.next()));

    this.subscription.add(
      this.formGroup.controls.singleLanguageLabel.valueChanges.subscribe((value) => {
        assert(this.currentLanguage, 'currentLanguage must be defined');
        this.formGroup.patchValue({
          multiLanguageLabel: {
            ...this.formGroup.getRawValue().multiLanguageLabel,
            [this.currentLanguage]: value,
          },
        });
      }),
    );
  }

  public validate(): ValidationErrors | null {
    return this.formGroup.controls.singleLanguageLabel.errors ?? null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.subscription.add(this.formGroup.valueChanges.subscribe(fn));
  }

  public writeValue(value: MultiLanguageText | undefined): void {
    assert(this.currentLanguage, 'currentLanguage must be defined');

    this.formGroup.reset(
      {
        singleLanguageLabel: value?.[this.currentLanguage],
        multiLanguageLabel: value,
      },
      { emitEvent: false },
    );
  }

  public registerOnChange(onChangeFn: (value: MultiLanguageText | undefined) => void): void {
    this.subscription.add(
      this.formGroup.controls.multiLanguageLabel.valueChanges.subscribe(onChangeFn),
    );
  }

  public registerOnTouched(onTouchedFn: () => void): void {
    this.subscription.add(this.touched$.subscribe(onTouchedFn));
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async openTranslationsDialog(): Promise<void> {
    assert(this.currentLanguage, 'currentLanguage must be defined');
    assert(this.languages, 'availableLanguages must be defined');

    const dialogRef = this.matDialog.open<
      MultiLanguageEditDialogComponent,
      MultiLanguageEditData,
      { values: MultiLanguageText }
    >(MultiLanguageEditDialogComponent, {
      autoFocus: false,
      hasBackdrop: true,
      restoreFocus: false,
      panelClass: ['rb-dialog-sm', 'rb-dialog-multi-language-edit'],
      data: {
        label: this.label,
        values: this.formGroup.getRawValue().multiLanguageLabel ?? {},
        currentLanguage: this.currentLanguage,
        availableLanguages: this.languages,
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result?.values) {
      this.formGroup.patchValue({
        multiLanguageLabel: result.values,
        singleLanguageLabel: result.values[this.currentLanguage],
      });
      this.formGroup.markAsDirty();
    }
  }
}
