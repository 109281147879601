import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NotificationsPopupComponent } from '@app/core/interface/notifications-popup/notifications-popup.component';
import { Store } from '@ngrx/store';
import { Notification } from '@remberg/notifications/common/main';
import {
  DialogOptions,
  DialogService,
  GlobalSelectors,
  LayoutService,
  NotificationService,
  RootGlobalState,
  ThemeService,
} from '@remberg/ui-core/core';
import { bounceAnimation } from 'angular-animations';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [bounceAnimation({ duration: 1000, delay: 100 })],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy {
  protected translation: string = $localize`:@@notifications:Notifications`;
  protected notifications: [{ expanded: boolean; target: string; notifications: Notification[] }?] =
    [];
  protected bounceNotificationToggle?: boolean;
  protected numNotifications: number = 0;
  protected displayedNotificationsCount: string = '';
  private NOTIFICATIONS_MAX_NUM_DISPLAY = 99;
  private oldNumNotifications: number = 0;

  // Remberg data
  private destroy$ = new Subject<void>();
  @ViewChild(MatMenuTrigger) protected notificationCenterTrigger?: MatMenuTrigger;

  constructor(
    public readonly layout: LayoutService,
    private readonly notificationService: NotificationService,
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: DialogService,
    private readonly store: Store<RootGlobalState>,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.setupNotificationStream();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(); // trigger the unsubscribe
    this.destroy$.complete();
    this.notificationService?.cancelNotificationStream();
  }

  private setupNotificationStream(): void {
    this.notificationService
      .setUpNotificationStream()
      .pipe(
        withLatestFrom(this.store.select(GlobalSelectors.selectTenant)),
        takeUntil(this.destroy$),
      )
      .subscribe(([data, tenant]) => {
        this.notifications = [];
        for (const receivedSingleNotification of data) {
          if (
            this.layout.isIonic &&
            !this.notificationService.showNotificationOnIonic(receivedSingleNotification)
          ) {
            // Do nothing
          } else {
            let addedToGroup = false;
            for (const notGroup of this.notifications) {
              if (notGroup && notGroup.target === receivedSingleNotification.target) {
                if (notGroup.notifications) {
                  notGroup.notifications.push(receivedSingleNotification);
                } else {
                  notGroup.notifications = [receivedSingleNotification];
                }
                addedToGroup = true;
              }
            }
            if (!addedToGroup) {
              this.notifications.push({
                expanded: false,
                target: receivedSingleNotification.target as string,
                notifications: [receivedSingleNotification],
              });
            }
          }
        }
        for (const notGroup of this.notifications) {
          if (notGroup?.notifications && notGroup.notifications.length > 1) {
            notGroup.notifications.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));
          }
        }
        // only bounce if notifications were added
        if (this.oldNumNotifications < data.length) {
          this.bounceNotificationToggle = !this.bounceNotificationToggle;
        }
        this.oldNumNotifications = data.length;
        this.numNotifications = this.getNotificationSum();
        if (this.numNotifications === 0) {
          this.displayedNotificationsCount = '';
        } else if (this.numNotifications <= this.NOTIFICATIONS_MAX_NUM_DISPLAY) {
          this.displayedNotificationsCount = `${this.numNotifications}`;
        } else {
          this.displayedNotificationsCount = `${this.NOTIFICATIONS_MAX_NUM_DISPLAY}+`;
        }

        const titleString = tenant?.theme.workspaceName ?? '';
        this.themeService.setTitle({
          workspaceName: titleString,
          displayedNotificationsCount: this.displayedNotificationsCount,
        });

        this.cdr.markForCheck();
      });

    this.store
      .select(GlobalSelectors.selectTheme)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  private getNotificationSum(): number {
    let result = 0;
    for (const notGroup of this.notifications) {
      if (notGroup?.notifications) {
        result =
          result + notGroup.notifications.filter((notification) => !notification.isRead).length;
      }
    }
    return result;
  }

  protected openNotificationModal(): void {
    const dialogOpts: DialogOptions<NotificationsPopupComponent> = {
      childComponent: NotificationsPopupComponent,
      modalKeyboardClose: true,
      isModal: true,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleFullscreen: true,
          styleNoMargin: true,
          styleHeaderDivider: true,
          overflowXHidden: true,
          overflowYHidden: true,
        },
        factoryInput: [{ notifications: this.notifications }, { notificationCenterTrigger: null }],
      },
    };
    this.dialog.showDialogOrModal<NotificationsPopupComponent>(dialogOpts, true);
  }
}
