import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationRaw } from '@remberg/crm/common/main';
import {
  OrganizationOfflineServiceInterface,
  OrganizationsFindManyWithCountCompatibilityParams,
} from '@remberg/crm/ui/clients';
import {
  ApiResponse,
  LogService,
  SQLQueryParams,
  SyncDataTypesEnum,
  stringToSQLSortDirection,
} from '@remberg/global/ui';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

enum ColumnNamesEnum {
  NAME = 'name',
  EXTERNAL_REFERENCE = 'externalReference',
  TENANT_ID = 'tenantId', // Never used for sort or filter -> could be removed
}

const params: SQLQueryParams<ColumnNamesEnum> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.ORGANIZATIONS,
  columns: {
    [ColumnNamesEnum.NAME]: {
      type: 'TEXT',
      valueFunction: (val: OrganizationRaw) => val?.crmData?.name?.trim(),
      sortNoCase: true,
    },
    [ColumnNamesEnum.EXTERNAL_REFERENCE]: {
      type: 'TEXT',
      valueFunction: (val: OrganizationRaw) => val?.crmData.externalReference?.trim(),
      sortNoCase: true,
    },
    [ColumnNamesEnum.TENANT_ID]: {
      type: 'TEXT',
      valueFunction: (val: OrganizationRaw) => val?.tenantId,
    },
  },
};

@Injectable()
export class OrganizationOfflineService
  extends BaseOfflineService<OrganizationRaw, never>
  implements OrganizationOfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, params, logger, store);
  }

  public async getManyWithCount(
    options: OrganizationsFindManyWithCountCompatibilityParams,
  ): Promise<ApiResponse<OrganizationRaw[]>> {
    const filterStrings = [];

    if (options.searchQuery) {
      filterStrings.push(
        `(${params.tableName}.${ColumnNamesEnum.NAME} LIKE '%${options.searchQuery}%'` +
          ` OR ${params.tableName}.${ColumnNamesEnum.EXTERNAL_REFERENCE} LIKE '%${options.searchQuery}%')`,
      );
    }

    const sqlSortDirection = stringToSQLSortDirection(options.sortDirection);

    return this.getInstancesWithCount(
      options.pageSize,
      options.pageIndex,
      options.sortField,
      sqlSortDirection,
      filterStrings.join(' AND '),
    );
  }
}
