import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormTemplateListResponse } from '@remberg/forms/common/dtos';
import { FormTemplate } from '@remberg/forms/common/main';
import { FormTemplateOfflineServiceInterface } from '@remberg/forms/ui/clients';
import { IsoLanguageCodesEnum } from '@remberg/global/common/core';
import {
  as,
  getNumberCount,
  LogService,
  SQLQueryParams,
  SyncDataTypesEnum,
} from '@remberg/global/ui';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { dataTypeToServiceMap, SqlDBService } from '../sqlDB.service';
import { FormInstanceOfflineService } from './formInstance.offline.service';
import { FormTemplateVersionOfflineService } from './formTemplateVersion.offline.service';

enum FormTemplateOfflinePopulateEnum {
  FORM_TEMPLATE_VERSION = 'formTemplateVersion',
  IN_PROGRESS_COUNT = 'inProgressCount',
}

export const FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX = 'name_';

export type FormTemplateTableColumnName =
  `${typeof FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX}${IsoLanguageCodesEnum}`;

const PARAMS: SQLQueryParams<
  FormTemplateTableColumnName,
  FormTemplateOfflinePopulateEnum,
  keyof FormTemplate
> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.FORMTEMPLATES,
  columns: Object.values(IsoLanguageCodesEnum).reduce(
    (column, locale) => ({
      ...column,
      [as<FormTemplateTableColumnName>(`${FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX}${locale}`)]: {
        type: 'TEXT',
        valueFunction: (val: FormTemplate) => val?.name[locale]?.trim(),
      },
    }),
    {} as Record<FormTemplateTableColumnName, keyof SQLQueryParams['columns']>,
  ),
  populates: {
    [FormTemplateOfflinePopulateEnum.FORM_TEMPLATE_VERSION]: {
      targetKey: 'formTemplateVersion',
      populateFunction: async (val: FormTemplate) =>
        await (
          dataTypeToServiceMap[
            SyncDataTypesEnum.FORMTEMPLATEVERSIONS
          ] as FormTemplateVersionOfflineService
        ).getLatestByFormTemplateId(val._id),
    },
    [FormTemplateOfflinePopulateEnum.IN_PROGRESS_COUNT]: {
      targetKey: 'inProgressCount',
      populateFunction: async (val: FormTemplate) =>
        await (
          dataTypeToServiceMap[SyncDataTypesEnum.FORMINSTANCES] as FormInstanceOfflineService
        ).getInProgressCountByFormTemplateId(val._id),
    },
  },
};

@Injectable()
export class FormTemplateOfflineService
  extends BaseOfflineService<FormTemplate, never>
  implements FormTemplateOfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, PARAMS, logger, store);
  }

  public async getInstancePopulated(
    formTemplateId: string,
    populateFormTemplateConfig: boolean,
    populateFormTemplateVersion: boolean,
    populateInProgressCount?: boolean,
  ): Promise<FormTemplate> {
    const formTemplate = await this.getInstance(formTemplateId, {
      ...(populateInProgressCount && { inProgressCount: populateInProgressCount }),
      formTemplateVersion: populateFormTemplateVersion,
    });

    return formTemplate;
  }

  public async getManyPopulatedWithCount(
    pageSize?: number,
    pageIndex?: number,
    searchQuery?: string,
    searchLocale?: IsoLanguageCodesEnum,
    populateInProgressCount?: boolean,
    populateFormTemplateVersion?: boolean,
  ): Promise<FormTemplateListResponse> {
    let filterString: string | undefined;

    // search
    if (searchQuery && searchLocale) {
      filterString = `${PARAMS.tableName}.${FORM_TEMPLATE_TABLE_COLUMN_NAME_PREFIX}${searchLocale} LIKE '%${searchQuery}%'`;
    }

    const response = await this.getInstancesWithCount(
      pageSize,
      pageIndex,
      undefined,
      undefined,
      filterString,
      {
        inProgressCount: populateInProgressCount || false,
        formTemplateVersion: populateFormTemplateVersion || false,
      },
    );

    return {
      data: response.data,
      count: getNumberCount(response.count ?? 0),
    };
  }
}
