<mat-card appearance="outlined" class="login-card center" ngClass.lt-sm="landing-card">
  <mat-card-header class="flex-row justify-center">
    <mat-card-title i18n="@@setNewPassword">Set a new password</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form
      ng-submit="setPwd()"
      (keydown)="setPwd($event)"
      *ngIf="!sent && !shouldRenew && tokenIsValid && passwordResetFormGroup"
      [formGroup]="passwordResetFormGroup"
    >
      <div class="form-container">
        <mat-form-field class="password-form-field" appearance="outline">
          <input
            matInput
            i18n-placeholder="@@newPassword"
            placeholder="New password"
            type="password"
            name="password"
            autocomplete="new-password"
            formControlName="password"
          />
          <mat-error
            *ngIf="
              passwordResetFormGroup.controls.password.errors
                | getFormErrorMessage: passwordErrorMessages as errorMsg
            "
          >
            {{ errorMsg }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input
            matInput
            i18n-placeholder="@@confirmPassword"
            placeholder="Confirm Password"
            type="password"
            name="passwordConfirm"
            autocomplete="new-password"
            formControlName="passwordConfirm"
          />
          <mat-error
            *ngIf="
              passwordResetFormGroup.controls.passwordConfirm.errors
                | getFormErrorMessage: passwordErrorMessages as errorMsg
            "
          >
            {{ errorMsg }}
          </mat-error>
        </mat-form-field>
        <button
          mat-flat-button
          [disabled]="sent"
          (click)="setPwd($event)"
          type="submit"
          color="primary"
          i18n="@@submit"
          class="landing-button"
        >
          Submit
        </button>
      </div>
    </form>

    <ng-container *ngIf="!tokenIsValid && tokenValidityChecked">
      <ng-container *ngIf="!shouldRenew">
        <div i18n="@@thisLinkHasExpired">This link has expired.</div>
        <button
          mat-flat-button
          [disabled]="shouldRenew"
          (click)="redirectToForgotPassword()"
          type="submit"
          color="primary"
          i18n="@@requestAgain"
          class="landing-button"
        >
          Request again
        </button>
      </ng-container>
      <div *ngIf="shouldRenew" i18n="@@thisLinkIsNotValid">This link is not valid.</div>
      <div *ngIf="renewSuccess" i18n="@@accessHasBeenRequestedAgain">
        Access has been requested again.
      </div>
    </ng-container>

    <div *ngIf="sent || !tokenValidityChecked">
      <app-loader></app-loader>
    </div>
  </mat-card-content>

  <mat-divider class="divider"></mat-divider>

  <mat-card-actions>
    <span class="align-items-center" [class.disabled]="sent">
      <mat-icon class="x-small-icon">arrow_back</mat-icon>
      <a class="text-link" [class.disabled]="sent" [routerLink]="['/login']" i18n="@@backToLogin"
        >Back to Login</a
      >
    </span>
  </mat-card-actions>
</mat-card>
