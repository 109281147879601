import { CustomProperty } from '../custom-property';
import { CustomPropertyKey } from '../custom-property-key';
import { CustomPropertyValue } from '../custom-property-value';

export function getCustomPropertyKey(
  customProperty: CustomProperty | CustomPropertyValue,
): CustomPropertyKey {
  const propertyType = customProperty.propertyType;
  const id = isCustomPropertyValue(customProperty)
    ? customProperty.customPropertyId
    : customProperty._id;

  return `cProp-${propertyType}-${id}`;
}

function isCustomPropertyValue(obj: unknown): obj is CustomPropertyValue {
  return !!obj && !!(obj as CustomPropertyValue).customPropertyId;
}
