import { ExternalFeatureFlagValues, FeatureFlagEnum } from '@remberg/global/common/core';
import { LocalStorageKeys } from '@remberg/global/ui';
import { Tenant } from '@remberg/tenants/common/main';
import { RembergUser } from '@remberg/users/common/main';
import { AppStateService } from '../services/app-state.service';

/**
 * @deprecated use the global store instead
 */
export function isAccountFeatureFlagEnabled(
  featureValue: FeatureFlagEnum,
  appState: AppStateService,
): boolean {
  const tenant = getTenantFromStorage(appState);
  const rembergUser = getRembergUserFromStorage(appState);
  return (
    !!tenant &&
    !!rembergUser &&
    (rembergUser.isTenantOwner
      ? tenant.internalFeatureFlags?.[featureValue] === true
      : tenant.externalFeatureFlags?.[featureValue as keyof ExternalFeatureFlagValues] === true)
  );
}

function getTenantFromStorage(appState: AppStateService): Tenant | undefined {
  const rawTenant = appState.getValue(LocalStorageKeys.IONIC_CURRENT_TENANT);
  const tenant = rawTenant ? JSON.parse(rawTenant) : undefined;
  return tenant;
}

function getRembergUserFromStorage(appState: AppStateService): RembergUser | undefined {
  const rawRembergUser = appState.getValue(LocalStorageKeys.IONIC_CURRENT_REMBERG_USER);
  const rembergUser = rawRembergUser ? JSON.parse(rawRembergUser) : undefined;
  return rembergUser;
}
