import { InjectionToken } from '@angular/core';
import { AdvancedFilter } from '@remberg/advanced-filters/common/main';
import { Product, ProductCompatibility } from '@remberg/assets/common/main';
import { ApiResponse, OfflinePopulateType, OfflineService } from '@remberg/global/ui';
import {
  AssetsLegacyFindManyOfflineQueryInterface,
  AssetsLegacyOfflineAdvancedFilterConfigKeys,
  AssetsLegacyOfflineAdvancedFilterQuery,
} from './assets-legacy.definitions';

export interface AssetsLegacyOfflineServiceInterface
  extends OfflineService<
    ProductCompatibility,
    AssetsLegacyOfflineAdvancedFilterQuery,
    AdvancedFilter<AssetsLegacyOfflineAdvancedFilterConfigKeys>
  > {
  findOne(id: string, populate?: OfflinePopulateType): Promise<Product>;

  findMany(options: AssetsLegacyFindManyOfflineQueryInterface): Promise<ApiResponse<Product[]>>;

  findManyByIds(ids: string[], populate?: OfflinePopulateType): Promise<Product[]>;
}

export const ASSETS_LEGACY_OFFLINE_SERVICE =
  new InjectionToken<AssetsLegacyOfflineServiceInterface>(
    'Token for injecting assets legacy offline service without circular dependencies',
  );
