import { InjectionToken, Provider } from '@angular/core';
import {
  FormInstancePrefillingService,
  SimpleStepFactory,
  StepFactory,
  StepsProvider,
} from '@remberg/forms/common/main';
import { AssetPrefillingStepsProvider } from './steps/asset.steps';
import { CustomPropertyValuePrefillingStepsProvider } from './steps/custom-property-value.steps';
import { PrefillingStepFunctions } from './steps/interfaces';
import { OrganizationPrefillingStepsProvider } from './steps/organization.steps';
import { STATIC_PREFILLING_STEPS_PROVIDER } from './steps/static.steps';
import { WorkOrderPrefillingStepsProvider } from './steps/work-order.steps';

export const FORMS_PREFILLING_SERVICE = new InjectionToken<FormInstancePrefillingService>(
  'Forms Prefilling Service',
);
export const FORMS_PREFILLING_STEP_FACTORY = new InjectionToken<
  StepFactory<PrefillingStepFunctions>
>('Forms Prefilling Step Factory');
export const FORMS_PREFILLING_STEPS_PROVIDER = new InjectionToken<
  StepsProvider<PrefillingStepFunctions>
>('Forms Prefilling Steps Provider');

export const PREFILLING_STEPS_PROVIDERS: Provider[] = [
  {
    provide: FORMS_PREFILLING_SERVICE,
    useFactory: (stepFactory: StepFactory<PrefillingStepFunctions>) =>
      new FormInstancePrefillingService(stepFactory),
    deps: [FORMS_PREFILLING_STEP_FACTORY],
  },
  {
    provide: FORMS_PREFILLING_STEP_FACTORY,
    useFactory: (providers: StepsProvider<PrefillingStepFunctions>[]) =>
      new SimpleStepFactory(providers),
    deps: [FORMS_PREFILLING_STEPS_PROVIDER],
  },
  {
    provide: FORMS_PREFILLING_STEPS_PROVIDER,
    multi: true,
    useClass: OrganizationPrefillingStepsProvider,
  },
  {
    provide: FORMS_PREFILLING_STEPS_PROVIDER,
    multi: true,
    useClass: AssetPrefillingStepsProvider,
  },
  {
    provide: FORMS_PREFILLING_STEPS_PROVIDER,
    multi: true,
    useClass: WorkOrderPrefillingStepsProvider,
  },
  {
    provide: FORMS_PREFILLING_STEPS_PROVIDER,
    multi: true,
    useValue: STATIC_PREFILLING_STEPS_PROVIDER,
  },
  {
    provide: FORMS_PREFILLING_STEPS_PROVIDER,
    multi: true,
    useClass: CustomPropertyValuePrefillingStepsProvider,
  },
];
