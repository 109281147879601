/**
 * Compares two version numbers and checks if the testVersion is higher than the againstVersion.
 * @param testVersion - The version to test.
 * @param againstVersion - The version to compare against.
 * @returns Returns `true` if the testVersion is higher, otherwise `false`.
 */
export function compareIfVersionHigher(testVersion: string, againstVersion: string): boolean {
  const incomingVersion = extractVersionNumbers(testVersion);
  const currentVersion = extractVersionNumbers(againstVersion);

  if (incomingVersion && currentVersion) {
    if (incomingVersion.major > currentVersion.major) {
      return true;
    } else if (incomingVersion.major === currentVersion.major) {
      if (incomingVersion.minor > currentVersion.minor) {
        return true;
      } else if (incomingVersion.minor === currentVersion.minor) {
        if (incomingVersion.patch > currentVersion.patch) {
          return true;
        }
      }
    }
  }

  return false;
}

/**
 * Extracts the major, minor, and patch version numbers from a version string.
 * @param versionString - The version string to extract numbers from.
 * @returns An object containing the major, minor, and patch version numbers, or `null` if the versionString is invalid.
 */
export function extractVersionNumbers(
  versionString: string,
): { major: number; minor: number; patch: number } | null {
  if (typeof versionString !== 'string') {
    return null;
  }

  const regex: RegExp = /^(\d+)\.(\d+)\.(\d+)/;
  const matches: RegExpMatchArray | null = versionString.match(regex);

  if (matches) {
    const [, major, minor, patch] = matches;
    return { major: parseInt(major!), minor: parseInt(minor!), patch: parseInt(patch!) };
  }

  return null;
}
