import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

export interface FileOverInfo {
  hasFileOver: boolean;
  items?: DataTransferItem[];
}

@Directive({
  selector: '[fileDrop]',
})
export class FileDropDirective {
  @Input() public disabled = false;

  @Output() public fileOver = new EventEmitter<FileOverInfo>();
  @Output() public fileDropped = new EventEmitter<File[]>();

  private dropTarget?: HTMLElement;

  // Dragover listener
  @HostListener('dragenter', ['$event']) public onDragEnter(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.disabled) return;

    this.dropTarget = evt.target as HTMLElement;
    this.fileOver.emit({ hasFileOver: true, items: [...(evt?.dataTransfer?.items ?? [])] });
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.disabled) return;

    if (this.dropTarget === evt.target) {
      this.fileOver.emit({ hasFileOver: false, items: [...(evt?.dataTransfer?.items ?? [])] });
    }
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  // Drop listener
  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.disabled) return;

    if (this.dropTarget === evt.target) {
      this.dropTarget = undefined;
      this.fileOver.emit({ hasFileOver: false, items: [...(evt?.dataTransfer?.items ?? [])] });
      // TODO #6589: Check if the file type is valid
      if (evt.dataTransfer?.files?.length) {
        const files = Object.values(evt.dataTransfer?.files);
        this.fileDropped.emit(files);
      }
    }
  }
}
