<div>
  <mat-accordion multi>
    <ul class="top-ul">
      <ng-container *ngTemplateOutlet="configOptionList; context: { list: config }"> </ng-container>
    </ul>
  </mat-accordion>
</div>

<ng-template #configOptionList let-list="list" let-parentOption="parentOption">
  <section
    class="section"
    *ngFor="let option of list"
    [attr.data-test-id]="'expansion-section-' + option.name"
  >
    <li>
      <mat-expansion-panel
        displayMode="flat"
        class="mat-expansion-panel-no-card parameters-panel"
        style="border: 0px !important; margin: 0"
        *ngIf="option.subOptions?.length > 0; else noSubOptions"
      >
        <mat-expansion-panel-header>
          <mat-checkbox
            class="checkbox-text"
            [(ngModel)]="option.checked"
            [checked]="isSet(option.subOptions, false)"
            (change)="setAll($event, option)"
            (click)="onCheckClick($event)"
            [indeterminate]="isSet(option.subOptions, true)"
            [attr.data-test-id]="'export-property-' + option.name"
          >
            {{ option.translation }}
          </mat-checkbox>
        </mat-expansion-panel-header>
        <span *ngIf="option.subOptions?.length > 0">
          <ul>
            <ng-container
              *ngTemplateOutlet="configOptionList; context: { list: option.subOptions }"
            ></ng-container>
          </ul>
        </span>
      </mat-expansion-panel>
      <ng-template #noSubOptions>
        <span>
          <mat-checkbox
            [(ngModel)]="option.checked"
            (change)="updateResultingConfig()"
            class="checkbox-text"
            [attr.data-test-id]="'export-property-' + option.name"
          >
            {{ option.translation }}
          </mat-checkbox>
        </span>
      </ng-template>
    </li>
  </section>
</ng-template>
