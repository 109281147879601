import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

/**
 * @title Configurable progress spinner
 */
@Component({
  selector: 'app-ai-loader',
  templateUrl: './ai-loader.component.html',
  styleUrls: ['./ai-loader.component.scss'],
})
export class AiLoaderComponent {
  @Input() public color: ThemePalette;
  @Input() public value: number | null | undefined = 0;
  @Input() public statusLabel: string | null | undefined = '';
}
