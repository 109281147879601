import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { LanguageCodeEnum } from '@remberg/global/common/core';

import { ApiResponse, OfflineService } from '@remberg/global/ui';
import { WorkOrderStatus } from '@remberg/work-orders-legacy/common/main';

export interface WorkOrderStatusOfflineServiceInterface
  extends OfflineService<WorkOrderStatus, AdvancedFilterQuery<never>, AdvancedFilter<never>> {
  getWorkOrderStatiWithCount(
    limit?: number,
    offset?: number,
    sortColumn?: string,
    sortDirection?: string,
    searchValue?: string,
    searchLocale?: LanguageCodeEnum,
  ): Promise<ApiResponse<WorkOrderStatus[]>>;
}

export const WORK_ORDER_STATUS_OFFLINE_SERVICE =
  new InjectionToken<WorkOrderStatusOfflineServiceInterface>(
    'Token for injecting work order status offline service without circular dependencies',
  );
