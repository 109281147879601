import { TicketActivityType } from '../enums';

export const getActivityContentWithExpansionInfo = ({
  plainText,
  strippedPlainText,
  html,
  strippedHtml,
}: {
  plainText?: string;
  strippedPlainText?: string;
  html?: string;
  strippedHtml?: string;
}): {
  defaultMessage: string;
  extendedMessage: string | undefined;
  hasExtendedMessage: boolean;
  isExtendedMessageTextOnly: boolean;
  isDefaultMessageTextOnly: boolean;
} => {
  let extendedMessage: string | undefined;
  let defaultMessage: string;
  let isExtendedMessageTextOnly = false;
  let isDefaultMessageTextOnly = false;

  if (html && strippedHtml && html !== strippedHtml) {
    extendedMessage = html;
    defaultMessage = strippedHtml;
    // 2. We only have the full html, so we show this, in order not to loose formatting.
  } else if (html) {
    extendedMessage = html;
    defaultMessage = html;
    // 3. Plain E-Mail case (as strippedHtml also exists for plain emails)
    // We show the stripped version per default, & the complete message after pressing show more.
  } else if (plainText && strippedHtml) {
    extendedMessage = plainText;
    defaultMessage = strippedHtml;
    isExtendedMessageTextOnly = true;
    // 4. Same as 3. bus with the "strippedPlainText" instead of strippedHtml
  } else if (plainText && strippedPlainText) {
    extendedMessage = plainText;
    defaultMessage = strippedPlainText;
    isExtendedMessageTextOnly = true;
    isDefaultMessageTextOnly = true;
    // 5. Last resort fallback option.
  } else {
    extendedMessage = plainText ?? strippedPlainText ?? '';
    defaultMessage = plainText ?? strippedPlainText ?? '';
    isExtendedMessageTextOnly = true;
    isDefaultMessageTextOnly = true;
  }

  const hasExtendedMessage = extendedMessage !== defaultMessage;

  return {
    defaultMessage,
    extendedMessage: hasExtendedMessage ? extendedMessage : undefined,
    hasExtendedMessage,
    isExtendedMessageTextOnly: hasExtendedMessage ? isExtendedMessageTextOnly : false,
    isDefaultMessageTextOnly,
  };
};

export const areActivitiesLimited = (serviceCase?: {
  activities: unknown[];
  activityCount?: number;
}): boolean => serviceCase?.activities?.length !== serviceCase?.activityCount;

export const getMovedActivityIds = (
  caseId: string,
  activities: {
    kind: string;
    destinationTicketId?: string;
    movedActivityIds?: string[];
  }[] = [],
): Set<string> =>
  new Set(
    activities
      .filter((activity) => activity.kind === TicketActivityType.CASE_MOVED)
      .filter(({ destinationTicketId }) => destinationTicketId?.toString() === caseId)
      .flatMap((activity) => activity?.movedActivityIds?.map((id) => id?.toString()) ?? []),
  );
