import { Pipe, PipeTransform } from '@angular/core';
import { VisualizationTypeEnum } from '@remberg/global/common/core';

@Pipe({
  name: 'castVisualizationType',
})
export class CastVisualizationTypePipe implements PipeTransform {
  transform(value: string | VisualizationTypeEnum): VisualizationTypeEnum {
    return value as VisualizationTypeEnum;
  }
}
