/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { CdkRowDef, DataSource } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatRowDef } from '@angular/material/table';

@Directive({
  selector: '[matStrictRowDef]',
  inputs: ['columns: matStrictRowDefColumns', 'when: matStrictRowDefWhen'],
  providers: [{ provide: CdkRowDef, useExisting: StrictRowDefDirective }],
})
export class StrictRowDefDirective<T> extends MatRowDef<T> {
  @Input('matStrictRowDef')
  public dataSource?: DataSource<T>;

  public static ngTemplateContextGuard<T>(
    directive: StrictRowDefDirective<T>,
    context: unknown,
  ): context is { $implicit: T } {
    return true;
  }
}
