import { AssetType } from '../../asset-types';
import { ProductType } from '../definitions/product-type';
import { createMapper } from './create-mapper';

function mapAssetTypeToProductType(assetType: AssetType): ProductType {
  return {
    _id: assetType._id,
    label: assetType.label,
    tenantId: assetType.tenantId,
    createdAt: assetType.createdAt,
    lastModified: assetType.updatedAt,
    image: assetType.imageId,
    manufacturer: assetType.manufacturerName,
  };
}

export const mapToProductType = createMapper(mapAssetTypeToProductType);
