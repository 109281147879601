import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ContactsService, OrganizationsService } from '@remberg/crm/ui/clients';
import { LogService } from '@remberg/global/ui';
import { parseAuthTokenPayload } from '@remberg/users/common/main';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import { TenantService } from '../../services/api/tenant.service';
import { UserRoleService } from '../../services/api/user-role.service';
import { LanguageService } from '../../services/language.service';
import { GlobalActions } from './global.actions';

@Injectable()
export class GlobalRegisterSessionInfoEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly organizationsService: OrganizationsService,
    private readonly contactsService: ContactsService,
    private readonly rembergUsersService: RembergUsersService,
    private readonly tenantService: TenantService,
    private readonly userRoleService: UserRoleService,
    private readonly languageService: LanguageService,
    private readonly logger: LogService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
  ) {}

  public readonly startRegisterSessionInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        GlobalActions.startRegisterSessionInfoLogin,
        GlobalActions.startRegisterSessionInfoAppInitialization,
      ),
      exhaustMap((action) => {
        try {
          const { organizationId, tenantId, rembergUserId, contactId } = parseAuthTokenPayload(
            action.token,
          );
          return forkJoin([
            organizationId
              ? this.organizationsService.findOneRawOrThrow(organizationId)
              : of(undefined),
            tenantId
              ? this.tenantService.getOneForGlobalInitializationOrThrow(tenantId)
              : of(undefined),
            this.contactsService.findOneRawOrThrow(contactId),
            this.rembergUsersService.findOneForGlobalInitializationOrThrow(rembergUserId),
          ]).pipe(
            mergeMap(([organization, tenant, contact, rembergUser]) => {
              if (!rembergUser.userRoleId) {
                return of([organization, tenant, contact, rembergUser, undefined] as const);
              }
              return this.userRoleService
                .getOneForGlobalInitializationOrThrow(rembergUser.userRoleId)
                .pipe(
                  map(
                    (userRole) => [organization, tenant, contact, rembergUser, userRole] as const,
                  ),
                );
            }),
            map(([organization, tenant, contact, rembergUser, userRole]) => {
              const isLanguageSwitch = this.languageService.switchLanguage(rembergUser.lang);
              return [
                isLanguageSwitch,
                organization,
                tenant,
                contact,
                rembergUser,
                userRole,
              ] as const;
            }),
            filter(([isLanguageSwitch]) => !isLanguageSwitch),
            // TODO: handle case when falling back to offline mode while online mode is enabled:
            // this.router.navigate(['/offline']);
            map(([, organization, tenant, contact, rembergUser, userRole]) =>
              GlobalActions.completeRegisterSessionInfo({
                organization,
                tenant,
                contact,
                rembergUser,
                userRole,
              }),
            ),
            catchError((error) =>
              of(GlobalActions.registerSessionInfoError({ error: error.message })),
            ),
          );
        } catch (error: unknown) {
          this.logger.error()(error);
          return of(GlobalActions.registerSessionInfoError({ error: (error as Error).message }));
        }
      }),
    ),
  );

  public readonly warnAboutUnverifiedEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.completeRegisterSessionInfo),
        tap((action) => {
          if (!action.rembergUser.activationInfo.emailVerifiedAt) {
            const toaster = this.toastr.warning(
              // eslint-disable-next-line max-len
              $localize`:@@yourEmailIsNotVerifiedPleaseVerify:Your email is not verified. Please verify it in your profile.`,
              $localize`:@@warning:Warning`,
            );
            toaster.onTap.subscribe(() => this.router.navigate(['center', 'profile']));
          }
        }),
      ),
    { dispatch: false },
  );
}
