import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, map, mergeMap, take } from 'rxjs';

import { Store } from '@ngrx/store';
import { GlobalSelectors, RootGlobalState } from '../store';

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnDestroy {
  private readonly token$ = new BehaviorSubject<string | undefined>(undefined);
  private readonly subscription: Subscription = this.store
    .select(GlobalSelectors.selectToken)
    .subscribe(this.token$);

  constructor(private readonly store: Store<RootGlobalState>) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // We only add the token headers if the request is not going to fetch a local/frontend
    let requestWithHeaders$: Observable<typeof request>;
    if (this.token$.getValue() && !request.url.startsWith('../assets/')) {
      requestWithHeaders$ = this.store.select(GlobalSelectors.selectOutgoingRequestHeaders).pipe(
        take(1),
        map((headers) =>
          request.clone({
            setHeaders: headers,
          }),
        ),
      );
    } else {
      requestWithHeaders$ = this.store
        .select(GlobalSelectors.selectPublicOutgoingRequestHeaders)
        .pipe(
          take(1),
          map((headers) =>
            request.clone({
              setHeaders: headers,
            }),
          ),
        );
    }
    return requestWithHeaders$.pipe(
      mergeMap((requestWithHeaders) => next.handle(requestWithHeaders)),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
