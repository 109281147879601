import { Injectable } from '@angular/core';
import { LocalStorageKeys, LogService, StorageService } from '@remberg/global/ui';
import { BehaviorSubject, filter, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private state: { [key in LocalStorageKeys]?: string } = {};
  private isReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly storage: StorageService,
    private readonly logger: LogService,
  ) {}

  public async initialize(): Promise<void> {
    if (!this.isReady.getValue()) {
      this.logger.debug()('Initializing storage for AppStateService...');
      await this.storage.initialize();
      this.logger.debug()('Loading data from storage...');
      for (const key of Object.values(LocalStorageKeys)) {
        this.state[key] = await this.storage.get(key);
      }
      this.logger.debug()('App state loaded from storage!');
      this.isReady.next(true);
    }
  }

  /**
   * Returns a cached value from the storage.
   * WARNING ! If this is used upon app initialization, be await to wait for {getReadyState} function
   * @param  {LocalStorageKeys} key
   * @returns string
   */
  public getValue(key: LocalStorageKeys): string | undefined {
    if (!this.isReady.getValue()) {
      this.logger.warn()(
        'Trying to fetch data before state is initialized! Key trying to fetch : ',
        key,
      );
    }
    return this.state[key];
  }

  public async setValue(key: LocalStorageKeys, value: string): Promise<void> {
    this.state[key] = value;
    return await this.storage.set(key, value);
  }

  public async removeValue(key: LocalStorageKeys): Promise<void> {
    this.state[key] = undefined;
    return await this.storage.remove(key);
  }

  public async removeValues(keys: LocalStorageKeys[]): Promise<void> {
    await Promise.all(keys.map((key) => this.removeValue(key)));
  }

  /**
   * Checks if the storage has been initialized by either returning directly true or waiting for the initialization to finish
   * It will never return false. It will wait util is true. Use this function in all services that are initialized upon app initialization.
   * @returns Promise(true)
   */
  public getReadyState(): Promise<void> {
    return new Promise((resolve) => {
      if (this.isReady.getValue()) {
        resolve();
      } else {
        this.isReady.pipe(filter(Boolean), take(1)).subscribe(() => {
          resolve();
        });
      }
    });
  }

  public async resetLocalStorage(): Promise<void> {
    if (!this.isReady.getValue()) {
      this.logger.warn()('Trying to reset data before state is initialized!');
    }
    await this.storage.reset();
    this.state = {};
  }
}

export function initializeAppState(appService: AppStateService): () => Promise<void> {
  return (): Promise<void> => appService.getReadyState();
}
