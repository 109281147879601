import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LayoutService } from '@remberg/ui-core/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

export enum TosterActionMessage {
  CLOSED = 'closed',
}

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
    ]),
  ],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastrComponent extends Toast {
  constructor(
    public override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
    public layout: LayoutService,
  ) {
    super(toastrService, toastPackage);
  }

  protected removeToaster(): void {
    this.remove();
    this.toastPackage.triggerAction(TosterActionMessage.CLOSED);
  }
}
