import { AssetBasic } from '@remberg/assets/common/base';
import { UnknownOr } from '@remberg/global/common/core';
import { assertNever, compareIDs, getDefinedOrThrow } from '../core';
import {
  AssetMultiSelectFieldData,
  AssetSingleSelectFieldData,
  FormField,
  FormFieldData,
  FormFieldSectionData,
  FormFieldTypesEnum,
  FormInstanceData,
  FormSection,
  FormSectionData,
  FormSectionTypesEnum,
  FormTemplateConfig,
  RepeaterFieldData,
} from '../models';

export function getFormInstanceRelatedAssets<Populated extends boolean>(
  formTemplateConfig: FormTemplateConfig,
  formInstanceData: FormInstanceData<Populated>,
): (Populated extends true ? UnknownOr<AssetBasic> : string)[] {
  const relatedAssets: (Populated extends true ? UnknownOr<AssetBasic> : string)[] = [];

  for (let i = 0; i < formTemplateConfig.sections.length; i++) {
    relatedAssets.push(
      ...getRelatedAssetsFromSection(
        getDefinedOrThrow(formTemplateConfig.sections[i]),
        getDefinedOrThrow(formInstanceData[i]),
      ),
    );
  }

  // make sure the result does not contain duplicates
  return relatedAssets.filter(
    (value, index) => index === relatedAssets.findIndex((obj) => compareIDs(obj, value)),
  );
}

function getRelatedAssetsFromSection<T extends boolean>(
  section: FormSection,
  sectionData: FormSectionData<T>,
): (T extends true ? UnknownOr<AssetBasic> : string)[] {
  const relatedAssets: (T extends true ? UnknownOr<AssetBasic> : string)[] = [];

  switch (section.type) {
    case FormSectionTypesEnum.FIELD_SECTION:
      for (let i = 0; i < section.fields.length; i++) {
        relatedAssets.push(
          ...getRelatedAssetsFromField(
            getDefinedOrThrow(section.fields[i]),
            (sectionData as FormFieldSectionData<T>).fields[i],
          ),
        );
      }
      break;
    case FormSectionTypesEnum.EMAIL_SECTION:
    case FormSectionTypesEnum.SIGNATURE_SECTION:
      // Section doesn't have any possible asset relation
      break;
    default:
      assertNever(section);
  }

  return relatedAssets;
}

function getRelatedAssetsFromField<T extends boolean>(
  field: FormField,
  fieldData: FormFieldData<T> | undefined,
): (T extends true ? UnknownOr<AssetBasic> : string)[] {
  const relatedAssets: (T extends true ? UnknownOr<AssetBasic> : string)[] = [];

  switch (field.type) {
    case FormFieldTypesEnum.FIELD_REPEATER: {
      const repeaterEntries = getDefinedOrThrow(fieldData as RepeaterFieldData<T>).entries;
      for (const entry of repeaterEntries) {
        for (let i = 0; i < field.config.fields?.length; i++) {
          relatedAssets.push(
            ...getRelatedAssetsFromField(getDefinedOrThrow(field.config.fields[i]), entry[i]),
          );
        }
      }
      break;
    }
    case FormFieldTypesEnum.ASSET_MULTI_SELECT: {
      const assets = getDefinedOrThrow(fieldData as AssetMultiSelectFieldData<T>).entries;
      if (assets && !field.config.value?.disableAssetFormInstanceRelationship) {
        for (const asset of assets) {
          relatedAssets.push(asset);
        }
      }
      break;
    }
    case FormFieldTypesEnum.ASSET_SINGLE_SELECT: {
      const asset = getDefinedOrThrow(fieldData as AssetSingleSelectFieldData<T>).selectedAsset;
      if (asset && !field.config.value?.disableAssetFormInstanceRelationship) {
        relatedAssets.push(asset);
      }
      break;
    }
    case FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT:
    case FormFieldTypesEnum.PART_LIST_INPUT:
    case FormFieldTypesEnum.SPARE_PART_LIST_INPUT:
    case FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT:
    case FormFieldTypesEnum.BOOLEAN_INPUT:
    case FormFieldTypesEnum.ADDRESS_INPUT:
    case FormFieldTypesEnum.EXPENSE_LIST_INPUT:
    case FormFieldTypesEnum.RICH_TEXT_INPUT:
    case FormFieldTypesEnum.PERSON_LIST_INPUT:
    case FormFieldTypesEnum.HTML_DISPLAY:
    case FormFieldTypesEnum.DATE_INPUT:
    case FormFieldTypesEnum.TIME_INPUT:
    case FormFieldTypesEnum.HEADLINE_DISPLAY:
    case FormFieldTypesEnum.PHONE_NUMBER_INPUT:
    case FormFieldTypesEnum.FILE_UPLOAD:
    case FormFieldTypesEnum.TASK_LIST_INPUT:
    case FormFieldTypesEnum.DATE_TIME_INPUT:
    case FormFieldTypesEnum.CONTACT_SINGLE_SELECT:
    case FormFieldTypesEnum.STATIC_SINGLE_SELECT:
    case FormFieldTypesEnum.STATIC_MULTI_SELECT:
    case FormFieldTypesEnum.TIME_TRACKING_LIST_INPUT:
    case FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT:
      // Field doesn't have any possible asset relation
      break;
    default:
      assertNever(field);
  }

  return relatedAssets;
}
