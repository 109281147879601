// based on this https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const SUPPORTED_GOTENBERG_MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/rtf',
  'text/plain',
  'text/rtf',
];

export const SUPPORTED_GOTENBERG_EXTENSIONS = ['doc', 'docx', 'rtf', 'txt', 'odt'];
