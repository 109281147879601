import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { DynamicProgressBarConfig, LogService, SyncDataTypesEnum } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';

export async function migrateToV19(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V19...');

  await migratePartsAddAssetTypes(db, logger);

  await db.executeSql('PRAGMA user_version = 19;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V19.');
  return;
}

async function migratePartsAddAssetTypes(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  const tableExistsQuery = await db.executeSql(
    `SELECT name FROM sqlite_master WHERE type='table' AND name='${SyncDataTypesEnum.PARTS}';`,
    [],
  );

  if (tableExistsQuery.rows?.item(0)?.name === SyncDataTypesEnum.PARTS) {
    logger.debug()(`FOUND ${SyncDataTypesEnum.PARTS} table`);
    // determine the amount of parts to migrate:
    const numOfPartsToMigrate =
      (await db.executeSql(`SELECT COUNT(_id) FROM ${SyncDataTypesEnum.PARTS}`, []))?.rows.item(
        0,
      )?.['COUNT(_id)'] ?? 0;
    logger.debug()(`Number of parts to migrate: ${numOfPartsToMigrate}`);

    // fetch all parts and migrate in batches
    if (numOfPartsToMigrate > 0) {
      const batchSize = 100;
      const numBatches = Math.ceil(numOfPartsToMigrate / batchSize);
      for (let i = 0; i < numBatches; i++) {
        const parts = await db.executeSql(
          `SELECT * FROM ${SyncDataTypesEnum.PARTS} LIMIT ${batchSize} OFFSET ${i * batchSize}`,
          [],
        );

        for (let x = 0; x < parts.rows.length; x++) {
          const part = JSON.parse(parts.rows.item(x).instance);
          let hasChanged = false;

          if (!part.assetTypeIds) {
            part.assetTypeIds = [];
            hasChanged = true;
          }

          if (hasChanged) {
            await db.executeSql(
              `UPDATE ${SyncDataTypesEnum.PARTS} SET instance = ? WHERE _id = ?`,
              [JSON.stringify(part), part._id],
            );
          }
        }
      }
    }
    const partsTableMetadata = await db.executeSql('PRAGMA table_info(parts)', []);

    let assetTypeIdsColumnExists = false;
    for (let i = 0; i < partsTableMetadata.rows.length; i++) {
      if (partsTableMetadata.rows.item(i).name === 'assetTypeIds') {
        assetTypeIdsColumnExists = true;
      }
    }

    if (!assetTypeIdsColumnExists) {
      await db.executeSql(
        `ALTER TABLE ${SyncDataTypesEnum.PARTS} ADD COLUMN assetTypeIds TEXT default ''`,
        [],
      );
    }
  }
}
