import { HttpEvent, HttpResponse } from '@angular/common/http';

export function isHttpResponse<T>(httpEvent: HttpEvent<T>): httpEvent is HttpResponse<T> {
  return httpEvent instanceof HttpResponse;
}

// eslint-disable-next-line no-control-regex
const FILE_NAME_REGEXP = new RegExp('filename=["]?([^;"\r\n]*)', 'i');

export function getContentDispositionFileName(
  contentDisposition: string | undefined | null,
): string | undefined {
  const match = contentDisposition ? contentDisposition.match(FILE_NAME_REGEXP) : undefined;

  return match && match[1] ? decodeURIComponent(match[1]) : undefined;
}
