import { Address } from '../definitions';

export function getAddressString(address?: Address | null): string {
  const addressProps: (keyof Address)[] = [
    'street',
    'other',
    'streetNumber',
    'zipPostCode',
    'city',
    'countryProvince',
    'country',
  ];

  if (address && addressProps.some((prop) => !!address[prop])) {
    const result =
      (address.company ? address.company + ', ' : '') +
      (address.person ? address.person + ', ' : '') +
      (address.other ? address.other + ', ' : '') +
      (address.street
        ? address.street + (address.streetNumber ? ' ' + address.streetNumber : '') + ', '
        : '') +
      (address.zipPostCode ? address.zipPostCode + ', ' : '') +
      (address.city ? address.city + ', ' : '') +
      (address.countryProvince ? address.countryProvince + ', ' : '') +
      (address.country ? address.country + ', ' : '');
    if (result && result.slice(result.length - 2) === ', ') {
      return result.slice(0, -2);
    }
  }
  return '';
}

/**
 * Returns true if address are the same otherwise it returns false
 */
export function compareAddresses(address1?: Address | null, address2?: Address | null): boolean {
  const addressString1 = getAddressString(address1) + address1?.latitude + address1?.longitude;
  const addressString2 = getAddressString(address2) + address2?.latitude + address2?.longitude;
  return addressString1 === addressString2;
}

/**
 * Returns a new address object updated with values that are not null/undefined
 * If new fields come with empty string, they will overide the existing data.
 * Then it will check if the coordinates should also have been updated and if they are not, removes them.
 */
export function getUpdatedAddress(oldAddress: Address, newAddress: Address): Address {
  // TODO geocodingResult was not originally included in old backend logic, should we check it?
  const fieldsToCheck: (keyof Address)[] = [
    'company',
    'person',
    'latitude',
    'longitude',
    'other',
    'street',
    'streetNumber',
    'zipPostCode',
    'city',
    'countryProvince',
    'country',
  ];

  // Only update fields that are different and not null/undefined (if '' then remove replace existing value)
  const updatedAddress: Address = fieldsToCheck.reduce((address, field) => {
    if (field in newAddress) {
      const newValue = newAddress[field];
      const oldValue = address[field];
      const shouldRetainOldValue =
        field in address && [null, undefined, oldValue].includes(newValue);

      return shouldRetainOldValue ? address : { ...address, [field]: newValue };
    }
    return address;
  }, oldAddress);

  const shouldCoordsBeRemoved = areCoordsDrivingFieldsChanged(oldAddress, updatedAddress);

  // Only remove geocodingResult if fields affecting coordinates have changed
  if (shouldCoordsBeRemoved) {
    updatedAddress.geocodingResult = undefined;
  }

  // Only remove lat/long data if the address has been updated and lat/long are still the same.
  if (shouldCoordsBeRemoved && areCoordsMatching(oldAddress, updatedAddress)) {
    updatedAddress.latitude = undefined;
    updatedAddress.longitude = undefined;
  }
  return updatedAddress;
}

/**
 * Returns true if one of the fields driving lat/long has been updated otherwise returns false.
 */
function areCoordsDrivingFieldsChanged(oldAddress: Address, newAddress: Address): boolean {
  if (!oldAddress) return false;

  const coordsDrivingFields: (keyof Address)[] = [
    'street',
    'streetNumber',
    'zipPostCode',
    'city',
    'countryProvince',
    'country',
  ];

  for (const field of coordsDrivingFields) {
    if (oldAddress[field] !== newAddress[field]) {
      return true;
    }
  }
  return false;
}

/**
 * Returns true if lat/long fields are the same for both address.
 */
function areCoordsMatching(oldAddress: Address, newAddress: Address): boolean {
  return (
    oldAddress.latitude === newAddress.latitude && oldAddress.longitude === newAddress.longitude
  );
}
