import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { RootGlobalState } from '../core-ui.definitions';
import { GlobalActions } from './global.actions';
import { GlobalSelectors } from './global.selectors';

@Injectable()
export class GlobalUserSettingsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootGlobalState>,
    private readonly rembergUsersService: RembergUsersService,
  ) {}

  public readonly updateUserSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.updateUserSettings),
      withLatestFrom(this.store.select(GlobalSelectors.selectUserSettings)),
      switchMap(([action, oldSettings]) =>
        this.rembergUsersService.updateSelf({ settings: action.settings }).pipe(
          map(() => GlobalActions.updateUserSettingsSuccess()),
          catchError(() => of(GlobalActions.updateUserSettingsError({ oldSettings: oldSettings }))),
        ),
      ),
    ),
  );

  public readonly resetUserSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.resetUIUserSettings),
      withLatestFrom(this.store.select(GlobalSelectors.selectUserSettings)),
      switchMap(([, settings]) =>
        this.rembergUsersService
          .updateSelf({ settings })
          .pipe(map(() => GlobalActions.updateUserSettingsSuccess())),
      ),
    ),
  );

  public readonly updateUserSettingsSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.updateUserSettingsSuccess),
      map(() =>
        GlobalActions.showToaster({
          toasterType: 'success',
          title: $localize`:@@success:Success`,
          message: $localize`:@@settingsSavedSuccessfully:Settings saved successfully`,
        }),
      ),
    ),
  );

  public readonly updateUserSettingsError = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.updateUserSettingsError),
      map(() =>
        GlobalActions.showToaster({
          toasterType: 'error',
          title: $localize`:@@error:Error`,
          message: $localize`:@@errorDuringUpdatingSettings:Error during updating settings`,
        }),
      ),
    ),
  );
}
