import { OrganizationBasic } from '@remberg/crm/common/base';
import { UnknownOr } from '@remberg/global/common/core';
import { assertNever, compareIDs, getDefinedOrThrow } from '../core';
import {
  FormField,
  FormFieldData,
  FormFieldSectionData,
  FormFieldTypesEnum,
  FormInstanceData,
  FormSection,
  FormSectionData,
  FormSectionTypesEnum,
  FormTemplateConfig,
  OrganizationSingleSelectFieldData,
  RepeaterFieldData,
} from '../models';

export function getFormInstanceRelatedOrganizations<T extends boolean>(
  formTemplateConfig: FormTemplateConfig,
  formInstanceData: FormInstanceData<T>,
): (T extends true ? UnknownOr<OrganizationBasic> : string)[] {
  const relatedOrganizations: (T extends true ? UnknownOr<OrganizationBasic> : string)[] = [];

  for (let i = 0; i < formTemplateConfig.sections.length; i++) {
    relatedOrganizations.push(
      ...getRelatedOrganizationsFromSection(
        getDefinedOrThrow(formTemplateConfig.sections[i]),
        getDefinedOrThrow(formInstanceData[i]),
      ),
    );
  }

  // Make sure the result does not contain duplicates
  return relatedOrganizations.filter(
    (value, index) => index === relatedOrganizations.findIndex((obj) => compareIDs(obj, value)),
  );
}

function getRelatedOrganizationsFromSection<T extends boolean>(
  section: FormSection,
  sectionData: FormSectionData<T>,
): (T extends true ? UnknownOr<OrganizationBasic> : string)[] {
  const relatedOrganizations: (T extends true ? UnknownOr<OrganizationBasic> : string)[] = [];

  switch (section.type) {
    case FormSectionTypesEnum.FIELD_SECTION:
      for (let i = 0; i < section.fields.length; i++) {
        relatedOrganizations.push(
          ...getRelatedOrganizationsFromField(
            getDefinedOrThrow(section.fields[i]),
            (sectionData as FormFieldSectionData<T>).fields[i],
          ),
        );
      }
      break;
    case FormSectionTypesEnum.EMAIL_SECTION:
    case FormSectionTypesEnum.SIGNATURE_SECTION:
      // Section doesn't have any possible account relation
      break;
    default:
      assertNever(section);
  }

  return relatedOrganizations;
}

function getRelatedOrganizationsFromField<T extends boolean>(
  field: FormField,
  fieldData: FormFieldData<T> | undefined,
): (T extends true ? UnknownOr<OrganizationBasic> : string)[] {
  const relatedOrganizations: (T extends true ? UnknownOr<OrganizationBasic> : string)[] = [];

  switch (field.type) {
    case FormFieldTypesEnum.FIELD_REPEATER: {
      const repeaterEntries = getDefinedOrThrow(fieldData as RepeaterFieldData<T>).entries;
      for (const entry of repeaterEntries) {
        for (let i = 0; i < field.config.fields?.length; i++) {
          relatedOrganizations.push(
            ...getRelatedOrganizationsFromField(
              getDefinedOrThrow(field.config.fields[i]),
              entry[i],
            ),
          );
        }
      }
      break;
    }
    case FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT: {
      const account = getDefinedOrThrow(
        fieldData as OrganizationSingleSelectFieldData<T>,
      ).selectedCompany;
      if (account) {
        relatedOrganizations.push(account);
      }
      break;
    }
    case FormFieldTypesEnum.ASSET_MULTI_SELECT:
    case FormFieldTypesEnum.ASSET_SINGLE_SELECT:
    case FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT:
    case FormFieldTypesEnum.PART_LIST_INPUT:
    case FormFieldTypesEnum.SPARE_PART_LIST_INPUT:
    case FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT:
    case FormFieldTypesEnum.BOOLEAN_INPUT:
    case FormFieldTypesEnum.ADDRESS_INPUT:
    case FormFieldTypesEnum.EXPENSE_LIST_INPUT:
    case FormFieldTypesEnum.RICH_TEXT_INPUT:
    case FormFieldTypesEnum.PERSON_LIST_INPUT:
    case FormFieldTypesEnum.HTML_DISPLAY:
    case FormFieldTypesEnum.DATE_INPUT:
    case FormFieldTypesEnum.TIME_INPUT:
    case FormFieldTypesEnum.HEADLINE_DISPLAY:
    case FormFieldTypesEnum.PHONE_NUMBER_INPUT:
    case FormFieldTypesEnum.FILE_UPLOAD:
    case FormFieldTypesEnum.TASK_LIST_INPUT:
    case FormFieldTypesEnum.DATE_TIME_INPUT:
    case FormFieldTypesEnum.CONTACT_SINGLE_SELECT:
    case FormFieldTypesEnum.STATIC_SINGLE_SELECT:
    case FormFieldTypesEnum.STATIC_MULTI_SELECT:
    case FormFieldTypesEnum.TIME_TRACKING_LIST_INPUT:
      // Field doesn't have any possible account relation
      break;
    default:
      assertNever(field);
  }
  return relatedOrganizations;
}
