export type Color =
  | 'Yellow'
  | 'Orange'
  | 'Red'
  | 'Pink'
  | 'Purple'
  | 'Blue'
  | 'Cyan'
  | 'Green'
  | 'Brown';
export type ColorWeight = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';

export type ColorPaletteVariantWithoutWeight = '';
export type ColorPaletteVariantWithWeight = 'Light' | 'Dark';

export type ColorOption<C extends Color> =
  | `palette${C}${ColorPaletteVariantWithoutWeight}`
  | `palette${C}${ColorPaletteVariantWithWeight}${ColorWeight}`;

export type Black = 'Black';
export type BlackWeight =
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | '950'
  | '975'
  | '1000';
export type BlackOption = `palette${Black}${ColorPaletteVariantWithWeight}${BlackWeight}`;

export type AllColors = Color | Black;
export type AllColorOptions<C extends AllColors> = C extends Color ? ColorOption<C> : BlackOption;

export type ColorPaletteVariant = ColorPaletteVariantWithoutWeight | ColorPaletteVariantWithWeight;
export type ColorPaletteVariant20 = '' | 'Light';
export type ColorPaletteOption<C extends AllColors> = `${C}${ColorPaletteVariant}`;
export type ColorPaletteOption10<C extends AllColors> = `${C}`;
export type ColorPaletteOption20<C extends AllColors> = `${C}${ColorPaletteVariant20}`;

export const COLOR_PALETTE_OPTIONS_10: ColorPaletteOption10<AllColors>[] = [
  'Yellow',
  'Orange',
  'Red',
  'Pink',
  'Purple',
  'Blue',
  'Cyan',
  'Brown',
  'Green',
  'Black',
];

export const COLOR_PALETTE_OPTIONS_20: ColorPaletteOption20<AllColors>[] = [
  'Yellow',
  'YellowLight',
  'Orange',
  'OrangeLight',
  'Red',
  'RedLight',
  'Pink',
  'PinkLight',
  'Purple',
  'PurpleLight',
  'Blue',
  'BlueLight',
  'Cyan',
  'CyanLight',
  'Brown',
  'BrownLight',
  'Green',
  'GreenLight',
  'Black',
  'BlackLight',
];

export const COLOR_PALETTE_OPTIONS: ColorPaletteOption<AllColors>[] = [
  'Yellow',
  'YellowLight',
  'YellowDark',
  'Orange',
  'OrangeLight',
  'OrangeDark',
  'Red',
  'RedLight',
  'RedDark',
  'Pink',
  'PinkLight',
  'PinkDark',
  'Purple',
  'PurpleLight',
  'PurpleDark',
  'Blue',
  'BlueLight',
  'BlueDark',
  'Cyan',
  'CyanLight',
  'CyanDark',
  'Brown',
  'BrownLight',
  'BrownDark',
  'Green',
  'GreenLight',
  'GreenDark',
  'Black',
  'BlackLight',
  'BlackDark',
];

export type TextPalette = 'paletteBlack' | 'paletteWhite';

export interface ColorPaletteConfig<C extends AllColors> {
  id: ColorPaletteOption<C>;
  label: string;
  background: AllColorOptions<C>;
  border: AllColorOptions<C>;
  disable: AllColorOptions<C>;
  text: TextPalette;
  textAlternative: BlackOption;
}

export type ColorPalette<C extends AllColors> = {
  [key in ColorPaletteOption<C>]: ColorPaletteConfig<C>;
};
export type ColorPropertyToDisplay = keyof Pick<
  ColorPaletteConfig<AllColors>,
  'background' | 'border' | 'disable' | 'text' | 'textAlternative'
>;

export const YELLOW_COLOR_PALETTE: ColorPalette<'Yellow'> = {
  Yellow: {
    id: 'Yellow',
    label: 'Yellow',
    background: 'paletteYellow',
    border: 'paletteYellowDark200',
    disable: 'paletteYellowDark400',
    text: 'paletteBlack',
    textAlternative: 'paletteBlackLight100',
  },
  YellowLight: {
    id: 'YellowLight',
    label: 'Yellow Light',
    background: 'paletteYellowLight400',
    border: 'paletteYellow',
    disable: 'paletteYellowDark200',
    text: 'paletteBlack',
    textAlternative: 'paletteBlackLight100',
  },
  YellowDark: {
    id: 'YellowDark',
    label: 'Yellow Dark',
    background: 'paletteYellowDark200',
    border: 'paletteYellowDark400',
    disable: 'paletteYellowDark600',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const ORANGE_COLOR_PALETTE: ColorPalette<'Orange'> = {
  Orange: {
    id: 'Orange',
    label: 'Orange',
    background: 'paletteOrange',
    border: 'paletteOrangeDark200',
    disable: 'paletteOrangeDark400',
    text: 'paletteBlack',
    textAlternative: 'paletteBlackLight100',
  },
  OrangeLight: {
    id: 'OrangeLight',
    label: 'Orange Light',
    background: 'paletteOrangeLight400',
    border: 'paletteOrange',
    disable: 'paletteOrangeDark200',
    text: 'paletteBlack',
    textAlternative: 'paletteBlackLight100',
  },
  OrangeDark: {
    id: 'OrangeDark',
    label: 'Orange Dark',
    background: 'paletteOrangeDark200',
    border: 'paletteOrangeDark400',
    disable: 'paletteOrangeDark600',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const RED_COLOR_PALETTE: ColorPalette<'Red'> = {
  Red: {
    id: 'Red',
    label: 'Red',
    background: 'paletteRed',
    border: 'paletteRedDark200',
    disable: 'paletteRedDark400',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  RedLight: {
    id: 'RedLight',
    label: 'Red Light',
    background: 'paletteRedLight400',
    border: 'paletteRed',
    disable: 'paletteRedDark200',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  RedDark: {
    id: 'RedDark',
    label: 'Red Dark',
    background: 'paletteRedDark200',
    border: 'paletteRedDark400',
    disable: 'paletteRedDark600',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const PINK_COLOR_PALETTE: ColorPalette<'Pink'> = {
  Pink: {
    id: 'Pink',
    label: 'Pink',
    background: 'palettePink',
    border: 'palettePinkDark200',
    disable: 'palettePinkDark400',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  PinkLight: {
    id: 'PinkLight',
    label: 'Pink Light',
    background: 'palettePinkLight400',
    border: 'palettePink',
    disable: 'palettePinkDark200',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  PinkDark: {
    id: 'PinkDark',
    label: 'Pink Dark',
    background: 'palettePinkDark200',
    border: 'palettePinkDark400',
    disable: 'palettePinkDark600',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const PURPLE_COLOR_PALETTE: ColorPalette<'Purple'> = {
  Purple: {
    id: 'Purple',
    label: 'Purple',
    background: 'palettePurple',
    border: 'palettePurpleDark200',
    disable: 'palettePurpleDark400',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  PurpleLight: {
    id: 'PurpleLight',
    label: 'Purple Light',
    background: 'palettePurpleLight400',
    border: 'palettePurple',
    disable: 'palettePurpleDark200',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  PurpleDark: {
    id: 'PurpleDark',
    label: 'Purple Dark',
    background: 'palettePurpleDark200',
    border: 'palettePurpleDark400',
    disable: 'palettePurpleDark600',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const BLUE_COLOR_PALETTE: ColorPalette<'Blue'> = {
  Blue: {
    id: 'Blue',
    label: 'Blue',
    background: 'paletteBlue',
    border: 'paletteBlueDark200',
    disable: 'paletteBlueDark400',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  BlueLight: {
    id: 'BlueLight',
    label: 'Blue Light',
    background: 'paletteBlueLight400',
    border: 'paletteBlue',
    disable: 'paletteBlueDark200',
    text: 'paletteBlack',
    textAlternative: 'paletteBlackLight100',
  },
  BlueDark: {
    id: 'BlueDark',
    label: 'Blue Dark',
    background: 'paletteBlueDark200',
    border: 'paletteBlueDark400',
    disable: 'paletteBlueDark600',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const CYAN_COLOR_PALETTE: ColorPalette<'Cyan'> = {
  Cyan: {
    id: 'Cyan',
    label: 'Cyan',
    background: 'paletteCyan',
    border: 'paletteCyanDark200',
    disable: 'paletteCyanDark400',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  CyanLight: {
    id: 'CyanLight',
    label: 'Cyan Light',
    background: 'paletteCyanLight400',
    border: 'paletteCyan',
    disable: 'paletteCyanDark200',
    text: 'paletteBlack',
    textAlternative: 'paletteBlackLight100',
  },
  CyanDark: {
    id: 'CyanDark',
    label: 'Cyan Dark',
    background: 'paletteCyanDark200',
    border: 'paletteCyanDark400',
    disable: 'paletteCyanDark600',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const BROWN_COLOR_PALETTE: ColorPalette<'Brown'> = {
  Brown: {
    id: 'Brown',
    label: 'Brown',
    background: 'paletteBrown',
    border: 'paletteBrownDark200',
    disable: 'paletteBrownDark400',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  BrownLight: {
    id: 'BrownLight',
    label: 'Brown Light',
    background: 'paletteBrownLight400',
    border: 'paletteBrown',
    disable: 'paletteBrownDark200',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  BrownDark: {
    id: 'BrownDark',
    label: 'Brown Dark',
    background: 'paletteBrownDark200',
    border: 'paletteBrownDark400',
    disable: 'paletteBrownDark600',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const GREEN_COLOR_PALETTE: ColorPalette<'Green'> = {
  Green: {
    id: 'Green',
    label: 'Green',
    background: 'paletteGreen',
    border: 'paletteGreenDark200',
    disable: 'paletteGreenDark400',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  GreenLight: {
    id: 'GreenLight',
    label: 'Green Light',
    background: 'paletteGreenLight400',
    border: 'paletteGreen',
    disable: 'paletteGreenDark200',
    text: 'paletteBlack',
    textAlternative: 'paletteBlackLight900',
  },
  GreenDark: {
    id: 'GreenDark',
    label: 'Green Dark',
    background: 'paletteGreenDark200',
    border: 'paletteGreenDark400',
    disable: 'paletteGreenDark600',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const GREY_COLOR_PALETTE: ColorPalette<'Black'> = {
  Black: {
    id: 'Black',
    label: 'Grey',
    background: 'paletteBlackLight400',
    border: 'paletteBlackLight600',
    disable: 'paletteBlackLight900',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
  BlackLight: {
    id: 'BlackLight',
    label: 'Grey Light',
    background: 'paletteBlackLight900',
    border: 'paletteBlackLight600',
    disable: 'paletteBlackLight400',
    text: 'paletteBlack',
    textAlternative: 'paletteBlackLight100',
  },
  BlackDark: {
    id: 'BlackDark',
    label: 'Grey Dark',
    background: 'paletteBlackLight200',
    border: 'paletteBlackDark900',
    disable: 'paletteBlackLight700',
    text: 'paletteWhite',
    textAlternative: 'paletteBlackLight900',
  },
};

export const COLOR_PALETTE: ColorPalette<AllColors> = {
  ...YELLOW_COLOR_PALETTE,
  ...ORANGE_COLOR_PALETTE,
  ...RED_COLOR_PALETTE,
  ...PINK_COLOR_PALETTE,
  ...PURPLE_COLOR_PALETTE,
  ...BLUE_COLOR_PALETTE,
  ...CYAN_COLOR_PALETTE,
  ...BROWN_COLOR_PALETTE,
  ...GREEN_COLOR_PALETTE,
  ...GREY_COLOR_PALETTE,
};

export type StatusColors = 'Red' | 'Green' | 'Orange' | 'Yellow' | 'Blue' | 'Black';

export const COLOR_PALETTE_STATUS_OPTIONS: StatusColors[] = [
  'Red',
  'Green',
  'Orange',
  'Yellow',
  'Blue',
  'Black',
];
