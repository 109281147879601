import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VersionInfo } from '@remberg/global/common/core';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { Observable } from 'rxjs';
import { SKIP_CACHE } from '../../interceptors/skip-cache.interceptor';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private readonly versionUrl = `${API_URL_PLACEHOLDER}/tenants/health/version`;

  constructor(private http: HttpClient) {}

  public getVersion(): Observable<VersionInfo> {
    const url = `${this.versionUrl}`;
    return this.http.get<VersionInfo>(url, { context: new HttpContext().set(SKIP_CACHE, true) });
  }
}
