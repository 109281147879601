import { Params } from '@angular/router';
import {
  applyFixedTableColumns,
  COLUMN_LIMIT_DESKTOP,
  COLUMN_LIMIT_LAPTOP,
  COLUMN_LIMIT_MOBILE,
  COLUMN_LIMIT_NO_COLUMNS,
  COLUMN_LIMIT_TABLET,
  TableColumn,
  TableColumnData,
  TableColumnsConfig,
  TableColumnsLimitConfig,
} from '@remberg/global/ui';
import { BreakpointState } from '../store';

function layoutToBreakpointColumnLimit(
  breakpoints?: BreakpointState,
  isSmallTable?: boolean,
  limitConfig?: TableColumnsLimitConfig,
): number {
  switch (true) {
    case breakpoints?.isXLargeView:
      return limitConfig?.isXLargeView ?? COLUMN_LIMIT_DESKTOP;
    case breakpoints?.isLargeView:
      return (
        limitConfig?.isLargeView ?? (isSmallTable ? COLUMN_LIMIT_LAPTOP : COLUMN_LIMIT_DESKTOP)
      );
    case breakpoints?.isMediumView:
      return (
        limitConfig?.isMediumView ?? (isSmallTable ? COLUMN_LIMIT_TABLET : COLUMN_LIMIT_LAPTOP)
      );
    case breakpoints?.isSmallView:
      return limitConfig?.isSmallView ?? COLUMN_LIMIT_TABLET;
    case breakpoints?.isXSmallView:
      return limitConfig?.isXSmallView ?? COLUMN_LIMIT_MOBILE;
    default:
      return COLUMN_LIMIT_DESKTOP;
  }
}

function getLimitTrimmedTableColumns<T extends string>(
  columns: TableColumn<T>[],
  config: TableColumnsConfig<T>,
  limit: number,
): TableColumn<T>[] {
  const fixedColumns = columns.filter(
    (column) => config[column.identifier].fixedIndex !== undefined,
  );
  const trimmedColumn = columns
    .filter((column) => config[column.identifier].fixedIndex === undefined)
    .slice(0, limit);
  return applyFixedTableColumns(trimmedColumn, fixedColumns, config);
}

export function getBreakpointTrimmedTableColumns<T extends string>(
  columns: TableColumn<T>[],
  config: TableColumnsConfig<T>,
  breakpoints?: BreakpointState,
  isSmallTable?: boolean,
  limitConfig?: TableColumnsLimitConfig,
): TableColumn<T>[] {
  const limit = layoutToBreakpointColumnLimit(breakpoints, isSmallTable, limitConfig);
  return getLimitTrimmedTableColumns(columns, config, limit);
}

export function parseTableColumnsQueryParams<T extends string>(
  queryParams: Params,
): TableColumnData<T>[] | undefined {
  const tableColumnsQueryParams = queryParams['displayedTableColumns'];

  if (!tableColumnsQueryParams) {
    return undefined;
  }

  return JSON.parse(tableColumnsQueryParams);
}

export function stringifyTableColumnsQueryParams(
  displayedTableColumns: TableColumn<string>[],
): string {
  return JSON.stringify(
    displayedTableColumns.map(({ identifier, displayOption }) => ({ identifier, displayOption })),
  );
}

/**
 * Returns the maximum number of columns that can be displayed in the tree table for a given
 * expansion level.
 *
 * The goal is to limit the number of columns to keep UI legible with rising level of expanded
 * descendants.
 *
 * There are 5 descending column limits (overrideable by limitConfig).
 * Every 2 expansions, drop to next smaller column limit relative to default limit for current
 * breakpoint.
 *
 * Eg breakpoint is Medium, user expanded 5 levels deep:
 * - default limit for breakpoint is COLUMN_LIMIT_LAPTOP
 * - levelStep is floor(5/2) = 2
 * => drop 2 limits below COLUMN_LIMIT_LAPTOP = COLUMN_LIMIT_MOBILE
 *
 * We're adjusting with leading non-configurable (fixedIndex) columns count because all columns are
 * included in ngFor and we only want to limit the configurable ones.
 */
export function getMaxColumnsLimitForExpansionLevel<T extends string>(
  level: number,
  breakpoints?: BreakpointState,
  columns?: TableColumn<T>[],
  config?: TableColumnsConfig<T>,
  isSmallTable?: boolean,
  limitConfig?: TableColumnsLimitConfig,
): number {
  const leadingNonConfigurableColumns = (columns ?? []).filter((column) => {
    const fixedIndex = config?.[column.identifier]?.fixedIndex;
    return fixedIndex !== undefined && fixedIndex < 0;
  });

  // any number of columns will look good on the largest breakpoint
  if (breakpoints?.isXLargeView) {
    return COLUMN_LIMIT_DESKTOP + leadingNonConfigurableColumns.length;
  }

  const descendingColumnsLimits = [
    limitConfig?.isLargeView ?? COLUMN_LIMIT_DESKTOP,
    limitConfig?.isMediumView ?? COLUMN_LIMIT_LAPTOP,
    limitConfig?.isSmallView ?? COLUMN_LIMIT_TABLET,
    limitConfig?.isXSmallView ?? COLUMN_LIMIT_MOBILE,
    COLUMN_LIMIT_NO_COLUMNS,
  ];

  const levelStep = Math.floor(level / 2);

  const breakpointColumnsLimit = layoutToBreakpointColumnLimit(
    breakpoints,
    isSmallTable,
    limitConfig,
  );

  const columnLimitIndexForBreakpoint = descendingColumnsLimits.indexOf(breakpointColumnsLimit);
  const columnLimitIndexForStep =
    columnLimitIndexForBreakpoint < 0
      ? // when column limit for current breakpoint is not found, use the smallest limit
        descendingColumnsLimits.length - 1
      : // when column limit for current breakpoint is found, use next smaller limit
        columnLimitIndexForBreakpoint + levelStep;

  const maxColumnsLimitForLevel =
    descendingColumnsLimits[columnLimitIndexForStep] ?? COLUMN_LIMIT_NO_COLUMNS;

  return leadingNonConfigurableColumns.length + maxColumnsLimitForLevel;
}
