import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Product } from '@remberg/assets/common/main';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
} from '@remberg/global/ui';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AssetsLegacyAdvancedFilterQuery,
  AssetsLegacyAdvancedStaticFilter,
  AssetsTree,
} from './assets-legacy.definitions';

@Injectable({
  providedIn: 'root',
})
export class AssetsTreeLegacyService {
  public readonly productsUrl = `${API_URL_PLACEHOLDER}/products`;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
  ) {}

  public getAssetTreesWithCount(
    populate?: boolean | Product,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterQuery?: AssetsLegacyAdvancedFilterQuery,
    staticFilters?: AssetsLegacyAdvancedStaticFilter[],
    groupFilter: any = null,
    ignoreUserGroups: boolean = false,
    hasCustomer: boolean = false,
    levels: number = 0,
    topLevelOnly: boolean = true,
  ): Observable<ApiResponse<AssetsTree[]>> {
    if (this.connectivityService.getConnected()) {
      this.logger.silly()('Online asset instances request...');
      return this.getAssetTreesBase(
        populate,
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        searchValue,
        filterQuery,
        staticFilters,
        groupFilter,
        ignoreUserGroups,
        hasCustomer,
        undefined,
        levels,
        topLevelOnly,
      ).pipe(map((res) => new ApiResponse<AssetsTree[]>(res.data, res.count)));
    }
    return EMPTY;
  }

  // get children for one parent node
  public getChildren(
    parentId: string,
    sortDirection: SortDirection = '',
    sortField: string = '',
  ): Observable<ApiResponse<AssetsTree[]>> {
    let params = new HttpParams();

    params = params.set('populate', true);
    params = params.set('parentId', parentId);
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }

    return this.http.get<ApiResponse<AssetsTree[]>>(this.productsUrl + '/tree/children', {
      params: params,
    });
  }

  public getParents(
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
  ): Observable<ApiResponse<Product[]>> {
    let params = new HttpParams();

    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', encodeURIComponent(String(searchValue)));
    }
    return this.http.get<any>(this.productsUrl + '/tree/parents', { params: params });
  }

  private getAssetTreesBase(
    populate?: boolean | Product,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterQuery: AssetsLegacyAdvancedFilterQuery | undefined = undefined,
    staticFilters: AssetsLegacyAdvancedStaticFilter[] | undefined = undefined,
    groupFilter: any = null,
    ignoreUserGroups: boolean = false,
    hasCustomer: boolean = false,
    ids?: string[],
    levels: number = 0,
    topLevelOnly: boolean = true,
  ): Observable<{ data: AssetsTree[]; count: number }> {
    let params = new HttpParams();

    if (populate !== undefined && populate && typeof populate === 'boolean') {
      params = params.set('populate', populate.toString());
    }
    if (populate && (populate as Product).productType) {
      params = params.set('populateProductType', 'true');
    }
    if (populate && (populate as Product).customerOrganization) {
      params = params.set('populateCustomerOrganization', 'true');
    }
    if (populate && (populate as Product).manufacturerOrganization) {
      params = params.set('populateManufacturerOrganization', 'true');
    }
    if (populate && (populate as Product).customerContact) {
      params = params.set('populateCustomerContact', 'true');
    }
    if (populate && (populate as Product).manufacturerContact) {
      params = params.set('populateManufacturerContact', 'true');
    }
    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', encodeURIComponent(String(searchValue)));
    }
    if (filterQuery) {
      params = params.set('filterQuery', JSON.stringify(filterQuery));
    }
    if (staticFilters) {
      params = params.set('staticFilters', JSON.stringify(staticFilters));
    }
    if (groupFilter) {
      params = params.set('groupId', groupFilter);
    }
    if (ignoreUserGroups) {
      params = params.set('ignoreUserGroups', 'true');
    }
    if (hasCustomer) {
      params = params.set('hasCustomer', 'true');
    }
    if (ids !== undefined) {
      params = params.set('instance_ids', JSON.stringify(ids));
    }
    params = params.set('levels', levels);
    params = params.set('topLevelOnly', topLevelOnly);
    return this.http.get<any>(this.productsUrl + '/tree', { params: params });
  }
}
