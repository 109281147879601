import { Action, ActionReducer } from '@ngrx/store';
import { tenantToTenantPublic } from '@remberg/tenants/common/main';
import { RootState } from './core-ui.definitions';
import { GlobalActions, INITIAL_GLOBAL_STATE } from './global';

export function logoutMetareducer(reducer: ActionReducer<RootState, Action>) {
  return function (state: RootState | undefined, action: Action): RootState {
    if (isLogoutCompleteAction(action)) {
      return reducer(
        {
          global: {
            ...INITIAL_GLOBAL_STATE,
            deviceType: state?.global.deviceType,
            versionInfo: state?.global.versionInfo,
            breakpoints: state?.global.breakpoints,
            isGlobalInitializationComplete: true,
            tenantPublic: action.discardTenantPublic
              ? undefined
              : tenantToTenantPublic(state?.global.tenant) ?? state?.global.tenantPublic,
            applicationDomain: state?.global?.applicationDomain,
            filesDownloadUrl: state?.global.filesDownloadUrl,
          },
          router: undefined,
        },
        action,
      );
    }

    return reducer(state, action);
  };
}

function isLogoutCompleteAction(
  action: Action,
): action is ReturnType<typeof GlobalActions.logoutComplete> {
  return action.type === GlobalActions.logoutComplete.type;
}
