import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogResult,
} from '../components';
import { DialogButtonConfig, SharedDialogConfig, SharedDialogTypeEnum } from '../definitions';

export const getConfirmationCancelMoveAway = (
  matDialog: MatDialog,
  dialogData?: Omit<SharedDialogConfig, 'dialogId'> & { panelClasses?: string[] },
): MatDialogRef<ConfirmationDialogComponent, ConfirmationDialogResult> => {
  const dialogRef = openConfirmationDialog(
    matDialog,
    {
      cancelButtonText: $localize`:@@discardChanges:Discard changes`,
      confirmButtonText: $localize`:@@keepEditing:Keep editing`,
      headerText: $localize`:@@areYouSureYouWantToDiscardChangesQuestionMark:Are you sure you want to discard changes?`,
      ...(dialogData ?? {}),
    },
    { disableClose: true, autoFocus: true },
  );

  return dialogRef;
};

export const openConfirmationDialog = (
  matDialog: MatDialog,
  dialogData: Omit<SharedDialogConfig, 'dialogId'> & { panelClasses?: string[] },
  matDialogConfig: MatDialogConfig = {},
): MatDialogRef<ConfirmationDialogComponent, ConfirmationDialogResult> => {
  const dialogConfig: SharedDialogConfig = {
    dialogId: SharedDialogTypeEnum.GenericConfirmation,
    buttonsConfig: DialogButtonConfig.ConfirmCancel,
    ...dialogData,
  };

  return matDialog.open<
    ConfirmationDialogComponent,
    ConfirmationDialogData,
    ConfirmationDialogResult
  >(ConfirmationDialogComponent, {
    autoFocus: false,
    hasBackdrop: true,
    restoreFocus: false,
    panelClass: ['confirmation-dialog', ...(dialogData.panelClasses ?? ['warning-dialog'])],
    data: dialogConfig,
    ...matDialogConfig,
  });
};
