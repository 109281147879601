import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ContactCrmData, ContactRaw, OrganizationRaw } from '@remberg/crm/common/main';
import { LanguageCodeEnum, TimeZoneEnum } from '@remberg/global/common/core';
import {
  BreadcrumbLink,
  ConnectionStatusEnum,
  LocalStorageKeys,
  SyncStateEnum,
  ToasterTypes,
} from '@remberg/global/ui';
import { Tenant, TenantPublic } from '@remberg/tenants/common/main';
import {
  RembergUser,
  RembergUserSettings,
  TwoFactorInfo,
  UserRole,
} from '@remberg/users/common/main';
import { GlobalLoadingIdEnum } from '../../enums';
import {
  ApplicationDomainState,
  BreakpointState,
  DeviceTypeState,
  UIState,
  VersionInfoState,
} from './global.definitions';

export const GlobalActions = createActionGroup({
  source: 'Global',
  events: {
    showToaster: props<{
      toasterType: ToasterTypes;
      message: string;
      /** @deprecated title is deprecated - use message instead. Only one text string per toastr allowed.  If message is not set and title is set, title will replace message as of now. */
      title: string;
      onTap?: () => void;
    }>(),
    effectError: props<{ context: string; error: unknown }>(),

    setUserRole: props<{ userRole?: UserRole }>(),
    setRembergUser: props<{ rembergUser?: RembergUser }>(),

    applicationUpdateAvailable: emptyProps(),
    permissionsUpdateAvailable: props<{ updatedUserRole: UserRole }>(),
    tenantUpdateAvailable: props<{ updatedTenant: Tenant }>(),
    applicationAndStorageUpdateInitiated: props<{ targetUrl: string }>(),

    ionicAppVersionChecked: props<{
      belowRequiredVersion: boolean;
      redirectImmediately: boolean;
    }>(),
    setSyncState: props<{ syncState: SyncStateEnum }>(),
    clearSyncState: emptyProps(),

    setConnectionStatus: props<{ connectionStatus: ConnectionStatusEnum }>(),

    intercomWebInitialized: emptyProps(),
    intercomMobileInitialized: emptyProps(),
    showIntercom: emptyProps(),

    setFilesDownloadUrl: props<{ url: string }>(),
    tenantUpdated: props<{ tenant: Tenant }>(),
    organizationUpdated: props<{ organization: OrganizationRaw }>(),
    twoFactorAuthenticationUpdated: props<{ twoFactorInfo: TwoFactorInfo }>(),
    contactCrmDataUpdated: props<{ crmData: Partial<ContactCrmData> }>(),
    languageUpdated: props<{ lang: LanguageCodeEnum }>(),
    timeZoneUpdated: props<{ tz: TimeZoneEnum }>(),

    setTenantPublic: props<{ tenantPublic?: TenantPublic }>(),
    themeColorsUpdated: props<{ primaryThemeColor?: string; backgroundThemeColor?: string }>(),
    breadcrumbsUpdated: props<{ breadcrumbs: BreadcrumbLink[] }>(),
    localUIStatePropertyUpdated: props<{ uiState: Partial<UIState> }>(),
    statePropertyPersisted: props<{ localStorageKey: LocalStorageKeys; updatedValue: unknown }>(),
    tokenRefreshed: props<{ token: string }>(),

    // ApplicationDomain
    applicationDomainInitialized: props<{ applicationDomain: ApplicationDomainState }>(),

    // Layout
    deviceTypeInitialized: props<{ deviceType: DeviceTypeState }>(),
    breakpointsUpdated: props<{ breakpoints: BreakpointState }>(),

    // VersionInfo
    versionInfoInitialized: props<{ versionInfo: VersionInfoState }>(),

    // Interface
    startInterfaceInitialization: emptyProps(),
    sideNavClosed: emptyProps(),
    sideNavToggled: emptyProps(),

    // Global Spinner
    startGlobalLoadingId: props<{ id: GlobalLoadingIdEnum }>(),
    stopGlobalLoadingId: props<{ id: GlobalLoadingIdEnum }>(),

    // User Settings
    updateUserSettings: props<{ settings: RembergUserSettings }>(),
    updateUserSettingsSuccess: emptyProps(),
    updateUserSettingsError: props<{ oldSettings?: RembergUserSettings }>(),
    resetUIUserSettings: emptyProps(),

    // Logout
    logoutInitiatedFromNavBar: emptyProps(),
    logoutConfirmedInterface: emptyProps(),
    logoutConfirmedInvalidToken: props<{ targetUrl?: string }>(),
    logoutComplete: props<{ targetUrl?: string; discardTenantPublic?: boolean }>(),

    // Session Initialization
    startRegisterSessionInfoLogin: props<{ token: string }>(),
    startRegisterSessionInfoAppInitialization: props<{ token: string }>(),
    completeRegisterSessionInfo: props<{
      organization?: OrganizationRaw;
      tenant?: Tenant;
      contact: ContactRaw;
      rembergUser: RembergUser;
      userRole?: UserRole;
    }>(),
    registerSessionInfoError: props<{ error: string }>(),

    // Global Initialization
    startGlobalInitialization: emptyProps(),
    globalInitializationComplete: emptyProps(),
    globalInitializationError: props<{ error: string }>(),
    setIsIonicAppBelowMinimumRequiredVersion: props<{
      isIonicAppBelowMinimumRequiredVersion: boolean;
    }>(),
  },
});
