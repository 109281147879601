import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumber } from '@remberg/global/common/core';
import { getPhoneNumberString } from '@remberg/global/ui';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: PhoneNumber): string {
    return getPhoneNumberString(value);
  }
}
