import { Duration, Interval, intervalToDuration } from 'date-fns';

export function intervalToDurationWithZeroes<DateType extends Date>(
  interval: Interval<DateType>,
): Duration {
  const tmpDuration = intervalToDuration(interval);
  return {
    years: tmpDuration.years ?? 0,
    months: tmpDuration.months ?? 0,
    days: tmpDuration.days ?? 0,
    hours: tmpDuration.hours ?? 0,
    minutes: tmpDuration.minutes ?? 0,
    seconds: tmpDuration.seconds ?? 0,
  };
}
