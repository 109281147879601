export function generateDBSnapshotFileName(
  curServerName: string,
  tenant: string,
  lastSyncTimeString: string,
  dbVersion: number,
  appVersion: string,
): string {
  const now = new Date(lastSyncTimeString);

  const year = now.getFullYear().toString();
  const month = padZero(now.getMonth() + 1); // Months are zero-based, so subtract 1
  const day = padZero(now.getDate());
  const hours = padZero(now.getHours());
  const minutes = padZero(now.getMinutes());

  const appVersionWithUnderscore = appVersion.replace(/\./g, '_');

  return `SQL_DB_SNAPSHOT_FROM_${curServerName}_${tenant}_${year}_${month}_${day}_${hours}-${minutes}_db_version_${dbVersion}_app_version_${appVersionWithUnderscore}.db`;
}

function padZero(value: number): string {
  return value.toString().padStart(2, '0');
}

export interface ParsedFileName {
  curServerName: string;
  tenant: string;
  lastSyncTimeString: Date;
}

export function parseFileName(fileName: string): ParsedFileName | null {
  const regex = /^SQL_DB_SNAPSHOT_FROM_(\w+)_(\w+)_(\d{4})_(\d{2})_(\d{2})_(\d{2})-(\d{2}).*\.db$/;
  const match = fileName.match(regex);

  if (!match) {
    return null;
  }

  const [, curServerName, tenant, year, month, day, hours, minutes] = match;
  const lastSyncTimeString = new Date(
    Number(year),
    Number(month) - 1, // Months are zero-based, so subtract 1
    Number(day),
    Number(hours),
    Number(minutes),
  );

  return {
    curServerName,
    tenant,
    lastSyncTimeString,
  };
}
