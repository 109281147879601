import { WorkOrderPopulateEnum } from './enums';

export const WORK_ORDER_FIND_ONE_POPULATED_FIELDS = [
  WorkOrderPopulateEnum.TYPE,
  WorkOrderPopulateEnum.STATUS,
  WorkOrderPopulateEnum.RELATED_CONTACTS,
  WorkOrderPopulateEnum.RESPONSIBLE_CONTACT,
  WorkOrderPopulateEnum.CREATED_BY,
  WorkOrderPopulateEnum.UPDATED_BY,
  WorkOrderPopulateEnum.RESPONSIBLE_GROUP,
  WorkOrderPopulateEnum.RELATED_ASSETS,
  WorkOrderPopulateEnum.RELATED_ORGANIZATION,
  WorkOrderPopulateEnum.RELATED_CASE,
  WorkOrderPopulateEnum.PARENT_WORK_ORDER,
  WorkOrderPopulateEnum.CUSTOM_PROPERTIES,
  WorkOrderPopulateEnum.PLANNING,
];
