import { getStringID } from '@remberg/global/ui';

type WithId<T> = T & { _id: any };
type Tenant = any;
type Organization = any;
type RawAccount = any;
type Company = any;
type Account = any;
type Theme = any;
type OrganizationCompatibility = any;

export enum OrganizationClassEnum {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export function accountToOrganizationAndTenant(
  account: Account,
  parentAccount?: Account,
): { organization: OrganizationCompatibility; tenant?: Tenant } {
  return {
    organization: accountToOrganization(account),
    tenant: parentAccount ? accountToTenant(parentAccount) : accountToTenant(account),
  };
}

function accountToOrganization(account: Account): OrganizationCompatibility {
  return {
    _id: account._id,
    creatorId: getStringID(account.createContext?.source),
    tenantOrganizationId: getStringID(account.parentAccount),
    tenantId: getStringID(account.tenantId),
    isTenantOwner: !account.parentAccount,

    class: account.parentAccount ? OrganizationClassEnum.EXTERNAL : OrganizationClassEnum.INTERNAL,

    crmData: {
      name: account.company.companyName,
      externalReference: account.customerNumber as string,
      shippingAddress: account.company.billingAddress,
      logoFileId: getStringID(account.company.favicon),
      websiteUrl: account.company.website,
      phoneNumbers: account.company.phone
        ? [{ id: 0, label: 'Default', phoneNumber: account.company.phone as any }]
        : [],
      emails: account.company.companyEmail
        ? [{ id: 0, label: 'Default', email: account.company.companyEmail }]
        : [],
      lang: account.lang as any,
      tz: account.tz,
    },
  };
}

function accountToTenant(account: Account): Tenant | undefined {
  if (account.parentAccount) {
    return undefined;
  }
  return {
    _id: getStringID(account.tenantId),
    allUsersGroupId: getStringID(account.allUsersGroup),
    ownerOrganizationId: getStringID(account._id),
    isActivated: !!account.activated,
    createdAt: account.createdAt as any,
    subdomain: account.subdomain as any,
    internalFeatureFlags: account.features as any,
    externalFeatureFlags: account.managedFeatures as any,
    isStatisticsEnabled: account.trackingEnabled,
    isSupportEnabled: account.isSupportEnabled,
    trackingInfo: account.prmVersion,
    label: account.company.companyName,
    theme: account.theme
      ? {
          workspaceName: account.theme.workspaceName ?? account.company.companyName,
          logoFileId: getStringID(account.theme.logo),
          faviconUrl: account.theme.faviconLink,
          primaryThemeColor: account.theme.primaryThemeColor,
          backgroundThemeColor: account.theme.backgroundThemeColor,
          isBetaBannerDisplayed: account.theme.showBetaBanner,
        }
      : {
          workspaceName: account.company.companyName,
        },

    configuration: {
      allowedLangs: account.allowedLangs ?? ([] as any),

      defaultTimePickerIntervalInMin: account.defaultTimePickerInterval,
      isPasswordChangeEnforced: account.forcePasswordChange,
      passwordChangeExpirationIntervalInMs: account.passwordChangeExpiration,

      supportEmailAddresses: account.supportEmailAddresses?.length
        ? account.supportEmailAddresses
        : ([] as any),
      caseNotifyContactIds: account.ticketNotifyUsers?.length
        ? account.ticketNotifyUsers.map(getStringID)
        : [],
      caseNotifyUserGroupIds: account.ticketNotifyUserGroups?.length
        ? account.ticketNotifyUserGroups.map(getStringID)
        : [],
      accessRequestNotifyContactIds: account.accessRequestNotifyUsers?.length
        ? account.accessRequestNotifyUsers.map(getStringID)
        : [],
      accessRequestNotifyUserGroupIds: account.accessRequestNotifyUserGroups?.length
        ? account.accessRequestNotifyUserGroups.map(getStringID)
        : [],

      defaultWorkOrderStatusId: getStringID(account.defaultWorkOrderStatus),

      isAssetSerialNumberUnique: account.uniqueSerialNumbers,
      assetCustomLinks: account.customLinks ?? [],
      defaultCurrencyCode: account.defaultCurrencyCode,
    },
  };
}
