import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AdvancedFilter,
  AdvancedFilterConcatOperatorEnum,
  AdvancedFilterOperatorEnum,
  AdvancedFilterQuery,
} from '@remberg/advanced-filters/common/main';
import { ASSETS_OFFLINE_SERVICE } from '@remberg/assets/ui/clients';
import { FilterType, INSTANCE_COUNTER_REGEX } from '@remberg/global/common/core';
import {
  ApiResponse,
  LogService,
  SQLQueryParams,
  SQLSortDirection,
  SyncDataTypesEnum,
  UnreachableCaseError,
  assertDefined,
  getStringID,
  isObjectID,
  stringToSQLSortDirection,
} from '@remberg/global/ui';
import { WorkOrder, WorkOrderAPIFilterFieldEnum } from '@remberg/work-orders-legacy/common/main';
import {
  GetWorkOrdersOfflineInput,
  WorkOrderOfflineServiceInterface,
} from '@remberg/work-orders-legacy/ui/clients';
import {
  SQLConcatOperator,
  concatSQLFiltersByOperator,
  generateBooleanSQLFilterItemValue,
  generateContainsSQLFilter,
  generateDoesNotContainSQLFilter,
  generateEqualsSQLFilter,
  generateIsEmptySQLFilter,
  generateNotEmptySQLFilter,
  generateNotEqualsSQLFilter,
  sqlFiltersHelper,
} from '../..//helpers/sqlFiltersHelper';
import { RootGlobalState } from '../../store';
import { AuthenticationService } from '../authentication.service';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService, populateArray } from '../sqlDB.service';

enum ColumnNamesEnum {
  ASSETS = 'assets',
  ASSIGNED_ORGANIZATION = 'assignedOrganization',
  PRIORITY = 'priority',
  TO_PERFORM_BY_CONTACT = 'toPerformByContact',
  RESPONSIBLE_CONTACT = 'responsibleContact',
  TO_PERFORM_BY_GROUP = 'toPerformByGroup',
  RESPONSIBLE_GROUP = 'responsibleGroup',
  STATUS_COMPLETE = 'status_complete',
  STATUS_STATUS_REF = 'status_statusRef',
  TYPE = 'type',
  TYPE_TYPE_REF = 'type_typeRef',
  RELATED_SERVICE_CASE = 'relatedServiceCase',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  DUE_DATE = 'dueDate',
  CREATED_AT = 'createdAt',
  LAST_MODIFIED = 'lastModified',
  TITLE = 'title',
  WORK_ORDER_ID = 'workOrderID',
  TYPE_ORDER_VALUE = 'type_orderValue',
  STATUS_ORDER_VALUE = 'status_orderValue',
  ATTENDANT_CONTACTS = 'attendantContacts',
  ERP_REFERENCE = 'erpReference',
}

enum WorkOrderOfflinePopulateEnum {
  ASSETS = ColumnNamesEnum.ASSETS,
  ASSIGNED_ORGANIZATION = ColumnNamesEnum.ASSIGNED_ORGANIZATION,
  TO_PERFORM_BY_CONTACT = ColumnNamesEnum.TO_PERFORM_BY_CONTACT,
  RESPONSIBLE_CONTACT = ColumnNamesEnum.RESPONSIBLE_CONTACT,
  TO_PERFORM_BY_GROUP = ColumnNamesEnum.TO_PERFORM_BY_GROUP,
  RESPONSIBLE_GROUP = ColumnNamesEnum.RESPONSIBLE_GROUP,
  RELATED_SERVICE_CASE = ColumnNamesEnum.RELATED_SERVICE_CASE,
  ATTENDANT_CONTACTS = ColumnNamesEnum.ATTENDANT_CONTACTS,
  DESCRIPTION_FILES = 'descriptionFiles',
  CONTACTS = 'contacts',
}

const PARAMS: SQLQueryParams<ColumnNamesEnum, WorkOrderOfflinePopulateEnum, keyof WorkOrder> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.WORKORDERS,
  columns: {
    [ColumnNamesEnum.ASSETS]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) =>
        (val?.assets as unknown[])?.map((asset) => getStringID(asset)).join(','),
    },
    [ColumnNamesEnum.ASSIGNED_ORGANIZATION]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => getStringID(val?.assignedOrganization),
    },
    [ColumnNamesEnum.PRIORITY]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => val?.priority,
    },
    [ColumnNamesEnum.TO_PERFORM_BY_CONTACT]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => getStringID(val?.toPerformByContact),
    },
    [ColumnNamesEnum.RESPONSIBLE_CONTACT]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => getStringID(val?.responsibleContact),
    },
    [ColumnNamesEnum.TO_PERFORM_BY_GROUP]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => getStringID(val?.toPerformByGroup),
    },
    [ColumnNamesEnum.RESPONSIBLE_GROUP]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => getStringID(val?.responsibleGroup),
    },
    [ColumnNamesEnum.STATUS_COMPLETE]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrder) => (val?.status?.complete ? 1 : 0),
    },
    [ColumnNamesEnum.STATUS_STATUS_REF]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) =>
        val?.status ? getStringID(val.status.statusRef) : undefined,
    },
    [ColumnNamesEnum.TYPE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => getStringID(val?.type),
    },
    [ColumnNamesEnum.TYPE_TYPE_REF]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => (val?.type ? getStringID(val.type.typeRef) : undefined),
    },
    [ColumnNamesEnum.RELATED_SERVICE_CASE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => getStringID(val?.relatedServiceCase),
    },
    [ColumnNamesEnum.START_DATE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => (val?.startDate ? String(val.startDate) : undefined),
    },
    [ColumnNamesEnum.END_DATE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => (val?.endDate ? String(val.endDate) : undefined),
    },
    [ColumnNamesEnum.DUE_DATE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => (val?.dueDate ? String(val.dueDate) : undefined),
    },
    [ColumnNamesEnum.CREATED_AT]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => (val?.createdAt ? String(val.createdAt) : undefined),
    },
    [ColumnNamesEnum.LAST_MODIFIED]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => (val?.lastModified ? String(val.lastModified) : undefined),
    },
    [ColumnNamesEnum.TITLE]: {
      type: 'TEXT',
      // assure offline sorting works as expected
      valueFunction: (val: WorkOrder) => val?.title?.toLowerCase()?.trim(),
      sortNoCase: true,
    },
    [ColumnNamesEnum.WORK_ORDER_ID]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrder) => val?.workOrderID,
    },
    [ColumnNamesEnum.TYPE_ORDER_VALUE]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrder) => val?.type?.orderValue,
    },
    [ColumnNamesEnum.STATUS_ORDER_VALUE]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrder) => val?.status?.orderValue,
    },
    [ColumnNamesEnum.ATTENDANT_CONTACTS]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) =>
        (val?.attendantContacts as unknown[])?.map(getStringID).join(','),
    },
    [ColumnNamesEnum.ERP_REFERENCE]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrder) => val?.erpReference,
    },
  },
  populates: {
    [WorkOrderOfflinePopulateEnum.ASSETS]: {
      targetKey: 'assets',
      populateFunction: async (val: WorkOrder, logger: LogService) => {
        const assetsOfflineService = inject(ASSETS_OFFLINE_SERVICE);
        if (!val.assets) return;

        return await assetsOfflineService.getManyByIds(val.assets);
      },
    },
    [WorkOrderOfflinePopulateEnum.ASSIGNED_ORGANIZATION]: {
      populateDataType: SyncDataTypesEnum.ORGANIZATIONS,
      sourceKey: ColumnNamesEnum.ASSIGNED_ORGANIZATION,
      targetKey: 'assignedOrganization',
    },
    [WorkOrderOfflinePopulateEnum.TO_PERFORM_BY_CONTACT]: {
      populateDataType: SyncDataTypesEnum.CONTACTS,
      sourceKey: ColumnNamesEnum.TO_PERFORM_BY_CONTACT,
      targetKey: 'toPerformByContact',
    },
    [WorkOrderOfflinePopulateEnum.RESPONSIBLE_CONTACT]: {
      populateDataType: SyncDataTypesEnum.CONTACTS,
      sourceKey: ColumnNamesEnum.RESPONSIBLE_CONTACT,
      targetKey: 'responsibleContact',
    },
    [WorkOrderOfflinePopulateEnum.TO_PERFORM_BY_GROUP]: {
      populateDataType: SyncDataTypesEnum.USERGROUPS,
      sourceKey: ColumnNamesEnum.TO_PERFORM_BY_GROUP,
      targetKey: 'toPerformByGroup',
    },
    [WorkOrderOfflinePopulateEnum.RESPONSIBLE_GROUP]: {
      populateDataType: SyncDataTypesEnum.USERGROUPS,
      sourceKey: ColumnNamesEnum.RESPONSIBLE_GROUP,
      targetKey: 'responsibleGroup',
    },
    [WorkOrderOfflinePopulateEnum.RELATED_SERVICE_CASE]: {
      populateDataType: SyncDataTypesEnum.SERVICECASES,
      sourceKey: ColumnNamesEnum.RELATED_SERVICE_CASE,
      targetKey: 'relatedServiceCase',
    },
    [WorkOrderOfflinePopulateEnum.DESCRIPTION_FILES]: {
      targetKey: 'descriptionFiles',
      populateFunction: async (val: WorkOrder, logger: LogService) =>
        await populateArray(SyncDataTypesEnum.FILES, val?.descriptionFiles, logger),
    },
    [WorkOrderOfflinePopulateEnum.CONTACTS]: {
      targetKey: 'contacts',
      populateFunction: async (val: WorkOrder, logger: LogService) =>
        await populateArray(SyncDataTypesEnum.CONTACTS, val?.contacts, logger),
    },
    [WorkOrderOfflinePopulateEnum.ATTENDANT_CONTACTS]: {
      targetKey: 'attendantContacts',
      populateFunction: async (val: WorkOrder, logger: LogService) =>
        await populateArray(SyncDataTypesEnum.CONTACTS, val?.attendantContacts, logger),
    },
  },
};

const WORK_ORDER_FILTER_ENUM_TO_COLUMN_NAME: Record<WorkOrderAPIFilterFieldEnum, string> = {
  [WorkOrderAPIFilterFieldEnum.ASSET]: 'assets',
  [WorkOrderAPIFilterFieldEnum.RESPONSIBLE_CONTACT]: 'responsibleContact',
  [WorkOrderAPIFilterFieldEnum.ASSIGNED_ORGANIZATION]: 'assignedOrganization',
  [WorkOrderAPIFilterFieldEnum.TO_PERFORM_BY_CONTACT]: 'toPerformByContact',
  [WorkOrderAPIFilterFieldEnum.PRIORITY]: 'priority',
  [WorkOrderAPIFilterFieldEnum.STATUS]: 'status_statusRef',
  [WorkOrderAPIFilterFieldEnum.STATUS_COMPLETED]: 'status_complete',
  [WorkOrderAPIFilterFieldEnum.TYPE]: 'type_typeRef',
  [WorkOrderAPIFilterFieldEnum.RELATED_SERVICE_CASE]: 'relatedServiceCase',
  [WorkOrderAPIFilterFieldEnum.TO_PERFORM_BY_GROUP]: 'toPerformByGroup',
  [WorkOrderAPIFilterFieldEnum.RESPONSIBLE_BY_GROUP]: 'responsibleGroup',
  [WorkOrderAPIFilterFieldEnum.ATTENDANT]: 'attendantContacts',
  [WorkOrderAPIFilterFieldEnum.MAINTENANCEPLAN]: 'createContext.source',
  [WorkOrderAPIFilterFieldEnum.ERP_REFERENCE]: 'erpReference',
  [WorkOrderAPIFilterFieldEnum.TOUCHED]: 'touchedByUser',
  [WorkOrderAPIFilterFieldEnum.CREATED]: 'createdAt',
  [WorkOrderAPIFilterFieldEnum.UPDATED]: 'lastModified',
  [WorkOrderAPIFilterFieldEnum.START_DATE]: 'startDate',
  [WorkOrderAPIFilterFieldEnum.END_DATE]: 'endDate',
  [WorkOrderAPIFilterFieldEnum.DUE_DATE]: 'dueDate',
  [WorkOrderAPIFilterFieldEnum.CITY]: 'city',
  [WorkOrderAPIFilterFieldEnum.COUNTRY]: 'country',
  [WorkOrderAPIFilterFieldEnum.COUNTRY_PROVINCE]: 'countryProvince',
  [WorkOrderAPIFilterFieldEnum.ZIP_CODE]: 'zipPostCode',
  // These filter types have a custom logic
  [WorkOrderAPIFilterFieldEnum.SCHEDULED]: 'CUSTOM_LOGIC',
  [WorkOrderAPIFilterFieldEnum.INVOLVED_CONTACT]: 'CUSTOM_LOGIC',
  [WorkOrderAPIFilterFieldEnum.MY_GROUPS]: 'CUSTOM_LOGIC',
  [WorkOrderAPIFilterFieldEnum.BOUNDING_BOX]: 'CUSTOM_LOGIC',
};

const REMBERG_DATE_COLUMNS = [WorkOrderAPIFilterFieldEnum.DUE_DATE];

// Currently we do not support filtering by custom properties in offline mode. Custom properties are not synced in offline mode.
export type WorkOrderOfflineModeAdvancedFilterQuery =
  AdvancedFilterQuery<WorkOrderAPIFilterFieldEnum>;

@Injectable()
export class WorkOrderOfflineService
  extends BaseOfflineService<WorkOrder, WorkOrderAPIFilterFieldEnum>
  implements WorkOrderOfflineServiceInterface
{
  constructor(
    private authenticationService: AuthenticationService,
    dbService: SqlDBService,
    logger: LogService,
    store: Store<RootGlobalState>,
  ) {
    super(dbService, PARAMS, logger, store);
  }

  public async getWorkOrdersWithCount({
    limit,
    offset,
    sortColumn,
    sortDirection,
    searchValue,
    filters,
    filterQuery,
    populate,
  }: GetWorkOrdersOfflineInput): Promise<ApiResponse<WorkOrder[]>> {
    const filterStrings: string[] = [];

    // filters
    if (filters?.length) {
      filterStrings.push(this.getFilterString(filters));
    }

    // advanced filters
    const advancedFilterString = this.getAdvancedFiltersString(filterQuery);
    if (advancedFilterString) {
      filterStrings.push(advancedFilterString);
    }

    // search:
    if (searchValue) {
      const matches = searchValue.match(INSTANCE_COUNTER_REGEX);
      if (!matches?.[1] || isNaN(parseInt(matches[1], 10))) {
        filterStrings.push(`${PARAMS.tableName}.title LIKE '%${searchValue}%'`);
      } else {
        filterStrings.push(
          `(${PARAMS.tableName}.title LIKE '%${searchValue}%' OR ${
            PARAMS.tableName
          }.workOrderID = ${parseInt(matches[1], 10)})`,
        );
      }
    }

    // sorting (must be mapped to the right column):
    let sqlSortDirection: SQLSortDirection | undefined = stringToSQLSortDirection(sortDirection);
    if (sortColumn === 'priority') {
      sortColumn = 'priority';
    } else if (sortColumn === 'type') {
      sortColumn = 'type_orderValue';
    } else if (sortColumn === 'status') {
      sortColumn = 'status_orderValue';
    } else if (sortColumn === 'counter') {
      sortColumn = 'workOrderID';
    } else if (sortColumn === 'dueDate') {
      sortColumn = 'dueDate';
      // dueDate uses a different sort order:
      if (sqlSortDirection === SQLSortDirection.ASC) {
        sqlSortDirection = SQLSortDirection.ASC_NULLS_LAST;
      } else if (sqlSortDirection === SQLSortDirection.DESC) {
        sqlSortDirection = SQLSortDirection.DESC_NULLS_FIRST;
      }
    } else if (sortColumn === 'startDate') {
      sortColumn = 'startDate';
    }

    return this.getInstancesWithCount(
      limit,
      offset,
      sortColumn,
      sqlSortDirection,
      filterStrings.join(' AND '),
      populate,
    );
  }

  private getFilterString(filters?: FilterType<string>[]): string {
    const userGroupIds = this.authenticationService.getSessionInfo().rembergUser.userGroupIds;
    const filterStrings: string[] = [];

    for (const filterObject of filters ?? []) {
      if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.ASSET) {
        if (filterObject.value === 'undefined') {
          filterStrings.push(
            `(${PARAMS.tableName}.assets IS NULL OR ${PARAMS.tableName}.assets = '')`,
          );
        } else if (isObjectID(getStringID(filterObject.value))) {
          filterStrings.push(
            `${PARAMS.tableName}.assets LIKE '%${getStringID(filterObject.value)}%'`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.ASSIGNED_ORGANIZATION) {
        if (filterObject.value === 'undefined') {
          filterStrings.push(`${PARAMS.tableName}.assignedOrganization IS NULL`);
        } else {
          filterStrings.push(
            `${PARAMS.tableName}.assignedOrganization = '${getStringID(filterObject.value)}'`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.PRIORITY) {
        if (filterObject.value === 'none') {
          filterStrings.push(`${PARAMS.tableName}.priority IS NULL`);
        } else {
          filterStrings.push(`${PARAMS.tableName}.priority = '${filterObject.value}'`);
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.STATUS_COMPLETED) {
        if (filterObject.value === 'true') {
          filterStrings.push(`${PARAMS.tableName}.status_complete = 1`);
        } else {
          filterStrings.push(`${PARAMS.tableName}.status_complete = 0`);
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.STATUS) {
        filterStrings.push(
          `${PARAMS.tableName}.status_statusRef = '${getStringID(filterObject.value)}'`,
        );
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.RESPONSIBLE_CONTACT) {
        if (filterObject.value === 'undefined') {
          filterStrings.push(`${PARAMS.tableName}.responsibleContact IS NULL`);
        } else {
          filterStrings.push(
            `${PARAMS.tableName}.responsibleContact = '${getStringID(filterObject.value)}'`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.TO_PERFORM_BY_CONTACT) {
        if (filterObject.value === 'undefined') {
          filterStrings.push(`${PARAMS.tableName}.toPerformByContact IS NULL`);
        } else {
          filterStrings.push(
            `${PARAMS.tableName}.toPerformByContact = '${getStringID(filterObject.value)}'`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.TYPE) {
        if (filterObject.value === 'none') {
          filterStrings.push(`${PARAMS.tableName}.type IS NULL`);
        } else {
          filterStrings.push(`type_typeRef = '${getStringID(filterObject.value)}'`);
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.RELATED_SERVICE_CASE) {
        if (filterObject.value === 'none') {
          filterStrings.push(`${PARAMS.tableName}.relatedServiceCase IS NULL`);
        } else {
          filterStrings.push(
            `${PARAMS.tableName}.relatedServiceCase = '${getStringID(filterObject.value)}'`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.RESPONSIBLE_BY_GROUP) {
        if (filterObject.value === 'none') {
          filterStrings.push(`${PARAMS.tableName}.responsibleGroup IS NULL`);
        } else {
          filterStrings.push(
            `${PARAMS.tableName}.responsibleGroup = '${getStringID(filterObject.value)}'`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.TO_PERFORM_BY_GROUP) {
        if (filterObject.value === 'none') {
          filterStrings.push(`${PARAMS.tableName}.toPerformByGroup IS NULL`);
        } else {
          filterStrings.push(
            `${PARAMS.tableName}.toPerformByGroup = '${getStringID(filterObject.value)}'`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.SCHEDULED) {
        if (filterObject.value === 'true') {
          filterStrings.push(
            `${PARAMS.tableName}.startDate IS NOT NULL AND ${PARAMS.tableName}.endDate IS NOT NULL`,
          );
        } else if (filterObject.value === 'false') {
          filterStrings.push(
            `(${PARAMS.tableName}.startDate IS NULL OR ${PARAMS.tableName}.endDate IS NULL)`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.START_DATE) {
        filterStrings.push(
          `${PARAMS.tableName}.startDate >= ${PARAMS.tableName}.datetime(` +
            filterObject.value +
            ')',
        );
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.END_DATE) {
        filterStrings.push(
          `${PARAMS.tableName}.endDate <= ${PARAMS.tableName}.datetime(` + filterObject.value + ')',
        );
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.INVOLVED_CONTACT) {
        // must be responsibleContact or toPerformByContact or group:
        filterStrings.push(
          '(' +
            `${PARAMS.tableName}.toPerformByContact = '${getStringID(filterObject.value)}'` +
            ` OR ${PARAMS.tableName}.attendantContacts LIKE '%${getStringID(
              filterObject.value,
            )}%'` +
            ` OR ${PARAMS.tableName}.responsibleContact = '${getStringID(filterObject.value)}'` +
            ')',
        );
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.MY_GROUPS) {
        if (filterObject.value === 'true' && userGroupIds?.length) {
          // must be responsible or toPerformBy group:
          filterStrings.push(
            '(' +
              `${PARAMS.tableName}.toPerformByGroup IN ('${userGroupIds.join("', '")}')` +
              ` OR ${PARAMS.tableName}.responsibleGroup IN ('${userGroupIds.join("', '")}')` +
              ')',
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.ATTENDANT) {
        if (filterObject.value === 'none') {
          filterStrings.push(
            `(${PARAMS.tableName}.attendantContacts IS NULL OR ${PARAMS.tableName}.attendantContacts = '')`,
          );
        } else if (isObjectID(getStringID(filterObject.value))) {
          filterStrings.push(
            `${PARAMS.tableName}.attendantContacts LIKE '%${getStringID(filterObject.value)}%'`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.MAINTENANCEPLAN) {
        if (filterObject.value === 'none') {
          // DO NOTHING
        } else if (isObjectID(getStringID(filterObject.value))) {
          filterStrings.push(
            `${PARAMS.tableName}.createContext.source = '${getStringID(filterObject.value)}'`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.TOUCHED) {
        if (filterObject.value === 'true') {
          filterStrings.push(
            `${PARAMS.tableName}.touchedByUser IS NOT NULL AND ${PARAMS.tableName}.endDate IS NOT NULL`,
          );
        } else if (filterObject.value === 'false') {
          filterStrings.push(
            `(${PARAMS.tableName}.touchedByUser IS NULL OR ${PARAMS.tableName}.endDate IS NULL)`,
          );
        }
      } else if (filterObject.identifier === WorkOrderAPIFilterFieldEnum.ERP_REFERENCE) {
        if (filterObject.value === 'none') {
          filterStrings.push(
            `(${PARAMS.tableName}.erpReference IS NULL OR ${PARAMS.tableName}.erpReference = '')`,
          );
        } else if (filterObject.value) {
          filterStrings.push(`${PARAMS.tableName}.erpReference LIKE '%${filterObject.value}%'`);
        }
      }
    }

    return concatSQLFiltersByOperator(filterStrings, SQLConcatOperator.AND);
  }

  public override getAdvancedFiltersString(
    filterQuery?: WorkOrderOfflineModeAdvancedFilterQuery,
  ): string | undefined {
    if (!filterQuery?.filters.length) {
      return undefined;
    }

    const sqlFilters = filterQuery.filters.map((filter) => this.getAdvancedFilterString(filter));

    return concatSQLFiltersByOperator(
      sqlFilters,
      filterQuery.concatOperator === AdvancedFilterConcatOperatorEnum.OR
        ? SQLConcatOperator.OR
        : SQLConcatOperator.AND,
    );
  }

  public override getAdvancedFilterString(
    filter: AdvancedFilter<WorkOrderAPIFilterFieldEnum>,
  ): string {
    const columnName = WORK_ORDER_FILTER_ENUM_TO_COLUMN_NAME[filter.identifier];
    const tableName = PARAMS.tableName;

    switch (filter.identifier) {
      case WorkOrderAPIFilterFieldEnum.ASSET:
      case WorkOrderAPIFilterFieldEnum.RESPONSIBLE_CONTACT:
      case WorkOrderAPIFilterFieldEnum.ASSIGNED_ORGANIZATION:
      case WorkOrderAPIFilterFieldEnum.TO_PERFORM_BY_CONTACT:
      case WorkOrderAPIFilterFieldEnum.PRIORITY:
      case WorkOrderAPIFilterFieldEnum.STATUS:
      case WorkOrderAPIFilterFieldEnum.TYPE:
      case WorkOrderAPIFilterFieldEnum.RELATED_SERVICE_CASE:
      case WorkOrderAPIFilterFieldEnum.TO_PERFORM_BY_GROUP:
      case WorkOrderAPIFilterFieldEnum.RESPONSIBLE_BY_GROUP:
      case WorkOrderAPIFilterFieldEnum.ATTENDANT:
      case WorkOrderAPIFilterFieldEnum.MAINTENANCEPLAN:
      case WorkOrderAPIFilterFieldEnum.ERP_REFERENCE:
      case WorkOrderAPIFilterFieldEnum.CITY:
      case WorkOrderAPIFilterFieldEnum.COUNTRY:
      case WorkOrderAPIFilterFieldEnum.COUNTRY_PROVINCE:
      case WorkOrderAPIFilterFieldEnum.ZIP_CODE:
        return sqlFiltersHelper(filter, `${tableName}.${columnName}`);
      case WorkOrderAPIFilterFieldEnum.CREATED:
      case WorkOrderAPIFilterFieldEnum.UPDATED:
      case WorkOrderAPIFilterFieldEnum.START_DATE:
      case WorkOrderAPIFilterFieldEnum.END_DATE:
      case WorkOrderAPIFilterFieldEnum.DUE_DATE: {
        const isRembergDate = REMBERG_DATE_COLUMNS.includes(filter.identifier);
        return sqlFiltersHelper(filter, `${tableName}.${columnName}`, isRembergDate);
      }
      case WorkOrderAPIFilterFieldEnum.STATUS_COMPLETED:
        return generateBooleanSQLFilterItemValue(filter, `${tableName}.${columnName}`);
      case WorkOrderAPIFilterFieldEnum.TOUCHED:
        return generateBooleanSQLFilterItemValue(filter, `${tableName}.${columnName}`);
      case WorkOrderAPIFilterFieldEnum.SCHEDULED: {
        if (filter.value === 'true') {
          return concatSQLFiltersByOperator(
            [
              generateNotEmptySQLFilter(`${tableName}.startDate`),
              generateNotEmptySQLFilter(`${tableName}.endDate`),
            ],
            SQLConcatOperator.AND,
          );
        } else {
          return concatSQLFiltersByOperator(
            [
              generateIsEmptySQLFilter(`${tableName}.startDate`),
              generateIsEmptySQLFilter(`${tableName}.endDate`),
            ],
            SQLConcatOperator.OR,
          );
        }
      }
      case WorkOrderAPIFilterFieldEnum.BOUNDING_BOX: {
        return ''; // Not supported in offline mode
      }
      case WorkOrderAPIFilterFieldEnum.INVOLVED_CONTACT: {
        switch (filter.operator) {
          case AdvancedFilterOperatorEnum.IS:
            assertDefined(filter.value, 'filter.value should be defined for filter operator is');
            return concatSQLFiltersByOperator(
              [
                `${generateEqualsSQLFilter(`${tableName}.toPerformByContact`, filter.value)}`,
                `${generateEqualsSQLFilter(`${tableName}.responsibleContact`, filter.value)}`,
                `${generateContainsSQLFilter(`${tableName}.attendantContacts`, filter.value)}`,
              ],
              SQLConcatOperator.OR,
            );
          case AdvancedFilterOperatorEnum.IS_NOT:
            assertDefined(
              filter.value,
              'filter.value should be defined for filter operator is not',
            );
            return concatSQLFiltersByOperator(
              [
                `${generateNotEqualsSQLFilter(`${tableName}.toPerformByContact`, filter.value)}`,
                `${generateNotEqualsSQLFilter(`${tableName}.responsibleContact`, filter.value)}`,
                `${generateDoesNotContainSQLFilter(
                  `${tableName}.attendantContacts`,
                  filter.value,
                )}`,
              ],
              SQLConcatOperator.AND,
            );
          case AdvancedFilterOperatorEnum.IS_EMPTY:
            return concatSQLFiltersByOperator(
              [
                `${generateIsEmptySQLFilter(`${tableName}.toPerformByContact`)}`,
                `${generateIsEmptySQLFilter(`${tableName}.responsibleContact`)}`,
                `${generateIsEmptySQLFilter(`${tableName}.attendantContacts`)}`,
              ],
              SQLConcatOperator.AND,
            );
          case AdvancedFilterOperatorEnum.IS_NOT_EMPTY:
            return concatSQLFiltersByOperator(
              [
                `${generateNotEmptySQLFilter(`${tableName}.toPerformByContact`)}`,
                `${generateNotEmptySQLFilter(`${tableName}.responsibleContact`)}`,
                `${generateNotEmptySQLFilter(`${tableName}.attendantContacts`)}`,
              ],
              SQLConcatOperator.OR,
            );
          default:
            throw new Error(
              `Operator ${filter.operator} can't be used for ${WorkOrderAPIFilterFieldEnum.INVOLVED_CONTACT} filter type`,
            );
        }
      }
      case WorkOrderAPIFilterFieldEnum.MY_GROUPS: {
        const userGroupIds = this.authenticationService.getSessionInfo().rembergUser.userGroupIds;
        const userGroupsString = userGroupIds?.join("', '");

        if (filter.value === 'true') {
          return concatSQLFiltersByOperator(
            [
              `${tableName}.toPerformByGroup IN ('${userGroupsString}')`,
              `${tableName}.responsibleGroup IN ('${userGroupsString}')`,
            ],
            SQLConcatOperator.OR,
          );
        }

        return concatSQLFiltersByOperator(
          [
            concatSQLFiltersByOperator(
              [
                `${tableName}.toPerformByGroup NOT IN ('${userGroupsString}')`,
                generateIsEmptySQLFilter(`${tableName}.toPerformByGroup`),
              ],
              SQLConcatOperator.OR,
            ),
            concatSQLFiltersByOperator(
              [
                `${tableName}.responsibleGroup NOT IN ('${userGroupsString}')`,
                generateIsEmptySQLFilter(`${tableName}.responsibleGroup`),
              ],
              SQLConcatOperator.OR,
            ),
          ],
          SQLConcatOperator.AND,
        );
      }
      default:
        throw new UnreachableCaseError(filter.identifier);
    }
  }
}
