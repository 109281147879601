import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterType2, generateObjectId } from '@remberg/global/ui';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { FilteringDialogData } from '../../../dialogs/dialogs';
import { FilterField } from '../../../helpers/filters';

interface FilterType2Trackable<T extends string> extends FilterType2<T> {
  id: string;
}

@Component({
  selector: 'app-filter-desktop-dialog',
  templateUrl: './filter-desktop-dialog.component.html',
  styleUrls: ['./filter-desktop-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDesktopDialogComponent implements OnDestroy {
  protected currentFilterFields$ = new BehaviorSubject<FilterType2Trackable<string>[]>([]);
  protected availableFilterFieldOptions$ = new ReplaySubject<FilterField<string>>();
  protected availableFilterFieldOptions?: FilterField<string>;

  protected filterFieldOptions: FilterField<string>;
  protected applyButtonDisabled$ = new BehaviorSubject<boolean>(false);
  protected addFilterDisabled$ = new BehaviorSubject<boolean>(false);

  @ViewChild('filterContent') private filterContent?: ElementRef;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public config: FilteringDialogData<string>,
  ) {
    this.subscriptions.add(
      this.currentFilterFields$.subscribe((currentFilterFields) => {
        this.computeAvailableFilterFieldOptions(currentFilterFields);
        this.computeApplyButtonStatus(currentFilterFields);
        this.cdRef.markForCheck();
      }),
    );
    this.subscriptions.add(
      this.availableFilterFieldOptions$.subscribe((options) => {
        this.availableFilterFieldOptions = options;
        this.cdRef.markForCheck();
      }),
    );
    this.filterFieldOptions = config.filterFieldOptions;
    this.currentFilterFields$.next(
      config.filterFields.map((filter) => ({ ...filter, id: generateObjectId() })),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected clearAll(): void {
    this.currentFilterFields$.next([]);
  }

  protected addFilter(): void {
    this.currentFilterFields$.next([
      ...this.currentFilterFields$.value,
      {
        identifier: Object.keys(this.availableFilterFieldOptions ?? {})[0],
        value: undefined,
        valueString: '',
        id: generateObjectId(),
      },
    ]);
    // Timeout is needed to wait for the component to be rendered and new scrollHeight to be computed
    setTimeout(() => {
      this.filterContent?.nativeElement.scroll({
        top: this.filterContent.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  }

  protected filterChange(filter: FilterType2<string>, index: number): void {
    const newCurrentFilters = [...this.currentFilterFields$.value];
    newCurrentFilters[index] = { ...filter, id: newCurrentFilters[index].id };
    this.currentFilterFields$.next(newCurrentFilters);
  }

  protected removeFilter(identifier: string): void {
    const newCurrentFilters = [...this.currentFilterFields$.value];
    newCurrentFilters.splice(
      newCurrentFilters.findIndex((f) => f.identifier === identifier),
      1,
    );
    this.currentFilterFields$.next(newCurrentFilters);
  }

  /**
   * Computes the filter field options to not show the ones already selected and avoid filtering by the same identifier multiple times
   */
  private computeAvailableFilterFieldOptions(currentFilterFields: FilterType2<string>[]): void {
    const availableFilterFieldOptions = { ...this.filterFieldOptions };
    if (currentFilterFields?.length > 0) {
      for (const filterField of currentFilterFields) {
        delete availableFilterFieldOptions[filterField.identifier];
      }
    }
    this.availableFilterFieldOptions$.next(availableFilterFieldOptions);

    if (Object.keys(availableFilterFieldOptions)?.length === 0) {
      this.addFilterDisabled$.next(true);
    } else {
      this.addFilterDisabled$.next(false);
    }
  }

  private computeApplyButtonStatus(currentFilterFields: FilterType2<string>[]): void {
    let disabled = false;
    for (const filterField of currentFilterFields) {
      if (filterField.value === null || filterField.value === undefined) {
        disabled = true;
      }
    }
    this.applyButtonDisabled$.next(disabled);
  }

  protected getCurrentOutput(): FilterType2<string>[] {
    return this.currentFilterFields$.value.map((filter) => ({
      identifier: filter.identifier,
      valueString: filter.valueString,
      value: filter.value,
    }));
  }

  protected trackEntriesBy = (_: number, filter: FilterType2Trackable<string>): string => filter.id;
}
