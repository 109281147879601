import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors, NavigationalActions, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class ActivationNavigationGuardService {
  constructor(
    private store: Store<RootGlobalState>,
    private logger: LogService,
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    this.logger.debug()('ActivationNavigationGuardService - canActivate');
    const isLoggedIn = await firstValueFrom(this.store.select(GlobalSelectors.selectIsLoggedIn));
    if (isLoggedIn) {
      this.store.dispatch(NavigationalActions.goToAuthenticatedDefaultPage());
      return false;
    }

    const isSubdomainRoutingActive = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsSubdomainRoutingActive),
    );
    if (!isSubdomainRoutingActive) {
      // We will login as admin
      return true;
    }

    const tenantSubdomain = await firstValueFrom(
      this.store.select(GlobalSelectors.selectTenantSubdomain),
    );
    if (!tenantSubdomain) {
      this.store.dispatch(NavigationalActions.goToApplicationRoot({}));
      return false;
    }

    return true;
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
