import { Pipe, PipeTransform } from '@angular/core';
import { RembergDate, TableColumnDisplayOptionEnum } from '@remberg/global/common/core';
import { DatetimeService, getDifferenceInDays, isValidRembergDate } from '@remberg/ui-core/core';

@Pipe({
  name: 'rembergDateDisplay',
})
export class RembergDateDisplayPipe implements PipeTransform {
  constructor(private datetimeService: DatetimeService) {}

  public transform(
    value?: RembergDate,
    displayOption:
      | TableColumnDisplayOptionEnum
      | 'useYesterdayAndToday' = TableColumnDisplayOptionEnum.EXACT,
  ): string {
    if (!value || !isValidRembergDate(value.toString())) {
      return value ?? '';
    }
    if (displayOption === TableColumnDisplayOptionEnum.RELATIVE) {
      return this.datetimeService.getElapsedTime(value);
    }
    if (displayOption === 'useYesterdayAndToday') {
      const today = new Date();
      const differenceInDays = getDifferenceInDays(new Date(value), today);
      if (differenceInDays === 0) {
        return $localize`:@@today:Today`;
      }
      if (differenceInDays === -1) {
        return $localize`:@@yesterday:Yesterday`;
      }
    }
    return this.datetimeService.formatDate(value, 'P');
  }
}
