import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'castToString',
})
export class CastToString implements PipeTransform {
  transform(value: unknown): string {
    return value as string;
  }
}
