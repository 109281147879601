import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const MUST_MATCH_ERROR_KEY = 'mustMatch';

/**
 * @description custom validator to check that two fields match. Should only be use on the FormGroup level.
 */
export function MustMatch<T extends FormGroup>(
  referenceControlName: keyof T['controls'],
  matchingControlName: keyof T['controls'],
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Check that the validator is apply on the FormGroup level and not control
    if (!('controls' in control)) {
      return null;
    }
    const controls = control.controls as T['controls'];

    const referenceControl = controls[referenceControlName];
    const matchingControl = controls[matchingControlName];

    if (referenceControl.value !== matchingControl.value) {
      matchingControl.setErrors({ [MUST_MATCH_ERROR_KEY]: true });
    } else {
      matchingControl.setErrors(null);
    }
    return null;
  };
}
