import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isTableEmpty',
})
export class IsTableEmptyPipe implements PipeTransform {
  public transform(
    count: number | undefined,
    pageIndex: number | undefined,
    pageSize: number | undefined,
  ): boolean {
    count = count ?? 0;
    pageIndex = pageIndex ?? 0;
    pageSize = pageSize ?? 1;

    return pageIndex >= count / pageSize;
  }
}
