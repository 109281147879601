export function partial<T>(obj: PartialDeep<T>): T {
  return obj as T;
}

/**
 * Create a type from another type with all keys and nested keys set to optional.
 * Source: https://github.com/sindresorhus/type-fest/blob/main/source/partial-deep.d.ts
 */
export type PartialDeep<T> = T extends BuiltIns
  ? T
  : T extends Map<infer KeyType, infer ValueType>
    ? PartialMapDeep<KeyType, ValueType>
    : T extends Set<infer ItemType>
      ? PartialSetDeep<ItemType>
      : T extends ReadonlyMap<infer KeyType, infer ValueType>
        ? PartialReadonlyMapDeep<KeyType, ValueType>
        : T extends ReadonlySet<infer ItemType>
          ? PartialReadonlySetDeep<ItemType>
          : T extends (...args: unknown[]) => unknown
            ? T | undefined
            : T extends object
              ? T extends (infer ItemType)[] // Test for arrays/tuples, per https://github.com/microsoft/TypeScript/issues/35156
                ? ItemType[] extends T // Test for arrays (non-tuples) specifically
                  ? // Recreate relevant array type to prevent eager evaluation of circular reference
                    PartialDeep<ItemType | undefined>[]
                  : PartialObjectDeep<T> // Tuples behave properly
                : PartialObjectDeep<T>
              : unknown;

type BuiltIns = Primitive | Date | RegExp;

type Primitive = null | undefined | string | number | boolean | symbol | bigint;

/**
Same as `PartialDeep`, but accepts only `Map`s and as inputs. Internal helper for `PartialDeep`.
*/
type PartialMapDeep<KeyType, ValueType> = Map<PartialDeep<KeyType>, PartialDeep<ValueType>>;

/**
Same as `PartialDeep`, but accepts only `Set`s as inputs. Internal helper for `PartialDeep`.
*/
type PartialSetDeep<T> = Set<PartialDeep<T>>;

/**
Same as `PartialDeep`, but accepts only `ReadonlyMap`s as inputs. Internal helper for `PartialDeep`.
*/
type PartialReadonlyMapDeep<KeyType, ValueType> = ReadonlyMap<
  PartialDeep<KeyType>,
  PartialDeep<ValueType>
>;

/**
Same as `PartialDeep`, but accepts only `ReadonlySet`s as inputs. Internal helper for `PartialDeep`.
*/
type PartialReadonlySetDeep<T> = ReadonlySet<PartialDeep<T>>;

/**
Same as `PartialDeep`, but accepts only `object`s as inputs. Internal helper for `PartialDeep`.
*/
type PartialObjectDeep<ObjectType extends object> = {
  [KeyType in keyof ObjectType]?: PartialDeep<ObjectType[KeyType]>;
};
