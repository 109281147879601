import { AssetPublic } from '../../assets';
import { AssetLegacyPublic } from '../definitions';
import { createMapper } from './create-mapper';

function mapOneAssetLegacyPublicToPublic(assetLegacyPublic: AssetLegacyPublic): AssetPublic {
  return {
    _id: assetLegacyPublic._id,
    tenantId: assetLegacyPublic.tenant?.tenantId as string,
    assetTypeId: assetLegacyPublic.productType?._id,
    assetTypeLabel: assetLegacyPublic.productType?.label,
    serialNumber: assetLegacyPublic.serialNumber,
    assetTypeImageId: assetLegacyPublic.productType?.image,
    assetTypeManufacturerName: assetLegacyPublic.productType?.manufacturer,
  };
}

export const mapAssetLegacyPublicToPublic = createMapper(mapOneAssetLegacyPublicToPublic);
