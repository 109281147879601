export enum FormFieldTypesEnum {
  ADDRESS_INPUT = 'addressInput',
  ASSET_MULTI_SELECT = 'assetMultiSelect',
  ASSET_SINGLE_SELECT = 'assetSingleSelect',
  BOOLEAN_INPUT = 'booleanInput',
  ORGANIZATION_SINGLE_SELECT = 'companySingleSelect',
  DATE_INPUT = 'dateInput',
  DATE_TIME_INPUT = 'dateTimeInput',
  EXPENSE_LIST_INPUT = 'expenseListInput',
  FIELD_REPEATER = 'fieldRepeater',
  FILE_UPLOAD = 'fileUpload',
  HEADLINE_DISPLAY = 'headlineDisplay',
  HTML_DISPLAY = 'htmlDisplay',
  MULTI_LINE_TEXT_INPUT = 'multiLineTextInput',
  PART_LIST_INPUT = 'partListInput',
  PERSON_LIST_INPUT = 'personListInput',
  PHONE_NUMBER_INPUT = 'phoneNumberInput',
  RICH_TEXT_INPUT = 'richTextInput',
  SINGLE_LINE_TEXT_INPUT = 'singleLineTextInput',
  SPARE_PART_LIST_INPUT = 'sparePartListInput',
  STATIC_MULTI_SELECT = 'staticMultiSelect',
  STATIC_SINGLE_SELECT = 'staticSingleSelect',
  TASK_LIST_INPUT = 'taskListInput',
  TIME_INPUT = 'timeInput',
  TIME_TRACKING_LIST_INPUT = 'timeTrackingListInput',
  CONTACT_SINGLE_SELECT = 'userSingleSelect',
}
