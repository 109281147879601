export const environment: {
  production: boolean;
  debug: boolean;
  live: boolean;
  mobile: boolean;
  defaultServer?: string;
  servers: any;
  intercomEnabled: boolean;
  intercomWhitelistEmails: string[];
} = {
  production: false,
  debug: true,
  live: true,
  mobile: false,

  defaultServer: undefined,
  servers: {},
  intercomEnabled: true,
  intercomWhitelistEmails: [
    'intercom-test-0@remberg.de',
    'intercom-test-1@remberg.de',
    'intercom-test-2@remberg.de',
    'intercom-test-3@remberg.de',
    'intercom-test-4@remberg.de',
    'intercom-test-5@remberg.de',
    'intercom-test-6@remberg.de',
    'intercom-test-7@remberg.de',
    'intercom-test-8@remberg.de',
    'intercom-test-9@remberg.de',
  ],
};
