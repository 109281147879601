/**
 * Transforms File in base64 string to a Blob a file
 * @param  {string} base64Data
 * @param  {string} contentType
 */
export function base64toBlob(base64Data: string, contentType: string): Blob {
  contentType = contentType ?? '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}
/**
 * Helper function to map content type from an image blob header to the corresponding image file extension.
 * Currently we only suport:
 * - jpg
 * - gif
 * - png
 * - svg
 * @param  {string} contentType
 * @returns string File extension
 */
export function getFileExtensionFromMimeType(contentType: string): string {
  switch (contentType) {
    case 'image/jpeg':
      return 'jpg';
    case 'image/gif':
      return 'gif';
    case 'image/png':
      return 'png';
    case 'image/svg+xml':
      return 'svg';
    default:
      throw new Error('Copy paste image mime type not supported : ' + contentType);
  }
}

/**
 * Transforms Blob File to base64 string
 * @param  {Blob} blob
 * @returns {string} base64 string
 */
export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export function convertMegaBytesToBytes(megaBytes: number): number {
  return megaBytes * 1024 * 1024;
}
