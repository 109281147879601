import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, mergeAll } from 'rxjs/operators';

@Pipe({
  name: 'secure',
})
export class SecureImagePipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  public transform(url: string): Observable<SafeUrl> {
    const headers = new HttpHeaders().set('Cache-Control', 'private, max-age=300');
    return this.http.get(url, { responseType: 'blob', headers }).pipe(
      map((val) => {
        if (val.type === 'image/svg+xml') {
          return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val));
        } else {
          return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val));
        }
      }),
    );
  }

  public transform2base64(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(
        (val) =>
          new Observable<string>((observer) => {
            const reader = new FileReader();
            reader.onloadend = function (): void {
              const base64data = reader.result;
              observer.next(base64data as string);
            };
            reader.readAsDataURL(val);
          }),
      ),
      mergeAll(),
    );
  }
}
