import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { getRouterSelectors } from '@ngrx/router-store';
import { DefaultProjectorFn, MemoizedSelector, createSelector } from '@ngrx/store';
import { TrackingEventEnum } from '@remberg/analytics/common/main';
import { IsPageActiveEnum, RouteDataPropertyEnum } from '@remberg/global/ui';
import { selectRouter } from '../core-ui.definitions';

const {
  selectCurrentRoute, // select the current route
  selectQueryParams, // select the current route query params
  selectRouteParams, // select the current route params
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getRouterSelectors(selectRouter);

const selectIsFormInstanceDetailPage = createSelector(
  selectRouteData,
  (data) => !!data?.[IsPageActiveEnum.IS_FORM_INSTANCE_DETAIL_PAGE],
);

const selectIsPortalRoute = createSelector(
  selectUrl,
  (url) => url?.split('/').includes('portal') ?? false,
);

const selectRouteTrackingEvent = createSelector(
  selectRouteData,
  (data): TrackingEventEnum | undefined =>
    (data?.[RouteDataPropertyEnum.TRACKING_EVENT] as TrackingEventEnum) || undefined,
);

const selectRouteTrackingEventUrlParamMap = createSelector(
  selectRouteData,
  (data) =>
    (data?.[RouteDataPropertyEnum.TRACKING_EVENT_URL_PARAM_MAP] as Record<string, string>) ?? {},
);

const selectRouteTrackingEventQueryParamMap = createSelector(
  selectRouteData,
  (data) =>
    (data?.[RouteDataPropertyEnum.TRACKING_EVENT_QUERY_PARAM_MAP] as Record<string, string>) ?? {},
);

const selectRouteTrackingEventDataFromUrlParam = createSelector(
  selectRouteParams,
  selectRouteTrackingEventUrlParamMap,
  (params, trackingEventUrlParamMap) => getEventDataMap(trackingEventUrlParamMap, params),
);

const selectRouteTrackingEventDataFromQueryParam = createSelector(
  selectQueryParams,
  selectRouteTrackingEventQueryParamMap,
  (params, trackingEventQueryParamMap) => getEventDataMap(trackingEventQueryParamMap, params),
);

// Looks for the specified property in the whole router state, so that the data property can be specified at any level
const selectRouteHierarchyDataProperty = (propertyName: string) =>
  createSelector(selectRouter, (router): any => {
    let route: ActivatedRouteSnapshot | null = router?.state.root;

    while (route) {
      if (route.data?.[propertyName]) {
        return route.data?.[propertyName];
      }
      route = route.firstChild;
    }
    return undefined;
  });

const selectRouteHierarchySecondarySidebarCategory = createSelector(
  selectRouteHierarchyDataProperty(RouteDataPropertyEnum.SECONDARY_SIDEBAR_CATEGORY),
  (value): string | undefined => value,
);

const selectRouteHierarchyHasOwnSecondarySidebar = createSelector(
  selectRouteHierarchyDataProperty(RouteDataPropertyEnum.HAS_OWN_SECONDARY_SIDEBAR),
  (value): boolean => !!value,
);

const selectRouteHierarchyHasOwnTertiarySidebar = createSelector(
  selectRouteHierarchyDataProperty(RouteDataPropertyEnum.HAS_OWN_TERTIARY_SIDEBAR),
  (value): boolean => !!value,
);

const selectRouteHierarchyHasOwnSidebar = createSelector(
  selectRouteHierarchyHasOwnSecondarySidebar,
  selectRouteHierarchyHasOwnTertiarySidebar,
  (hasOwnSecondarySidebar, hasOwnTertiarySidebar): boolean =>
    hasOwnSecondarySidebar || hasOwnTertiarySidebar,
);

const selectRouteHierarchyHasOwnMainContentPadding = createSelector(
  selectRouteHierarchyDataProperty(RouteDataPropertyEnum.HAS_OWN_MAIN_CONTENT_PADDING),
  selectRouteHierarchyHasOwnSidebar,
  (hasOwnMainContentPadding, hasOwnSidebar): boolean => !!hasOwnMainContentPadding || hasOwnSidebar,
);

const selectValueFromQueryParams = (key: string) =>
  createSelector(selectQueryParams, (params) => params?.[key]);

const selectJsonParsedPrefillingQueryParams = <T>(
  key: string,
): MemoizedSelector<object, T | undefined, DefaultProjectorFn<T | undefined>> =>
  createSelector(selectValueFromQueryParams(key), (param) => {
    if (!param) return;
    return JSON.parse(param);
  });

function getEventDataMap(
  trackingEventParamMap: Record<string, string>,
  params: Params,
): Record<string, string> {
  return Object.entries(trackingEventParamMap).reduce(
    (trackingEventData, [trackingEventProperty, paramName]) => ({
      ...trackingEventData,
      [trackingEventProperty]: params[paramName],
    }),
    {} as Record<string, string>,
  );
}

export const RouterSelectors = {
  selectCurrentRoute,
  selectQueryParams,
  selectRouteParams,
  selectRouteData,
  selectUrl,
  selectIsFormInstanceDetailPage,
  selectIsPortalRoute,
  selectRouteTrackingEvent,
  selectRouteTrackingEventDataFromUrlParam,
  selectRouteTrackingEventDataFromQueryParam,
  selectRouteHierarchySecondarySidebarCategory,
  selectRouteHierarchyHasOwnTertiarySidebar,
  selectRouteHierarchyHasOwnMainContentPadding,
  selectJsonParsedPrefillingQueryParams,
};
