import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { DynamicProgressBarConfig, LogService } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { AppStateService } from '../app-state.service';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';

export async function migrateToV18(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
  appState: AppStateService,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V18...');

  // TODO: do we need to support compatibility for this migration?
  await appState.resetLocalStorage();

  await db.executeSql('PRAGMA user_version = 18;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V18.');
  return;
}
