import { RembergUserStatusEnum } from '@remberg/global/common/core';
import { RembergUser } from './definitions';

// Note that this function cannot automatically derive a value of RembergUserStatusEnum.PERMANENTLY_DISABLED since
// in such a case, the RembergUser has been deleted and in the process of the event, the contact is explicitly marked
// with RembergUserStatusEnum.PERMANENTLY_DISABLED (this process still needs to be implemented once we allow Contacts without RembergUsers).
export function getRembergUserStatus(rembergUser?: RembergUser): RembergUserStatusEnum | undefined {
  if (!rembergUser) {
    return undefined;
  }
  const activationInfo = rembergUser.activationInfo;

  if (activationInfo.activatedAt) {
    return RembergUserStatusEnum.ACTIVATED;
  }

  if (activationInfo.suspendedAt) {
    return RembergUserStatusEnum.SUSPENDED;
  }

  if (activationInfo.isInvited) {
    return RembergUserStatusEnum.INVITED;
  }
  return RembergUserStatusEnum.NOT_INVITED;
}
