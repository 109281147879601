import { Injectable } from '@angular/core';
import {
  LanguageCodeEnum as LanguageCodeCoreEnum,
  LanguageCodeEnum,
  getDefaultLanguageCodeEnumForString,
  getShortLanguageCode,
} from '@remberg/global/common/core';
import { LogService, environment } from '@remberg/global/ui';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private currentLanguage: LanguageCodeEnum;

  constructor(private logger: LogService) {
    // Get current browser language
    const parsedPath = window.location.pathname.split('/');
    const browserLangCode = parsedPath.length > 1 ? parsedPath[1] : undefined;
    try {
      this.currentLanguage = getDefaultLanguageCodeEnumForString(browserLangCode);
    } catch (error) {
      // Ignoring this error.
      this.currentLanguage = LanguageCodeEnum.EN_US;
    }
  }

  public getCurrentLanguage(): LanguageCodeEnum {
    return this.currentLanguage;
  }

  public getCurrentLanguageShort(): string | undefined {
    if (this.currentLanguage) {
      return getShortLanguageCode(this.currentLanguage);
    } else {
      return undefined;
    }
  }

  public switchLanguage(lang: LanguageCodeEnum | LanguageCodeCoreEnum): boolean | void {
    const nextLangCode = getShortLanguageCode(lang);

    this.logger.debug()('Interface language switch function called.');

    if (getShortLanguageCode(this.currentLanguage) !== nextLangCode && environment.live) {
      this.logger.debug()('Language switch started to lang -> ', nextLangCode);

      // in capacitor context (localhost) we need to redirect to the exact entry file
      const index = window.location.href.indexOf('localhost');
      if (index > -1) {
        window.location.href =
          window.location.href.substring(0, index) + `localhost/${nextLangCode}/index.html`;
        return true;
      } else {
        this.logger.debug()('Pathname ', window.location.pathname);
        window.location.href = `/${nextLangCode}/${window.location.pathname
          .split('/')
          .slice(2)
          .join('/')}${window.location.search}`;
        return true;
      }
    } else {
      this.logger.warn()('Language switch ignored since we are not on a live environment.');
    }
  }
}
