/** Allowed field to perform filtering from API */
export enum AssetsLegacyFilterFieldEnum {
  PRODUCT_TYPE = 'productType',
  CUSTOMER = 'customerOrganization',
  QR_CODE = 'qrCode',
  ZIP_CODE = 'zipPostCode',
  CITY = 'city',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  CONTACT_PERSON = 'contactPerson',
  RESPONSIBLE = 'responsible',
  INVOLVED_CONTACT = 'involvedContact',
  STATUS = 'status',
  PARENT_ASSET = 'parentAsset',
  CREATED = 'created',
  UPDATED = 'updated',
  BOUNDING_BOX = 'boundingBox',
  // For asset hierarchy detail page
  ASSET_ID = 'assetId',
  PART_ID = 'parts',
  // For user group settings
  GROUP_ID = 'groupId',
}
