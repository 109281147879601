import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'getFormErrorMessage',
})
export class GetFormErrorMessagePipe implements PipeTransform {
  public transform(
    errors: ValidationErrors | null,
    errorMessages: Record<string, string>,
  ): string | null | undefined {
    return (
      errors &&
      Object.keys(errors)
        .map((errorKey) => errorMessages[errorKey])
        .join(' ')
    );
  }
}
