import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unknownImageUrls',
})
export class UnknownImageUrlsPipe implements PipeTransform {
  private readonly unknownImageUrl = '/assets/img/placeholder/unknown.svg';
  private readonly filesUrlPattern = '/api/files/v2/download/';

  public transform(html: string | undefined): string {
    if (!html) {
      return '';
    }

    return html.replaceAll(
      new RegExp(`src\\s*=\\s*"${this.filesUrlPattern}.*?"`, 'gm'),
      `loading="lazy" src="${this.unknownImageUrl}"`,
    );
  }
}
