import { Injectable } from '@angular/core';
import JSZip, { JSZipGeneratorOptions } from 'jszip';

@Injectable({
  providedIn: 'root',
})
export class FileZipService {
  public zipFile = async (fileName: string, data: string | Blob): Promise<Blob> => {
    const zip = new JSZip();

    const options: JSZipGeneratorOptions<'blob'> = {
      compression: 'DEFLATE',
      mimeType: 'application/zip',
      compressionOptions: {
        level: 9,
      },
      type: 'blob',
    };

    if (typeof data === 'string') {
      const blobFromFile = new Blob([data], { type: 'text/plain; charset=utf-8' });
      const buffer = await blobFromFile.arrayBuffer();
      zip.file(`${fileName}`, buffer);
    } else {
      zip.file(`${fileName}`, data);
    }

    zip.file(`${fileName}`, data);

    const zipFile = await zip.generateAsync(options);

    return zipFile;
  };
}
