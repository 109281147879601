export enum ServiceCaseRightsEnum {
  TICKETS_ENABLED = 'tickets_enabled',
  TICKETS_CREATE = 'tickets_create',
  TICKETS_UPDATE = 'tickets_update',
  TICKETS_ADDANSWER = 'tickets_addAnswer', // Includes notes
  TICKETS_DELETE = 'tickets_delete',
  TICKETS_EXPORT = 'tickets_export',
  TICKETS_MOVE = 'tickets_move',
  TICKETS_ENABLE_AI = 'tickets_enableAi',
  // notes
  TICKETS_DELETE_NOTES_OWN = 'tickets_deleteNotesOwn',
  TICKETS_DELETE_NOTES_ALL = 'tickets_deleteNotesAll',
}

export enum TaskRightsEnum {
  TASKS_ENABLED = 'tasks_enabled',
  TASKS_UPDATE_ALL = 'tasks_updateAll',
  TASKS_DELETE_ALL = 'tasks_deleteAll',
}

export enum FormRightsEnum {
  FORMS_ENABLED = 'forms_enabled',
  FORMS_FILL = 'forms_fill',
  FORMS_FILL_OVERWRITE = 'forms_fillOverwrite',
  FORMS_UPDATE = 'forms_update',
  FORMS_DELETE = 'forms_delete',
  FORMS_DELETE_OWN = 'forms_deleteOwn',
  FORMS_SEND = 'forms_send',
  FORMS_NEWTYPE = 'forms_newType',
  FORMS_UPDATETYPE = 'forms_updateType',
  FORMS_DELETETYPE = 'forms_deleteType',
  FORMS_UPDATE_ASSIGNEE = 'forms_updateAssignee',
  FORMS_VIEW_ALL = 'forms_viewAll',
  FORMS_CREATE_NOTES = 'forms_createNotes',
  FORMS_DELETE_NOTES_OWN = 'forms_deleteNotesOwn',
  FORMS_DELETE_NOTES_ALL = 'forms_deleteNotesAll',
  FORMS_DUPLICATE_ALL = 'forms_duplicateAllForms',
  FORMS_DUPLICATE_OWN = 'forms_duplicateOwnForms',
}

/**
 * @deprecated - Use WorkOrderPermissionsEnum
 */
export enum WorkOrderRightsEnum {
  WORK_ORDER_ENABLED = 'workOrders_enabled',
  WORK_ORDER_CREATE = 'workOrders_create',
  WORK_ORDER_UPDATE = 'workOrders_update',
  WORK_ORDER_DELETE = 'workOrders_delete',
  WORK_ORDER_UPDATE_SCHEDULING = 'workOrders_updateScheduling',
  WORK_ORDER_VIEW_SCHEDULING = 'workOrders_viewScheduling',
  // notes
  WORK_ORDER_CREATE_NOTES = 'workOrders_createNotes',
  WORK_ORDER_DELETE_NOTES_OWN = 'workOrders_deleteNotesOwn',
  WORK_ORDER_DELETE_NOTES_ALL = 'workOrders_deleteNotesAll',
}

export enum MaintenancePlanRightsEnum {
  MAINTENANCE_PLAN_ENABLED = 'maintenancePlans_enabled',
  MAINTENANCE_PLAN_CREATE = 'maintenancePlans_create',
  MAINTENANCE_PLAN_UPDATE = 'maintenancePlans_update',
  MAINTENANCE_PLAN_DELETE = 'maintenancePlans_delete',
}
