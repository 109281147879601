import { Inject, Injectable } from '@angular/core';
import { AssetBasic, ProductType } from '@remberg/assets/common/base';
import { productToAssetBasic } from '@remberg/assets/common/main';
import {
  ASSETS_OFFLINE_SERVICE,
  ASSET_TYPES_OFFLINE_SERVICE,
  AssetTypesOfflineServiceInterface,
  AssetsOfflineServiceInterface,
} from '@remberg/assets/ui/clients';
import { ContactBasic, OrganizationBasic } from '@remberg/crm/common/base';
import {
  contactRawToContactBasic,
  organizationRawToOrganizationBasic,
} from '@remberg/crm/common/main';
import {
  CONTACTS_OFFLINE_SERVICE,
  ContactsOfflineServiceInterface,
  ORGANIZATIONS_OFFLINE_SERVICE,
  OrganizationOfflineServiceInterface,
} from '@remberg/crm/ui/clients';
import { PlatformFile } from '@remberg/files/common/main';
import {
  PLATFORM_FILES_OFFLINE_SERVICE,
  PlatformFilesOfflineServiceInterface,
} from '@remberg/files/ui/clients';
import {
  AssetMultiSelectFieldData,
  AssetSingleSelectFieldData,
  ContactSingleSelectFieldData,
  FileUploadFieldData,
  FormField,
  FormFieldData,
  FormFieldSectionData,
  FormFieldTypesEnum,
  FormInstanceData,
  FormSection,
  FormSectionData,
  FormSectionTypesEnum,
  FormTemplateConfig,
  OrganizationSingleSelectFieldData,
  RepeatableFieldData,
  RepeaterFieldData,
  SparePartListInputFieldData,
  SparePartListItem,
  TimeTrackingEntry,
  TimeTrackingListInputFieldData,
} from '@remberg/forms/common/main';
import { UnknownOr, createUnknownObject } from '@remberg/global/common/core';
import { LogService, UnreachableCaseError, as, getStringID } from '@remberg/global/ui';
import { Part } from '@remberg/parts/common/main';
import { PARTS_OFFLINE_SERVICE, PartsOfflineServiceInterface } from '@remberg/parts/ui/clients';

interface ObjectsAndTypesToPopulate {
  file: UnknownOr<PlatformFile>;
  assetType: ProductType | undefined;
  assetInfo: UnknownOr<AssetBasic>;
  contact: UnknownOr<ContactBasic>;
  organization: UnknownOr<OrganizationBasic>;
  part: Part | undefined;
}

type PopulationCache = {
  [Key in keyof ObjectsAndTypesToPopulate]: Map<string, ObjectsAndTypesToPopulate[Key]>;
};

type PopulationLoaders = {
  [Key in keyof ObjectsAndTypesToPopulate as `get${Capitalize<Key>}`]: (
    id: string,
  ) => Promise<ObjectsAndTypesToPopulate[Key] | undefined>;
};

@Injectable({
  providedIn: 'root',
})
export class FormInstancePopulateOfflineService {
  constructor(
    @Inject(ASSETS_OFFLINE_SERVICE)
    private readonly assetsService: AssetsOfflineServiceInterface,
    @Inject(ASSET_TYPES_OFFLINE_SERVICE)
    private readonly assetTypesService: AssetTypesOfflineServiceInterface,
    @Inject(CONTACTS_OFFLINE_SERVICE)
    private readonly contactService: ContactsOfflineServiceInterface,
    @Inject(PARTS_OFFLINE_SERVICE)
    private readonly partsService: PartsOfflineServiceInterface,
    @Inject(ORGANIZATIONS_OFFLINE_SERVICE)
    private readonly organizationService: OrganizationOfflineServiceInterface,
    @Inject(PLATFORM_FILES_OFFLINE_SERVICE)
    private readonly platformFilesOfflineService: PlatformFilesOfflineServiceInterface,
    private readonly logger: LogService,
  ) {}

  public async populateFormInstanceData(
    template: FormTemplateConfig,
    data: FormInstanceData<false>,
  ): Promise<FormInstanceData<true>> {
    const cache: PopulationCache = {
      assetType: new Map(),
      assetInfo: new Map(),
      file: new Map(),
      contact: new Map(),
      organization: new Map(),
      part: new Map(),
    };

    const getAssetType = getCached(cache.assetType, this.getAssetType.bind(this));

    const api: PopulationLoaders = {
      getOrganization: getCached(cache.organization, this.getOrganization.bind(this)),
      getFile: getCached(cache.file, this.getFile.bind(this)),
      getAssetType,
      getAssetInfo: getCached(cache.assetInfo, (id: string) => this.getAssetInfo(id, getAssetType)),
      getContact: getCached(cache.contact, this.getContact.bind(this)),
      getPart: getCached(cache.part, this.getPart.bind(this)),
    };

    return await populate(template, data, api);
  }

  private async getContact(id: string): Promise<UnknownOr<ContactBasic>> {
    const contact = await this.contactService.getInstance(id).catch(() => {
      this.logger.warn()(
        `Could not fetch contact for _id ${id}. Populating UnknownObject instead!`,
      );
    });

    return contact ? contactRawToContactBasic(contact) : createUnknownObject(id);
  }

  private async getPart(id: string): Promise<Part | undefined> {
    return this.partsService.getInstance(id).catch(() => {
      this.logger.warn()(`Could not fetch part for _id ${id}.`);
      return undefined;
    });
  }

  private async getOrganization(id: string): Promise<UnknownOr<OrganizationBasic>> {
    const organization = await this.organizationService.getInstance(id).catch(() => {
      this.logger.warn()(
        `Could not fetch organization for _id ${id}. Populating UnknownObject instead!`,
      );
    });

    return (
      (organization && organizationRawToOrganizationBasic(organization)) || createUnknownObject(id)
    );
  }

  private async getFile(id: string): Promise<UnknownOr<PlatformFile>> {
    const file = await this.platformFilesOfflineService.getInstance(id, false).catch(() => {
      this.logger.warn()(`Could not fetch file for _id ${id}. Populating UnknownObject instead!`);
    });

    return file || createUnknownObject(id);
  }

  private async getAssetType(id: string): Promise<ProductType | undefined> {
    return await this.assetTypesService.getInstance({ id }).catch(() => {
      this.logger.warn()(
        `Could not fetch assetType for _id ${id}. Populating UnknownObject instead!`,
      );
      return undefined;
    });
  }

  private async getAssetInfo(
    id: string,
    getAssetType: (id: string) => Promise<ProductType | undefined>,
  ): Promise<UnknownOr<AssetBasic>> {
    const asset = await this.assetsService.tryGetInstance({ id }).catch(() => {
      this.logger.warn()(`Could not fetch asset for _id ${id}. Populating UnknownObject instead!`);
    });

    const assetInfo =
      asset &&
      productToAssetBasic({
        ...asset,
        productType: (await getAssetType(getStringID(asset.productType))) || asset.productType,
      });

    return assetInfo || createUnknownObject(id);
  }
}

async function populate(
  template: FormTemplateConfig,
  data: FormInstanceData<false>,
  api: PopulationLoaders,
): Promise<FormInstanceData<true>> {
  const populatedData: FormInstanceData<true> = [];

  for (const [sectionIndex, sectionData] of data.entries()) {
    const sectionConfig = template.sections[sectionIndex];
    const populatedSectionData = await populateSection(sectionConfig, sectionData, api);

    populatedData.push(populatedSectionData);
  }

  return populatedData;
}

async function populateSection(
  config: FormSection,
  data: FormSectionData<false>,
  api: PopulationLoaders,
): Promise<FormSectionData<true>> {
  switch (config.type) {
    case FormSectionTypesEnum.FIELD_SECTION: {
      const formFieldData = data as FormFieldSectionData<false>;
      const fields: FormFieldData<true>[] = [];

      for (const [fieldIndex, fieldData] of formFieldData.fields.entries()) {
        const fieldConfig = config.fields[fieldIndex];
        const populatedFieldData = await populateField(fieldConfig, fieldData, api);

        fields.push(populatedFieldData);
      }

      return as<FormFieldSectionData<true>>({
        ...formFieldData,
        fields,
      });
    }
    case FormSectionTypesEnum.EMAIL_SECTION:
    case FormSectionTypesEnum.SIGNATURE_SECTION:
      // nothing to populate here, just clone the data
      return { ...data } as FormSectionData<true>;
    default:
      throw new UnreachableCaseError(config);
  }
}

async function populateField(
  config: FormField,
  data: FormFieldData<false>,
  api: PopulationLoaders,
): Promise<FormFieldData<true>> {
  switch (config.type) {
    case FormFieldTypesEnum.FIELD_REPEATER: {
      const entries = (data as RepeaterFieldData<false>).entries;
      const populatedEntries: RepeatableFieldData<true>[][] = [];

      for (const fields of entries) {
        const populatedEntry: RepeatableFieldData<true>[] = [];

        for (const [fieldIndex, fieldData] of fields.entries()) {
          const fieldConfig = config.config.fields[fieldIndex];
          const populatedFieldData = (await populateField(
            fieldConfig,
            fieldData,
            api,
          )) as RepeatableFieldData<true>;

          populatedEntry.push(populatedFieldData);
        }
        populatedEntries.push(populatedEntry);
      }

      return as<RepeaterFieldData<true>>({
        ...data,
        entries: populatedEntries,
      });
    }
    case FormFieldTypesEnum.ASSET_MULTI_SELECT: {
      const assetIds = (data as AssetMultiSelectFieldData<false>).entries;
      const populatedAssets: UnknownOr<AssetBasic>[] = [];
      if (assetIds) {
        for (const assetId of assetIds) {
          const asset = await api.getAssetInfo(assetId);
          if (asset) {
            populatedAssets.push(asset);
          }
        }
      }

      return as<AssetMultiSelectFieldData<true>>({
        ...data,
        entries: populatedAssets,
      });
    }
    case FormFieldTypesEnum.ASSET_SINGLE_SELECT: {
      const assetId = (data as AssetSingleSelectFieldData<false>).selectedAsset;

      return as<AssetSingleSelectFieldData<true>>({
        ...data,
        selectedAsset: assetId ? await api.getAssetInfo(assetId) : undefined,
      });
    }
    case FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT: {
      const companyId = (data as OrganizationSingleSelectFieldData<false>).selectedCompany;

      return as<OrganizationSingleSelectFieldData<true>>({
        ...data,
        selectedCompany: companyId ? await api.getOrganization(companyId) : undefined,
      });
    }
    case FormFieldTypesEnum.CONTACT_SINGLE_SELECT: {
      const contactId = (data as ContactSingleSelectFieldData<false>).user;

      return as<ContactSingleSelectFieldData<true>>({
        ...data,
        user: contactId ? await api.getContact(contactId) : undefined,
      });
    }
    case FormFieldTypesEnum.FILE_UPLOAD: {
      const fileIds = (data as FileUploadFieldData<false>).entries || [];
      const populatedFiles: UnknownOr<PlatformFile>[] = [];

      for (const fileId of fileIds) {
        const file = await api.getFile(fileId);

        if (file) {
          populatedFiles.push(file);
        }
      }

      return as<FileUploadFieldData<true>>({
        ...data,
        entries: populatedFiles,
      });
    }
    case FormFieldTypesEnum.SPARE_PART_LIST_INPUT: {
      const entries = (data as SparePartListInputFieldData<false>).entries || [];
      const populatedEntries: SparePartListItem<true>[] = [];

      for (const entry of entries) {
        const { part, ...otherProperties } = entry;

        populatedEntries.push({
          ...otherProperties,
          part: part ? await api.getPart(part) : undefined,
        });
      }

      return as<SparePartListInputFieldData<true>>({
        ...data,
        entries: populatedEntries,
      });
    }
    case FormFieldTypesEnum.TIME_TRACKING_LIST_INPUT: {
      const entries = (data as TimeTrackingListInputFieldData<false>).entries || [];
      const populatedEntries: TimeTrackingEntry<true>[] = [];

      for (const entry of entries) {
        const { technician, ...otherProperties } = entry;

        populatedEntries.push({
          ...otherProperties,
          technician: technician ? await api.getContact(technician) : undefined,
        });
      }

      return as<TimeTrackingListInputFieldData<true>>({
        ...data,
        entries: populatedEntries,
      });
    }
    case FormFieldTypesEnum.PART_LIST_INPUT:
    case FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT:
    case FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT:
    case FormFieldTypesEnum.BOOLEAN_INPUT:
    case FormFieldTypesEnum.ADDRESS_INPUT:
    case FormFieldTypesEnum.EXPENSE_LIST_INPUT:
    case FormFieldTypesEnum.RICH_TEXT_INPUT:
    case FormFieldTypesEnum.PERSON_LIST_INPUT:
    case FormFieldTypesEnum.HTML_DISPLAY:
    case FormFieldTypesEnum.DATE_INPUT:
    case FormFieldTypesEnum.TIME_INPUT:
    case FormFieldTypesEnum.HEADLINE_DISPLAY:
    case FormFieldTypesEnum.PHONE_NUMBER_INPUT:
    case FormFieldTypesEnum.TASK_LIST_INPUT:
    case FormFieldTypesEnum.DATE_TIME_INPUT:
    case FormFieldTypesEnum.STATIC_SINGLE_SELECT:
    case FormFieldTypesEnum.STATIC_MULTI_SELECT:
      return { ...data } as FormFieldData<true>;
    default:
      throw new UnreachableCaseError(config);
  }
}

function getCached<T>(
  cache: Map<string, T>,
  load: (id: string) => Promise<T>,
): (id: string) => Promise<T | undefined> {
  return async (id: string) => {
    if (!cache.has(id)) {
      cache.set(id, Object.freeze(await load(id)));
    }

    return cache.get(id);
  };
}
