import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom, take } from 'rxjs';
import { GlobalActions, GlobalSelectors } from '../store';

export const logoutGuardFn: CanActivateFn = async (): Promise<boolean> => {
  const router = inject(Router);
  const store = inject(Store);
  store.dispatch(GlobalActions.logoutConfirmedInterface());
  await firstValueFrom(
    store.select(GlobalSelectors.selectIsLoggedIn).pipe(
      filter((isLoggedIn) => !isLoggedIn),
      take(1),
    ),
  );

  return router.navigate(['welcome']);
};
