import { AuthTokenPayload } from '@remberg/global/common/core';

export function parseAuthTokenPayload(token: string): AuthTokenPayload {
  const base64encodedPayload = token.split('.')[1];
  if (!base64encodedPayload) {
    throw new Error('No payload in auth token!');
  }

  const decodedPayload = atob(base64encodedPayload);
  const jsonPayload: AuthTokenPayload = JSON.parse(decodedPayload);
  return jsonPayload;
}
