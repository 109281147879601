import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SortDirectionEnum } from '@remberg/global/common/core';
import { SORT_DIRECTION_OPTIONS, SortField, SortingDialogData } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../../../store';

@Component({
  selector: 'app-sorting-mobile-dialog',
  templateUrl: 'sorting-mobile-dialog.component.html',
  styleUrls: ['./sorting-mobile-dialog.component.scss'],
})
export class SortingMobileDialogComponent {
  protected currentSortField?: string;
  protected currentSortDirection?: SortDirectionEnum;

  protected sortFields: SortField<string>;
  protected sortDirectionOptions = SORT_DIRECTION_OPTIONS;

  protected doneButtonDisabled$ = new BehaviorSubject<boolean>(false);
  protected readonly deviceType$ = this.store.select(GlobalSelectors.selectDeviceType);

  constructor(
    private store: Store<RootGlobalState>,
    public dialogRef: MatDialogRef<SortingMobileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: SortingDialogData<string>,
  ) {
    this.currentSortField = config.currentSortField;
    this.currentSortDirection = config.currentSortDirection;
    this.sortFields = config.sortFields;
  }

  protected closeDialog = (): void => {
    this.dialogRef.close(null);
  };

  protected sortingKeyClick = (key?: string): void => {
    this.currentSortField = key;
    this.computeApplyButtonStatus();
  };

  protected sortingDirectionClick(direction?: SortDirectionEnum): void {
    this.currentSortDirection = direction;
    this.computeApplyButtonStatus();
  }

  protected clearAll(): void {
    this.currentSortField = undefined;
    this.currentSortDirection = undefined;
    this.computeApplyButtonStatus();
  }

  private computeApplyButtonStatus(): void {
    const disabled: boolean =
      (!this.currentSortDirection && !!this.currentSortField) ||
      (!!this.currentSortDirection && !this.currentSortField);
    this.doneButtonDisabled$.next(disabled);
  }

  protected handleDialogCancel(): void {
    this.dialogRef.close({ isConfirmed: false });
  }

  protected handleDialogApply(): void {
    const sorting =
      this.currentSortField && this.currentSortDirection
        ? { field: this.currentSortField, direction: this.currentSortDirection }
        : undefined;

    this.dialogRef.close({ isConfirmed: true, sorting });
  }
}
