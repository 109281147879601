import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { FilterType } from '@remberg/global/common/core';

import { ApiResponse, OfflinePopulateType, OfflineService } from '@remberg/global/ui';
import { WorkOrder, WorkOrderAPIFilterFieldEnum } from '@remberg/work-orders-legacy/common/main';

export interface GetWorkOrdersOfflineInput {
  limit?: number;
  offset?: number;
  sortColumn?: string;
  sortDirection?: string;
  searchValue?: string;
  filters?: FilterType<string>[];
  filterQuery?: AdvancedFilterQuery<WorkOrderAPIFilterFieldEnum>;
  populate?: OfflinePopulateType;
}

export interface WorkOrderOfflineServiceInterface
  extends OfflineService<
    WorkOrder,
    AdvancedFilterQuery<WorkOrderAPIFilterFieldEnum>,
    AdvancedFilter<WorkOrderAPIFilterFieldEnum>
  > {
  getWorkOrdersWithCount(params: GetWorkOrdersOfflineInput): Promise<ApiResponse<WorkOrder[]>>;
}

export const WORK_ORDER_OFFLINE_SERVICE = new InjectionToken<WorkOrderOfflineServiceInterface>(
  'Token for injecting work order offline service without circular dependencies',
);
