import { PhoneNumber } from '@remberg/global/common/core';

export function checkPhoneNumberEntriesEquality(
  entryA?: PhoneNumber,
  entryB?: PhoneNumber,
): boolean {
  if (entryA && entryB) {
    return entryA.number === entryB.number && entryA.countryPrefix === entryB.countryPrefix;
  }
  return !!entryA === !!entryB;
}
