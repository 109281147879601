<mat-chip
  class="preview-indicator-chip"
  [class.small-size]="smallSize"
  [ngClass]="{
    'padding-correction': hideLabel,
    'transparent-backdrop': !isBackdropOnHoverVisible,
  }"
  disableRipple
  [removable]="false"
  (click)="handleClick($event)"
  (mouseup)="handleMouseup($event)"
>
  <div matChipAvatar *ngIf="icon && !thumbnailId" class="icon-wrapper">
    <mat-icon class="smaller-icon">{{ icon }}</mat-icon>
  </div>
  <img
    *ngIf="!icon && !thumbnailId && thumbnailFallback"
    matChipAvatar
    class="thumbnail-image"
    [src]="thumbnailFallback"
  />
  <img
    *ngIf="icon && icon === 'user' && !thumbnailId"
    matChipAvatar
    class="thumbnail-image"
    [src]="IMAGE_FALLBACK_URL.user"
  />
  <img
    class="thumbnail-image"
    *ngIf="thumbnailId"
    matChipAvatar
    [src]="thumbnailId | fileUrl: thumbnailSource : 'small' : false | async"
    [src-fallback]="thumbnailFallback"
  />
  <div
    *ngIf="!hideLabel"
    class="chip-text"
    [matTooltipDisabled]="!isTooltipVisible"
    [matTooltip]="title ?? ''"
    [class.chip-text--isLink]="isLink || linkUrl"
    [class.chip-text--isBold]="boldLabel"
    [attr.data-test-id]="'title-' + title"
  >
    {{ title }}
  </div>
</mat-chip>
