import {
  AdvancedFilter,
  AdvancedFilterClientConfig,
  AdvancedFilterQuery,
} from '@remberg/advanced-filters/common/main';
import { VisualizationTypeEnum } from '@remberg/global/common/core';
import {
  SortField,
  TableColumnsConfig,
  VISUALIZATION_TYPES,
  VisualizationTypes,
} from '@remberg/global/ui';
import {
  WorkOrderAPIFilterFieldEnum,
  WorkOrderCustomPropertyKey,
  WorkOrderSortFieldEnum,
  WorkOrderTableColumnNameEnum,
} from '@remberg/work-orders-legacy/common/main';

export type WorkOrderSortFieldType = SortField<WorkOrderSortFieldEnum>;

export type WorkOrderAdvancedFilterConfigKeys =
  | WorkOrderAPIFilterFieldEnum
  | WorkOrderCustomPropertyKey;
export type WorkOrderAdvancedFilterQuery = AdvancedFilterQuery<WorkOrderAdvancedFilterConfigKeys>;
export type WorkOrderAdvancedStaticFilter = AdvancedFilter<WorkOrderAPIFilterFieldEnum>;

export type WorkOrderTableColumnsConfigKeys =
  | WorkOrderTableColumnNameEnum
  | WorkOrderCustomPropertyKey;
export type WorkOrderTableColumnsConfig = TableColumnsConfig<WorkOrderTableColumnsConfigKeys>;

export const WorkOrderSortFields: WorkOrderSortFieldType = {
  [WorkOrderSortFieldEnum.COUNTER]: {
    identifier: WorkOrderSortFieldEnum.COUNTER,
    label: $localize`:@@id:ID`,
  },
  [WorkOrderSortFieldEnum.PRIORITY]: {
    identifier: WorkOrderSortFieldEnum.PRIORITY,
    label: $localize`:@@priority:Priority`,
  },
  [WorkOrderSortFieldEnum.TYPE]: {
    identifier: WorkOrderSortFieldEnum.TYPE,
    label: $localize`:@@type:Type`,
  },
  [WorkOrderSortFieldEnum.STATUS]: {
    identifier: WorkOrderSortFieldEnum.STATUS,
    label: $localize`:@@status:Status`,
  },
  [WorkOrderSortFieldEnum.TITLE]: {
    identifier: WorkOrderSortFieldEnum.TITLE,
    label: $localize`:@@subject:Subject`,
  },
  [WorkOrderSortFieldEnum.DUE_DATE]: {
    identifier: WorkOrderSortFieldEnum.DUE_DATE,
    label: $localize`:@@dueDate:Due Date`,
  },
  [WorkOrderSortFieldEnum.START_DATE]: {
    identifier: WorkOrderSortFieldEnum.START_DATE,
    label: $localize`:@@plannedStartDate:Planned Start Date`,
  },
  [WorkOrderSortFieldEnum.CREATED_AT]: {
    identifier: WorkOrderSortFieldEnum.CREATED_AT,
    label: $localize`:@@created:Created`,
  },
  [WorkOrderSortFieldEnum.LAST_MODIFIED]: {
    identifier: WorkOrderSortFieldEnum.LAST_MODIFIED,
    label: $localize`:@@updated:Updated`,
  },
  [WorkOrderSortFieldEnum.END_DATE]: {
    identifier: WorkOrderSortFieldEnum.END_DATE,
    label: $localize`:@@plannedEndDate:Planned End Date`,
  },
  [WorkOrderSortFieldEnum.ESTIMATED_DURATION]: {
    identifier: WorkOrderSortFieldEnum.ESTIMATED_DURATION,
    label: $localize`:@@estimatedDuration:Estimated Duration`,
  },
  [WorkOrderSortFieldEnum.COUNTRY]: {
    identifier: WorkOrderSortFieldEnum.COUNTRY,
    label: $localize`:@@country:Country`,
  },
  [WorkOrderSortFieldEnum.COUNTRY_PROVINCE]: {
    identifier: WorkOrderSortFieldEnum.COUNTRY_PROVINCE,
    label: $localize`:@@countryProvince:Country Province`,
  },
  [WorkOrderSortFieldEnum.CITY]: {
    identifier: WorkOrderSortFieldEnum.CITY,
    label: $localize`:@@city:City`,
  },
  [WorkOrderSortFieldEnum.ZIP_POST_CODE]: {
    identifier: WorkOrderSortFieldEnum.ZIP_POST_CODE,
    label: $localize`:@@zipCode:ZIP Code`,
  },
  [WorkOrderSortFieldEnum.STREET]: {
    identifier: WorkOrderSortFieldEnum.STREET,
    label: $localize`:@@street:Street`,
  },
  [WorkOrderSortFieldEnum.ERP_REFERENCE]: {
    identifier: WorkOrderSortFieldEnum.ERP_REFERENCE,
    label: $localize`:@@erpNumber:ERP number`,
  },
};

export type WorkOrderAdvancedFilterConfig =
  AdvancedFilterClientConfig<WorkOrderAdvancedFilterConfigKeys>;

export const WORK_ORDER_VISUALIZATION_TYPES: VisualizationTypes = {
  [VisualizationTypeEnum.TABLE]: VISUALIZATION_TYPES.TABLE,
  [VisualizationTypeEnum.CARDS]: VISUALIZATION_TYPES.CARDS,
  [VisualizationTypeEnum.SPLIT]: VISUALIZATION_TYPES.SPLIT,
  [VisualizationTypeEnum.MAP]: VISUALIZATION_TYPES.MAP,
};
