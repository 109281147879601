export enum WorkOrderCustomPropertyTypesEnum {
  INPUT_TEXT = 'INPUT_TEXT',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  URL = 'URL',
  INPUT_NUMBER = 'INPUT_NUMBER',
  REMBERG_ASSET_SELECT = 'REMBERG_ASSET_SELECT', // asset-select.component
  REMBERG_DATE = 'REMBERG_DATE', // date.component
  REMBERG_DATETIME = 'REMBERG_DATETIME', // datetime.component
  TEXTAREA = 'TEXTAREA',

  REMBERG_ORGANIZATION_SELECT = 'REMBERG_ORGANIZATION_SELECT',
  REMBERG_CONTACT_SELECT = 'REMBERG_CONTACT_SELECT',
}
