import { IsoLanguageCodesEnum, LanguageCodeEnum } from '@remberg/global/common/core';
import { UnreachableCaseError } from './general-helpers';

export function getLanguageTextForIsoLanguageCodeEnum(
  lang: IsoLanguageCodesEnum | string | undefined,
): string {
  if (
    lang === IsoLanguageCodesEnum.EN ||
    lang === 'en' ||
    lang === 'Englisch' ||
    lang === 'English'
  ) {
    return $localize`:@@english:English`;
  }
  if (
    lang === IsoLanguageCodesEnum.DE ||
    lang === 'de' ||
    lang === 'Deutsch' ||
    lang === 'German'
  ) {
    return $localize`:@@german:German`;
  }
  if (lang === IsoLanguageCodesEnum.TR || lang === 'tr' || lang === 'Türk' || lang === 'Turkish') {
    return $localize`:@@turkish:Turkish`;
  }
  if (
    lang === IsoLanguageCodesEnum.ES ||
    lang === 'es' ||
    lang === 'Español' ||
    lang === 'Spanish'
  ) {
    return $localize`:@@spanish:Spanish`;
  }
  if (
    lang === IsoLanguageCodesEnum.FR ||
    lang === 'fr' ||
    lang === 'Français' ||
    lang === 'French'
  ) {
    return $localize`:@@french:French`;
  }
  if (
    lang === IsoLanguageCodesEnum.IT ||
    lang === 'it' ||
    lang === 'Italiano' ||
    lang === 'Italian'
  ) {
    return $localize`:@@italian:Italian`;
  }
  if (
    lang === IsoLanguageCodesEnum.EL ||
    lang === 'el' ||
    lang === 'Ελληνικά' ||
    lang === 'Greek'
  ) {
    return $localize`:@@greek:Greek`;
  }
  if (lang === IsoLanguageCodesEnum.TH || lang === 'th' || lang === 'แบบไทย' || lang === 'Thai') {
    return $localize`:@@thai:Thai`;
  }

  return '';
}

export function getLanguageText(lang: LanguageCodeEnum): string {
  switch (lang) {
    case LanguageCodeEnum.EN_US:
      return $localize`:@@english:English`;
    case LanguageCodeEnum.DE:
      return $localize`:@@german:German`;
    case LanguageCodeEnum.TR:
      return $localize`:@@turkish:Turkish`;
    case LanguageCodeEnum.FR:
      return $localize`:@@french:French`;
    case LanguageCodeEnum.IT:
      return $localize`:@@italian:Italian`;
    case LanguageCodeEnum.ES:
      return $localize`:@@spanish:Spanish`;
    case LanguageCodeEnum.EL:
      return $localize`:@@greek:Greek`;
    case LanguageCodeEnum.TH:
      return $localize`:@@thai:Thai`;
    default:
      throw new UnreachableCaseError(lang);
  }
}

export function getMappedLocale(locale: string): LanguageCodeEnum {
  // return locale if it is contained in language code
  if (Object.values(LanguageCodeEnum).find((l) => l === locale)) {
    return locale as LanguageCodeEnum;
  } else if (locale === 'code') {
    // map local development locale to en-US
    return LanguageCodeEnum.EN_US;
  } else {
    // map to languageCodes if at least the first part matches (before the '-')
    const splitLocale = locale.split('-')[0];
    const valueCode = (
      Object.values(LanguageCodeEnum).filter(
        (type) => isNaN(type as any) && typeof type !== 'function',
      ) as string[]
    ).find((val) => splitLocale === val.split('-')[0]);
    // in case of match, return languageCode, otherwise the passed in locale
    return (valueCode ? valueCode : locale) as LanguageCodeEnum;
  }
}
