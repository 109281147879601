import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';

import { OfflineService } from '@remberg/global/ui';
import { WorkOrderBasic } from '@remberg/work-orders/common/base';
import {
  WorkOrder,
  WorkOrderFilterFieldEnum,
  WorkOrderFindManyBasicByIdsBody,
  WorkOrderFindManyQuery,
  WorkOrderFindManyResponse,
  WorkOrderRaw,
} from '@remberg/work-orders/common/main';
import { Observable } from 'rxjs';

export interface WorkOrder2OfflineServiceInterface
  extends OfflineService<
    WorkOrderRaw,
    AdvancedFilterQuery<WorkOrderFilterFieldEnum>,
    AdvancedFilter<WorkOrderFilterFieldEnum>
  > {
  findOne(id: string): Promise<WorkOrder>;
  findManyBasicByIds(body?: WorkOrderFindManyBasicByIdsBody): Observable<WorkOrderBasic[]>;
  findMany(options?: WorkOrderFindManyQuery): Promise<WorkOrderFindManyResponse>;
}

export const WORK_ORDER_2_OFFLINE_SERVICE = new InjectionToken<WorkOrder2OfflineServiceInterface>(
  'Token for injecting work order offline service without circular dependencies',
);
