import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AllColors, ColorPaletteOption, StatusIndicatorSizeEnum } from '@remberg/global/ui';

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent {
  /** label: actual string to show inside indiator */
  @Input() public label?: string;

  /** statusClass: is the backend string or style string to autouse the predefined colors (e.g. 'inProgress') */
  @Input() public statusClass?: string;

  /**
   * color: style string to overwrite color
   * @deprecated use colorPalette instead
   */
  @Input() public color?: string;

  /**
   * backgroundColor: style string to overwrite background color
   * @deprecated use colorPalette instead
   */
  @Input() public backgroundColor?: string;

  /** icon: use Material icon string */
  @Input() public icon?: string;

  /** size: the enum that sets height, font-size and icon-size (SMALL, MEDIUM, LARGE) */
  @Input() public size?: StatusIndicatorSizeEnum;

  /** maxWidth: limit width */
  @Input() public maxWidth?: string;

  /* displayFlex: allows to set flex status e.g. for mat-option usage */
  @Input() public displayFlex: boolean = false;

  /** tooltipText: text that appears on hover  */
  @Input() public tooltipText?: string;

  @Input() public colorPaletteOption: ColorPaletteOption<AllColors> | undefined;

  protected readonly StatusIndicatorSizeEnum = StatusIndicatorSizeEnum;
}
