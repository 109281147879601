<div class="header-wrapper">
  <div>
    <mat-icon class="mat-icon notranslate mat-icon-no-color icon-primary">{{
      headerIcon
    }}</mat-icon>
  </div>
</div>

<div>
  <div *ngIf="headerText">
    <h2 class="header-text">
      {{ headerText }}
    </h2>
  </div>
</div>

<div>
  <div *ngIf="contentText">
    <p class="content-text">
      {{ contentText }}
    </p>
  </div>
  <div *ngIf="htmlContent" [innerHTML]="htmlContent | safeHtml" class="html-content"></div>
</div>

<div class="checkbox-item-list">
  <div *ngFor="let checkboxItem of checkboxItems">
    <mat-checkbox
      class="mat-checkbox-top-vertical-align checkbox-item"
      color="accent"
      [id]="checkboxItem.id"
      [(ngModel)]="checkboxItem.isChecked"
    >
      {{ checkboxItem.text }}
    </mat-checkbox>
  </div>
</div>

<div class="buttons-container">
  <button
    *ngIf="cancelButtonText"
    mat-stroked-button
    color="accent"
    (click)="close()"
    data-test-id="dialog-cancel-button"
  >
    {{ cancelButtonText }}
  </button>
  <button
    *ngIf="confirmButtonText"
    class="button-confirm"
    color="primary"
    mat-raised-button
    (click)="confirm()"
    cdkFocusInitial
    data-test-id="dialog-confirm-button"
  >
    <mat-icon *ngIf="confirmButtonIcon">{{ confirmButtonIcon }}</mat-icon>
    {{ confirmButtonText }}
  </button>
</div>

<div *ngIf="footerText" class="footer-container">
  <mat-icon *ngIf="footerIcon" class="material-icons-filled footer-icon">{{ footerIcon }}</mat-icon>
  <p class="footer-text">{{ footerText }}</p>
</div>
