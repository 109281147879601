// Wojciech: This enum doesn't adhere to camelCase values convention
// to comply with Amplitude data taxonomy requirements
export enum TrackingCategoryEnum {
  ACCOUNTS = 'accounts',
  AI_COPILOT = 'ai copilot',
  ASSETS = 'assets',
  CASES = 'cases',
  FILES = 'files',
  FORMS = 'forms',
  TASKS = 'tasks',
  USERS = 'users',
  APPLICATION = 'application',
  CONTACTS = 'contacts',
  MASTER_DATA = 'master data',
  ACCOUNT_SETTINGS = 'account settings',
  MAINTENANCE_PLANS = 'maintenance plans',
  WORK_ORDERS = 'work orders',
  SCHEDULING_BOARD = 'scheduling board',
  PARTS = 'parts',
}
