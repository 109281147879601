import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonActions } from '@remberg/global/ui';

@Component({
  selector: 'app-confirmation-pop-up',
  templateUrl: './confirmation-pop-up.component.html',
  styleUrls: ['./confirmation-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationPopUpComponent {
  constructor(private dialogRef: MatDialogRef<ConfirmationPopUpComponent>) {}

  confirm(): void {
    this.dialogRef.close({
      confirmation: true,
      action: ButtonActions.CONFIRM,
    });
  }
  abort(): void {
    this.dialogRef.close({
      confirmation: false,
      action: ButtonActions.ABORT,
    });
  }
}
