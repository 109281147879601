import { ContactsOrganizationTypeFilterEnum } from '@remberg/crm/common/main';
import { MultiLanguageText } from '@remberg/global/common/core';
import { ConfigWithPrefilling } from '../../../automation';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PersonListInputField
  extends Field<FormFieldTypesEnum.PERSON_LIST_INPUT, PersonListInputFieldConfig> {}

export interface PersonListInputFieldConfig
  extends ConfigWithPrefilling<PersonListPrefillTargetsEnum> {
  disabled?: boolean;
  requiredAmountOfEntries?: number;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  label: {
    text: MultiLanguageText;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value?: {
    organizationTypeFilter?: ContactsOrganizationTypeFilterEnum;
    firstNameRequired?: boolean;
    lastNameRequired?: boolean;
    email?: {
      required?: boolean;
      hide?: boolean;
      pdfHide?: boolean;
    };
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
    pdfAddLineBreaks?: boolean;
  };
}

export interface PersonListInputFieldData {
  entries: PersonListItem[];
}

export interface PersonListItem {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export enum PersonListPrefillTargetsEnum {
  ENTRIES = 'entries',
}
