// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path='./quill-emoji.d.ts' />
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path='./quill-blot-formatter.d.ts' />

import { ImageHandler, VideoHandler } from 'ngx-quill-upload-ivy';
import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import * as Emoji from 'quill-emoji';
import { TableBlockEmbed } from './block-embed';

export function setupQuill(): void {
  // register Styles to force quill to use inline CSS
  const AlignStyle = Quill.import('attributors/style/align');
  Quill.register(AlignStyle, true);
  const BackgroundStyle = Quill.import('attributors/style/background');
  Quill.register(BackgroundStyle, true);
  const ColorStyle = Quill.import('attributors/style/color');
  Quill.register(ColorStyle, true);
  const DirectionStyle = Quill.import('attributors/style/direction');
  Quill.register(DirectionStyle, true);
  const FontStyle = Quill.import('attributors/style/font');
  Quill.register(FontStyle, true);
  const SizeStyle = Quill.import('attributors/style/size');
  SizeStyle.whitelist = ['10px', '14px', '22px', '32px'];
  Quill.register(SizeStyle, true);

  // links entered in the editor require a protocol. If no valid protocol is specified, add http
  const Link = Quill.import('formats/link');
  class CustomLink extends Link {
    static sanitize(url: string) {
      const value = super.sanitize(url);
      if (value) {
        for (let i = 0; i < Link.PROTOCOL_WHITELIST.length; i++) {
          if (value.startsWith(Link.PROTOCOL_WHITELIST[i])) {
            return value;
          }
          return `http://${value}`;
        }
      }
      return value;
    }
  }
  Quill.register(CustomLink, true);

  const BlockQuote = Quill.import('formats/blockquote');
  BlockQuote.className = 'rich-text-editor-blockquote';
  Quill.register(BlockQuote, true);

  // create and register an inline text-indent style
  const Parchment = Quill.import('parchment');
  class IndentAttributor extends Parchment.Attributor.Style {
    constructor(a: string, b: string, c: any) {
      super(a, b, c);
    }

    public add(node: HTMLElement, value: number): boolean {
      if (value === 0) {
        this.remove(node);
        return true;
      } else {
        return super.add(node, `${value}em`);
      }
    }

    public value(node: HTMLElement): number | undefined {
      const value = (super.value(node) || '').replace('em', '');
      return parseInt(value, 10) || undefined; // Don't return NaN
    }
  }

  const IndentStyle = new IndentAttributor('indent', 'text-indent', {
    scope: Parchment.Scope.BLOCK,
    whitelist: [
      '1em',
      '2em',
      '3em',
      '4em',
      '5em',
      '6em',
      '7em',
      '8em',
      '9em',
      '10em',
      '11em',
      '12em',
      '13em',
      '14em',
      '15em',
      '16em',
      '17em',
      '18em',
      '19em',
    ],
  });
  Quill.register(IndentStyle, true);
  Quill.register('modules/imageHandler', ImageHandler);
  Quill.register('modules/videoHandler', VideoHandler);
  Quill.register('modules/blotFormatter', BlotFormatter);
  Quill.register('modules/emoji', Emoji);
  Quill.register(TableBlockEmbed, true);
}
