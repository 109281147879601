<button
  mat-stroked-button
  class="toolbar-item"
  [matMenuTriggerFor]="visualizationTypeMenu"
  data-test-id="visualization-type-button"
>
  <mat-icon *ngIf="visualizationTypes && currentVisualizationType">{{
    visualizationTypes[currentVisualizationType]?.icon
  }}</mat-icon>
  <span
    *ngIf="visualizationTypes && currentVisualizationType"
    class="icon-aligner icon-aligner-responsive"
  >
    {{ visualizationTypes[currentVisualizationType]?.label }}
  </span>
</button>

<mat-menu #visualizationTypeMenu="matMenu" class="menu-no-min-height">
  <ng-container *ngIf="visualizationTypes">
    <ng-container *ngFor="let type of visualizationTypes | keyvalue: originalOrder">
      <ng-container *ngIf="type.key | castVisualizationType; let view">
        <button
          mat-menu-item
          class="visualization-option"
          attr.data-test-id="option-{{ view }}"
          [ngClass]="{ 'selected-visualization': view === currentVisualizationType }"
          [value]="view"
          (click)="visualizationTypeChanged?.emit(view)"
        >
          <mat-icon>{{ type.value.icon }}</mat-icon>
          <span class="visualization-type-option-text">{{ type.value.label }}</span>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-menu>
