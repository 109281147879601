import { Category } from './category.model';

export class TicketTreeNode {
  name?: string;
  question?: string;
  category?: Category;
  children?: TicketTreeNode[];
  expandable?: boolean;
  level?: number;
  disabled?: boolean;
  unsafed?: boolean;
  init?: boolean;
}

export interface TicketTree {
  _id: string;
  tenantId: string;
  root: TicketTreeNode;

  __v: number;
}
