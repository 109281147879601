export enum AddressFormatEnum {
  Short = 'short',
  Long = 'long',
  Vertical = 'vertical',
}

const partialAddressProps: Partial<keyof Address>[] = [
  'company',
  'street',
  'other',
  'streetNumber',
  'zipPostCode',
  'city',
  'countryProvince',
  'country',
];

const addressProps: (keyof Address)[] = [
  'company',
  'street',
  'other',
  'streetNumber',
  'zipPostCode',
  'city',
  'countryProvince',
  'country',
  'person',
  'latitude',
  'longitude',
];

/**
 * TODO: Investigate if references to it can be migrated safely now
 * @deprecated Use the one declared in global/common/core instead
 * (also implement internal methods)
 */
export class Address {
  company?: string;
  street?: string;
  streetNumber?: string;
  zipPostCode?: string;
  city?: string;
  countryProvince?: string;
  country?: string;
  other?: string;
  person?: string;
  latitude?: number;
  longitude?: number;

  public static cloneAddress(address: Address): Address {
    return addressProps.reduce(
      (clonedAddress, key) => ({ ...clonedAddress, [key]: address[key as keyof Address] || '' }),
      {} as Address,
    );
  }

  public static getAddressString(address?: Address, format?: AddressFormatEnum): string {
    let result = '';

    if (
      address &&
      partialAddressProps.reduce((acc, current) => {
        if (acc || address[current]) {
          return true;
        }
        return false;
      }, false)
    ) {
      switch (format) {
        case AddressFormatEnum.Short:
          result =
            (address.street
              ? address.street + (address.streetNumber ? ' ' + address.streetNumber : '') + ', '
              : '') +
            (address.zipPostCode ? address.zipPostCode + ', ' : '') +
            (address.city ? address.city + ', ' : '') +
            (address.country ? address.country + ', ' : '');
          break;
        default:
          result =
            (address.company ? address.company + ', ' : '') +
            (address.person ? address.person + ', ' : '') +
            (address.other ? address.other + ', ' : '') +
            (address.street
              ? address.street + (address.streetNumber ? ' ' + address.streetNumber : '') + ', '
              : '') +
            (address.zipPostCode ? address.zipPostCode + ', ' : '') +
            (address.city ? address.city + ', ' : '') +
            (address.countryProvince ? address.countryProvince + ', ' : '') +
            (address.country ? address.country + ', ' : '');
      }
    } else {
      result = '';
    }

    if (result !== '' && result.slice(result.length - 2) === ', ') {
      return result.slice(0, -2);
    }
    return result;
  }

  public static getBusinessLookingAddressString(
    address: Address,
    showCompany: boolean = true,
  ): string {
    if (address) {
      const result =
        (address.company ? address.company : '') +
        (address.person ? '\n' + address.person : '') +
        (address.other ? '\n' + address.other : '') +
        (address.street
          ? '\n' + address.street + (address.streetNumber ? ' ' + address.streetNumber : '')
          : '') +
        (address.zipPostCode ? '\n' + address.zipPostCode + ', ' : '') +
        (address.city ? (address.zipPostCode ? address.city : '\n' + address.city) : '') +
        (address.countryProvince ? '\n' + address.countryProvince + ', ' : '') +
        (address.country
          ? address.countryProvince
            ? address.country
            : '\n' + address.country
          : '');
      if (result) {
        return result.slice(result.length - 2) === ', ' ? result.slice(0, -2) : result;
      }
    }
    return '';
  }

  public static isAddressFilled(address: Address): boolean {
    if (address) {
      for (let i = 0; i < partialAddressProps.length; i++) {
        if (address[partialAddressProps[i]]) {
          return true;
        }
      }
    }
    return false;
  }

  public static compareAddresses(address1?: Address, address2?: Address): boolean {
    const addressString1 = address1
      ? Address.getAddressString(address1) + address1.latitude + address1.longitude
      : '';
    const addressString2 = address2
      ? Address.getAddressString(address2) + address2.latitude + address2.longitude
      : '';
    return addressString1 === addressString2;
  }
}
