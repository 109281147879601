<mat-card appearance="outlined" class="login-card center" ngClass.lt-sm="landing-card">
  <mat-card-header class="flex-row justify-center">
    <mat-card-title>
      <span i18n="@@loginTo">Log in to</span>
      <span class="domain-value no-bold" data-test-id="workspace-name">{{ workspaceName }}</span>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <ng-container *ngIf="isOnline$ | async; else isOffline">
      <form *ngIf="loginFormGroup" [formGroup]="loginFormGroup" ng-submit="login()">
        <div class="form-container">
          <mat-form-field
            [ngStyle]="twoFactorCheck ? { color: 'var(--disabledText)' } : {}"
            appearance="outline"
            [class.margin-error-one-line]="loginFormGroup.invalid && loginFormGroup.touched"
          >
            <mat-label i18n="@@email">Email</mat-label>
            <input
              #emailInput
              class="email-input"
              matInput
              formControlName="email"
              [readOnly]="twoFactorCheck"
              type="text"
              name="email"
              autofocus
              (change)="emailNativeChange(emailInput.value)"
              data-test-id="username"
            />
            <mat-error
              *ngIf="loginFormGroup.get('email')?.errors?.required"
              i18n="@@emailIsRequired"
              data-test-id="email-required-error"
              >Email is required</mat-error
            >
            <mat-error
              *ngIf="loginFormGroup.get('email')?.errors?.pattern"
              i18n="@@emailHasWrongFormat"
              data-test-id="email-wrong-format-error"
              >Email has wrong format</mat-error
            >
          </mat-form-field>

          <mat-form-field
            [ngStyle]="twoFactorCheck ? { color: 'var(--disabledText)' } : {}"
            appearance="outline"
            [class.margin-error-one-line]="loginFormGroup.invalid && loginFormGroup.touched"
          >
            <mat-label i18n="@@password">Password</mat-label>
            <input
              class="password-input"
              #passwordInput
              matInput
              formControlName="password"
              [readOnly]="twoFactorCheck"
              type="password"
              name="password"
              (change)="passwordNativeChange(passwordInput.value)"
              data-test-id="password"
            />
            <mat-icon
              *ngIf="
                loginFormGroup.get('password')?.value?.length > 0 &&
                passwordInputRef?.nativeElement.type === 'password'
              "
              matSuffix
              (click)="togglePasswordVisibility()"
              >visibility</mat-icon
            >
            <mat-icon
              *ngIf="
                loginFormGroup.get('password')?.value?.length > 0 &&
                passwordInputRef?.nativeElement.type === 'text'
              "
              matSuffix
              (click)="togglePasswordVisibility()"
              >visibility_off</mat-icon
            >
            <mat-error
              *ngIf="loginFormGroup.get('password')?.errors?.required"
              i18n="@@passwordIsRequired"
              data-test-id="password-required-error"
              >Password is required</mat-error
            >
            <mat-error
              *ngIf="loginFormGroup.get('password')?.errors?.incorrectCredentials"
              i18n="@@credentialsAreIncorrectPleaseCheckYourEmailAndPasswordAgain"
              data-test-id="credentials-incorrect"
              >Credentials are incorrect. Please check your email and password again.</mat-error
            >
          </mat-form-field>

          <mat-form-field [hidden]="!twoFactorCheck" appearance="outline">
            <mat-label i18n="@@securityCode">Security code</mat-label>
            <input
              class="two-factor-input"
              matInput
              formControlName="twoFactorToken"
              type="password"
              name="twoFactor"
            />
          </mat-form-field>

          <div class="text-forgot">
            <a
              *ngIf="!twoFactorCheck"
              class="text-link"
              (click)="moveToResetPasswordPage(loginFormGroup.get('email')?.value)"
              i18n="@@forgotPassword"
              data-test-id="forget-password"
            >
              Forgot password?
            </a>
          </div>

          <div fxLayout="column">
            <app-button-loader
              class="landing-button-loader"
              [disabled]="loginSent || loginFormGroup.invalid"
              [loading]="loginSent"
              (action)="onLoginClick($event)"
              [buttonStyle]="'mat-flat-button'"
              [text]="twoFactorCheck ? translations.confirm : translations.login"
              data-test-id="login-button"
            />
          </div>

          <div
            *ngIf="(isIonic$ | async) === false && (isOnline$ | async) && !isAtAdminSubdomain"
            class="center register"
          >
            <p>
              <span i18n="@@dontHaveAnAccountYet">Don't have an account yet?</span>
              <a
                class="text-link"
                (click)="openAccessPopup()"
                i18n="@@requestAccess"
                data-test-id="request-access-button"
                >Request access</a
              >.
            </p>
          </div>

          <mat-error
            *ngIf="loginFailedNoInternet"
            i18n="@@cannotReachTheServerPleaseMakeSureYouAreOnlineAndTryAgain"
            style="margin-top: 10px"
          >
            Cannot reach the server. Please make sure you are online and try again.
          </mat-error>
        </div>

        <div class="divider-container">
          <mat-divider />
        </div>
      </form>
    </ng-container>

    <ng-template #isOffline>
      <img class="offline-image" src="/assets/img/lists/notifications_list.svg" />
    </ng-template>
  </mat-card-content>

  <mat-card-actions class="login-card-actions">
    <p
      *ngIf="(isOnline$ | async) === false"
      class="full-width"
      i18n="@@youAreCurrentlyOfflinePleaseTryAgain"
    >
      You are currently offline and cannot login right now. Please try again with an active internet
      connection.
    </p>

    <ng-container *ngIf="isOnline$ | async">
      <a
        *ngIf="twoFactorCheck"
        class="text-link"
        [routerLink]="['/']"
        i18n="@@abort"
        (click)="abortLogin()"
      >
        Abort
      </a>
      <span class="align-items-center clickable" (click)="onMainDomainNavigation()">
        <mat-icon class="x-small-icon">arrow_back</mat-icon>
        <a
          *ngIf="!twoFactorCheck"
          class="text-link another-organization-link"
          i18n="@@logInToAnotherOR"
          data-test-id="change-tenant"
        >
          Log in to another organization
        </a>
      </span>
      <div class="right-buttons">
        <app-language-picker />
      </div>
    </ng-container>
  </mat-card-actions>
</mat-card>
