import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import {
  ApiKeyCreateOneBody,
  ApiKeyCreateOneResponse,
  ApiKeyListElement,
} from '@remberg/users/common/main';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiKeysService {
  private readonly API_KEYS_URL = `${API_URL_PLACEHOLDER}/apikeys/v1`;

  constructor(private http: HttpClient) {}

  public getApiKeys(): Observable<ApiKeyListElement[]> {
    return this.http.get<ApiKeyListElement[]>(this.API_KEYS_URL);
  }

  public addApiKey(name: string): Observable<ApiKeyCreateOneResponse> {
    const body: ApiKeyCreateOneBody = { name };
    return this.http.post<ApiKeyCreateOneResponse>(this.API_KEYS_URL, body);
  }

  public deleteApiKey(apiKeyId: string): Observable<void> {
    const url = `${this.API_KEYS_URL}/${apiKeyId}`;
    return this.http.delete<void>(url);
  }
}
