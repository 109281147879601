export enum IamForbiddenMessageEnum {
  INCORRECT_CREDENTIALS = 'Incorrect credentials!',
  TENANT_DEACTIVATED = 'The company account is not activated anymore.',
  MOBILE_APPLICATION_DISABLED = 'Mobile application is not enabled for this account.',
  PASSWORD_EXPIRED = 'Your password has expired, please request a new one.',
  USER_NOT_ACTIVATED = 'User account not yet activated!',
  TWO_FACTOR_TOKEN_MISSING = 'We need a two-factor token.',
  AUTHY_SERVER_ERROR = 'Login Server Error. Please try again later!',
  PORTAL_NOT_ENABLED = 'Portal feature is not enabled!',
  USER_IS_SUSPENDED = 'User is suspended.',
}
