import { MultiLanguageText } from '@remberg/global/common/core';
import { ConfigWithDynamicActions, ConfigWithPrefilling } from '../../../automation';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PhoneNumberInputField
  extends Field<FormFieldTypesEnum.PHONE_NUMBER_INPUT, PhoneNumberInputFieldConfig> {}

export interface PhoneNumberInputFieldConfig
  extends ConfigWithPrefilling<PhoneNumberInputPrefillTargetsEnum>,
    ConfigWithDynamicActions {
  disabled?: boolean;
  required?: boolean;
  placeHolderText?: MultiLanguageText;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  label: {
    text: MultiLanguageText;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value?: {
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
    validators?: PhoneNumberInputValidatorConfig;
  };
}

export interface PhoneNumberInputValidatorConfig {
  minCharacters?: number;
  maxCharacters?: number;
  supportedNonNumericCharacters?: string;
}

export enum PhoneNumberInputPrefillTargetsEnum {
  VALUE = 'value',
}

export interface PhoneNumberInputFieldData {
  number?: string;
  countryPrefix?: string;
}
