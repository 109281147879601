import Dropzone from 'dropzone';

interface DropzoneUploadOptions<T = unknown> {
  method?: string;
  url?: string;
  payload?: T;
  headers: Record<string, string>;
}

type OnSendingHandler = (
  files: Dropzone.DropzoneFile | Dropzone.DropzoneFile[],
  xhr: XMLHttpRequest,
  formData: FormData,
) => void;

export function uploadFilesWith<T>(
  dropzone: Dropzone,
  options: DropzoneUploadOptions<T>,
): Promise<void> {
  const { method, url, payload } = options || {};

  const originalOptions = dropzone.options;

  dropzone.options = {
    ...originalOptions,
    url: url || originalOptions.url,
    method: method || originalOptions.method,
    headers: options.headers,
  };

  const sendingEventName = originalOptions.uploadMultiple ? 'sendingmultiple' : 'sending';
  const sendingHandler: OnSendingHandler = (files, xhr, formData) => {
    if (payload) {
      formData.append('data', JSON.stringify(payload));
    }
  };

  const successEventName = originalOptions.uploadMultiple ? 'successmultiple' : 'success';
  const errorEventName = originalOptions.uploadMultiple ? 'errormultiple' : 'error';

  return new Promise<void>((resolve, reject) => {
    const successHandler = () => {
      unsubscribe();
      resolve();
    };

    const errorHandler = () => {
      unsubscribe();
      reject();
    };

    const unsubscribe = () => {
      dropzone.off(sendingEventName, sendingHandler);
      dropzone.off(successEventName, successHandler);
      dropzone.off(errorEventName, errorHandler);

      dropzone.options = originalOptions;
    };

    dropzone.on(sendingEventName, sendingHandler);
    dropzone.on(successEventName, successHandler);
    dropzone.on(errorEventName, errorHandler);

    dropzone.processQueue();
  });
}
