import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { PREFILLING_STEPS_PROVIDERS } from './services/offline/prefilling/providers';
import { PREFILLING_STEPS_PROVIDERS_2 } from './services/offline/prefilling/providers-2';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule.forRoot({
      _forceStatusbarPadding: true,
    }),
    IonicStorageModule.forRoot(),
  ],
  exports: [IonicModule],
  providers: [SQLite, ...PREFILLING_STEPS_PROVIDERS, ...PREFILLING_STEPS_PROVIDERS_2],
})
export class IonicSharedModule {}
