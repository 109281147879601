import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DriveFilesBulkDeleteBody,
  DriveFilesBulkMoveBody,
  DriveFilesBulkUnassignBody,
  DriveFilesBulkUpdateBody,
} from '@remberg/files/common/main';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { Observable } from 'rxjs';
import { ROOT_ID } from './drive-files.definitions';

@Injectable({
  providedIn: 'any',
})
export class DriveFilesBulkService {
  private readonly bulkActionUrl = `${API_URL_PLACEHOLDER}/files/v2/bulk`;

  constructor(private http: HttpClient) {}

  public deleteMany(fileIds: string[]): Observable<void> {
    const body: DriveFilesBulkDeleteBody = { fileIds };
    return this.http.delete<void>(`${this.bulkActionUrl}`, { body });
  }

  public moveMany(fileIds: string[], targetId: string): Observable<void> {
    const body: DriveFilesBulkMoveBody = { fileIds };
    if (targetId !== ROOT_ID) {
      body.targetId = targetId;
    }
    return this.http.patch<void>(`${this.bulkActionUrl}/move`, body);
  }

  public updateMany(payload: DriveFilesBulkUpdateBody): Observable<void> {
    return this.http.patch<void>(`${this.bulkActionUrl}`, payload);
  }

  public bulkUnassignAsset(payload: DriveFilesBulkUnassignBody): Observable<void> {
    return this.http.patch<void>(`${this.bulkActionUrl}/unassignasset`, payload);
  }
}
