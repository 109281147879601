export enum PartsFilterEnum {
  ASSET = 'asset',
  ASSET_TYPE = 'assetType',
  CREATED = 'created',
  DISTRIBUTOR = 'distributor',
  MANUFACTURER = 'manufacturer',
  UPDATED = 'updated',
  IS_PUBLIC = 'isPublic',
  ASSET_OR_ASSET_TYPE = 'assetOrAssetType',
}
