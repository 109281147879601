import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import {
  FormInstanceCreateFromEmptyBody,
  FormInstanceCreateFromExistingBody,
  FormInstanceTableQuery,
  FormInstanceTableResponse,
  FormInstanceUpdateBody,
} from '@remberg/forms/common/dtos';
import {
  FormInstance,
  FormInstanceEmailStatusEnum,
  FormInstanceRaw,
} from '@remberg/forms/common/main';
import { DataResponse, OfflineService, OnlineStatusDataTypeEnum } from '@remberg/global/ui';

export interface FormInstanceOfflineServiceInterface
  extends OfflineService<FormInstanceRaw, AdvancedFilterQuery<never>, AdvancedFilter<never>> {
  createFromEmpty(formInstanceMeta: FormInstanceCreateFromEmptyBody): Promise<FormInstanceRaw>;

  createFromExisting(formInstance: FormInstanceCreateFromExistingBody): Promise<FormInstanceRaw>;

  updateOne(
    update: FormInstanceUpdateBody,
    onlineStatus: OnlineStatusDataTypeEnum.ONLINE | OnlineStatusDataTypeEnum.OFFLINE_CHANGE,
  ): Promise<OnlineStatusDataTypeEnum>;

  deleteOne(formInstanceId: string): Promise<void>;

  getOneForDetailPage(formInstanceId: string): Promise<DataResponse<FormInstance> | undefined>;

  getManyForList(query: FormInstanceTableQuery): Promise<FormInstanceTableResponse>;

  getInProgressCountByFormTemplateId(formTemplateId: string): Promise<number>;

  getEmailStatusesForFormInstance(
    formInstanceOrId: string | FormInstanceRaw,
  ): Promise<Record<string, FormInstanceEmailStatusEnum>>;

  getDeletedInstanceIds(): string[];

  setDeletedInstanceIds(value: string[]): Promise<void>;

  getDraftIds(): Record<string, number | undefined>;

  setDraftIds(draftsIds: Record<string, number | undefined>): Promise<void>;
}

export const FORM_INSTANCE_OFFLINE_SERVICE =
  new InjectionToken<FormInstanceOfflineServiceInterface>(
    'Token for injecting form instances offline service without circular dependencies',
  );
