import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FilesPermissionEnum } from '@remberg/files/common/main';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { LogService, MainRoutes } from '@remberg/global/ui';
import { isAccountFeatureFlagEnabled } from '../../helpers/checkFeatureHelper';
import { AppStateService } from '../../services/app-state.service';
import { UserRightsService } from '../../services/user-rights.service';

@Injectable({
  providedIn: 'root',
})
export class FileDriveGuard {
  constructor(
    private userRightsService: UserRightsService,
    private router: Router,
    private logger: LogService,
    private appState: AppStateService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (
        isAccountFeatureFlagEnabled(FeatureFlagEnum.FILES, this.appState) &&
        this.userRightsService.getPermission(FilesPermissionEnum.FILES_ENABLED) &&
        !this.userRightsService.getPermission(FilesPermissionEnum.FILES_SHOW_ONLY_IN_ASSET_DETAILS)
      ) {
        return resolve(true);
      }

      this.logger.info()('File Drive Guard: Redirecting from: ' + state.url);
      this.router.navigate([MainRoutes.ASSETS]);
      return resolve(false);
    });
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
