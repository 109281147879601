import { FilterType } from '@remberg/global/common/core';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  filter,
  map,
  mergeMap,
  scan,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { BaseModel } from '../definitions/base.model';
import { ApiResponse } from '../definitions/data-wrappers';
import { SingleSelectOption, SingleSelectPreviewData } from '../definitions/single-select';
import { AppInjector } from '../helpers/app-injector';
import { LogService } from './log.service';

export class SingleSelectOptionsService<T extends BaseModel> {
  // Input subjects
  private filterSubject: BehaviorSubject<FilterType<string>[]>;
  private searchSubject: BehaviorSubject<string>;
  private staticOptionsSubject: BehaviorSubject<T[]>;
  private excludedOptionsSubject: BehaviorSubject<T[]>;

  // output subjects
  public options = new ReplaySubject<SingleSelectOption<T>[]>(1);
  public optionsComplete = new BehaviorSubject<boolean>(false);
  public loading = new BehaviorSubject<boolean>(false);
  public optionsLoaded = new BehaviorSubject<boolean>(false);

  // internal state
  private fetchedOptionsSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private searchConfigSubject: BehaviorSubject<[string, FilterType<string>[]] | undefined> =
    new BehaviorSubject<[string, FilterType<string>[]] | undefined>(undefined);
  private currentlyLoadingPageSubject = new BehaviorSubject<number>(0);
  private lastLoadedPageSubject = new BehaviorSubject<number>(-1);
  private fetchTriggerSubject = new Subject<boolean>();
  private logger: LogService;

  constructor(
    private compareFunction: (a: T, b: T) => boolean,
    private fetchFunction: (
      search: string,
      filters: FilterType<string>[],
      pageSize: number,
      pageIndex: number,
      populate?: boolean,
    ) => Observable<ApiResponse<T[]>>,
    private filterStringFunction: (item: T) => string,
    private calculatePreviewFunction: (item: T) => Observable<SingleSelectPreviewData>,
    private getValueStringFunction: (item: T) => string,
    private getMultilineTextWithIconFunction?: (value: T) => Record<string, string>,
    filters?: FilterType<string>[],
    search?: string,
    staticOptions?: T[],
    excludedOptions?: T[],
    public activated: boolean = false,
    private showPreview?: boolean,
    private pageSize: number = 10,
  ) {
    // initialize logger
    this.logger = AppInjector.get(LogService);

    // initialize input subjects
    this.filterSubject = new BehaviorSubject(filters ?? []);
    this.searchSubject = new BehaviorSubject(search ?? '');
    this.staticOptionsSubject = new BehaviorSubject(staticOptions ?? []);
    this.excludedOptionsSubject = new BehaviorSubject(excludedOptions ?? []);

    // initialize internal routines
    this.setupStreams();

    this.logger.debug()('Single-Select-Service initialized');
  }

  // public methods
  public activate(): void {
    this.activated = true;
  }
  public fetchMoreOptions(): void {
    this.fetchTriggerSubject.next(true);
  }
  public setFilters(filters: FilterType<string>[]): void {
    const currentFilters = this.filterSubject.getValue() ?? [];
    const newFilters = filters ?? [];
    if (JSON.stringify(newFilters) !== JSON.stringify(currentFilters)) {
      this.filterSubject.next(newFilters);
    }
  }
  public setSearch(search: string): void {
    if (search !== this.searchSubject.getValue()) {
      this.searchSubject.next(search ?? '');
    }
  }
  public setStaticOptions(options: T[]): void {
    this.staticOptionsSubject.next(options);
  }
  public setExcludedOptions(options: T[]): void {
    this.excludedOptionsSubject.next(options);
  }

  // internal methods
  private setupStreams(): void {
    // create a subject of search configs that listens to the filter and search inputs and only emits if at least one of them changes
    combineLatest([this.searchSubject, this.filterSubject])
      .pipe(
        withLatestFrom(this.searchConfigSubject),
        filter(
          ([newSearchConfig, oldSearchConfig]) =>
            !this.compareConfigs(newSearchConfig, oldSearchConfig),
        ),
        map((result) => result[0]),
      )
      .subscribe(this.searchConfigSubject);

    // on every fetch trigger, fetch a new set of options
    this.fetchTriggerSubject
      .pipe(
        tap(() => this.logger.debug()('Single-Select-Service fetch was triggered')),
        // only proceed if there is a valid searchConfig
        // and the last loaded page equals the currently loading page
        // and not all options have been fetched yet
        // and the service is activated
        withLatestFrom(
          this.currentlyLoadingPageSubject,
          this.lastLoadedPageSubject,
          this.searchConfigSubject,
          this.optionsComplete,
        ),
        filter(([trigger, currentPage, lastPage, config, allFetched]) => {
          if (!!config && currentPage === lastPage && !allFetched && this.activated) {
            return true;
          } else {
            return false;
          }
        }),
        // increment the currently loading page
        map(([trigger, currentPage, lastPage, config]) => {
          this.currentlyLoadingPageSubject.next(currentPage + 1);
          return [currentPage + 1, config] as [number, [string, FilterType<string>[]]];
        }),
        // fetch a new page
        mergeMap(([currentPage, config]) => {
          this.loading.next(true);
          this.logger.debug()('Single-Select-Service doing fetch');
          return this.fetchFunction(config[0], config[1], this.pageSize, currentPage).pipe(
            catchError((error) => {
              this.logger.error()('Error fetching select options:', error);
              return of(null as unknown as ApiResponse<T[]>);
            }),
            withLatestFrom(this.searchConfigSubject),
            // abort if config has changed in the meantime
            filter(([results, currentConfig]) => {
              const condition = this.compareConfigs(config, currentConfig);
              // if the searchconfig is still up to date but there was an error,
              // decrement current page and stop loading
              if (!results && condition) {
                this.logger.debug()('Single-Select-Service fetch abort');
                this.currentlyLoadingPageSubject.next(currentPage - 1);
                this.loading.next(false);
              }
              return results && condition;
            }),
            // extract the results
            map(([results]) => {
              // stop loading and mark page as loaded
              this.loading.next(false);
              this.lastLoadedPageSubject.next(currentPage);
              this.optionsLoaded.next(true);
              // if necessary mark the searchConfig as "allOptionsFetched"
              if (results.data?.length !== this.pageSize) {
                this.optionsComplete.next(true);
              }
              this.logger.debug()('Single-Select-Service fetched options extracted');
              return [results.data, currentPage] as [T[], number];
            }),
          );
        }),
        // combine the results of pages for the same searchConfig
        scan((acc, [options, page]) => {
          this.logger.debug()('Single-Select-Service accumulating options');
          if (page === 0) {
            return [...options];
          } else {
            return [...acc, ...options];
          }
        }, [] as T[]),
      )
      .subscribe(this.fetchedOptionsSubject);

    // filter staticOptions based on the provided filter function
    const filteredStaticOptionsSubject = combineLatest([
      this.staticOptionsSubject,
      this.searchSubject,
      this.excludedOptionsSubject,
    ]).pipe(
      map(([options, searchValue, excludedOptions]) =>
        options
          // remove all options that do not match the searchValue or are in excludedOptions
          .filter(
            (option) =>
              (!searchValue || this.filterStringFunction(option)?.includes(searchValue)) &&
              !excludedOptions?.find((opt) => this.compareFunction(option, opt)),
          )
          // calculate the preview for all remaining options (async)
          .map((option) => this.generateSingleSelectOption(option)),
      ),
      // join the preview operation results
      mergeMap((options) => forkJoin(options).pipe(defaultIfEmpty([] as SingleSelectOption<T>[]))),
    );

    // filter fetchedOptions based on static and excluded options
    const filteredOptionsSubject = combineLatest([
      this.fetchedOptionsSubject,
      this.excludedOptionsSubject,
    ]).pipe(
      map(([options, excludedOptions]) =>
        options
          // remove all options that are in excludedOptions
          .filter((option) => !excludedOptions?.find((opt) => this.compareFunction(option, opt)))
          // calculate the preview for all remaining options (async)
          .map((option) => this.generateSingleSelectOption(option)),
      ),
      // join the preview operation results
      mergeMap((options) => forkJoin(options).pipe(defaultIfEmpty([] as SingleSelectOption<T>[]))),
    );

    // combine static and fetchedOptions
    combineLatest([filteredOptionsSubject, filteredStaticOptionsSubject])
      .pipe(
        map(([options, staticOptions]) =>
          [
            ...staticOptions,
            // do not add options that are already in static options
            ...options.filter(
              (option) =>
                !staticOptions?.find((opt) => this.compareFunction(option.value, opt.value)),
            ),
            // make sure values are unique:
          ].filter((v, i, a) => a.findIndex((it) => this.compareFunction(it.value, v.value)) === i),
        ),
      )
      .subscribe(this.options);

    // on every search config change, reset the page subjects and trigger a new fetch
    this.searchConfigSubject
      .pipe(
        // only proceed with an existing search config
        filter((searchConfig) => !!searchConfig),
        tap(() => {
          this.logger.debug()('Single-Select-Service search config changed');
          // set the currently loading page and last loaded page to -1
          this.lastLoadedPageSubject.next(-1);
          this.currentlyLoadingPageSubject.next(-1);
          this.optionsComplete.next(false);
          this.optionsLoaded.next(false);
          // reset the collection of found options
          this.fetchedOptionsSubject.next([]);
          // trigger a new fetching of options
          this.fetchTriggerSubject.next(true);
        }),
        //
      )
      .subscribe();
  }

  private compareConfigs(
    config1?: [string, FilterType<string>[]],
    config2?: [string, FilterType<string>[]],
  ): boolean {
    if (!config1 && !config2) {
      return true;
    }
    if (!config1 || !config2) {
      return false;
    }
    return config2[0] === config1[0] && JSON.stringify(config2[1]) === JSON.stringify(config1[1]);
  }

  private generateSingleSelectOption(option: T): Observable<SingleSelectOption<T>> {
    if (this.showPreview) {
      return this.calculatePreviewFunction(option).pipe(
        map((preview) => ({
          value: option,
          previewType: preview.type,
          previewContent: preview.content,
          displayValue: this.getValueStringFunction(option),
          displayValueMultiLineWithIcon: this.getMultilineTextWithIconFunction?.(option) ?? {},
        })),
        catchError(() =>
          of({
            value: option,
            displayValue: this.getValueStringFunction(option),
            displayValueMultiLineWithIcon: this.getMultilineTextWithIconFunction?.(option) ?? {},
          }),
        ),
      );
    } else {
      return of({
        value: option,
        displayValue: this.getValueStringFunction(option),
        displayValueMultiLineWithIcon: this.getMultilineTextWithIconFunction
          ? this.getMultilineTextWithIconFunction(option)
          : {},
      });
    }
  }
}
