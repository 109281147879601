import { UserGroupBasic } from '@remberg/users/common/main';

export function mapToUserGroupBasic({
  _id,
  name,
  type,
  description,
}: UserGroupBasic): UserGroupBasic {
  return { _id, name, type, description };
}
