export enum CaseTableColumnNameEnum {
  COUNTER = 'counter',
  SUBJECT = 'subject',
  REQUESTER = 'requester',
  CREATED = 'created',
  UPDATED = 'updated',
  STATUS = 'status',
  ASSIGNEE = 'assignee',
  ORGANIZATION = 'organization',
  PRIORITY = 'priority',
  SUPPORT_EMAIL_ADDRESS = 'supportEmailAddress',
  ASSETS = 'assets',
  CATEGORY = 'category',
  SOURCE = 'source',
  OPTIONS = 'options',
}

export enum CaseCardTableColumNameEnum {
  CARD = 'card',
}

export enum CasesSharedDialogTypeEnum {
  CASE_CREATION = 'caseCreation',
  CASE_FILTER = 'caseFilter',
  CASE_DELETION = 'caseDeletion',
  CASE_EXPORT = 'caseExport',
  CASE_MOVE = 'caseMove',
  CASE_MOVE_CONFIRMATION = 'caseMoveConfirmation',
  CASE_MOVE_UNDO = 'caseMoveUndo',
}

export enum CasesSharedTableIdsEnum {
  CASE_OVERVIEW = 'caseOverview',
  ASSET_DETAIL_OPENED = 'assetDetailOpened',
  ASSET_DETAIL_CLOSED = 'assetDetailClosed',
  CASE_MOVE_DIALOG = 'caseMoveDialog',
  ORGANIZATION_DETAIL = 'organizationDetail',
}
