import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import {
  AdvancedFilter,
  AdvancedFilterConcatOperatorEnum,
  AdvancedFilterQuery,
} from '@remberg/advanced-filters/common/main';
import { QRCode } from '@remberg/assets/common/base';
import {
  QRCodeLegacy,
  QRCodesCreateBody,
  QRCodesFilterEnum,
  QRCodesFindManyQuery,
  QRCodesFindManyResponse,
  QRCodesSortFieldsEnum,
  QrCodesPopulateTypeEnum,
  mapToQRCode,
  mapToQRCodeLegacy,
} from '@remberg/assets/common/main';
import { FilterType, SortDirectionEnum } from '@remberg/global/common/core';
import { UnreachableCaseError, getNumberCount } from '@remberg/global/ui';
import { Observable, catchError, map } from 'rxjs';
import { QRCodesLegacyService } from './qr-codes-legacy.service';
import { QRCodesNewService } from './qr-codes-new.service';

const mapQRCodesSortFieldEnum = (
  sortField: QRCodesSortFieldsEnum | undefined,
): string | undefined => {
  switch (sortField) {
    case QRCodesSortFieldsEnum.CREATED_AT:
      return 'creationDate';
    case QRCodesSortFieldsEnum.ASSIGNED_AT:
      return 'target';
    case QRCodesSortFieldsEnum.IS_DOWNLOADED:
      return 'downloaded';
    default:
      return undefined;
  }
};

const mapQRCodesFilterEnum = (filterField: QRCodesFilterEnum): string => {
  switch (filterField) {
    case QRCodesFilterEnum.IS_ASSIGNED:
      return 'assignment';
    case QRCodesFilterEnum.IS_DOWNLOADED:
      return 'downloaded';
    case QRCodesFilterEnum.TENANT_ID:
      return 'tenant';
    default:
      throw new UnreachableCaseError(filterField as never);
  }
};

const getOldQRCodeFilters = (
  filterQuery?: AdvancedFilterQuery<QRCodesFilterEnum>,
  staticFilters: AdvancedFilter<QRCodesFilterEnum>[] = [],
): FilterType<string>[] => {
  const filtersToTransform: AdvancedFilter<QRCodesFilterEnum>[] = staticFilters;

  if (
    filterQuery?.filters.length &&
    filterQuery.concatOperator === AdvancedFilterConcatOperatorEnum.AND
  ) {
    filtersToTransform.push(...filterQuery.filters);
  }

  return filtersToTransform.map(({ identifier, value }) => ({
    identifier: mapQRCodesFilterEnum(identifier),
    value,
  }));
};

@Injectable({ providedIn: 'root' })
export class QRCodesService {
  constructor(
    private readonly qrCodesLegacyService: QRCodesLegacyService,
    private readonly qrCodesNewService: QRCodesNewService,
  ) {}

  public createMany(
    isAssetsTemporaryEnabled: boolean,
    { amount, tenantId }: QRCodesCreateBody,
  ): Observable<QRCode[]> {
    if (isAssetsTemporaryEnabled) {
      return this.qrCodesNewService.createMany({ amount, tenantId });
    }
    return this.qrCodesLegacyService.addQRCodes(amount ?? 1, tenantId).pipe(map(mapToQRCode));
  }

  public findOne(
    isAssetsTemporaryEnabled: boolean,
    qrCodeId: string,
    populate?: boolean,
  ): Observable<QRCodeLegacy> {
    if (isAssetsTemporaryEnabled) {
      return this.qrCodesNewService
        .findOne(
          qrCodeId,
          populate
            ? { populate: [QrCodesPopulateTypeEnum.TENANT_LABEL, QrCodesPopulateTypeEnum.IMAGE] }
            : undefined,
        )
        .pipe(map((qrCode) => mapToQRCodeLegacy(qrCode)));
    }

    return this.qrCodesLegacyService.getQRCode(qrCodeId, populate);
  }

  public findMany(
    isAssetsTemporaryEnabled: boolean,
    query: QRCodesFindManyQuery,
  ): Observable<QRCodesFindManyResponse> {
    if (isAssetsTemporaryEnabled) {
      return this.qrCodesNewService.findMany(query);
    }

    const { populate, filterObject, staticFilters, page, limit, search, sortDirection, sortField } =
      query;

    const newSortField = mapQRCodesSortFieldEnum(sortField);
    let newSortDirection: SortDirection | undefined;
    if (sortDirection === SortDirectionEnum.ASC) {
      newSortDirection = 'asc';
    } else if (sortDirection === SortDirectionEnum.DESC) {
      newSortDirection = 'desc';
    }

    return this.qrCodesLegacyService
      .getQRCodesWithCount(
        !!populate,
        limit,
        page,
        newSortDirection,
        newSortField,
        search,
        getOldQRCodeFilters(filterObject, staticFilters),
      )
      .pipe(
        map(({ data, count }) => ({
          qrCodes: mapToQRCode(data),
          count: count ? getNumberCount(count) : 0,
        })),
      );
  }

  public downloadOne(
    isAssetsTemporaryEnabled: boolean,
    qrCodeId: string,
    downloadFileName: string,
  ): void {
    isAssetsTemporaryEnabled
      ? this.qrCodesNewService.downloadOne(qrCodeId, downloadFileName)
      : this.qrCodesLegacyService.downloadQRCodePDF(qrCodeId, downloadFileName);
  }

  public downloadMany(
    isAssetsTemporaryEnabled: boolean,
    {
      populate,
      search,
      filterObject,
    }: {
      populate?: boolean;
      search?: string;
      filterObject?: AdvancedFilterQuery<QRCodesFilterEnum>;
    },
  ): void {
    if (isAssetsTemporaryEnabled) {
      return this.qrCodesNewService.downloadMany({ search, filterObject });
    }
    const filterValue = getOldQRCodeFilters(filterObject);
    return this.qrCodesLegacyService.downloadMultipleQRCodePDFs(populate, search, filterValue);
  }

  /**
   * Public Endpoint
   */
  public findOneByLinkId(qrCodeLinkId: string): Observable<QRCodeLegacy> {
    return this.qrCodesLegacyService
      .getQRCodeByLink(qrCodeLinkId)
      .pipe(
        catchError(() =>
          this.qrCodesNewService
            .findOneByLinkId(qrCodeLinkId)
            .pipe(map((qrCode) => mapToQRCodeLegacy(qrCode))),
        ),
      );
  }

  /**
   * Public Endpoint
   */
  public scanQRCode(linkId: string): Observable<void> {
    return this.qrCodesLegacyService
      .scanQRCode(linkId)
      .pipe(catchError(() => this.qrCodesNewService.scanQRCode(linkId)));
  }

  public deleteOne(isAssetsTemporaryEnabled: boolean, qrCodeId: string): Observable<void> {
    return isAssetsTemporaryEnabled
      ? this.qrCodesNewService.deleteOne(qrCodeId)
      : this.qrCodesLegacyService.deleteQRCode(qrCodeId);
  }

  public assignOne(isAssetsTemporaryEnabled: boolean, qrCode: QRCodeLegacy): Observable<void> {
    return isAssetsTemporaryEnabled
      ? this.qrCodesNewService.assignOne(qrCode._id, { targetId: qrCode.target })
      : this.qrCodesLegacyService.updateQRCode(qrCode);
  }
}
