import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AssetsFindManyInTreeQuery,
  AssetsFindManyInTreeResponse,
  AssetsLegacySortFieldEnum,
  Product,
} from '@remberg/assets/common/main';
import { SortTypeConfig } from '@remberg/global/common/core';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  filterEmptyProps,
} from '@remberg/global/ui';
import { EMPTY, Observable } from 'rxjs';
import {
  AssetsLegacyAdvancedFilterQuery,
  AssetsLegacyAdvancedStaticFilter,
} from './assets-legacy.definitions';
import {
  assetsLegacySortToAssetsSort,
  mapLegacyFiltersToAssetsFilters,
  mapLegacyPopulateToPopulateItems,
} from './assets.service';

@Injectable({ providedIn: 'root' })
export class AssetsTreeService {
  public readonly assetsUrl = `${API_URL_PLACEHOLDER}/assets/v1`;

  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly httpClient: HttpClient,
  ) {}

  public findManyInTree({
    filterQuery,
    staticFilters,
    sorting,
    pageIndex,
    pageSize,
    searchQuery,
    populate,
    parentId,
  }: {
    filterQuery?: AssetsLegacyAdvancedFilterQuery;
    staticFilters?: AssetsLegacyAdvancedStaticFilter[];
    sorting?: SortTypeConfig<AssetsLegacySortFieldEnum>;
    pageIndex?: number;
    pageSize?: number;
    searchQuery?: string;
    populate?: boolean | Product;
    parentId?: string;
  }): Observable<AssetsFindManyInTreeResponse> {
    if (!this.connectivityService.getConnected()) return EMPTY;

    const sortField = sorting?.field;
    const sortDirection = sorting?.direction;

    const query: AssetsFindManyInTreeQuery = {
      page: pageIndex ?? 0,
      limit: pageSize ?? 20,
      search: searchQuery,
      sortDirection,
      sortField: sortField ? assetsLegacySortToAssetsSort[sortField] : undefined,
      filterObject: filterQuery
        ? {
            concatOperator: filterQuery.concatOperator,
            filters: mapLegacyFiltersToAssetsFilters(filterQuery.filters),
          }
        : undefined,
      staticFilters: staticFilters ? mapLegacyFiltersToAssetsFilters(staticFilters) : undefined,
      populate: mapLegacyPopulateToPopulateItems(populate),
      parentId,
    };

    const {
      filterObject,
      staticFilters: newStaticFilters,
      populate: newPopulate,
      ...queryParams
    } = query;

    const params = new HttpParams({
      fromObject: {
        ...filterEmptyProps(queryParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(newStaticFilters && { staticFilters: JSON.stringify(newStaticFilters) }),
        ...(newPopulate && { populate: JSON.stringify(newPopulate) }),
      },
    });
    return this.httpClient.get<AssetsFindManyInTreeResponse>(`${this.assetsUrl}/tree`, { params });
  }
}
