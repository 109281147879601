import { Injectable } from '@angular/core';
import { ContactCompatibility } from '@remberg/crm/common/main';
import { FileSourceTypeEnum } from '@remberg/files/common/main';
import { PlatformFilesService } from '@remberg/files/ui/clients';
import { base64toBlob, getFileExtensionFromMimeType, LogService } from '@remberg/global/ui';
import { QuillEditorComponent } from 'ngx-quill';
import { ToastrService } from 'ngx-toastr';
import { ServerConfigurationService } from './server-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class RichTextService {
  private translations = {
    errorMessageBase64: $localize`:@@uploadingImageIsNotYetSupportedAddAnImageUsingItsPublicUrl:
          Uploading image is not supported yet. Add an image using its public url.`,
  };

  constructor(
    private readonly platformFilesService: PlatformFilesService,
    private readonly logger: LogService,
    private readonly toastrService: ToastrService,
    private readonly serverConfigurationService: ServerConfigurationService,
  ) {}

  /**
   * Inserts a new line in the passed editor where the cursor is
   * @param  {QuillEditorComponent} editor
   */
  public insertNewLine(editor?: QuillEditorComponent): void {
    const range = editor?.quillEditor.getSelection(true);
    if (range) {
      editor?.quillEditor.insertText(range.index + range.length + 1, '\n', 'api');
    }
  }

  /**
   * Inserts a new l in the passed editor where the cursor is
   * @param  {QuillEditorComponent} editor
   */
  public insertHTMLAtCursor(editor: QuillEditorComponent, content: string): void {
    const range = editor.quillEditor.getSelection(true);
    if (range) {
      editor.quillEditor.clipboard.dangerouslyPasteHTML(range.index, content, 'api');
    }
  }
  /**
   * Transforms the image sources <img> in an html content that matched a driveFile to a valid local url after caching the files.
   * The driveFile has to match the folowing regex: /src\s*=\s*"api\/files\/download\/(.{24})"/gm
   * @param  {string} content
   * @param  {QuillEditorComponent} editor
   */
  public async transformServerImagesToInlineLocalImages(
    content: string,
    editor?: QuillEditorComponent,
  ): Promise<string> {
    this.logger.silly()('transformServerImagesToInlineLocalImages');
    const matches = content?.match(/src\s*=\s*"api\/files\/download\/(.{24})"/gm);
    for (const match of matches ?? []) {
      const imageFileId = match.match(/\/(.{24})"/gm);
      if (imageFileId?.length === 1) {
        const imageFileStringId = imageFileId[0].slice(1, -1); // Removing first and last chars : '/' and '"'
        const url = await new Promise((resolve) => {
          this.platformFilesService.downloadFileToBlob(imageFileStringId).subscribe({
            next: (blob) => {
              const url = URL.createObjectURL(blob);
              this.insertNewLine(editor);
              resolve(url);
            },
            error: () => resolve(null),
          });
        });
        content = content.replace(match, `src="${url}"`);
      }
    }
    return content;
  }

  /**
   * Takes some html content as an input and transforms all base64 images in the content to Files that are uploaded to the server.
   * The source of each image is updated with a local url of the file that will, when the message is sent,
   * be transform to a url with the id of the file that was uploaded.
   * @param  {string} content HTML content to sanitize
   * @returns Promise HTML content after sanitization
   */
  public async sanitizeImageTags(
    content: string,
    editor: QuillEditorComponent,
    contact: ContactCompatibility,
    inlineImageSourceType?: FileSourceTypeEnum,
    source?: string,
  ): Promise<string> {
    this.logger.silly()('sanitizeImageTags');
    const imageTags = content?.match(/<img.*?>/gm);
    for (const imageTag of imageTags ?? []) {
      const urlmatch = imageTag.match('src="data:image/([a-zA-Z]*);base64,([^"]*)"');
      if (urlmatch?.length === 3) {
        if (inlineImageSourceType === FileSourceTypeEnum.RICH_TEXT_CASE_FILE) {
          const contentType = 'image/' + urlmatch[1];
          const blob = base64toBlob(urlmatch[2], contentType);
          // TODO Generate better image name
          const now = new Date();
          const randomName = `image_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${new Date().getTime()}.${getFileExtensionFromMimeType(
            contentType,
          )}`;
          const file = new File([blob], randomName, { type: contentType });
          // If online, upload file and return new link
          const serverUrl = await new Promise((resolve) => {
            this.platformFilesService
              .createFile(
                file,
                inlineImageSourceType,
                {
                  _id: contact._id,
                  account: contact.organizationId,
                },
                false,
                source,
              )
              .subscribe((data) => {
                // This function is triggered multiple times since to keep track of the uploading percentage
                this.logger.debug()('File in inline rich text image added from copyPaste', data);
                // Data object is only set when the upload is completed
                if (data) {
                  this.insertNewLine(editor);
                  const filesDownloadUrl =
                    this.serverConfigurationService.filesDownloadUrl.getValue();
                  return resolve(`${filesDownloadUrl}${data.data._id}`);
                }
              });
          });
          if (serverUrl) {
            content = content.replace(urlmatch[0], `src="${serverUrl}"`);
          }
        } else {
          content = content.replace(imageTag, '');
          this.toastrService.error(this.translations.errorMessageBase64);
        }
      }
    }
    return content;
  }
}
