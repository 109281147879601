import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

export class AbortToken {
  aborted = false;
  completed = new ReplaySubject<boolean>(1);

  async abort(): Promise<void> {
    this.aborted = true;
    await this.completed.pipe(take(1)).toPromise();
  }

  check(silent: boolean = false): boolean {
    if (this.aborted) {
      this.completed.next(true);
    }
    if (silent || !this.aborted) {
      return !this.aborted;
    } else {
      throw { aborted: true };
    }
  }

  complete(): void {
    this.completed.next(true);
  }
}
