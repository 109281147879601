import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';
import { BehaviorSubject, ReplaySubject, Subject, map, skipUntil, takeUntil } from 'rxjs';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public label?: string;
  @Input() public appearance: 'legacy' | 'outline' = 'outline';
  @Input() public required: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public checked: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public touched: boolean = false;
  @Input() public color: ThemePalette = 'primary';
  @Input() public checkErrorMessage?: string;
  @Input() public subLabel?: string;
  @Input() public boldLabel: boolean = false;
  @Input() public isInline: boolean = false;
  @Input() public showBottomPadding: boolean = true;
  @Input() public dataTestId?: string;

  protected hovered = false;
  protected readonly translations = {
    defaultErrorMessage: $localize`:@@checkIsRequired:Check is required`,
  };

  protected readonly disabled$ = new BehaviorSubject<boolean>(false);
  protected readonly checked$ = new BehaviorSubject<boolean>(false);
  private readonly touched$ = new ReplaySubject<void>(1);
  private onChangeFn?: (isChecked: boolean) => void;

  private readonly destroyed$ = new Subject<void>();

  protected readonly hasError$ = this.checked$.pipe(
    skipUntil(this.touched$),
    map((checked) => Boolean(this.required) && !checked),
  );

  public ngOnInit(): void {
    if (this.touched) this.touched$.next();
    this.disabled$.next(this.disabled);
    this.checked$.next(this.checked);
  }

  public registerOnChange(onChangeFn: (isChecked: boolean) => void): void {
    this.onChangeFn = onChangeFn;
  }

  public registerOnTouched(onTouchedFn: () => void): void {
    this.touched$.pipe(takeUntil(this.destroyed$)).subscribe(onTouchedFn);
  }

  public writeValue(isChecked: boolean): void {
    this.checked$.next(isChecked);
  }

  public setDisabledState(isDisabled: boolean): void {
    if (this.readonly) return;
    this.disabled$.next(isDisabled);
  }

  protected change(value: MatCheckboxChange): void {
    this.touched$.next();
    this.checked$.next(value.checked);
    this.onChangeFn?.(value.checked);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
