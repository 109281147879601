import { Product } from '@remberg/assets/common/main';
import { QuotationRequestPreselection } from '../definitions';

export const getQuotationRequestPreselection = (
  assets?: Product[],
): QuotationRequestPreselection | undefined => {
  if (!assets) return;

  return {
    assetIds: assets.map((asset) => asset?._id).filter(Boolean),
    assetTypeIds: assets
      .map((asset) =>
        typeof asset?.productType === 'string' ? asset?.productType : asset?.productType?._id,
      )
      .filter(Boolean),
  };
};
