import { RembergDate, RembergDateTime, RembergTimestamp } from '@remberg/global/common/core';
import { format, formatInTimeZone } from 'date-fns-tz';

/** Returns the date in the form of "2020-10-15". No time is returned. Default timezone is the running machine's TZ */
export function toRembergDate(date: Date, tz?: string): RembergDate {
  return tz ? formatInTimeZone(date, tz, 'yyyy-MM-dd') : format(date, 'yyyy-MM-dd');
}

/** Returns the date in UTC form, along with timezone information */
export function toRembergDateTime(date: Date | RembergTimestamp, tz?: string): RembergDateTime {
  const utc = typeof date === 'string' ? date : date.toISOString();

  return [utc, tz].filter((e) => !!e).join('_');
}

/** Parses a RembergDateTime string and transforms it to a RembergDate */
export function rembergDateTimeToRembergDate(
  value: RembergDateTime | undefined,
): RembergDate | undefined {
  if (!value || typeof value !== 'string') {
    return undefined;
  }

  const [utcDatetime, tz] = value.split(/_(.*)/s);
  if (!utcDatetime) {
    return undefined;
  }

  try {
    return toRembergDate(new Date(utcDatetime), tz);
  } catch {
    return 'INVALID_DATE';
  }
}
