import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from '@remberg/global/ui';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { ToastrService } from 'ngx-toastr';
import { Subscription, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  protected message?: string;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly logger: LogService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly rembergUsersService: RembergUsersService,
  ) {}

  public ngOnInit(): void {
    this.logger.debug()('Verify email component loaded');

    this.subscriptions.add(
      this.route.queryParams.subscribe(async (routeParams) => {
        await this.handleToken(routeParams['token']);
        setTimeout(() => {
          this.router.navigate(['../'], { relativeTo: this.route });
        }, 500);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async handleToken(token?: string): Promise<void> {
    if (!token) {
      this.toastr.error(
        $localize`:@@linkIsNotValidAnymoreEmailNotVerified:This Link is not valid anymore. Email not verified.`,
        $localize`:@@error:Error`,
      );
      return;
    }

    this.message = $localize`:@@yourEmailVerificationLinkIsBeingChecked:Your email verification link is being checked.`;
    this.cdRef.markForCheck();

    try {
      await firstValueFrom(this.rembergUsersService.checkVerificationToken({ token }));
      this.logger.debug()('Verify Email Token valid');
      this.message = $localize`:@@yourEmailIsGettingVerified:Your email is getting verified.`;
      this.cdRef.markForCheck();

      await firstValueFrom(this.rembergUsersService.verifyEmail({ token }));
      this.toastr.success(
        $localize`:@@emailSuccessfullyVerified:Email successfully verified.`,
        $localize`:@@success:Success`,
      );
    } catch (error) {
      this.logger.error()(error);
      this.logger.debug()('Verify Email Token not valid');
      this.toastr.error(
        $localize`:@@linkIsNotValidAnymoreEmailNotVerified:This Link is not valid anymore. Email not verified.`,
        $localize`:@@error:Error`,
      );
    }
  }
}
