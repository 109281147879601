import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LocalStorageKeys,
  LogService,
  StorageService,
} from '@remberg/global/ui';
import {
  Tenant,
  TenantCreateOneBody,
  TenantCreateOneResponse,
  TenantFindManyQuery,
  TenantFindManyResponse,
  TenantPublic,
  TenantUpdateAdminBody,
  TenantUpdateConfigurationBody,
  TenantUpdateLogoResponse,
  TenantUpdateThemeBody,
} from '@remberg/tenants/common/main';
import { Observable, from, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private readonly TENANTS_URL = `${API_URL_PLACEHOLDER}/tenants/v1`;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly storageService: StorageService,
  ) {}

  /**
   * @description
   * Only to be used during initialization. In order to fetch the current tenant use the store.
   */
  public getOneForGlobalInitializationOrThrow(tenantId: string): Observable<Tenant> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()(`Getting Local Tenant in offline mode ${tenantId}`);

      return from(this.storageService.get(LocalStorageKeys.IONIC_CURRENT_TENANT)).pipe(
        map((tenantString: string | undefined) => {
          if (!tenantString) {
            throw new Error('Local Tenant not found');
          }
          const tenant: Tenant = JSON.parse(tenantString);
          if (tenant._id !== tenantId) {
            throw new Error(
              `Local Tenant Id ${tenant._id} does not match requested id ${tenantId}`,
            );
          }
          return tenant;
        }),
      );
    }

    return this.getOne(tenantId).pipe(
      map((tenant) => {
        if (!tenant) {
          throw new Error('Tenant not found');
        }
        return tenant;
      }),
    );
  }

  public getOne(tenantId: string): Observable<Tenant> {
    return this.http.get<Tenant>(`${this.TENANTS_URL}/${tenantId}`);
  }

  public getPublicBySubdomain(subdomain: string): Observable<TenantPublic> {
    return this.http.get<TenantPublic>(`${this.TENANTS_URL}/subdomain/${subdomain}`);
  }

  public getPublicById(tenantId: string): Observable<TenantPublic> {
    return this.http.get<TenantPublic>(`${this.TENANTS_URL}/public/${tenantId}`);
  }

  public getMany(options: TenantFindManyQuery): Observable<TenantFindManyResponse> {
    let params = new HttpParams();

    if (options.limit !== undefined) {
      params = params.set('limit', options.limit);
    }
    if (options.page !== undefined) {
      params = params.set('page', options.page);
    }
    if (options.sortDirection) {
      params = params.set('sortDirection', options.sortDirection);
    }
    if (options.sortField) {
      params = params.set('sortField', options.sortField);
    }
    if (options.search) {
      params = params.set('search', options.search.toString());
    }

    return this.http.get<TenantFindManyResponse>(this.TENANTS_URL, { params });
  }

  public updateLogo(tenantId: string, file: File): Observable<TenantUpdateLogoResponse> {
    // Send multipart/formdata request here
    const formData = new FormData();
    formData.append('file', file, encodeURIComponent(file.name));
    return this.http.put<TenantUpdateLogoResponse>(
      `${this.TENANTS_URL}/${tenantId}/logo`,
      formData,
    );
  }

  public updateTheme(tenantId: string, body: TenantUpdateThemeBody): Observable<void> {
    return this.http.patch<void>(`${this.TENANTS_URL}/${tenantId}/theme`, body);
  }

  public updateConfiguration(
    tenantId: string,
    body: TenantUpdateConfigurationBody,
  ): Observable<void> {
    return this.http.patch<void>(`${this.TENANTS_URL}/${tenantId}/configuration`, body);
  }

  public updateAdmin(tenantId: string, body: TenantUpdateAdminBody): Observable<void> {
    return this.http.patch<void>(`${this.TENANTS_URL}/${tenantId}`, body);
  }

  public createOne(body: TenantCreateOneBody): Observable<TenantCreateOneResponse> {
    return this.http.post<TenantCreateOneResponse>(`${this.TENANTS_URL}`, body);
  }

  public clearData(tenantId: string, shouldDeleteManagedOrganizations = false): Observable<void> {
    return this.http.delete<void>(`${this.TENANTS_URL}/${tenantId}/clear`, {
      params: new HttpParams().set(
        'shouldDeleteManagedOrganizations',
        shouldDeleteManagedOrganizations,
      ),
    });
  }

  public deleteOne(tenantId: string): Observable<void> {
    return this.http.delete<void>(`${this.TENANTS_URL}/${tenantId}`);
  }
}
