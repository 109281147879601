import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedDialogConfig, SharedDialogTypeEnum } from '@remberg/global/ui';
import { LayoutService } from '../../services/layout.service';
@Component({
  selector: 'app-simple-select-dialog',
  templateUrl: './simple-select-dialog.component.html',
  styleUrls: ['./simple-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleSelectDialogComponent {
  protected options?: { identifier: string; label: string; icon?: string }[];
  protected selectedOption?: { identifier: string; label: string; icon?: string };

  constructor(
    public layout: LayoutService,
    @Inject(MAT_DIALOG_DATA)
    public config: SharedDialogConfig & { dialogId: SharedDialogTypeEnum.ModalSimpleSelectDialog },
    public dialogRef: MatDialogRef<SimpleSelectDialogComponent>,
  ) {
    this.options = config.data.options;
    this.selectedOption = config.data.selectedOption;
  }

  protected dismiss(): void {
    this.dialogRef.close();
  }

  protected select(selection: string): void {
    this.dialogRef.close({
      selectedOption: selection,
    });
  }
}
