import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FilterType, INSTANCE_COUNTER_REGEX } from '@remberg/global/common/core';
import {
  ApiResponse,
  LogService,
  SQLQueryParams,
  SyncDataTypesEnum,
  stringToSQLSortDirection,
} from '@remberg/global/ui';
import { CaseAPIFilterFieldEnum, ServiceCase } from '@remberg/tickets/common/main';
import { ServiceCaseOfflineServiceInterface } from '@remberg/tickets/ui/clients';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

const enum ColumnNamesEnum {
  SUBJECT = 'subject',
  TICKET_ID = 'ticketID',
}

const params: SQLQueryParams<ColumnNamesEnum> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.SERVICECASES,
  columns: {
    [ColumnNamesEnum.SUBJECT]: {
      type: 'TEXT',
      valueFunction: (val: ServiceCase) => val?.subject?.trim(),
      sortNoCase: true,
    },
    [ColumnNamesEnum.TICKET_ID]: {
      type: 'INTEGER',
      valueFunction: (val: ServiceCase) => val?.ticketID,
    },
  },
};

@Injectable()
export class ServiceCaseOfflineService
  extends BaseOfflineService<ServiceCase, CaseAPIFilterFieldEnum>
  implements ServiceCaseOfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, params, logger, store);
  }

  async getServiceCasesWithCount(
    limit?: number,
    offset?: number,
    sortColumn?: string,
    sortDirection?: string,
    searchValue?: string,
    filters?: FilterType<string>[],
  ): Promise<ApiResponse<ServiceCase[]>> {
    // filters:
    const filterStrings = [];

    // search:
    if (searchValue) {
      const matches = searchValue.match(INSTANCE_COUNTER_REGEX);
      if (!matches?.[1] || isNaN(parseInt(matches[1], 10))) {
        filterStrings.push(
          `${params.tableName}.${ColumnNamesEnum.SUBJECT} LIKE '%${searchValue}%'`,
        );
      } else {
        filterStrings.push(
          `(${params.tableName}.${ColumnNamesEnum.SUBJECT} LIKE '%${searchValue}%' OR ${
            params.tableName
          }.${ColumnNamesEnum.TICKET_ID} = ${parseInt(matches[1], 10)})`,
        );
      }
    }

    // sorting (must be mapped to the right column):
    const sqlSortDirection = stringToSQLSortDirection(sortDirection);
    if (sortColumn === ColumnNamesEnum.SUBJECT) {
      sortColumn = ColumnNamesEnum.SUBJECT;
    }

    return this.getInstancesWithCount(
      limit,
      offset,
      sortColumn,
      sqlSortDirection,
      filterStrings.join(' AND '),
    );
  }
}
