import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { getConfirmationCancelMoveAway, LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { ComponentCanDeactivate } from '../helpers/component-can-deactivate.interface';
import { GlobalSelectors, RootGlobalState } from '../store';

export const pendingChangesGuardFn: CanDeactivateFn<ComponentCanDeactivate> = async (
  component: ComponentCanDeactivate,
): Promise<boolean> => {
  const matDialog = inject(MatDialog);
  const logger = inject(LogService);
  const store = inject(Store<RootGlobalState>);

  const isUserLoggedIn = await firstValueFrom(store.select(GlobalSelectors.selectIsLoggedIn));
  if (!isUserLoggedIn) return true;

  if (!component) return true;

  logger.debug()(`Deactivating page: ${component.canDeactivateSettingName}`);

  if (component?.canDeactivate && !component?.canDeactivate()) {
    const isConfirmed = !(await firstValueFrom(
      getConfirmationCancelMoveAway(matDialog).afterClosed(),
    ));

    if (isConfirmed) {
      component?.canDeactivateConfirmed?.();
    }
    return isConfirmed;
  }

  logger.debug()(component.canDeactivateSettingName, 'can be deactivated');

  return true;
};
