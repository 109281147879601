import { de, el, enUS, es, fr, it, Locale, th, tr } from 'date-fns/locale';
import { LanguageCodeEnum } from '../enums';

/**
 * When a new language is added, we need to add it additionally to
 * libs/conversations/ui/editor/src/components/ck-editor/ck-editor.component.ts
 * translations to support the langue in the editor.
 */

/** JSON linking date-fns locales to LanguageCodeEnum */
export const LanguageCodesDateFns: { [id in LanguageCodeEnum]: Locale } = {
  'en-US': enUS,
  'de-DE': de,
  'tr-TR': tr,
  'fr-FR': fr,
  'es-ES': es,
  'it-IT': it,
  'el-GR': el,
  'th-TH': th,
};

/**
 * Wrapper constant that is used by the getDefaultLanguageCodeEnumForString function.
 * @see {@link getDefaultLanguageCodeEnumForString}
 */
export const ShortLanguageCodeMapper = {
  code: LanguageCodeEnum.EN_US,
  en: LanguageCodeEnum.EN_US,
  de: LanguageCodeEnum.DE,
  tr: LanguageCodeEnum.TR,
  fr: LanguageCodeEnum.FR,
  es: LanguageCodeEnum.ES,
  it: LanguageCodeEnum.IT,
  el: LanguageCodeEnum.EL,
  th: LanguageCodeEnum.TH,
};
