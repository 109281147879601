import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { OrganizationRaw, OrganizationsFilterEnum } from '@remberg/crm/common/main';
import { ApiResponse, OfflineService } from '@remberg/global/ui';
import { OrganizationsFindManyWithCountCompatibilityParams } from './organizations.definitions';

export interface OrganizationOfflineServiceInterface
  extends OfflineService<
    OrganizationRaw,
    AdvancedFilterQuery<OrganizationsFilterEnum>,
    AdvancedFilter<OrganizationsFilterEnum>
  > {
  getManyWithCount(
    options: OrganizationsFindManyWithCountCompatibilityParams,
  ): Promise<ApiResponse<OrganizationRaw[]>>;
}

export const ORGANIZATIONS_OFFLINE_SERVICE =
  new InjectionToken<OrganizationOfflineServiceInterface>(
    'Token for injecting organizations offline service without circular dependencies',
  );
