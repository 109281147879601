/**
 * Helps to return strict typed objects or keys of certain type
 * when it's hard or cumbersome to define it explicitly
 *
 * @example
 * interface Foo {
 *  name: string;
 * }
 *
 * function getFooKey(...) {
 *   ...
 *
 * // return 'name' as keyof Foo -- wouldn't produce an error if the key name changes
 *    return as<keyof Foo>('name'); // - works just fine in that case
 * }
 *
 * .pipe(
 *    map(input => {
 *    ... some logic ...
 *
 *    return as<SomeItem>({ // now IntelliSense will help you to construct SomeItem correctly
 *       ...
 *    });
 * }
 */
export function as<T>(obj: T): T {
  return obj;
}

export function isDefined<T>(obj: T | undefined | null): obj is T {
  // eslint-disable-next-line no-null/no-null
  return obj !== undefined && obj !== null;
}

export function assertDefined<T>(obj: T | undefined | null, message?: string): asserts obj is T {
  if (!isDefined(obj)) {
    throw new Error(message || 'Assert: object is undefined or null');
  }
}

export function assertNever(obj: never, message?: string): void {
  throw new Error(
    message || `Assert: this should never happen. But input was: ${JSON.stringify(obj)}`,
  );
}

export function getDefinedOrThrow<T>(obj: T | undefined | null, message?: string): T {
  if (!isDefined(obj)) {
    throw new Error(message || 'Ensure: object is undefined or null');
  }

  return obj;
}
