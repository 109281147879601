import { OfflinePopulateType } from '../definitions/sync.model';

export function mapPopulateToOfflinePopulate<T extends string>(
  populateResourceArray: T[] | undefined,
): OfflinePopulateType<T> | undefined {
  if (!populateResourceArray) return;
  return populateResourceArray.reduce(
    (populateMap, populate) => ({ ...populateMap, [populate]: true }),
    {} as OfflinePopulateType<T>,
  );
}
