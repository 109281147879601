import { SUBDOMAIN_REGEX } from '@remberg/global/common/core';
import { RESERVED_SUBDOMAINS } from '../definitions';

export function getLocalBaseUrl(): string {
  if (window.location.host.includes('localhost') && window.location.port !== '4200') {
    return `local.localhost:${window.location.port}`;
  }
  return 'local.localhost:4200';
}

/**
 * Checks is the domains is already used internally or not in order to be a valid domain for a customer
 * Also checks for invalid symbols in subdomain string.
 * @param  {string} subDomain customer subdomain trying to check
 * @returns boolean
 */
export function validateSubdomainForCustomer(subDomain: string): boolean {
  return !isNotAReservedSubdomain(subDomain) && SUBDOMAIN_REGEX.test(subDomain);
}

export function isNotAReservedSubdomain(subdomainCandidate: string): boolean {
  return (
    RESERVED_SUBDOMAINS.includes(subdomainCandidate) || // General check
    subdomainCandidate.startsWith('pr-') || // Needed for AWS Amplify to work correctly
    subdomainCandidate.startsWith('preview-')
  ); // Needed for Argo CI/CD PR Generator
}
