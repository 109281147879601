import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { SEARCH_DEBOUNCE_TIME } from '@remberg/global/ui';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[debounce]',
})
export class DebounceDirective<T> implements AfterViewInit, OnDestroy {
  @Output()
  public debounce: EventEmitter<T> = new EventEmitter<T>();

  @Input()
  public debounceDelay = SEARCH_DEBOUNCE_TIME;

  // eslint-disable-next-line @typescript-eslint/ban-types
  private eventRegistration?: Function;
  private _debounceEvent?: string;

  @Input() public set debounceEvent(eventName: string) {
    this._debounceEvent = eventName;
    this.unlisten();
    this.eventRegistration = this.renderer.listen(
      this.elementRef.nativeElement,
      this._debounceEvent,
      (event) => {
        this.stream.next(event.target.value);
      },
    );
  }

  private stream: Subject<T> = new Subject<T>();
  private subscription?: Subscription;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  public ngAfterViewInit(): void {
    this.subscription = this.stream
      .pipe(debounceTime(this.debounceDelay))
      .subscribe((value: T) => this.debounce.next(value));
  }

  private unlisten(): void {
    if (this.eventRegistration) {
      this.eventRegistration(); // cancel previous listener
    }
  }

  public ngOnDestroy(): void {
    this.unlisten();
    this.subscription?.unsubscribe();
  }
}
