import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { ContactBasic } from '@remberg/crm/common/base';
import {
  ContactCompatibility,
  ContactRaw,
  ContactsFilterEnum,
  ContactsFindManyBasicResponse,
  ContactsFindManyCompatibilityQuery,
  ContactsFindManyQuery,
} from '@remberg/crm/common/main';
import { OfflineService } from '@remberg/global/ui';
import { ContactsFindManyOfflineParams } from './contacts.definitions';

export interface ContactsOfflineServiceInterface
  extends OfflineService<
    ContactRaw,
    AdvancedFilterQuery<ContactsFilterEnum>,
    AdvancedFilter<ContactsFilterEnum>
  > {
  getManyWithCountCompatibility(
    options: ContactsFindManyCompatibilityQuery,
  ): Promise<{ data: ContactCompatibility[]; count: number }>;

  getManyWithCountBasic(options: ContactsFindManyQuery): Promise<ContactsFindManyBasicResponse>;

  getManyWithCount(
    options: ContactsFindManyOfflineParams,
  ): Promise<{ contacts: ContactRaw[]; count: number }>;

  getManyBasicByIds(ids: string[]): Promise<ContactBasic[]>;
}

export const CONTACTS_OFFLINE_SERVICE = new InjectionToken<ContactsOfflineServiceInterface>(
  'Token for injecting contacts offline service without circular dependencies',
);
