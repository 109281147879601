import { SortDirectionEnum, valuesOf } from '@remberg/global/common/core';

export type SortField<T extends string> = { [key in T]?: SortFieldOption<T> };
export interface SortFieldOption<T> {
  identifier: T;
  label: string;

  /** If set to true, it is removed by using the removeInaccessibleSortFields function */
  isDisabled?: boolean;
}

export type SortDirection<T extends string> = { [key in T]?: SortDirectionOption<T> };
export interface SortDirectionOption<T> {
  identifier: T;
  label: string;
}
export const SORT_DIRECTION_OPTIONS: SortDirection<SortDirectionEnum> = {
  asc: {
    identifier: SortDirectionEnum.ASC,
    label: $localize`:@@sortingDirectionAscending: Ascending`,
  },
  desc: {
    identifier: SortDirectionEnum.DESC,
    label: $localize`:@@sortingDirectionDescending: Descending`,
  },
};

export function validateSortDirection(
  sortDirection: SortDirectionEnum,
): SortDirectionEnum | undefined {
  const sortDirectionOptions = Object.values(SortDirectionEnum);
  if (sortDirectionOptions.includes(sortDirection)) {
    return sortDirection;
  } else {
    return undefined;
  }
}

export function validateSortField<T extends string>(sortField: T, sortFieldOptions: T[]): T | null {
  if (sortFieldOptions.includes(sortField)) {
    return sortField;
  } else {
    return null;
  }
}

export function removeInaccessibleSortFields<T extends string>(
  sortFields: SortField<T>,
): SortField<T> {
  const result = {} as SortField<T>;
  for (const field of valuesOf(sortFields)) {
    if (field && !field.isDisabled) {
      result[field.identifier] = field;
    }
  }
  return result;
}
