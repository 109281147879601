import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  LogService,
  MustMatch,
  PASSWORD_ERROR_MESSAGES,
  PASSWORD_VALIDATORS_REQUIRED,
} from '@remberg/global/ui';
import { GlobalSelectors, RootGlobalState } from '@remberg/ui-core/core';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { ToastrService } from 'ngx-toastr';
import { Subscription, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-setpwd',
  templateUrl: './setpwd.component.html',
})
export class SetpwdComponent implements OnInit, OnDestroy {
  protected passwordResetFormGroup = new FormGroup(
    {
      token: new FormControl<string>('', { nonNullable: true }),
      password: new FormControl<string>('', {
        validators: PASSWORD_VALIDATORS_REQUIRED,
        nonNullable: true,
      }),
      passwordConfirm: new FormControl<string>('', {
        validators: Validators.required,
        nonNullable: true,
      }),
    },
    {
      validators: [MustMatch('password', 'passwordConfirm')],
    },
  );

  protected passwordErrorMessages = PASSWORD_ERROR_MESSAGES;

  protected submittedForm = false;
  protected token?: string;

  protected sent = false;
  protected shouldRenew = false;
  protected renewSuccess = false;
  protected tokenIsValid = false;
  protected tokenValidityChecked = false;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly toastr: ToastrService,
    private readonly rembergUsersService: RembergUsersService,
    private readonly router: Router,
    private readonly logger: LogService,
    private readonly route: ActivatedRoute,
    private readonly store: Store<RootGlobalState>,
  ) {}

  get resetForm() {
    return this.passwordResetFormGroup.controls;
  }

  public ngOnInit(): void {
    this.init();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected async setPwd(event: KeyboardEvent | MouseEvent): Promise<void> {
    if (event.type === 'click' || (event as KeyboardEvent).keyCode === 13) {
      this.submittedForm = true;
      if (this.passwordResetFormGroup.valid) {
        this.sent = true;
        try {
          const { token, password } = this.passwordResetFormGroup.getRawValue();
          await firstValueFrom(this.rembergUsersService.setPassword({ token, password }));
          this.toastr.success(
            $localize`:@@passwordWasResetSuccessfully:Password was reset successfully.`,
            $localize`:@@success:Success`,
          );
        } catch (error) {
          this.logger.error()(error);
          this.toastr.warning(
            $localize`:@@passwordWasNotReset:Password was not reset. Please contact support.`,
            $localize`:@@warning:Warning`,
          );
        }

        this.router.navigateByUrl('/login');
      }
    }
  }

  protected redirectToForgotPassword(): void {
    this.router.navigateByUrl('/forgot');
  }

  private async init(): Promise<void> {
    this.logger.debug()('Check if User is logged in?');
    if (await firstValueFrom(this.store.select(GlobalSelectors.selectIsLoggedIn))) {
      this.logger.info()('User is logged in, sending him to the interface.');
      this.router.navigateByUrl('/');
    } else {
      this.subscriptions.add(
        this.route.queryParams.subscribe(async (params) => {
          const token = params['token'];
          if (token) {
            this.token = token;

            try {
              await firstValueFrom(this.rembergUsersService.checkResetToken({ token }));
              setTimeout(() => {
                this.tokenValidityChecked = true;
              }, 500);

              this.tokenIsValid = true;
              this.passwordResetFormGroup?.patchValue({ token });
            } catch (error) {
              this.logger.error()(error);
              this.tokenValidityChecked = true;
              if ((error as HttpErrorResponse).status === HttpStatusCode.NotFound) {
                this.shouldRenew = true;
              }
            }
          } else {
            this.logger.error()('Missing token');
            this.router.navigateByUrl('/');
          }
        }),
      );
    }
  }
}
