import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { Observable, mergeMap, of, take } from 'rxjs';
import { GlobalSelectors, NavigationalActions, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private store: Store<RootGlobalState>,
    private logger: LogService,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    this.logger.debug()('AuthGuardService - canActivate');
    return this.store.select(GlobalSelectors.selectIsLoggedIn).pipe(
      take(1),
      mergeMap((isLoggedIn) => {
        if (!isLoggedIn) {
          // Handle redirects for public pages
          if (state.url.startsWith('/assets/detail/')) {
            this.logger.debug()(
              'AuthGuardService - user is not logged in (redirecting to public asset page)',
            );
            this.store.dispatch(NavigationalActions.goToUrl({ url: '/public' + state.url }));
            return of(false);
          }

          // Default: redirect to the login page with the targetUrl as query parameter
          this.logger.debug()(
            'AuthGuardService - user is not logged in (redirecting to landing page)',
          );
          this.store.dispatch(
            NavigationalActions.goToUnauthenticatedLandingPage({ targetUrl: state.url }),
          );
          return of(false);
        }
        return of(true);
      }),
    );
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
