import { Injectable } from '@angular/core';
import { mapToProductType, ProductType } from '@remberg/assets/common/base';
import {
  AssetTypesCreateOneBody,
  AssetTypesFindManyByIdsBody,
  AssetTypesFindManyQuery,
  AssetTypesSortFieldEnum,
  AssetTypesSortFieldsEnum,
  AssetTypesUpdateOneBody,
} from '@remberg/assets/common/main';
import { SortDirectionEnum } from '@remberg/global/common/core';
import { ApiResponse, LogService } from '@remberg/global/ui';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AssetTypesLegacyService } from './asset-types-legacy.service';
import { AssetTypesNewService } from './asset-types-new.service';

const mapAssetTypesSortFieldEnum = (
  sortField: AssetTypesSortFieldEnum | undefined,
): AssetTypesSortFieldsEnum | undefined => {
  switch (sortField) {
    case AssetTypesSortFieldEnum.MANUFACTURER:
      return AssetTypesSortFieldsEnum.MANUFACTURER_NAME;
    case AssetTypesSortFieldEnum.NAME:
      return AssetTypesSortFieldsEnum.LABEL;
    default:
      return undefined;
  }
};

@Injectable({
  providedIn: 'root',
})
export class AssetTypesService {
  constructor(
    private readonly logger: LogService,
    private readonly assetTypesLegacyService: AssetTypesLegacyService,
    private readonly assetTypesNewService: AssetTypesNewService,
  ) {}

  public createOne(
    isAssetsTemporaryEnabled: boolean,
    body: AssetTypesCreateOneBody,
    image?: File,
  ): Observable<ProductType> {
    if (isAssetsTemporaryEnabled) {
      return this.assetTypesNewService.createOne(body).pipe(
        switchMap((assetType) =>
          image
            ? this.assetTypesNewService.updateImage(assetType._id, image).pipe(
                catchError(() => {
                  this.logger.error()('Asset type image upload failed');
                  return of(assetType);
                }),
              )
            : of(assetType),
        ),
        map((assetType) => mapToProductType(assetType)),
      );
    }

    return this.assetTypesLegacyService.addProductType(body, image);
  }

  public updateOne(
    isAssetsTemporaryEnabled: boolean,
    assetTypeId: string,
    body: AssetTypesUpdateOneBody,
    image?: File,
  ): Observable<ProductType> {
    if (isAssetsTemporaryEnabled) {
      return this.assetTypesNewService.updateOne(assetTypeId, body).pipe(
        switchMap((assetType) =>
          image
            ? this.assetTypesNewService.updateImage(assetType._id, image).pipe(
                catchError(() => {
                  throw new Error('Asset type image upload failed');
                }),
              )
            : of(assetType),
        ),
        map((assetType) => mapToProductType(assetType)),
      );
    }
    return this.assetTypesLegacyService.updateProductType(assetTypeId, body, image);
  }

  public findOne(isAssetsTemporaryEnabled: boolean, assetTypeId: string): Observable<ProductType> {
    if (isAssetsTemporaryEnabled) {
      return this.assetTypesNewService
        .findOne(assetTypeId)
        .pipe(map((assetType) => mapToProductType(assetType)));
    }
    return this.assetTypesLegacyService.getProductType(assetTypeId);
  }

  public findMany(
    isAssetsTemporaryEnabled: boolean,
    {
      pageSize,
      pageIndex,
      sortDirection,
      sortField,
      searchValue,
      filterValue,
    }: {
      pageSize?: number;
      pageIndex?: number;
      sortDirection?: SortDirectionEnum;
      sortField?: AssetTypesSortFieldEnum;
      searchValue?: string;
      filterValue?: any;
    },
  ): Observable<ApiResponse<ProductType[]>> {
    if (isAssetsTemporaryEnabled) {
      const newSortField = mapAssetTypesSortFieldEnum(sortField);
      const newQuery: AssetTypesFindManyQuery = {
        page: pageIndex,
        limit: pageSize,
        search: searchValue,
        sortDirection: sortDirection,
        sortField: newSortField,
      };
      return this.assetTypesNewService
        .findMany(newQuery)
        .pipe(
          map(
            (response) =>
              new ApiResponse<ProductType[]>(mapToProductType(response.assetTypes), response.count),
          ),
        );
    }
    return this.assetTypesLegacyService.getProductTypesWithCount({
      pageSize,
      pageIndex,
      sortDirection,
      sortField,
      searchValue,
      filterValue,
    });
  }

  public findManyByIds(
    isAssetsTemporaryEnabled: boolean,
    { assetTypeIds }: AssetTypesFindManyByIdsBody,
  ): Observable<ProductType[]> {
    if (isAssetsTemporaryEnabled) {
      return this.assetTypesNewService
        .findManyByIds({ assetTypeIds })
        .pipe(map((assetTypes) => mapToProductType(assetTypes)));
    }
    return this.assetTypesLegacyService
      .getProductTypesWithCount({
        ids: assetTypeIds,
      })
      .pipe(map((response) => response.data));
  }

  public deleteOne(isAssetsTemporaryEnabled: boolean, assetTypeId: string): Observable<void> {
    if (isAssetsTemporaryEnabled) {
      return this.assetTypesNewService.deleteOne(assetTypeId);
    }
    return this.assetTypesLegacyService.deleteProductType(assetTypeId);
  }
}
