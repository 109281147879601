import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMAGE_FALLBACK_URL } from '@remberg/global/ui';
import { ServerFilePaths } from '@remberg/ui-core/core';

@Component({
  selector: 'app-remberg-card-hover',
  templateUrl: './remberg-card-hover.component.html',
  styleUrls: ['./remberg-card-hover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RembergCardHoverComponent {
  @Input() public content: boolean = true;

  /** hasHeaderImage: general control of image / icon */
  @Input() public hasHeaderImage: boolean = false;

  /** icon: use material icon to show before indicator, thumbnail will overwrite it */
  @Input() public icon?: string;

  /** thumbnail: image of the preview, overwrites icon */
  @Input() public thumbnailId?: string;
  @Input() public thumbnailSource?: ServerFilePaths;

  /** Fallback to use in case the thumbnail could not be found. Use IMAGE_FALLBACK_URL set up in the app.config */
  @Input() public thumbnailFallback: string = IMAGE_FALLBACK_URL.unknown;

  /** hasMetaData: general control of meta data section */
  @Input() public hasMetaData: boolean = false;
}
