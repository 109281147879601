import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpStatusCode,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  FormInstanceCreateFromEmptyBody,
  FormInstanceCreateFromExistingBody,
  FormInstanceCreateFromExistingResponse,
  FormInstanceTableQuery,
  FormInstanceTableResponse,
  FormInstanceUpdateBody,
} from '@remberg/forms/common/dtos';
import { FormInstance, FormInstanceEmailStatusEnum } from '@remberg/forms/common/main';
import { isDefined } from '@remberg/global/common/core';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  DEFAULT_PDF_FILE_NAME,
  DataResponse,
  LogService,
  OnlineStatusDataTypeEnum,
  getContentDispositionFileName,
  isHttpResponse,
} from '@remberg/global/ui';
import {
  Observable,
  catchError,
  filter,
  firstValueFrom,
  from,
  map,
  of,
  tap,
  throwError,
} from 'rxjs';
import { FORM_INSTANCE_OFFLINE_SERVICE, FormInstanceOfflineServiceInterface } from './definitions';

@Injectable({
  providedIn: 'root',
})
export class FormInstanceService {
  public readonly formsUrl = `${API_URL_PLACEHOLDER}/forms/v2/instances`;
  public readonly formInstancesSyncUrl = `${API_URL_PLACEHOLDER}/forms/v2/sync/instances`;

  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly http: HttpClient,
    private readonly logger: LogService,
    @Inject(FORM_INSTANCE_OFFLINE_SERVICE)
    private readonly formInstanceOfflineService: FormInstanceOfflineServiceInterface,
  ) {}

  public createFromEmpty(formInstanceMeta: FormInstanceCreateFromEmptyBody): Observable<string> {
    if (this.connectivityService.getConnected()) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      };

      return this.http
        .post<{ _id: string }>(this.formsUrl, formInstanceMeta, httpOptions)
        .pipe(map((data) => data._id));
    } else {
      this.logger.debug()('Offline createFromEmpty formInstance fallback...');

      return from(this.formInstanceOfflineService.createFromEmpty(formInstanceMeta)).pipe(
        map((data) => data._id),
      );
    }
  }

  public createFromExisting(model: FormInstanceCreateFromExistingBody): Observable<string> {
    if (this.connectivityService.getConnected()) {
      return this.createFromExistingOnline(model).pipe(map((data) => data._id));
    } else {
      this.logger.debug()('Offline createFromExisting formInstance fallback...');
      return from(this.formInstanceOfflineService.createFromExisting(model)).pipe(
        map((data) => data._id),
      );
    }
  }

  public createFromExistingOnline(
    model: FormInstanceCreateFromExistingBody,
  ): Observable<FormInstanceCreateFromExistingResponse> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.formsUrl}/existing`;

    return this.http.post<FormInstanceCreateFromExistingResponse>(url, model, httpOptions);
  }

  public async updateOne(formInstance: FormInstanceUpdateBody): Promise<OnlineStatusDataTypeEnum> {
    if (this.connectivityService.getConnected()) {
      return firstValueFrom(
        this.updateOneOnline(formInstance).pipe(
          map(async () => {
            if (this.connectivityService.offlineCapabilitiesEnabled()) {
              const instance = await this.formInstanceOfflineService.tryGetInstance(
                formInstance._id,
                false,
              );
              if (isDefined(instance)) {
                try {
                  await this.formInstanceOfflineService.updateOne(
                    formInstance,
                    OnlineStatusDataTypeEnum.ONLINE,
                  );
                } catch (error) {
                  this.logger.error()(error);
                  throw error;
                }
              } else {
                this.logger.warn()(
                  "Did not update formInstance data locally since it hasn't been synced before.",
                );
              }
            }
            return OnlineStatusDataTypeEnum.ONLINE;
          }),
        ),
      );
    } else {
      return await this.formInstanceOfflineService.updateOne(
        formInstance,
        OnlineStatusDataTypeEnum.OFFLINE_CHANGE,
      );
    }
  }

  public updateOneOnline(formInstance: FormInstanceUpdateBody): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.formsUrl}/${formInstance._id}`;
    return this.http.patch<void>(url, formInstance, httpOptions);
  }

  public getOneForDetailPage(
    formInstanceId: string,
  ): Observable<DataResponse<FormInstance> | undefined> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online getOne formInstance request...');
      const url = `${this.formsUrl}/${formInstanceId}`;
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: new HttpParams(),
      };
      return this.http.get<FormInstance>(url, httpOptions).pipe(
        map((data) => new DataResponse(data, OnlineStatusDataTypeEnum.ONLINE)),
        catchError((error: HttpErrorResponse) => {
          this.logger.error()('Error fetching form instance:', error);
          if (error.status === HttpStatusCode.NotFound) {
            return of(undefined);
          }
          return throwError(error);
        }),
      );
    } else {
      this.logger.debug()('Offline getOne formInstance fallback...');
      return from(this.formInstanceOfflineService.getOneForDetailPage(formInstanceId));
    }
  }

  /**
   * NOTE: This function is meant for the GPT PoC. It interacts with the Customer facing API in order
   * to get a summary of the form instance. In the future, we should move the summary logic to the internal
   * NestJS application (e.g. generate summary in the service) and call it from the Customer Facing API.
   * @param formInstanceId - The form instance to summarize
   */
  public getSummary(formInstanceId: string): Observable<DataResponse<FormInstance> | undefined> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()('Offline getSummary formInstance fallback...');
      throw new Error('Fetching the form summary is not possible in offline mode.');
    }

    this.logger.debug()('Online getSummary formInstance request...');
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    return this.http.get<FormInstance>(`/v1/forms/${formInstanceId}`, httpOptions).pipe(
      map((data) => new DataResponse(data, OnlineStatusDataTypeEnum.ONLINE)),
      catchError((error: HttpErrorResponse) => {
        this.logger.error()('Error fetching form instance summary:', error);
        if (error.status === HttpStatusCode.NotFound) {
          return of(undefined);
        }
        return throwError(error);
      }),
    );
  }

  /**
   * Generates a form instance PDF in the backend. Fails in offline mode.
   * @param formInstanceId - The form instance ID to generate a PDF for.
   * @param sectionId - If provided, all the sections below this ID will be excluded.
   * @returns A Pdf file.
   */
  public downloadFormInstancePdf(formInstanceId: string, sectionId?: number): Observable<File> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()('Offline downloadFormInstancePdf formInstance fallback...');
      throw new Error('Downloading PDFs not possible in offline mode.');
    }

    this.logger.debug()('Online downloadFormInstancePdf formInstance request...');
    const url = `${this.formsUrl}/${formInstanceId}/pdf`;

    let params = new HttpParams();
    if (isDefined(sectionId)) {
      params = params.set('sectionId', sectionId.toString());
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params,
        reportProgress: true,
        observe: 'events',
        responseType: 'blob' as 'json',
      })
      .pipe(
        // Check if event is instance of HttpResponse, because that means the file finished fetching from the API,
        // so the browser can download the blob buffer.
        filter(isHttpResponse),
        map((event) => {
          if (!event.ok) {
            throw new Error('An error occurred while generating the form instance!');
          }

          const contentDisposition = event.headers.get('content-disposition');

          const fileName =
            getContentDispositionFileName(contentDisposition) || DEFAULT_PDF_FILE_NAME;

          return new File([event.body as Blob], fileName, { type: 'application/pdf' });
        }),
      );
  }

  public getManyForList(query: FormInstanceTableQuery): Observable<FormInstanceTableResponse> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online formInstance request...');
      const url = `${this.formsUrl}`;
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: getParams(query),
      };
      return this.http.get<FormInstanceTableResponse>(url, httpOptions);
    } else {
      this.logger.debug()('Offline formInstance fallback...');
      return from(this.formInstanceOfflineService.getManyForList(query));
    }
  }

  public getEmailStatuses(
    formInstanceId: string,
  ): Observable<Record<string, FormInstanceEmailStatusEnum>> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online getEmailStatuses formInstance request...');
      const url = `${this.formsUrl}/${formInstanceId}/email-statuses`;
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: new HttpParams(),
      };

      return this.http.get<Record<string, FormInstanceEmailStatusEnum>>(url, httpOptions);
    } else {
      this.logger.debug()('Offline getEmailStatuses formInstance fallback...');
      return from(this.formInstanceOfflineService.getEmailStatusesForFormInstance(formInstanceId));
    }
  }

  public deleteFormInstance(formInstanceId: string): Observable<void> {
    if (this.connectivityService.getConnected()) {
      return this.deleteOneOnline(formInstanceId).pipe(
        tap(async () => {
          if (this.connectivityService.offlineCapabilitiesEnabled()) {
            const instance = await this.formInstanceOfflineService.tryGetInstance(
              formInstanceId,
              false,
            );

            if (isDefined(instance)) {
              try {
                await this.formInstanceOfflineService.deleteInstance(formInstanceId);
              } catch (error) {
                this.logger.error()(error);

                throw error;
              }
            } else {
              this.logger.warn()(
                "Did not delete formInstance data locally since it hasn't been synced before.",
              );
            }
          }
        }),
      );
    } else {
      return from(this.formInstanceOfflineService.deleteOne(formInstanceId));
    }
  }

  public deleteOneOnline(formInstanceId: string): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.formsUrl}/${formInstanceId}`;

    return this.http.delete<void>(url, httpOptions);
  }
}

function getParams({
  limit,
  page,
  search,
  sortField,
  sortDirection,
  filters,
  populate,
}: FormInstanceTableQuery): HttpParams {
  let params = new HttpParams();

  if (limit !== undefined) {
    params = params.set('limit', limit.toString());
  }
  if (page !== undefined) {
    params = params.set('page', page.toString());
  }
  if (search) {
    params = params.set('search', search);
  }
  if (sortField) {
    params = params.set('sortField', sortField);
  }
  if (sortDirection) {
    params = params.set('sortDirection', sortDirection);
  }
  if (filters !== undefined && filters?.length > 0) {
    params = params.set('filters', JSON.stringify(filters));
  }
  if (populate !== undefined && populate?.length > 0) {
    params = params.set('populate', populate.toString());
  }
  return params;
}
