import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { RequiredSessionInfo, SessionInfo } from '../definitions';
import { GlobalSelectors, RootGlobalState } from '../store';

/**
 * This service can be replaced by Angular Signal adapter
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private sessionInfo$ = new BehaviorSubject<SessionInfo>({});

  constructor(private readonly store: Store<RootGlobalState>) {
    this.store.select(GlobalSelectors.selectSessionInfo).subscribe((sessionInfo) => {
      this.sessionInfo$.next(sessionInfo);
    });
  }

  /**
   * This is used to get session info synchronously
   * @deprecated retrieve the relevant session information from the global store instead
   */
  public getSessionInfo(): RequiredSessionInfo {
    const sessionInfo = this.sessionInfo$.value;
    if (
      sessionInfo?.contact &&
      sessionInfo?.tenant &&
      sessionInfo?.organization &&
      sessionInfo?.rembergUser
    ) {
      return sessionInfo as RequiredSessionInfo;
    } else {
      throw new Error('Missing session info');
    }
  }
}
