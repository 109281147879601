<div
  class="spinner-container"
  [style.marginTop]="marginTop"
  [style.marginBottom]="marginBottom ?? marginTop"
  [style.justifyContent]="position"
>
  <mat-spinner
    [style.stroke]="color"
    [class.overwrite-color]="color"
    [diameter]="diameter"
    [color]="color ? undefined : materialColor"
    [strokeWidth]="strokeWidth"
  ></mat-spinner>
</div>
