import { Pipe, PipeTransform } from '@angular/core';
import { LanguageCodeEnum } from '@remberg/global/common/core';
import { getMappedLocale } from '@remberg/global/ui';

@Pipe({
  name: 'dateFormatWithoutYear',
})
export class DateFormatWithoutYearPipe implements PipeTransform {
  public transform(locale: string): string {
    const languageCode = getMappedLocale(locale);
    switch (languageCode) {
      case LanguageCodeEnum.DE:
        return 'dd.MM';
      case LanguageCodeEnum.EN_US:
        return 'MM/dd';
      default:
        return 'dd/MM';
    }
  }
}
