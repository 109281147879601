import { MultiLanguageText, RembergDateTime } from '@remberg/global/common/core';
import {
  AutofillTypeEnum,
  ConfigWithDynamicActions,
  ConfigWithPrefilling,
} from '../../../automation';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DateTimeInputField
  extends Field<FormFieldTypesEnum.DATE_TIME_INPUT, DateTimeInputFieldConfig> {}

export interface DateTimeInputFieldConfig
  extends ConfigWithPrefilling<DateTimeInputPrefillTargetsEnum>,
    ConfigWithDynamicActions {
  disabled?: boolean;
  required?: boolean;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  label: {
    text: MultiLanguageText;
    uiHide?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value?: {
    dropdownInterval?: number;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
    autofill?: AutofillTypeEnum;
  };
}

export enum DateTimeInputPrefillTargetsEnum {
  VALUE = 'value',
}

export interface DateTimeInputFieldData {
  dateTime?: RembergDateTime;
}
