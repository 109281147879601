import { Validators } from '@angular/forms';
import { PASSWORD_CONSTRAINTS } from '@remberg/global/common/core';

export const PASSWORD_VALIDATORS = [
  Validators.minLength(PASSWORD_CONSTRAINTS.minLength),
  Validators.pattern(PASSWORD_CONSTRAINTS.formatRegex),
];

export const PASSWORD_VALIDATORS_REQUIRED = PASSWORD_VALIDATORS.concat(Validators.required);

export const PASSWORD_ERROR_MESSAGES = {
  minlength: $localize`:@@passwordMustBeAtLeast12Characters:Password must be at least 12 characters.`,
  pattern: $localize`:@@passwordMustContainAtLeastOneUppercaseLowercaseNumber:Password must contain at least one uppercase letter, one lowercase letter, and one number.`,
  mustMatch: $localize`:@@passwordMustMatch:Password confirmation must match.`,
};
