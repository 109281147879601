import { CustomPropertyTypesEnum } from '@remberg/custom-properties/common/main';
import { AdvancedFilterTypeEnum } from '../enums';

export const CUSTOM_PROPERTY_TYPE_TO_ADVANCED_FILTER_TYPE_MAP: {
  [key in CustomPropertyTypesEnum]: AdvancedFilterTypeEnum;
} = {
  [CustomPropertyTypesEnum.INPUT_TEXT]: AdvancedFilterTypeEnum.TEXT,
  [CustomPropertyTypesEnum.TEXTAREA]: AdvancedFilterTypeEnum.TEXT,
  [CustomPropertyTypesEnum.URL]: AdvancedFilterTypeEnum.TEXT,
  [CustomPropertyTypesEnum.INPUT_NUMBER]: AdvancedFilterTypeEnum.NUMBER,
  [CustomPropertyTypesEnum.CHECKBOX]: AdvancedFilterTypeEnum.BOOLEAN,
  [CustomPropertyTypesEnum.SELECT]: AdvancedFilterTypeEnum.STATIC_SELECT,
  [CustomPropertyTypesEnum.REMBERG_ASSET_SELECT]: AdvancedFilterTypeEnum.ASSET,
  [CustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT]: AdvancedFilterTypeEnum.ORGANIZATION,
  [CustomPropertyTypesEnum.REMBERG_CONTACT_SELECT]: AdvancedFilterTypeEnum.CONTACT,
  [CustomPropertyTypesEnum.REMBERG_DATE]: AdvancedFilterTypeEnum.REMBERG_DATE,
  [CustomPropertyTypesEnum.REMBERG_DATETIME]: AdvancedFilterTypeEnum.REMBERG_TIMESTAMP,
};
