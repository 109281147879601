import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormTemplateVersion } from '@remberg/forms/common/main';
import {
  getStringID,
  LogService,
  SQLQueryParams,
  SQLSortDirection,
  SyncDataTypesEnum,
} from '@remberg/global/ui';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

enum ColumnNamesEnum {
  FORM_TEMPLATE_ID = 'formTemplateId',
  VERSION_NUMBER = 'versionNumber',
}

const params: SQLQueryParams<ColumnNamesEnum> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.FORMTEMPLATEVERSIONS,
  columns: {
    [ColumnNamesEnum.FORM_TEMPLATE_ID]: {
      type: 'TEXT',
      valueFunction: (val: FormTemplateVersion) => getStringID(val?.formTemplateId),
    },
    [ColumnNamesEnum.VERSION_NUMBER]: {
      type: 'INTEGER',
      valueFunction: (val: FormTemplateVersion) => val?.versionNumber,
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class FormTemplateVersionOfflineService extends BaseOfflineService<
  FormTemplateVersion,
  never
> {
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, params, logger, store);
  }

  public async getManyByIds(ids: string[]): Promise<Record<string, FormTemplateVersion>> {
    if (!(ids?.length > 0)) {
      return {};
    }
    const filterString = `_id IN (${ids.map((id) => `'${id}'`).join(',')})`;
    const formTemplateVersions = await this.getInstances(
      undefined,
      undefined,
      undefined,
      undefined,
      filterString,
    );
    const result: Record<string, FormTemplateVersion> = {};
    for (const formTemplateVersion of formTemplateVersions) {
      result[formTemplateVersion._id] = formTemplateVersion;
    }
    return result;
  }

  public async getLatestByFormTemplateId(
    formTemplateId: string,
  ): Promise<FormTemplateVersion | undefined> {
    const results = await this.getInstances(
      1,
      0,
      ColumnNamesEnum.VERSION_NUMBER,
      SQLSortDirection.DESC,
      `${ColumnNamesEnum.FORM_TEMPLATE_ID}='${formTemplateId}'`,
    );
    return results.length > 0 ? results[0] : undefined;
  }
}
