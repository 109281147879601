import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductType, mapToProductType } from '@remberg/assets/common/base';
import {
  ASSET_TYPES_LEGACY_OFFLINE_SERVICE,
  ASSET_TYPES_NEW_OFFLINE_SERVICE,
  AssetTypeRawOffline,
  AssetTypesFindManyOfflineQueryInterface,
  AssetTypesLegacyOfflineServiceInterface,
  AssetTypesOfflineServiceInterface,
} from '@remberg/assets/ui/clients';
import { ApiResponse, OfflinePopulateType } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../../store';
import { AssetTypesNewOfflineService } from './asset-types-new.offline.service';

@Injectable()
export class AssetTypesOfflineService implements AssetTypesOfflineServiceInterface {
  constructor(
    @Inject(ASSET_TYPES_LEGACY_OFFLINE_SERVICE)
    private readonly assetTypesLegacyOfflineService: AssetTypesLegacyOfflineServiceInterface,
    @Inject(ASSET_TYPES_NEW_OFFLINE_SERVICE)
    private readonly assetTypesNewOfflineService: AssetTypesNewOfflineService,
    private readonly store: Store<RootGlobalState>,
  ) {}

  public async tryGetInstance(params: {
    id: string | ProductType | AssetTypeRawOffline;
    populate?: OfflinePopulateType;
  }): Promise<ProductType | undefined> {
    try {
      return await this.getInstance(params);
    } catch {
      return undefined;
    }
  }

  public async getInstance(options: {
    id: string | ProductType | AssetTypeRawOffline;
    populate?: OfflinePopulateType;
  }): Promise<ProductType> {
    const { id, populate } = options;
    const isAssetsTemporaryEnabled = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsAssetsTemporaryEnabled),
    );
    if (isAssetsTemporaryEnabled) {
      return await this.assetTypesNewOfflineService
        .getInstance(id as string | AssetTypeRawOffline, populate)
        .then((assetType) => mapToProductType(assetType));
    }
    return await this.assetTypesLegacyOfflineService.getInstance(id, populate);
  }

  public async getAssetTypesWithCount(
    queryOptions: AssetTypesFindManyOfflineQueryInterface,
  ): Promise<ApiResponse<ProductType[]>> {
    const isAssetsTemporaryEnabled = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsAssetsTemporaryEnabled),
    );
    if (isAssetsTemporaryEnabled) {
      return await this.assetTypesNewOfflineService
        .getAssetTypesWithCount(queryOptions)
        .then(({ data, count }) => ({ data: mapToProductType(data), count }));
    }

    return await this.assetTypesLegacyOfflineService.getAssetTypesWithCount(queryOptions);
  }

  public async getManyByIds(ids: string[]): Promise<ProductType[]> {
    if (!ids.length) {
      return [];
    }
    const isAssetsTemporaryEnabled = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsAssetsTemporaryEnabled),
    );
    if (isAssetsTemporaryEnabled) {
      return await this.assetTypesNewOfflineService
        .getManyByIds(ids)
        .then((assetType) => mapToProductType(assetType));
    }

    return await this.assetTypesLegacyOfflineService.getManyByIds(ids);
  }
}
