<div class="feature-disabled-container">
  <img class="lock-icon" [attr.src]="'/assets/img/lock_open_fill.svg' | sanitizeUrl" />

  <h1 class="title" i18n="@@unlockThisFeature">Unlock this feature</h1>

  <p class="contact-para">
    <span i18n="@@contactRembergToAccessThisFeatureDot">
      Contact remberg to access this feature.
    </span>
    <span i18n="@@weWouldBeHappyToAssistYouInEnablingThisFunctionalityBasedOnYourSpecificNeedsDot">
      We would be happy to assist you in enabling this functionality based on your specific needs.
    </span>
  </p>

  <button
    *ngIf="isIntercomInitialized$ | async"
    class="contact-button"
    mat-flat-button
    color="primary"
    i18n="@@contactUs"
    (click)="onContactUsClick()"
  >
    Contact Us
  </button>
</div>
