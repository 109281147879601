import { Pipe, PipeTransform } from '@angular/core';
import { hexToRgb } from '@remberg/global/common/core';
import { HEX_REGEX } from '@remberg/global/ui';

/*
 * Converts rgb, hex and rgba to each other.
 * Takes a color string, transformationType and opacity and return a string
 * Usage:
 *   '#ffffff' | 'hexToRgba' | '0.7'
 *   '#ffffff' | 'hexToRgb'
 *   'rgb(12, 14, 15)' | 'rgbToHex'
 */

export interface RGB {
  r: number;
  g: number;
  b: number;
}

export interface HEX {
  hex: string;
}

@Pipe({
  name: 'cssColorConverter',
})
export class CssColorConverterPipe implements PipeTransform {
  public transform(color: string, transformationType: string, opacity: number): string | null {
    switch (transformationType) {
      case 'hexToRgb': {
        const rgb = hexToRgb(color);
        return rgb ? 'rgb(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ')' : null;
      }
      case 'rgbToHex': {
        return this.rgbToHex(color)['hex'];
      }
      case 'hexToRgba': {
        const rgba = hexToRgb(color);
        return this.isCorrectOpacity(opacity) && rgba
          ? 'rgba(' + rgba.r + ', ' + rgba.g + ', ' + rgba.b + ', ' + opacity + ')'
          : null;
      }
      case 'hexToLightenHex': {
        if (HEX_REGEX.test(color)) {
          return this.lightenColor(color, opacity);
        } else {
          return color;
        }
      }
      default:
        return null;
    }
  }

  private isCorrectOpacity(opacity: number): boolean {
    return opacity >= 0 && opacity <= 1;
  }

  private componentFromStr(numStr: string, percent: boolean): number {
    const num = Math.max(0, parseInt(numStr, 10));
    return percent ? Math.floor((255 * Math.min(100, num)) / 100) : Math.min(255, num);
  }

  private rgbToHex(rgb: string): HEX {
    const rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
    let r,
      g,
      b,
      hex = '';
    const result = rgbRegex.exec(rgb);
    if (result) {
      r = this.componentFromStr(result[1], !!result[2]);
      g = this.componentFromStr(result[3], !!result[4]);
      b = this.componentFromStr(result[5], !!result[6]);

      hex = '0x' + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }
    return { hex: hex };
  }

  private lightenColor(color: string, percent: number): string {
    const num = parseInt(color.replace('#', ''), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;

    return (
      '#' +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }
}
