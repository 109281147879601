/* eslint-disable max-len */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { EmptyListType } from '@remberg/global/ui';
import { LayoutService } from '@remberg/ui-core/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyListComponent implements OnDestroy {
  @Input() protected readonly type?: EmptyListType;
  @Input() protected readonly hideSubtext: boolean = false;
  @Input() protected readonly fullHeight: boolean = false;
  @Input() protected readonly addFileOption: boolean = false;
  @Input() protected readonly hideImage: boolean = false;
  @Input() protected readonly inCard: boolean = false;
  /**
   * Optional dataTestId if default value should not be used
   */
  @Input() protected dataTestId = 'empty-list-placeholder';

  @Output() private readonly c2aButton = new EventEmitter<unknown>();

  protected height: string = '140px';
  protected width: string = '140px';

  protected readonly emptyListTypeTranslations: Record<
    EmptyListType,
    { mainText: string; subText: string }
  > = {
    appointments: {
      mainText: $localize`:@@looksLikeThereAreNoAppointments:Looks like there are no appointments.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstAppointment:Remove search and filters or create your first appointment.`,
    },
    asset: {
      mainText: $localize`:@@looksLikeThereAreNoAssets:Looks like there are no assets.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstAsset:Remove search and filters or create your first asset.`,
    },
    assetHierarchy: {
      mainText: $localize`:@@looksLikeThereAreNoAssets:Looks like there are no assets.`,
      subText: $localize`:@@AssignParentOrChildToCurrentAsset:Assign a parent or a child to the current asset.`,
    },
    assettype: {
      mainText: $localize`:@@looksLikeThereAreNoAssetTypes:Looks like there are no asset types.`,
      subText: $localize`:@@RemoveSearchOrCreateYourFirstAssetType:Remove search or create your first asset type.`,
    },
    caseMessageTemplate: {
      mainText: $localize`:@@looksLikeThereAreNoCaseEmailTemplates:Looks like there are no case email templates.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstCaseEmailtemplate:Remove search and filters or create your first case email template.`,
    },
    contacts: {
      mainText: $localize`:@@looksLikeThereAreNoContacts:Looks like there are no contacts.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstContact:Remove search and filters or create your first contact.`,
    },
    emails: {
      mainText: $localize`:@@looksLikeThereAreNoEmails:Looks like there are no emails.`,
      subText: $localize`:@@TryAnotherSearch:Try another search or filtering criteria.`,
    },
    exports: {
      mainText: $localize`:@@looksLikeThereAreNoExportedFiles:Looks like there are no exported files.`,
      subText: $localize`:@@IfYouHaveStartedADataExportYouCanDownloadTheFilesHere:If you have started a data export, you can download the exported files here.`,
    },
    files: {
      mainText: $localize`:@@looksLikeThereAreNoFiles:Looks like there are no files.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrStartUploadingYourFirstFile:Remove search and filters or start uploading your first file.`,
    },
    filesSearch: {
      mainText: $localize`:@@couldNotFindFoldersMatchingThisSearchFullstop:Could not find folders matching this search.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrGoNavigateToAnotherFolder:Remove search and filters or navigate to another folder.`,
    },
    formDataFeed: {
      mainText: $localize`:@@looksLikeThereAreNoActivities:Looks like there are no activities here.`,
      subText: $localize`:@@addANoteToCreateActivities:Add a note to create activities.`,
    },
    forms: {
      mainText: $localize`:@@looksLikeThereAreNoForms:Looks like there are no forms.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrFillOutYourFirstForm:Remove search and filters or fill out your first form.`,
    },
    imports: {
      mainText: $localize`:@@looksLikeThereAreNoImports:Looks like there are no imports.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstImport:Remove search and filters or create your first import.`,
    },
    largeScreen: {
      mainText: $localize`:@@schedulingBoardIsNotAvailableOnSmallScreens:Scheduling Board is not available on small screens.`,
      subText: $localize`:@@pleaseOpenThisPageOnAScreenLargerThan960X540Pixels:Please open this page on a screen larger than 960 x 540 pixels.`,
    },
    maintenanceplans: {
      mainText: $localize`:@@looksLikeThereAreNoMaintenancePlans:Looks like there are no maintenance plans.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstMaintenancePlan:Remove search and filters or create your first maintenance plan.`,
    },
    messages: {
      mainText: $localize`:@@looksLikeThereAreNoMessages:Looks like there are no messages.`,
      subText: '',
    },
    notes: {
      mainText: $localize`:@@looksLikeThereAreNoNotes:Looks like there are no notes.`,
      subText: '',
    },
    checklist: {
      mainText: $localize`:@@looksLikeThereAreNoChecklistItems:Looks like there are no checklist items.`,
      subText: '',
    },
    notifications: {
      mainText: $localize`:@@looksLikeThereAreNoNotifications:Looks like there are no notifications.`,
      subText: $localize`:@@ifYouHaveActivatedNotificationsViaPushTheyWillAppearHereYouCanManageYourSettingsInYourProfile:If you have activated notifications via push, they will appear here. You can manage your settings in your profile.`,
    },
    organizations: {
      mainText: $localize`:@@looksLikeThereAreNoCustomers:Looks like there are no customers.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstCustomer:Remove search and filters or create your first customer.`,
    },
    parts: {
      mainText: $localize`:@@looksLikeThereAreNoParts:Looks like there are no parts.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstPart:Remove search and filters or create your first part.`,
    },
    qrCodes: {
      mainText: $localize`:@@looksLikeThereAreNoQRCodes:Looks like there are no QR codes.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstQRCode:Remove search and filters or create your first qrCode.`,
    },
    servicecases: {
      mainText: $localize`:@@looksLikeThereAreNoServiceCases:Looks like there are no cases.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstServiceCase:Remove search and filters or create your first case.`,
    },
    tenants: {
      mainText: $localize`:@@looksLikeThereAreNoTenants:Looks like there are no Tenants.`,
      subText: $localize`:@@removeSearchAndFiltersOrCreateYourFirstTenant:Remove search and filters or create your first Tenant.`,
    },
    users: {
      mainText: $localize`:@@looksLikeThereAreNoUsers:Looks like there are no users.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrCreateYourFirstUser:Remove search and filters or create your first users.`,
    },
    workorders: {
      mainText: $localize`:@@looksLikeThereAreNoWorkOrders:Looks like there are no work orders.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrSCreateYourFirstWorkOrder:Remove search and filters or create your first work order.`,
    },
    workorderStatus: {
      mainText: $localize`:@@looksLikeThereAreNoWorkOrderStatuses:Looks like there are no work order statuses.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrSCreateYourFirstWorkOrderStatus:Remove search and filters or create your first work order status.`,
    },
    workorderType: {
      mainText: $localize`:@@looksLikeThereAreNoWorkOrderTypes:Looks like there are no work order types.`,
      subText: $localize`:@@RemoveSearchAndFiltersOrSCreateYourFirstWorkOrderType:Remove search and filters or create your first work order type.`,
    },
    problem: {
      mainText: $localize`:@@thisPageIsCurrentlyHavingAProblemDot:This page is having a problem.`,
      subText:
        $localize`:@@yourDataIsnTCurrentlyAccessibleDueToAnIssueOnOurEndDot:Your data isn't currently accessible due to an issue on our end.` +
        ' ' +
        $localize`:@@restAssuredCommaWeReMonitoringTheseErrorsDot:Rest assured, we're monitoring these errors.` +
        ' ' +
        $localize`:@@ifAQuickRefreshDoesnTWorkCommaYouCanAlsoGoBackOrContactUsForAssistanceDot:If a quick refresh doesn't work, you can also go back or contact us for assistance.`,
    },
    customProperty: {
      mainText: $localize`:@@looksLikeThereAreNoCustomProperties:Looks like there are no custom properties.`,
      subText: '',
    },
    tasks: {
      mainText: $localize`:@@looksLikeThereAreNoTasks:Looks like there are no Tasks`,
      subText: $localize`:@@RemoveSearchAndFiltersOrSCreateYourTask:Remove search and filters or create your first Task.`,
    },
  };

  private readonly subscriptions = new Subscription();

  constructor(
    public readonly layout: LayoutService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected click(): void {
    this.c2aButton.emit(true);
  }
}
