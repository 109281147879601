/** Allowed field to perform sorting*/
export enum WorkOrderSortFieldEnum {
  COUNTER = 'counter',
  PRIORITY = 'priority',
  TYPE = 'type',
  TITLE = 'title',
  STATUS = 'status',
  DUE_DATE = 'dueDate',
  START_DATE = 'startDate',
  CREATED_AT = 'createdAt',
  LAST_MODIFIED = 'lastModified',
  END_DATE = 'endDate',
  ESTIMATED_DURATION = 'estimatedDuration',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  CITY = 'city',
  ZIP_POST_CODE = 'zipPostCode',
  STREET = 'street',
  ERP_REFERENCE = 'erpReference',
}
