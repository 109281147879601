import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { ServerConfigurationService } from '../services';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
  constructor(private serverConfigurationService: ServerConfigurationService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(API_URL_PLACEHOLDER)) {
      return next.handle(request);
    }

    return this.serverConfigurationService.ready.pipe(
      take(1),
      map(() => {
        const apiUrl = this.serverConfigurationService.apiUrl.getValue();
        if (request.url.startsWith(API_URL_PLACEHOLDER)) {
          return request.clone({
            url: request.url.replace(API_URL_PLACEHOLDER, apiUrl),
          });
        } else {
          return request;
        }
      }),
      mergeMap((req) => next.handle(req)),
    );
  }
}
