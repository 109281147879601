import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  LogService,
  SQLQueryParams,
  SQLSortDirection,
  SyncDataTypesEnum,
} from '@remberg/global/ui';
import { WorkOrderType } from '@remberg/work-orders/common/base';
import {
  WorkOrderTypeFindManyByIdsBody,
  WorkOrderTypeFindManyQuery,
  WorkOrderTypeFindManyResponse,
} from '@remberg/work-orders/common/main';
import { WorkOrderType2OfflineServiceInterface } from '@remberg/work-orders/ui/clients';
import { Observable, from, map } from 'rxjs';
import { RootGlobalState } from '../../../store';
import { BaseOfflineService } from '../../base.offline.service';
import { SqlDBService } from '../../sqlDB.service';

enum ColumnNamesEnum {
  ORDER_INDEX = 'orderIndex',
}

const params: SQLQueryParams<ColumnNamesEnum> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.WORKORDERTYPES2,
  columns: {
    [ColumnNamesEnum.ORDER_INDEX]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderType) => val?.order,
    },
  },
};

@Injectable()
export class WorkOrderType2OfflineService
  extends BaseOfflineService<WorkOrderType, never>
  implements WorkOrderType2OfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, params, logger, store);
  }

  public findMany(options?: WorkOrderTypeFindManyQuery): Observable<WorkOrderTypeFindManyResponse> {
    return from(
      this.getInstancesWithCount(
        options?.page,
        options?.limit,
        ColumnNamesEnum.ORDER_INDEX,
        SQLSortDirection.ASC,
      ),
    ).pipe(map((res) => ({ data: res.data, count: res.count as number })));
  }

  public findManyByIds(body: WorkOrderTypeFindManyByIdsBody): Observable<WorkOrderType[]> {
    if (!body.workOrderTypeIds?.length) {
      return from([]);
    }
    const filterString = `${params.tableName}._id IN (${body.workOrderTypeIds.map((id) => `'${id}'`).join(',')})`;
    return from(this.getInstances(undefined, undefined, undefined, undefined, filterString));
  }
}
