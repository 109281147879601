// see full api documentation here -> https://sql.js.org/

// library is currently not compatible with js bundler - https://github.com/sql-js/sql.js/issues/544
// this is why we only use import the type here
import type initSqlJs from 'sql.js/dist/sql-wasm.js';

interface DbTransaction {
  executeSql(
    sqlStatement: string,
    params?: any[],
    successCallback?: (transaction: DbTransaction, resultSet: SQLitePlugin.Results) => void,
    errorCallback?: (transaction: DbTransaction, error: any) => void,
  ): void;
}

export class SQLiteObjectMock {
  private dbInstance: initSqlJs.Database;

  constructor(dbInstance: initSqlJs.Database) {
    this.dbInstance = dbInstance;
  }

  public executeSql(
    statement: string,
    params: initSqlJs.BindParams,
  ): Promise<SQLitePlugin.Results> {
    return new Promise((resolve, reject) => {
      try {
        const stmt = this.dbInstance.prepare(statement, params);
        const rows: initSqlJs.ParamsObject[] = [];
        while (stmt.step()) {
          rows.push(stmt.getAsObject());
        }
        const payload: SQLitePlugin.Results = {
          rows: {
            item: (i: number) => rows[i],
            length: rows.length,
          },
          rowsAffected: this.dbInstance.getRowsModified() || 0,
        };
        stmt.free();
        resolve(payload);
      } catch (e) {
        reject(e);
      }
    });
  }

  public sqlBatch(sqlStatements: [string, initSqlJs.BindParams][]): Promise<SQLitePlugin.Results> {
    return new Promise((resolve, reject) => {
      try {
        const rows: initSqlJs.ParamsObject[] = [];
        let rowsAffected: number = 0;
        for (const [sqlStatement, params] of sqlStatements) {
          const stmt = this.dbInstance.prepare(sqlStatement, params);
          while (stmt.step()) {
            rows.push(stmt.getAsObject());
          }
          rowsAffected += this.dbInstance.getRowsModified() || 0;

          stmt.free();
        }
        resolve({
          rows: {
            item: (i: number) => rows[i],
            length: rows.length,
          },
          rowsAffected: rowsAffected,
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  public transaction(fn: (tx: DbTransaction) => void): Promise<any> {
    return new Promise((resolve, reject) => {
      const tx: DbTransaction = {
        executeSql: (sqlStatement, params, successCallback, errorCallback) => {
          this.executeSql(sqlStatement, params ?? [])
            .then((resultSet) => {
              if (successCallback) {
                successCallback(tx, resultSet);
              }
            })
            .catch((error) => {
              if (errorCallback) {
                errorCallback(tx, error);
              }
            });
        },
      };

      try {
        fn(tx);
      } catch (error) {
        reject(error);
      }
    });
  }
}
