import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { LanguageCodeEnum } from '@remberg/global/common/core';
import { getLanguageText } from '@remberg/global/ui';
import { GlobalSelectors, LanguageService, RootGlobalState } from '@remberg/ui-core/core';
import { map } from 'rxjs';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerComponent {
  protected currentLanguage: LanguageCodeEnum = LanguageCodeEnum.EN_US;
  protected readonly availableLanguages = Object.values(LanguageCodeEnum);
  protected getLanguageText = getLanguageText;
  protected readonly isXSmallView$ = this.store
    .select(GlobalSelectors.selectBreakpoints)
    .pipe(map((breakpoints) => breakpoints?.isXSmallView));

  constructor(
    private languageService: LanguageService,
    private store: Store<RootGlobalState>,
  ) {
    this.currentLanguage = this.languageService.getCurrentLanguage();
  }

  protected onSelectLanguage(language: LanguageCodeEnum): void {
    this.languageService.switchLanguage(language);
  }
}
