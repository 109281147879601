import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  catchError,
  combineLatest,
  EMPTY,
  filter,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

export interface InfiniteListContainerState<T> {
  initialized: boolean;
  dataSource: T[];
  totalCount: number;
  batchSize: number;
  // Minimum size of a singluar item rendered in the viewport
  itemSize: number;
  currentBatch: number;
  isLoading: boolean;
  disableFetching: boolean;
}

function getDefaultState<T>(): InfiniteListContainerState<T> {
  return {
    initialized: false,
    dataSource: [],
    totalCount: 0,
    batchSize: 0,
    itemSize: 0,
    currentBatch: 0,
    isLoading: false,
    disableFetching: false,
  };
}

export interface DataSourceUpdatePayload<T> {
  dataSource: T[];
  totalCount: number;
  currentBatch?: number;
  overwriteDataSource?: boolean;
}

export function getNextBatch<T>(batchSize: number, currentBatch: number, itemList?: T[]): T[] {
  const fetchedIdsIndex = batchSize * currentBatch;
  const endIndex = Math.min(fetchedIdsIndex + batchSize, itemList?.length ?? 0);
  let batchedIds: T[] = [];
  if (itemList?.length && !!endIndex) {
    batchedIds = itemList.slice(fetchedIdsIndex, endIndex);
  }
  return batchedIds;
}

@Injectable()
export abstract class InfiniteListContainerStore<T> extends ComponentStore<
  InfiniteListContainerState<T>
> {
  constructor() {
    super(getDefaultState());
  }

  public readonly isInitialized$ = this.select(({ initialized }) => initialized);
  public readonly dataSource$ = this.select(({ dataSource }) => dataSource);
  public readonly totalCount$ = this.select(({ totalCount }) => totalCount);
  public readonly batchSize$ = this.select(({ batchSize }) => batchSize);
  public readonly itemSize$ = this.select(({ itemSize }) => itemSize);
  public readonly currentBatch$ = this.select(({ currentBatch }) => currentBatch);
  public readonly isLoading$ = this.select(({ isLoading }) => isLoading);
  public readonly isFetchingDisabled$ = this.select(({ disableFetching }) => disableFetching);

  public readonly initialize$ = this.updater(
    (state, value: { batchSize: number; itemSize: number; disableFetching?: boolean }) => ({
      ...getDefaultState(),
      initialized: true,
      batchSize: value.batchSize,
      itemSize: value.itemSize,
      isLoading: true,
      disableFetching: value.disableFetching ?? state.disableFetching,
    }),
  );

  public readonly loadDataSource$ = this.effect(() =>
    combineLatest([this.isLoading$, this.isInitialized$]).pipe(
      withLatestFrom(this.batchSize$, this.currentBatch$, this.isFetchingDisabled$),
      filter(([[isLoading, isInitialized]]) => isLoading && isInitialized),
      switchMap(([, batchSize, currentBatch, isFetchingDisabled]) =>
        this.fetchFunction$(batchSize, currentBatch, isFetchingDisabled).pipe(
          tap((payload) => this.updateDataSource$(payload)),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  public readonly updateDataSource$ = this.updater((state, value: DataSourceUpdatePayload<T>) => ({
    ...state,
    dataSource: value.overwriteDataSource
      ? value.dataSource || []
      : [...state.dataSource, ...(value.dataSource || [])],
    isLoading: false,
    currentBatch: value.currentBatch ?? ++state.currentBatch,
    totalCount: value.totalCount,
  }));

  public readonly dispatchLoading$ = this.updater((state) => ({
    ...state,
    isLoading: true,
  }));

  public readonly disableFetching$ = this.updater((state, value: { disableFetching: boolean }) => ({
    ...state,
    disableFetching: value.disableFetching,
  }));

  public readonly resetDataSource$ = this.updater((state, value: { batchSize?: number }) => ({
    ...state,
    isLoading: false,
    dataSource: [],
    currentBatch: 0,
    batchSize: value.batchSize ?? state.batchSize,
  }));

  public abstract fetchFunction$(...args: unknown[]): Observable<DataSourceUpdatePayload<T>>;
}
