import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors, NavigationalActions, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class TenantNavigationGuardService {
  constructor(
    private logger: LogService,
    private router: Router,
    private store: Store<RootGlobalState>,
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    this.logger.debug()('TenantNavigationGuardService - canActivate');

    const isSubdomainRoutingActive = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsSubdomainRoutingActive),
    );

    const isLoggedIn = await firstValueFrom(this.store.select(GlobalSelectors.selectIsLoggedIn));
    if (isLoggedIn) {
      if (isSubdomainRoutingActive) {
        this.store.dispatch(NavigationalActions.goToAuthenticatedDefaultPage());
        return false;
      } else {
        const target = route.queryParams.target;
        if (target && target !== '/' && target !== '/login' && target !== '/welcome') {
          this.logger.debug()('Moving to targeted page from tenant selection page: ' + target);
          this.router.navigateByUrl(target);
          return false;
        }
      }
    }

    if (!isSubdomainRoutingActive) {
      const theme = await firstValueFrom(this.store.select(GlobalSelectors.selectTheme));
      if (theme) {
        const target = route.queryParams.target;
        if (target && target !== '/' && target !== '/login' && target !== '/welcome') {
          return this.router.navigate(['login'], { queryParams: { target } });
        }
        return this.router.navigate(['login']);
      }
      return true;
    } else {
      const subdomain = await firstValueFrom(
        this.store.select(GlobalSelectors.selectTenantSubdomain),
      );
      if (!subdomain) {
        return true;
      } else {
        this.store.dispatch(
          NavigationalActions.goToTenantLoginAtSubdomain({
            subdomain,
            targetUrl: state.url !== '/welcome' ? state.url : undefined,
          }),
        );
        return false;
      }
    }
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
