<mat-card appearance="outlined" class="login-card center" ngClass.lt-sm="landing-card">
  <mat-card-header class="flex-row justify-center">
    <mat-card-title i18n="@@logInToYourOrganization">Log in to your organization </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="flex-col">
      <form class="full-width">
        <mat-form-field
          appearance="outline"
          class="full-width"
          [class.margin-error-two-line]="tenantLoginFormControl.hasError('invalidSubdomain')"
          [class.margin-error-one-line]="
            tenantLoginFormControl.hasError('required') && tenantLoginFormControl.touched
          "
        >
          <span matTextPrefix>https://</span>
          <input
            autofocus
            matInput
            [formControl]="tenantLoginFormControl"
            type="text"
            placeholder="your-company"
            i18n-placeholder="@@yourDashOrganization"
            (keyup.enter)="onSubdomainSubmitted()"
            data-test-id="tenant-id"
          />
          <mat-error *ngIf="tenantLoginFormControl.hasError('invalidSubdomain')">
            <span
              i18n="@@thisIsNotAValidDomainDotClickHereIfYouNeedHelpFindingYourOrganizationDomain"
            >
              This is not a valid domain. Click
              <a class="link-error" [href]="domainHelpUrl" target="_blank">here</a> if you need help
              finding your organization domain.
            </span>
          </mat-error>
          <mat-error
            *ngIf="
              tenantLoginFormControl.hasError('required') ||
              tenantLoginFormControl.hasError('pattern')
            "
          >
            <span i18n="@@aValidDomainIsReuqired"> A valid domain is required. </span>
          </mat-error>
          <span matTextSuffix>.{{ mainDomainUrl }}</span>
        </mat-form-field>
      </form>

      <app-button-loader
        [buttonStyle]="'mat-flat-button'"
        (action)="onSubdomainSubmitted()"
        [disabled]="tenantLoginFormControl.invalid"
        [materialColor]="'primary'"
        [loading]="isProcessingDomain"
        [text]="transtations.next"
        data-test-id="tenant-login-button"
      >
      </app-button-loader>

      <mat-divider class="divider"></mat-divider>

      <div class="align-items-center">
        <p class="help-text" *ngIf="(isIonic$ | async) === false; else ionicHelpText">
          <span i18n="@@noAccountYetQuestion">No account yet?</span>
          <a
            [href]="demoUrl"
            target="_blank"
            class="text-link help-text help-text-link"
            i18n="@@bookAFreeDemoDot"
            >Book a free demo.</a
          >
        </p>
        <ng-template #ionicHelpText>
          <span i18n="@@selectYourLanguage">Select your language</span>
        </ng-template>
        <span class="fill-remaining-space"></span>
        <app-language-picker></app-language-picker>
      </div>

      <ng-container *ngIf="(isServerPickerDisplayed$ | async) && availableServers?.length">
        <mat-divider class="divider"></mat-divider>
        <div class="align-items-center">
          <p class="help-text">Select a server connection</p>
          <span class="fill-remaining-space"></span>
          <app-server-picker></app-server-picker>
        </div>
      </ng-container>

      <ng-container *ngIf="isIonicSimulationModeDisplayed">
        <mat-divider class="divider"></mat-divider>
        <div class="align-items-center">
          <p class="help-text" i18n="@@selectTheStateTheAppShouldRunIn">
            Select the state the App should run in
          </p>
          <span class="fill-remaining-space"></span>
          <app-simulation-picker></app-simulation-picker>
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
