import { AdvancedFilterOperatorEnum, AdvancedFilterTypeEnum } from '../enums';

/** @deprecated duplicated enum waiting for refactoring the config. Use the once from crm/common/main instead */
export enum DeprecatedContactsOrganizationTypeFilterEnum {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export type AdvancedFilterClientConfig<T extends string> = {
  [key in T]: AdvancedFilterFieldClientConfig<T>;
};

export interface AdvancedFilterFieldClientConfig<T extends string> {
  identifier: T;
  label: string;
  type: AdvancedFilterTypeEnum;
  /** Static options used for Enum Type or Static Select Custom Prop */
  options?: { identifier: string; label: string }[];
  /** Filters out some of the default operators. Ex. IS_EMPTY for required properties */
  hideOperators?: AdvancedFilterOperatorEnum[];
  organizationType?: `${DeprecatedContactsOrganizationTypeFilterEnum}`;
  /** Define if the filter should be available or not*/
  isDisabled?: boolean;

  /** @deprecated */
  icon?: string;
  /** @deprecated Use isDisabled instead. Filter should only be available if user belongs to an OEM*/
  oemOnly?: boolean;
  /** @deprecated Use isDisabled instead. Filter should only be available if user is in online mode*/
  onlineOnly?: boolean;
  /** @deprecated Use isDisabled instead. Filter should only be available if feature flag is enabled*/
  featureFlagOnly?: boolean;
  /** @deprecated Use isDisabled instead.Filter should only be available if permission is enabled*/
  permissionOnly?: boolean;
}
