import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';

import { OfflineService } from '@remberg/global/ui';
import { WorkOrderStatus } from '@remberg/work-orders/common/base';
import {
  WorkOrderStatusFindManyByIdsBody,
  WorkOrderStatusFindManyQuery,
  WorkOrderStatusFindManyResponse,
} from '@remberg/work-orders/common/main';
import { Observable } from 'rxjs';

export interface WorkOrderStatus2OfflineServiceInterface
  extends OfflineService<WorkOrderStatus, AdvancedFilterQuery<never>, AdvancedFilter<never>> {
  findMany(options?: WorkOrderStatusFindManyQuery): Observable<WorkOrderStatusFindManyResponse>;
  findManyByIds(body?: WorkOrderStatusFindManyByIdsBody): Observable<WorkOrderStatus[]>;
}

export const WORK_ORDER_STATUS_2_OFFLINE_SERVICE =
  new InjectionToken<WorkOrderStatus2OfflineServiceInterface>(
    'Token for injecting work order status offline service without circular dependencies',
  );
