import { ContactBasic } from '@remberg/crm/common/base';
import { Complete } from '@remberg/global/common/core';
import { Contact, ContactCompatibility, ContactRaw } from '../definitions';

export const getFirstEmailOfContact = (contact: ContactCompatibility): string | undefined =>
  contact?.crmData?.emails?.[0]?.email;

export function contactCompatibilityToContactBasic(contact: ContactCompatibility): ContactBasic;
export function contactCompatibilityToContactBasic(
  contact: ContactCompatibility | undefined,
): ContactBasic | undefined;
export function contactCompatibilityToContactBasic(
  contact: ContactCompatibility | undefined,
): ContactBasic | undefined {
  return (
    contact && {
      _id: contact._id,
      firstName: contact.crmData.firstName,
      lastName: contact.crmData.lastName,
      primaryEmail: contact.crmData.emails?.[0]?.email,
      profilePictureId: contact.crmData.profilePictureId,
      rembergUserId: contact.rembergUserId,
      organizationId: contact.organizationId,
      primaryPhoneNumber: contact.crmData.phoneNumbers?.[0]?.phoneNumber,
      position: contact.crmData.position,
    }
  );
}

export function contactToFullNameString(contact: ContactBasic | undefined): string {
  return [contact?.firstName, contact?.lastName].filter(Boolean).join(' ') || '';
}

export function contactRawToContactBasic(contact: ContactRaw): ContactBasic;
export function contactRawToContactBasic(contact: ContactRaw | undefined): ContactBasic | undefined;
export function contactRawToContactBasic(
  contact: ContactRaw | undefined,
): ContactBasic | undefined {
  return (
    contact && {
      _id: contact._id,
      firstName: contact.crmData.firstName,
      lastName: contact.crmData.lastName,
      primaryEmail: contact.crmData.emails?.[0]?.email,
      profilePictureId: contact.crmData.profilePictureId,
      rembergUserId: contact.rembergUserId,
      organizationId: contact.organizationId,
      primaryPhoneNumber: contact.crmData.phoneNumbers?.[0]?.phoneNumber,
      position: contact.crmData.position,
    }
  );
}

export function contactToContactBasic(contact: Contact): ContactBasic;
export function contactToContactBasic(contact: Contact | undefined): ContactBasic | undefined;
export function contactToContactBasic(contact: Contact | undefined): ContactBasic | undefined {
  return (
    contact && {
      _id: contact._id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      primaryEmail: contact.emails?.[0]?.email,
      profilePictureId: contact.profilePictureId,
      rembergUserId: contact.rembergUserId,
      organizationId: contact.organizationId,
      primaryPhoneNumber: contact.phoneNumbers?.[0]?.phoneNumber,
      position: contact.position,
    }
  );
}

export function contactRawToContactCompatibility(
  contact: ContactRaw,
): Complete<ContactCompatibility>;
export function contactRawToContactCompatibility(
  contact: ContactRaw | undefined,
): Complete<ContactCompatibility> | undefined;
export function contactRawToContactCompatibility(
  contact: ContactRaw | undefined,
): Complete<ContactCompatibility> | undefined {
  return (
    contact && {
      _id: contact._id,
      creatorId: contact.creatorId,
      tenantId: contact.tenantId,
      organizationId: contact.organizationId,
      rembergUserId: contact.rembergUserId,
      rembergUserStatus: contact.rembergUserStatus,
      crmData: contact.crmData,
      userGroupIds: undefined,
    }
  );
}
