<div
  *ngIf="(isServerPickerDisplayed$ | async) && availableServers?.length"
  class="server-picker-wrapper flex-row justify-end"
>
  <button class="server-button" mat-stroked-button [matMenuTriggerFor]="serverMenu">
    <mat-icon>cloud</mat-icon>
    <span class="button-text">{{ currentServerName }}</span>
  </button>

  <mat-menu #serverMenu="matMenu" class="" xPosition="before">
    <button
      mat-menu-item
      *ngFor="let serverOpt of availableServers"
      [disabled]="serverOpt === currentServerName && currentServerName !== 'preview'"
      [ngClass]="{ 'selected-server': serverOpt === currentServerName }"
      (click)="onSelectServer(serverOpt)"
    >
      {{ serverOpt }}
    </button>
  </mat-menu>
</div>
