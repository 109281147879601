import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DatetimeService } from '@remberg/ui-core/core';

@Component({
  selector: 'app-date-time-display',
  templateUrl: './date-time-display.component.html',
  styleUrls: ['./date-time-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeDisplayComponent {
  @Input() public value?: string;
  @Input() public isLabelHidden: boolean = false;
  @Input() public label: string = $localize`:@@dateTime:Datetime`;
  @Input() public required: boolean = false;
  @Input() public showInline: boolean = false;
  @Input() public showTimezone: boolean = false;
  @Input() public preserveTimezone: boolean = false;
  @Input() public showInForms: boolean = false;
  @Input() public isTooltipVisible: boolean = false;
  @Input() public isTooltipLabelVisible: boolean = false;
  @Input() public showElapsedTimeInTooltip: boolean = false;
  @Input() public hideTime: boolean = false;
  @Input() public hideDate: boolean = false;

  constructor(public dateTimeService: DatetimeService) {}
}
