<mat-card appearance="outlined" class="login-card center" ngClass.lt-sm="landing-card">
  <mat-card-header class="flex-row justify-center">
    <mat-card-title i18n="@@resetPassword">Reset Password</mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="!sent">
    <p *ngIf="!passwordExpired" i18n="@@forgotHelpText">
      Enter your email address and we will send you instructions on how to reset your password.
    </p>
    <p *ngIf="passwordExpired" i18n="@@expiredHelpText">
      Your password has expired, please request a new one.
    </p>
    <form ng-submit="login()">
      <div class="form-container">
        <mat-form-field appearance="outline">
          <mat-label i18n-placeholder="@@email">Email</mat-label>
          <input
            matInput
            type="text"
            *ngIf="emailFormControl"
            [formControl]="emailFormControl"
            name="email"
            data-test-id="reset-password-input"
          />
          <mat-error *ngIf="emailFormControl?.errors?.pattern" i18n="@@emailMustBeValid">
            Email must be valid
          </mat-error>
          <mat-error *ngIf="emailFormControl?.errors?.required" i18n="@@emailIsRequired">
            Email is required
          </mat-error>
        </mat-form-field>
        <button
          mat-flat-button
          class="landing-button password-form-field"
          [disabled]="
            sent || emailFormControl?.value === '' || emailFormControl?.status === 'INVALID'
          "
          (click)="forgot()"
          type="submit"
          color="primary"
          i18n="@@resetPassword"
          data-test-id="reset-password-button"
        >
          Reset Password
        </button>
      </div>
    </form>
  </mat-card-content>
  <mat-card-content *ngIf="sent">
    <app-loader></app-loader>
  </mat-card-content>

  <mat-divider class="divider"></mat-divider>

  <mat-card-actions>
    <span class="align-items-center" [class.disabled]="sent">
      <mat-icon class="x-small-icon">arrow_back</mat-icon>
      <a [routerLink]="[loginUrl$ | async]" class="text-link" i18n="@@backToLogin">
        Back to Login
      </a>
    </span>
  </mat-card-actions>
</mat-card>
