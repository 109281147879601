import { InjectionToken } from '@angular/core';
import { AssetType, ProductType } from '@remberg/assets/common/base';
import { ApiResponse, OfflinePopulateType } from '@remberg/global/ui';
import { AssetTypesFindManyOfflineQueryInterface } from './asset-types.definitions';

export interface AssetTypesOfflineServiceInterface {
  tryGetInstance(params: {
    id: string | ProductType | AssetType;
    populate?: OfflinePopulateType;
  }): Promise<ProductType | undefined>;

  getInstance(options: {
    id: string | ProductType | AssetType;
    populate?: OfflinePopulateType;
  }): Promise<ProductType>;

  getAssetTypesWithCount(
    queryOptions: AssetTypesFindManyOfflineQueryInterface,
  ): Promise<ApiResponse<ProductType[]>>;

  getManyByIds(ids: string[]): Promise<ProductType[]>;
}

export const ASSET_TYPES_OFFLINE_SERVICE = new InjectionToken<AssetTypesOfflineServiceInterface>(
  'Token for injecting asset types offline service without circular dependencies',
);
