import { MultiLanguageText } from '@remberg/global/common/core';
import { ConfigWithDynamicActions, ConfigWithPrefilling } from '../../../automation';
import { SelectOptionDictionary } from '../../../common';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StaticMultiSelectField
  extends Field<FormFieldTypesEnum.STATIC_MULTI_SELECT, StaticMultiSelectFieldConfig> {}

export interface StaticMultiSelectFieldConfig
  extends ConfigWithPrefilling<StaticMultiSelectPrefillTargetsEnum>,
    ConfigWithDynamicActions {
  disabled?: boolean;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  minAmountOfSelections?: number;
  maxAmountOfSelections?: number;
  label: {
    text: MultiLanguageText;
    uiHide?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value: {
    options: SelectOptionDictionary;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
    pdfAddLineBreaks?: boolean;
  };
}

export enum StaticMultiSelectPrefillTargetsEnum {
  VALUE = 'value',
}

export interface StaticMultiSelectFieldData {
  selectedValues: string[];
}
