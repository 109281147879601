import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AssetsService } from '@remberg/assets/ui/clients';
import { SEARCH_DEBOUNCE_TIME } from '@remberg/global/ui';
import { finalize, map, of, switchMap, timer, withLatestFrom } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../store';

// custom validator to check that two fields match
export function duplicateSNValidator(
  assetsService: AssetsService,
  store: Store<RootGlobalState>,
  oldValue?: string,
  productTypeId?: string,
  cdr?: ChangeDetectorRef,
): AsyncValidatorFn {
  return (control: AbstractControl) => {
    if ((oldValue && control.value.trim() === oldValue.trim()) || !control.value) {
      // do not check with the backend in those cases
      return of(null);
    }

    // Wait for the specified debounce time before making the request to the backend.
    return timer(SEARCH_DEBOUNCE_TIME).pipe(
      withLatestFrom(store.select(GlobalSelectors.selectIsAssetsTemporaryEnabled)),
      switchMap(([, isAssetsTemporaryEnabled]) =>
        assetsService
          .checkSerialNumberUniqueness(
            isAssetsTemporaryEnabled,
            control.value.trim(),
            productTypeId,
          )
          .pipe(
            map((exists) =>
              !exists ? null : { duplicateSNValidator: 'This serialNumber already exists!' },
            ),
            finalize(() => cdr?.markForCheck()),
          ),
      ),
    );
  };
}
