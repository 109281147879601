import { Pipe, PipeTransform } from '@angular/core';
import { RembergDateTime, TimeZoneEnum } from '@remberg/global/common/core';
import { TableColumnDisplayOptionEnum, environment } from '@remberg/global/ui';
import { DatetimeService, isISODateString, isTimezoneString } from '@remberg/ui-core/core';

export type TimezoneDisplayMode =
  | 'showTimezoneOnlyIfDifferent'
  | 'alwaysShowTimezone'
  | 'alwaysHideTimezone';

@Pipe({
  name: 'rembergDateTimeDisplay',
})
export class RembergDateTimeDisplayPipe implements PipeTransform {
  constructor(private readonly datetimeService: DatetimeService) {}

  public transform(
    value: RembergDateTime | null | undefined,
    options?: {
      timezoneDisplayMode?: TimezoneDisplayMode;
      convertToUserTimezone?: boolean;
      displayOption?: TableColumnDisplayOptionEnum;
      overrideFormat?: string;
    },
  ): string {
    const { timezoneDisplayMode, convertToUserTimezone, displayOption, overrideFormat } = {
      timezoneDisplayMode: 'showTimezoneOnlyIfDifferent',
      convertToUserTimezone: false,
      displayOption: TableColumnDisplayOptionEnum.EXACT,
      ...options,
    };

    if (!value) {
      return '';
    }

    const [isoDateString, tz] = value.split(/_(.*)/s);
    if (!isoDateString || !isISODateString(isoDateString)) {
      return value;
    }

    if (displayOption === TableColumnDisplayOptionEnum.RELATIVE) {
      return this.datetimeService.getElapsedTime(isoDateString);
    }

    const userTimezone: TimeZoneEnum = this.datetimeService.getUserTimezone();
    let timezone = TimeZoneEnum['Etc/UTC'];
    if (tz) {
      if (!isTimezoneString(tz)) {
        if (!environment.production) {
          throw new Error(`Invalid timezone string: ${tz}`);
        }
        return value;
      }

      timezone = tz;
    }

    if (convertToUserTimezone) {
      timezone = userTimezone;
    }

    let formatString: string = 'P, p';

    if (overrideFormat) {
      formatString = overrideFormat;
    }

    if (
      timezoneDisplayMode !== 'alwaysHideTimezone' &&
      (timezone !== userTimezone || timezoneDisplayMode === 'alwaysShowTimezone')
    ) {
      formatString += ' (z)';
    }

    return this.datetimeService.formatDateInTimezone(isoDateString, timezone, formatString);
  }
}
