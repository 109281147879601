import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { ContactBasic } from '@remberg/crm/common/base';
import {
  ContactCompatibility,
  ContactLoadingTypeEnum,
  ContactRaw,
  ContactsFilterEnum,
  ContactsFindManyBasicResponse,
  ContactsFindManyCompatibilityQuery,
  ContactsFindManyQuery,
  contactRawToContactBasic,
} from '@remberg/crm/common/main';
import {
  ContactsFindManyOfflineParams,
  ContactsOfflineServiceInterface,
} from '@remberg/crm/ui/clients';
import { GetManyPayload } from '@remberg/global/common/core';
import {
  LogService,
  SQLQueryParams,
  SyncDataTypesEnum,
  UnreachableCaseError,
  stringToSQLSortDirection,
} from '@remberg/global/ui';
import { RootGlobalState } from '../../store';
import { AuthenticationService } from '../authentication.service';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

enum ColumnNamesEnum {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ORGANIZATION_ID = 'organizationId',
  FULL_NAME = 'fullName',
}

const params: SQLQueryParams<ColumnNamesEnum> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.CONTACTS,
  columns: {
    [ColumnNamesEnum.FIRST_NAME]: {
      type: 'TEXT',
      valueFunction: (val: ContactRaw) => val?.crmData.firstName?.trim(),
      sortNoCase: true,
    },
    [ColumnNamesEnum.LAST_NAME]: {
      type: 'TEXT',
      valueFunction: (val: ContactRaw) => val?.crmData.lastName?.trim(),
      sortNoCase: true,
    },
    [ColumnNamesEnum.EMAIL]: {
      type: 'TEXT',
      valueFunction: (val: ContactRaw) => val?.crmData.emails[0]?.email.trim(),
      sortNoCase: true,
    },
    [ColumnNamesEnum.ORGANIZATION_ID]: {
      type: 'TEXT',
      valueFunction: (val: ContactRaw) => val?.organizationId,
    },
    [ColumnNamesEnum.FULL_NAME]: {
      type: 'TEXT',
      valueFunction: (val: ContactRaw) =>
        (val?.crmData.firstName?.trim() + ' ' + val?.crmData.lastName?.trim())?.trim(),
      sortNoCase: true,
    },
  },
};

@Injectable()
export class ContactOfflineService
  extends BaseOfflineService<ContactRaw, ContactsFilterEnum>
  implements ContactsOfflineServiceInterface
{
  constructor(
    dbService: SqlDBService,
    logger: LogService,
    store: Store<RootGlobalState>,
    private authenticationService: AuthenticationService,
  ) {
    super(dbService, params, logger, store);
  }

  public async getManyWithCountCompatibility(
    options: ContactsFindManyCompatibilityQuery,
  ): Promise<{ data: ContactCompatibility[]; count: number }> {
    const params: ContactsFindManyOfflineParams = {
      pageIndex: options.pageIndex,
      pageSize: options.pageSize,
      sortField: options.sortField,
      searchQuery: options.searchQuery,
      sortDirection: options.sortDirection,
      organizationId: options.organizationId,
      loadingType: options.loadingType,
    };

    const { contacts, count } = await this.getManyWithCount(params);

    return { data: contacts, count };
  }

  public async getManyWithCountBasic(
    options: ContactsFindManyQuery,
  ): Promise<ContactsFindManyBasicResponse> {
    const params: ContactsFindManyOfflineParams = {
      pageIndex: options.page,
      pageSize: options.limit,
      sortField: options.sortField,
      searchQuery: options.search,
      sortDirection: options.sortDirection,

      organizationId: getFilterValue(options, ContactsFilterEnum.ORGANIZATION),
      loadingType: getFilterValue(options, ContactsFilterEnum.ORGANIZATION_TYPE) as
        | ContactLoadingTypeEnum
        | undefined,
      emailWhitelist: (getFilterValue(options, ContactsFilterEnum.EMAIL_WHITELIST) ?? '')
        .split('|')
        .filter(Boolean),
    };

    const { contacts, count } = await this.getManyWithCount(params);

    return {
      contacts: contacts.map((contactRaw) => contactRawToContactBasic(contactRaw)),
      count,
    };
  }

  public async getManyWithCount(
    options: ContactsFindManyOfflineParams,
  ): Promise<{ contacts: ContactRaw[]; count: number }> {
    const filterStrings = [];

    const organizationId = options.organizationId;

    if (organizationId) {
      filterStrings.push(
        `${params.tableName}.${ColumnNamesEnum.ORGANIZATION_ID} = '${organizationId}'`,
      );
    } else if (options.loadingType) {
      switch (options.loadingType) {
        case ContactLoadingTypeEnum.INTERNAL: {
          const { contact } = this.authenticationService.getSessionInfo();
          filterStrings.push(
            `${params.tableName}.${ColumnNamesEnum.ORGANIZATION_ID} = '${contact.organizationId}'`,
          );
          break;
        }
        case ContactLoadingTypeEnum.EXTERNAL: {
          const { tenant } = this.authenticationService.getSessionInfo();
          filterStrings.push(
            `${params.tableName}.${ColumnNamesEnum.ORGANIZATION_ID} != '${tenant.ownerOrganizationId}'`,
          );
          break;
        }
        case ContactLoadingTypeEnum.ALL:
          break;
        default:
          throw new UnreachableCaseError(options.loadingType);
      }
    }

    if (options.searchQuery) {
      filterStrings.push(
        `(${params.tableName}.${ColumnNamesEnum.FULL_NAME} LIKE '%${options.searchQuery}%'` +
          ` OR ${params.tableName}.${ColumnNamesEnum.EMAIL} LIKE '%${options.searchQuery}%')`,
      );
    }

    if (options.emailWhitelist?.length) {
      filterStrings.push(getDomainsSQLFilter(options.emailWhitelist));
    }

    const sqlSortDirection = stringToSQLSortDirection(options.sortDirection);

    const result = await this.getInstancesWithCount(
      options.pageSize,
      options.pageIndex,
      options.sortField,
      sqlSortDirection,
      filterStrings.join(' AND '),
    );

    return { contacts: result.data, count: result.count as number };
  }

  public async getManyBasicByIds(ids: string[]): Promise<ContactBasic[]> {
    if (!ids.length) {
      return [];
    }
    const filterString = `${params.tableName}._id IN (${ids.map((id) => `'${id}'`).join(',')})`;
    const contacts = await this.getInstances(
      undefined,
      undefined,
      undefined,
      undefined,
      filterString,
    );

    return contacts
      .map(contactRawToContactBasic)
      .filter((contact): contact is ContactBasic => contact !== undefined);
  }
}

function getFilterValue<F extends string, S extends string>(
  options: GetManyPayload<AdvancedFilterQuery<F>, AdvancedFilter<F>, S>,
  identifier: F,
): string | undefined {
  return (
    options.filterObject?.filters?.find((f) => f.identifier === identifier)?.value ??
    options.staticFilters?.find((f) => f.identifier === identifier)?.value
  );
}

function getDomainsSQLFilter(domains: string[]): string {
  const domainsListExp = domains
    .map((domain) => `${params.tableName}.${ColumnNamesEnum.EMAIL} LIKE '%@${domain}'`)
    .join(' OR ');

  return `(${domainsListExp})`;
}
