export enum OutboundEmailErrorTypeEnum {
  // Checked to prevent infinite loop in tickets creation
  SUPPORT_EMAIL_IN_FROM = 'supportEmailInFrom',
  DUPLICATE_MESSAGE = 'duplicateMessage',
  TENANT_NOT_FOUND = 'tenantNotFound',
  TOO_LARGE_PAYLOAD = 'tooLargePayload',
  UNKNOWN_MAIL_SENDING_ERROR = 'unknownMailSendingError',
  // We want to ensure that email `from` field is in valid senders
  NOT_ALLOWED_TO_SEND_FROM_ADDRESS = 'notAllowedToSendFromAddress',
  COULD_NOT_FIND_ACTIVITY_BY_MESSAGE_ID = 'couldNotFindActivityByMessageId',
  // This prevents creation of tickets based on system notification messages from AWS
  MAIL_FROM_AWS_MAILER_DAEMON_IGNORING = 'mailFromAWSMailerDaemonIgnoring',
}
