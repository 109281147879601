/* eslint-disable no-restricted-syntax */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { AssetBasic } from '@remberg/assets/common/base';
import {
  AssetLegacyPublic,
  AssetsCreateOneBody,
  AssetsLegacyFilterFieldEnum,
  Product,
  ProductActivity,
  ProductCompatibility,
  ProductCustomerPatchPayload,
  ProductInfoPatchPayload,
  ProductLocationPatchPayload,
  ProductPrivateNoteActivity,
  ProductPublicNoteActivity,
  mapProductCompatibilityToProduct,
  productToAssetBasic,
} from '@remberg/assets/common/main';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
  OfflinePopulateType,
} from '@remberg/global/ui';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AssetsLegacyAdvancedFilterQuery,
  AssetsLegacyAdvancedStaticFilter,
  AssetsLegacyOfflineAdvancedFilterQuery,
} from './assets-legacy.definitions';
import {
  ASSETS_OFFLINE_SERVICE,
  AssetsOfflineServiceInterface,
} from './assets.offline.service.interface';

@Injectable({
  providedIn: 'root',
})
export class AssetsLegacyService {
  public readonly productsUrl = `${API_URL_PLACEHOLDER}/products`;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(ASSETS_OFFLINE_SERVICE)
    private readonly assetOfflineService: AssetsOfflineServiceInterface,
  ) {}

  public serialNumberExists(serialNumber: string, productTypeId?: string): Observable<boolean> {
    let params = new HttpParams().set('serialNumber', serialNumber);
    if (productTypeId) {
      params = params.set('productType', productTypeId);
    }
    return this.http
      .get<{ data: boolean }>(this.productsUrl + '/checkserialnumber', { params: params })
      .pipe(map((res) => res.data));
  }

  public getProductsByIds(ids: string[]): Observable<Product[]> {
    const populateProductType = { productType: true };
    if (this.connectivityService.getConnected()) {
      return this.getProductsBase(
        populateProductType as unknown as Product,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        ids,
      ).pipe(map((res) => res.data));
    } else {
      return from(
        this.assetOfflineService.getManyByIds(ids, populateProductType as OfflinePopulateType),
      );
    }
  }

  public getProductsUsingAdvancedFilter(
    populate?: boolean | Product,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterQuery?: AssetsLegacyAdvancedFilterQuery,
    staticFilters?: AssetsLegacyAdvancedStaticFilter[],
    ignoreUserGroups?: boolean,
  ): Observable<ApiResponse<Product[]>> {
    if (this.connectivityService.getConnected()) {
      this.logger.silly()('Online asset instances request...');
      return this.getProductsBase(
        populate,
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        searchValue,
        undefined,
        filterQuery,
        staticFilters,
        undefined,
        ignoreUserGroups,
      ).pipe(map((res) => new ApiResponse<Product[]>(res.data, res.count)));
    } else {
      this.logger.debug()('Offline asset instances fallback...');
      return from(
        this.assetOfflineService.getAssetsWithCount({
          limit: pageSize,
          offset: pageIndex,
          sortColumn: sortField,
          sortDirection,
          searchValue,
          filterQuery: filterQuery as AssetsLegacyOfflineAdvancedFilterQuery,
          populate: populate as OfflinePopulateType,
        }),
      );
    }
  }

  public getAssetsBasicUsingAdvancedFilter(
    populate?: boolean | Product,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterQuery?: AssetsLegacyAdvancedFilterQuery,
    staticFilters?: AssetsLegacyAdvancedStaticFilter[],
    ignoreUserGroups?: boolean,
  ): Observable<ApiResponse<AssetBasic[]>> {
    return this.getProductsUsingAdvancedFilter(
      populate,
      pageSize,
      pageIndex,
      sortDirection,
      sortField,
      searchValue,
      filterQuery,
      staticFilters,
      ignoreUserGroups,
    ).pipe(
      map((response) => ({
        ...response,
        data: response.data.map(productToAssetBasic),
      })),
    );
  }

  // standard calls
  public getProduct(
    product: string,
    populate?: boolean,
    populateParent?: boolean,
  ): Observable<Product> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online asset instance request...');
      const url = `${this.productsUrl}/${product}`;
      let params = new HttpParams();
      if (populate) {
        params = params.set('populate', populate.toString());
      }
      if (populateParent) {
        params = params.set('populateParent', populateParent.toString());
      }
      return this.http
        .get<ApiResponse<ProductCompatibility>>(url, { params: params })
        .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
    } else {
      this.logger.debug()('Offline asset instance fallback...');

      return from(this.assetOfflineService.getInstance({ id: product, populate }));
    }
  }

  public getProductPublic(product: string): Observable<AssetLegacyPublic> {
    const url = `${this.productsUrl}/${product}/public`;
    return this.http.get<ApiResponse<AssetLegacyPublic>>(url).pipe(map((res) => res.data));
  }

  // TODO: make this also support a populate response
  public addProduct(body: AssetsCreateOneBody): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    httpOptions.params = httpOptions.params.set('populate', 'true');
    if (body.isAtCustomerLocation) {
      httpOptions.params = httpOptions.params.set('useCustomerLocation', 'true');
    }

    const product: Partial<Product> = {
      tenantId: body.tenantId,
      productType: body.assetTypeId,
      serialNumber: body.serialNumber,
      manufacturerOrganization: body.manufacturerOrganizationId,
      customerOrganization: body.relatedOrganizationIds?.[0],
      manufacturerContact: body.tenantOwnerContactId,
      customerContact: body.relatedContactIds?.[0],
      location: body.location,
      userGroups: body.userGroupIds,
      assetHierarchy: body.parentId,
    };

    return this.http
      .post<ApiResponse<ProductCompatibility>>(this.productsUrl, product, httpOptions)
      .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
  }

  // TODO: make this also support a populate response
  public updateProduct(product: Product): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.productsUrl}/${product._id}`;
    return this.http
      .patch<ApiResponse<ProductCompatibility>>(url, product, httpOptions)
      .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
  }

  public patchProductCustomer(
    payload: ProductCustomerPatchPayload,
    populate?: boolean,
    useCustomerLocation?: boolean,
  ): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    if (useCustomerLocation !== undefined) {
      httpOptions.params = httpOptions.params.set(
        'useCustomerLocation',
        useCustomerLocation.toString(),
      );
    }
    const url = `${this.productsUrl}/${payload._id}`;
    return this.http
      .patch<ApiResponse<ProductCompatibility>>(url, payload, httpOptions)
      .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
  }

  public patchProductManufacturer(
    payload: ProductInfoPatchPayload,
    populate?: boolean,
    populateParent?: boolean,
  ): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    if (populateParent !== undefined) {
      httpOptions.params = httpOptions.params.set('populateParent', populateParent.toString());
    }
    const url = `${this.productsUrl}/${payload._id}`;
    return this.http
      .patch<ApiResponse<ProductCompatibility>>(url, payload, httpOptions)
      .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
  }

  public patchProductLocation(
    payload: ProductLocationPatchPayload,
    populate?: boolean,
    populateParent?: boolean,
  ): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    if (populateParent !== undefined) {
      httpOptions.params = httpOptions.params.set('populateParent', populateParent.toString());
    }
    const url = `${this.productsUrl}/${payload._id}`;
    return this.http
      .patch<ApiResponse<ProductCompatibility>>(url, payload, httpOptions)
      .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
  }

  public deleteProduct(product: Product | string): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const id = typeof product === 'string' ? product : product._id;
    const url = `${this.productsUrl}/${id}`;

    return this.http
      .delete<ApiResponse<ProductCompatibility>>(url, httpOptions)
      .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
  }

  public unassignProduct(product: Product | string): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const id = typeof product === 'string' ? product : product._id;
    const url = `${this.productsUrl}/${id}/unassign`;

    return this.http
      .get<ApiResponse<ProductCompatibility>>(url, httpOptions)
      .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
  }

  public appendActivity(
    payload: ProductPublicNoteActivity | ProductPrivateNoteActivity,
    populate?: boolean,
  ): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    const url = `${this.productsUrl}/${payload._id}/activity`;
    return this.http
      .patch<ApiResponse<ProductCompatibility>>(url, payload, httpOptions)
      .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
  }

  public deleteActivity(
    product: Product | string,
    payload: ProductActivity,
    populate: boolean = true,
  ): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    const id = typeof product === 'string' ? product : product._id;
    const url = `${this.productsUrl}/${id}/activity/${payload._id}`;

    return this.http
      .delete<ApiResponse<ProductCompatibility>>(url, httpOptions)
      .pipe(map((res) => mapProductCompatibilityToProduct(res.data)));
  }

  public getLocationFilter(
    filter?: AssetsLegacyFilterFieldEnum,
    pageSize?: number,
    pageIndex?: number,
    search?: string,
  ): Observable<ApiResponse<string[]>> {
    let params = new HttpParams();
    if (filter) {
      params = params.set('filter', filter);
    }
    if (search) {
      params = params.set('search', search);
    }
    if (pageSize) {
      params = params.set('pageSize', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('pageIndex', String(pageIndex));
    }
    return this.http
      .get<any>(this.productsUrl + '/locationfilter', { params: params })
      .pipe(map((res) => new ApiResponse<string[]>(res.data, res.count)));
  }

  private getProductsBase(
    populate?: boolean | Product,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
    filterQuery: AssetsLegacyAdvancedFilterQuery | undefined = undefined,
    staticFilters: AssetsLegacyAdvancedStaticFilter[] | undefined = undefined,
    groupFilter: any = null,
    ignoreUserGroups: boolean = false,
    hasCustomer: boolean = false,
    ids?: string[],
  ): Observable<ApiResponse<Product[]>> {
    let params = new HttpParams();
    let body;
    if (populate !== undefined && populate && typeof populate === 'boolean') {
      params = params.set('populate', populate.toString());
    }
    if (populate && (populate as Product).productType) {
      params = params.set('populateProductType', 'true');
    }
    if (populate && (populate as Product).customerOrganization) {
      params = params.set('populateCustomerOrganization', 'true');
    }
    if (populate && (populate as Product).manufacturerOrganization) {
      params = params.set('populateManufacturerOrganization', 'true');
    }
    if (populate && (populate as Product).customerContact) {
      params = params.set('populateCustomerContact', 'true');
    }
    if (populate && (populate as Product).manufacturerContact) {
      params = params.set('populateManufacturerContact', 'true');
    }
    if (populate && (populate as Product).qrcode) {
      params = params.set('populateQrcode', 'true');
    }
    if (populate && (populate as Product).createContext) {
      params = params.set('populateCreateContext', 'true');
    }
    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', encodeURIComponent(String(searchValue)));
    }
    if (filterValue && filterValue.length > 0) {
      const parsedFilter = filterValue.map((item: any) => ({
        identifier: item.identifier,
        value: item.value,
      }));
      params = params.set('filter', JSON.stringify(parsedFilter));
    }
    if (filterQuery) {
      params = params.set('filterQuery', JSON.stringify(filterQuery));
    }
    if (staticFilters) {
      params = params.set('staticFilters', JSON.stringify(staticFilters));
    }
    if (groupFilter) {
      params = params.set('groupId', groupFilter);
    }
    if (ignoreUserGroups) {
      params = params.set('ignoreUserGroups', 'true');
    }
    if (hasCustomer) {
      params = params.set('hasCustomer', 'true');
    }
    if (ids !== undefined) {
      body = { instance_ids: ids };
    }

    return body
      ? this.http
          .put<ApiResponse<ProductCompatibility[]>>(this.productsUrl, body, { params })
          .pipe(
            map((res) => new ApiResponse(mapProductCompatibilityToProduct(res.data), res.count)),
          )
      : this.http
          .get<ApiResponse<ProductCompatibility[]>>(this.productsUrl, { params })
          .pipe(
            map((res) => new ApiResponse(mapProductCompatibilityToProduct(res.data), res.count)),
          );
  }

  public generateQuanosLink(productId: string): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.productsUrl}/${productId}/quanos`;

    return this.http.get<any>(url, httpOptions).pipe(map((res) => res.data.link));
  }
}
