import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SEARCH_DEBOUNCE_TIME } from '@remberg/global/ui';
import { Subject, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() public inputPlaceholder?: string = $localize`:@@searchDotDotDot:Search ...`;
  @Input() public shouldDebounce = true;
  @Input() public isIconHidden = false;

  @Output() public searchQueryChange = new EventEmitter<string>();
  @Output() public inputFocused = new EventEmitter<void>();

  @ViewChild('searchInputElement') protected searchInputElement?: ElementRef;

  protected searchString?: string | null = undefined;

  private searchQuerySubject: Subject<string | null> = new Subject<string | null>();
  private subscription: Subscription = new Subscription();

  @Input() public set inputValue(value: string | undefined | null) {
    if (this.searchInputElement?.nativeElement !== document.activeElement) {
      this.searchString = value;
    }
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.searchQuerySubject
        .pipe(
          distinctUntilChanged(),
          // null search values from clear button are bypassing the debounce time
          debounce((value) =>
            value !== null && this.shouldDebounce ? timer(SEARCH_DEBOUNCE_TIME) : timer(0),
          ),
        )
        .subscribe((searchQuery) => this.searchQueryChange.emit(searchQuery ?? '')),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onValueChange(event: Event): void {
    this.searchQuerySubject.next((event.target as HTMLInputElement).value.trim());
  }

  public clearValue(): void {
    this.searchString = '';
    this.searchQuerySubject.next(null);
  }
}
