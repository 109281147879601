import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;
  private readonly _readyState = new BehaviorSubject<boolean>(false);

  constructor(private readonly storage: Storage) {}

  public async initialize(): Promise<void> {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
    this._readyState.next(true);
  }

  public async set(key: string, value: unknown): Promise<void> {
    await this.isReady();
    return this._storage?.set(key, value);
  }

  public async get(key: string): Promise<any> {
    await this.isReady();
    return this._storage?.get(key);
  }

  public async remove(key: string): Promise<void> {
    await this.isReady();
    return this._storage?.remove(key);
  }

  /**
   * Checks if the storage has been initialized by either returning directly true or waiting for the initialization to finish
   * It will never return false. It will wait util is true. Use this function in all services that are initialized upon app initialization.
   */
  public async isReady(): Promise<void> {
    if (this._readyState.getValue()) {
      return;
    } else {
      return new Promise((resolve) => {
        this._readyState.subscribe((value) => {
          if (value) {
            resolve();
          }
        });
      });
    }
  }

  public async reset(): Promise<void> {
    await this.isReady();
    await this._storage?.clear();
  }
}
