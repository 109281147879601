import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductType } from '@remberg/assets/common/base';
import {
  AssetTypesCreateOneBody,
  AssetTypesSortFieldEnum,
  AssetTypesUpdateOneBody,
} from '@remberg/assets/common/main';
import { FileSourceTypeEnum } from '@remberg/files/common/main';
import { SortDirectionEnum } from '@remberg/global/common/core';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
} from '@remberg/global/ui';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ASSET_TYPES_LEGACY_OFFLINE_SERVICE,
  AssetTypesLegacyOfflineServiceInterface,
} from './asset-types-legacy.offline.service.interface';

@Injectable({
  providedIn: 'root',
})
export class AssetTypesLegacyService {
  public readonly productsUrl = `${API_URL_PLACEHOLDER}/products`;
  private readonly productsTypesUrl = `${this.productsUrl}/types`;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(ASSET_TYPES_LEGACY_OFFLINE_SERVICE)
    private readonly assetTypeOfflineService: AssetTypesLegacyOfflineServiceInterface,
  ) {}

  public getProductTypesWithCount({
    pageSize,
    pageIndex,
    sortDirection,
    sortField,
    searchValue,
    filterValue,
    ids,
  }: {
    pageSize?: number;
    pageIndex?: number;
    sortDirection?: SortDirectionEnum | '';
    sortField?: AssetTypesSortFieldEnum;
    searchValue?: string;
    filterValue?: any;
    ids?: string[];
  }): Observable<ApiResponse<ProductType[]>> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online asset type instances request...');
      return this.getProductTypesBase(
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        searchValue,
        filterValue,
        ids,
      ).pipe(map((res) => new ApiResponse<ProductType[]>(res.data, res.count)));
    } else {
      this.logger.debug()('Offline asset type fallback...');
      return from(
        this.assetTypeOfflineService.getAssetTypesWithCount({
          limit: pageSize,
          offset: pageIndex,
          sortColumn: sortField,
          sortDirection,
          searchValue,
        }),
      );
    }
  }

  public getProductType(productTypeId: string): Observable<ProductType> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online assetType instance request...');
      const url = `${this.productsTypesUrl}/${productTypeId}`;
      return this.http.get<ApiResponse<ProductType>>(url).pipe(map(({ data }) => data));
    } else {
      this.logger.debug()('Offline assetType instance fallback...');
      return from(this.assetTypeOfflineService.getInstance(productTypeId));
    }
  }

  public updateProductType(
    productTypeId: string,
    body: AssetTypesUpdateOneBody,
    image?: File,
  ): Observable<ProductType> {
    const url = `${this.productsTypesUrl}/${productTypeId}`;
    const actualBody = {
      label: body.label,
      manufacturer: body.manufacturerName,
    };
    if (image) {
      const formData = new FormData();
      formData.append(
        'data',
        JSON.stringify({ ...actualBody, sourceType: FileSourceTypeEnum.ASSET_TYPE_IMAGE }),
      );
      formData.append('file', image, encodeURIComponent(image.name));
      return this.http.put<ApiResponse<ProductType>>(url, formData).pipe(map(({ data }) => data));
    }
    return this.http.put<ApiResponse<ProductType>>(url, actualBody).pipe(map(({ data }) => data));
  }

  public addProductType(body: AssetTypesCreateOneBody, image?: File): Observable<ProductType> {
    const actualBody = {
      ...(body.label && { label: body.label }),
      ...(body.manufacturerName && { manufacturer: body.manufacturerName }),
    };
    if (image) {
      const formData = new FormData();
      formData.append(
        'data',
        JSON.stringify({ ...actualBody, sourceType: FileSourceTypeEnum.ASSET_TYPE_IMAGE }),
      );
      formData.append('file', image, encodeURIComponent(image.name));
      return this.http
        .post<ApiResponse<ProductType>>(this.productsTypesUrl, formData)
        .pipe(map(({ data }) => data));
    }

    return this.http
      .post<ApiResponse<ProductType>>(this.productsTypesUrl, actualBody)
      .pipe(map(({ data }) => data));
  }

  public deleteProductType(productTypeId: string): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.productsTypesUrl}/${productTypeId}`;

    return this.http.delete<void>(url, httpOptions);
  }

  private getProductTypesBase(
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirectionEnum | '' = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
    ids?: string[],
  ): Observable<ApiResponse<ProductType[]>> {
    let params = new HttpParams();
    let body;
    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', encodeURIComponent(String(searchValue)));
    }
    if (filterValue) {
      params = params.set('filter', JSON.stringify(filterValue));
    }
    if (ids !== undefined) {
      body = { instance_ids: ids };
    }

    return body
      ? this.http.put<ApiResponse<ProductType[]>>(this.productsTypesUrl, body, { params })
      : this.http.get<ApiResponse<ProductType[]>>(this.productsTypesUrl, { params });
  }
}
