<div class="feature-restricted-container">
  <img class="lock-icon" [attr.src]="'/assets/img/no_permission_fill.svg' | sanitizeUrl" />

  <h1 class="title" i18n="@@youDoNotHavePermissionToViewThisPage">
    You do not have permission to view this page
  </h1>

  <p class="contact-para" i18n="@@pleaseContactTheAdministratorOfTheSoftwareToAccessThisPageDot">
    Please contact the administrator of the software to access this page.
  </p>
</div>
