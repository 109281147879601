import { AssetBasic } from '@remberg/assets/common/base';
import { MultiLanguageText, UnknownOr } from '@remberg/global/common/core';
import { ConfigWithDynamicActions, ConfigWithPrefilling } from '../../../automation';
import { ConfigWithFilterSource } from '../config-with-filter-source';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AssetSingleSelectField
  extends Field<FormFieldTypesEnum.ASSET_SINGLE_SELECT, AssetSingleSelectFieldConfig> {}

export interface AssetSingleSelectFieldConfig
  extends ConfigWithPrefilling<AssetSingleSelectPrefillTargetsEnum>,
    ConfigWithDynamicActions,
    ConfigWithFilterSource {
  required?: boolean;
  disabled?: boolean;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  label: {
    text: MultiLanguageText;
    uiHide?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value?: {
    disableAssetFormInstanceRelationship?: boolean;
    disableCreation?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
  };
}

export enum AssetSingleSelectPrefillTargetsEnum {
  SELECTED_ASSET = 'selectedAsset',
}

export interface AssetSingleSelectFieldData<Populated extends boolean = false> {
  selectedAsset?: Populated extends true ? UnknownOr<AssetBasic> : string;
}
