import { Pipe, PipeTransform } from '@angular/core';
import { formatToRembergNumberString } from '@remberg/global/common/core';

@Pipe({
  name: 'rembergNumber',
})
export class RembergNumberPipe implements PipeTransform {
  public transform(value: number | string): string {
    return formatToRembergNumberString(value);
  }
}
