import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { RembergDate, getLanguageCodesDateFns } from '@remberg/global/common/core';
import { createArray } from '@remberg/global/ui';
import {
  Locale,
  addDays,
  addMonths,
  addYears,
  format,
  formatISO,
  getDaysInMonth,
  parse,
  parseISO,
} from 'date-fns';
import { toRembergDate } from './datetime.service';

const MONTH_FORMATS = {
  long: 'LLLL',
  short: 'LLL',
  narrow: 'LLLLL',
};

const DAY_OF_WEEK_FORMATS = {
  long: 'EEEE',
  short: 'EEE',
  narrow: 'EEEEE',
};

/**
 * Custom date adapter which returns date as "RembergDate" instead of the Date object.
 */
@Injectable()
export class RembergDateAdapter extends DateAdapter<RembergDate> {
  private localeCode: Locale;

  constructor(@Inject(LOCALE_ID) public override locale: string) {
    super();

    this.localeCode = getLanguageCodesDateFns(this.locale);
    super.setLocale(this.localeCode);
  }

  public getYear(date: RembergDate): number {
    return parseISO(date).getFullYear();
  }

  public getMonth(date: RembergDate): number {
    return parseISO(date).getMonth();
  }

  public getDate(date: RembergDate): number {
    return parseISO(date).getDate();
  }

  public getDayOfWeek(date: RembergDate): number {
    return parseISO(date).getDay();
  }

  public getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const pattern = MONTH_FORMATS[style];
    return createArray(12, (i) => this.formatNativeDate(new Date(2017, i, 1), pattern));
  }

  public getDateNames(): string[] {
    const dtf = new Intl.DateTimeFormat(this.localeCode.code, { day: 'numeric', timeZone: 'utc' });

    return createArray(31, (i) => {
      const date = new Date();
      date.setUTCFullYear(2017, 0, i + 1);
      date.setUTCHours(0, 0, 0, 0);

      // Some browsers add extra invisible characters that we should strip before asserting.
      return dtf.format(date).replace(/[\u200e\u200f]/g, '');
    });
  }

  public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const pattern = DAY_OF_WEEK_FORMATS[style];
    return createArray(7, (i) => this.formatNativeDate(new Date(2017, 0, i + 1), pattern));
  }

  public getYearName(date: RembergDate): string {
    return this.format(date, 'y');
  }

  public getFirstDayOfWeek(): number {
    return this.localeCode.options?.weekStartsOn ?? 0;
  }

  public getNumDaysInMonth(date: RembergDate): number {
    return getDaysInMonth(parseISO(date));
  }

  public clone(date: RembergDate): RembergDate {
    return date.toString() as RembergDate;
  }

  public createDate(year: number, month: number, date: number): RembergDate {
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    // Passing the year to the constructor causes year numbers <100 to be converted to 19xx.
    // To work around this we use `setFullYear` and `setHours` instead.
    const result = new Date();
    result.setFullYear(year, month, date);
    result.setHours(0, 0, 0, 0);

    // Check that the date wasn't above the upper bound for the month, causing the month to overflow
    if (result.getMonth() !== month) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return toRembergDate(result);
  }

  public today(): RembergDate {
    return toRembergDate(new Date());
  }

  public parse(value: any, parseFormat: string): RembergDate | null {
    if (!value) {
      return null;
    }

    if (typeof value === 'number') {
      return toRembergDate(new Date(value));
    }

    if (typeof value === 'string') {
      const formatLength = new Date().toLocaleDateString(this.locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).length;
      if (value.length !== formatLength) {
        return this.invalid();
      }

      const fromFormat = toRembergDate(
        parse(value, parseFormat, new Date(), { locale: this.localeCode }),
      );

      if (this.isValid(fromFormat)) {
        return fromFormat;
      }

      return this.invalid();
    }

    return null;
  }

  public format(date: RembergDate, displayFormat: string): string {
    if (!this.isValid(date)) {
      return date;
    }

    return this.formatNativeDate(convertRembergDateToDateWithCurrentTimezone(date), displayFormat);
  }

  public addCalendarYears(date: RembergDate, years: number): RembergDate {
    return toRembergDate(addYears(convertRembergDateToDateWithCurrentTimezone(date), years));
  }

  public addCalendarMonths(date: RembergDate, months: number): RembergDate {
    return toRembergDate(addMonths(convertRembergDateToDateWithCurrentTimezone(date), months));
  }

  public addCalendarDays(date: RembergDate, days: number): RembergDate {
    return toRembergDate(addDays(convertRembergDateToDateWithCurrentTimezone(date), days));
  }

  public toIso8601(date: RembergDate): string {
    return formatISO(parseISO(date), { representation: 'date' });
  }

  public override deserialize(value: any): RembergDate | null {
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      return value as RembergDate;
    }
    return null;
  }

  public isDateInstance(date: any): boolean {
    return typeof date == 'string' ? this.isValid(date as RembergDate) : false;
  }

  public isValid(date: RembergDate): boolean {
    return !isNaN(toDate(date).getTime());
  }

  public invalid(): RembergDate {
    return 'INVALID_DATE';
  }

  private formatNativeDate(date: Date, displayFormat: string): string {
    return format(date, displayFormat, { locale: this.localeCode });
  }
}

function toDate(date: RembergDate): Date {
  if (date === 'INVALID_DATE') {
    return new Date(NaN);
  }
  return parse(date, 'yyyy-MM-dd', new Date());
}

/**
 * Converts a RembergDate to a Date object without affecting the original date by using the midnight local time.
 * @param date date in RembergDate format: 'YYYY-MM-DD'
 */
export function convertRembergDateToDateWithCurrentTimezone(date: RembergDate): Date {
  const dateObj = new Date(date);

  return new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
}
