const FILL_TENANT_ID = '6401058d4c4596d3f0dc8d47';
const FILL_SANDBOX_SYSTEM_TENANT_ID = '6401058e4c4596d3f0dc8d6c';
const TESTGMBH_TENANT_ID = '63eb9af44555c18d45b1ca96';

export const QUANOS_ALLOWED_TENANTS = [
  FILL_TENANT_ID,
  FILL_SANDBOX_SYSTEM_TENANT_ID,
  TESTGMBH_TENANT_ID,
];

export const QUANOS_BASE_URL = 'https://etk.fill.co.at';
