import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { EMAIL_VALIDATION_REGEX, LogService, assertDefined } from '@remberg/global/ui';
import { GlobalSelectors, RootGlobalState, RouterSelectors } from '@remberg/ui-core/core';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { ToastrService } from 'ngx-toastr';
import { Subscription, firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit, OnDestroy {
  protected emailFormControl?: UntypedFormControl;
  protected sent: boolean = false;
  protected passwordExpired: boolean = false;
  protected email?: string;

  protected readonly loginUrl$ = this.store
    .select(RouterSelectors.selectIsPortalRoute)
    .pipe(map((isPortal) => (isPortal ? '/portal/login' : '/login')));

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly rembergUsersService: RembergUsersService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly logger: LogService,
    private readonly store: Store<RootGlobalState>,
  ) {}

  public ngOnInit(): void {
    this.emailFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(EMAIL_VALIDATION_REGEX),
    ]);
    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        this.email = params['passwordExpired'] || '';
        this.passwordExpired = params['passwordExpired'] ? true : false;
      }),
    );
    if (this.email) {
      this.emailFormControl.patchValue(this.email);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async forgot(): Promise<void> {
    this.sent = true;
    try {
      const tenantId = await firstValueFrom(this.store.select(GlobalSelectors.selectTenantId));
      const email = this.emailFormControl?.value;
      assertDefined(email, 'Send password reset mail only available if email is set');

      await firstValueFrom(
        this.rembergUsersService.sendPasswordResetEmail({
          email,
          tenantId,
        }),
      );
      this.toastr.info(
        $localize`:@@passwordResetLinkWasSentIfIsAssociatedUser:A password reset link was sent if there is an associated user.`,
        $localize`:@@info:Info`,
      );
    } catch (error) {
      this.logger.error()(error);
      this.toastr.error(
        $localize`:@@errorWhileSendingPasswordForgottenMail:Error while sending password forgotten mail.`,
        $localize`:@@error:Error`,
      );
    }
    const url = await firstValueFrom(this.loginUrl$);
    this.router.navigate([url]);
  }
}
