import { Assert, FeatureFlagEnum, TwoWayCompareStringType } from '@remberg/global/common/core';
import { AppointmentPermissions } from '@remberg/users/common/main';

export enum AppointmentsPermissionsEnum {
  APPOINTMENTS_ENABLED = 'appointmentsTemporary_enabled',
  APPOINTMENTS_CREATE = 'appointmentsTemporary_create',
  APPOINTMENTS_UPDATE = 'appointmentsTemporary_update',
  APPOINTMENTS_DELETE = 'appointmentsTemporary_delete',
}

export type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `${FeatureFlagEnum.APPOINTMENTS_TEMPORARY}_${keyof AppointmentPermissions}`,
    `${AppointmentsPermissionsEnum}`
  >
>;
