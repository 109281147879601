import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class RembergGuardService {
  constructor(
    private store: Store<RootGlobalState>,
    private router: Router,
  ) {}

  public async canActivate(): Promise<boolean> {
    const isAdmin = await firstValueFrom(this.store.select(GlobalSelectors.selectIsRembergAdmin));
    if (isAdmin) {
      return true;
    }
    this.router.navigateByUrl('/login');
    return false;
  }

  public canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
