import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PhoneNumber } from '@remberg/global/common/core';
import { POPOVER_DELAY, getPhoneNumberString } from '@remberg/global/ui';
import { ClipboardService } from '@remberg/ui-core/core';

@Component({
  selector: 'app-phone-number-display',
  templateUrl: './phone-number-display.component.html',
  styleUrls: ['./phone-number-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberDisplayComponent {
  @Input() public isLabelHidden: boolean = false;
  @Input() public label: string = $localize`:@@phoneNumber:Phone Number`;
  @Input() public required: boolean = false;
  @Input() public showInline: boolean = false;
  @Input() public disableNavigation: boolean = false;
  @Input() public isTooltipVisible = true;
  @Input() public showIcon = false;
  public _value?: PhoneNumber;
  protected href: string = 'javascript:void(0)';
  protected readonly POPOVER_DELAY = POPOVER_DELAY;

  constructor(public clipboardService: ClipboardService) {}

  @Input()
  public get value(): PhoneNumber | undefined {
    return this._value;
  }
  public set value(value: PhoneNumber | undefined) {
    this._value = value;

    const { number, countryPrefix } = value ?? {};
    if (!number) return;
    if (!countryPrefix) {
      this.href = `tel:${number}`;
      return;
    }
    this.href = `tel:+${countryPrefix} ${number}`;

    if (this.disableNavigation) {
      this.href = '';
    }
  }

  protected copyPhoneNumber(): void {
    if (!this.value) return;
    this.clipboardService.copyToClipboard(getPhoneNumberString(this.value), {
      title: $localize`:@@phoneNumberCopied:Phone Number copied`,
      message: '',
    });
  }
}
