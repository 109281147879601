import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PlatformFilesService } from '@remberg/files/ui/clients';
import {
  FORM_INSTANCE_OFFLINE_SERVICE,
  FormInstanceOfflineServiceInterface,
} from '@remberg/forms/ui/clients';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import {
  AbortToken,
  CONNECTIVITY_SERVICE,
  ChangeTypeEnum,
  ConnectivityServiceInterface,
  LogService,
  PushStatus,
} from '@remberg/global/ui';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { isAccountFeatureFlagEnabled } from '../../helpers/checkFeatureHelper';
import { GlobalSelectors, RootGlobalState } from '../../store';
import { AppStateService } from '../app-state.service';
import { MobileLogsUploadService } from '../mobile-logs/mobile-logs-upload.service';
import { OfflinePushFormInstanceService } from './offline-push-form-instance.service';

@Injectable({
  providedIn: 'root',
})
export class OfflinePushService {
  public pushing = new BehaviorSubject<boolean>(false);
  public pushStatus: BehaviorSubject<PushStatus | undefined> = new BehaviorSubject<
    PushStatus | undefined
  >(undefined);
  public outstandingChangesCount: BehaviorSubject<number | undefined> = new BehaviorSubject<
    number | undefined
  >(undefined);

  private abortToken?: AbortToken;

  constructor(
    private logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly fileService: PlatformFilesService,
    private readonly offlinePushFormInstanceService: OfflinePushFormInstanceService,
    @Inject(FORM_INSTANCE_OFFLINE_SERVICE)
    private readonly formInstanceOfflineService: FormInstanceOfflineServiceInterface,
    private readonly mobileLogsUploadService: MobileLogsUploadService,
    private readonly appState: AppStateService,
    private readonly store: Store<RootGlobalState>,
  ) {
    // initially derive the outstanding changes count
    this.formInstanceOfflineService.outstandingChangesCount.subscribe(this.outstandingChangesCount);

    // setup a subscription to watch for viewRefreshes to also recalculate the outstanding changes
    this.connectivityService.shouldRefreshView$.subscribe(() =>
      this.recalculateOutstandingChanges(),
    );
  }

  private recalculateOutstandingChanges(): void {
    // We must add all data types here once they have changes to recount
    if (isAccountFeatureFlagEnabled(FeatureFlagEnum.FORMS, this.appState)) {
      this.formInstanceOfflineService.recalculateOutstandingChangesCount();
    }
  }

  public async terminatePushChanges(): Promise<void> {
    await this.abortToken?.abort();
  }

  public async pushChanges(): Promise<boolean> {
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    // make sure we only push once at a time
    if (this.pushing.getValue()) {
      this.logger.warn()('Push already in progress!');
      return false;
    } else if (!isIonic) {
      this.logger.debug()(
        'Will not push because the offline feature is only available for native apps.',
      );
      return false;
    } else if (await firstValueFrom(this.store.select(GlobalSelectors.selectIsRembergAdmin))) {
      this.logger.debug()('Will not push because remberg admin.');
      return false;
    } else {
      let success = true;
      this.pushing.next(true);
      try {
        this.abortToken = new AbortToken();
        const status = {};
        this.pushStatus.next(status);

        // push mobile logs
        await this.mobileLogsUploadService
          .pushLogsToServer(this.pushStatus, this.abortToken)
          .catch((error) => {
            if (error.aborted) {
              throw error;
            } else {
              throw { message: 'Failed pushing the logs to server', error: error };
            }
          });

        // push offline files
        this.logger.silly()(
          'Push offline files, e.g. from rich text editor, file upload component...',
        );
        await this.fileService
          .pushOfflineFileCreations(this.pushStatus, this.abortToken)
          .catch((error) => {
            if (error.aborted) {
              throw error;
            } else {
              throw { message: 'pushOfflineFileCreations failed!', error: error };
            }
          });

        if (isAccountFeatureFlagEnabled(FeatureFlagEnum.FORMS, this.appState)) {
          // Create new forms
          await this.offlinePushFormInstanceService
            .pushOfflineFormCreations(this.pushStatus, this.abortToken)
            .catch((error) => {
              if (error.aborted) {
                throw error;
              } else {
                throw { message: 'forms pushOfflineFormCreations failed!', error: error };
              }
            });

          // Update existing forms
          await this.offlinePushFormInstanceService
            .pushOfflineFormUpdates(this.pushStatus, this.abortToken)
            .catch((error) => {
              if (error.aborted) {
                throw error;
              } else {
                throw { message: 'forms pushOfflineFormUpdates failed!', error: error };
              }
            });

          // delete existing forms
          await this.offlinePushFormInstanceService
            .pushOfflineFormDeletions(this.pushStatus, this.abortToken)
            .catch((error) => {
              if (error.aborted) {
                throw error;
              } else {
                throw { message: 'forms pushOfflineFormDeletions failed!', error: error };
              }
            });
        } else {
          skipSteps(status, [
            ChangeTypeEnum.FORM_CREATIONS,
            ChangeTypeEnum.FORM_UPDATES,
            ChangeTypeEnum.FORM_DELETIONS,
          ]);
          this.pushStatus.next(status);
        }

        // push WO Updates etc...

        // PushChanges complete
        this.logger.info()('Push Changes complete!');
      } catch (error: any) {
        success = false;
        // do not log errors for aborting the process
        if (!error.aborted) {
          this.logger.error()(error);
        }
      } finally {
        if (!this.abortToken?.check(true)) {
          this.logger.info()('Push Changes was aborted!');
          success = false;
        }
        this.abortToken?.complete();
        this.pushing.next(false);
      }
      return success;
    }
  }
}

function skipSteps(status: PushStatus, steps: ChangeTypeEnum[]): PushStatus {
  for (const step of steps) {
    status[step] = 1;
  }

  return status;
}
