import Quill from 'quill';
const BlockEmbed = Quill.import('blots/block/embed');

export class TableBlockEmbed extends BlockEmbed {
  static blotName = 'TableBlockEmbed';
  static tagName = 'table';

  static create(value: any) {
    const node = super.create();
    let valueToReturn = value;
    // assign ID to table tag
    const tableId = `assignedTableId-${Date.now()}`;
    valueToReturn = value.replace('#tableId', `#${tableId}`);
    node.setAttribute('id', tableId);
    node.setAttribute('style', 'border-collapse:collapse'); // this is applied style to <table> tag
    node.innerHTML = this.transformValue(valueToReturn);
    return node;
  }

  static transformValue(value: any) {
    let handleArr = value.split('\n');
    handleArr = handleArr.map((e: any) => e.replace(/^[\s]+/, '').replace(/[\s]+$/, ''));
    return handleArr.join('');
  }

  static value(node: any) {
    return node.innerHTML;
  }
}
