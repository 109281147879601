import { ApiResponse, AppInjector, BaseModel, LogService } from '@remberg/global/ui';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { catchError, finalize, withLatestFrom } from 'rxjs/operators';

export class MultiselectOptionsService<T extends BaseModel> {
  private isLoadingSource = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this.isLoadingSource.asObservable();

  private optionsSource = new BehaviorSubject<T[]>([]);
  public readonly options$ = this.optionsSource.asObservable();

  private currentPageSource = new BehaviorSubject<number>(0);

  protected hasLoadedAllPagesSource = new BehaviorSubject<boolean>(false);
  public readonly hasLoadedAllPages$ = this.hasLoadedAllPagesSource.asObservable();

  private pageSize: number = 10;

  private logger: LogService;

  constructor(
    private fetchFunction: (
      search: string,
      pageSize: number,
      pageOffset: number,
    ) => Observable<ApiResponse<T[]>>,
  ) {
    this.logger = AppInjector.get(LogService);
  }

  public fetchMore(search: string): void {
    if (!this.hasLoadedAllPagesSource.value) {
      this.isLoadingSource.next(true);
      this.fetchFunction(search, this.pageSize, this.currentPageSource.value)
        .pipe(
          withLatestFrom(this.options$),
          map(([res, lastResults]) => {
            this.currentPageSource.next(this.currentPageSource.value + 1);
            this.optionsSource.next([...lastResults, ...res.data]);
            if (res.data?.length !== this.pageSize) {
              this.hasLoadedAllPagesSource.next(true);
            }
          }),
          catchError((error) => {
            this.logger.error()('Error fetching select options for multiple options:', error);
            return of(null);
          }),
          finalize(() => {
            this.isLoadingSource.next(false);
          }),
        )
        .subscribe();
    }
  }

  public reset(): void {
    this.currentPageSource.next(0);
    this.isLoadingSource.next(false);
    this.hasLoadedAllPagesSource.next(false);
    this.optionsSource.next([]);
  }

  public search(searchValue: string): void {
    this.reset();
    this.fetchMore(searchValue);
  }
}
