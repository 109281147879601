import { ContactBasic } from '@remberg/crm/common/base';
import { contactToFullNameString } from '@remberg/crm/common/main';

export function getContactLabelWithCheckForCurrentContact(
  contact: ContactBasic | undefined,
  currentContact: ContactBasic | undefined,
): string | undefined {
  return contact && contactToFullNameString(contact)
    ? `${contactToFullNameString(contact)}${
        contact._id === currentContact?._id ? ` (${$localize`:@@you:You`})` : ''
      }`
    : undefined;
}
