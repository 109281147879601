import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileSourceTypeEnum } from '@remberg/files/common/main';
import { IMAGE_FALLBACK_URL } from '@remberg/global/ui';
import { GlobalSelectors, RootGlobalState } from '@remberg/ui-core/core';

@Component({
  selector: 'app-landing-portal-navbar',
  templateUrl: './landing-portal-navbar.component.html',
  styleUrls: ['./landing-portal-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPortalNavbarComponent {
  protected readonly backgroundColor$ = this.store.select(
    GlobalSelectors.selectBackgroundThemeColor,
  );
  protected readonly logo$ = this.store.select(GlobalSelectors.selectLogoFileId);
  protected readonly FileSourceTypeEnum = FileSourceTypeEnum;
  protected readonly IMAGE_FALLBACK_URL = IMAGE_FALLBACK_URL;

  constructor(private readonly store: Store<RootGlobalState>) {}
}
