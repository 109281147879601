import { INSTANCE_COUNTER_REGEX, isDefined } from '@remberg/global/common/core';

export function getInstanceNumber(searchString: string | undefined): number | undefined {
  if (searchString) {
    const matches = INSTANCE_COUNTER_REGEX.exec(searchString);
    const id: string | undefined = matches?.[1];

    if (isDefined(id) && !isNaN(parseInt(id, 10))) {
      return parseInt(id, 10);
    }
  }

  return undefined;
}
