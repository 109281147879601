import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-list-add-entry',
  templateUrl: './button-list-add-entry.component.html',
  styleUrls: ['./button-list-add-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonListAddEntryComponent {
  /** isHidden: hide from parent component */
  @Input() public isHidden: boolean = false;

  /** label: adjust label from parent component */
  @Input() public label: string = $localize`:@@plusAdd:+ Add`;

  /** borderStyle: directly set border-style property as string */
  @Input() public borderStyle: string = 'solid';

  /** borderColor: manual override border color for err */
  @Input() public borderColorRequired: boolean = false;

  /** onButtonClick: emits a simple button click */
  @Output() public onButtonClick: EventEmitter<void> = new EventEmitter<void>();
}
