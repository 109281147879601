import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogConfigBase, DialogResultBase } from '../../definitions';

export type ConfirmationDialogResult = DialogResultBase & {
  dialogId: string;
  checkboxItems?: {
    id: string;
    isChecked: boolean;
  }[];
  payload?: any;
};

type CheckboxItem = {
  id: string;
  text: string;
  isChecked?: boolean;
};

export type ConfirmationDialogData = DialogConfigBase<string, ConfirmationDialogResult> & {
  dialogId: string;
  confirmButtonIcon?: string;
  checkboxItems?: CheckboxItem[];
  payload?: string;
};

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  protected readonly dialogId: string;
  protected readonly confirmButtonText: string;
  protected readonly cancelButtonText?: string;
  protected readonly confirmButtonIcon?: string;
  protected readonly contentText?: string;
  protected readonly headerText?: string;
  protected readonly headerIcon: string;
  protected readonly footerText?: string;
  protected readonly footerIcon?: string;
  protected readonly htmlContent?: string;
  protected readonly payload?: any;
  protected readonly checkboxItems: CheckboxItem[];
  protected readonly confirmationCallback?: (dialogResult: ConfirmationDialogResult) => void;
  protected readonly cancellationCallback?: (dialogResult: ConfirmationDialogResult) => void;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ConfirmationDialogData,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent, ConfirmationDialogResult>,
  ) {
    this.dialogId = this.data.dialogId;
    this.confirmButtonText = this.data.confirmButtonText ?? '';
    this.cancelButtonText = this.data.cancelButtonText;
    this.confirmButtonIcon = this.data.confirmButtonIcon;
    this.contentText = this.data.contentText;
    this.headerText = this.data.headerText;
    this.headerIcon = this.data.headerIcon ?? 'warning_amber';
    this.footerText = this.data.footerText;
    this.footerIcon = this.data.footerIcon;
    this.htmlContent = this.data.htmlContent;
    this.payload = this.data.payload;
    this.checkboxItems = this.data.checkboxItems ?? [];
    this.confirmationCallback = this.data.confirmationCallback;
    this.cancellationCallback = this.data.cancellationCallback;
  }

  protected confirm(): void {
    const dialogResult: ConfirmationDialogResult = {
      isConfirmed: true,
      isCancelled: false,
      dialogId: this.dialogId,
      checkboxItems: this.checkboxItems.map((checkboxItem) => ({
        id: checkboxItem.id,
        isChecked: checkboxItem.isChecked ?? false,
      })),
      payload: this.payload,
    };
    this.confirmationCallback?.(dialogResult);
    this.dialogRef.close(dialogResult);
  }

  protected close(): void {
    const dialogResult: ConfirmationDialogResult = {
      isConfirmed: false,
      isCancelled: true,
      dialogId: this.dialogId,
      payload: this.payload,
    };
    this.cancellationCallback?.(dialogResult);

    // TODO: (backward compatibility) using undefined until eventual upgrade of confirmation dialogs to being aware of this result model
    // this.dialogRef.close(dialogResult);
    this.dialogRef.close(undefined);
  }
}
