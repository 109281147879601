import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './components';
import { MaterialModule } from './material.module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    MaterialModule,
    FormsModule,
  ],
  declarations: [ConfirmationDialogComponent, SafeHtmlPipe],
  exports: [ConfirmationDialogComponent, SafeHtmlPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class GlobalUIModule {}
