/**
 * @description Enum for the activity model that will be used to know from which type of activity we are dealing with.`
 */
export enum ActivityModelNameEnum {
  ASSET = 'asset',
  ASSET_TYPE = 'assetType',
  CASE = 'case',
  CONTACT = 'contact',
  EMAIL = 'email',
  FORM_INSTANCE = 'formInstance',
  MAINTENANCE_PLAN = 'maintenance-plan',
  NOTE = 'note',
  ORGANIZATION = 'organization',
  PLATFORM_FILE = 'platformFile',
  TASK = 'task',
  USER_GROUP = 'userGroup',
  WORK_ORDER = 'workOrder',
}
