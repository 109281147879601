<div [ngClass]="{ 'time-inline': showInline, 'time-show-in-forms': showInForms }">
  <div class="label" [matTooltipDisabled]="!isTooltipVisible" [matTooltip]="label">
    {{ label }}<span class="required" *ngIf="required">*</span>
  </div>
  <span *ngIf="showInline" class="fill-remaining-space"></span>
  <div
    *ngIf="value"
    class="value"
    [matTooltipDisabled]="!isTooltipVisible"
    [matTooltip]="value | rembergTimeDisplay"
  >
    {{ value | rembergTimeDisplay }}
  </div>
  <div *ngIf="!value" class="value">-</div>
</div>
