import { TypeToString } from '@remberg/global/common/core';
import { GlobalConfigParameters } from './global-config-parameters.interface';

export const GLOBAL_CONFIG_PARAMETERS_SETTINGS: {
  [groupKey in keyof GlobalConfigParameters]: {
    [groupItemKey in keyof GlobalConfigParameters[groupKey]]: {
      default: GlobalConfigParameters[groupKey][groupItemKey];
      type: TypeToString<GlobalConfigParameters[groupKey][groupItemKey]>;
    };
  };
} = {
  emails: {
    inboxProcessedItemsPerRun: { default: 20, type: 'number' },
    inboxProcessedItemsPerRunHealthMultiplier: { default: 5, type: 'number' },
    inboxReadCronjobExecution: { default: '*/30 * * * * *', type: 'string' },
  },
  workOrders: {
    overdueNotificationCronjobExecution: { default: '*/30 * * * * *', type: 'string' },
    processedItemsForNotificationsPerRun: { default: 100, type: 'number' },
    maxLimitReturnForSchedulingItems: { default: 2000, type: 'number' },
  },
};
