/* eslint-disable @typescript-eslint/no-empty-interface */
import { MultiLanguageText } from '@remberg/global/common/core';
import { ConfigWithDynamicActions, ConfigWithPrefilling } from '../../../automation';
import { ConfigWithFilterSource } from '../config-with-filter-source';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';
import { AddressInputField, AddressInputFieldData } from './address-input';
import { AssetSingleSelectField, AssetSingleSelectFieldData } from './asset-single-select';
import { BooleanInputField, BooleanInputFieldData } from './boolean-input';
import { ContactSingleSelectField, ContactSingleSelectFieldData } from './contact-single-select';
import { DateInputField, DateInputFieldData } from './date-input';
import { DateTimeInputField, DateTimeInputFieldData } from './date-time-input';
import { HeadlineDisplayField, HeadlineDisplayFieldData } from './headline-display';
import { HtmlDisplayField, HtmlDisplayFieldData } from './html-display';
import { MultiLineTextInputField, MultiLineTextInputFieldData } from './multi-line-text-input';
import {
  OrganizationSingleSelectField,
  OrganizationSingleSelectFieldData,
} from './organization-single-select';
import { PhoneNumberInputField, PhoneNumberInputFieldData } from './phone-number-input';
import { SingleLineTextInputField, SingleLineTextInputFieldData } from './single-line-text-input';
import { StaticMultiSelectField, StaticMultiSelectFieldData } from './static-multi-select';
import { StaticSingleSelectField, StaticSingleSelectFieldData } from './static-single-select';
import { TimeInputField, TimeInputFieldData } from './time-input';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RepeaterField
  extends Field<FormFieldTypesEnum.FIELD_REPEATER, RepeaterFieldConfig> {}

export interface RepeaterFieldConfig {
  label: {
    text: MultiLanguageText;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  helpTextBefore?: MultiLanguageText;
  helpTextAfter?: MultiLanguageText;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  pdfEnableListView?: boolean;
  requiredAmountOfEntries?: number;
  fields: RepeatableField[];
}

export type RepeatableField = HeadlineDisplayField | HtmlDisplayField | PdfRepeatableField;

export type PdfRepeatableField =
  | RepeatableAddressInputField
  | RepeatableAssetSingleSelectField
  | RepeatableBooleanInputField
  | RepeatableCompanySingleSelectField
  | RepeatableDateInputField
  | RepeatableDateTimeInputField
  | RepeatableMultiLineTextInputField
  | RepeatablePhoneNumberInputField
  | RepeatableSingleLineTextInputField
  | RepeatableStaticMultiSelectField
  | RepeatableStaticSingleSelectField
  | RepeatableTimeInputField
  | RepeatableUserSingleSelectField;

// For JSON Schema it's important to have an interface instead of a type
interface RepeatableAddressInputField extends PdfRepeatable<AddressInputField> {}
interface RepeatableAssetSingleSelectField extends PdfRepeatable<AssetSingleSelectField> {}
interface RepeatableBooleanInputField extends PdfRepeatable<BooleanInputField> {}
interface RepeatableCompanySingleSelectField extends PdfRepeatable<OrganizationSingleSelectField> {}
interface RepeatableDateInputField extends PdfRepeatable<DateInputField> {}
interface RepeatableDateTimeInputField extends PdfRepeatable<DateTimeInputField> {}
interface RepeatableMultiLineTextInputField extends PdfRepeatable<MultiLineTextInputField> {}
interface RepeatablePhoneNumberInputField extends PdfRepeatable<PhoneNumberInputField> {}
interface RepeatableSingleLineTextInputField extends PdfRepeatable<SingleLineTextInputField> {}
interface RepeatableStaticMultiSelectField extends PdfRepeatable<StaticMultiSelectField> {}
interface RepeatableStaticSingleSelectField extends PdfRepeatable<StaticSingleSelectField> {}
interface RepeatableTimeInputField extends PdfRepeatable<TimeInputField> {}
interface RepeatableUserSingleSelectField extends PdfRepeatable<ContactSingleSelectField> {}

export type PdfRepeatableFieldConfig = PdfRepeatableField['config'];

type PdfRepeatable<F extends Field<FormFieldTypesEnum, BasePdfRepeatableFieldConfig>> =
  F extends Field<infer T, infer C>
    ? Field<
        T,
        Omit<
          C,
          keyof ConfigWithDynamicActions | keyof ConfigWithPrefilling | keyof ConfigWithFilterSource
        > &
          PdfRepeatableFieldOptions
      >
    : never;

export interface BasePdfRepeatableFieldConfig {
  pdfHide?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  label: {
    text: MultiLanguageText;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value?: {
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
  };
}

export interface PdfRepeatableFieldOptions {
  uiHideInRepeaterCardDisplay?: boolean;
  pdfFieldRepeaterCellAlignment?: PdfFieldRepeaterCellAlignmentEnum;
}

export enum PdfFieldRepeaterCellAlignmentEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface RepeaterFieldData<Populated extends boolean = false> {
  entries: RepeatableFieldData<Populated>[][];
}

export type RepeatableFieldData<Populated extends boolean = false> =
  | HeadlineDisplayFieldData
  | HtmlDisplayFieldData
  | AddressInputFieldData
  | AssetSingleSelectFieldData<Populated>
  | BooleanInputFieldData
  | OrganizationSingleSelectFieldData<Populated>
  | DateInputFieldData
  | DateTimeInputFieldData
  | MultiLineTextInputFieldData
  | PhoneNumberInputFieldData
  | SingleLineTextInputFieldData
  | StaticMultiSelectFieldData
  | StaticSingleSelectFieldData
  | TimeInputFieldData
  | ContactSingleSelectFieldData<Populated>;
