export interface AssetBasic {
  _id: string;
  serialNumber: string;
  assetTypeId: string;
  assetTypeName: string;
  assetTypeImageId?: string;
  customerId?: string;
}

export function isAssetBasic(
  assetBasic: AssetBasic | { _id: string } | undefined,
): assetBasic is AssetBasic {
  return !!(assetBasic as AssetBasic)?.assetTypeName;
}
