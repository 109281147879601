import { AbstractControl, ValidatorFn } from '@angular/forms';

export const MIN_LENGTH_ARRAY_ERROR_KEY = 'minLength';
export const MAX_LENGTH_ARRAY_ERROR_KEY = 'maxLength';

// Array Validators
export class ArrayValidators {
  /**
   * @description custom validator to check that an array should have at most X amount of entries.
   */
  public static maxLength(max: number): ValidatorFn {
    return (control: AbstractControl) =>
      control.value.length > max ? { [MAX_LENGTH_ARRAY_ERROR_KEY]: true } : null;
  }

  /**
   * @description custom validator to check that an array should have at least X amount of entries.
   */
  public static minLength(min: number): ValidatorFn {
    return (control: AbstractControl) =>
      control.value.length < min ? { [MIN_LENGTH_ARRAY_ERROR_KEY]: true } : null;
  }
}
