export enum ConnectionStatusEnum {
  Online = 'online',
  Offline = 'offline',
}

/**
 * This Enum is used to store the current synchronization status when the mobile app is going into offline mode or back
 * it gets stored in ionic starage with the Key LocalStorageKeys.IONIC_DATA_SYNC_STATUS and in the GlobalState as syncState
 */
export enum SyncStateEnum {
  PushingDataToServer = 'pushingDataToServer',
  PullingDataFromServer = 'pullingDataFromServer',
}
