import { InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConnectionStatusEnum } from './connectivity-service.definitions';

export interface ConnectivityServiceInterface {
  /** Only emits something if we are switching to online status. */
  online$: Subject<string>;
  /** Only emits something if we are switching to offline status. */
  offline$: Subject<string>;
  /** Emits the current internal status and any future change to it. */
  connection$: BehaviorSubject<boolean>;
  /** Emits the current external connectivity status and any future change to it. */
  externalConnection$: BehaviorSubject<boolean>;
  shouldDisplayOnlineUi$: Observable<boolean>;
  shouldRefreshView$: Subject<boolean>;

  initialize(): Promise<void>;

  checkExternalStatus(): void;

  transitionExternalOnline(): void;

  transitionExternalOffline(): void;

  getCurrentStatus(): ConnectionStatusEnum;

  getCurrentExternalStatus(): ConnectionStatusEnum;

  getConnected(): boolean;

  goOnline(): Promise<void>;

  reset(): Promise<void>;

  goOffline(): Promise<void>;

  refreshView(): void;

  offlineCapabilitiesEnabled(): boolean;
}

export const CONNECTIVITY_SERVICE = new InjectionToken<ConnectivityServiceInterface>(
  'Token for injecting connectivity service in api libs without creating circular dependencies',
);
