import { LanguageCodeEnum } from '../enums';

interface NumberFormat {
  decimalSeparator: string;
  thousandsSeparator: string;
}

const NUMBER_FORMATS: Record<LanguageCodeEnum, NumberFormat> = {
  [LanguageCodeEnum.EN_US]: { decimalSeparator: '.', thousandsSeparator: ',' },
  [LanguageCodeEnum.DE]: { decimalSeparator: ',', thousandsSeparator: '.' },
  [LanguageCodeEnum.FR]: { decimalSeparator: ',', thousandsSeparator: '.' },
  [LanguageCodeEnum.ES]: { decimalSeparator: ',', thousandsSeparator: '.' },
  [LanguageCodeEnum.TR]: { decimalSeparator: ',', thousandsSeparator: '.' },
  [LanguageCodeEnum.IT]: { decimalSeparator: ',', thousandsSeparator: '.' },
  [LanguageCodeEnum.EL]: { decimalSeparator: ',', thousandsSeparator: '.' },
  [LanguageCodeEnum.TH]: { decimalSeparator: '.', thousandsSeparator: ',' },
};

/**
 * Formats a number to a string with a given locale and precision.
 *
 * @param value - The number to format.
 * @param locale - The locale to use for formatting the number.
 * @param precision - The number of decimal places to show.
 * @returns The formatted number as a string.
 */
export function formatNumber(
  value: number,
  precision = 0,
  language: LanguageCodeEnum = LanguageCodeEnum.EN_US,
): string {
  // Format the number in US format, e.g. 1,700.50, and split it by the decimal '.'
  const precisionMultiplier = Math.pow(10, precision);
  const formattedNumber = `${(
    Math.round(value * precisionMultiplier) / precisionMultiplier
  ).toLocaleString(LanguageCodeEnum.EN_US, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })}`.split('.');

  // Fetch the actual separator with the locale
  const separators = NUMBER_FORMATS[language];
  const thousandsSeparator = precision === 0 ? '' : separators.thousandsSeparator;

  const wholeNumber = `${(formattedNumber[0] ?? '0').replace(/,/g, thousandsSeparator)}`;
  if (formattedNumber.length > 1) {
    return `${wholeNumber}${separators.decimalSeparator}${formattedNumber[1]}`;
  }

  return wholeNumber;
}
