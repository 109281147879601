import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdvancedFilter, AdvancedFilterOperatorEnum } from '@remberg/advanced-filters/common/main';
import {
  AssetsLegacyFilterFieldEnum,
  Product,
  ProductCompatibility,
  mapProductCompatibilityToProduct,
} from '@remberg/assets/common/main';
import {
  AssetsLegacyFindManyOfflineQueryInterface,
  AssetsLegacyOfflineAdvancedFilterColumns,
  AssetsLegacyOfflineAdvancedFilterConfigKeys,
  AssetsLegacyOfflinePopulate,
  AssetsLegacyOfflineServiceInterface,
} from '@remberg/assets/ui/clients';
import { isDefined } from '@remberg/global/common/core';
import {
  ApiResponse,
  LogService,
  OfflinePopulateType,
  SQLQueryParams,
  SyncDataTypesEnum,
  UnreachableCaseError,
  assertDefined,
  getStringID,
} from '@remberg/global/ui';
import {
  SQLConcatOperator,
  concatSQLFiltersByOperator,
  generateBooleanSQLFilterItemValue,
  generateContainsSQLFilter,
  generateEqualsSQLFilter,
  generateIsEmptySQLFilter,
  generateNotEmptySQLFilter,
  generateNotEqualsSQLFilter,
  sqlFiltersHelper,
} from '../../helpers/sqlFiltersHelper';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

enum ColumnNamesEnum {
  SERIAL_NUMBER = 'serialNumber',
  PRODUCT_TYPE_NAME = 'productTypeName',
  CUSTOMER_ORGANIZATION = 'customerOrganization',
  PRODUCT_TYPE = 'productType',
  PARENT_ASSET = 'parentAsset',
  CUSTOMER_CONTACT = 'customerContact',
  MANUFACTURER_CONTACT = 'manufacturerContact',
  CITY = 'city',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  ZIP_POST_CODE = 'zipPostCode',
  CREATED_AT = 'createdAt',
  LAST_MODIFIED = 'lastModified',
  STATUS = 'status',
  QR_CODE = 'qrCode',
}

const PARAMS: SQLQueryParams<ColumnNamesEnum, AssetsLegacyOfflinePopulate, keyof Product> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.ASSETS,
  columns: {
    serialNumber: {
      type: 'TEXT',
      valueFunction: (val: Product) => val?.serialNumber?.trim(),
      sortNoCase: true,
      isSearchColumn: true,
    },
    productTypeName: {
      type: 'TEXT',
      valueFunction: (val: Product) => val?.productTypeName?.trim(),
      sortNoCase: true,
      isSearchColumn: true,
    },
    customerOrganization: {
      type: 'TEXT',
      valueFunction: (val: Product) => getStringID(val?.customerOrganization),
      sortNoCase: true,
    },
    productType: {
      type: 'TEXT',
      valueFunction: (val: Product) => getStringID(val?.productType),
    },
    parentAsset: {
      type: 'TEXT',
      valueFunction: (val: Product) => val?.assetHierarchy?.split(',').slice(-2, -1)[0],
    },
    customerContact: {
      type: 'TEXT',
      valueFunction: (val: Product) => getStringID(val?.customerContact),
    },
    manufacturerContact: {
      type: 'TEXT',
      valueFunction: (val: Product) => getStringID(val?.manufacturerContact),
    },
    city: {
      type: 'TEXT',
      valueFunction: (val: Product) => val?.location?.city?.trim(),
    },
    country: {
      type: 'TEXT',
      valueFunction: (val: Product) => val?.location?.country?.trim(),
    },
    countryProvince: {
      type: 'TEXT',
      valueFunction: (val: Product) => val?.location?.countryProvince?.trim(),
    },
    zipPostCode: {
      type: 'TEXT',
      valueFunction: (val: Product) => val?.location?.zipPostCode?.trim(),
    },
    createdAt: {
      type: 'TEXT',
      valueFunction: (val: Product) => (val?.createdAt ? String(val.createdAt) : undefined),
    },
    lastModified: {
      type: 'TEXT',
      valueFunction: (val: Product) => (val?.lastModified ? String(val.lastModified) : undefined),
    },
    status: {
      type: 'TEXT',
      valueFunction: (val: Product) => val?.status,
    },
    qrCode: {
      type: 'INTEGER',
      valueFunction: (val: Product) => (isDefined(val?.qrcode) ? 1 : 0),
    },
  },
  populates: {
    [AssetsLegacyOfflinePopulate.CUSTOMER_ORGANIZATION]: {
      populateDataType: SyncDataTypesEnum.ORGANIZATIONS,
      sourceKey: ColumnNamesEnum.CUSTOMER_ORGANIZATION,
      targetKey: 'customerOrganization',
    },
    [AssetsLegacyOfflinePopulate.CUSTOMER_CONTACT]: {
      populateDataType: SyncDataTypesEnum.CONTACTS,
      sourceKey: ColumnNamesEnum.CUSTOMER_CONTACT,
      targetKey: 'customerContact',
    },
    [AssetsLegacyOfflinePopulate.PRODUCT_TYPE]: {
      populateDataType: SyncDataTypesEnum.ASSETTYPES,
      sourceKey: ColumnNamesEnum.PRODUCT_TYPE,
      targetKey: 'productType',
    },
  },
};

const ASSET_FILTER_ENUM_TO_COLUMN_NAME: Record<AssetsLegacyOfflineAdvancedFilterColumns, string> = {
  [AssetsLegacyFilterFieldEnum.CITY]: 'city',
  [AssetsLegacyFilterFieldEnum.CONTACT_PERSON]: 'customerContact',
  [AssetsLegacyFilterFieldEnum.COUNTRY]: 'country',
  [AssetsLegacyFilterFieldEnum.COUNTRY_PROVINCE]: 'countryProvince',
  [AssetsLegacyFilterFieldEnum.CREATED]: 'createdAt',
  [AssetsLegacyFilterFieldEnum.CUSTOMER]: 'customerOrganization',
  [AssetsLegacyFilterFieldEnum.PARENT_ASSET]: 'parentAsset',
  [AssetsLegacyFilterFieldEnum.PRODUCT_TYPE]: 'productType',
  [AssetsLegacyFilterFieldEnum.QR_CODE]: 'qrCode',
  [AssetsLegacyFilterFieldEnum.RESPONSIBLE]: 'manufacturerContact',
  [AssetsLegacyFilterFieldEnum.STATUS]: 'status',
  [AssetsLegacyFilterFieldEnum.UPDATED]: 'lastModified',
  [AssetsLegacyFilterFieldEnum.ZIP_CODE]: 'zipPostCode',
};

@Injectable()
export class AssetsLegacyOfflineService
  extends BaseOfflineService<ProductCompatibility, AssetsLegacyOfflineAdvancedFilterConfigKeys>
  implements AssetsLegacyOfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, PARAMS, logger, store);
  }

  public async findOne(id: string, populate?: OfflinePopulateType): Promise<Product> {
    const productCompatibility = await this.getInstance(id, populate);
    return mapProductCompatibilityToProduct(productCompatibility);
  }

  public async findMany({
    limit,
    offset,
    sortColumn,
    sortDirection,
    searchValue,
    filters,
    filterQuery,
    populate,
  }: AssetsLegacyFindManyOfflineQueryInterface): Promise<ApiResponse<Product[]>> {
    // filters:
    const filterStrings: string[] = [];

    // setup old simple filters
    if (filters && Array.isArray(filters)) {
      for (const filter of filters) {
        const identifier = filter.identifier as AssetsLegacyFilterFieldEnum;
        switch (identifier) {
          case AssetsLegacyFilterFieldEnum.CUSTOMER:
            filterStrings.push(
              `${PARAMS.tableName}.customerOrganization LIKE '%${getStringID(filter.value)}%'`,
            );
            break;
          case AssetsLegacyFilterFieldEnum.CITY:
          case AssetsLegacyFilterFieldEnum.COUNTRY:
          case AssetsLegacyFilterFieldEnum.PRODUCT_TYPE:
          case AssetsLegacyFilterFieldEnum.COUNTRY_PROVINCE:
          case AssetsLegacyFilterFieldEnum.QR_CODE:
          case AssetsLegacyFilterFieldEnum.STATUS:
          case AssetsLegacyFilterFieldEnum.ZIP_CODE:
          case AssetsLegacyFilterFieldEnum.CONTACT_PERSON:
          case AssetsLegacyFilterFieldEnum.RESPONSIBLE:
          case AssetsLegacyFilterFieldEnum.INVOLVED_CONTACT:
          case AssetsLegacyFilterFieldEnum.PARENT_ASSET:
          case AssetsLegacyFilterFieldEnum.ASSET_ID:
          case AssetsLegacyFilterFieldEnum.CREATED:
          case AssetsLegacyFilterFieldEnum.UPDATED:
          case AssetsLegacyFilterFieldEnum.PART_ID:
          case AssetsLegacyFilterFieldEnum.BOUNDING_BOX:
          case AssetsLegacyFilterFieldEnum.GROUP_ID:
            // Please use the advanced filtering since the normal filtering is deprecated!
            this.logger.error()('Asset filter not implemented for offline mode: ' + identifier);
            break;
          default:
            // If this throws a compiler error, one of the enum types is missing in the above case statement
            this.logger.error()('Unknown asset filterIdentifier: ' + identifier);
            throw new UnreachableCaseError(identifier);
        }
      }
    }

    const response = await this.getManyItemsWithCount(
      {
        limit,
        offset,
        sortColumn,
        sortDirection,
        searchValue,
        filterQuery,
        populate,
      },
      filterStrings,
    );
    return new ApiResponse(mapProductCompatibilityToProduct(response.data), response.count);
  }

  public async findManyByIds(ids: string[], populate?: OfflinePopulateType): Promise<Product[]> {
    if (!ids.length) {
      return [];
    }

    const filterString = `${PARAMS.tableName}._id IN (${ids.map((id) => `'${id}'`).join(',')})`;
    const assets = await this.getInstances(
      undefined,
      undefined,
      undefined,
      undefined,
      filterString,
      populate,
    );

    return mapProductCompatibilityToProduct(assets);
  }

  public override getAdvancedFilterString(
    filter: AdvancedFilter<AssetsLegacyOfflineAdvancedFilterConfigKeys>,
  ): string {
    const tableName = PARAMS.tableName;

    switch (filter.identifier) {
      case AssetsLegacyFilterFieldEnum.CITY:
      case AssetsLegacyFilterFieldEnum.COUNTRY:
      case AssetsLegacyFilterFieldEnum.COUNTRY_PROVINCE:
      case AssetsLegacyFilterFieldEnum.CUSTOMER:
      case AssetsLegacyFilterFieldEnum.CREATED:
      case AssetsLegacyFilterFieldEnum.UPDATED:
      case AssetsLegacyFilterFieldEnum.PRODUCT_TYPE:
      case AssetsLegacyFilterFieldEnum.STATUS:
      case AssetsLegacyFilterFieldEnum.ZIP_CODE:
      case AssetsLegacyFilterFieldEnum.RESPONSIBLE:
      case AssetsLegacyFilterFieldEnum.CONTACT_PERSON: {
        const columnName = ASSET_FILTER_ENUM_TO_COLUMN_NAME[filter.identifier];
        return sqlFiltersHelper(filter, `${tableName}.${columnName}`);
      }
      case AssetsLegacyFilterFieldEnum.PARENT_ASSET:
        return generateContainsSQLFilter(`${tableName}.parentAsset`, filter.value);
      case AssetsLegacyFilterFieldEnum.QR_CODE: {
        const columnName = ASSET_FILTER_ENUM_TO_COLUMN_NAME[filter.identifier];
        return generateBooleanSQLFilterItemValue(filter, `${tableName}.${columnName}`);
      }
      case AssetsLegacyFilterFieldEnum.INVOLVED_CONTACT: {
        switch (filter.operator) {
          case AdvancedFilterOperatorEnum.IS:
            assertDefined(filter.value, 'filter.value should be defined for filter operator is');
            return concatSQLFiltersByOperator(
              [
                `${generateEqualsSQLFilter(`${tableName}.manufacturerContact`, filter.value)}`,
                `${generateEqualsSQLFilter(`${tableName}.customerContact`, filter.value)}`,
              ],
              SQLConcatOperator.OR,
            );
          case AdvancedFilterOperatorEnum.IS_NOT:
            assertDefined(
              filter.value,
              'filter.value should be defined for filter operator is not',
            );
            return concatSQLFiltersByOperator(
              [
                `${generateNotEqualsSQLFilter(`${tableName}.manufacturerContact`, filter.value)}`,
                `${generateNotEqualsSQLFilter(`${tableName}.customerContact`, filter.value)}`,
              ],
              SQLConcatOperator.AND,
            );
          case AdvancedFilterOperatorEnum.IS_EMPTY:
            return concatSQLFiltersByOperator(
              [
                `${generateIsEmptySQLFilter(`${tableName}.manufacturerContact`)}`,
                `${generateIsEmptySQLFilter(`${tableName}.customerContact`)}`,
              ],
              SQLConcatOperator.AND,
            );
          case AdvancedFilterOperatorEnum.IS_NOT_EMPTY:
            return concatSQLFiltersByOperator(
              [
                `${generateNotEmptySQLFilter(`${tableName}.manufacturerContact`)}`,
                `${generateNotEmptySQLFilter(`${tableName}.customerContact`)}`,
              ],
              SQLConcatOperator.OR,
            );
          default:
            throw new Error(
              `Operator ${filter.operator} can't be used for ${AssetsLegacyFilterFieldEnum.INVOLVED_CONTACT} filter type`,
            );
        }
      }
      default:
        throw new Error("Field can't be used as a filter identifier");
    }
  }
}
