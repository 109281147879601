import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  /** marginTop: set margin on top*/
  @Input() public marginTop: string = '30px';

  /** marginTop: set margin on top*/
  @Input() public marginBottom?: string;

  /** diameter: set diameter */
  @Input() public diameter: number = 20;

  /** strokeWidth: set strokeWidth for the circle */
  @Input() public strokeWidth: number = 3;

  /** loaderId: possible to overwrite for custom control */
  @Input() public loaderId?: string;

  /** materialColor: Set a material theme color. Defaults to primary. Will be overwritten if 'color' property is set  */
  @Input() public materialColor?: ThemePalette = 'primary';

  /** color: possible to overwrite the spinner color */
  @Input() public color?: string;

  /** loaderId: possible to overwrite for custom control */
  @Input() public position: 'center' | 'start' | 'end' = 'center';

  constructor(private ngxService: NgxUiLoaderService) {
    if (!this.loaderId) {
      this.loaderId = Math.round(Date.now() / 1000).toString();
    }
  }

  public ngOnInit(): void {
    if (this.loaderId) {
      this.ngxService.startLoader(this.loaderId);
    }
  }

  public ngOnDestroy(): void {
    if (this.loaderId) {
      this.ngxService.stopLoader(this.loaderId);
    }
  }
}
