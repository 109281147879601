<div class="checkbox-container">
  <mat-checkbox
    [class.checkbox-skip-ripple-size]="isInline"
    [checked]="checked$ | async"
    [disabled]="(disabled$ | async) || readonly || false"
    (change)="change($event)"
    [color]="color"
    labelPosition="after"
    (mouseenter)="hovered = true"
    (mouseleave)="hovered = false"
    [class.checkbox-error]="hasError$ | async"
    [class.checkbox-bottom-padding]="showBottomPadding && appearance === 'outline'"
    [class.checkbox-readonly]="readonly"
    [class.checkbox-disabled]="disabled"
    [class.checkbox-outline]="appearance === 'outline'"
    [class.checkbox-outline-hovered]="
      !readonly && (disabled$ | async) === false && appearance === 'outline' && hovered
    "
    [attr.data-test-id]="dataTestId"
  >
    <div class="checkbox-label">
      <div
        [class.mat-body]="!subLabel && !boldLabel"
        [class.mat-subtitle-2]="(appearance === 'outline' && subLabel) || boldLabel"
        [class.one-line-clamp]="appearance === 'outline' && subLabel"
        [class.two-line-clamp]="appearance === 'outline' && !subLabel"
        class="checkbox-label-main"
      >
        {{ label }}
        <sup *ngIf="required">*</sup>
      </div>
      <div *ngIf="appearance === 'outline' && subLabel" class="mat-body one-line-clamp">
        {{ subLabel }}
      </div>
    </div>

    <ng-content select="[slot=label]"></ng-content>
  </mat-checkbox>
  <mat-error
    *ngIf="appearance === 'outline' && (hasError$ | async)"
    class="mat-mdc-error-emulate mat-body-tiny"
  >
    <span *ngIf="hasError$ | async">{{
      checkErrorMessage ?? translations.defaultErrorMessage
    }}</span>
  </mat-error>
</div>
