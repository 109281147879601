import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AssetType } from '@remberg/assets/common/base';
import {
  AssetTypesCreateOneBody,
  AssetTypesFindManyByIdsBody,
  AssetTypesFindManyByLabelsBody,
  AssetTypesFindManyQuery,
  AssetTypesFindManyResponse,
  AssetTypesUpdateOneBody,
} from '@remberg/assets/common/main';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  FuzzyCount,
  LogService,
  filterNullOrUndefinedProps,
  getNumberCount,
} from '@remberg/global/ui';
import { Observable, from, map } from 'rxjs';
import {
  ASSET_TYPES_NEW_OFFLINE_SERVICE,
  AssetTypesNewOfflineServiceInterface,
} from './asset-types-new.offline.service.interface';

@Injectable({
  providedIn: 'root',
})
export class AssetTypesNewService {
  public readonly assetsUrl = `${API_URL_PLACEHOLDER}/assets/v1`;
  public readonly assetTypesUrl = `${this.assetsUrl}/types`;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly logger: LogService,
    @Inject(ASSET_TYPES_NEW_OFFLINE_SERVICE)
    private readonly assetTypesNewOfflineService: AssetTypesNewOfflineServiceInterface,
  ) {}

  public createOne(body: AssetTypesCreateOneBody): Observable<AssetType> {
    return this.httpClient.post<AssetType>(this.assetTypesUrl, body);
  }

  public updateOne(assetTypeId: string, body: AssetTypesUpdateOneBody): Observable<AssetType> {
    return this.httpClient.patch<AssetType>(`${this.assetTypesUrl}/${assetTypeId}`, body);
  }

  public findOne(assetTypeId: string): Observable<AssetType> {
    return this.httpClient.get<AssetType>(`${this.assetTypesUrl}/${assetTypeId}`);
  }

  public findMany(
    query: AssetTypesFindManyQuery,
  ): Observable<AssetTypesFindManyResponse & { count: FuzzyCount }> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online asset types (new) instances request...');
      const params = new HttpParams({
        fromObject: filterNullOrUndefinedProps({ ...query }),
      });

      return this.httpClient.get<AssetTypesFindManyResponse>(this.assetTypesUrl, { params });
    }
    this.logger.debug()('Offline asset types (new) instances request...');
    return from(
      this.assetTypesNewOfflineService.getAssetTypesWithCount({
        limit: query.limit,
        offset: query.page,
        sortDirection: query.sortDirection,
        sortColumn: query.sortField,
        searchValue: query.search,
      }),
    ).pipe(
      map(({ count, data }) => ({
        count: (count && getNumberCount(count)) || 0,
        assetTypes: data,
      })),
    );
  }

  public findManyByIds(body: AssetTypesFindManyByIdsBody): Observable<AssetType[]> {
    return this.httpClient.put<AssetType[]>(this.assetTypesUrl, body);
  }

  public findManyByLabels(body: AssetTypesFindManyByLabelsBody): Observable<AssetType[]> {
    return this.httpClient.put<AssetType[]>(`${this.assetTypesUrl}/labels`, body);
  }

  public updateImage(assetTypeId: string, image: File): Observable<AssetType> {
    const formData = new FormData();
    formData.append('image', image, encodeURIComponent(image.name));
    return this.httpClient.patch<AssetType>(`${this.assetTypesUrl}/${assetTypeId}/image`, formData);
  }

  public deleteOne(assetTypeId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.assetTypesUrl}/${assetTypeId}`);
  }
}
