import { MultiLanguageText, RembergDate } from '@remberg/global/common/core';
import {
  AutofillTypeEnum,
  ConfigWithDynamicActions,
  ConfigWithPrefilling,
} from '../../../automation';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DateInputField
  extends Field<FormFieldTypesEnum.DATE_INPUT, DateInputFieldConfig> {}

export interface DateInputFieldConfig
  extends ConfigWithPrefilling<DateInputPrefillTargetsEnum>,
    ConfigWithDynamicActions {
  disabled?: boolean;
  required?: boolean;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  label: {
    text: MultiLanguageText;
    uiHide?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value?: {
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
    validators?: DateInputValidatorConfig;
    autofill?: AutofillTypeEnum;
  };
}

export interface DateInputValidatorConfig {
  minDate?: RembergDate;
  maxDate?: RembergDate;
}

export enum DateInputPrefillTargetsEnum {
  VALUE = 'value',
}

export interface DateInputFieldData {
  date?: RembergDate;
}
