import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationBasic } from '@remberg/crm/common/base';
import { OrganizationsService } from '@remberg/crm/ui/clients';
import { UnknownOr, isUnknownObject } from '@remberg/global/common/core';
import {
  ApiResponse,
  EmptyListType,
  MultiSelectFunctions,
  MultiSelectModalOptionLabel,
} from '@remberg/global/ui';
import { Observable, map, of, take } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../../store';
import {
  BaseMultiselectComponent,
  BaseMultiselectComponentTranslations,
} from '../base-multiselect/base-multiselect.component';

@Component({
  selector: 'app-organization-multiselect',
  templateUrl: '../base-multiselect/base-multiselect.component.html',
  styleUrls: ['../base-multiselect/base-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationMultiselectComponent extends BaseMultiselectComponent<
  UnknownOr<OrganizationBasic>
> {
  @Input() public readonly titleIcon: string = 'domain';
  @Input() public readonly translations: BaseMultiselectComponentTranslations = {
    title: $localize`:@@organizations:Organizations`,
  };

  protected readonly emptyListType: EmptyListType = 'organizations';

  private getModalOptionLabel = (
    company: UnknownOr<OrganizationBasic>,
  ): MultiSelectModalOptionLabel => ({
    label: isUnknownObject(company) ? '' : `${company.name}`,
    subLabel: isUnknownObject(company) ? '' : company.externalReference,
  });

  private getOptionLabel = (company: UnknownOr<OrganizationBasic>): string =>
    this.getModalOptionLabel(company).label;

  private getChipLabel = (company: UnknownOr<OrganizationBasic>): string =>
    this.getOptionLabel(company);

  private getChipLink = (company: UnknownOr<OrganizationBasic>): Observable<string> => {
    if (isUnknownObject(company)) {
      return of('');
    }
    return this.store.select(GlobalSelectors.selectOrganizationDetailPageLink(company._id)).pipe(
      take(1),
      map((link) => link ?? ''),
    );
  };

  private fetchItemsWithCount = (
    search: string,
    pageSize: number,
    pageOffset: number,
  ): Observable<ApiResponse<OrganizationBasic[]>> =>
    this.organizationsService
      .findManyWithCountBasic({
        search,
        page: pageOffset,
        limit: pageSize,
      })
      .pipe(
        map((res) => ({
          data: res.organizations,
          count: res.count,
        })),
      );

  protected readonly functions: MultiSelectFunctions<UnknownOr<OrganizationBasic>> = {
    getChipLabel: this.getChipLabel,
    getChipLink: this.getChipLink,
    getOptionLabel: this.getOptionLabel,
    getModalOptionLabel: this.getModalOptionLabel,
    fetchListValuesWithCountFunction: this.fetchItemsWithCount,
  };

  constructor(
    private readonly organizationsService: OrganizationsService,
    private readonly store: Store<RootGlobalState>,
  ) {
    super();
  }
}
