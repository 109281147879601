import { ToastrService } from 'ngx-toastr';
/**
 * Displays a toastr message depending on the error through by the onError method of the dropZone
 * @param  {ToastrService} toastr - ToastrService
 * @param  {string} error event[1] - error through by the onError method
 * @returns void
 */

export enum DropzoneErrorMessage {
  WrongType = "You can't upload files of this type.",
  TooManyFiles = 'You can not upload any more files.',
  TooBigFile = 'File is too big', // e.g. File is too big (117.73MiB). Max filesize: 25MiB.
}

export function showDropZoneOnError(toastr: ToastrService, errorMessage: string): void {
  if (errorMessage === DropzoneErrorMessage.WrongType) {
    toastr.error(
      $localize`:@@youCantUploadFilesOfThisType:You can\'t upload files of this type!`,
      $localize`:@@error:Error`,
    );
  } else if (errorMessage === DropzoneErrorMessage.TooManyFiles) {
    toastr.error(
      $localize`:@@youCantUploadAnyMoreFiles:You can\'t upload any more files!`,
      $localize`:@@error:Error`,
    );
  } else if (errorMessage?.includes(DropzoneErrorMessage.TooBigFile)) {
    toastr.error(
      $localize`:@@selectedFileIsTooBig:Selected file is too large!`,
      $localize`:@@error:Error`,
    );
  } else {
    toastr.error(
      $localize`:@@somethingWentWrongWhileUploadingTheFile:Something went wrong while uploading the file.`,
      $localize`:@@error:Error`,
    );
  }
}
