<button
  mat-stroked-button
  (click)="onSortClicked()"
  class="toolbar-item"
  data-target="sortButton"
  data-test-id="sort-dialog-button"
  [matBadge]="1"
  [matBadgeHidden]="!sortDirection || !sortField"
>
  <mat-icon>sort</mat-icon>
  <span i18n="@@sort" class="icon-aligner-responsive">Sort</span>
</button>
