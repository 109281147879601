import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { UserRightsService } from '../services/user-rights.service';

const isPermitted = <T extends string>(action: T): boolean => {
  const userRightsService = inject(UserRightsService);
  return userRightsService.getPermission(action);
};

export const canMatchPermission =
  <T extends string>(action: T, ifPermitted = true): CanMatchFn =>
  () =>
    ifPermitted ? isPermitted(action) : !isPermitted(action);

export const canMatchOneOfPermissions =
  <T extends string>(actions: T[] = [], ifPermitted = true): CanMatchFn =>
  () =>
    actions.some((action) => (ifPermitted ? isPermitted(action) : !isPermitted(action)));
