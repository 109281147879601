import { DefaultKnownDataTypeScriptTypes, KnownDataTypeEnum, TypeFor } from './known-data-types';

export const KNOWN_PREFILLING_INPUTS = {
  assetId: KnownDataTypeEnum.ASSET_ID,
  // TODO Dmitry: rename to relatedOrganizationId
  organizationId: KnownDataTypeEnum.ORGANIZATION_ID,
  creationDateTime: KnownDataTypeEnum.REMBERG_DATETIME,
  currentUser: KnownDataTypeEnum.CONTACT_BASIC,
  currentUserId: KnownDataTypeEnum.CONTACT_ID,
  currentAccount: KnownDataTypeEnum.ORGANIZATION,
  currentAccountId: KnownDataTypeEnum.ORGANIZATION_ID,
  assignedUserId: KnownDataTypeEnum.CONTACT_ID,
  workOrderId: KnownDataTypeEnum.WORK_ORDER_ID,
  none: KnownDataTypeEnum.NONE,
} as const;

type KnownPrefillingInputs = typeof KNOWN_PREFILLING_INPUTS;

export type PrefillingInputName = keyof KnownPrefillingInputs;

export type PrefillingInputs = {
  [Name in Exclude<PrefillingInputName, 'none'>]?: TypeFor<
    KnownPrefillingInputs[Name],
    DefaultKnownDataTypeScriptTypes
  >;
};
