<div
  class="row-wrapper {{ toastPackage.toastType | materialIcons }}"
  [style.display]="state().value === 'inactive' ? 'none' : ''"
  data-test-id="toastr"
  [ngClass]="{
    'progress-bar-padding': options.progressBar && !options.disableTimeOut,
  }"
>
  <!-- Left Side -->
  <div class="toast-header-wrapper {{ toastPackage.toastType | materialIcons }}">
    <div class="toast-header-title">
      <mat-icon
        *ngIf="(toastPackage.toastType | materialIcons) !== ''"
        matPrefix
        class="icon-color {{ toastPackage.toastType | materialIcons }} large-icon"
      >
        {{ toastPackage.toastType | materialIcons }}
      </mat-icon>
    </div>
  </div>

  <!-- Right Side -->
  <div class="toast-content-wrapper {{ toastPackage.toastType | materialIcons }}">
    <div class="content-text">
      <div
        *ngIf="(message || (!message && title)) && options.enableHtml"
        role="alert"
        aria-live="polite"
        [class]="options.messageClass"
        [innerHTML]="message ? message : title"
      ></div>
      <div
        class="bold-message"
        *ngIf="(message || (!message && title)) && !options.enableHtml"
        role="alert"
        aria-live="polite"
        [class]="options.messageClass"
        [attr.aria-label]="message ? message : title"
      >
        {{ message ? message : title }}
      </div>
    </div>
    <a *ngIf="options.closeButton" (click)="removeToaster()" class="close-button">
      <mat-icon matSuffix inline class="icon-close">close</mat-icon>
    </a>
  </div>
</div>

<!-- Progress Bar -->
<div *ngIf="options.progressBar && !options.disableTimeOut" class="progress-bar">
  <div
    class="toast-progress"
    [ngClass]="(toastPackage.toastType | materialIcons) ?? ''"
    [style.width]="width() + '%'"
  ></div>
</div>
