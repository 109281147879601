<span>
  <!-- For mobile trigger modal -->
  <ng-container *ngIf="layout.isXSmallView | async; else bigScreen">
    <button
      mat-icon-button
      color="primary"
      (click)="openNotificationModal()"
      data-test-id="notifications-button"
    >
      <mat-icon
        class="material-icons-outlined notification-icon"
        [matBadge]="displayedNotificationsCount"
        matBadgePosition="above after"
        [matBadgeHidden]="!displayedNotificationsCount"
      >
        notifications
      </mat-icon>
    </button>
  </ng-container>
  <!-- For big screen trigger menu -->
  <ng-template #bigScreen>
    <button
      mat-icon-button
      color="primary"
      [matTooltip]="translation"
      [matMenuTriggerFor]="menuNotifications"
      data-test-id="notifications-button"
    >
      <mat-icon
        [matBadge]="displayedNotificationsCount"
        matBadgePosition="above after"
        [matBadgeHidden]="!displayedNotificationsCount"
        class="material-icons-outlined notification-icon"
      >
        notifications
      </mat-icon>
    </button>
    <!-- CSS class for the mat-menu is defined globally in the theme.scss -->
    <mat-menu #menuNotifications="matMenu" class="notification-panel">
      <app-notifications-popup
        [notificationCenterTrigger]="notificationCenterTrigger"
        [notifications]="notifications"
      >
      </app-notifications-popup>
    </mat-menu>
  </ng-template>
</span>
