<mat-card appearance="outlined" class="login-card center" ngClass.lt-sm="landing-card">
  <mat-card-header class="mat-card-header-custom">
    <mat-card-title i18n="@@updatePassword">Update Password</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p i18n="@@yourPasswordIsAboutToExpireLongText">
      Your password is about to expire. You can to set a new one now. After your password has
      expired you have to request a new one through the forgot password interface.
    </p>
    <form
      ng-submit="setPwd()"
      *ngIf="passwordExpiredFormGroup"
      (keydown)="updatePassword($event)"
      [formGroup]="passwordExpiredFormGroup"
    >
      <div class="form-container">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@currentPassword">Current Password</mat-label>
          <input
            matInput
            type="password"
            name="currentPasswordassword"
            autocomplete="current-password"
            formControlName="currentPassword"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label i18n="@@newPassword">New Password</mat-label>
          <input
            matInput
            type="password"
            name="password"
            autocomplete="new-password"
            formControlName="password"
            required
          />
          <mat-error
            *ngIf="
              passwordExpiredFormGroup.controls.password.errors
                | getFormErrorMessage: passwordErrorMessages as errorMsg
            "
          >
            {{ errorMsg }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label i18n="@@confirmPassword">Confirm Password</mat-label>
          <input
            matInput
            type="password"
            name="passwordConfirm"
            autocomplete="new-password"
            formControlName="passwordConfirm"
            required
          />
          <mat-error
            *ngIf="
              passwordExpiredFormGroup.controls.passwordConfirm.errors
                | getFormErrorMessage: passwordErrorMessages as errorMsg
            "
          >
            {{ errorMsg }}
          </mat-error>
        </mat-form-field>
        <button
          mat-flat-button
          (click)="updatePassword($event)"
          type="submit"
          color="primary"
          [disabled]="passwordExpiredFormGroup?.pristine || passwordExpiredFormGroup?.invalid"
          i18n="@@submit"
          class="landing-button"
        >
          Submit
        </button>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions fxLayoutAlign="end">
    <button mat-flat-button (click)="moveToLoggedInPage()" i18n="@@skip">Skip</button>
  </mat-card-actions>
</mat-card>
