import { ContactBasic } from '@remberg/crm/common/base';
import { FilterType, isUnknownObject, UnknownOr } from '@remberg/global/common/core';
import { BaseModel, PredefinedDialogOption } from '@remberg/global/ui';

export function createViewableContactOptions(
  predefinedOptions: PredefinedDialogOption[],
  loadedOptions: ContactBasic[],
  currentContact: ContactBasic | undefined,
  selectedOption: UnknownOr<ContactBasic> | BaseModel | undefined,
  filter: FilterType<string> | undefined,
): UnknownOr<ContactBasic>[] {
  // Steps for creating the viewable options:
  // - add the predefined options if any
  // - add the selected option if any
  // - add the current contact if any
  // - add the loaded options from the backend if any
  // the order needs to be preserved and no duplicates should appear in the list
  const allContactOptions: UnknownOr<ContactBasic>[] = [];

  // Add the predefined options
  allContactOptions.push(
    ...predefinedOptions.map((option) => predefinedOptionToContactBasic(option)),
  );

  // Add the selected option if it is a UnknownOr<ContactBasic> and NOT already part of the options array.
  if (
    (isContactBasic(selectedOption) || isUnknownObject(selectedOption)) &&
    !allContactOptions.find((item) => item._id === selectedOption._id)
  ) {
    allContactOptions.push(selectedOption);
  }

  // Add the current contact if NOT already part of the options array, and only if the contact meets the filter.
  if (
    currentContact &&
    !allContactOptions.find((item) => item._id === currentContact._id) &&
    (!filter ||
      filter.identifier !== 'organization' ||
      filter.value === currentContact.organizationId)
  ) {
    allContactOptions.push(currentContact);
  }

  // Add the loaded options
  if (loadedOptions) {
    for (const option of loadedOptions) {
      // If not already part of the options array.
      if (!allContactOptions.find((item) => item._id === option._id)) {
        allContactOptions.push(option);
      }
    }
  }

  return allContactOptions;
}

export function isContactBasic(
  contact: ContactBasic | BaseModel | undefined,
): contact is ContactBasic {
  return !!(contact as ContactBasic)?.organizationId;
}

export function predefinedOptionToContactBasic(option: PredefinedDialogOption): ContactBasic {
  return {
    _id: option.id,
    firstName: option.label,
    lastName: '',
    primaryEmail: '',
    organizationId: '',
  };
}
