import { Params, QueryParamsHandling } from '@angular/router';
import {
  AdvancedFilterConcatOperatorEnum,
  FilterParams,
} from '@remberg/advanced-filters/common/main';
import { VisualizationTypeEnum } from '@remberg/global/common/core';
import { MainRoutes, TableColumn } from '@remberg/global/ui';

export interface RedirectUrl {
  baseUrl: string[];
  params?: Params;
  replaceUrl?: boolean;
  queryParamsHandling?: QueryParamsHandling;
}

export function getRedirectUrl<F extends string, E extends FilterParams<F>>(
  redirectRoute: MainRoutes,
  { staticFilters, filterQuery, ...params }: E,
  displayedTableColumns: TableColumn<string>[],
  count: number,
  pageSize?: number,
): RedirectUrl | undefined {
  if (!count) return;
  return {
    baseUrl: ['/', redirectRoute],
    params: {
      ...params,
      ...(staticFilters && {
        filterQuery: JSON.stringify({
          filters: [...staticFilters, ...(filterQuery?.filters ?? [])],
          concatOperator: AdvancedFilterConcatOperatorEnum.AND,
        }),
      }),
      visualizationType: VisualizationTypeEnum.TABLE,
      ...(pageSize && { pageSize }),
      ...(displayedTableColumns.length && {
        displayedTableColumns: JSON.stringify(displayedTableColumns),
      }),
    },
  };
}
