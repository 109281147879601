import { CustomPropertyTypesEnum } from '@remberg/custom-properties/common/main';

export const CUSTOM_PROPERTY_TYPE_TO_ARRAY_MODEL_FIELD: {
  [key in CustomPropertyTypesEnum]: boolean;
} = {
  [CustomPropertyTypesEnum.INPUT_TEXT]: false,
  [CustomPropertyTypesEnum.TEXTAREA]: false,
  [CustomPropertyTypesEnum.URL]: false,
  [CustomPropertyTypesEnum.INPUT_NUMBER]: false,
  [CustomPropertyTypesEnum.CHECKBOX]: false,
  [CustomPropertyTypesEnum.SELECT]: true,
  [CustomPropertyTypesEnum.REMBERG_ASSET_SELECT]: true,
  [CustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT]: true,
  [CustomPropertyTypesEnum.REMBERG_CONTACT_SELECT]: true,
  [CustomPropertyTypesEnum.REMBERG_DATE]: false,
  [CustomPropertyTypesEnum.REMBERG_DATETIME]: false,
};
