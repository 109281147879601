import { isUnknownObject, UnknownOr } from '@remberg/global/common/core';
import { BaseModel, PredefinedDialogOption } from '@remberg/global/ui';
import { WorkOrder } from '@remberg/work-orders-legacy/common/main';

export function createViewableWorkOrderOptions(
  predefinedOptions: PredefinedDialogOption[],
  loadedOptions: WorkOrder[],
  selectedOption: UnknownOr<WorkOrder> | BaseModel | undefined,
) {
  // Steps for creating the viewable options:
  // - add the predefined options if any
  // - add the loaded options from the backend if any
  // - add the selected option if any
  // the order needs to be preserved and no duplicates should appear in the list
  const allWorkOrderOptions: UnknownOr<WorkOrder>[] = [];

  // Add the predefined options
  allWorkOrderOptions.push(
    ...predefinedOptions.map((option) => predefinedOptionToWorkOrder(option)),
  );

  // Add the selected option if it is valid WorkOrder and NOT already part of the options array.
  if (
    (isWorkOrder(selectedOption) || isUnknownObject(selectedOption)) &&
    !isOptionInList(selectedOption, allWorkOrderOptions)
  ) {
    allWorkOrderOptions.push(selectedOption);
  }

  // Add the loaded options
  for (const option of loadedOptions) {
    // Add the selected option if it is NOT already part of the options array.
    if (!isOptionInList(option, allWorkOrderOptions)) {
      allWorkOrderOptions.push(option);
    }
  }

  return allWorkOrderOptions;
}

export function isWorkOrder(workOrder: WorkOrder | BaseModel | undefined): workOrder is WorkOrder {
  return !!(workOrder as WorkOrder)?.workOrderID;
}

function predefinedOptionToWorkOrder(option: PredefinedDialogOption): WorkOrder {
  return {
    _id: option.id,
    title: option.label,
  } as WorkOrder;
}

function isOptionInList(option: UnknownOr<WorkOrder>, list: UnknownOr<WorkOrder>[]): boolean {
  return list.some((item) => item._id === option._id);
}
