import { FileFormatEnum } from '../definitions';

export enum ImageSizeDictEnum {
  SMALL = 60,
  MEDIUM = 120,
  LARGE = 300,
  DEFAULT = 1000,
}

export const ImageSizeDict = {
  [FileFormatEnum.SMALL]: [ImageSizeDictEnum.SMALL, ImageSizeDictEnum.SMALL],
  [FileFormatEnum.MEDIUM]: [ImageSizeDictEnum.MEDIUM, ImageSizeDictEnum.MEDIUM],
  [FileFormatEnum.LARGE]: [ImageSizeDictEnum.LARGE, ImageSizeDictEnum.LARGE],
  [FileFormatEnum.DEFAULT]: [ImageSizeDictEnum.DEFAULT, ImageSizeDictEnum.DEFAULT],
};

export type ImageSizeDictType = Partial<typeof ImageSizeDict>;
