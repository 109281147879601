<button mat-stroked-button [matMenuTriggerFor]="languageMenu">
  <mat-icon>language</mat-icon>
  <span class="langText">{{ getLanguageText(currentLanguage) }}</span>
</button>

<mat-menu #languageMenu="matMenu" class="" xPosition="before">
  <button
    mat-menu-item
    *ngFor="let langOpt of availableLanguages"
    [disabled]="langOpt === currentLanguage"
    [ngClass]="{ 'selected-language': langOpt === currentLanguage }"
    attr.data-test-id="lang-{{ langOpt }}"
    (click)="onSelectLanguage(langOpt)"
  >
    {{ getLanguageText(langOpt) }}
  </button>
</mat-menu>
