import { RembergDateTime, RembergTimestamp } from '../definitions';
import { TimeZoneEnum } from '../enums';
import { isTimeZoneEnum } from './is-time-zone-enum';

export function parseRembergDateTime(
  value: RembergDateTime,
): [RembergTimestamp, TimeZoneEnum | undefined];
export function parseRembergDateTime(
  value: RembergDateTime | undefined,
): [RembergTimestamp | undefined, TimeZoneEnum | undefined];
export function parseRembergDateTime(
  value: RembergDateTime | undefined,
): [RembergTimestamp | undefined, TimeZoneEnum | undefined] {
  const [utc, ...timeZoneParts] = (value ?? '').split('_');

  const timeZone = timeZoneParts.length ? timeZoneParts.join('_') : undefined;

  return [
    utc ? (utc as RembergTimestamp) : undefined,
    isTimeZoneEnum(timeZone) ? timeZone : undefined,
  ];
}
