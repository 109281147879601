import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LogService } from '@remberg/global/ui';
import { EMPTY, from } from 'rxjs';
import { catchError, exhaustMap, filter, switchMap } from 'rxjs/operators';
import { IntercomService, IntercomSetupOptions } from '../../services/intercom.service';
import { GlobalActions } from './global.actions';

@Injectable()
export class GlobalIntercomEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly intercomService: IntercomService,
    private readonly logger: LogService,
  ) {}

  public readonly setupIntercom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.completeRegisterSessionInfo),
        filter((action) => !action.rembergUser.isRembergAdmin),
        exhaustMap((action) =>
          from(this.intercomService.fetchIntercomHashes()).pipe(
            switchMap((intercomHashes) => {
              const intercomOptions: IntercomSetupOptions = {
                contactId: action.contact._id,
                email: action.rembergUser.email,
                firstName: action.contact.crmData.firstName,
                lastName: action.contact.crmData.lastName,
                intercomHashes,
                lang: action.rembergUser.lang,
                isTenantOwner: action.rembergUser.isTenantOwner,
                organizationId: action.organization?._id,
                isSupportEnabled: action.tenant?.isSupportEnabled,
                subdomain: action.tenant?.subdomain,
                tenantLabel: action.organization?.crmData.name,
                prmVersion: action.tenant?.trackingInfo,
                userRole: action.userRole,
              };

              return this.intercomService.setupIntercom(intercomOptions);
            }),
          ),
        ),
        catchError((error) => {
          this.logger.error()('Error during intercom setup', error);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  public readonly shutdownIntercom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.logoutComplete),
        switchMap(() => this.intercomService.shutdownIntercom()),
        catchError((error) => {
          this.logger.error()('Error during intercom shutdown', error);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  public readonly showIntercom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.showIntercom),
        switchMap(() => this.intercomService.showIntercom()),
        catchError((error) => {
          this.logger.error()('Error happened trying to show intercom', error);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );
}
