import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { LayoutService } from '@remberg/ui-core/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
  providers: [NgxUiLoaderService],
})
export class ImageLoaderComponent implements OnInit {
  srcValue?: string | SafeUrl | null;

  @ViewChild('image') imageRef?: ElementRef;

  @Input() srcFallback?: string;

  @Input() dataUrl?: string | ArrayBuffer;

  @Input() set src(src: string | SafeUrl | undefined | null) {
    this.srcValue = src;
    if (this.srcValue) {
      if (this.loaded) {
        this.loaded = false;
        this.ngxService.startLoader('img-loader');
      }
    } else {
      this.srcValue = this.srcFallback;
    }
  }
  loaded: boolean = false;

  constructor(
    private ngxService: NgxUiLoaderService,
    public layout: LayoutService,
  ) {}

  ngOnInit(): void {
    if (this.srcValue) {
      this.ngxService.startLoader('img-loader');
    }
  }

  onLoad(): void {
    this.ngxService.stopLoader('img-loader');
    this.loaded = true;
  }
}
