import { isObjectId } from '@remberg/global/common/core';
import { CustomPropertyKey } from '../custom-property-key';
import { CustomPropertyTypesEnum } from '../enums';

export function isCustomPropertyKey(propertyKey: string): propertyKey is CustomPropertyKey {
  const cProp: string[] = propertyKey.split('-');
  const [prefix, propertyType, objectId] = cProp;
  return (
    cProp.length === 3 &&
    prefix === 'cProp' &&
    (propertyType ? isCustomPropertyTypeEnum(propertyType) : false) &&
    (objectId ? isObjectId(objectId) : false)
  );
}

export function isCustomPropertyTypeEnum(x?: string): x is CustomPropertyTypesEnum {
  return Object.values(CustomPropertyTypesEnum).includes(x as CustomPropertyTypesEnum);
}
