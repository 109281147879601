import { OperatorFunction, concatMap, of } from 'rxjs';

/**
 * Takes a function that uses the value from existing operator composition to
 * create a new OperatorFunction. Useful when the desired OperatorFunction
 * requires a value from the preceeding stream as an argument
 *
 * @param operatorFactory A function that uses the value from the existing
 * stream to return the desired OperatorFunction
 */
export function concatOperatorWith<T, V>(
  operatorFactory: (value: V) => OperatorFunction<V, T>,
): OperatorFunction<V, T> {
  return concatMap((value) => {
    const operatorFunction = operatorFactory(value);
    return of(value).pipe(operatorFunction);
  });
}
