import { InjectionToken } from '@angular/core';

import { TrackingEventEnum, TrackingInfoOptions } from '@remberg/analytics/common/main';

export interface AnalyticsTrackingServiceInterface {
  trackEvent(eventName: TrackingEventEnum, eventOptions?: TrackingInfoOptions): Promise<void>;
}

export const TRACKING_SERVICE = new InjectionToken<AnalyticsTrackingServiceInterface>(
  'Token for injecting tracking service in api libs without creating circular dependencies',
);
