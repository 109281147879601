import { InjectionToken } from '@angular/core';
import { Asset, Product } from '@remberg/assets/common/main';
import { ApiResponse, OfflinePopulateType } from '@remberg/global/ui';
import { AssetsLegacyFindManyOfflineQueryInterface } from './assets-legacy.definitions';

export interface AssetsOfflineServiceInterface {
  tryGetInstance(params: {
    id: string | Product | Asset;
    populate?: OfflinePopulateType;
  }): Promise<Product | undefined>;

  getInstance(params: {
    id: string | Product | Asset;
    populate?: OfflinePopulateType;
  }): Promise<Product>;

  getAssetsWithCount(
    options: AssetsLegacyFindManyOfflineQueryInterface,
  ): Promise<ApiResponse<Product[]>>;

  getManyByIds(ids: string[], populate?: OfflinePopulateType): Promise<Product[]>;
}

export const ASSETS_OFFLINE_SERVICE = new InjectionToken<AssetsOfflineServiceInterface>(
  'Token for injecting assets offline service without circular dependencies',
);
