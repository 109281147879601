<div *ngIf="filter">
  <mat-divider *ngIf="first" class="divider"></mat-divider>

  <div class="text-particle-wrapper">
    <span *ngIf="first" i18n="@@Where">Where</span>
    <span *ngIf="!first" i18n="@@And">And</span>
    <button
      mat-button
      color="warn"
      attr.data-test-id="remove-{{ filter.identifier }}"
      i18n="@@Remove"
      (click)="remove.emit()"
    >
      Remove
    </button>
  </div>

  <app-remberg-simple-select
    [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
    [initialOption]="filter.identifier"
    (onOptionChanged)="filterIdentifierChange($event)"
    [options]="availableFilterFieldOptionsAsArray"
    [dataTestId]="'identifier-select-' + filter.identifier"
  />

  <span class="text-particle" i18n="@@is">Is</span>

  <!-- Filter Value Select -->
  <ng-container [ngSwitch]="filterFieldOptions?.[filter.identifier]?.type">
    <app-organization-basic-select
      *ngSwitchCase="FilterTypeEnum.ORGANIZATION"
      appearance="outline"
      [organization]="filter.value"
      [fullWidth]="true"
      [panelWidth]="'auto'"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (changedOrganization)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-tenant-select
      *ngSwitchCase="FilterTypeEnum.TENANT"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [fullWidth]="true"
      [panelWidth]="'auto'"
      (onTenantChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-compatibility-asset-type-select
      *ngSwitchCase="FilterTypeEnum.ASSET_TYPE"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [fullWidth]="true"
      [panelWidth]="'auto'"
      (onAssetTypeChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-compatibility-asset-select
      *ngSwitchCase="FilterTypeEnum.ASSET"
      appearance="outline"
      [initialProductId]="filter.value"
      [patchAsset]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [fullWidth]="true"
      [panelWidth]="'auto'"
      [placeholderLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (onAssetChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-contact-basic-select
      *ngSwitchCase="FilterTypeEnum.CONTACT"
      appearance="outline"
      [contact]="
        filter | getFilterValue: filterFieldOptions?.[filter.identifier]?.anyAndNoneOptions ?? false
      "
      [required]="false"
      [hideHeader]="true"
      [fullWidth]="true"
      [panelWidth]="'auto'"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (changedContact)="filterValueChange($event._id)"
      [organizationType]="filterFieldOptions?.[filter.identifier]?.organizationType"
      [allowNoneSelection]="filterFieldOptions?.[filter.identifier]?.anyAndNoneOptions ?? false"
      [allowAnySelection]="filterFieldOptions?.[filter.identifier]?.anyAndNoneOptions ?? false"
      [dataTestId]="filter.identifier"
    />

    <app-support-email-select
      *ngSwitchCase="FilterTypeEnum.SUPPORT_EMAIL"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (onSupportEmailChange)="filterValueChange($event)"
      [dataTestId]="filter.identifier"
    />

    <app-case-select
      *ngSwitchCase="FilterTypeEnum.CASE"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (caseChanged)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-case-category-select
      *ngSwitchCase="FilterTypeEnum.CASE_CATEGORY"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (onCaseCategoryChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-maintenance-plan-select
      *ngSwitchCase="FilterTypeEnum.MAINTENANCE_PLAN"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (onMaintenancePlanChange)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <app-work-order-status-select
      *ngSwitchCase="FilterTypeEnum.WORK_ORDER_STATUS"
      [initialValueId]="filter.value"
      [label]="filterFieldOptions?.[filter.identifier]?.label"
      (changedStatus)="filterValueChange($event._id)"
    />

    <app-work-order-type-select
      *ngSwitchCase="FilterTypeEnum.WORK_ORDER_TYPE"
      [initialValueId]="filter.value"
      [label]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (changedType)="filterValueChange($event._id)"
    />

    <app-user-group-select
      *ngSwitchCase="FilterTypeEnum.USER_GROUP"
      appearance="outline"
      [initialValueId]="filter.value"
      [required]="false"
      [hideHeader]="true"
      [limitWidth]="true"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      (userGroupChanged)="filterValueChange($event._id)"
      [dataTestId]="filter.identifier"
    />

    <mat-form-field
      *ngSwitchCase="FilterTypeEnum.STATIC_SELECT"
      appearance="outline"
      class="my-mat-form-field my-mat-form-field-outline"
      attr.data-test-id="{{ filter.identifier }}"
    >
      <mat-label>{{ filterFieldOptions?.[filter.identifier]?.label }}</mat-label>
      <mat-select
        [value]="filter.value"
        attr.data-test-id="value-select-{{ filter.identifier }}"
        disableOptionCentering
        [panelClass]="'dropdown-select-panel-set-width'"
        (selectionChange)="filterValueChange($event.value)"
      >
        <mat-option
          *ngFor="let filterValueOption of filterFieldOptions?.[filter.identifier]?.options"
          [value]="filterValueOption.identifier"
          attr.data-test-id="option-key-{{ filterValueOption.identifier }}"
        >
          {{ filterValueOption.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-remberg-simple-select
      *ngSwitchCase="FilterTypeEnum.ENUM"
      [fieldLabel]="filterFieldOptions?.[filter.identifier]?.label ?? ''"
      [initialOption]="filter.value"
      (onOptionChanged)="filterValueChange($event)"
      [options]="filterFieldOptions?.[filter.identifier]?.options"
      [dataTestId]="filter.identifier"
    />

    <mat-form-field
      *ngSwitchCase="FilterTypeEnum.BOOLEAN"
      appearance="outline"
      class="my-mat-form-field my-mat-form-field-outline"
      attr.data-test-id="{{ filter.identifier }}"
    >
      <mat-label>{{ filterFieldOptions?.[filter.identifier]?.label }}</mat-label>
      <mat-select
        [value]="filter.value"
        attr.data-test-id="value-select-{{ filter.identifier }}"
        disableOptionCentering
        [panelClass]="'dropdown-select-panel-set-width'"
        (selectionChange)="filterValueChange($event.value)"
      >
        <mat-option
          *ngFor="let filterValueOption of booleanFilterValueOptions"
          [value]="filterValueOption.value"
          attr.data-test-id="option-key-{{ filterValueOption.value }}"
        >
          {{ filterValueOption.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-date-picker
      *ngSwitchCase="FilterTypeEnum.DATE"
      [name]="filter.identifier"
      [label]="filterFieldOptions?.[filter.identifier]?.label"
      [ngModel]="filter.value"
      [isClearButtonHidden]="true"
      (ngModelChange)="filterValueChange($event)"
      data-test-id="{{ filter.identifier }}"
    />

    <app-text-input
      *ngSwitchCase="FilterTypeEnum.TEXT"
      [dataTestId]="filter.identifier"
      [label]="filterFieldOptions?.[filter.identifier]?.label"
      [value]="filter.value"
      (changedValue)="filterValueChange($event)"
    />

    <app-partial-location-select
      *ngSwitchCase="FilterTypeEnum.CITY"
      [type]="AdvancedFilterTypeEnum.CITY"
      appearance="outline"
      [required]="false"
      [hideHeader]="true"
      [fullWidth]="true"
      [panelWidth]="'auto'"
      (partialLocationChange)="filterValueChange($event)"
      partialLocation="{{ filter?.value }}"
      [dataTestId]="filter.identifier"
    />

    <app-partial-location-select
      *ngSwitchCase="FilterTypeEnum.COUNTRY"
      [type]="AdvancedFilterTypeEnum.COUNTRY"
      appearance="outline"
      [required]="false"
      [hideHeader]="true"
      [fullWidth]="true"
      [panelWidth]="'auto'"
      (partialLocationChange)="filterValueChange($event)"
      partialLocation="{{ filter?.value }}"
      [dataTestId]="filter.identifier"
    />

    <app-partial-location-select
      *ngSwitchCase="FilterTypeEnum.PROVINCE"
      [type]="AdvancedFilterTypeEnum.PROVINCE"
      appearance="outline"
      [required]="false"
      [hideHeader]="true"
      [fullWidth]="true"
      [panelWidth]="'auto'"
      (partialLocationChange)="filterValueChange($event)"
      partialLocation="{{ filter?.value }}"
      [dataTestId]="filter.identifier"
    />
  </ng-container>

  <mat-divider class="divider"></mat-divider>
</div>
