import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { ProductType } from '@remberg/assets/common/base';
import { ApiResponse, OfflineService } from '@remberg/global/ui';
import { AssetTypesFindManyOfflineQueryInterface } from './asset-types.definitions';

export interface AssetTypesLegacyOfflineServiceInterface
  extends OfflineService<ProductType, AdvancedFilterQuery<never>, AdvancedFilter<never>> {
  getAssetTypesWithCount(
    options: AssetTypesFindManyOfflineQueryInterface,
  ): Promise<ApiResponse<ProductType[]>>;

  getManyByIds(ids: string[]): Promise<ProductType[]>;
}

export const ASSET_TYPES_LEGACY_OFFLINE_SERVICE =
  new InjectionToken<AssetTypesLegacyOfflineServiceInterface>(
    'Token for injecting asset types offline service without circular dependencies',
  );
