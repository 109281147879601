export enum ContactsFilterEnum {
  EMAIL_WHITELIST = 'emailWhitelist',
  ORGANIZATION_TYPE = 'organizationType',
  ORGANIZATION = 'organization',
  CREATED_BY = 'creatorId',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  EXCLUDE_OWN = 'excludeOwn',
  EXCLUDE_EXTERNAL = 'excludeExternal',
  USER_STATUS = 'userStatus',
}
