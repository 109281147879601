import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { GlobalActions } from './global.actions';

@Injectable()
export class GlobalBreakpointEffects {
  constructor(private breakpointObserver: BreakpointObserver) {}

  public readonly breakpoint$ = createEffect(() =>
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.HandsetPortrait,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,

        Breakpoints.Tablet,
        Breakpoints.TabletLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.Handset,
        Breakpoints.Web,
      ])
      .pipe(
        map(({ breakpoints }) => {
          // Phone Size
          const isXSmallView =
            breakpoints[Breakpoints.XSmall] || breakpoints[Breakpoints.HandsetPortrait];
          // Larger Phone to Tablet
          const isSmallView = breakpoints[Breakpoints.Small];
          const isXSmallOrSmallView = isXSmallView || isSmallView;
          // Larger than Tablet
          const isMediumView = breakpoints[Breakpoints.Medium];
          const isLargeView = breakpoints[Breakpoints.Large];
          // Larger than regular desktop
          const isXLargeView = breakpoints[Breakpoints.XLarge];
          const isMediumOrLargeOrXLargeView = isMediumView || isLargeView || isXLargeView;

          return GlobalActions.breakpointsUpdated({
            breakpoints: {
              isXSmallView,
              isSmallView,
              isXSmallOrSmallView,
              isMediumOrLargeOrXLargeView,
              isMediumView,
              isLargeView,
              isXLargeView,
            },
          });
        }),
      ),
  );
}
