/**
 * Converts milliseconds to a readable human format with days, hours and minutes.
 * Automatically doesn't show values when they are 0.
 * @param timeInMs - The time to convert, in MS (1 second === 1000 ms)
 * @param showSeconds - Whether to show seconds (default: false)
 */
export function convertMsToReadableTime(timeInMs: number, showSeconds = false): string {
  if (timeInMs < 1000) {
    return `${timeInMs} ms`;
  }

  const totalSeconds = timeInMs / 1000;
  const totalMinutes = totalSeconds / 60;
  const totalHours = totalMinutes / 60;

  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor(totalMinutes % 60);
  const hours = Math.floor(totalHours % 24);
  const days = Math.floor(totalHours / 24);

  const output: (string | undefined)[] = [];
  output.push(days ? `${days} d` : undefined);
  output.push(hours ? `${hours} h` : undefined);
  output.push(minutes ? `${minutes} m` : undefined);
  if (showSeconds) {
    output.push(seconds ? `${seconds} s` : undefined);
  }

  return output.filter(Boolean).join(' ');
}
