import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LanguageCodeEnum } from '@remberg/global/common/core';
import {
  ApiResponse,
  LogService,
  SQLQueryParams,
  stringToSQLSortDirection,
  SyncDataTypesEnum,
} from '@remberg/global/ui';
import { WorkOrderType } from '@remberg/work-orders-legacy/common/main';
import { WorkOrderTypeOfflineServiceInterface } from '@remberg/work-orders-legacy/ui/clients';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

enum ColumnNamesEnum {
  ORDER_VALUE = 'orderValue',
  TYPE_LABELS = 'typeLabels',
}

const params: SQLQueryParams<string> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.WORKORDERTYPES,
  columns: {
    [ColumnNamesEnum.ORDER_VALUE]: {
      type: 'INTEGER',
      valueFunction: (val: WorkOrderType) => val?.orderValue,
    },
    [ColumnNamesEnum.TYPE_LABELS]: {
      type: 'TEXT',
      valueFunction: (val: WorkOrderType) => JSON.stringify(val.typeLabels),
    },
  },
};

@Injectable()
export class WorkOrderTypeOfflineService
  extends BaseOfflineService<WorkOrderType, never>
  implements WorkOrderTypeOfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, params, logger, store);
  }

  async getWorkOrderTypesWithCount(
    limit?: number,
    offset?: number,
    sortColumn?: string,
    sortDirection?: string,
    searchValue?: string,
    searchLocale?: LanguageCodeEnum,
  ): Promise<ApiResponse<WorkOrderType[]>> {
    // filters:
    const filterStrings = [];

    // search:
    if (searchValue && searchLocale) {
      filterStrings.push(
        `${params.tableName}.${ColumnNamesEnum.TYPE_LABELS} LIKE '%"${searchLocale}":"%${searchValue}%"'`,
      );
    }

    // sorting (must be mapped to the right column):
    const sqlSortDirection = stringToSQLSortDirection(sortDirection);
    if (sortColumn === ColumnNamesEnum.ORDER_VALUE) {
      sortColumn = ColumnNamesEnum.ORDER_VALUE;
    }

    return this.getInstancesWithCount(
      limit,
      offset,
      sortColumn,
      sqlSortDirection,
      filterStrings.join(' AND '),
    );
  }
}
