import {
  AddressInputFieldData,
  AssetSingleSelectFieldData,
  BooleanInputFieldData,
  ContactSingleSelectFieldData,
  DateInputFieldData,
  DateTimeInputFieldData,
  FormFieldTypesEnum,
  FormSectionTypesEnum,
  FormSignatureSectionData,
  MultiLineTextInputFieldData,
  OrganizationSingleSelectFieldData,
  PhoneNumberInputFieldData,
  RichTextInputFieldData,
  SingleLineTextInputFieldData,
  StaticMultiSelectFieldData,
  StaticSingleSelectFieldData,
  TimeInputFieldData,
} from '../form-sections';
import { KnownDataTypeEnum } from './known-data-types';

export type PossibleDfaSourceType =
  | FormSectionTypesEnum.SIGNATURE_SECTION
  | FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT
  | FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT
  | FormFieldTypesEnum.BOOLEAN_INPUT
  | FormFieldTypesEnum.ASSET_SINGLE_SELECT
  | FormFieldTypesEnum.ADDRESS_INPUT
  | FormFieldTypesEnum.RICH_TEXT_INPUT
  | FormFieldTypesEnum.DATE_INPUT
  | FormFieldTypesEnum.TIME_INPUT
  | FormFieldTypesEnum.DATE_TIME_INPUT
  | FormFieldTypesEnum.STATIC_SINGLE_SELECT
  | FormFieldTypesEnum.STATIC_MULTI_SELECT
  | FormFieldTypesEnum.PHONE_NUMBER_INPUT
  | FormFieldTypesEnum.CONTACT_SINGLE_SELECT
  | FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT;

export type PossibleDfaTargetType = PossibleDfaSourceType;

export type PossibleDfaTargetData = {
  [Type in PossibleDfaTargetType]: Type extends FormSectionTypesEnum.SIGNATURE_SECTION
    ? FormSignatureSectionData
    : Type extends FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT
      ? SingleLineTextInputFieldData
      : Type extends FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT
        ? MultiLineTextInputFieldData
        : Type extends FormFieldTypesEnum.BOOLEAN_INPUT
          ? BooleanInputFieldData
          : Type extends FormFieldTypesEnum.ASSET_SINGLE_SELECT
            ? AssetSingleSelectFieldData<true>
            : Type extends FormFieldTypesEnum.ADDRESS_INPUT
              ? AddressInputFieldData
              : Type extends FormFieldTypesEnum.RICH_TEXT_INPUT
                ? RichTextInputFieldData
                : Type extends FormFieldTypesEnum.DATE_INPUT
                  ? DateInputFieldData
                  : Type extends FormFieldTypesEnum.TIME_INPUT
                    ? TimeInputFieldData
                    : Type extends FormFieldTypesEnum.DATE_TIME_INPUT
                      ? DateTimeInputFieldData
                      : Type extends FormFieldTypesEnum.DATE_TIME_INPUT
                        ? DateTimeInputFieldData
                        : Type extends FormFieldTypesEnum.STATIC_SINGLE_SELECT
                          ? StaticSingleSelectFieldData
                          : Type extends FormFieldTypesEnum.STATIC_MULTI_SELECT
                            ? StaticMultiSelectFieldData
                            : Type extends FormFieldTypesEnum.PHONE_NUMBER_INPUT
                              ? PhoneNumberInputFieldData
                              : Type extends FormFieldTypesEnum.CONTACT_SINGLE_SELECT
                                ? ContactSingleSelectFieldData<true>
                                : Type extends FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT
                                  ? OrganizationSingleSelectFieldData<true>
                                  : never;
};

type DefaultPropertyNames = {
  [Type in PossibleDfaTargetType]: keyof PossibleDfaTargetData[Type] | undefined;
};

export const DFA_DEFAULT_TARGET_PROPERTY_NAMES: DefaultPropertyNames = {
  signatureSection: undefined,
  singleLineTextInput: 'text',
  multiLineTextInput: 'text',
  booleanInput: 'checked',
  assetSingleSelect: 'selectedAsset',
  addressInput: 'address',
  richTextInput: 'html',
  dateInput: 'date',
  timeInput: 'time',
  dateTimeInput: 'dateTime',
  staticSingleSelect: 'selectedValue',
  staticMultiSelect: 'selectedValues',
  userSingleSelect: 'user',
  companySingleSelect: 'selectedCompany',
  phoneNumberInput: undefined,
};

type PossibleSourceValueTypes = {
  [Type in PossibleDfaTargetType]:
    | KnownDataTypeEnum
    | ({
        [PropertyName in keyof Required<PossibleDfaTargetData[Type]>]: KnownDataTypeEnum;
      } & { __default__?: KnownDataTypeEnum });
};

export const DFA_SOURCE_VALUE_DATA_TYPES: PossibleSourceValueTypes = {
  signatureSection: {
    __default__: KnownDataTypeEnum.SIGNATURE,
    date: KnownDataTypeEnum.REMBERG_DATE,
    location: KnownDataTypeEnum.STRING,
    name: KnownDataTypeEnum.STRING,
    signature: KnownDataTypeEnum.STRING,
  },
  singleLineTextInput: KnownDataTypeEnum.STRING,
  multiLineTextInput: KnownDataTypeEnum.STRING,
  booleanInput: KnownDataTypeEnum.BOOLEAN,
  assetSingleSelect: KnownDataTypeEnum.ASSET_INFO,
  addressInput: KnownDataTypeEnum.ADDRESS,
  richTextInput: KnownDataTypeEnum.HTML_STRING,
  dateInput: KnownDataTypeEnum.REMBERG_DATE,
  timeInput: KnownDataTypeEnum.REMBERG_TIME,
  dateTimeInput: KnownDataTypeEnum.REMBERG_DATETIME,
  staticSingleSelect: KnownDataTypeEnum.STRING,
  staticMultiSelect: KnownDataTypeEnum.ARRAY_OF_STRINGS,
  userSingleSelect: KnownDataTypeEnum.CONTACT_BASIC,
  phoneNumberInput: KnownDataTypeEnum.PHONE_NUMBER,
  companySingleSelect: KnownDataTypeEnum.ORGANIZATION_BASIC,
};
