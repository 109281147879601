<ng-container
  *ngIf="{
    dataSource: dataSource$ | async,
    virtualScrollHeight: virtualScrollHeight$ | async,
    isLoading: isLoading$ | async,
    totalCount: totalCount$ | async,
    currentBatchSize: infiniteListContainerStore.batchSize$ | async
  } as observables"
>
  <ng-container *ngIf="!!observables.dataSource?.length || observables.isLoading; else placeholder">
    <cdk-virtual-scroll-viewport
      class="virtual-scroll-wrapper"
      [itemSize]="itemSize$ | async"
      (scrolledIndexChange)="fetchNextBatch()"
      [minBufferPx]="observables.virtualScrollHeight"
      [maxBufferPx]="observables.virtualScrollHeight"
      [ngStyle]="{ 'height.px': !isFullScreen && observables.virtualScrollHeight }"
      [ngClass]="{
        'overflow-hidden': !isFullScreen && observables.currentBatchSize === observables.totalCount
      }"
    >
      <ng-container
        *cdkVirtualFor="
          let data of observables.dataSource;
          let i = index;
          templateCacheSize: templateCacheSize;
          trackBy: trackByFn
        "
      >
        <ng-template
          [ngTemplateOutlet]="templateRef"
          [ngTemplateOutletContext]="{ $implicit: data, index: i }"
        ></ng-template>
      </ng-container>
      <app-loader *ngIf="observables.isLoading"></app-loader>
    </cdk-virtual-scroll-viewport>
  </ng-container>
</ng-container>

<ng-template #placeholder>
  <ng-content select="[slot=placeholder]"></ng-content>
</ng-template>
