import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-feature-offline-unavailable',
  templateUrl: './feature-offline-unavailable.component.html',
  styleUrls: ['./feature-offline-unavailable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureOfflineUnavailableComponent {
  @Input() protected title?: string;
  @Input() protected text?: string;
}
