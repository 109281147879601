import { Assert, FeatureFlagEnum, TwoWayCompareStringType } from '@remberg/global/common/core';
import { WorkOrder2Permissions } from '@remberg/users/common/main';

export enum WorkOrderPermissionsEnum {
  WORK_ORDERS_ENABLED = 'workOrdersTemporary_enabled',
  WORK_ORDERS_CREATE = 'workOrdersTemporary_create',
  WORK_ORDERS_UPDATE_PLANNING = 'workOrdersTemporary_updatePlanning',
  WORK_ORDERS_UPDATE_DETAILS = 'workOrdersTemporary_updateDetails',
  WORK_ORDERS_UPDATE_STATUS_AND_CHECKLIST = 'workOrdersTemporary_updateStatusAndChecklist',
  WORK_ORDERS_DELETE = 'workOrdersTemporary_delete',

  // notes
  WORK_ORDERS_NOTES_CREATE_UPDATE_DELETE_OWN = 'workOrdersTemporary_notesCreateUpdateDeleteOwn',
  WORK_ORDERS_NOTES_DELETE_ALL = 'workOrdersTemporary_notesDeleteAll',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `${FeatureFlagEnum.WORK_ORDERS_TEMPORARY}_${keyof WorkOrder2Permissions}`,
    `${WorkOrderPermissionsEnum}`
  >
>;
