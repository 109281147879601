import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LogService } from '@remberg/global/ui';
import { TaskService } from '../../services/api/task.service';

@Injectable({
  providedIn: 'root',
})
export class TasksGuard {
  constructor(
    private router: Router,
    private logger: LogService,
    private taskService: TaskService,
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const hasTasksFeatureAccess = this.taskService.hasTasksFeatureAccess();
      // for now disable for Ionic until we have a proper implementation
      if (hasTasksFeatureAccess) {
        return resolve(true);
      }
      this.logger.info()('Tasks Guard: Redirecting from: ' + state.url);
      this.router.navigate(['/']);
      return resolve(false);
    });
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
