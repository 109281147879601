import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VisualizationTypeEnum } from '@remberg/global/common/core';
import { VisualizationTypeOption, VisualizationTypes } from '@remberg/global/ui';
@Component({
  selector: 'app-visualization-type-selector',
  templateUrl: './visualization-type-selector.component.html',
  styleUrls: ['./visualization-type-selector.component.scss'],
})
export class VisualizationTypeSelectorComponent {
  @Input() public currentVisualizationType?: VisualizationTypeEnum;
  @Input() public visualizationTypes?: VisualizationTypes;
  @Output() public visualizationTypeChanged = new EventEmitter<VisualizationTypeEnum>();

  protected originalOrder(
    a: KeyValue<string, VisualizationTypeOption>,
    b: KeyValue<string, VisualizationTypeOption>,
  ): number {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  }
}
