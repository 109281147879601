// @see frontend/src/app/shared-main/pipes/css-color-converter.pipe.ts

import { ALL_COLORS, AllColors, COLOR_PALETTE_VARIANTS, ColorPaletteOption } from '../definitions';

export interface RGB {
  r: number;
  g: number;
  b: number;
}

export const hexToRgb = (hex: string): RGB | undefined => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (_, r, g, b) {
    return r + r + g + g + b + b;
  });

  const hasHexPrefix = hex.includes('0x');
  hex = hasHexPrefix ? hex.substring(2) : hex;

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1] ?? '', 16),
        g: parseInt(result[2] ?? '', 16),
        b: parseInt(result[3] ?? '', 16),
      }
    : undefined;
};

export const codeFromColor = (color: string, type: 'hex' | 'rgb'): RGB | undefined => {
  if (type === 'hex') {
    return hexToRgb(color);
  } else if (type === 'rgb') {
    return {
      r: parseInt(color.match(/\d+/g)?.[0] ?? ''),
      g: parseInt(color.match(/\d+/g)?.[1] ?? ''),
      b: parseInt(color.match(/\d+/g)?.[2] ?? ''),
    };
  }
  return undefined;
};

export const getContrastColor = (color: string, type: 'hex' | 'rgb'): string => {
  const code = codeFromColor(color, type);

  if (code && code.r * 0.299 + code.g * 0.587 + code.b * 0.114 >= 150) {
    // RGB related to color --paletteBlack: #30343f;
    return 'rgb(48, 52, 63)';
  } else {
    // RGB related to color --paletteWhite: #FFFFFF;
    return 'rgb(255,255,255)';
  }
};

export function isColorPaletteOption(color: string): color is ColorPaletteOption<AllColors> {
  return ALL_COLORS.flatMap((colorOption) =>
    COLOR_PALETTE_VARIANTS.map((variant) => `${colorOption}${variant}`),
  ).includes(color);
}

export function getFontColorDependingOnBackground(color: string, type: 'hex' | 'rgb'): string {
  const code = codeFromColor(color, type);

  if (code && code.r * 0.299 + code.g * 0.587 + code.b * 0.114 >= 150) {
    // RGB related to color --paletteBlack: #30343f;
    return 'rgb(48, 52, 63)';
  } else {
    // RGB related to color --paletteWhite: #FFFFFF;
    return 'rgb(255,255,255)';
  }
}
