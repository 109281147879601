import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LocalStorageKeys,
  LogService,
  StorageService,
} from '@remberg/global/ui';
import {
  UserRole,
  UserRoleCreateOneBody,
  UserRoleFindManyQuery,
  UserRoleUpdateOneBody,
} from '@remberg/users/common/main';
import { Observable, from, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  private readonly USER_ROLES_URL = `${API_URL_PLACEHOLDER}/userroles/v1`;

  constructor(
    private readonly http: HttpClient,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly logger: LogService,
    private readonly storageService: StorageService,
  ) {}

  /**
   * @description
   * Only to be used during initialization. In order to fetch the current user role use the store.
   */
  public getOneForGlobalInitializationOrThrow(userRoleId: string): Observable<UserRole> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()(`Getting Local User Role in offline mode ${userRoleId}`);

      return from(this.storageService.get(LocalStorageKeys.IONIC_CURRENT_USER_ROLE)).pipe(
        map((userRoleString: string | undefined) => {
          if (!userRoleString) {
            throw new Error('Local User Role not found');
          }
          const userRole: UserRole = JSON.parse(userRoleString);
          if (userRole._id !== userRoleId) {
            throw new Error(
              `Local User Role Id ${userRole._id} does not match requested id ${userRoleId}`,
            );
          }
          return userRole;
        }),
      );
    }

    return this.getOne(userRoleId).pipe(
      map((userRole) => {
        if (!userRole) {
          throw new Error('User Role not found');
        }
        return userRole;
      }),
    );
  }

  public getOne(id: string): Observable<UserRole> {
    return this.http.get<UserRole>(`${this.USER_ROLES_URL}/${id}`);
  }

  public getMany({
    tenantId,
    search,
    type: userRoleType,
  }: UserRoleFindManyQuery): Observable<UserRole[]> {
    let params = new HttpParams();
    if (tenantId !== undefined) {
      params = params.set('tenantId', String(tenantId));
    }
    if (userRoleType !== undefined) {
      params = params.set('type', String(userRoleType));
    }
    if (search) {
      params = params.set('search', search);
    }

    return this.http.get<UserRole[]>(this.USER_ROLES_URL, { params });
  }

  public createOne(userRoleCreateOneBody: UserRoleCreateOneBody): Observable<UserRole> {
    return this.http.post<UserRole>(this.USER_ROLES_URL, userRoleCreateOneBody);
  }

  public updateOne(id: string, userRoleUpdateOneBody: UserRoleUpdateOneBody): Observable<void> {
    return this.http.patch<void>(`${this.USER_ROLES_URL}/${id}`, userRoleUpdateOneBody);
  }

  public deleteOne(id: string): Observable<void> {
    return this.http.delete<void>(`${this.USER_ROLES_URL}/${id}`);
  }
}
