import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import {
  DynamicProgressBarConfig,
  LocalStorageKeys,
  LogService,
  StorageService,
} from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';
import { accountToOrganizationAndTenant } from './accountTranformationHelpers';
import { userToContactAndRembergUser } from './userTransformationHelpers';

type Account = any;
type User = any;

export async function migrateToV21(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
  storage: StorageService,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V21...');

  await persistTenantInformation(storage, logger);
  await persistRembergUserInformation(storage, logger);
  await renameLocalStorageKeys(storage, logger);

  await db.executeSql('PRAGMA user_version = 21;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V21.');
  return;
}

async function persistTenantInformation(
  storage: StorageService,
  logger: LogService,
): Promise<void> {
  logger.debug()('[Start] persisting tenant information into local storage from ACCOUNT');

  const accountString: string = await storage.get(LocalStorageKeys.ACCOUNT);
  if (!accountString) {
    logger.error()('No account saved locally needed for migration');
    return;
  }

  const account: Account = JSON.parse(accountString);
  if (!account) {
    logger.error()(`Account saved locally could not be parsed for migration-${account}`);
    return;
  }

  const { tenant } = accountToOrganizationAndTenant(account);

  if (!tenant) {
    logger.error()(`Tenant could not be extracted from account - ${accountString}`);
    return;
  }

  await storage.set(LocalStorageKeys.IONIC_CURRENT_TENANT, JSON.stringify(tenant));

  logger.debug()('[End] persisting tenant information into local storage from ACCOUNT');
}

async function persistRembergUserInformation(
  storage: StorageService,
  logger: LogService,
): Promise<void> {
  logger.debug()(
    '[Start] persisting remberg user information into local storage from IONIC_CURRENT_USER_PROFILE',
  );

  const user: User = await storage.get(LocalStorageKeys.IONIC_CURRENT_USER_PROFILE);
  if (!user) {
    logger.error()(`User saved locally could not be parsed for migration - ${user}`);
    return;
  }

  const { rembergUser } = userToContactAndRembergUser(user);

  if (!rembergUser) {
    logger.error()(`Remberg user could not be extracted from user - ${JSON.stringify(user)}`);
    return;
  }

  await storage.set(LocalStorageKeys.IONIC_CURRENT_REMBERG_USER, JSON.stringify(rembergUser));

  logger.debug()(
    '[End] persisting remberg user information into local storage from IONIC_CURRENT_USER_PROFILE',
  );
}

async function renameLocalStorageKeys(storage: StorageService, logger: LogService): Promise<void> {
  logger.debug()('[Start] renaming local storage keys');

  const userRightsString: string = await storage.get(LocalStorageKeys.USER_RIGHTS);
  if (!userRightsString) {
    logger.error()(`User Rights could not be extracted from local storage`);
    return;
  }
  await storage.set(LocalStorageKeys.IONIC_CURRENT_USER_ROLE, userRightsString);

  logger.debug()('[End] renaming local storage keys');
}
