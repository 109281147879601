import { PartialDeep } from '@remberg/global/common/core';
import { GLOBAL_CONFIG_PARAMETERS_SETTINGS, GlobalConfigParameters } from '../definitions';

export function isGlobalParameterGroupKey(
  parameterGroupKey: string,
): parameterGroupKey is keyof GlobalConfigParameters {
  return (
    GLOBAL_CONFIG_PARAMETERS_SETTINGS[parameterGroupKey as keyof GlobalConfigParameters] !==
    undefined
  );
}

export function isGlobalParameterGroupItemKey<K extends keyof GlobalConfigParameters>(
  groupKey: K,
  groupItemKey: string | number | symbol,
): groupItemKey is keyof GlobalConfigParameters[K] {
  return (
    GLOBAL_CONFIG_PARAMETERS_SETTINGS[groupKey]?.[
      groupItemKey as keyof GlobalConfigParameters[K]
    ] !== undefined
  );
}

export function getGlobalConfigParameterSettings<K extends keyof GlobalConfigParameters>(
  groupKey: K,
  groupItemKey: string,
): (typeof GLOBAL_CONFIG_PARAMETERS_SETTINGS)[K][keyof GlobalConfigParameters[K]] | undefined {
  if (!isGlobalParameterGroupItemKey(groupKey, groupItemKey)) return;

  return GLOBAL_CONFIG_PARAMETERS_SETTINGS[groupKey]?.[groupItemKey];
}

export function getGroupItemKeys<K extends keyof GlobalConfigParameters>(
  groupKey: K,
): (keyof GlobalConfigParameters[K])[] {
  return Object.keys(GLOBAL_CONFIG_PARAMETERS_SETTINGS[groupKey] ?? {}).filter((groupItemKey) =>
    isGlobalParameterGroupItemKey(groupKey, groupItemKey),
  ) as (keyof GlobalConfigParameters[K])[];
}

export function buildGlobalConfigParameterDefaults(
  overrides?: PartialDeep<GlobalConfigParameters>,
): GlobalConfigParameters {
  return Object.keys(GLOBAL_CONFIG_PARAMETERS_SETTINGS).reduce((group, groupKey) => {
    if (!isGlobalParameterGroupKey(groupKey)) return group;
    const item = getParametersItemDefault(groupKey, overrides?.[groupKey]);
    return {
      ...group,
      ...(item ? { [item.groupKey]: item.groupItem } : {}),
    };
  }, {} as GlobalConfigParameters);
}

function getParametersItemDefault<K extends keyof GlobalConfigParameters>(
  groupKey: K,
  overrides?: Partial<GlobalConfigParameters[K]>,
): { groupKey: K; groupItem: GlobalConfigParameters[K] } | undefined {
  const group = GLOBAL_CONFIG_PARAMETERS_SETTINGS[groupKey];
  if (!group) return;

  return {
    groupKey,
    groupItem: Object.keys(group).reduce(
      (acc, groupItemKey) => ({
        ...acc,
        ...(isGlobalParameterGroupItemKey(groupKey, groupItemKey)
          ? { [groupItemKey]: overrides?.[groupItemKey] ?? group[groupItemKey].default }
          : {}),
      }),
      {} as GlobalConfigParameters[K],
    ),
  };
}

export function areGlobalConfigParametersValid(
  parameters?: Record<string, Record<string, unknown>>,
): boolean {
  if (!parameters) return true;

  for (const groupKey of Object.keys(parameters)) {
    if (!isGlobalParameterGroupKey(groupKey)) return false;

    const group = parameters[groupKey];
    if (!group) return false;

    for (const groupItemKey of Object.keys(group)) {
      if (!isParameterItemValid({ group, groupKey, groupItemKey })) return false;
    }
  }

  return true;
}

function isParameterItemValid<K extends keyof GlobalConfigParameters>({
  groupKey,
  groupItemKey,
  group,
}: {
  groupKey: K;
  groupItemKey: string;
  group: Record<string, unknown>;
}): boolean {
  const setting = getGlobalConfigParameterSettings(groupKey, groupItemKey);
  if (!setting) return false;

  const groupItemKeyValue = group[groupItemKey];
  if (groupItemKeyValue === undefined) return false;

  if (typeof groupItemKeyValue !== setting.type) return false;

  return true;
}
