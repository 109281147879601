import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { DynamicProgressBarConfig, LogService } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';

export async function migrateToV26(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V26...');

  await removeAssetsV2Table(db, logger);

  await db.executeSql('PRAGMA user_version = 26;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V26.');
}

async function removeAssetsV2Table(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  try {
    await removeTable(db, logger, 'assets2');
  } catch (error) {
    logger.error()(`Error removing assets2 table`, error);
  }
}

async function removeTable(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  tableName: string,
): Promise<void> {
  try {
    const tableExistsQuery = await db.executeSql(
      `SELECT name FROM sqlite_master WHERE type='table' AND name='${tableName}';`,
      [],
    );

    if (tableExistsQuery.rows?.item(0)?.name === tableName) {
      logger.debug()(`FOUND ${tableName} table, removing...`);

      await db.executeSql(`DROP TABLE IF EXISTS ${tableName}`, []);
    } else {
      logger.debug()(`${tableName} table does not exist. Skipping migration.`);
    }
  } catch (error) {
    logger.error()(`Error during ${tableName} table existence check`, error);
  }
}
