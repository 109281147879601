import sanitize from 'sanitize-filename';

export const DEFAULT_FILE_NAME = 'file';

export interface SanitizeOptions {
  excludeExtension?: boolean;
  replaceWhitespacesWith?: string;
  replaceSlashWith?: string;
}

export function sanitizeFileName(fileName: string, options?: SanitizeOptions): string {
  const { name, ext } = parseFileName(fileName, !!options?.excludeExtension);

  const safeName = name ? sanitizeOnlyFileName(name, options) : '';
  const safeExt = ext ? sanitize(ext) : '';

  if (safeExt) {
    return `${safeName}.${safeExt}`;
  }

  return safeName;
}

function parseFileName(
  fileName: string,
  excludeExtension: boolean,
): { name?: string; ext?: string } {
  if (excludeExtension) {
    return { name: fileName.trim() };
  }

  const split = fileName.split('.').map((name) => name.trim());

  if (split.length > 1) {
    const ext = split.pop();
    const name = split.join('.');

    return { name, ext };
  }

  return { name: split.join('.') };
}

function sanitizeOnlyFileName(fileName: string, options?: SanitizeOptions): string {
  // 1. sanitize
  let safeName = sanitize(fileName, {
    replacement: (char) => (char === '/' && options?.replaceSlashWith) || '',
  });

  // 2. replace duplicated whitespaces
  safeName = safeName.replace(/\s+/g, options?.replaceWhitespacesWith ?? ' ');

  // 3. return the default file name if the result is empty
  return safeName || DEFAULT_FILE_NAME;
}
