import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NavigationalActions = createActionGroup({
  source: 'Navigational',
  events: {
    refreshPage: emptyProps(),
    goToLastVisitedPage: props<{ bypassReload?: boolean }>(),
    goBack: props<{
      fallbackUrl?: string;
      ensureRerender?: boolean;
    }>(),
    goToUrl: props<{ url: string; shouldOpenInNewTab?: boolean }>(),
    goToUrlEnsureRerender: props<{ url: string }>(),
    goToApplicationRoot: props<{ targetUrl?: string }>(),
    goToTenantLoginAtSubdomain: props<{ subdomain: string; targetUrl?: string }>(),
    goToUnauthenticatedLandingPage: props<{ targetUrl?: string }>(),
    goToAuthenticatedDefaultPage: emptyProps(),
  },
});
