import { ContactBasic } from '@remberg/crm/common/base';
import { MultiLanguageText, UnknownOr } from '@remberg/global/common/core';
import { ConfigWithDynamicActions, ConfigWithPrefilling } from '../../../automation';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ContactSingleSelectField
  extends Field<FormFieldTypesEnum.CONTACT_SINGLE_SELECT, ContactSingleSelectFieldConfig> {}

export interface ContactSingleSelectFieldConfig
  extends ConfigWithPrefilling<ContactSingleSelectPrefillTargetsEnum>,
    ConfigWithDynamicActions {
  required?: boolean;
  disabled?: boolean;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  label: {
    text: MultiLanguageText;
    uiHide?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  value?: {
    pdfPrintEmailAddress?: boolean;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
    pdfStartInNewLine?: boolean;
  };
}

export enum ContactSingleSelectPrefillTargetsEnum {
  VALUE = 'value',
}

export interface ContactSingleSelectFieldData<Populated extends boolean = false> {
  user?: Populated extends true ? UnknownOr<ContactBasic> : string;
}
