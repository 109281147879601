<mat-form-field
  appearance="outline"
  floatLabel="always"
  class="my-mat-form-field phone-number-input-field"
  [ngClass]="{ 'my-mat-form-field-full-width': fullWidth }"
  [class.mat-form-field-invalid]="(showError || isInErrorState) && errorMessage"
>
  <mat-label>{{ label }}</mat-label>

  <input
    [type]="layout.isAndroid || layout.isIos ? 'tel' : 'text'"
    matInput
    [disabled]="disabled"
    [required]="required"
    [minlength]="minLength ?? null"
    [maxlength]="maxLength ?? null"
    [pattern]="phoneNumberRegex"
    [formControl]="numberControl"
    data-test-id="phone-number-input"
  />

  <span
    matPrefix
    class="country-code-prefix"
    [ngClass]="{ 'disabled-color': disabled }"
    *ngIf="countryCodeWithFallback$ | async; let countryCode"
    data-test-id="phone-number-country-code-span"
    >+{{ countryCode[2] }}</span
  >

  <!-- Country button -->
  <button
    matSuffix
    mat-button
    class="country-code"
    [disabled]="disabled"
    (click)="openCountryCodeSelect()"
    *ngIf="countryCodeWithFallback$ | async; let countryCode"
    data-test-id="phone-number-country-code-button"
  >
    <div class="flex-row country-code-button" [ngClass]="{ 'disabled-color': disabled }">
      {{ countryCode[1] | uppercase }}
      <mat-icon [ngClass]="{ 'disabled-color': disabled }" class="country-code-icon"
        >arrow_drop_down</mat-icon
      >
    </div>
  </button>

  <!-- Errors -->
  <mat-error
    *ngIf="(showError || isInErrorState) && errorMessage"
    [@transitionMessages]="animationState"
    >{{ errorMessage }}</mat-error
  >
</mat-form-field>

<div class="select">
  <mat-select
    class="mat-mdc-option-force-primary"
    #countryCodeSelect
    disableOptionCentering
    [compareWith]="compareWith"
    [value]="countryCodeWithFallback$ | async"
    (selectionChange)="countryCodeSelected($event)"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="translations.search"
        [noEntriesFoundLabel]="translations.noEntriesFound"
        [disableInitialFocus]="false"
        ngModel
        (ngModelChange)="filterCountryCodeOptions($event)"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let countryCode of filteredCountryCodeOptions"
      [value]="countryCode"
      attr.data-test-id="option-key-{{ countryCode[2] }}"
    >
      <div class="country-code-option">
        <span class="text-ellipsis">{{ countryCode[0] }} ({{ countryCode[1].toUpperCase() }})</span>
        <span class="country-code-option-number">+{{ countryCode[2] }}</span>
      </div>
    </mat-option>
  </mat-select>
</div>
