import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { ToastrService } from 'ngx-toastr';
import { Observable, map } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class OemGuard {
  constructor(
    private logger: LogService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<RootGlobalState>,
  ) {}

  public canActivate(
    route?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.store.select(GlobalSelectors.selectIsTenantOwner).pipe(
      map((isTenantOwner) => {
        if (isTenantOwner) {
          return true;
        } else {
          this.logger.info()('OEM Guard: Redirecting from: ' + state?.url);
          this.toastr.warning(
            $localize`:@@noAccessToThisPage:You do not have access to this page.`,
            $localize`:@@warning:Warning`,
          );
          this.router.navigateByUrl('/login');
          return false;
        }
      }),
    );
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
