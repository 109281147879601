import { AssetBasic } from '@remberg/assets/common/base';
import { ContactBasic, OrganizationBasic } from '@remberg/crm/common/base';
import { OrganizationRaw } from '@remberg/crm/common/main';
import {
  RembergDate,
  RembergDateTime,
  RembergTime,
  RembergTimestamp,
} from '@remberg/global/common/core';
import { Asset, WorkOrder } from '../../core';
import { Address, SignatureInfo } from '../common';
import { PhoneNumber } from '../common/phone-number';
import { Task } from '../common/task';
import { PersonListItem } from '../form-sections';

export enum KnownDataTypeEnum {
  // general purpose types
  STRING = 'string',
  ARRAY_OF_STRINGS = 'arrayOfStrings',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  NONE = 'none',
  ANY = 'any',
  UNKNOWN = 'unknown',

  // remberg specific types
  REMBERG_DATE = 'rembergDate',
  REMBERG_TIME = 'rembergTime',
  REMBERG_DATETIME = 'rembergDateTime',
  REMBERG_TIMESTAMP = 'rembergTimestamp',
  HTML_STRING = 'html',
  ADDRESS = 'address',
  ASSET = 'asset',
  ASSET_INFO = 'assetInfo',
  ASSET_ID = 'assetId',
  ASSET_IDS = 'assetIds',
  ORGANIZATION_BASIC = 'organizationBasic',
  ORGANIZATION = 'organization',
  ORGANIZATION_ID = 'organizationId',
  CONTACT_ID = 'contactId',
  CONTACT_BASIC = 'contactBasic',
  CONTACT = 'contact',
  CONTACTS = 'contacts',
  SIGNATURE = 'signature',
  PHONE_NUMBER = 'phoneNumber',
  PERSON_LIST_ENTRIES = 'personListEntries',
  TASKS = 'tasks',
  WORK_ORDER_ID = 'workOrderId',
  WORK_ORDER = 'workOrder',
}

/**
 * This is an interface to map a KnownDataTypeEnum (e.g. ASSET_ID) to respective typescript type.
 *  We need this to properly type the step providers.
 */
export interface DefaultKnownDataTypeScriptTypes {
  string: string;
  arrayOfStrings: string[];
  number: number;
  boolean: boolean;
  none: void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any: any;
  unknown: unknown;

  // remberg specific types
  rembergDate: RembergDate;
  rembergTime: RembergTime;
  rembergDateTime: RembergDateTime;
  rembergTimestamp: RembergTimestamp;
  html: string;
  address: Address;
  assetId: string;
  assetIds: string[];
  assetInfo: AssetBasic;
  asset: Asset;
  organizationId: string;
  organizationBasic: OrganizationBasic;
  organization: OrganizationRaw;
  contactId: string;
  contactBasic: ContactBasic;
  contact: ContactBasic;
  contacts: ContactBasic[];
  signature: SignatureInfo;
  phoneNumber: PhoneNumber;
  personListEntries: PersonListItem[];
  tasks: Task[];
  workOrderId: string;
  workOrder: WorkOrder;
}

export type TypeFor<
  T extends KnownDataTypeEnum | readonly KnownDataTypeEnum[],
  KnownDataTypeScriptTypes extends DefaultKnownDataTypeScriptTypes,
> = T extends KnownDataTypeEnum
  ? KnownDataTypeScriptTypes[T]
  : {
      [Key in keyof T]: T[Key] extends KnownDataTypeEnum
        ? TypeFor<T[Key], KnownDataTypeScriptTypes>
        : never;
    };

export const COMPATIBLE_KNOWN_DATA_TYPES: Partial<Record<KnownDataTypeEnum, KnownDataTypeEnum[]>> =
  {
    string: [
      KnownDataTypeEnum.STRING,
      KnownDataTypeEnum.REMBERG_DATE,
      KnownDataTypeEnum.REMBERG_TIME,
      KnownDataTypeEnum.REMBERG_DATETIME,
      KnownDataTypeEnum.HTML_STRING,
      KnownDataTypeEnum.ASSET_ID,
      KnownDataTypeEnum.ORGANIZATION_ID,
      KnownDataTypeEnum.CONTACT_ID,
      KnownDataTypeEnum.ANY,
      KnownDataTypeEnum.UNKNOWN,
    ],
    number: [KnownDataTypeEnum.NUMBER, KnownDataTypeEnum.ANY, KnownDataTypeEnum.UNKNOWN],
    boolean: [KnownDataTypeEnum.BOOLEAN, KnownDataTypeEnum.ANY, KnownDataTypeEnum.UNKNOWN],
    unknown: [KnownDataTypeEnum.ANY, KnownDataTypeEnum.UNKNOWN],
    arrayOfStrings: [
      KnownDataTypeEnum.ARRAY_OF_STRINGS,
      KnownDataTypeEnum.ASSET_IDS,
      KnownDataTypeEnum.ANY,
      KnownDataTypeEnum.UNKNOWN,
    ],
    address: [KnownDataTypeEnum.ADDRESS, KnownDataTypeEnum.ANY, KnownDataTypeEnum.UNKNOWN],
    tasks: [KnownDataTypeEnum.TASKS, KnownDataTypeEnum.ANY, KnownDataTypeEnum.UNKNOWN],
    phoneNumber: [KnownDataTypeEnum.PHONE_NUMBER, KnownDataTypeEnum.ANY, KnownDataTypeEnum.UNKNOWN],
    contact: [KnownDataTypeEnum.CONTACT, KnownDataTypeEnum.ANY, KnownDataTypeEnum.UNKNOWN],
  };
