import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdvancedFilter } from '@remberg/advanced-filters/common/main';
import {
  Asset,
  AssetsFilterEnum,
  AssetsLegacyFilterFieldEnum,
  Product,
  mapAssetToProduct,
} from '@remberg/assets/common/main';
import {
  ASSETS_LEGACY_OFFLINE_SERVICE,
  ASSETS_NEW_OFFLINE_SERVICE,
  AssetsFindManyOfflineQueryInterface,
  AssetsLegacyFindManyOfflineQueryInterface,
  AssetsLegacyOfflineAdvancedFilterConfigKeys,
  AssetsLegacyOfflineServiceInterface,
  AssetsNewOfflineServiceInterface,
  AssetsOfflineAdvancedFilterConfigKeys,
  AssetsOfflineServiceInterface,
} from '@remberg/assets/ui/clients';
import { isCustomPropertyKey } from '@remberg/custom-properties/common/main';
import { ApiResponse, OfflinePopulateType, getStringID } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../../store';

const assetsOfflineLegacyFilterToAssetsFilter: Record<
  AssetsLegacyOfflineAdvancedFilterConfigKeys,
  AssetsOfflineAdvancedFilterConfigKeys
> = {
  [AssetsLegacyFilterFieldEnum.PRODUCT_TYPE]: AssetsFilterEnum.ASSET_TYPE,
  [AssetsLegacyFilterFieldEnum.CUSTOMER]: AssetsFilterEnum.RELATED_ORGANIZATION,
  [AssetsLegacyFilterFieldEnum.QR_CODE]: AssetsFilterEnum.QR_CODE,
  [AssetsLegacyFilterFieldEnum.ZIP_CODE]: AssetsFilterEnum.ZIP_POST_CODE,
  [AssetsLegacyFilterFieldEnum.CITY]: AssetsFilterEnum.CITY,
  [AssetsLegacyFilterFieldEnum.COUNTRY]: AssetsFilterEnum.COUNTRY,
  [AssetsLegacyFilterFieldEnum.COUNTRY_PROVINCE]: AssetsFilterEnum.COUNTRY_PROVINCE,
  [AssetsLegacyFilterFieldEnum.CONTACT_PERSON]: AssetsFilterEnum.RELATED_CONTACT,
  [AssetsLegacyFilterFieldEnum.INVOLVED_CONTACT]: AssetsFilterEnum.INVOLVED_CONTACT,
  [AssetsLegacyFilterFieldEnum.STATUS]: AssetsFilterEnum.STATUS,
  [AssetsLegacyFilterFieldEnum.PARENT_ASSET]: AssetsFilterEnum.ANCESTOR_ASSET,
  [AssetsLegacyFilterFieldEnum.CREATED]: AssetsFilterEnum.CREATED,
  [AssetsLegacyFilterFieldEnum.UPDATED]: AssetsFilterEnum.UPDATED,
  [AssetsLegacyFilterFieldEnum.GROUP_ID]: AssetsFilterEnum.USER_GROUP,
  [AssetsLegacyFilterFieldEnum.BOUNDING_BOX]: AssetsFilterEnum.BOUNDING_BOX,
  [AssetsLegacyFilterFieldEnum.RESPONSIBLE]: AssetsFilterEnum.TENANT_OWNER_CONTACT,
};

@Injectable()
export class AssetsOfflineService implements AssetsOfflineServiceInterface {
  constructor(
    @Inject(ASSETS_LEGACY_OFFLINE_SERVICE)
    private readonly assetsLegacyOfflineService: AssetsLegacyOfflineServiceInterface,
    @Inject(ASSETS_NEW_OFFLINE_SERVICE)
    private readonly assetsNewOfflineService: AssetsNewOfflineServiceInterface,
    private readonly store: Store<RootGlobalState>,
  ) {}

  public async tryGetInstance(options: {
    id: string | Product | Asset;
    populate?: OfflinePopulateType;
  }): Promise<Product | undefined> {
    return await this.getInstance(options);
  }

  public async getInstance({
    id,
    populate,
  }: {
    id: string | Product | Asset;
    populate?: OfflinePopulateType;
  }): Promise<Product> {
    const isAssetsTemporaryEnabled = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsAssetsTemporaryEnabled),
    );
    if (isAssetsTemporaryEnabled) {
      return this.assetsNewOfflineService
        .findOne(getStringID(id), populate)
        .then((asset) => mapAssetToProduct(asset));
    }
    return await this.assetsLegacyOfflineService.findOne(getStringID(id), populate);
  }

  public async getAssetsWithCount(
    options: AssetsLegacyFindManyOfflineQueryInterface,
  ): Promise<ApiResponse<Product[]>> {
    const isAssetsTemporaryEnabled = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsAssetsTemporaryEnabled),
    );
    if (isAssetsTemporaryEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { filterQuery, staticFilters, ...parameters } = options;
      const payload: AssetsFindManyOfflineQueryInterface = {
        ...parameters,
        ...(staticFilters && {
          staticFilters: mapLegacyOfflineFiltersToAssetsOfflineFilters(staticFilters),
        }),
        ...(options.filterQuery && {
          filterQuery: {
            concatOperator: options.filterQuery.concatOperator,
            filters: mapLegacyOfflineFiltersToAssetsOfflineFilters(options.filterQuery?.filters),
          },
        }),
      };
      return this.assetsNewOfflineService
        .findMany(payload)
        .then((response) => new ApiResponse(mapAssetToProduct(response.assets), response.count));
    }
    return await this.assetsLegacyOfflineService.findMany(options);
  }

  public async getManyByIds(
    ids: string[],
    populate?: boolean | Record<string, boolean> | undefined,
  ): Promise<Product[]> {
    const isAssetsTemporaryEnabled = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsAssetsTemporaryEnabled),
    );
    if (isAssetsTemporaryEnabled) {
      return this.assetsNewOfflineService
        .findManyByIds(ids, populate)
        .then((asset) => mapAssetToProduct(asset));
    }
    return await this.assetsLegacyOfflineService.findManyByIds(ids, populate);
  }
}

export function mapLegacyOfflineFiltersToAssetsOfflineFilters(
  filters: AdvancedFilter<AssetsLegacyOfflineAdvancedFilterConfigKeys>[],
): AdvancedFilter<AssetsOfflineAdvancedFilterConfigKeys>[] {
  const advancedFilters: AdvancedFilter<AssetsOfflineAdvancedFilterConfigKeys>[] = [];
  for (const { identifier, operator, value } of filters) {
    if (!isCustomPropertyKey(identifier)) {
      const newIdentifier = assetsOfflineLegacyFilterToAssetsFilter[identifier];
      advancedFilters.push({ identifier: newIdentifier, operator, value });
    }
  }

  return advancedFilters;
}
