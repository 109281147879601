import { FeatureFlagEnum } from '../enums';

export const INTERNAL_FEATURE_FLAGS: FeatureFlagEnum[] = Object.values(FeatureFlagEnum);

export type InternalFeatureFlagKey = FeatureFlagEnum;

export type InternalFeatureFlagValues = Partial<Record<InternalFeatureFlagKey, boolean>>;

export const EXTERNAL_FEATURE_FLAGS = [
  FeatureFlagEnum.ASSETS_HIERARCHY,
  FeatureFlagEnum.ASSETS_NOTES,
  FeatureFlagEnum.ASSETS_ORGANIZATIONS_AND_CONTACTS,
  FeatureFlagEnum.ASSETS_WORKORDERS_ADDRESS,
  FeatureFlagEnum.ASSETS,
  FeatureFlagEnum.ASSETS_STATUS,
  FeatureFlagEnum.CASES_CATEGORY,
  FeatureFlagEnum.CASES,
  FeatureFlagEnum.FILES_DOWNLOAD,
  FeatureFlagEnum.FILES,
  FeatureFlagEnum.PARTS,
  FeatureFlagEnum.ASSETS_TEMPORARY,
  FeatureFlagEnum.NOTES,
  FeatureFlagEnum.TICKETS_TEMPORARY,
] as const;

export const DEFAULT_TRUE_FEATURE_FLAGS = [FeatureFlagEnum.ASSETS] as const;

export type ExternalFeatureFlagKey = TupleElement<typeof EXTERNAL_FEATURE_FLAGS>;

export type ExternalFeatureFlagValues = Partial<Record<ExternalFeatureFlagKey, boolean>>;

/** Feature flags that should be carefully updated since they could lead to data inconsistency*/
export const CANNOT_TOGGLE_FALSE_FEATURE_FLAGS = [
  FeatureFlagEnum.ASSETS_ORGANIZATIONS_AND_CONTACTS,
  FeatureFlagEnum.CASES_ORGANIZATION,
  FeatureFlagEnum.ASSETS,
  FeatureFlagEnum.WORKORDERS_ORGANIZATIONS_AND_CONTACTS,
] as const;

export type CannotToggleFalseFeatureFlagKey = TupleElement<
  typeof CANNOT_TOGGLE_FALSE_FEATURE_FLAGS
>;

export function isFeatureFlagEnum(value: string): value is FeatureFlagEnum {
  return Object.values<string>(FeatureFlagEnum).includes(value);
}

export function isExternalFeatureFlag(value: string): value is ExternalFeatureFlagKey {
  return EXTERNAL_FEATURE_FLAGS.includes(value as ExternalFeatureFlagKey);
}

export function cannotToggleOffFeatureFlag(
  featureFlag: string,
  existingValue: boolean | undefined,
): boolean {
  return (
    CANNOT_TOGGLE_FALSE_FEATURE_FLAGS.includes(featureFlag as CannotToggleFalseFeatureFlagKey) &&
    !!existingValue
  );
}

type TupleElement<Type> = Type extends readonly (infer T)[] ? T : never;
