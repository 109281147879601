import { ContactBasic, OrganizationBasic } from '@remberg/crm/common/base';
import { Address, CreateContextNew, UnknownOr, isObjectId } from '@remberg/global/common/core';
import { ProductCompatibility } from './product-compatibility';

/**
 * This interface is only used in the frontend and represents the object that is returned from the legacy ui-client.
 * */
export type Product = Omit<
  ProductCompatibility,
  | 'customerOrganization'
  | 'manufacturerOrganization'
  | 'customerContact'
  | 'manufacturerContact'
  | 'parentAsset'
  | 'createContext'
> & {
  customerOrganization?: OrganizationBasic | string;
  manufacturerOrganization?: OrganizationBasic | string;
  customerContact?: ContactBasic | string;
  manufacturerContact?: ContactBasic | string;
  parentAsset?: Product;
  createContext?: Omit<CreateContextNew, 'source'> & { source: string | ContactBasic | undefined };
};

export interface AssetCustomPropertyValueLegacy {
  _id?: string;
  property: AssetCustomProperty;
  propertyId: number;
  value: any;
  public?: boolean;
  externalEdit?: boolean;
}

export enum AssetCustomPropertyTypesEnum {
  INPUT_TEXT = 'INPUT_TEXT',
  TEXTAREA = 'TEXTAREA',
  CHECKBOX = 'CHECKBOX',
  INPUT_NUMBER = 'INPUT_NUMBER',
  URL = 'URL',
  SELECT = 'SELECT',
  REMBERG_ASSET_SELECT = 'REMBERG_ASSET_SELECT', // asset-select.component
  REMBERG_ORGANIZATION_SELECT = 'REMBERG_ORGANIZATION_SELECT',
  REMBERG_CONTACT_SELECT = 'REMBERG_CONTACT_SELECT',
  REMBERG_DATE = 'REMBERG_DATE', // date.component
  REMBERG_DATETIME = 'REMBERG_DATETIME', // datetime.component
}

export interface AssetCustomProperty {
  _id: string;
  tenantId: string;
  label: string;
  required?: boolean;
  public?: boolean;
  enabled?: boolean;
  externalEdit?: boolean;
  propertyId: number;
  fieldType: AssetCustomPropertyTypesEnum;
  inputNumberDisplayPrecision?: number;
  options?: {
    options?: any[];
    multiple?: boolean;
  };
  sortIndex: number;
  __v: number;
}

export interface CustomPropertyPayloadValue {
  _id?: string;
  property: string;
  propertyId: number;
  value: any;
  public?: boolean;
  externalEdit?: boolean;
}

export interface ProductCustomerPatchPayload {
  _id: string;
  customerOrganization: string;
  customerContact: string;
}

export interface ProductLocationPatchPayload {
  _id: string;
  location: Address;
}

export interface ProductFilesPatchPayload {
  _id: string;
  files: string[];
}

export interface ProductInfoPatchPayload {
  _id: string;
  manufacturerContact?: string;
  serialNumber?: string;
  customProperties?: CustomPropertyPayloadValue[];
  parentAssetId?: string;
}

export type AssetCustomPropertyKey = `${'cProp-'}${AssetCustomPropertyTypesEnum}-${string}`;

export function isAssetCustomPropertyKey(
  propertyKey: string,
): propertyKey is AssetCustomPropertyKey {
  const cProp = propertyKey.split('-');
  return (
    cProp.length === 3 &&
    cProp[0] === 'cProp' &&
    isAssetCustomPropertyTypeEnum(cProp[1]) &&
    isObjectId(cProp[2])
  );
}

export function isAssetCustomPropertyTypeEnum(x?: string): x is AssetCustomPropertyTypesEnum {
  return Object.values(AssetCustomPropertyTypesEnum).includes(x as AssetCustomPropertyTypesEnum);
}

export function isProduct(
  argument?: string | { _id: string } | UnknownOr<Product>,
): argument is Product {
  return !!argument && !!(argument as Product).productType;
}
