<div class="container landing" [style.background-color]="(theme$ | async)?.backgroundThemeColor">
  <div
    class="center padded landing-logo-wrapper"
    ngClass.lt-sm="landing-logo-wrapper-small"
    (click)="enterDebugMode()"
  >
    <a *ngIf="!(theme$ | async)?.logoFileId" [routerLink]="['/']">
      <img
        class="landing-logo-image"
        ngClass.lt-sm="landing-logo-image-small"
        src="/assets/img/remberg-darkblue.png"
      />
    </a>
    <a *ngIf="(theme$ | async)?.logoFileId; let logo" [routerLink]="['/']">
      <img
        class="landing-logo-image"
        ngClass.lt-sm="landing-logo-image-small"
        [attr.src]="logo | getIDString | fileUrl: FileSourceTypeEnum.LOGO | async"
      />
    </a>
  </div>

  <div class="padded">
    <router-outlet></router-outlet>
  </div>
</div>
