/** Allowed field to perform sorting*/
export enum AssetsLegacySortFieldEnum {
  SERIAL_NUMBER = 'serialNumber',
  STATUS = 'status',
  CREATED = 'created',
  UPDATED = 'updated',
  PRODUCT_TYPE = 'productType',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  CITY = 'city',
  ZIP_POST_CODE = 'zipPostCode',
  STREET = 'street',
}
