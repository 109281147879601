/* eslint-disable max-params */
import { Store } from '@ngrx/store';
import {
  AdvancedFilter,
  AdvancedFilterConcatOperatorEnum,
  AdvancedFilterQuery,
} from '@remberg/advanced-filters/common/main';
import {
  ApiResponse,
  BaseModel,
  DataResponse,
  FindManyOfflineQuery,
  LogService,
  OfflinePopulateType,
  OfflineService,
  OnlineStatusDataTypeEnum,
  SQLDataValues,
  SQLQueryParams,
  SQLSortDirection,
  getStringID,
  stringToSQLSortDirection,
} from '@remberg/global/ui';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { SQLConcatOperator, concatSQLFiltersByOperator } from '../helpers/sqlFiltersHelper';
import { GlobalSelectors, RootGlobalState } from '../store';
import { SqlDBService } from './sqlDB.service';

export abstract class BaseOfflineService<T extends BaseModel, F extends string>
  implements OfflineService<T, AdvancedFilterQuery<F>, AdvancedFilter<F>>
{
  public outstandingChangesCount = new BehaviorSubject<number>(0);

  constructor(
    protected readonly dbService: SqlDBService,
    public readonly params: SQLQueryParams<string, string, string>,
    protected readonly logger: LogService,
    protected readonly store: Store<RootGlobalState>,
  ) {}

  public async initialize(): Promise<void> {
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    if (isIonic) {
      // wait until DB is ready to create the tables
      this.logger.debug()('WAITING FOR TABLE CREATION: ' + this.params.tableName);
      await firstValueFrom(
        this.dbService.isDbReady.pipe(
          takeWhile((v) => !v, true),
          filter((v) => v),
        ),
      );
      this.logger.debug()('TABLE CREATION START: ' + this.params.tableName);
      await this.dbService.createTable(this.params, this);
      // derive initial value for the outstandingChanges subject
      await this.recalculateOutstandingChangesCount();
    }
  }

  public async recalculateOutstandingChangesCount(): Promise<void> {
    const count = await this.countInstances(
      `(onlineStatus = '${OnlineStatusDataTypeEnum.OFFLINE_CREATION}' OR onlineStatus = '${OnlineStatusDataTypeEnum.OFFLINE_CHANGE}')`,
    );
    this.outstandingChangesCount.next(count);
  }

  public async getManyItemsWithCount(
    {
      limit,
      offset,
      sortColumn,
      sortDirection,
      searchValue,
      staticFilters,
      filterQuery,
      populate,
    }: FindManyOfflineQuery<AdvancedFilterQuery<F>, AdvancedFilter<F>>,
    filterStrings: string[] = [],
    concatOperator = ' AND ',
  ): Promise<ApiResponse<T[]>> {
    if (staticFilters?.length) {
      if (!this.getStaticFiltersString) {
        throw new Error('Method [getStaticFiltersString] not Implemented!');
      }
      filterStrings.push(...this.getStaticFiltersString(staticFilters));
    }

    const searchQuery = this.getSearchQuery(searchValue);
    if (searchQuery) {
      filterStrings.push(searchQuery);
    }

    // advanced filters
    const advancedFilterString = this.getAdvancedFiltersString(filterQuery);
    if (advancedFilterString) {
      filterStrings.push(advancedFilterString);
    }

    const { sqlSortDirection, sortField } = this.getSortDirectionAndColumn(
      sortDirection,
      sortColumn,
    );

    return this.getInstancesWithCount(
      limit,
      offset,
      sortField,
      sqlSortDirection,
      filterStrings.join(concatOperator),
      populate,
    );
  }

  public getStaticFiltersString?(staticFilters: AdvancedFilter<string>[] | undefined): string[];

  public getSearchQuery(searchValue: string | undefined): string | undefined {
    if (!this.params.columns || !searchValue) return;

    const searchColumns = Object.entries(this.params.columns).filter(
      ([, value]) => value.isSearchColumn,
    );
    if (!searchColumns.length) throw new Error('[getSearchQuery]: No search columns present!');

    return `${searchColumns.reduce(
      (aggregation, [columnName], index) =>
        `${aggregation}${index ? ' OR ' : ''}${
          this.params.tableName
        }.${columnName} LIKE '%${searchValue}%'`,
      '(',
    )})`;
  }

  public getAdvancedFiltersString(filterQuery?: AdvancedFilterQuery<string>): string | undefined {
    if (!filterQuery?.filters.length) {
      return undefined;
    }
    if (!this.getAdvancedFilterString) {
      throw new Error('Method [getAdvancedFilterString] not Implemented!');
    }

    const sqlFilters = filterQuery.filters.map(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (filter) => this.getAdvancedFilterString!(filter),
    );

    const concatOperator =
      filterQuery.concatOperator === AdvancedFilterConcatOperatorEnum.OR
        ? SQLConcatOperator.OR
        : SQLConcatOperator.AND;

    return concatSQLFiltersByOperator(sqlFilters, concatOperator);
  }

  public getAdvancedFilterString?(filter: AdvancedFilter<string>): string;

  public getSortDirectionAndColumn<E extends string>(
    sortDirection: E | undefined,
    sortColumn: string | undefined,
  ): { sqlSortDirection: SQLSortDirection | undefined; sortField: string | undefined } {
    if (this.params.columns && sortColumn) {
      if (!Object.keys(this.params.columns).includes(sortColumn)) {
        throw new Error(
          `Sort column ${sortColumn} does not exist in ${JSON.stringify(
            this.params.columns,
          )}. Override this method to handle custom sort column definitions`,
        );
      }
    }
    const sqlSortDirection: SQLSortDirection | undefined = stringToSQLSortDirection(sortDirection);
    return { sqlSortDirection, sortField: sortColumn };
  }

  public async getInstance(instance: string | T, populate?: OfflinePopulateType): Promise<T> {
    const result = (await this.dbService.getGenericObject(
      instance,
      this.params,
      undefined,
      populate,
    )) as T;
    this.logger.debug()(`Instance from ${this.params?.tableName}:`);
    this.logger.debug()(result);
    return result;
  }

  public async tryGetInstance(
    instance: string | T,
    populate?: OfflinePopulateType,
  ): Promise<T | undefined> {
    try {
      return await this.getInstance(instance, populate);
    } catch {
      // no need to log anything - .getInstance will log if the instance is not found.
    }

    return undefined;
  }

  public async getOriginalInstance(instance: string | T): Promise<T> {
    try {
      // First try to fetch a value from the originalInstance column in case originalInstance already exists:
      return (await this.dbService.getGenericObject(
        instance,
        this.params,
        'originalInstance',
        undefined,
      )) as T;
    } catch (error) {
      // If the originalInstance value is empty, use the current instance instead:
      return await this.getInstance(instance, undefined);
    }
  }

  public async getInstanceAsDataResponse(
    instance: string | T,
    populate?: OfflinePopulateType,
  ): Promise<DataResponse<T>> {
    return await this.dbService.getGenericObjectAsDataResponse(
      instance,
      this.params,
      undefined,
      populate,
    );
  }

  public async getInstances(
    pageSize?: number,
    pageIndex?: number,
    sortColumn?: string,
    sortDirection?: SQLSortDirection,
    sqlFilter?: string,
    populate?: OfflinePopulateType,
  ): Promise<T[]> {
    const instances = (await this.dbService.getGenericObjects(
      this.params,
      pageSize,
      pageIndex,
      sortColumn,
      sortDirection,
      sqlFilter,
      populate,
    )) as T[];
    this.logger.debug()(`Instances from ${this.params.tableName} (children: ${instances.length}):`);

    // commented out because of memory spikes in production
    // https://remberg.atlassian.net/browse/S6-133
    // this.logger.debug()(instances);

    return instances;
  }

  public async getInstancesAsDataResponse(
    pageSize?: number,
    pageIndex?: number,
    sortColumn?: string,
    sortDirection?: SQLSortDirection,
    sqlFilter?: string,
    populate?: OfflinePopulateType,
  ): Promise<DataResponse<T>[]> {
    const instances = (await this.dbService.getGenericObjectsAsDataResponse(
      this.params,
      pageSize,
      pageIndex,
      sortColumn,
      sortDirection,
      sqlFilter,
      populate,
    )) as DataResponse<T>[];
    this.logger.debug()(`Instances with DataResponse from ${this.params.tableName}:`);
    this.logger.debug()(instances);
    return instances;
  }

  public async getInstancesWithCount(
    pageSize?: number,
    pageIndex?: number,
    sortColumn?: string,
    sortDirection?: SQLSortDirection,
    sqlFilter?: string,
    populate?: OfflinePopulateType,
  ): Promise<ApiResponse<T[]>> {
    const results = await this.getInstances(
      pageSize,
      pageIndex,
      sortColumn,
      sortDirection,
      sqlFilter,
      populate,
    );
    const count = await this.countInstances(sqlFilter);
    return new ApiResponse<T[]>(results, count);
  }

  public async getInstancesWithCountAsDataResponse(
    pageSize?: number,
    pageIndex?: number,
    sortColumn?: string,
    sortDirection?: SQLSortDirection,
    sqlFilter?: string,
    populate?: OfflinePopulateType,
  ): Promise<ApiResponse<DataResponse<T>[]>> {
    const results = await this.getInstancesAsDataResponse(
      pageSize,
      pageIndex,
      sortColumn,
      sortDirection,
      sqlFilter,
      populate,
    );
    const count = await this.countInstances(sqlFilter);
    return new ApiResponse<DataResponse<T>[]>(results, count);
  }

  public async countInstances(sqlFilter?: string): Promise<number> {
    return this.dbService.countGenericObjects(this.params, sqlFilter);
  }

  public async getIDs(onlineStatus?: OnlineStatusDataTypeEnum): Promise<string[]> {
    const allIDs: string[] = [];
    const batchSize = 1000;
    const sqlFilter = onlineStatus ? `onlineStatus = '${onlineStatus}'` : undefined;

    let results: string[] = [];
    let pageCount = 0;

    do {
      results = await this.dbService.getGenericObjectIDs(
        this.params,
        sqlFilter,
        batchSize,
        pageCount,
      );
      pageCount++;
      allIDs.push(...results);
    } while (results.length > 0);

    return allIDs;
  }

  public async addInstance(
    instance: T,
    onlineStatus:
      | OnlineStatusDataTypeEnum.ONLINE
      | OnlineStatusDataTypeEnum.OFFLINE_CREATION = OnlineStatusDataTypeEnum.ONLINE,
  ): Promise<DataResponse<T> | undefined> {
    const result = await this.createInstanceHelper(instance, onlineStatus);
    // increment outstanding changes count in case of OFFLINE_CREATION
    if (OnlineStatusDataTypeEnum.OFFLINE_CREATION === onlineStatus) {
      this.outstandingChangesCount.next(this.outstandingChangesCount.getValue() + 1);
    }
    return result;
  }

  public async updateInstance(
    instance: T,
    onlineStatus:
      | OnlineStatusDataTypeEnum.ONLINE
      | OnlineStatusDataTypeEnum.OFFLINE_CHANGE = OnlineStatusDataTypeEnum.ONLINE,
    populate?: OfflinePopulateType,
  ): Promise<DataResponse<T> | undefined> {
    let originalInstance: T | undefined = undefined;
    let computedOnlineStatus: OnlineStatusDataTypeEnum = onlineStatus;
    let outstandingChangesCountModifier = 0;
    if (onlineStatus === OnlineStatusDataTypeEnum.OFFLINE_CHANGE) {
      // if switching from ONLINE to OFFLINE_CHANGE, the previous instance must be stored
      // in the originalInstance column for future use (e.g. merge).
      // Further updates that are still OFFLINE_CHANGE need to carry this information over!
      // Thus, when onlineStatus is OFFLINE_CHANGE, we always need to store the originalInstance.
      originalInstance = await this.getOriginalInstance(instance);
      if (!originalInstance) {
        throw new Error('updateInstance - originalInstance could not be found.');
      }

      // if applying an OFFLINE_CHANGE to an OFFLINE_CREATION instance,
      // we must maintain the OFFLINE_CREATION onlineStatus
      const currentInstance = await this.getInstanceAsDataResponse(instance, undefined);
      if (!currentInstance) {
        throw new Error('updateInstance - currentInstance could not be found.');
      }
      if (currentInstance.onlineStatus === OnlineStatusDataTypeEnum.OFFLINE_CREATION) {
        computedOnlineStatus = OnlineStatusDataTypeEnum.OFFLINE_CREATION;
      }

      // only if the instance's onlineStatus is currently ONLINE,
      // we need to increase the outstandingChangesCount
      if (currentInstance.onlineStatus === OnlineStatusDataTypeEnum.ONLINE) {
        outstandingChangesCountModifier = 1;
      }
    }
    // This decreases the outstanding count by one if onlineStatus is offline.
    await this.deleteInstanceHelper(instance);
    const result = await this.createInstanceHelper(
      instance,
      computedOnlineStatus,
      originalInstance,
      populate,
    );
    if (outstandingChangesCountModifier !== 0) {
      this.outstandingChangesCount.next(
        this.outstandingChangesCount.getValue() + outstandingChangesCountModifier,
      );
    }
    return result;
  }

  public async updateInstanceAndStatus(
    instance: T,
    onlineStatus: OnlineStatusDataTypeEnum.ONLINE | OnlineStatusDataTypeEnum.OFFLINE_CHANGE,
  ): Promise<DataResponse<T> | undefined> {
    let originalInstance: T | undefined = undefined;

    if (onlineStatus === OnlineStatusDataTypeEnum.OFFLINE_CHANGE) {
      originalInstance = await this.getOriginalInstance(instance);

      if (!originalInstance) {
        throw new Error('updateInstance - originalInstance could not be found.');
      }
    }

    await this.deleteInstanceHelper(instance);
    return await this.createInstanceHelper(instance, onlineStatus, originalInstance, false);
  }

  public async bulkAddUpdateInstances(
    instances: T[],
    onlineStatus = OnlineStatusDataTypeEnum.ONLINE,
  ): Promise<void> {
    const dataValuesArray: SQLDataValues = [];
    for (const instance of instances) {
      const dataValues = this.generateDataValues(instance, onlineStatus);
      dataValuesArray.push(dataValues);
    }
    return this.dbService.bulkAddUpdateGenericObjects(dataValuesArray, this.params);
  }

  public async deleteInstance(instance: string | T): Promise<void> {
    const currentInstance = await this.getInstanceAsDataResponse(instance, undefined);

    // if we delete an OFFLINE_CREATION or OFFLINE_CHANGE instance, the outstandingChangesCount must be decremented
    if (
      currentInstance?.onlineStatus === OnlineStatusDataTypeEnum.OFFLINE_CREATION ||
      currentInstance?.onlineStatus === OnlineStatusDataTypeEnum.OFFLINE_CHANGE
    ) {
      return this.deleteInstanceHelper(instance, -1);
    }

    // if we delete an ONLINE instance, the outstandingChangesCount dont need to be updated
    return this.deleteInstanceHelper(instance);
  }

  public async bulkDeleteInstances(
    instances: string[] | T[],
    skipOfflineCreation?: boolean,
  ): Promise<void> {
    const createdIDs = await this.getIDs(OnlineStatusDataTypeEnum.OFFLINE_CREATION);
    let instancesToDelete = (instances as (string | T)[]).map((instance) => getStringID(instance));
    if (skipOfflineCreation) {
      instancesToDelete = instancesToDelete.filter(
        (instance) => !createdIDs.includes(getStringID(instance)),
      );
    }
    return this.dbService.deleteGenericObjects(instancesToDelete, this.params);
  }

  public async dropTable(): Promise<void> {
    return this.dbService.dropGenericTable(this.params);
  }

  public async getOfflineChangeIDs(): Promise<string[]> {
    const createdIDs = await this.getIDs(OnlineStatusDataTypeEnum.OFFLINE_CREATION);
    const updatedIDs = await this.getIDs(OnlineStatusDataTypeEnum.OFFLINE_CHANGE);
    return createdIDs.concat(updatedIDs);
  }

  protected generateDataValues(
    instance: T,
    onlineStatus: OnlineStatusDataTypeEnum,
    originalInstance?: T,
  ): SQLDataValues {
    const dataValues = [
      getStringID(instance),
      onlineStatus,
      JSON.stringify(instance),
      originalInstance ? JSON.stringify(originalInstance) : null,
    ];
    return dataValues.concat(this.convertInstanceToDataValues(instance));
  }

  protected convertInstanceToDataValues(instance: T): SQLDataValues {
    const result = [];
    for (const column of Object.keys(this.params?.columns ?? {})) {
      result.push(this.params.columns?.[column]?.valueFunction(instance));
    }
    return result;
  }

  private async createInstanceHelper(
    instance: T,
    onlineStatus: OnlineStatusDataTypeEnum,
    originalInstance?: T,
    populate?: OfflinePopulateType,
  ): Promise<DataResponse<T> | undefined> {
    const data = this.generateDataValues(instance, onlineStatus, originalInstance);
    await this.dbService.addGenericObject(data, this.params);
    this.logger.debug()('Object successfully created with data: ', data);
    if (populate) {
      return await this.getInstanceAsDataResponse(instance, populate);
    } else {
      return new DataResponse<T>(instance, onlineStatus);
    }
  }

  private async deleteInstanceHelper(
    instance: string | T,
    outstandingChangesCountModifier?: number,
  ): Promise<void> {
    const result = await this.dbService.deleteGenericObject(instance, this.params);
    if (outstandingChangesCountModifier) {
      this.outstandingChangesCount.next(
        this.outstandingChangesCount.getValue() + outstandingChangesCountModifier,
      );
    }
    return result;
  }
}
