import { AppInjector, ButtonActions, DEFAULT_DIALOG_WIDTH } from '@remberg/global/ui';
import { LayoutService } from '../services/layout.service';
import { DialogOptions } from './dialogs';
import { DynamicPopUpComponent } from './dynamic-pop-up/dynamic-pop-up.component';

export function getDefaultConfirmationDialogOptions(
  descriptionTexts: string[] = [$localize`:@@confirmation:Confirmation`, ''],
  iconKey: string = 'warning_amber',
  redDescriptionColor: boolean = false,
  buttonTexts: string[] = [$localize`:@@cancel:Cancel`, $localize`:@@confirm:Confirm`],
): DialogOptions<DynamicPopUpComponent> {
  const layout = AppInjector.get(LayoutService);

  return {
    childComponent: DynamicPopUpComponent,
    dialogData: {
      wrapperInput: {
        headerShow: false,
        removeBottomMargin: false,
        overflowXHidden: false,
        overflowYHidden: false,
        styleNoMargin: false,
        styleMinWidth: layout.isXSmallView.getValue() ? undefined : DEFAULT_DIALOG_WIDTH,
        styleMaxWidth: layout.isXSmallView.getValue() ? undefined : '500px',
        styleWidth: DEFAULT_DIALOG_WIDTH,
        styleHeight: 'auto',
      },
      factoryInput: [
        { buttonsDirection: 'vertical' },
        {
          buttons: [
            {
              text: buttonTexts[0],
              category: 'danger',
              action: ButtonActions.ABORT,
              dataTestId: 'button-abort',
            },
            {
              text: buttonTexts[1],
              category: 'success',
              color: 'primary',
              action: ButtonActions.CONFIRM,
              dataTestId: 'button-confirm',
            },
          ],
        },
        {
          icon: {
            icon: iconKey,
            color: 'primary',
          },
        },
        {
          title: {
            text: descriptionTexts[0],
            position: 'center',
          },
        },
        {
          description: {
            text: [descriptionTexts[1]],
            position: 'center',
            redColor: redDescriptionColor,
          },
        },
        { showDoNotAskAgain: false },
        { hideAbortButton: true },
      ],
    },
  };
}

export function getDefaultDeletionDialogOptions(
  descriptionTexts: string[] = [$localize`:@@delete:Delete`, ''],
  iconKey: string = 'delete',
  redDescriptionColor: boolean = false,
  buttonTexts: string[] = [$localize`:@@cancel:Cancel`, $localize`:@@delete:Delete`],
): DialogOptions<DynamicPopUpComponent> {
  const layout = AppInjector.get(LayoutService);

  return {
    childComponent: DynamicPopUpComponent,
    dialogData: {
      wrapperInput: {
        headerShow: false,
        removeBottomMargin: false,
        overflowXHidden: false,
        overflowYHidden: true,
        styleNoMargin: false,
        styleMinWidth: layout.isXSmallView.getValue() ? undefined : DEFAULT_DIALOG_WIDTH,
        styleMaxWidth: layout.isXSmallView.getValue() ? undefined : '500px',
        styleWidth: DEFAULT_DIALOG_WIDTH,
        styleHeight: 'auto',
      },
      factoryInput: [
        { buttonsDirection: 'vertical' },
        {
          buttons: [
            {
              text: buttonTexts[0],
              category: 'danger',
              action: ButtonActions.ABORT,
              dataTestId: 'button-abort',
            },
            {
              text: buttonTexts[1],
              category: 'success',
              color: 'warn',
              action: ButtonActions.CONFIRM,
              dataTestId: 'button-confirm',
            },
          ],
        },
        {
          icon: {
            icon: iconKey,
            color: 'error',
          },
        },
        {
          title: {
            text: descriptionTexts[0],
            position: 'center',
          },
        },
        {
          description: {
            text: [descriptionTexts[1]],
            position: 'center',
            redColor: redDescriptionColor,
          },
        },
        { showDoNotAskAgain: false },
        { hideAbortButton: true },
      ],
    },
  };
}

export function getDefaultWarningDialogOptions(
  descriptionTexts: string[] = [$localize`:@@warning:Warning`, ''],
  iconKey: string = 'warning_amber',
  redDescriptionColor: boolean = false,
  buttonTexts: string[] = [$localize`:@@cancel:Cancel`, $localize`:@@confirm:Confirm`],
): DialogOptions<DynamicPopUpComponent> {
  const layout = AppInjector.get(LayoutService);

  return {
    childComponent: DynamicPopUpComponent,
    dialogData: {
      wrapperInput: {
        headerShow: false,
        removeBottomMargin: false,
        overflowXHidden: false,
        overflowYHidden: true,
        styleNoMargin: false,
        styleMinWidth: layout.isXSmallView.getValue() ? undefined : DEFAULT_DIALOG_WIDTH,
        styleMaxWidth: layout.isXSmallView.getValue() ? undefined : '500px',
        styleWidth: DEFAULT_DIALOG_WIDTH,
        styleHeight: 'auto',
      },
      factoryInput: [
        { buttonsDirection: 'vertical' },
        {
          buttons: [
            {
              text: buttonTexts[0],
              category: 'danger',
              action: ButtonActions.ABORT,
              dataTestId: 'button-abort',
            },
            {
              text: buttonTexts[1],
              category: 'success',
              color: 'primary',
              action: ButtonActions.CONFIRM,
              dataTestId: 'button-confirm',
            },
          ],
        },
        {
          icon: {
            icon: iconKey,
            color: 'warning',
          },
        },
        {
          title: {
            text: descriptionTexts[0],
            position: 'center',
          },
        },
        {
          description: {
            text: [descriptionTexts[1]],
            position: 'center',
            redColor: redDescriptionColor,
          },
        },
        { showDoNotAskAgain: false },
        { hideAbortButton: true },
      ],
    },
  };
}
