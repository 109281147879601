import { ContactCompatibility, OrganizationCompatibility } from '@remberg/crm/common/main';
import { MessageStatus } from '@remberg/emails/common/main';
import { TicketActivityType } from '@remberg/tickets/common/main';

export enum CaseActivitySystemMessageType {
  PDF_EXPORT = 'pdfExport',
  IMPORT = 'import',
}

export interface Activity {
  _id: string;
  kind: TicketActivityType;
  timeStamp: Date;
  creator: ContactCompatibility | string;
  creatorOrganization: OrganizationCompatibility | string;
  tenantId: string;
}

export interface MessageActivity extends Activity {
  from: string;
  files: string[];
  plainText: string;
  html?: string;
  strippedHtml?: string;
  kind: TicketActivityType.OUTBOUND_EMAIL_MESSAGE;
  subject?: string;
  to?: string[];
  cc?: string[];
  bcc?: string[];
  toContact?: string | ContactCompatibility;
  strippedPlainText?: string;
  status: MessageStatus;
  isStatusIgnored?: boolean;
}

export interface PortalMessageActivity extends Activity {
  from: string;
  files: string[];
  plainText: string;
  html?: string;
  strippedHtml?: string;
  kind: TicketActivityType.PORTAL_MESSAGE;
  subject?: string;
  to?: string[];
  toContact?: string | ContactCompatibility;
  cc?: string[];
  bcc?: string[];
  strippedPlainText?: string;
}

export interface EmailMessageActivity extends Activity {
  from?: string;
  files: string[];
  plainText: string;
  html?: string;
  strippedHtml?: string;
  kind: TicketActivityType.INBOUND_EMAIL_MESSAGE;
  subject: string;
  messageID: string;
  senderEmail: string;
  senderName?: string;
  to?: string[];
  cc?: string[];
  bcc?: string[];
  toContact?: string | ContactCompatibility;
  strippedPlainText?: string;
}

export interface NoteActivity extends Activity {
  files: string[];
  plainText: string;
  html?: string;
  strippedHtml?: string;
  strippedPlainText?: string;
  kind: TicketActivityType.NOTE;
  senderEmail?: string;
  senderName?: string;
  to?: string[];
  cc?: string[];
  bcc?: string[];
  toContact?: string | ContactCompatibility;
  subject?: string;
}

export interface ChangeActivity extends Activity {
  propertyName: string;
  oldValue: unknown;
  newValue: unknown;
  kind: TicketActivityType.CHANGE;
}

export interface OemFeedbackActivity extends Activity {
  problem: string;
  solution: string;
  materialEffort: string;
  travelTimeEffort: string;
  onPlaceTimeEffort: string;
  internalTimeEffort: string;
  kind: TicketActivityType.OEM_FEEDBACK;
}

export interface SystemMessageActivity extends Activity {
  kind: TicketActivityType.SYSTEM_MESSAGE;
  messageType: CaseActivitySystemMessageType;
  files: string[];
}

export interface MovedActivity extends Activity {
  kind: TicketActivityType.CASE_MOVED;
  sourceTicketId: string;
  destinationTicketId: string;
  movedActivityIds: string[];
  isUndone: boolean;
}

export interface MovedUndoActivity extends Activity {
  kind: TicketActivityType.CASE_MOVED_UNDO;
  sourceTicketId: string;
  destinationTicketId: string;
}

export type ConversationActivity =
  | MessageActivity
  | EmailMessageActivity
  | NoteActivity
  | PortalMessageActivity;
export type MoveableActivity = ConversationActivity;

export const isConversationActivity = (activity: Activity): activity is MoveableActivity =>
  (activity as MoveableActivity).strippedPlainText !== undefined;

export const isMovableActivity = isConversationActivity;
