import { Injectable } from '@angular/core';
import { Product } from '@remberg/assets/common/main';
import { OrganizationBasic } from '@remberg/crm/common/base';
import { UnknownOr } from '@remberg/global/common/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailService {
  private parentAssetSelection = new Subject<UnknownOr<Product>>();
  private customerSelection = new Subject<UnknownOr<OrganizationBasic>>();

  public setParentAssetSelection(product: UnknownOr<Product>): void {
    this.parentAssetSelection.next(product);
  }

  public getParentAssetSelectionObservable(): Observable<UnknownOr<Product>> {
    return this.parentAssetSelection;
  }

  public setCustomerSelection(account: UnknownOr<OrganizationBasic>): void {
    this.customerSelection.next(account);
  }

  public getCustomerSelectionObservable(): Observable<UnknownOr<OrganizationBasic>> {
    return this.customerSelection;
  }
}
