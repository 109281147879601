import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Flavor,
  IsoLanguageCodesEnum,
  LanguageCodeEnum,
  LanguageCodesDateFns,
  RembergDate,
  RembergDateTime,
  RembergTime,
  RembergTimestamp,
  TimeZoneEnum,
  dateToRembergTimestamp,
  getDefaultLanguageCodeEnumForString,
  intervalToDurationWithZeroes,
  isRembergDateTimeString,
} from '@remberg/global/common/core';
import { DATE_REGEX, ISO_DATE_REGEX, LogService } from '@remberg/global/ui';
import {
  Duration,
  Locale,
  add,
  addMilliseconds,
  compareAsc,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  formatDistance,
  formatDistanceToNow,
  getWeek,
  isValid,
  minutesToMilliseconds,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  sub,
  toDate,
} from 'date-fns';
import { formatInTimeZone, fromZonedTime, getTimezoneOffset, toZonedTime } from 'date-fns-tz';
import { de, el, enUS, es, fr, it, th, tr } from 'date-fns/locale';
import { BehaviorSubject, distinctUntilChanged, map, tap } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../store';
import { convertRembergDateToDateWithCurrentTimezone } from './rembergDateAdapter';

/** JSON linking date-fns locales to IsoLanguageCodesEnum */
const IsoLanguageCodesDateFns: { [id in IsoLanguageCodesEnum]: Locale } = {
  en: enUS,
  de: de,
  tr: tr,
  fr: fr,
  es: es,
  it: it,
  el: el,
  th: th,
};

type Digit = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '0';
type ZeroToFive = '0' | '1' | '2' | '3' | '4' | '5';
type ZeroToThree = '0' | '1' | '2' | '3';

/**
 * Time string in 12h format "hh:MM aa"
 *
 * @TJS-type string
 */
export type TimeString12h = Flavor<string, 'hh:MM aa'>;

export interface TimeProperties {
  hours: `${'0' | '1'}${Digit}` | `2${ZeroToThree}` | '24';
  minutes: `${ZeroToFive}${Digit}`;
  amPm?: `${'AM' | 'PM'}`;
}

@Injectable({
  providedIn: 'root',
})
export class DatetimeService {
  private tz$ = new BehaviorSubject<TimeZoneEnum | undefined>(undefined);

  constructor(
    private store: Store<RootGlobalState>,
    private logger: LogService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.store
      .select(GlobalSelectors.selectSessionInfo)
      .pipe(
        map((sessionInfo) => sessionInfo?.rembergUser?.tz ?? sessionInfo?.organization?.crmData.tz),
        distinctUntilChanged(),
        tap((tz) => this.logger.debug()('Setting timezone to ' + tz)),
      )
      .subscribe(this.tz$);
  }

  public getUserTimezone(): TimeZoneEnum {
    return this.tz$.getValue() ?? TimeZoneEnum['Etc/UTC'];
  }

  public getCurrentLanguageCode(): LanguageCodeEnum {
    return this.locale ? getDefaultLanguageCodeEnumForString(this.locale) : LanguageCodeEnum.EN_US;
  }

  public now(options?: { withoutSeconds?: boolean }): RembergDateTime {
    const now = new Date();

    if (options?.withoutSeconds) {
      now.setSeconds(0);
      now.setMilliseconds(0);
    }

    return toRembergDateTime(now, this.getUserTimezone());
  }

  /**
   * Returns the formated string for a provided date
   * @param {Date | string} date - The date being formatted
   * @param {string} formatString - Date according to https://date-fns.org/v2.20.2/docs/format
   */
  public formatDate(date: Date | string, formatString: string): string {
    const lang = this.getCurrentLanguageCode();
    return formatDateWithLanguageCode(date, formatString, lang);
  }

  /**
   * Returns the formated string for a provided date
   * @param {Date | string} date - The date being formatted
   * @param {TimeZoneEnum} timezone - (e.g. Europe/Berlin)
   * @param {string} formatString - Date according to https://date-fns.org/v2.20.2/docs/format
   * @returns formatted string against the locale
   */
  public formatDateInTimezone(
    date: Date | string,
    timezone: TimeZoneEnum,
    formatString: string,
  ): string {
    const lang = this.getCurrentLanguageCode();
    return formatInTimeZone(date, timezone, formatString, {
      locale: LanguageCodesDateFns[lang],
    });
  }

  /**
   * @param {RembergTime} time - The 24h time string being formatted (13:30)
   * @returns formatted time string against the locale (e.g. 1:30 PM).
   */
  public formatRembergTime(time: RembergTime): string {
    const lang = this.getCurrentLanguageCode();

    const [HH, mm] = time.split(':').map((t) => +t);
    const date = new Date(0, 0, 0, HH, mm);

    return format(date, 'p', {
      locale: LanguageCodesDateFns[lang],
    });
  }

  /**
   * Returns the formated string for a provided date
   * @param {Date | string} timeStamp - The first date
   * @param {Date | string} timeStampTo - The second optional date. If not provided, the current time will be used.
   * timeStamp - timeStampTo: Negative values are possible (e.g. 1 day ago vs. in 1 day)
   */
  public getElapsedTime(timeStamp: string | Date, timeStampTo?: string | Date): string {
    const lang = this.getCurrentLanguageCode();
    return getElapsedTime(timeStamp, timeStampTo, lang);
  }

  /**
   * Returns date in local (user's) timezone.
   * If nothins is passed, returns the current Date.
   * @param {Date} date - The first date
   */
  public getLocalizedDate(date?: Date): Date {
    const formatDate: Date = date ? date : new Date();
    const tz: TimeZoneEnum = this.getUserTimezone();
    return getLocalizedDate(formatDate, tz);
  }

  /**
   * Returns the formated string for a provided date
   * @param {Date | string} timeStamp - The first date
   * @param {Date | string} timeStampTo - The second optional date. If not provided, the current time will be used.
   */
  public msToHHMM(milliseconds: number): string {
    const lang = this.getCurrentLanguageCode();
    return msToHHMM(milliseconds, lang);
  }

  public processDate(date: Date | string, tz?: TimeZoneEnum): Date {
    if (tz === undefined) {
      tz = this.getUserTimezone();
    }
    return processDate(date, tz);
  }

  public parseFormatString(value: string, format?: string | string[]): Date {
    const lang = this.getCurrentLanguageCode();
    return parseFormatString(value, format, lang);
  }

  public getUTCDate(date: Date, tz?: TimeZoneEnum): Date {
    if (!tz) {
      tz = this.getUserTimezone();
    }
    return getUTCDate(date, tz);
  }

  public getStartOfTheWeek(date: Date): Date {
    const lang = this.getCurrentLanguageCode();
    return getStartOfTheWeek(date, lang);
  }

  public getEndOfTheWeek(date: Date): Date {
    const lang = this.getCurrentLanguageCode();
    return getEndOfTheWeek(date, lang);
  }

  public getWeekNumber(date: Date): number {
    const lang = this.getCurrentLanguageCode();
    return getWeekNumber(date, lang);
  }

  public getOffsetTimeZone(date?: Date): number {
    const tz = this.getUserTimezone();
    return getOffsetTimeZone(tz, date);
  }

  public is24HourFormat(): boolean {
    const languageCode = this.getCurrentLanguageCode();
    return (
      new Date(0).toLocaleTimeString(languageCode, { hour12: false }) ===
      new Date(0).toLocaleTimeString(languageCode)
    );
  }
}

export function getTzDateInLanguageFormat(
  date: Date,
  languageCode: IsoLanguageCodesEnum,
  tz: TimeZoneEnum = TimeZoneEnum['Europe/Berlin'],
): string {
  if (!date) {
    return '';
  }
  const localDate: Date = toZonedTime(date, tz);

  const locale: Locale = IsoLanguageCodesDateFns[languageCode];

  return format(localDate, 'P', {
    locale: locale,
  });
}

export function getTzDatetimeInLanguageFormat(
  date?: Date,
  languageCode?: IsoLanguageCodesEnum,
  tz: TimeZoneEnum = TimeZoneEnum['Europe/Berlin'],
): string {
  if (!date || !languageCode) {
    return '';
  }
  const localDate: Date = toZonedTime(date, tz);

  const locale: Locale = IsoLanguageCodesDateFns[languageCode];

  return format(localDate, 'Pp', {
    locale: locale,
  });
}

export function getTzTimeInLanguageFormat(
  date: Date,
  languageCode: IsoLanguageCodesEnum,
  tz: TimeZoneEnum = TimeZoneEnum['Europe/Berlin'],
): string {
  if (!date) {
    return '';
  }
  const localDate: Date = toZonedTime(date, tz);

  const locale: Locale = IsoLanguageCodesDateFns[languageCode];

  return format(localDate, 'p', {
    locale: locale,
  });
}

/**
 * Formats a date with the given format string and the given languageCode.
 * If no language code is passed, we assume american english.
 */
export function formatDateWithLanguageCode(
  date: Date | string,
  formatString: string,
  languageCode: LanguageCodeEnum = LanguageCodeEnum.EN_US,
): string {
  const value = processDate(date);
  if (languageCode) {
    return format(value, formatString, {
      locale: LanguageCodesDateFns[languageCode],
    });
  }
  return format(value, formatString);
}

export function getElapsedTime(
  timeStamp: string | Date,
  toTimeStamp?: string | Date,
  languageCode: LanguageCodeEnum = LanguageCodeEnum.EN_US,
): string {
  const timeStampDate = processDate(timeStamp);
  const toTimeStampDate = isDateValid(toTimeStamp) ? processDate(toTimeStamp) : undefined;

  if (toTimeStampDate) {
    return formatDistance(toTimeStampDate, timeStampDate, {
      locale: LanguageCodesDateFns[languageCode],
      addSuffix: true,
    });
  }
  return formatDistanceToNow(timeStampDate, {
    locale: LanguageCodesDateFns[languageCode],
    addSuffix: true,
  });
}

export function msToHHMM(
  milliseconds: number | undefined,
  languageCode: LanguageCodeEnum = LanguageCodeEnum.EN_US,
): string {
  if (milliseconds === undefined || isNaN(milliseconds) || milliseconds <= 0) {
    return $localize`:@@0h0m:0h 0m`;
  }
  const referenceDate = new Date(0);
  const newDate = addMilliseconds(new Date(0), milliseconds);
  const duration = intervalToDurationWithZeroes({
    start: referenceDate,
    end: newDate,
  });

  const hours = differenceInHours(newDate, referenceDate);
  const minutes = differenceInMinutes(newDate, referenceDate);
  if (hours > 99) {
    return $localize`:@@over99h59m:> 99h 59m`;
  }
  if (minutes < 1) {
    return $localize`:@@less0h1m:< 0h 1m`;
  }

  // TODO: Localize format
  return `${hours}h ${duration.minutes}m`;
}

export function msToDDHHMM(milliseconds: number): Duration {
  const referenceDate = new Date(0);
  if (isNaN(milliseconds)) {
    milliseconds = 0;
  }
  // larger values cannot be transformed via the date-fns methods
  if (milliseconds > 8640000000000000) {
    return {
      days: Math.floor(milliseconds / 24 / 3600 / 1000),
      hours: Math.floor((milliseconds % (24 * 3600 * 1000)) / 3600 / 1000),
      minutes: Math.floor((milliseconds % (3600 * 1000)) / 60 / 1000),
      seconds: Math.floor((milliseconds % (60 * 1000)) / 1000),
    };
  }
  const newDate = addMilliseconds(new Date(0), milliseconds);

  return intervalToDurationWithZeroes({
    start: referenceDate,
    end: newDate,
  });
}

export function checkValidHHMMString(timeString: string): boolean {
  const validDate = parseFormatString(timeString, ['hh:mm a', 'HH:mm']);
  return isValid(validDate);
}

export function getWeekNumber(
  currDate: Date,
  languageCode: LanguageCodeEnum = LanguageCodeEnum.EN_US,
): number {
  return getWeek(currDate, {
    locale: LanguageCodesDateFns[languageCode],
    weekStartsOn: 1,
    firstWeekContainsDate: 4,
  });
}

export function getStartOfTheDay(currDate: Date): Date {
  return startOfDay(currDate);
}

export function getEndOfTheDay(currDate: Date): Date {
  return endOfDay(currDate);
}

export function getStartOfTheWeek(
  currDate: Date,
  languageCode: LanguageCodeEnum = LanguageCodeEnum.EN_US,
): Date {
  return startOfWeek(currDate, {
    locale: LanguageCodesDateFns[languageCode],
  });
}

export function getEndOfTheWeek(
  currDate: Date,
  languageCode: LanguageCodeEnum = LanguageCodeEnum.EN_US,
): Date {
  return endOfWeek(currDate, {
    locale: LanguageCodesDateFns[languageCode],
  });
}

export function isDateValid(input: string | Date | undefined | null): input is string | Date {
  let value: Date;
  if (typeof input === 'string') {
    value = parseISO(input);
  } else if (input === undefined || input === null) {
    return false;
  } else {
    value = input;
  }

  return isValid(value);
}

export function parseDateString(input: string): Date {
  return parseISO(input);
}

export function parseFormatString(
  value: string,
  format?: string | string[],
  languageCode: LanguageCodeEnum = LanguageCodeEnum.EN_US,
): Date {
  if (!format) {
    return parseDateString(value);
  }
  if (Array.isArray(format)) {
    const formatString = format.shift();
    const tempResult = parseFormatString(value, formatString, languageCode);
    if (isValid(tempResult) || format.length < 1) {
      return tempResult;
    } else {
      return parseFormatString(value, format, languageCode);
    }
  } else {
    return parse(value, format, 0, {
      locale: LanguageCodesDateFns[languageCode],
    });
  }
}

export function getLocalizedDate(date: Date, tz: TimeZoneEnum): Date {
  return toZonedTime(date, tz);
}

export function processDate(date: Date | string, tz?: TimeZoneEnum): Date {
  let value: Date;
  if (typeof date === 'string') {
    value = parseDateString(date);
  } else {
    value = toDate(date);
  }
  if (tz) {
    return getLocalizedDate(value, tz);
  }
  return value;
}

/**
 * Checks if the input is a valid date in format: "yyyy-MM-dd"
 * @param {RembergDate} input - The remberg date string (e.g 2022-08-22)
 */
export function isValidRembergDate(input: string): input is RembergDate {
  if (!isRembergDateString(input)) {
    return false;
  }

  const date = new Date(input);
  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(input);
}

/**
 * Compare the two dates and return 1 if the first date is after the second,
 * -1 if the first date is before the second or 0 if dates are equal.
 * @param {Date} date1 - The date being formatted
 * @param {Date} date2 - Include localized data format (e.g. de-DE: DD.MM.YYYY)
 */
export function compareDates(date1: Date, date2: Date): number {
  return compareAsc(date1, date2);
}

export function getDateDiff(date1: Date, date2: Date): number {
  return differenceInMilliseconds(date1, date2);
}

export function getUTCDate(date: Date, tz: TimeZoneEnum): Date {
  return fromZonedTime(date, tz);
}

export function getOffsetTimeZone(tz: TimeZoneEnum, date?: Date): number {
  const offset = getTimezoneOffset(tz, date);
  return offset / 3600000;
}

/**
 * Returns the amount of days between two dates
 * @param {Date} startDate - The date of start
 * @param {Date} endDate - The date of end
 */
export function getDifferenceInDays(startDate: Date, endDate: Date): number;
/**
 * Returns the amount of days between two Remberg dates
 * @param {Date} startDate - The date of start
 * @param {Date} endDate - The date of end
 */
export function getDifferenceInDays(startDate: RembergDate, endDate: RembergDate): number;
export function getDifferenceInDays(
  startDate: Date | RembergDate,
  endDate: Date | RembergDate,
): number {
  const start =
    typeof startDate === 'string'
      ? convertRembergDateToDateWithCurrentTimezone(startDate)
      : startDate;
  const end =
    typeof endDate === 'string' ? convertRembergDateToDateWithCurrentTimezone(endDate) : endDate;

  return differenceInDays(start, end);
}

/**
 * pareses the time into hours, minutes and AM/PM.
 * @param {RembergTime | TimeString12h} time
 * @returns {TimeProperties}
 */
export function parseRembergTime(time: RembergTime | TimeString12h): TimeProperties | undefined {
  if (isRembergTime(time)) {
    const [hours, minutes] = time.split(':');
    return { hours, minutes } as TimeProperties;
  }

  if (is12HourFormatTimeString(time)) {
    const [hours, minutes, amPm] = [...time.slice(0, -3).split(':'), time.slice(-2)];
    return { hours, minutes, amPm } as TimeProperties;
  }

  return undefined;
}

/**
 * converts a 12h formatted time string to 24h format
 * @param {TimeString12h} time12Hour - 12h formatted input string (e.g. 03:30 PM)
 */
export function convert12HoursToRembergTime(time12Hour: TimeString12h): RembergTime {
  if (is12HourFormatTimeString(time12Hour)) {
    const hoursNumber: number = parseInt(time12Hour.slice(0, 2));
    const suffix = time12Hour.slice(6, 8);
    let hours: string;
    if (hoursNumber === 12) {
      hours = suffix === 'PM' ? '12' : '00';
    } else if (suffix === 'PM') {
      hours = String(hoursNumber + 12);
    } else {
      hours = String(hoursNumber).padStart(2, '0');
    }
    const minutes = time12Hour.slice(3, 5);
    return (hours + ':' + minutes) as RembergTime;
  } else {
    throw new Error('Invalid 12h format string: ' + time12Hour);
  }
}

/**
 * converts a 24h formatted time string to 12h format
 * @param {RembergTime} time - 24h formatted input string (e.g. 15:30)
 */
export function convertRembergTimeTo12HoursString(time: RembergTime): TimeString12h {
  if (isRembergTime(time)) {
    const hours = String(+time.slice(0, 2) % 12 || 12).padStart(2, '0'); // map to 1...12
    const minutes = time.slice(3, 5);
    const suffix = +time.slice(0, 2) >= 12 ? ' PM' : ' AM';
    return (hours + ':' + minutes + suffix) as TimeString12h;
  } else {
    throw new Error('Invalid remberg time string: ' + time);
  }
}

/**
 * Converts the values to "yyyy-MM-ddTHH:mm:ss.SSSZ" format string
 * @param {RembergDate} date - "yyyy-MM-dd" format string (e.g. 2022-08-22)
 * @param {RembergTime} time - 24h formatted input string (e.g. 15:30)
 * @param {TimeZoneEnum} timezone - (e.g. Europe/Berlin)
 */
export function convertToUTCDate(
  date: RembergDate,
  time: RembergTime,
  timezone: TimeZoneEnum,
): string {
  return fromZonedTime(
    parse(`${date}${time}`, 'yyyy-MM-ddHH:mm', new Date()),
    timezone,
  ).toISOString();
}

/**
 * Converts the values to "yyyy-MM-ddTHH:mm:ss.SSSZ_Timezone" format string
 * @param {RembergDate} date - "yyyy-MM-dd" format string (e.g. 2022-08-22)
 * @param {RembergTime} time - 24h formatted input string (e.g. 15:30)
 * @param {TimeZoneEnum} timezone - (e.g. Europe/Berlin)
 */
export function convertToUTCDateWithTimezone(
  date: RembergDate,
  time: RembergTime,
  timezone: TimeZoneEnum,
): string {
  const utcDate = fromZonedTime(
    parse(`${date}${time}`, 'yyyy-MM-ddHH:mm', new Date()),
    timezone,
  ).toISOString();
  return `${utcDate}_${timezone}`;
}

/**
 * Returns 1 if val1 is greater than val2, 0 if both are equal and -1 otherwise.
 * @param {RembergTime} val1 - 24h formatted input string (e.g. 15:30)
 * @param {RembergTime} val2 - 24h formatted input string (e.g. 15:30)
 */
export function compareRembergTimeValues(val1: RembergTime, val2: RembergTime): number {
  return parseInt(val1.replace(':', '')) > parseInt(val2.replace(':', ''))
    ? 1
    : val1 === val2
      ? 0
      : -1;
}

/**
 * Typeguard. Returns true if the input value is a RembergDate value.
 * @param {RembergDate} input - RembergDate input string (e.g. 2022-08-22)
 */
export function isRembergDateString(input: string): input is RembergDate {
  return RegExp(DATE_REGEX).test(input);
}

/**
 * Typeguard. Returns true if the input value is a RembergTime value.
 * @param {RembergTime} input - 24h formatted input string (e.g. 15:30)
 */
export function isRembergTime(input?: string): input is RembergTime {
  return !!input && RegExp(/^([01]\d|2[0-3])(:)([0-5]\d)$/).test(input);
}

/**
 * Returns true if the input value is a valid iso date string.
 * @param {string} input
 */
export function isISODateString(input: string): input is RembergTimestamp {
  return RegExp(ISO_DATE_REGEX).test(input);
}

/**
 * Returns true if the input value is a valid TimeZoneEnum.
 * @param {string} input
 */
export function isTimezoneString(input: string): input is TimeZoneEnum {
  return Object.values(TimeZoneEnum).includes(input as TimeZoneEnum);
}

/**
 * Typeguard. Returns true if the input value is a TimeString12h value.
 * @param {TimeString12h} input - 12h formatted input string (e.g. 05:30 PM)
 */
export function is12HourFormatTimeString(input?: string): input is TimeString12h {
  return !!input && RegExp(/^((0[1-9])|(1[0-2]))(:)([0-5]\d)(\s)([AP]M)?$/).test(input);
}

export function getMonthName(date: Date): string {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[date.getMonth()];
}

export function addMonthToDate(date: Date, monthsToAdd: number): Date {
  return add(date, { months: monthsToAdd });
}

export function addDaysToDate(date: Date, daysToAdd: number): Date {
  return add(date, { days: daysToAdd });
}

export function addHoursToDate(date: Date, hoursToAdd: number): Date {
  return add(date, { hours: hoursToAdd });
}

export function addDuration(date: Date, duration: Duration): Date {
  return add(date, duration);
}

export function subtractDuration(date: Date, duration: Duration): Date {
  return sub(date, duration);
}

export function getStartDateOfWeek(date: Date): Date {
  return startOfWeek(date);
}

export function getStartDateOfMonth(date: Date): Date {
  return startOfMonth(date);
}

export function getEndOfMonth(date: Date): Date {
  return endOfMonth(date);
}

export function getStartOfQuarter(date: Date): Date {
  return startOfQuarter(date);
}

export function getEndOfQuarter(date: Date): Date {
  return endOfQuarter(date);
}

export function getStartOfYear(date: Date): Date {
  return startOfYear(date);
}

export function getEndOfYear(date: Date): Date {
  return endOfYear(date);
}

export function minsToMilliseconds(mins: number): number {
  return minutesToMilliseconds(mins);
}

export function toRembergDate(date: Date, tz?: string): RembergDate {
  if (!date || isNaN(date.getTime())) {
    return 'INVALID_DATE';
  }

  return tz ? formatInTimeZone(date, tz, 'yyyy-MM-dd') : format(date, 'yyyy-MM-dd');
}

export function nowAsDate(): RembergDate {
  return toRembergDate(new Date());
}

export function toRembergTime(
  date: Date | RembergDateTime | RembergTimestamp,
  tz?: TimeZoneEnum,
): RembergTime {
  if (typeof date === 'string' && isRembergDateTimeString(date)) {
    return tz
      ? formatInTimeZone(new Date(date), tz, 'HH:mm')
      : (format(new Date(date), 'HH:mm') as RembergTime);
  }
  if (!date || typeof date === 'string' || isNaN(date.getTime())) {
    throw new Error('Invalid Date passed!');
  }
  return tz ? formatInTimeZone(date, tz, 'HH:mm') : (format(date, 'HH:mm') as RembergTime);
}

export function nowAsTime(): RembergTime {
  return toRembergTime(new Date());
}

export function toRembergTimestamp(
  date: Date | RembergDateTime | string | undefined | null,
): RembergTimestamp | undefined {
  if (!date) {
    return undefined;
  }

  if (typeof date !== 'string') {
    return isNaN(date.getTime()) ? undefined : dateToRembergTimestamp(date);
  }

  if (isISODateString(date)) {
    return date;
  }

  if (isRembergDateTimeString(date)) {
    const [isoDateString, tz] = date.split('_');
    return isoDateString;
  }

  return undefined;
}

/** Returns the date in UTC form, along with timezone information */
export function toRembergDateTime(date: Date | RembergTimestamp, tz?: string): RembergDateTime {
  const utc = typeof date === 'string' ? date : dateToRembergTimestamp(date);

  return [utc, tz].filter((e) => !!e).join('_');
}

/**
 * Returns the local time from a UTC ISO string, but treats it as UTC.
 * @example
 *    // If you pass in
 *      "2024-01-01T00:00:00.000Z, Europe/Berlin"
 *    // it returns
 *      "2024-01-01T01:00:00.000Z"
 *    // because it transforms it to the local time, but doesn't attach any timezone information.
 */
export function convertToTimezoneUtc(
  date: Date | string | number,
  timezone = TimeZoneEnum['Etc/UTC'],
): string {
  return format(toZonedTime(date, timezone), `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`);
}

/**
 * The reverse of `convertToTimezoneUtc`, receives a UTC string which is actually zoned,
 * and converts it to the real UTC value by removing the Z and converting the time.
 * @example
 *    // If you pass in
 *      "2024-01-01T01:00:00.000Z, Europe/Berlin"
 *    // it returns
 *      "2024-01-01T00:00:00.000Z"
 *    // because it transforms it to the UTC time, but doesn't attach any timezone information.
 */
export function convertTimezoneUtcToRealUtc(
  isoString: string,
  timezone = TimeZoneEnum['Etc/UTC'],
): string {
  return fromZonedTime(isoString.replace('Z', ''), timezone).toISOString();
}
