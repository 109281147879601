import { isObjectId } from '@remberg/global/common/core';

export function compareIDs(obj1: any, obj2: any): boolean {
  if (!obj1) {
    return obj1 === obj2;
  } else {
    return obj2 && getStringID(obj1) === getStringID(obj2);
  }
}

export function getStringID(obj1: any): string {
  if (!obj1) {
    return obj1;
  } else if (typeof obj1 === 'string') {
    return obj1;
  } else if (obj1._id) {
    return obj1._id;
  } else if (obj1.id) {
    return obj1.id;
  } else {
    // It is quite hard to fix this function without a huge code change all accross our codebase
    // in light of the strict typing changes for #5717 we will use this hack for now.
    return undefined as any as string;
  }
}

export function isObjectID(value: any): boolean {
  return isObjectId(value, false);
}

export function getEnumKeyByEnumValue(myEnum: any, enumValue: any) {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export function nullString(input: any): string {
  if (typeof input === 'string') {
    return input;
  } else if (input === null || input === undefined) {
    return '';
  } else {
    return String(input);
  }
}

export function deriveAddedAndRemovedArrayElements<T>(
  oldArray: T[],
  newArray: T[],
): { added: T[]; removed: T[]; untouched: T[] } {
  const res: {
    added: T[];
    removed: T[];
    untouched: T[];
  } = {
    added: [],
    removed: [],
    untouched: [],
  };
  res.removed = oldArray?.filter(
    (oldArrayElement) =>
      !newArray.find((newArrayElement) => compareIDs(newArrayElement, oldArrayElement)),
  );
  res.added = newArray?.filter(
    (newArrayElement) =>
      !oldArray.find((oldArrayElement) => compareIDs(oldArrayElement, newArrayElement)),
  );
  res.untouched = oldArray?.filter((oldArrayElement) =>
    newArray.find((newArrayElement) => compareIDs(newArrayElement, oldArrayElement)),
  );
  return res;
}
