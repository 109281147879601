import { TimeZoneEnum } from '@remberg/global/common/core';
import { FormFieldTypesEnum, FormSectionTypesEnum, FormTemplateConfig } from '../models';

export const EXAMPLE_MINIMAL_FORM_TEMPLATE_CONFIG: FormTemplateConfig = {
  general: {
    timezone: TimeZoneEnum['Europe/Berlin'],
    hideInstanceCounterInPdf: false,
  },
  sections: [
    {
      id: 'fieldSection_Statics',
      type: FormSectionTypesEnum.FIELD_SECTION,
      config: {
        headline: {
          text: {
            en: 'Static fields',
            de: 'Static fields',
            tr: 'Static fields',
            it: 'Static fields',
            es: 'Static fields',
            fr: 'Static fields',
          },
        },
      },
      fields: [
        {
          id: FormFieldTypesEnum.HEADLINE_DISPLAY,
          type: FormFieldTypesEnum.HEADLINE_DISPLAY,
          config: {
            text: {
              en: 'Minimal fields',
              de: 'Minimal fields',
              tr: 'Minimal fields',
              it: 'Minimal fields',
              es: 'Minimal fields',
              fr: 'Minimal fields',
            },
          },
        },
        {
          id: FormFieldTypesEnum.HTML_DISPLAY,
          type: FormFieldTypesEnum.HTML_DISPLAY,
          config: {
            text: {
              en:
                '<p>This example provides all fields in their minimal state.</p>' +
                '<p>If there is a required field ever coming up, it will appear in this config.</p>',
              de:
                '<p>This example provides all fields in their minimal state.</p>' +
                '<p>If there is a required field ever coming up, it will appear in this config.</p>',
              tr:
                '<p>This example provides all fields in their minimal state.</p>' +
                '<p>If there is a required field ever coming up, it will appear in this config.</p>',
              it:
                '<p>This example provides all fields in their minimal state.</p>' +
                '<p>If there is a required field ever coming up, it will appear in this config.</p>',
              es:
                '<p>This example provides all fields in their minimal state.</p>' +
                '<p>If there is a required field ever coming up, it will appear in this config.</p>',
              fr:
                '<p>This example provides all fields in their minimal state.</p>' +
                '<p>If there is a required field ever coming up, it will appear in this config.</p>',
            },
          },
        },
      ],
    },
    {
      id: 'fieldSection_Inputs',
      type: FormSectionTypesEnum.FIELD_SECTION,
      config: {
        headline: {
          text: {
            en: 'Input fields',
            de: 'Input fields',
            tr: 'Input fields',
            it: 'Input fields',
            es: 'Input fields',
            fr: 'Input fields',
          },
        },
      },
      fields: [
        {
          id: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
          type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
          config: {
            label: {
              text: {
                en: 'Single line text input',
                de: 'Single line text input',
                tr: 'Single line text input',
                it: 'Single line text input',
                es: 'Single line text input',
                fr: 'Single line text input',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT,
          type: FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT,
          config: {
            label: {
              text: {
                en: 'Multi line text input',
                de: 'Multi line text input',
                tr: 'Multi line text input',
                it: 'Multi line text input',
                es: 'Multi line text input',
                fr: 'Multi line text input',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.RICH_TEXT_INPUT,
          type: FormFieldTypesEnum.RICH_TEXT_INPUT,
          config: {
            label: {
              text: {
                en: 'Rich text input',
                de: 'Rich text input',
                tr: 'Rich text input',
                it: 'Rich text input',
                es: 'Rich text input',
                fr: 'Rich text input',
              },
            },
          },
        },
        {
          id: 'fakeDividerField',
          type: FormFieldTypesEnum.HTML_DISPLAY,
          config: {
            text: {
              en: '<br /><hr /><br />',
              de: '<br /><hr /><br />',
              tr: '<br /><hr /><br />',
              it: '<br /><hr /><br />',
              es: '<br /><hr /><br />',
              fr: '<br /><hr /><br />',
            },
          },
        },
        {
          id: FormFieldTypesEnum.PHONE_NUMBER_INPUT,
          type: FormFieldTypesEnum.PHONE_NUMBER_INPUT,
          config: {
            label: {
              text: {
                en: 'Phone number input',
                de: 'Phone number input',
                tr: 'Phone number input',
                it: 'Phone number input',
                es: 'Phone number input',
                fr: 'Phone number input',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.DATE_TIME_INPUT,
          type: FormFieldTypesEnum.DATE_TIME_INPUT,
          config: {
            label: {
              text: {
                en: 'Date time input',
                de: 'Date time input',
                tr: 'Date time input',
                it: 'Date time input',
                es: 'Date time input',
                fr: 'Date time input',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.DATE_INPUT,
          type: FormFieldTypesEnum.DATE_INPUT,
          config: {
            label: {
              text: {
                en: 'Date input',
                de: 'Date input',
                tr: 'Date input',
                it: 'Date input',
                es: 'Date input',
                fr: 'Date input',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.TIME_INPUT,
          type: FormFieldTypesEnum.TIME_INPUT,
          config: {
            label: {
              text: {
                en: 'Time input',
                de: 'Time input',
                tr: 'Time input',
                it: 'Time input',
                es: 'Time input',
                fr: 'Time input',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.BOOLEAN_INPUT,
          type: FormFieldTypesEnum.BOOLEAN_INPUT,
          config: {
            label: {
              text: {
                en: 'Boolean input',
                de: 'Boolean input',
                tr: 'Boolean input',
                it: 'Boolean input',
                es: 'Boolean input',
                fr: 'Boolean input',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.ADDRESS_INPUT,
          type: FormFieldTypesEnum.ADDRESS_INPUT,
          config: {
            label: {
              text: {
                en: 'Address input',
                de: 'Address input',
                tr: 'Address input',
                it: 'Address input',
                es: 'Address input',
                fr: 'Address input',
              },
            },
          },
        },
      ],
    },
    {
      id: 'fieldSection_Selects',
      type: FormSectionTypesEnum.FIELD_SECTION,
      config: {
        headline: {
          text: {
            en: 'Selection fields',
            de: 'Selection fields',
            tr: 'Selection fields',
            it: 'Selection fields',
            es: 'Selection fields',
            fr: 'Selection fields',
          },
        },
      },
      fields: [
        {
          id: FormFieldTypesEnum.STATIC_SINGLE_SELECT,
          type: FormFieldTypesEnum.STATIC_SINGLE_SELECT,
          config: {
            label: {
              text: {
                en: 'Static single select',
                de: 'Static single select',
                tr: 'Static single select',
                it: 'Static single select',
                es: 'Static single select',
                fr: 'Static single select',
              },
            },
            value: {
              options: {
                optionOne: {
                  en: 'Option one',
                  de: 'Option one',
                  tr: 'Option one',
                  it: 'Option one',
                  es: 'Option one',
                  fr: 'Option one',
                },
                optionTwo: {
                  en: 'Option two',
                  de: 'Option two',
                  tr: 'Option two',
                  it: 'Option two',
                  es: 'Option two',
                  fr: 'Option two',
                },
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.STATIC_MULTI_SELECT,
          type: FormFieldTypesEnum.STATIC_MULTI_SELECT,
          config: {
            label: {
              text: {
                en: 'Static multi select',
                de: 'Static multi select',
                tr: 'Static multi select',
                it: 'Static multi select',
                es: 'Static multi select',
                fr: 'Static multi select',
              },
            },
            value: {
              options: {
                optionOne: {
                  en: 'Option one',
                  de: 'Option one',
                  tr: 'Option one',
                  it: 'Option one',
                  es: 'Option one',
                  fr: 'Option one',
                },
                optionTwo: {
                  en: 'Option two',
                  de: 'Option two',
                  tr: 'Option two',
                  it: 'Option two',
                  es: 'Option two',
                  fr: 'Option two',
                },
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT,
          type: FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT,
          config: {
            label: {
              text: {
                en: 'Company single select',
                de: 'Company single select',
                tr: 'Company single select',
                it: 'Company single select',
                es: 'Company single select',
                fr: 'Company single select',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.ASSET_SINGLE_SELECT,
          type: FormFieldTypesEnum.ASSET_SINGLE_SELECT,
          config: {
            label: {
              text: {
                en: 'Asset single select',
                de: 'Asset single select',
                tr: 'Asset single select',
                it: 'Asset single select',
                es: 'Asset single select',
                fr: 'Asset single select',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.CONTACT_SINGLE_SELECT,
          type: FormFieldTypesEnum.CONTACT_SINGLE_SELECT,
          config: {
            label: {
              text: {
                en: 'User single select',
                de: 'User single select',
                tr: 'User single select',
                it: 'User single select',
                es: 'User single select',
                fr: 'User single select',
              },
            },
          },
        },
      ],
    },
    {
      id: 'fieldSection_Repeatables',
      type: FormSectionTypesEnum.FIELD_SECTION,
      config: {
        headline: {
          text: {
            en: 'Lists',
            de: 'Lists',
            tr: 'Lists',
            it: 'Lists',
            es: 'Lists',
            fr: 'Lists',
          },
        },
      },
      fields: [
        {
          id: FormFieldTypesEnum.PERSON_LIST_INPUT,
          type: FormFieldTypesEnum.PERSON_LIST_INPUT,
          config: {
            label: {
              text: {
                en: 'Person list',
                de: 'Person list',
                tr: 'Person list',
                it: 'Person list',
                es: 'Person list',
                fr: 'Person list',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.EXPENSE_LIST_INPUT,
          type: FormFieldTypesEnum.EXPENSE_LIST_INPUT,
          config: {
            label: {
              text: {
                en: 'Expense list',
                de: 'Expense list',
                tr: 'Expense list',
                it: 'Expense list',
                es: 'Expense list',
                fr: 'Expense list',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.TIME_TRACKING_LIST_INPUT,
          type: FormFieldTypesEnum.TIME_TRACKING_LIST_INPUT,
          config: {
            label: {
              text: {
                en: 'Time tracking list',
                de: 'Time tracking list',
                tr: 'Time tracking list',
                it: 'Time tracking list',
                es: 'Time tracking list',
                fr: 'Time tracking list',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.TASK_LIST_INPUT,
          type: FormFieldTypesEnum.TASK_LIST_INPUT,
          config: {
            label: {
              text: {
                en: 'Check list',
                de: 'Check list',
                tr: 'Check list',
                it: 'Check list',
                es: 'Check list',
                fr: 'Check list',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.PART_LIST_INPUT,
          type: FormFieldTypesEnum.PART_LIST_INPUT,
          config: {
            label: {
              text: {
                en: 'Part list',
                de: 'Part list',
                tr: 'Part list',
                it: 'Part list',
                es: 'Part list',
                fr: 'Part list',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.SPARE_PART_LIST_INPUT,
          type: FormFieldTypesEnum.SPARE_PART_LIST_INPUT,
          config: {
            label: {
              text: {
                en: 'Spare Part list',
                de: 'Spare Part list',
                tr: 'Spare Part list',
                it: 'Spare Part list',
                es: 'Spare Part list',
                fr: 'Spare Part list',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.ASSET_MULTI_SELECT,
          type: FormFieldTypesEnum.ASSET_MULTI_SELECT,
          config: {
            label: {
              text: {
                en: 'Asset Multi Select',
                de: 'Asset Multi Select',
                tr: 'Asset Multi Select',
                it: 'Asset Multi Select',
                es: 'Asset Multi Select',
                fr: 'Asset Multi Select',
              },
            },
          },
        },
        {
          id: FormFieldTypesEnum.FILE_UPLOAD,
          type: FormFieldTypesEnum.FILE_UPLOAD,
          config: {
            label: {
              text: {
                en: 'File upload',
                de: 'File upload',
                tr: 'File upload',
                it: 'File upload',
                es: 'File upload',
                fr: 'File upload',
              },
            },
          },
        },
      ],
    },
    {
      id: 'fieldSection_Repeater',
      type: FormSectionTypesEnum.FIELD_SECTION,
      config: {
        headline: {
          text: {
            en: 'Field repeater',
            de: 'Field repeater',
            tr: 'Field repeater',
            it: 'Field repeater',
            es: 'Field repeater',
            fr: 'Field repeater',
          },
        },
      },
      fields: [
        {
          id: FormFieldTypesEnum.FIELD_REPEATER,
          type: FormFieldTypesEnum.FIELD_REPEATER,
          config: {
            label: {
              text: {
                en: 'Field repeater',
                de: 'Field repeater',
                tr: 'Field repeater',
                it: 'Field repeater',
                es: 'Field repeater',
                fr: 'Field repeater',
              },
            },
            fields: [
              {
                id:
                  FormFieldTypesEnum.FIELD_REPEATER +
                  FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT +
                  '1',
                type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
                config: {
                  label: {
                    text: {
                      en: 'First name',
                      de: 'First name',
                      tr: 'First name',
                      it: 'First name',
                      es: 'First name',
                      fr: 'First name',
                    },
                  },
                },
              },
              {
                id:
                  FormFieldTypesEnum.FIELD_REPEATER +
                  FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT +
                  '2',
                type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
                config: {
                  label: {
                    text: {
                      en: 'Last name',
                      de: 'Last name',
                      tr: 'Last name',
                      it: 'Last name',
                      es: 'Last name',
                      fr: 'Last name',
                    },
                  },
                },
              },
            ],
          },
        },
      ],
    },
    {
      id: FormSectionTypesEnum.EMAIL_SECTION,
      type: FormSectionTypesEnum.EMAIL_SECTION,
      config: {
        headlineText: {
          en: 'Email section',
          de: 'Email section',
          tr: 'Email section',
          it: 'Email section',
          es: 'Email section',
          fr: 'Email section',
        },
      },
    },
    {
      id: FormSectionTypesEnum.SIGNATURE_SECTION,
      type: FormSectionTypesEnum.SIGNATURE_SECTION,
      config: {
        required: true,
        headlineText: {
          en: 'Signature section',
          de: 'Signature section',
          tr: 'Signature section',
          it: 'Signature section',
          es: 'Signature section',
          fr: 'Signature section',
        },
      },
    },
  ],
};
