import { Injectable } from '@angular/core';

import { formatDate } from '@angular/common';
import { environment } from '../environments/environment';

export enum LogLevel {
  All = 0,
  Silly = 1,
  Debug = 2,
  Info = 3,
  Warn = 4,
  Error = 5,
  Fatal = 6,
  Off = 7,
}

let CUSTOM_LOG_LEVEL: LogLevel;
if (!environment.debug) {
  CUSTOM_LOG_LEVEL = LogLevel.Error;
} else {
  CUSTOM_LOG_LEVEL = LogLevel.Debug;
}

if (environment.mobile) {
  CUSTOM_LOG_LEVEL = LogLevel.Debug;
}

// If you want to see all Logs, uncomment the line below
// CUSTOM_LOG_LEVEL = LogLevel.All;

const emptyFunc = function () {
  return function () {
    // empty
  };
};

@Injectable({
  providedIn: 'root',
})
export class LogService {
  level: LogLevel = CUSTOM_LOG_LEVEL;
  logWithDate: boolean = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  silly: () => (...data: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug: () => (...data: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info: () => (...data: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn: () => (...data: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: () => (...data: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fatal: () => (...data: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log: () => (...data: any[]) => void;

  constructor() {
    if (this.shouldLog(LogLevel.Silly)) {
      this.silly = function () {
        // eslint-disable-next-line no-console
        return console.log.bind(window.console, this.createPrefix(LogLevel.Silly));
      };
    } else {
      this.silly = emptyFunc;
    }
    if (this.shouldLog(LogLevel.Debug)) {
      this.debug = function () {
        // eslint-disable-next-line no-console
        return console.log.bind(window.console, this.createPrefix(LogLevel.Debug));
      };
    } else {
      this.debug = emptyFunc;
    }
    if (this.shouldLog(LogLevel.Info)) {
      this.info = function () {
        // eslint-disable-next-line no-console
        return console.log.bind(window.console, this.createPrefix(LogLevel.Info));
      };
    } else {
      this.info = emptyFunc;
    }
    if (this.shouldLog(LogLevel.Warn)) {
      this.warn = function () {
        // eslint-disable-next-line no-console
        return console.warn.bind(window.console, this.createPrefix(LogLevel.Warn));
      };
    } else {
      this.warn = emptyFunc;
    }
    if (this.shouldLog(LogLevel.Error)) {
      this.error = function () {
        // eslint-disable-next-line no-console
        return console.error.bind(window.console, this.createPrefix(LogLevel.Error));
      };
    } else {
      this.error = emptyFunc;
    }
    if (this.shouldLog(LogLevel.Fatal)) {
      this.fatal = function () {
        // eslint-disable-next-line no-console
        return console.error.bind(window.console, this.createPrefix(LogLevel.Fatal));
      };
    } else {
      this.fatal = emptyFunc;
    }
    if (this.shouldLog(LogLevel.All)) {
      this.log = function () {
        // eslint-disable-next-line no-console
        return console.log.bind(window.console, this.createPrefix(LogLevel.All));
      };
    } else {
      this.log = emptyFunc;
    }
  }

  private shouldLog(level: LogLevel): boolean {
    let ret = false;
    if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
      ret = true;
    }
    return ret;
  }

  private createPrefix(level: LogLevel): string {
    let value = '';

    // Build log string
    if (this.logWithDate) {
      value = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US') + ' ';
    }
    value += '[' + LogLevel[level] + ']';
    return value;
  }
}
