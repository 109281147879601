type ObjectWithId = { _id: string } | { id: string };

export function compareIDs(
  obj1: ObjectWithId | string | undefined,
  obj2: ObjectWithId | string | undefined,
): boolean {
  return getStringID(obj1) === getStringID(obj2);
}

export function getStringID(obj: ObjectWithId | string | undefined): string | undefined {
  if (typeof obj === 'string') {
    return obj;
  }
  if ((obj as { _id: string } | undefined)?._id) {
    return (obj as { _id: string })._id;
  }
  if ((obj as { id: string } | undefined)?.id) {
    return (obj as { id: string }).id;
  }

  return undefined;
}
