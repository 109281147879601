import { HttpClient, HttpErrorResponse, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeleteFileOptions, Directory, Filesystem } from '@capacitor/filesystem';
import { PlatformFile } from '@remberg/files/common/main';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  FILE_SYNC_DIRECTORY,
  LogService,
} from '@remberg/global/ui';
import { of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilesystemService {
  private readonly filesUrl = `${API_URL_PLACEHOLDER}/files/v2`;

  constructor(
    private readonly http: HttpClient,
    private readonly logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
  ) {}

  /**
   * Creates the sub file subfolders for saving files in the device
   * @returns Promise
   */
  public async setupDirectories(): Promise<void> {
    await this.setupDirectory(FILE_SYNC_DIRECTORY, Directory.Data);
  }

  /**
   * Returns true if the directory was created, and false if it already existed
   * @param path
   * @param directory
   */
  private async setupDirectory(path: string, directory: Directory): Promise<boolean> {
    this.logger.debug()('setupDirectory - Setting up a directory: ' + path);
    try {
      const result = await Filesystem.readdir({
        path: path,
        directory: directory,
      });
      this.logger.debug()(
        'setupDirectory - Directory already exists - ' +
          path +
          ' ' +
          directory +
          ': ' +
          JSON.stringify(result),
      );
      return false;
    } catch (err) {
      try {
        this.logger.debug()('setupDirectory - Creating...: ' + path);
        await Filesystem.mkdir({
          path: path,
          directory: directory,
          recursive: true,
        });
        this.logger.debug()('setupDirectory - Success: ' + path);
        return true;
      } catch (err) {
        this.logger.error()('setupDirectory - Error: ' + JSON.stringify(err));
        return false;
        // throw { message: 'Error creating directory.', error: err };
      }
    }
  }

  /**
   * Saves a file to the local file storage according to the path and directory specified.
   * @param  {File} file
   * @param  {string} fileId string that will be taken over as file name
   * @param  {string} path By default, FILE_SYNC_DIRECTORY is taken over
   * @param  {Directory} directory By default, Data directory is taken over
   * @returns Promise URI - URI from the files that was just saved
   */
  public async saveFileToFilesystem(
    file: File | Blob,
    fileId: string,
    path: string = FILE_SYNC_DIRECTORY,
    directory: Directory = Directory.Data,
  ): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const result = await Filesystem.writeFile({
          data: reader.result as string,
          path: path + '/' + fileId,
          directory: directory,
          recursive: true,
        });

        resolve(result.uri);
      };
      reader.readAsDataURL(file as Blob);
    });
  }
  /**
   * Deletes the file object stored locally according to the fileId - File must be in the
   * @param  {string} fileId ObjectID from the file you want to delete
   * @param  {string} path By default, FILE_SYNC_DIRECTORY is taken over
   * @param  {FilesystemDirectory} directory By default, Data directory is taken over
   * @return — a promise that resolves with the deleted file data result
   */
  public removeFileFromFilesystem(
    fileId: string,
    path: string = FILE_SYNC_DIRECTORY,
    directory: Directory = Directory.Data,
  ): Promise<void> {
    const options: DeleteFileOptions = {
      path: path + '/' + fileId,
      directory: directory,
    };
    return Filesystem.deleteFile(options);
  }
  /**
   * Read file object stored locally according to the PlatformFile given.
   * @param  {PlatformFile} file
   * @param  {string} path By default, FILE_SYNC_DIRECTORY is taken over
   * @param  {Directory} directory By default, Data directory is taken over
   * @returns Promise<Blob>
   */
  public async readFileFromFilesystem(
    file: PlatformFile,
    path: string = FILE_SYNC_DIRECTORY,
    directory: Directory = Directory.Data,
  ): Promise<Blob> {
    const base64Data = (
      await Filesystem.readFile({
        path: path + '/' + file._id,
        directory: directory,
      })
    )?.data;
    const resFetch = await fetch('data:' + file.type + ';base64,' + base64Data);
    const blob = await resFetch.blob();
    return blob;
  }

  /**
   * Only works online. Fetches a file with fileID from the server and saves it locally on the device.
   * @param  {string} fileID
   * @param  {string} path By default, FILE_SYNC_DIRECTORY is taken over
   * @param  {FilesystemDirectory} directory By default, Data directory is taken over
   * @returns Promise
   */
  public downloadFileToFilesystem(
    fileID: string,
    path: string = FILE_SYNC_DIRECTORY,
    directory: Directory = Directory.Data,
  ): Promise<string | undefined> {
    if (this.connectivityService.getConnected()) {
      return this.http
        .get(`${this.filesUrl}/download/${fileID}`, {
          observe: 'body',
          responseType: 'blob',
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        })
        .pipe(
          switchMap((file: Blob) => this.saveFileToFilesystem(file, fileID, path, directory)),
          catchError((error) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === HttpStatusCode.NotFound) {
                return of(undefined);
              }
            }

            return throwError(error);
          }),
        )
        .toPromise();
    } else {
      throw { message: 'Cannot download files while being offline!' };
    }
  }
}
