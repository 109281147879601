import { ContactBasic } from '@remberg/crm/common/base';
import { MultiLanguageText, RembergDateTime, UnknownOr } from '@remberg/global/common/core';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TimeTrackingListInputField
  extends Field<FormFieldTypesEnum.TIME_TRACKING_LIST_INPUT, TimeTrackingListInputFieldConfig> {}

export interface TimeTrackingListInputFieldConfig {
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  requiredAmountOfEntries?: number;
  maximumDuration?: number;
  label: {
    text: MultiLanguageText;
    pdfHide?: boolean;
    pdfTextSize?: number;
    pdfTextColor?: string;
  };
  fields?: {
    timeTrackingTypeStaticSingleSelect?: {
      pdfHide?: boolean;
    };
    startDateTimeInput?: {
      pdfHide?: boolean;
    };
    endDateTimeInput?: {
      pdfHide?: boolean;
    };
    breakNumericInput?: {
      disabled?: boolean;
      required?: boolean;
      pdfHide?: boolean;
    };
    durationDisplay?: {
      disabled?: boolean;
      pdfHide?: boolean;
    };
    distanceNumericInput?: {
      disabled?: boolean;
      required?: boolean;
      pdfHide?: boolean;
    };
    technicianUserSingleSelect?: {
      disabled?: boolean;
      required?: boolean;
      pdfHide?: boolean;
      pdfPrintEmailAddress?: boolean;
    };
    commentMultiLineTextInput?: {
      disabled?: boolean;
      required?: boolean;
      pdfHide?: boolean;
    };
  };
}

export interface TimeTrackingListInputFieldData<Populated extends boolean = false> {
  entries: TimeTrackingEntry<Populated>[];
}

export interface TimeTrackingEntry<Populated extends boolean = false> {
  travelType: TimeEntryTypeEnum;
  startDateTime: RembergDateTime;
  endDateTime: RembergDateTime;
  break?: number;
  duration?: number;
  distance?: number;
  technician?: Populated extends true ? UnknownOr<ContactBasic> : string;
  comment?: string;
}

export enum TimeEntryTypeEnum {
  ARRIVAL = 'arrival',
  WORK = 'work',
  RETURN = 'return',
}
