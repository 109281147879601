/**
 * Value is in format: "yyyy-MM-dd"
 */
export const DATE_REGEX: RegExp = /^\d{4}-\d{2}-\d{2}$/;

/**
 * Value is in format: "yyyy-MM-ddTHH:mm:ss.SSSZ"
 */
export const ISO_DATE_REGEX: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

/**
 * Value is in format: "yyyy-MM-ddTHH:mm:ss.SSSZ{_OPTIONAL/TIMEZONE}"
 */
export const REMBERG_DATE_TIME_REGEX: RegExp =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z(_[A-Za-z\-/_]*)?$/;

/**
 * For checking if a search query contains a number
 * @example // Searching for `#001` will match `1`, searching for `0044` will match `44`
 */
export const INSTANCE_COUNTER_REGEX =
  /^#?(\d+|00000\d|0000\d\d|000\d\d\d|00\d\d\d\d|0\d\d\d\d\d|\d\d\d\d\d\d)/;

/**
 * Value does not contains any values that are not allowed in sobdomains: https://stackoverflow.com/a/7933253/815382
 */
export const SUBDOMAIN_REGEX: RegExp = /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/;
