<div class="page-not-found-wrapper">
  <img
    style="max-width: 100%"
    class="not-found-image"
    [attr.src]="'/assets/img/404.svg' | sanitizeUrl"
  />

  <div class="main-text" i18n="@@pageNotFound">Page not found</div>
  <div class="sub-text" i18n="@@weCouldNotLocateThePageYouAreLookingFor">
    We could not locate the page you are looking for
  </div>

  <button
    class="back-home-button"
    mat-raised-button
    color="primary"
    [rbRouterLink]="['/']"
    [replaceUrl]="true"
    i18n="@@backToHome"
  >
    Back to Home
  </button>
</div>
