import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  PlatformFile,
  PlatformFilesFindManyQuery,
  PlatformFilesFindManyResponse,
} from '@remberg/files/common/main';
import { FilesystemService, PlatformFilesOfflineServiceInterface } from '@remberg/files/ui/clients';
import { LogService, SQLQueryParams, SyncDataTypesEnum } from '@remberg/global/ui';
import { Observable, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  SQLConcatOperator,
  concatSQLFiltersByOperator,
  generateContainsSQLFilter,
} from '../../helpers/sqlFiltersHelper';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

export enum FilesTableColumnEnum {
  SOURCE = 'source',
  SOURCE_TYPE = 'sourceType',
  ORIGINALNAME = 'originalname',
}

const params: SQLQueryParams<FilesTableColumnEnum> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.FILES,
  columns: {
    [FilesTableColumnEnum.SOURCE]: {
      type: 'TEXT',
      valueFunction: (val: PlatformFile) => val?.source,
    },
    [FilesTableColumnEnum.SOURCE_TYPE]: {
      type: 'TEXT',
      valueFunction: (val: PlatformFile) => val?.sourceType,
    },
    [FilesTableColumnEnum.ORIGINALNAME]: {
      type: 'TEXT',
      valueFunction: (val: PlatformFile) => val?.originalname,
    },
  },
};

@Injectable()
export class PlatformFilesOfflineService
  extends BaseOfflineService<PlatformFile, never>
  implements PlatformFilesOfflineServiceInterface
{
  constructor(
    dbService: SqlDBService,
    logger: LogService,
    store: Store<RootGlobalState>,
    private readonly filesystemService: FilesystemService,
  ) {
    super(dbService, params, logger, store);
  }

  /**
   * Download a file and return its data as a blob. Works only in offline mode.
   * @param fileId
   * @returns
   */
  public downloadFileToBlobOffline(fileId: string): Observable<Blob> {
    this.logger.debug()('DownloadFileToBlob in offline mode.');
    return from(this.getInstance(fileId, undefined)).pipe(
      mergeMap((file) => this.filesystemService.readFileFromFilesystem(file)),
    );
  }

  public findMany(params: PlatformFilesFindManyQuery): Observable<PlatformFilesFindManyResponse> {
    return from(
      this.getInstancesWithCount(
        params.limit,
        params.page,
        undefined,
        undefined,
        toSqlFilter(params.sourceIds, params.sourceTypes, params.search),
      ),
    ).pipe(
      map(({ data, count }) => ({
        platformFiles: data,
        count: count as number,
      })),
    );
  }
}

function toSqlFilter(
  sourceIds?: string[],
  sourceTypes?: string[],
  searchString?: string,
): string | undefined {
  const sqlFilter: string[] = [];

  if (sourceIds?.length) {
    const sourceIdQuery = `${params.tableName}.${FilesTableColumnEnum.SOURCE} IN (${sourceIds.map((id) => `'${id}'`).join(',')})`;
    sqlFilter.push(`(${sourceIdQuery})`);
  }

  if (sourceTypes?.length) {
    const sourceTypeQuery = `${params.tableName}.${FilesTableColumnEnum.SOURCE_TYPE} IN (${sourceTypes.map((sourceType) => `'${sourceType}'`).join(',')})`;
    sqlFilter.push(`(${sourceTypeQuery})`);
  }

  if (searchString) {
    sqlFilter.push(
      `(${generateContainsSQLFilter(`${params.tableName}.${FilesTableColumnEnum.ORIGINALNAME}`, searchString)})`,
    );
  }

  return concatSQLFiltersByOperator(sqlFilter, SQLConcatOperator.AND);
}
