import { FormControl } from '@angular/forms';
import { IsoLanguageCodesEnum, MultiLanguageText } from '@remberg/global/common/core';

export interface MultiLanguageEditData {
  label: string;
  values: MultiLanguageText;
  currentLanguage: IsoLanguageCodesEnum;
  availableLanguages: IsoLanguageCodesEnum[];
}

export type MultiLanguageEditDataForm = Record<IsoLanguageCodesEnum, FormControl<string>>;

export const MAP_ISO_LANGUAGE_TO_FLAG_SVG: Record<IsoLanguageCodesEnum, string> = {
  [IsoLanguageCodesEnum.DE]: '/assets/img/languages/german.svg',
  [IsoLanguageCodesEnum.EN]: '/assets/img/languages/english.svg',
  [IsoLanguageCodesEnum.ES]: '/assets/img/languages/spanish.svg',
  [IsoLanguageCodesEnum.FR]: '/assets/img/languages/french.svg',
  [IsoLanguageCodesEnum.TH]: '/assets/img/languages/thai.svg',
  [IsoLanguageCodesEnum.TR]: '/assets/img/languages/turkish.svg',
  [IsoLanguageCodesEnum.IT]: '/assets/img/languages/italian.svg',
  [IsoLanguageCodesEnum.EL]: '/assets/img/languages/greek.svg',
};
