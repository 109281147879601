import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { compareIfVersionHigher } from '@remberg/global/common/core';
import { VERSION } from '@remberg/global/common/version';
import {
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LocalStorageKeys,
  LogService,
  StorageService,
  UPDATE_CHECK_INTERVAL,
  environment,
} from '@remberg/global/ui';
import { RembergUsersService } from '@remberg/users/ui/clients';
import isEqual from 'lodash/isEqual';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, interval, of, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { TenantService } from '../../services/api/tenant.service';
import { UserRoleService } from '../../services/api/user-role.service';
import { VersionService } from '../../services/api/version.service';
import { AppStateService } from '../../services/app-state.service';
import { awaitPropertyPersisted } from '../../services/persisted-state.service';
import { UserRightsService } from '../../services/user-rights.service';
import { RootGlobalState } from '../core-ui.definitions';
import { GlobalActions } from './global.actions';
import { GlobalSelectors } from './global.selectors';

@Injectable()
export class GlobalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly tenantService: TenantService,
    private readonly userRightsService: UserRightsService,
    private readonly appStateService: AppStateService,
    private readonly toastrService: ToastrService,
    private readonly logger: LogService,
    private readonly store: Store<RootGlobalState>,
    private readonly versionService: VersionService,
    private readonly swUpdate: SwUpdate,
    private readonly storageService: StorageService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly router: Router,
    private readonly rembergUsersService: RembergUsersService,
    private readonly userRoleService: UserRoleService,
  ) {}

  // Wojciech: timer does not start at 0 to give the app time to load and not end up in an infinite reload loop
  public readonly checkForApplicationUpdate$ = createEffect(() =>
    timer(UPDATE_CHECK_INTERVAL, UPDATE_CHECK_INTERVAL).pipe(
      filter(() => environment.live), // only check for update when hosted on actual server and not locally
      withLatestFrom(
        this.store.select(GlobalSelectors.selectIsApplicationUpdateAvailable),
        this.store.select(GlobalSelectors.selectIsIonic),
      ),
      filter(
        ([, isUpdateAvailable, isIonic]) =>
          !isUpdateAvailable && !isIonic && !this.swUpdate.isEnabled,
      ),
      switchMap(() =>
        this.versionService.getVersion().pipe(
          map((data) => {
            this.logger.silly()('Frontend version is: ' + VERSION);
            this.logger.silly()('Backend version is: ' + data.rembergVersion);
            // Wojciech: preventing unnecessary app update from breaking CI integration tests
            const isRemoteVersionDummy = data.rembergVersion === '__VERSION__';
            if (data.rembergVersion !== VERSION && !isRemoteVersionDummy) {
              this.logger.debug()('Notifying user of new version.');
              this.logger.warn()('Frontend upgrade required...');
              return true;
            }
            return false;
          }),
          catchError((error) => {
            this.logger.warn()('Version update check failed.', error);
            return of(false);
          }),
        ),
      ),
      filter((isApplicationUpdateAvailable) => isApplicationUpdateAvailable),
      map(() => GlobalActions.applicationUpdateAvailable()),
    ),
  );

  public readonly initiallyCheckForMinimumIonicVersion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.globalInitializationComplete),
        withLatestFrom(
          this.connectivityService.connection$,
          this.store.select(GlobalSelectors.selectIsIonic),
          this.store.select(GlobalSelectors.selectIsIonicAppBelowMinimumRequiredVersion),
        ),
        filter(([, , isIonic]) => isIonic),
        tap(([, isConnected, , isIonicAppBelowMinimumRequiredVersion]) => {
          if (!isConnected && isIonicAppBelowMinimumRequiredVersion) {
            this.store.dispatch(
              GlobalActions.ionicAppVersionChecked({
                belowRequiredVersion: true,
                redirectImmediately: true,
              }),
            );
          } else if (isConnected) {
            this.versionService
              .getVersion()
              .pipe(
                catchError((error) => {
                  this.logger.error()('Ionic min Version check failed.', error);
                  return of(undefined);
                }),
                filter(Boolean),
              )
              .subscribe(({ minAllowedIonicAppVersion }) => {
                this.store.dispatch(
                  GlobalActions.ionicAppVersionChecked({
                    belowRequiredVersion: compareIfVersionHigher(
                      minAllowedIonicAppVersion,
                      VERSION,
                    ),
                    redirectImmediately: true,
                  }),
                );
              });
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * This Effect periodically checks the minimum Ionic version in case the running app is not allowed anymore
   */
  public readonly checkForMinimumIonicVersion$ = createEffect(() =>
    interval(UPDATE_CHECK_INTERVAL).pipe(
      withLatestFrom(
        this.store.select(GlobalSelectors.selectIsIonic),
        this.connectivityService.connection$,
      ),
      filter(([, isIonic, isOnline]) => isIonic && isOnline),
      switchMap(() =>
        this.versionService.getVersion().pipe(
          map(({ minAllowedIonicAppVersion }) =>
            compareIfVersionHigher(minAllowedIonicAppVersion, VERSION),
          ),
          catchError((error) => {
            this.logger.error()('Ionic min Version check failed.', error);
            return of(false);
          }),
        ),
      ),
      map((isAppInvalid) =>
        GlobalActions.ionicAppVersionChecked({
          belowRequiredVersion: isAppInvalid,
          redirectImmediately: false,
        }),
      ),
    ),
  );

  public readonly redirectToIonicAppUpdatePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.ionicAppVersionChecked),
        tap((action) => {
          const curentStoreValue = this.appStateService.getValue(
            LocalStorageKeys.IONIC_APP_VERSION_INVALID,
          );
          if (action.belowRequiredVersion && !curentStoreValue) {
            this.appStateService.setValue(LocalStorageKeys.IONIC_APP_VERSION_INVALID, 'true');
          } else if (!action.belowRequiredVersion && curentStoreValue) {
            this.appStateService.removeValue(LocalStorageKeys.IONIC_APP_VERSION_INVALID);
          }
        }),
        filter(({ redirectImmediately }) => redirectImmediately),
        tap(({ belowRequiredVersion }) => {
          if (belowRequiredVersion) {
            this.router.navigateByUrl('/update-required');
            // additionally redirect users away from the /update-page in case it is the current page
          } else if (this.router.url.indexOf('/update-required') > -1) {
            this.router.navigateByUrl('/');
          }
        }),
      ),
    { dispatch: false },
  );

  public readonly checkIfApplicationHasBeenUpdated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.globalInitializationComplete),
        mergeMap(async () => {
          const storedAppVersion: string | undefined = await this.storageService.get(
            LocalStorageKeys.APP_VERSION,
          );
          if (!!storedAppVersion && storedAppVersion !== VERSION) {
            this.toastrService.info(
              undefined,
              $localize`:@@applicationWasUpdated:Application was updated`,
            );
          }
          await this.storageService.set(LocalStorageKeys.APP_VERSION, VERSION);
        }),
      ),
    { dispatch: false },
  );

  // Wojciech: timer does not start at 0 to give the app time to load and not end up in an infinite reload loop
  public readonly checkForPermissionsAndFeaturesChange$ = createEffect(() =>
    timer(UPDATE_CHECK_INTERVAL, UPDATE_CHECK_INTERVAL).pipe(
      withLatestFrom(
        this.store.select(GlobalSelectors.selectSyncState),
        this.store.select(GlobalSelectors.selectTenantFeatures),
        this.store.select(GlobalSelectors.selectTheme),
        this.connectivityService.connection$,
        this.store.select(GlobalSelectors.selectIsIonicAppBelowMinimumRequiredVersion),
        this.store.select(GlobalSelectors.selectCurrentRembergUser),
        this.store.select(GlobalSelectors.selectHasUserChosenToLogout),
      ),
      filter(
        ([
          ,
          syncState,
          oldFeatures,
          oldTheme,
          isOnline,
          isIonicAppBelowMinimumRequiredVersion,
          rembergUser,
          hasUserChosenToLogout,
        ]) =>
          !!rembergUser &&
          !hasUserChosenToLogout &&
          !!oldTheme &&
          !rembergUser.isRembergAdmin &&
          !syncState &&
          !!oldFeatures &&
          isOnline &&
          !isIonicAppBelowMinimumRequiredVersion,
      ),
      switchMap(([, , oldFeatures, oldTheme, , , rembergUser]) =>
        combineLatest([
          of(oldFeatures),
          of(oldTheme),
          this.tenantService.getOne(rembergUser?.tenantId as string),
          this.rembergUsersService
            .findOne(rembergUser?._id as string)
            .pipe(
              mergeMap((rembergUser) =>
                this.userRoleService.getOne(rembergUser.userRoleId as string),
              ),
            ),
          of(rembergUser?.isTenantOwner),
        ]).pipe(
          mergeMap(([oldFeatures, oldTheme, newTenant, newUserRole, isTenantOwner]) => {
            const actions: TypedAction<string>[] = [];

            const oldUserPermissions = this.userRightsService.getUserRights()?.permissions;
            const newUserPermissions = newUserRole.permissions;
            const newFeatures = isTenantOwner
              ? newTenant.internalFeatureFlags
              : newTenant.externalFeatureFlags;

            if (!oldUserPermissions || !newUserPermissions || !newTenant) {
              return actions;
            }

            const areFeaturesChanged = !isEqual(oldFeatures, newFeatures);
            const isThemeChanged = !isEqual(oldTheme, newTenant.theme);
            if (areFeaturesChanged || isThemeChanged) {
              actions.push(
                GlobalActions.tenantUpdateAvailable({
                  updatedTenant: newTenant,
                }),
              );
            }

            const arePermissionsChanged = !isEqual(oldUserPermissions, newUserPermissions);
            if (arePermissionsChanged) {
              actions.push(
                GlobalActions.permissionsUpdateAvailable({
                  updatedUserRole: newUserRole,
                }),
              );
            }

            return actions;
          }),
          catchError((error) =>
            of(
              GlobalActions.effectError({
                context: 'checkForPermissionsAndFeaturesChange$',
                error: JSON.stringify(error),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly setFeatureUpdateLocalStorageKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.tenantUpdateAvailable),
        withLatestFrom(this.store.select(GlobalSelectors.selectTenantId)),
        tap(async ([, tenantId]) => {
          await this.storageService.set(LocalStorageKeys.FEATURES_UPDATED_TENANT_ID, tenantId);
        }),
      ),
    { dispatch: false },
  );

  public readonly setPermissionsUpdateLocalStorageKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.permissionsUpdateAvailable),
        withLatestFrom(this.store.select(GlobalSelectors.selectCurrentContactId)),
        tap(async ([, userId]) => {
          await this.storageService.set(LocalStorageKeys.PERMISSIONS_UPDATED_USER_ID, userId);
        }),
      ),
    { dispatch: false },
  );

  public readonly applicationAndStorageUpdateInitiated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.applicationAndStorageUpdateInitiated),
        withLatestFrom(
          this.store.select(GlobalSelectors.selectUpdatedTenant),
          this.store.select(GlobalSelectors.selectUpdatedUserRole),
        ),
        tap(async ([{ targetUrl }, updatedTenant, updatedUserRole]) => {
          if (updatedTenant) {
            this.store.dispatch(GlobalActions.tenantUpdated({ tenant: updatedTenant }));
            await awaitPropertyPersisted(
              LocalStorageKeys.IONIC_CURRENT_TENANT,
              updatedTenant,
              this.actions$,
            );
          }

          if (updatedUserRole) {
            this.store.dispatch(GlobalActions.setUserRole({ userRole: updatedUserRole }));
            await awaitPropertyPersisted(
              LocalStorageKeys.IONIC_CURRENT_USER_ROLE,
              updatedUserRole,
              this.actions$,
            );
          }

          window.location.href = targetUrl;
        }),
      ),
    { dispatch: false },
  );

  public readonly checkIfPermissionsOrFeaturesHaveBeenUpdated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.completeRegisterSessionInfo),
        withLatestFrom(
          this.store.select(GlobalSelectors.selectCurrentContactId),
          this.store.select(GlobalSelectors.selectTenantId),
        ),
        mergeMap(async ([, userId, tenantId]) => {
          const tenantIdWithUpdatedFeatures: string | undefined = await this.storageService.get(
            LocalStorageKeys.FEATURES_UPDATED_TENANT_ID,
          );
          if (tenantIdWithUpdatedFeatures === tenantId) {
            this.toastrService.info(
              undefined,
              $localize`:@@featuresHaveBeenUpdated:Features were updated`,
            );
          }
          await this.storageService.remove(LocalStorageKeys.FEATURES_UPDATED_TENANT_ID);

          const userIdWithUpdatedPermissions: string | undefined = await this.storageService.get(
            LocalStorageKeys.PERMISSIONS_UPDATED_USER_ID,
          );
          if (userIdWithUpdatedPermissions === userId) {
            this.toastrService.info(
              undefined,
              $localize`:@@permissionsWereUpdated:Permissions were updated`,
            );
          }
          await this.storageService.remove(LocalStorageKeys.PERMISSIONS_UPDATED_USER_ID);
        }),
      ),
    { dispatch: false },
  );

  // This effect simply persists the `syncState` value state to the local (ionic) Storage
  public readonly setSyncState = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.setSyncState),
        map((action) =>
          this.appStateService.setValue(LocalStorageKeys.IONIC_DATA_SYNC_STATUS, action.syncState),
        ),
      ),
    { dispatch: false },
  );

  // This effect simply persists the `syncState` value state to the local (ionic) Storage
  public readonly clearSyncingState = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.clearSyncState),
        map(() => this.appStateService.removeValue(LocalStorageKeys.IONIC_DATA_SYNC_STATUS)),
      ),
    { dispatch: false },
  );

  public readonly showToaster$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.showToaster),
        withLatestFrom(this.store.select(GlobalSelectors.selectLayout)),
        map(([action]) => {
          const toastr = this.toastrService[action.toasterType](action.message, action.title);
          if (toastr.onTap) toastr.onTap.subscribe(action.onTap);
        }),
      ),
    { dispatch: false },
  );

  public readonly errorHandler$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.effectError),
        map((action) => this.logger.error()('EffectError: ', action.context, action.error)),
      ),
    { dispatch: false },
  );
}
