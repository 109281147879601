import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Notification, NotificationTypeEnum } from '@remberg/notifications/common/main';
import {
  DatetimeService,
  DialogService,
  DynamicPopUpComponent,
  LayoutService,
  ModalDialogWrapper,
  NavigationalActions,
  NotificationService,
  RootGlobalState,
  getDefaultWarningDialogOptions,
} from '@remberg/ui-core/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-notifications-popup',
  templateUrl: './notifications-popup.component.html',
  styleUrls: ['./notifications-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsPopupComponent implements OnChanges {
  @Input() public notificationCenterTrigger?: MatMenuTrigger;
  @Input() public dialogModalRef?: ModalDialogWrapper;
  @Input() public notifications?: [
    { expanded: boolean; target: string; notifications: Notification[] }?,
  ];

  protected readonly NotificationTypeEnum = NotificationTypeEnum;

  constructor(
    protected readonly layout: LayoutService,
    protected readonly dateTimeService: DatetimeService,
    private readonly notificationService: NotificationService,
    private readonly toastr: ToastrService,
    private readonly _dialog: DialogService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<RootGlobalState>,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.notifications) {
      this.notifications = changes.notifications.currentValue;
    }
  }

  protected close(): void {
    if (this.layout.isXSmallView.getValue()) {
      this.dialogModalRef?.closeDialogModalFromChildComponent();
    }
  }

  private getNotificationLink(notification: Notification): string {
    const link = this.notificationService.getNotificationLink(notification);
    return link !== '' ? link : this.router.url;
  }

  protected getMatIconForNotification(notification: Notification): string {
    return this.notificationService.getNotificationIcon(notification);
  }

  protected async markNotificationAsRead(
    notificationID: string | undefined,
    event: Event,
    doNotCloseMenu?: boolean,
  ): Promise<void> {
    event.stopPropagation();
    if (!notificationID) {
      return;
    }

    try {
      await firstValueFrom(this.notificationService.readNotification(notificationID));

      if (!doNotCloseMenu) {
        this.dialogModalRef?.closeDialogModalFromChildComponent();
        this.notificationCenterTrigger?.closeMenu();
        return;
      }
      // When this component is used as a modal, the notification input is not updated -> we manually update the local data
      // TODO refactor using ngrx
      if (!this.notifications) return;

      for (const notification of this.notifications) {
        if (!notification) continue;
        const { notifications } = notification;
        const notificationsCount = notifications.length;
        notification.notifications = notifications.filter((n) => n._id !== notificationID);

        if (notification.notifications.length < notificationsCount) {
          break;
        }
      }
      this.cdr.markForCheck();
    } catch (error) {
      this.toastr.error(
        $localize`:@@notificationCouldNotBeMarkedAsRead:Notification could not be marked as read!`,
        $localize`:@@error:Error`,
      );
    }
  }

  protected isNotificationGroupRead(notificationGroup: { notifications: Notification[] }): boolean {
    return notificationGroup.notifications.every((n) => n.isRead);
  }

  private showRemoveAllNotificationsConfirmationPopup(): ModalDialogWrapper {
    const descriptionTexts = [
      $localize`:@@areYouReallySureYouWantToRemoveAllNotifications:Are you sure you want to remove all notifications?`,
    ];
    const dialogOpts = getDefaultWarningDialogOptions(descriptionTexts);
    return this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
  }

  protected expandButton(event: Event, notificationGroup: { expanded: boolean }): void {
    event.stopPropagation();
    notificationGroup.expanded = !notificationGroup.expanded;
  }

  protected redirectToNotificationLink(notification: Notification): void {
    const baseUrl = this.getNotificationLink(notification);
    const notificationParams = this.notificationService.getNotificationQueryParams(notification);
    const queryParams = new URLSearchParams(notificationParams);
    const queryString = queryParams ? `?${queryParams}` : '';
    this.store.dispatch(
      NavigationalActions.goToUrlEnsureRerender({ url: `${baseUrl}${queryString}` }),
    );
  }

  protected async removeNotifications({
    notifications,
    event,
    shouldCloseMenu = true,
  }: {
    notifications: Notification[];
    event: Event;
    shouldCloseMenu?: boolean;
  }): Promise<void> {
    event.stopPropagation();

    const notificationIds = notifications.map((n) => n._id ?? '');
    if (!notificationIds.length) return;

    try {
      await firstValueFrom(
        this.notificationService.removeNotifications({ notificationIds: notificationIds }),
      );
      if (this.notifications?.[0]) {
        this.notifications[0].notifications = this.notifications[0].notifications?.filter(
          (n) => n._id && !notificationIds.includes(n._id),
        );
      }
      this.cdr.markForCheck();

      if (shouldCloseMenu) {
        this.dialogModalRef?.closeDialogModalFromChildComponent();
        this.notificationCenterTrigger?.closeMenu();
        return;
      }
    } catch (error) {
      this.showRemovalError();
    }
  }

  protected async removeAllNotifications(event: Event): Promise<void> {
    event.stopPropagation();
    const popup = this.showRemoveAllNotificationsConfirmationPopup();
    const data = await popup.waitForCloseData();
    if (!data?.confirmed) return;

    try {
      await firstValueFrom(this.notificationService.removeNotifications({ shouldDeleteAll: true }));
      if (this.notifications?.[0]) {
        this.notifications[0].notifications = [];
      }

      this.cdr.markForCheck();
    } catch (error) {
      this.showRemovalError();
    }
  }

  private showRemovalError(): void {
    this.toastr.error(
      $localize`:@@notificationsCouldNotBeRemovedAtTheMoment:Notifications could not be removed at the moment!`,
      $localize`:@@error:Error`,
    );
  }
}
