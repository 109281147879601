<div class="update-required-header">
  <img class="update-required-logo-img" src="/assets/img/remberg-darkblue.png" />
</div>
<div class="update-required-content-wrapper">
  <div class="padded update-required-content">
    <img class="update-required-img" src="/assets/img/update-app-required-img.png" />
    <h1 i18n="@@newApplicationVersionAvailable">New application version available</h1>
    <p
      class="update-required-copy-text"
      i18n="
        @@discoverNewFeaturesAndImprovementsInOurLatestVersionInOrderToContinueToUseOurAppUpdateByTappingGoToTheAppStore"
    >
      Discover new features and improvements in our latest version. In order to continue to use our
      app, update by tapping 'Go to the App Store'
    </p>

    <a
      *ngIf="(deviceType$ | async)?.isAndroid"
      href="https://play.google.com/store/apps/details?id=de.remberg.remberg"
      target="_blank"
      mat-raised-button
      color="primary"
      i18n="@@goToTheAppStore"
    >
      Go to the App Store
    </a>
    <a
      *ngIf="(deviceType$ | async)?.isIos"
      href="https://apps.apple.com/de/app/remberg/id1542476352"
      target="_blank"
      mat-raised-button
      color="primary"
      i18n="@@goToTheAppStore"
    >
      Go to the App Store
    </a>
  </div>
</div>
