import { Pipe, PipeTransform } from '@angular/core';
import { ContactBasic } from '@remberg/crm/common/base';
import { contactToFullNameString } from '@remberg/crm/common/main';

@Pipe({
  name: 'contactFullName',
})
export class ContactFullNamePipe implements PipeTransform {
  public transform(
    contact: ContactBasic | undefined,
    fallBackName = $localize`:@@unknownUser:Unknown User`,
  ): string {
    return (contact && contactToFullNameString(contact)) || fallBackName;
  }
}
