<p class="privacy-policy-text">
  <ng-container i18n="@@ppPrivacyPolicyConfirmationPartOne"
    >By submitting the form, I confirm that I have read the
  </ng-container>
  <a href="https://www.remberg.de/privacy-policy-app/" target="_blank" i18n="@@privacyPolicy"
    >Privacy Policy</a
  >
  <ng-container i18n="@@andAgreeToTheProcessingOfMyPersonalDataBy">
    and agree to the processing of my personal data by
  </ng-container>
  <a href="https://remberg.de" target="_blank">remberg</a>
  <ng-container
    i18n="@@forThePurposesStatedDotInTheEventOfConsentCommaIMayRevokeMyConsentAtAnyTimeDot"
  >
    for the purposes stated. In the event of consent, I may revoke my consent at any time.
  </ng-container>
</p>
