import { createProvider } from '@remberg/forms/common/main';
import { RembergDate, RembergDateTime } from '@remberg/global/common/core';
import { Address } from '@remberg/global/ui';
import { toRembergDate } from '../../../datetime.service';
import { PrefillingStepFunctions } from './interfaces';

export const STATIC_PREFILLING_STEPS_PROVIDER = createProvider<PrefillingStepFunctions>({
  addressToCityString: (address: Address | undefined) => address?.city,
  dateTimeToDate,
  staticString: bypass,
  staticStringArray: bypass,
  staticBoolean: bypass,
  staticAddress: bypass,
  staticDate: bypass,
  staticDateTime: bypass,
  staticTime: bypass,
  staticPhoneNumber: bypass,
  staticTasks: bypass,
});

function bypass<T>(_: void, input: T | undefined): T | undefined {
  return input;
}

/** Parses a RembergDateTime string and transforms it to a RembergDate */
export function dateTimeToDate(value: RembergDateTime | undefined): RembergDate | undefined {
  if (!value || typeof value !== 'string') {
    return undefined;
  }

  const [utcDatetime, tz] = value.split(/_(.*)/s);

  try {
    return toRembergDate(new Date(utcDatetime), tz);
  } catch {
    return 'INVALID_DATE';
  }
}
