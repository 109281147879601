import { AbstractControl, ValidatorFn } from '@angular/forms';

export const MIN_LENGTH_TRIMMED_ERROR_KEY = 'minLengthTrimmed';

export function MinLengthTrimmed(min: number): ValidatorFn {
  return (control: AbstractControl): { [MIN_LENGTH_TRIMMED_ERROR_KEY]: true } | null => {
    const isInvalid =
      control.value && typeof control.value === 'string' && control.value.trim().length < min;
    return isInvalid ? { [MIN_LENGTH_TRIMMED_ERROR_KEY]: true } : null;
  };
}
