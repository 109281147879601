import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  NavigationBehaviorOptions,
  NavigationEnd,
  NavigationStart,
  ResolveEnd,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { TrackingCategoryEnum } from '@remberg/analytics/common/main';
import { AnalyticsTrackingServiceInterface, TRACKING_SERVICE } from '@remberg/analytics/ui/clients';
import { InternalFeatureFlagValues, entriesOf } from '@remberg/global/common/core';
import {
  LogService,
  RouteDataPropertyEnum,
  environment,
  getLocalBaseUrl,
  isNotAReservedSubdomain,
} from '@remberg/global/ui';
import { RembergUserSettings } from '@remberg/users/common/main';
import intersection from 'lodash/intersection';
import { firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { RembergPage, RembergPageList } from '../../helpers/rembergPage.model';
import { IntercomService } from '../../services/intercom.service';
import { RembergPageService } from '../../services/remberg-page.service';
import { RouterHistoryService } from '../../services/router-history.service';
import { ServerConfigurationService } from '../../services/server-configuration.service';
import { RootGlobalState } from '../core-ui.definitions';
import { GlobalActions, GlobalSelectors } from '../global';
import { RouterSelectors } from '../router';
import { NavigationalActions } from './navigational.actions';

@Injectable()
export class NavigationalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootGlobalState>,
    private readonly router: Router,
    private readonly routerHistoryService: RouterHistoryService,
    @Inject(TRACKING_SERVICE)
    private readonly analyticsTrackingService: AnalyticsTrackingServiceInterface,
    private readonly intercomService: IntercomService,
    private readonly logger: LogService,
    private readonly serverConfigurationService: ServerConfigurationService,
    private readonly rembergPageService: RembergPageService,
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.routerHistoryService.removeLatestUrl(event.url);
      }
      if (event instanceof NavigationEnd) {
        const data = getRouteData(router.routerState.snapshot);
        const isCreationPage = !!data?.[RouteDataPropertyEnum.IS_CREATION_PAGE];
        if (!isCreationPage) {
          this.routerHistoryService.addUrl(event.url);
        }
      }
    });
  }

  public readonly refreshPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationalActions.refreshPage),
      withLatestFrom(this.store.select(RouterSelectors.selectUrl)),
      map(([, url]) => NavigationalActions.goToUrlEnsureRerender({ url })),
    ),
  );

  public readonly goToLastVisitedPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationalActions.goToLastVisitedPage),
        tap(({ bypassReload }) => {
          const currentUrl = this.routerHistoryService.getCurrentUrl();
          const options: NavigationBehaviorOptions = {};

          if (bypassReload) {
            options.onSameUrlNavigation = 'ignore' as unknown as 'reload';
          }
          this.router.navigateByUrl(currentUrl || '/', options);
        }),
      ),
    { dispatch: false },
  );

  public readonly goBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationalActions.goBack),
      map(({ fallbackUrl, ensureRerender }) => {
        const previousUrl = this.routerHistoryService.getPreviousUrl();
        const targetUrl = previousUrl ?? fallbackUrl ?? '/';
        return ensureRerender
          ? NavigationalActions.goToUrlEnsureRerender({ url: targetUrl })
          : NavigationalActions.goToUrl({ url: targetUrl });
      }),
    ),
  );

  public readonly goToUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationalActions.goToUrl),
        withLatestFrom(this.store.select(GlobalSelectors.selectIsIonic)),
        map(([{ url, shouldOpenInNewTab }, isIonic]) =>
          !isIonic && shouldOpenInNewTab
            ? window.open(url, '_blank', 'noopener')
            : this.router.navigateByUrl(url),
        ),
      ),
    { dispatch: false },
  );

  public readonly goToUrlEnsureRerender$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationalActions.goToUrlEnsureRerender),
        mergeMap(async ({ url }) => {
          const [path, queryParamsString = ''] = url.split('?');
          const searchParams = new URLSearchParams(queryParamsString);
          const queryParams = Object.fromEntries(searchParams.entries());

          await this.router.navigate([path], { queryParams, state: { forceRerender: true } });
        }),
      ),
    { dispatch: false },
  );

  public readonly updateStorageAndRefreshAppOnNavigation$ = createEffect(() =>
    this.router.events.pipe(
      filter((event) => event instanceof ResolveEnd),
      map((event) => (event as ResolveEnd).urlAfterRedirects),
      withLatestFrom(
        this.store.select(GlobalSelectors.selectUpdatedTenant),
        this.store.select(GlobalSelectors.selectUpdatedUserRole),
        this.store.select(GlobalSelectors.selectIsApplicationUpdateAvailable),
      ),
      filter(
        ([, updatedFeatures, updatedUserRole, isUpdateAvailable]) =>
          !!updatedFeatures || !!updatedUserRole || isUpdateAvailable,
      ),
      map(([targetUrl]) => GlobalActions.applicationAndStorageUpdateInitiated({ targetUrl })),
    ),
  );

  public readonly redirectOnNavigationWhenIonicApplicationIsInvalid$ = createEffect(
    () =>
      this.router.events.pipe(
        withLatestFrom(
          this.store.select(GlobalSelectors.selectIsIonicAppBelowMinimumRequiredVersion),
        ),
        filter(([, isVersionInvalid]) => isVersionInvalid),
        filter(([event]) => event instanceof ResolveEnd),
        map(([event]) => (event as ResolveEnd).urlAfterRedirects),
        filter((url) => url !== '/update-required'),
        tap(() => {
          this.router.navigateByUrl('/update-required');
        }),
      ),
    { dispatch: false },
  );

  public readonly pingIntercomOnNavigation$ = createEffect(
    () =>
      this.router.events.pipe(
        withLatestFrom(this.store.select(GlobalSelectors.selectIsIntercomWebInitialized)),
        filter(([, isIntercomWebInitialized]) => isIntercomWebInitialized),
        filter(([event]) => event instanceof NavigationEnd),
        map(([event]) => (event as NavigationEnd).urlAfterRedirects),
        tap(() => {
          this.intercomService.sendIntercomPingWeb();
        }),
      ),
    { dispatch: false },
  );

  public readonly trackPageView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        map((event) => event.payload.event.urlAfterRedirects.split('?')[0]), // disregard query params navigations
        distinctUntilChanged(),
        withLatestFrom(
          this.store.select(RouterSelectors.selectRouteTrackingEvent),
          this.store.select(RouterSelectors.selectRouteTrackingEventDataFromUrlParam),
          this.store.select(RouterSelectors.selectRouteTrackingEventDataFromQueryParam),
        ),
        tap(
          ([
            ,
            trackingEvent,
            trackingEventDataFromUrlParamMap,
            trackingEventDataFromQueryParamMap,
          ]) => {
            const overlappingKeys = intersection(
              Object.keys(trackingEventDataFromQueryParamMap),
              Object.keys(trackingEventDataFromUrlParamMap),
            );
            if (overlappingKeys) {
              this.logger.warn()(
                `Route and query params have overlapping keys: ${overlappingKeys} (query params will override culprit keys)`,
              );
            }
            if (trackingEvent) {
              void this.analyticsTrackingService.trackEvent(trackingEvent, {
                category: TrackingCategoryEnum.APPLICATION,
                ...trackingEventDataFromUrlParamMap,
                ...trackingEventDataFromQueryParamMap,
              });
            }
          },
        ),
      ),
    { dispatch: false },
  );

  public readonly goToToApplicationRoot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationalActions.goToApplicationRoot),
        withLatestFrom(this.store.select(GlobalSelectors.selectApplicationRootDomain)),
        tap(([action, applicationRootDomain]) => {
          this.logger.info()(
            `Navigating to application root domain ${applicationRootDomain} with targetUrl: ${action.targetUrl}`,
          );

          let newUrl;
          if (!environment.production && !environment.live && environment.debug) {
            this.logger.debug()(
              'Currently running on a local machine for development, url is independent of the server configuration',
            );
            const baseUrl = getLocalBaseUrl();
            newUrl = new URL(`${window.location.protocol}//${baseUrl}/welcome`);
            if (action.targetUrl) {
              newUrl.searchParams.append('target', action.targetUrl);
            }
          } else {
            this.logger.debug()(
              `Currently running on a non local machine ${window.location.protocol}//${applicationRootDomain}/welcome`,
            );
            newUrl = new URL(`${window.location.protocol}//${applicationRootDomain}/welcome`);
            if (action.targetUrl) {
              newUrl.searchParams.append('target', action.targetUrl);
            }
          }
          this.logger.debug()(`Navigating to: ${newUrl}`);
          window.location.href = newUrl.toString();
        }),
      ),
    { dispatch: false },
  );

  public readonly goToTenantLoginAtSubdomain$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationalActions.goToTenantLoginAtSubdomain),
        withLatestFrom(this.store.select(GlobalSelectors.selectApplicationRootDomain)),
        tap(([action, applicationRootDomain]) => {
          const loginPath = window.location.pathname.includes('/public') ? 'portal/login' : 'login';
          const newUrl = new URL(
            `${window.location.protocol}//${action.subdomain}.${applicationRootDomain}/${loginPath}`,
          );
          if (action.targetUrl) {
            newUrl.searchParams.append('target', action.targetUrl);
          }
          const currentServerName = this.serverConfigurationService.currentServerName;
          if (currentServerName) {
            newUrl.searchParams.append('server', this.serverConfigurationService.currentServerName);
            if (currentServerName === 'preview') {
              const previewUrl = this.serverConfigurationService.currentServerUrl;
              const previewId = previewUrl.match(/^https:\/\/preview-(\d{4,})\.remberg\.dev$/)?.[1];
              if (!previewId) {
                this.store.dispatch(
                  GlobalActions.showToaster({
                    toasterType: 'error',
                    message: $localize`:@@missingPreviewId:Missing PreviewId`,
                    title: $localize`:@@error:Error`,
                  }),
                );
                return;
              }
              newUrl.searchParams.append('preview-id', previewId);
            }
          }
          this.logger.info()(`Navigating to subdomain ${action.subdomain} and full url ${newUrl}`);
          window.location.href = newUrl.toString();
        }),
      ),
    { dispatch: false },
  );

  public readonly goToUnauthenticatedLandingPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationalActions.goToUnauthenticatedLandingPage),
      withLatestFrom(
        this.store.select(GlobalSelectors.selectApplicationRootDomain),
        this.store.select(GlobalSelectors.selectIsAtAdminSubdomain),
        this.store.select(GlobalSelectors.selectIsAtTenantSubdomain),
        this.store.select(GlobalSelectors.selectIsSubdomainRoutingActive),
        this.store.select(GlobalSelectors.selectTenantSubdomain),
      ),
      mergeMap(
        async ([
          action,
          applicationRootDomain,
          isAtAdminSubdomain,
          isAtTenantSubdomain,
          isSubdomainRoutingActive,
          tenantSubdomain,
        ]) => {
          this.logger.debug()(`Going to landing page with targetUrl: ${action.targetUrl}`);

          if (isAtAdminSubdomain) {
            this.logger.debug()('isAtAdminSubdomain - navigating to login');
            await this.router.navigate(
              ['login'],
              action.targetUrl ? { queryParams: { target: action.targetUrl } } : undefined,
            );
            return undefined;
          }

          if (isAtTenantSubdomain) {
            const loginPath = window.location.pathname.includes('/public')
              ? 'portal/login'
              : 'login';
            this.logger.debug()(`isAtTenantSubdomain - navigating to ${loginPath}`);
            await this.router.navigate(
              [loginPath],
              action.targetUrl ? { queryParams: { target: action.targetUrl } } : undefined,
            );
            return undefined;
          }

          if (!isSubdomainRoutingActive) {
            this.logger.debug()('subdomain routing disabled - navigating to tenant login');
            await this.router.navigate(
              ['welcome'],
              action.targetUrl ? { queryParams: { target: action.targetUrl } } : undefined,
            );
            return undefined;
          }

          const subdomain = applicationRootDomain?.split('.')[0];
          if (!tenantSubdomain && !(subdomain && isNotAReservedSubdomain(subdomain))) {
            this.logger.debug()('isNotAtValidSubdomain');

            return NavigationalActions.goToApplicationRoot({ targetUrl: action.targetUrl });
          }

          if (action.targetUrl) {
            return NavigationalActions.goToApplicationRoot({ targetUrl: action.targetUrl });
          }

          this.logger.debug()('No subdomain - navigating to application root');
          return NavigationalActions.goToApplicationRoot({});
        },
      ),
      filter(Boolean),
    ),
  );

  public readonly goToAuthenticatedDefaultPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationalActions.goToAuthenticatedDefaultPage),
        withLatestFrom(
          this.store.select(GlobalSelectors.selectIsRembergAdmin),
          this.store.select(GlobalSelectors.selectTenantFeatures),
          this.store.select(GlobalSelectors.selectUserSettings),
        ),
        tap(async ([, isRembergAdmin, tenantFeatures, userSettings]) => {
          if (isRembergAdmin) {
            await this.router.navigateByUrl('/remberg');
            return;
          }

          if (tenantFeatures && userSettings) {
            const defaultUrl = await this.getDefaultUrlForUser(tenantFeatures, userSettings);
            this.logger.debug()('Navigating to: ' + defaultUrl);
            await this.router.navigateByUrl(defaultUrl);
          } else {
            throw Error(
              'Feature array or user settings not provided via selectors (user might not be logged in).',
            );
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * This is a fallback that returns any valid Url so we can always navigate somewhere if needed.
   * @param features Pass in the features that the active user has access to.
   * @returns a fallback Url that is accessible.
   */
  private async getFallbackUrl(features: InternalFeatureFlagValues): Promise<string> {
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    const rembergPages = await this.rembergPageService.prepareRembergPageList();
    let accessiblePages: RembergPage[] = [];
    accessiblePages = rembergPages.filter(
      (page) =>
        page.requiredFeatures?.filter((reqFeature) => features[reqFeature]).length ===
          page.requiredFeatures?.length &&
        (!isIonic || page?.availableMobile === true),
    );
    this.logger.debug()(
      'Finding accessible pages for fallback url call: ' + JSON.stringify(accessiblePages),
    );

    if (accessiblePages.length > 0) {
      return accessiblePages[0].link;
    } else {
      return '/notfound';
    }
  }

  /**
   * This function validates whether a passed url is a correct target url and can be accessed given a certain feature set.
   * @param url Url that should be validated.
   * @param features Pass in the features that the active user has access to.
   * @returns A boolean that indicates if the URL is accessible.
   */
  private async validateTargetUrl(
    url: string,
    features: InternalFeatureFlagValues,
  ): Promise<boolean> {
    this.logger.debug()('Validating target url...');
    const rembergPages = await this.rembergPageService.prepareRembergPageList(url);
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    // returns true if for any of the pages all of the features are available and the page.link matches the url
    return rembergPages.some(
      (page) =>
        page.requiredFeatures?.filter((reqFeature) => features[reqFeature]).length &&
        page.link === url &&
        (!isIonic || page?.availableMobile === true),
    );
  }

  /**
   * This function return the default URL that should be opened when the application starts.
   * @param features Pass in the features that the active user has access to.
   * @returns The default url in string format.
   */
  private async getDefaultUrlForUser(
    features: InternalFeatureFlagValues,
    userSettings: RembergUserSettings,
  ): Promise<string> {
    if (!features) {
      throw Error('The features input was not defined.');
    }
    this.logger.debug()('Get default URL for features array: ' + JSON.stringify(features));
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    let defaultPage;
    if (isIonic) {
      defaultPage = userSettings?.defaultMobilePage ?? RembergPageList.WorkOrders.link;
    } else {
      defaultPage = userSettings?.defaultWebPage ?? RembergPageList.Assets.link;
    }
    this.logger.debug()('Checking default page: ' + defaultPage);
    if (await this.validateTargetUrl(defaultPage, features)) {
      return defaultPage;
    } else {
      this.logger.warn()('Falling back on a backup url.');
      return this.getFallbackUrl(features);
    }
  }
}

function getRouteData(snapshot: RouterStateSnapshot): Data {
  let node: ActivatedRouteSnapshot | null = snapshot.root;
  const data: Data = {};

  while (node) {
    for (const [key, value] of entriesOf(node.data)) {
      data[key] = value;
    }
    node = node.firstChild;
  }

  return data;
}
