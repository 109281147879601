/* eslint-disable @typescript-eslint/no-explicit-any */
import { instanceToPlain } from 'class-transformer';
import isEqual from 'lodash/isEqual';

/**
 * Compares two arrays and returns a boolean indicating whether they are equal.
 *
 * @param array1 - The first array to compare.
 * @param array2 - The second array to compare.
 * @param options - The options for comparison.
 * @param options.isUnordered - If true, the order of elements in the arrays is ignored during comparison. Default is false.
 *
 * @returns A boolean indicating whether the arrays are equal.
 */
export function compareArrays(
  array1: any[],
  array2: any[],
  options: { isUnordered?: boolean } = {},
): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  const pojoArray1 = instanceToPlain(array1) as any[];
  const pojoArray2 = instanceToPlain(array2) as any[];

  if (options.isUnordered) {
    const sortedArray1 = [...pojoArray1].sort(compareValues);
    const sortedArray2 = [...pojoArray2].sort(compareValues);
    return isEqual(sortedArray1, sortedArray2);
  }
  return isEqual(pojoArray1, pojoArray2);
}

/**
 * The compareValues function uses getTypeOrder to determine the type order and compareSameType to compare values of the same type.
 * */
function compareValues(a: any, b: any): number {
  const typeOrderA = getTypeOrder(a);
  const typeOrderB = getTypeOrder(b);
  if (typeOrderA !== typeOrderB) return typeOrderA - typeOrderB;
  return compareSameType(a, b);
}

/**
 *  Assigns a numerical order to different types of values (e.g., null comes before boolean, boolean comes before number, etc.).
 *  */
function getTypeOrder(value: any): number {
  if (value === null) return 0;
  if (typeof value === 'boolean') return 1;
  if (typeof value === 'number') return 2;
  if (typeof value === 'string') return 3;
  if (Array.isArray(value)) return 4;
  if (typeof value === 'object') return 5;
  return 6; // undefined or other types
}

/**
 * The compareSameType function defines how to compare values of the same type:
 *  •	Strings and numbers are compared directly.
 *  •	Booleans are compared with true considered greater than false.
 *  •	Arrays are compared element by element recursively.
 *  •	Objects are compared by their sorted keys and their corresponding values recursively.
 * */
function compareSameType(a: any, b: any): number {
  if (typeof a === 'string' || typeof a === 'number') {
    return a < b ? -1 : a > b ? 1 : 0;
  }

  if (typeof a === 'boolean') {
    return a === b ? 0 : a ? 1 : -1;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    for (let i = 0; i < Math.min(a.length, b.length); i++) {
      const result = compareValues(a[i], b[i]);
      if (result !== 0) return result;
    }
    return a.length - b.length;
  }

  if (typeof a === 'object' && typeof b === 'object') {
    const aKeys = Object.keys(a).sort();
    const bKeys = Object.keys(b).sort();
    for (let i = 0; i < Math.min(aKeys.length, bKeys.length); i++) {
      const keyComparison = compareValues(aKeys[i], bKeys[i]);
      if (keyComparison !== 0) return keyComparison;
      const valueComparison = compareValues(a[aKeys[i] as string], b[aKeys[i] as string]);
      if (valueComparison !== 0) return valueComparison;
    }
    return aKeys.length - bKeys.length;
  }

  return 0;
}
