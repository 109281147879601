import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
} from '@remberg/global/ui';
import { WorkOrderType } from '@remberg/work-orders/common/base';
import {
  WorkOrderTypeCreateOneBody,
  WorkOrderTypeCreateOneResponse,
  WorkOrderTypeFindManyByIdsBody,
  WorkOrderTypeFindManyQuery,
  WorkOrderTypeFindManyResponse,
  WorkOrderTypeUpdateOneBody,
} from '@remberg/work-orders/common/main';
import { Observable } from 'rxjs';
import {
  WORK_ORDER_TYPE_2_OFFLINE_SERVICE,
  WorkOrderType2OfflineServiceInterface,
} from './work-order-type.offline.service.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderTypeService {
  private readonly WORK_ORDER_TYPES_URL = `${API_URL_PLACEHOLDER}/workorders/v2/types`;

  constructor(
    private readonly http: HttpClient,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(WORK_ORDER_TYPE_2_OFFLINE_SERVICE)
    private readonly workOrderType2OfflineService: WorkOrderType2OfflineServiceInterface,
  ) {}

  public findMany(options?: WorkOrderTypeFindManyQuery): Observable<WorkOrderTypeFindManyResponse> {
    if (!this.connectivityService.getConnected()) {
      return this.workOrderType2OfflineService.findMany(options);
    }

    let params = new HttpParams();

    if (options?.page !== undefined) {
      params = params.set('page', String(options.page));
    }
    if (options?.limit !== undefined) {
      params = params.set('limit', String(options.limit));
    }

    return this.http.get<WorkOrderTypeFindManyResponse>(this.WORK_ORDER_TYPES_URL, {
      params,
    });
  }

  public findAll(): Observable<WorkOrderType[]> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: findAll`);
    }

    return this.http.get<WorkOrderType[]>(`${this.WORK_ORDER_TYPES_URL}/all`);
  }

  public findManyByIds(body: WorkOrderTypeFindManyByIdsBody): Observable<WorkOrderType[]> {
    if (!this.connectivityService.getConnected()) {
      return this.workOrderType2OfflineService.findManyByIds(body);
    }

    return this.http.put<WorkOrderType[]>(`${this.WORK_ORDER_TYPES_URL}/sync`, body);
  }

  public deleteOne(id: string): Observable<void> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: deleteOne ${id}`);
    }

    return this.http.delete<void>(`${this.WORK_ORDER_TYPES_URL}/${id}`);
  }

  public create(body: WorkOrderTypeCreateOneBody): Observable<WorkOrderTypeCreateOneResponse> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: create body ${JSON.stringify(body)}`);
    }

    return this.http.post<WorkOrderTypeCreateOneResponse>(this.WORK_ORDER_TYPES_URL, body);
  }

  public update(id: string, body: WorkOrderTypeUpdateOneBody): Observable<WorkOrderType> {
    if (!this.connectivityService.getConnected()) {
      throw new Error(`Offline mode not supported for: update id ${id}`);
    }

    return this.http.patch<WorkOrderType>(`${this.WORK_ORDER_TYPES_URL}/${id}`, body);
  }
}
