import { MultiLanguageText } from '@remberg/global/common/core';
import { MultiLanguageHtml } from '../../core';
import { AutofillTypeEnum, ConfigWithDynamicActions, ConfigWithPrefilling } from '../automation';
import { SignatureInfo } from '../common';
import { FormSectionTypesEnum } from './section-type.enum';

/**
 * Signature section options, used in the config and the state.
 * Used in validation as well.
 */
export interface FormSignatureSection {
  id: string;
  type: FormSectionTypesEnum.SIGNATURE_SECTION;
  config: FormSignatureSectionConfig;
}

export interface FormSignatureSectionConfig
  extends ConfigWithPrefilling<SignatureSectionPrefillTargetsEnum> {
  required: boolean;
  headlineText: MultiLanguageText;
  helpTextHtmlAbove?: {
    text: MultiLanguageHtml;
    pdfHide?: boolean;
  };
  helpTextHtmlBelow?: {
    text: MultiLanguageHtml;
    pdfHide?: boolean;
  };
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  hidePreviewPdfButton?: boolean;
  hideDownloadPdfButton?: boolean;
  /**
   * The relative section width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;

  // Fields config
  fields?: {
    dateInput?: {
      disable?: boolean;
      required?: boolean;
      pdfHide?: boolean;
      autofill?: AutofillTypeEnum;
    } & ConfigWithDynamicActions;
    locationSingleLineTextInput?: {
      disable?: boolean;
      required?: boolean;
      pdfHide?: boolean;
    } & ConfigWithDynamicActions;
    nameSingleLineTextInput?: {
      disable?: boolean;
      required?: boolean;
      pdfHide?: boolean;
      capitalizeFirstLetterOfEveryWord?: boolean;
    } & ConfigWithDynamicActions;
  };
}

export enum SignatureSectionPrefillTargetsEnum {
  NAME = 'name',
  LOCATION = 'location',
  DATE = 'date',
}

export type FormSignatureSectionData = SignatureInfo;
