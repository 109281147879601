import { Injectable } from '@angular/core';
import { Params, QueryParamsHandling, Router } from '@angular/router';
import { DEFAULT_PAGESIZE } from '@remberg/global/ui';

@Injectable({
  providedIn: 'root',
})
export class UrlUpdaterService {
  constructor(private router: Router) {}

  public updateUrl(
    baseUrl: string | string[],
    params: Params | null,
    replaceUrl = true,
    queryParamsHandling: QueryParamsHandling | null = 'merge',
    ignoreDefaultParams = false,
  ): void {
    if (
      !params ||
      Object.keys(params).length === 0 ||
      (!params['pageSize'] && !ignoreDefaultParams)
    ) {
      const defaultQueryParams: Params = !ignoreDefaultParams ? this.getDefaultQueryParams() : {};
      this.router.navigate(Array.isArray(baseUrl) ? baseUrl : [baseUrl], {
        replaceUrl,
        queryParams: defaultQueryParams,
        queryParamsHandling,
      });
    } else {
      const queryParams = this.constructParamsObjectFrom(params);
      this.router.navigate(Array.isArray(baseUrl) ? baseUrl : [baseUrl], {
        replaceUrl,
        queryParams: queryParams,
        queryParamsHandling,
      });
    }
  }

  private constructParamsObjectFrom = (params: Params): Params => {
    const keys = Object.keys(params);

    const queryParams: Params = {};

    for (const key of keys) {
      // params with values that are empty strings, null or undefined shall not appear in the URL
      if (params[key] === '' || params[key] == null) {
        queryParams[key] = null;
      } else {
        queryParams[key] = params[key];
      }
    }

    return queryParams;
  };

  private getDefaultQueryParams = (): Params => ({
    pageSize: DEFAULT_PAGESIZE,
    pageIndex: 0,
    sortDirection: undefined,
    sortField: undefined,
    filters: [],
  });
}
