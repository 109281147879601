import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function stringValidator(matchingStr: string, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): Record<string, any> | null => {
    if (!control.value) {
      return null;
    }
    const valid = control.value === matchingStr;
    return valid ? null : error;
  };
}
