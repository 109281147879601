import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AdvancedFilterTypeEnum } from '@remberg/advanced-filters/common/main';
import { FilterType2 } from '@remberg/global/ui';
import { FilterField, FilterFieldOption, FilterTypeEnum } from '../../../../helpers/filters';

@Component({
  selector: 'app-filter-mobile-dialog-entry',
  templateUrl: './filter-mobile-dialog-entry.component.html',
  styleUrls: ['./filter-mobile-dialog-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterMobileDialogEntryComponent implements OnChanges {
  @Input() public first?: boolean;
  @Input() public filter?: FilterType2<string>;
  @Input() public filterFieldOptions?: FilterField<string>;
  @Input() public availableFilterFieldOptions?: FilterField<string>;
  protected availableFilterFieldOptionsAsArray?: FilterFieldOption<string>[];

  protected readonly FilterTypeEnum = FilterTypeEnum;
  protected readonly AdvancedFilterTypeEnum = AdvancedFilterTypeEnum;

  protected readonly booleanFilterValueOptions = [
    {
      label: $localize`:@@yes:Yes`,
      value: 'true',
    },
    {
      label: $localize`:@@no:No`,
      value: 'false',
    },
  ];

  @Output() public remove = new EventEmitter<void>();
  @Output() public filterChange = new EventEmitter<FilterType2<string>>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['availableFilterFieldOptions']?.currentValue && this.filter) {
      this.availableFilterFieldOptions = { ...changes['availableFilterFieldOptions'].currentValue };
      if (this.availableFilterFieldOptions) {
        this.availableFilterFieldOptions[this.filter.identifier] =
          this.filterFieldOptions?.[this.filter.identifier];
      }
      if (this.availableFilterFieldOptions) {
        this.availableFilterFieldOptionsAsArray = Object.values(this.availableFilterFieldOptions)
          .filter((option): option is FilterFieldOption<string> => !!option)
          .sort((a: FilterFieldOption<string>, b: FilterFieldOption<string>) =>
            a.label.localeCompare(b.label),
          );
      }
    }
  }

  protected filterIdentifierChange(identifier: string): void {
    this.filter = {
      identifier: identifier,
      value: null as unknown as string,
    };
    this.filterChange.emit(this.filter);
  }

  protected filterValueChange(value: string): void {
    this.filter = {
      identifier: this.filter?.identifier as string,
      value,
    };
    this.filterChange.emit(this.filter);
  }
}
