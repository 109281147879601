import { Pipe, PipeTransform } from '@angular/core';
import { RembergTime, RembergTimestamp } from '@remberg/global/common/core';
import { DatetimeService, toRembergTime } from '@remberg/ui-core/core';

@Pipe({
  name: 'toRembergTime',
})
export class ToRembergTimePipe implements PipeTransform {
  constructor(private readonly datetimeService: DatetimeService) {}

  public transform(value: RembergTimestamp): RembergTime;
  public transform(value: Date | undefined): RembergTime | undefined;
  public transform(value: RembergTimestamp | undefined): RembergTime | undefined;
  public transform(
    value: RembergTimestamp | Date | null | undefined | string,
  ): RembergTime | undefined {
    if (!value) return undefined;

    try {
      const tz = this.datetimeService.getUserTimezone();
      return toRembergTime(value, tz);
    } catch (error) {
      return undefined;
    }
  }
}
