export function isObjectId(input: unknown, shouldIncludeObjectCheck = true): boolean {
  let valueToCheck = '';
  switch (typeof input) {
    case 'number': {
      return false;
    }
    case 'string': {
      valueToCheck = input;
      break;
    }
    case 'object': {
      if (!shouldIncludeObjectCheck) return false;
      valueToCheck = String(
        (input as { _id?: string })?._id ?? (input as { id?: string })?.id ?? input,
      );
      break;
    }
    default: {
      valueToCheck = String(input);
      break;
    }
  }

  return valueToCheck.length === 24 && /^[a-f\d]{24}$/i.test(valueToCheck);
}
