import { OrganizationBasic } from '@remberg/crm/common/base';
import { UnknownOr, isUnknownObject } from '@remberg/global/common/core';
import { BaseModel, PredefinedDialogOption } from '@remberg/global/ui';

export function createViewableOrganizationOptions(
  predefinedOptions: PredefinedDialogOption[],
  loadedOptions: OrganizationBasic[],
  currentOrganization: OrganizationBasic | undefined,
  selectedOption: UnknownOr<OrganizationBasic> | BaseModel | undefined,
): UnknownOr<OrganizationBasic>[] {
  // Steps for creating the viewable options:
  // - add the predefined options if any
  // - add the selected option if any
  // - add the current organization if any
  // - add the loaded options from the backend if any
  // the order needs to be preserved and no duplicates should appear in the list
  const allOrganizationOptions: UnknownOr<OrganizationBasic>[] = [];

  // Add the predefined options
  allOrganizationOptions.push(
    ...predefinedOptions.map((option) => predefinedOptionToOrganizationBasic(option)),
  );

  // Add the selected option if it is an UnknownOr<OrganizationBasic> and NOT already part of the options array.
  if (
    (isOrganizationBasic(selectedOption) || isUnknownObject(selectedOption)) &&
    !allOrganizationOptions.find((item) => item._id === selectedOption._id)
  ) {
    allOrganizationOptions.push(selectedOption);
  }

  // Add the current organization if NOT already part of the options array.
  if (
    currentOrganization &&
    !allOrganizationOptions.find((item) => item._id === currentOrganization._id)
  ) {
    allOrganizationOptions.push(currentOrganization);
  }

  // Add the loaded options
  if (loadedOptions) {
    for (const option of loadedOptions) {
      // If not already part of the options array.
      if (!allOrganizationOptions.find((item) => item._id === option._id)) {
        allOrganizationOptions.push(option);
      }
    }
  }

  return allOrganizationOptions;
}

export function isOrganizationBasic(
  organization: OrganizationBasic | BaseModel | undefined,
): organization is OrganizationBasic {
  return !!(organization as OrganizationBasic)?.name;
}

export function predefinedOptionToOrganizationBasic(
  option: PredefinedDialogOption,
): OrganizationBasic {
  return {
    _id: option.id,
    name: option.label,
  };
}
