<ng-container *ngIf="isEnabled$ | async">
  <ng-container *ngIf="isSpinning$ | async; else noSpinning">
    <div class="spinner-wrapper">
      <mat-spinner
        [diameter]="spinnerDiameter"
        [matTooltip]="inProgressTooltipText"
        data-test-id="auto-saving-spinner"
      ></mat-spinner>
    </div>
  </ng-container>
  <ng-template #noSpinning>
    <button
      mat-icon-button
      [matTooltip]="completionTooltipText"
      data-test-id="auto-saving-done"
      color="primary"
    >
      <mat-icon class="mat-button-with-icon">done</mat-icon>&nbsp;
    </button>
  </ng-template>
</ng-container>
