import { Pipe, PipeTransform } from '@angular/core';
import { RembergTimestamp, TableColumnDisplayOptionEnum } from '@remberg/global/common/core';
import { DatetimeService, isISODateString } from '@remberg/ui-core/core';

@Pipe({
  name: 'rembergTimestampDisplay',
})
export class RembergTimestampDisplayPipe implements PipeTransform {
  constructor(private readonly datetimeService: DatetimeService) {}

  public transform(
    value: RembergTimestamp | null | undefined,
    displayOption:
      | TableColumnDisplayOptionEnum
      | 'customFormat' = TableColumnDisplayOptionEnum.EXACT,
    overrideFormat?: string,
  ): string {
    if (!value) return '';

    if (!value || !isISODateString(value)) return value;

    if (displayOption === TableColumnDisplayOptionEnum.RELATIVE) {
      return this.datetimeService.getElapsedTime(value);
    }

    const timezone = this.datetimeService.getUserTimezone();

    const formatString = overrideFormat ?? 'P, p';

    return this.datetimeService.formatDateInTimezone(value, timezone, formatString);
  }
}
