import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@remberg/global/ui';

@Injectable({
  providedIn: 'root',
})
export class DevelopmentGuardService {
  private environment = environment;

  constructor(private router: Router) {}

  public canActivate(): boolean {
    if (!this.environment.production) {
      return true;
    }
    this.router.navigateByUrl('/login');
    return false;
  }

  public canActivateChild(): boolean {
    return this.canActivate();
  }
}
