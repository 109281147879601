import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FormInstanceActivityCreateBody,
  FormInstanceActivityListResponse,
} from '@remberg/forms/common/dtos';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormInstanceActivityService {
  public readonly formsUrl = `${API_URL_PLACEHOLDER}/forms/v2/instances`;

  constructor(private http: HttpClient) {}

  public getList(formInstanceId: string): Observable<FormInstanceActivityListResponse> {
    const url = `${this.formsUrl}/${formInstanceId}/activities`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    return this.http.get<FormInstanceActivityListResponse>(url, httpOptions);
  }

  public deleteOne(formInstanceId: string, activityId: string): Observable<void> {
    const url = `${this.formsUrl}/${formInstanceId}/activities/${activityId}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    return this.http.delete<void>(url, httpOptions);
  }

  public createOne(
    formInstanceId: string,
    payload: FormInstanceActivityCreateBody,
  ): Observable<void> {
    const url = `${this.formsUrl}/${formInstanceId}/activities`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    return this.http.post<void>(url, payload, httpOptions);
  }
}
