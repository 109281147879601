export enum AppointmentSortFieldEnum {
  COUNTER = 'counter',
  SUBJECT = 'subject',
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt',
  START_DATE = 'startDate',
  END_DATE = 'endDate',

  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  CITY = 'city',
  ZIP_POST_CODE = 'zipPostCode',
  STREET = 'street',
}
