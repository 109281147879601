<div class="dialog-header-container">
  <div class="dialog-title-container">
    <mat-icon class="arrow-back" [mat-dialog-close]="">arrow_back</mat-icon>
    <h2 class="dialog-title-text" i18n="@@addFilter">Add filter</h2>
  </div>
  <div class="dialog-actions">
    <div class="mobile-action-buttons">
      <button
        mat-button
        color="primary"
        data-test-id="filter-done"
        [disabled]="doneButtonDisabled$ | async"
        (click)="done()"
        i18n="@@done"
      >
        Done
      </button>
    </div>
  </div>
</div>

<div
  class="filters-container"
  [ngClass]="{
    'filters-container-ios': (deviceType$ | async)?.isIos,
    'filters-container-android': (deviceType$ | async)?.isAndroid
  }"
>
  <p
    class="no-filter-message empty-list"
    *ngIf="!(currentFilterFields$ | async)?.length"
    data-test-id="no-filters"
    i18n="@@noFiltersSelected"
  >
    There are currently no filters selected.
  </p>

  <div class="mobile-filter-section-wrapper">
    <ng-container
      *ngFor="
        let filter of currentFilterFields$ | async;
        index as filterIndex;
        first as first;
        trackBy: trackEntriesBy
      "
    >
      <app-filter-mobile-dialog-entry
        [first]="first"
        [filterFieldOptions]="filterFieldOptions"
        [availableFilterFieldOptions]="(availableFilterFieldOptions$ | async) ?? undefined"
        [filter]="filter"
        (filterChange)="filterChange($event, filterIndex)"
        (remove)="removeFilter(filter.identifier)"
      >
      </app-filter-mobile-dialog-entry>
    </ng-container>
  </div>

  <div class="mobile-dialog-button-wrapper" fxLayout="column">
    <button
      mat-raised-button
      cdkFocusInitial
      [disabled]="addFilterDisabled$ | async"
      color="primary"
      data-test-id="add-filter"
      i18n="@@plusAddAFilter"
      (click)="addFilter()"
    >
      + Add a filter
    </button>
    <button
      mat-button
      [disabled]="(currentFilterFields$ | async)?.length === 0"
      class="clear-all-button"
      color="warn"
      data-test-id="action"
      i18n="@@clearAll"
      (click)="clearAll()"
    >
      Clear all
    </button>
  </div>
</div>
