import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ContactBasic } from '@remberg/crm/common/base';
import {
  Contact,
  ContactCompatibility,
  ContactRaw,
  ContactsCreateOneAdminBody,
  ContactsCreateOneBody,
  ContactsFindManyBasicResponse,
  ContactsFindManyCompatibilityResponse,
  ContactsFindManyQuery,
  ContactsFindManyResponse,
  ContactsPopulateTypeEnum,
  ContactsUpdateOneBody,
  contactRawToContactBasic,
} from '@remberg/crm/common/main';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
  filterEmptyProps,
} from '@remberg/global/ui';
import { Observable, catchError, from, map, of, throwError } from 'rxjs';
import { ContactsFindManyWithCountCompatibilityParams } from './contacts.definitions';
import {
  CONTACTS_OFFLINE_SERVICE,
  ContactsOfflineServiceInterface,
} from './contacts.offline.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  public readonly contactsUrl = `${API_URL_PLACEHOLDER}/contacts/v1`;

  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly http: HttpClient,
    private readonly logger: LogService,
    @Inject(CONTACTS_OFFLINE_SERVICE)
    private readonly contactOfflineService: ContactsOfflineServiceInterface,
  ) {}

  public findOne(
    id: string,
    populate?: Record<ContactsPopulateTypeEnum, boolean>,
  ): Observable<Contact> {
    let httpParams = new HttpParams();
    if (populate?.organization) {
      httpParams = httpParams.set(
        'populate',
        JSON.stringify(ContactsPopulateTypeEnum.ORGANIZATION),
      );
    }

    return this.http.get<Contact>(`${this.contactsUrl}/${id}`, {
      params: httpParams,
    });
  }

  public findOneRaw(contactId: string): Observable<ContactRaw | undefined> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()('Getting one contact raw in offline mode');
      return from(this.contactOfflineService.tryGetInstance(contactId));
    }

    return this.http
      .put<ContactRaw[]>(`${this.contactsUrl}/sync`, { ids: [contactId] })
      .pipe(map((result) => result[0]));
  }

  public findOneRawOrThrow(contactId: string): Observable<ContactRaw> {
    return this.findOneRaw(contactId).pipe(
      map((contact) => {
        if (!contact) {
          throw new Error('Failed to retrieve ContactRaw' + contactId);
        }
        return contact;
      }),
    );
  }

  public findOneBasic(contactId: string): Observable<ContactBasic | undefined> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()('Getting one contact in offline mode');
      return from(this.contactOfflineService.tryGetInstance(contactId)).pipe(
        map(contactRawToContactBasic),
      );
    }

    return this.http.get<ContactBasic>(`${this.contactsUrl}/basic/${contactId}`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound || error.status === HttpStatusCode.Forbidden) {
          return of(undefined);
        }

        return throwError(() => error);
      }),
    );
  }

  public findOneCompatibility(contactId: string): Observable<ContactCompatibility> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()('Getting one contact in offline mode');
      return from(this.contactOfflineService.getInstance(contactId));
    }

    return this.http.get<ContactCompatibility>(`${this.contactsUrl}/compatibility/${contactId}`);
  }

  public findMany(params: ContactsFindManyQuery): Observable<ContactsFindManyResponse> {
    const { filterObject, staticFilters, populate, ...contactParams } = params;
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(contactParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
        ...(populate && { populate: JSON.stringify(populate) }),
      },
    });

    return this.http.get<ContactsFindManyResponse>(`${this.contactsUrl}`, {
      params: httpParams,
    });
  }

  public findManyWithCountBasic(
    params: ContactsFindManyQuery,
  ): Observable<ContactsFindManyBasicResponse> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()('Getting many contacts basic with count in offline mode');
      return from(this.contactOfflineService.getManyWithCountBasic(params));
    }

    const { filterObject, staticFilters, ...findManyParams } = params;
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(findManyParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
      },
    });

    return this.http.get<ContactsFindManyBasicResponse>(`${this.contactsUrl}/basic`, {
      params: httpParams,
    });
  }

  public findManyWithCountCompatibility(
    params: ContactsFindManyWithCountCompatibilityParams,
  ): Observable<{ count: number; data: ContactCompatibility[] }> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()('Getting many contacts with count in offline mode');
      return from(this.contactOfflineService.getManyWithCountCompatibility(params));
    }

    const { filterValue, ...findManyParams } = params;
    const organizationId = filterValue?.find((f) => f.identifier === 'organization')?.value;
    const httpParams = new HttpParams({
      fromObject: {
        ...(organizationId && { organizationId }),
        ...filterEmptyProps(findManyParams),
      },
    });

    return this.http
      .get<ContactsFindManyCompatibilityResponse>(`${this.contactsUrl}/compatibility`, {
        params: httpParams,
      })
      .pipe(map(({ count, contacts }) => ({ count, data: contacts })));
  }

  public findManyByIdsCompatibility(contactIds: string[]): Observable<ContactCompatibility[]> {
    return this.http.put<ContactCompatibility[]>(`${this.contactsUrl}/compatibility`, {
      contactIds,
    });
  }

  public findManyBasicByIds(contactIds: string[]): Observable<ContactBasic[]> {
    return this.http.put<ContactBasic[]>(`${this.contactsUrl}/basic`, { contactIds });
  }

  public findManyBasicByEmails(emails: string[]): Observable<ContactBasic[]> {
    return this.http.put<ContactBasic[]>(`${this.contactsUrl}/emails`, { emails });
  }

  public findManyBasicByEmailsAsMap(emails: string[]): Observable<Record<string, ContactBasic>> {
    return this.findManyBasicByEmails(emails).pipe(
      map((contacts) =>
        contacts.reduce<Record<string, ContactBasic>>((prev, curr) => {
          prev[curr?.primaryEmail ?? ''] = curr;
          return prev;
        }, {}),
      ),
    );
  }

  public deleteOne(id: string): Observable<void> {
    return this.http.delete<void>(`${this.contactsUrl}/${id}`);
  }

  public createOne(contact: ContactsCreateOneBody): Observable<Contact> {
    return this.http.post<Contact>(`${this.contactsUrl}`, contact);
  }

  public createOneAdmin(contact: ContactsCreateOneAdminBody): Observable<Contact> {
    return this.http.post<Contact>(`${this.contactsUrl}/admin`, contact);
  }

  public removeProfilePicture(id: string): Observable<Contact> {
    const updateOneOrganization: ContactsUpdateOneBody = { profilePictureId: null };
    return this.http.patch<Contact>(`${this.contactsUrl}/${id}`, updateOneOrganization);
  }

  public updateProfilePicture(contactId: string, image: File): Observable<Contact> {
    const formData = new FormData();
    formData.append('image', image, encodeURIComponent(image.name));
    return this.http.patch<Contact>(`${this.contactsUrl}/${contactId}/profilepicture`, formData);
  }

  public updateOne(id: string, body: ContactsUpdateOneBody): Observable<Contact> {
    return this.http.patch<Contact>(`${this.contactsUrl}/${id}`, body);
  }
}
