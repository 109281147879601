import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
} from '@remberg/global/ui';
import { WorkOrderCustomProperty } from '@remberg/work-orders-legacy/common/main';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderCustomPropertyService {
  public readonly workOrderCustomPropertiesUrl = `${API_URL_PLACEHOLDER}/workorders/customproperties`;

  constructor(
    private http: HttpClient,
    private logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
  ) {}

  public getWorkOrderCustomProperties(
    tenantId?: string,
    populate?: boolean,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
  ): Observable<ApiResponse<WorkOrderCustomProperty[]>> {
    if (this.connectivityService.getConnected()) {
      let params = new HttpParams();
      if (populate !== undefined && populate) {
        params = params.set('populate', populate.toString());
      }
      if (pageSize) {
        params = params.set('limit', String(pageSize));
      }
      if (pageIndex) {
        params = params.set('page', String(pageIndex));
      }
      if (sortDirection) {
        params = params.set('order', String(sortDirection));
      }
      if (sortField) {
        params = params.set('sort', String(sortField));
      }
      if (searchValue) {
        params = params.set('search', encodeURIComponent(String(searchValue)));
      }
      if (filterValue) {
        params = params.set('filter', JSON.stringify(filterValue));
      }
      if (tenantId) {
        params = params.set('tenantId', String(tenantId));
      }
      return this.http.get<any>(this.workOrderCustomPropertiesUrl, {
        params: params,
      });
    } else {
      this.logger.debug()('Offline work order custom properties are not supported...');
      return of(new ApiResponse([], 0));
    }
  }

  updateWorkOrderCustomPropertiesSortOrder(
    tenantId: string,
    sortOrder: string[],
  ): Observable<string[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.workOrderCustomPropertiesUrl}/sort/${tenantId}`;
    return this.http
      .post<any>(url, { sortOrder: sortOrder }, httpOptions)
      .pipe(map((res) => res.data));
  }

  getWorkOrderCustomProperty(
    workOrderCustomProperty: string,
    populate?: boolean,
  ): Observable<WorkOrderCustomProperty> {
    const url = `${this.workOrderCustomPropertiesUrl}/${workOrderCustomProperty}`;
    let params = new HttpParams();
    if (populate !== undefined) {
      params = params.set('populate', populate.toString());
    }
    return this.http.get<any>(url, { params: params }).pipe(map((res) => res.data));
  }

  addWorkOrderCustomProperty(
    workOrderCustomProperty: WorkOrderCustomProperty,
  ): Observable<WorkOrderCustomProperty> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post<any>(this.workOrderCustomPropertiesUrl, workOrderCustomProperty, httpOptions)
      .pipe(map((res) => res.data));
  }

  updateWorkOrderCustomProperty(workOrderCustomProperty: WorkOrderCustomProperty): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.workOrderCustomPropertiesUrl}/${workOrderCustomProperty._id}`;
    return this.http
      .put<any>(url, workOrderCustomProperty, httpOptions)
      .pipe(map((res) => res.data));
  }

  deleteWorkOrderCustomProperty(
    workOrderCustomProperty: WorkOrderCustomProperty | string,
  ): Observable<WorkOrderCustomProperty> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const id =
      typeof workOrderCustomProperty === 'string'
        ? workOrderCustomProperty
        : workOrderCustomProperty._id;
    const url = `${this.workOrderCustomPropertiesUrl}/${id}`;

    return this.http.delete<any>(url, httpOptions);
  }
}
