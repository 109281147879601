import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { QRCodeLegacy } from '@remberg/assets/common/main';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  getDownloadFileName,
  programmaticallyDownloadFile,
} from '@remberg/global/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class QRCodesLegacyService {
  private readonly qrCodesUrl = `${API_URL_PLACEHOLDER}/qrcodes`;

  constructor(private readonly http: HttpClient) {}

  public getQRCodesWithCount(
    populate?: boolean,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
  ): Observable<ApiResponse<QRCodeLegacy[]>> {
    return this.getQRCodesBase(
      populate,
      pageSize,
      pageIndex,
      sortDirection,
      sortField,
      searchValue,
      filterValue,
    ).pipe(map((res) => new ApiResponse<QRCodeLegacy[]>(res.data, res.count)));
  }

  public getQRCode(qrcode: string, populate?: boolean): Observable<QRCodeLegacy> {
    const url = `${this.qrCodesUrl}/${qrcode}`;
    let params = new HttpParams();
    if (populate !== undefined) {
      params = params.set('populate', populate.toString());
    }
    return this.http.get<any>(url, { params: params }).pipe(map((res) => res.data));
  }

  public downloadMultipleQRCodePDFs(
    populate?: boolean,
    searchValue: string = '',
    filterValue: any = null,
  ): void {
    const url = `${this.qrCodesUrl}/download-pdfs`;
    let params = new HttpParams();
    if (populate !== undefined && populate) {
      params = params.set('populate', populate.toString());
    }
    if (searchValue) {
      params = params.set('search', String(searchValue));
    }
    if (filterValue) {
      params = params.set('filter', JSON.stringify(filterValue));
    }
    this.http.get(url, { responseType: 'blob', params: params }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const targetFileName = 'qrcode_collection.zip';
      programmaticallyDownloadFile({ blob, targetFileName });
    });
  }

  public downloadQRCodePDF(qrcode: string, downloadFileName: string): void {
    const url = `${this.qrCodesUrl}/${qrcode}/download-pdf`;
    this.http.get(url, { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const targetFileName = `qrcode_${getDownloadFileName(downloadFileName)}.pdf`;
      programmaticallyDownloadFile({ blob, targetFileName });
    });
  }

  public getQRCodeByLink(qrcode: string): Observable<QRCodeLegacy> {
    const url = `${this.qrCodesUrl}/link/${qrcode}`;
    return this.http.get<any>(url).pipe(map((res) => res.data));
  }

  public addQRCodes(numberOfQRCodes: number, tenantId?: string): Observable<QRCodeLegacy[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams().set('amount', numberOfQRCodes.toString()),
    };
    const body: Partial<QRCodeLegacy> = {};
    if (tenantId) {
      body.tenantId = tenantId;
    }
    return this.http.post<any>(this.qrCodesUrl, body, httpOptions).pipe(map((res) => res.data));
  }

  public scanQRCode(linkId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.qrCodesUrl}/link/${linkId}/scan`;
    return this.http.post<any>(url, {}, httpOptions).pipe(map((res) => res.data));
  }

  public updateQRCode(qrcode: QRCodeLegacy): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.qrCodesUrl}/${qrcode._id}`;
    return this.http.put<any>(url, qrcode, httpOptions).pipe(map((res) => res.data));
  }

  public deleteQRCode(qrcodeId: string): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.qrCodesUrl}/${qrcodeId}`;

    return this.http.delete<void>(url, httpOptions);
  }

  private getQRCodesBase(
    populate?: boolean,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
  ): Observable<any> {
    let params = new HttpParams();
    if (populate !== undefined && populate) {
      params = params.set('populate', populate.toString());
    }
    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', String(searchValue));
    }
    if (filterValue) {
      params = params.set('filter', JSON.stringify(filterValue));
    }
    return this.http.get<any>(this.qrCodesUrl, { params: params });
  }
}
