import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { AppStateService } from '../services/app-state.service';
import { GlobalSelectors, NavigationalActions, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class LoginNavigationGuardService {
  constructor(
    private store: Store<RootGlobalState>,
    private appState: AppStateService,
    private logger: LogService,
    private router: Router,
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    this.logger.debug()('LoginNavigationGuardService - canActivate');
    await this.appState.getReadyState();

    const isLoggedIn = await firstValueFrom(this.store.select(GlobalSelectors.selectIsLoggedIn));

    if (!isLoggedIn) {
      const isSubdomainRoutingActive = await firstValueFrom(
        this.store.select(GlobalSelectors.selectIsSubdomainRoutingActive),
      );
      if (!isSubdomainRoutingActive) {
        // We will login as admin
        return true;
      }

      const tenantSubdomain = await firstValueFrom(
        this.store.select(GlobalSelectors.selectTenantSubdomain),
      );
      if (!tenantSubdomain) {
        this.store.dispatch(
          NavigationalActions.goToApplicationRoot({ targetUrl: route.queryParams.target }),
        );
        return false;
      }
      return true;
    } else {
      const target = route.queryParams.target;
      if (target && target !== undefined && target !== '/' && target !== '/login') {
        this.logger.debug()('Moving to targeted page: ' + target);
        this.router.navigateByUrl(target);
        return false;
      } else {
        this.store.dispatch(NavigationalActions.goToAuthenticatedDefaultPage());
        return false;
      }
    }
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
