<div class="translation-flags">
  <img
    *ngFor="let countryCode of countryCodes"
    class="flag"
    [src]="MAP_ISO_LANGUAGE_TO_FLAG_SVG[countryCode]"
    [class.faded]="!translations?.[countryCode]"
    [matTooltip]="translations?.[countryCode] ?? ''"
    [matTooltipDisabled]="!translations?.[countryCode]"
  />
</div>
