import { Pipe, PipeTransform } from '@angular/core';
import { ServerConfigurationService } from '@remberg/ui-core/core';

@Pipe({
  name: 'imageSrcUrls',
})
export class ImageSrcUrlsPipe implements PipeTransform {
  constructor(private serverConfigurationService: ServerConfigurationService) {}

  public transform(
    html: string,
    {
      urlPattern = 'api/files/download/',
      lazyLoad = true,
    }: { urlPattern?: string; lazyLoad?: boolean } = {},
  ): string {
    const filesDownloadUrl = this.serverConfigurationService.filesDownloadUrl.getValue();
    const lazyLoadStr = lazyLoad ? 'loading="lazy" ' : '';
    return html?.replaceAll(
      new RegExp(`src\\s*=\\s*"${urlPattern}`, 'gm'),
      `${lazyLoadStr}src="${filesDownloadUrl}`,
    );
  }
}
