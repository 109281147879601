import { isObjectId } from '@remberg/global/common/core';
import { WorkOrderCustomPropertyTypesEnum } from '../enums';

export interface WorkOrderCustomProperty {
  _id: string;
  organizationId: string;
  tenantId: string;
  label: string;
  public?: boolean;
  enabled?: boolean;
  propertyId: number;
  fieldType: WorkOrderCustomPropertyTypesEnum;
  inputNumberDisplayPrecision?: number;
  options?: {
    options?: any[];
    multiple?: boolean;
  };
  sortIndex: number;
  __v: number;
}

export interface WorkOrderCustomPropertyValue {
  _id?: string;
  property: WorkOrderCustomProperty;
  propertyId: number;
  value: any;
  public?: boolean;
}

export interface WorkOrderCustomPropertyPayloadValue {
  _id?: string;
  property: string;
  propertyId: number;
  value: any;
  public?: boolean;
}

export type WorkOrderCustomPropertyKey = `${'cProp-'}${WorkOrderCustomPropertyTypesEnum}-${string}`;

export function isWorkOrderCustomPropertyKey(
  propertyKey: string,
): propertyKey is WorkOrderCustomPropertyKey {
  const cProp = propertyKey.split('-');
  return (
    cProp.length === 3 &&
    cProp[0] === 'cProp' &&
    isWorkOrderCustomPropertyTypeEnum(cProp[1]) &&
    isObjectId(cProp[2])
  );
}

export function isWorkOrderCustomPropertyTypeEnum(
  x?: string,
): x is WorkOrderCustomPropertyTypesEnum {
  return Object.values(WorkOrderCustomPropertyTypesEnum).includes(
    x as WorkOrderCustomPropertyTypesEnum,
  );
}
