import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { API_URL_PLACEHOLDER, ApiResponse } from '@remberg/global/ui';
import { SchedulingEvent } from '@remberg/work-orders-legacy/common/main';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SchedulingEventService {
  public readonly schedulingEventsUrl = `${API_URL_PLACEHOLDER}/workorders/events`;

  constructor(private http: HttpClient) {}

  // ========================  List API  ===================================
  getSchedulingEvents(
    populate?: boolean | SchedulingEvent,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
    populateAll?: boolean,
    populateContactsWithUserGroups: boolean = false,
  ): Observable<any> {
    let params = new HttpParams();

    if (populate !== undefined && populate && typeof populate === 'boolean') {
      // only populate organization, toPerformByContact and responsibleContact for the WO list
      if (populateAll) {
        params = params.set('populate', populate.toString());
      } else {
        params = params.set('populateToPerformByContact', 'true');
        params = params.set('populateToPerformByGroup', 'true');
        params = params.set('populateAttendantContacts', 'true');
      }
    }
    if (populateContactsWithUserGroups) {
      params = params.set('populateContactsWithUserGroups', 'true');
    }
    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', encodeURIComponent(String(searchValue)));
    }
    if (filterValue) {
      params = params.set('filter', JSON.stringify(filterValue));
    }
    return this.http.get<any>(this.schedulingEventsUrl, { params: params });
  }

  getSchedulingEventsWithCount(
    populate?: boolean | SchedulingEvent,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
    populateContactsWithUserGroups: boolean = false,
  ): Observable<ApiResponse<SchedulingEvent[]>> {
    return this.getSchedulingEvents(
      populate,
      pageSize,
      pageIndex,
      sortDirection,
      sortField,
      searchValue,
      filterValue,
      undefined,
      populateContactsWithUserGroups,
    ).pipe(map((res) => new ApiResponse<SchedulingEvent[]>(res.data, res.count)));
  }

  // ========================  Detail API  ===================================
  getSchedulingEvent(schedulingEvent: string, populate?: boolean): Observable<SchedulingEvent> {
    const url = `${this.schedulingEventsUrl}/${schedulingEvent}`;
    let params = new HttpParams();
    if (populate !== undefined) {
      params = params.set('populate', populate.toString());
    }
    return this.http.get<any>(url, { params: params }).pipe(map((res) => res.data));
  }

  addSchedulingEvent(
    schedulingEvent: SchedulingEvent,
    populate?: boolean,
  ): Observable<SchedulingEvent> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    return this.http
      .post<any>(this.schedulingEventsUrl, schedulingEvent, httpOptions)
      .pipe(map((res) => res.data));
  }

  patchSchedulingEvent(payload: SchedulingEvent, populate?: boolean): Observable<SchedulingEvent> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    const url = `${this.schedulingEventsUrl}/${payload._id}`;
    return this.http.patch<any>(url, payload, httpOptions).pipe(map((res) => res.data));
  }

  deleteEvent(evt: SchedulingEvent | string): Observable<SchedulingEvent> {
    const evt_id = typeof evt === 'string' ? evt : evt._id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.schedulingEventsUrl}/${evt_id}`;

    return this.http.delete<any>(url, httpOptions);
  }
}
