/** Allowed field to perform filtering from API */
export enum WorkOrderAPIFilterFieldEnum {
  ASSET = 'asset',
  RESPONSIBLE_CONTACT = 'responsibleContact',
  ASSIGNED_ORGANIZATION = 'assignedOrganization',
  TO_PERFORM_BY_CONTACT = 'toPerformByContact',
  PRIORITY = 'priority',
  STATUS = 'status',
  STATUS_COMPLETED = 'statusCompleted',
  TYPE = 'type',
  RELATED_SERVICE_CASE = 'relatedServiceCase',
  TO_PERFORM_BY_GROUP = 'toPerformByGroup',
  RESPONSIBLE_BY_GROUP = 'responsibleGroup',
  SCHEDULED = 'scheduled',
  INVOLVED_CONTACT = 'involvedContact',
  MY_GROUPS = 'myGroups',
  ATTENDANT = 'attendant',
  MAINTENANCEPLAN = 'maintenancePlan',
  TOUCHED = 'touched',
  ERP_REFERENCE = 'erpReference',
  CREATED = 'created',
  UPDATED = 'updated',
  DUE_DATE = 'dueDate',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  CITY = 'city',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  ZIP_CODE = 'zipPostCode',
  BOUNDING_BOX = 'boundingBox',
}
