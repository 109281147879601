import { ContactCompatibility } from '@remberg/crm/common/main';
import { OrganizationTypeEnum } from '@remberg/global/common/core';
import { getStringID } from '@remberg/global/ui';
import { getRembergUserStatus, RembergUser } from '@remberg/users/common/main';

type User = any;

export function userToContactAndRembergUser(user: User): {
  contact: ContactCompatibility;
  rembergUser?: RembergUser;
} {
  return {
    contact: userToContact(user),
    rembergUser: userToRembergUser(user),
  };
}

function userToContact(user: User): ContactCompatibility {
  return {
    _id: user._id,
    tenantId: user.tenantId as any,
    organizationId: getStringID(user.account),
    rembergUserId: user.rembergUserId,
    rembergUserStatus: getRembergUserStatus(userToRembergUser(user)),
    crmData: {
      emails: [{ id: 0, label: 'Default', email: user.email }],
      profilePictureId: getStringID(user.picture),
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      position: user.position,
      phoneNumbers: user.phoneNumber
        ? [{ id: 0, label: 'Default', phoneNumber: user.phoneNumber as any }]
        : [],
    },
  };
}

function userToRembergUser(user: User): RembergUser | undefined {
  if (!user.rembergUserId) {
    return undefined;
  }
  return {
    _id: user.rembergUserId,
    contactId: user._id,
    tenantId: user.tenantId as string,
    organizationId: user.account as string,
    isRembergAdmin: user.role === OrganizationTypeEnum.REMBERG,
    isTenantOwner: !!user.isTenantOwner,
    activationInfo: {
      isInvited: user.credentialsSent,
      emailVerifiedAt: user.emailVerificationDate,
      emailVerificationToken: user.emailVerificationToken,
      emailVerificationTokenExpiresAt: user.emailVerificationTokenExpires,
      activatedAt: user.activationDate,
      activationToken: user.activationToken,
      activationTokenExpiresAt: user.activationTokenExpires,
    },
    twoFactorInfo: {
      phoneNumber: user.phone,
      isPhoneNumberVerified: user.phoneVerified,
      isTwoFactorActive: user.twoFactorActivated,
    },
    email: user.email,
    userRoleId: getStringID(user.userRole),
    userGroupIds: user.userGroups?.map(getStringID) ?? [],
    lang: user.lang as any,
    tz: user.tz as any,
    settings: {
      ...(user.settings as any),
      signature: user.signature,
    },
  };
}
