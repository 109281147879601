import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { Store } from '@ngrx/store';
import { AssetsPermissionEnum } from '@remberg/assets/common/main';
import { FileSourceTypeEnum } from '@remberg/files/common/main';
import { FeatureFlagEnum, LanguageCodeEnum } from '@remberg/global/common/core';
import {
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  IMAGE_FALLBACK_URL,
  LogService,
  MainRoutes,
  NavLink,
  NavlinkTypesEnum,
} from '@remberg/global/ui';
import {
  GlobalActions,
  GlobalSelectors,
  LayoutService,
  NavigationService,
  NavigationalActions,
  OfflinePrefetchService,
  OfflinePushService,
  RootGlobalState,
  RouterSelectors,
  SyncUiService,
  checkFeatureFlag,
  checkUserPermission,
} from '@remberg/ui-core/core';
import { Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { map, pairwise, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss'],
  providers: [NavigationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterfaceComponent implements OnInit, AfterContentChecked, OnDestroy {
  protected IMAGE_FALLBACK_URL = IMAGE_FALLBACK_URL;

  protected FileSourceTypeEnum = FileSourceTypeEnum;

  @ViewChild('sidenav') protected sidenav?: MatSidenav;
  @ViewChild('sidenavContent') protected sidenavContent?: MatSidenavContent;

  protected NavlinkTypesEnum = NavlinkTypesEnum;

  protected translations = {
    syncInProgress: $localize`:@@synchronizationInProgress:Synchronization in progress`,
    changesAndOffline: $localize`:@@offlineWithPendingChanges:Offline with pending changes`,
    syncAndOffline: $localize`:@@offlineWithoutChanges:Offline without changes`,
    syncAndOnline: $localize`:@@onlineWithoutChanges:Online`,
    noConnection: $localize`:@@noInternetConnection:No internet connection`,
    profile: $localize`:@@profile:Profile`,
  };

  private readonly subscriptions = new Subscription();

  private readonly ignorePageScrollRoutes: string[] = [MainRoutes.FILE_DRIVE];

  protected readonly routeHasOwnMainContentPadding$ = this.store.select(
    RouterSelectors.selectRouteHierarchyHasOwnMainContentPadding,
  );
  protected readonly isUpdateInProgress$ = this.store.select(
    GlobalSelectors.selectIsApplicationAndStorageUpdateInProgress,
  );
  protected readonly isGlobalLoadingSpinning$ = this.store.select(
    GlobalSelectors.selectGlobalLoadingIndicatorIsSpinning,
  );
  protected readonly isAskAiButtonShown$ = combineLatest([
    this.store.select(GlobalSelectors.selectTenantFeatures),
    this.store.select(GlobalSelectors.selectUserPermissions),
  ]).pipe(
    map(
      ([features, permissions]) =>
        features &&
        permissions &&
        checkFeatureFlag(FeatureFlagEnum.AI_GLOBAL_QA, features) &&
        checkFeatureFlag(FeatureFlagEnum.AI_ASSETS_CHAT_WITH_X, features) &&
        checkUserPermission(
          AssetsPermissionEnum.ASSETS_ENABLE_AI_CHAT_WITH_X,
          permissions,
          features,
        ),
    ),
  );

  protected theme$ = this.store.select(GlobalSelectors.selectTheme);
  protected contact$ = this.store.select(GlobalSelectors.selectCurrentContact);
  protected isLoggedIn$ = this.store.select(GlobalSelectors.selectIsLoggedIn);
  protected isRembergAdmin$ = this.store.select(GlobalSelectors.selectIsRembergAdmin);
  protected breadcrumbs$ = this.store.select(GlobalSelectors.selectBreadcrumbs);
  protected isIntercomInitialized$ = this.store.select(GlobalSelectors.selectIsIntercomInitialized);
  protected showBrowserWarningBanner$ = this.appComponent.bannerSource.asObservable();
  protected navLinks$ = this.store.select(GlobalSelectors.selectNavLinks);
  protected isSideNavOpen$ = this.store.select(GlobalSelectors.selectIsPrimaryNavigationOpen);

  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    public readonly connectivityService: ConnectivityServiceInterface,
    private readonly router: Router,
    private readonly appComponent: AppComponent,
    private readonly logger: LogService,
    public readonly layout: LayoutService,
    public readonly syncUiService: SyncUiService,
    public readonly offlinePrefetchService: OfflinePrefetchService,
    public readonly offlinePushService: OfflinePushService,
    private readonly store: Store<RootGlobalState>,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.logger.debug()('Initializing interface...');
    this.subscriptions.add(
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }

        if (this.sidenavContent && !this.isIgnoredForScrolling(evt.url)) {
          (this.sidenavContent as any).elementRef.nativeElement.scroll(0, 0);
        }
      }),
    );

    // Close the SideNav whenever the screen is resized from (x)small to larger
    this.subscriptions.add(
      this.layout.isXSmallOrSmallView.pipe(pairwise()).subscribe(([wasXSmallOrSmall]) => {
        if (!wasXSmallOrSmall) {
          this.store.dispatch(GlobalActions.sideNavClosed());
        }
      }),
    );

    // On small screens, the material side nav needs to be toggled separately (on large screens its always open)
    this.subscriptions.add(
      this.isSideNavOpen$
        .pipe(withLatestFrom(this.store.select(GlobalSelectors.selectLayout)))
        .subscribe(([isOpen, layout]) => {
          if (layout?.isXSmallOrSmallView) {
            if (isOpen) {
              this.sidenav?.open();
            } else {
              this.sidenav?.close();
            }
          }
        }),
    );

    this.store.dispatch(GlobalActions.startInterfaceInitialization());
  }

  public ngAfterContentChecked(): void {
    // Called after every check of the component's or directive's content.
    // Add 'implements AfterContentChecked' to the class.
    // This is necessary as a lot of our old code components don't use onPush change detection yet
    this.cdRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  protected toggleSideNav(): void {
    this.store.dispatch(GlobalActions.sideNavToggled());
  }

  protected async reloadRouteIfActive(event: Event, navLink: NavLink): Promise<void> {
    if (this.isRouteActive(navLink.link)) {
      event.preventDefault();
      this.store.dispatch(NavigationalActions.goToUrlEnsureRerender({ url: navLink.link }));
    }
    await this.closeSideNavIfSmallView();
  }

  protected async closeSideNavIfSmallView(): Promise<void> {
    const isSmallView = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsXSmallOrSmallView),
    );
    if (isSmallView) {
      this.store.dispatch(GlobalActions.sideNavClosed());
    }
  }

  protected logout(): void {
    this.store.dispatch(GlobalActions.logoutInitiatedFromNavBar());
  }

  protected async redirectToDefaultPage(): Promise<void> {
    this.store.dispatch(NavigationalActions.goToAuthenticatedDefaultPage());
  }

  private isIgnoredForScrolling(url: string): boolean {
    return this.ignorePageScrollRoutes.includes(url.split('?')[0]?.slice(1));
  }

  protected showIntercom(): void {
    this.store.dispatch(GlobalActions.showIntercom());
  }

  protected isRouteActive(url: string): boolean {
    if (url === this.router.url) {
      return true;
    }
    if (!this.router.url.startsWith(url)) {
      return false;
    }
    const urlRest = this.router.url.slice(url.length);
    return urlRest === '' || ['/', '#', '?'].includes(urlRest[0]);
  }

  protected async openHelpCenter(): Promise<void> {
    const userLang = await firstValueFrom(this.store.select(GlobalSelectors.selectUserLang));
    switch (userLang) {
      case LanguageCodeEnum.DE:
        window.open('https://help.remberg.de/de/', '_blank');
        break;
      default:
        window.open('https://help.remberg.de/en/', '_blank');
    }
  }
}
