import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalSelectors, RootGlobalState } from '@remberg/ui-core/core';

@Component({
  templateUrl: './update-required.component.html',
  styleUrls: ['./update-required.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateRequiredComponent {
  protected readonly deviceType$ = this.store.select(GlobalSelectors.selectDeviceType);

  constructor(private readonly store: Store<RootGlobalState>) {}
}
