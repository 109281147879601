import { Pipe, PipeTransform } from '@angular/core';
import { RembergDateTime, RembergTimestamp } from '@remberg/global/common/core';
import { toRembergTimestamp } from '@remberg/ui-core/core';

@Pipe({
  name: 'toRembergTimestamp',
})
export class ToRembergTimestampPipe implements PipeTransform {
  public transform(value: RembergDateTime): RembergTimestamp;
  public transform(value: Date): RembergTimestamp;
  public transform(value: Date | undefined): RembergTimestamp | undefined;
  public transform(value: Date | null | undefined): RembergTimestamp | undefined;
  public transform(value: RembergDateTime | undefined): RembergTimestamp | undefined;
  public transform(
    value: RembergDateTime | Date | null | undefined | string,
  ): RembergTimestamp | undefined {
    return toRembergTimestamp(value);
  }
}
