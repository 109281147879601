import { HtmlToTextOptions, htmlToText } from 'html-to-text';

export function convertHtmlToPlainText(htmlText: string, options: HtmlToTextOptions = {}): string {
  return htmlToText(htmlText, {
    selectors: [{ selector: 'a', options: { ignoreHref: true } }],
    decodeEntities: true,
    preserveNewlines: true,
    ...options,
  });
}
