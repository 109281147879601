const DEFAULT_OPTIONS: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'center',
};

export function scrollToElement(
  element: HTMLElement | undefined,
  options?: ScrollIntoViewOptions,
): void {
  element?.scrollIntoView({
    ...DEFAULT_OPTIONS,
    ...(Object.keys(options ?? {}).length > 0 && options),
  });
}
