import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

interface ToastrMessage {
  title: string;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(public readonly toastrService: ToastrService) {}

  /**
   * Function used for copy text to clipboard
   * @param text string to copy
   * @param toastrMessage toastr message to show after copy
   */
  public copyToClipboard(text: string, toastrMessage?: ToastrMessage): void {
    const listener = (e: ClipboardEvent): void => {
      const clipboard = e.clipboardData || (window as never)['clipboardData'];
      clipboard.setData('text', text);
      e.preventDefault();
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
    if (toastrMessage) {
      this.toastrService.info(toastrMessage.message, toastrMessage.title);
    } else {
      this.toastrService.info('', $localize`:@@copiedText:Copied Text`);
    }
  }
}
