import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppointmentsPermissionsEnum } from '@remberg/appointments/common/main';
import { LogService } from '@remberg/global/ui';
import { Observable } from 'rxjs';
import { UserRightsService } from '../../services/user-rights.service';

export const appointmentsGuardFn: CanActivateFn = (): boolean | Observable<boolean> => {
  const userRightsService = inject(UserRightsService);
  const router = inject(Router);
  const logger = inject(LogService);

  const isAppointmentsFeatureAccessible = userRightsService.getPermission(
    AppointmentsPermissionsEnum.APPOINTMENTS_ENABLED,
  );

  if (isAppointmentsFeatureAccessible) return true;

  logger.info()('Appointments Guard: Redirecting from: ' + router.url);
  router.navigate(['/']);

  return false;
};
