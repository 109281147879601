import { Assert, FeatureFlagEnum, TwoWayCompareStringType } from '@remberg/global/common/core';
import { DashboardsPermissions } from '@remberg/users/common/main';

// This needs to be kept in sync with 'DashboardsRightsEnum' in the shared service!
export enum DashboardsPermissionsEnum {
  DASHBOARDS_ENABLED = 'dashboards_enabled',
}

export type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `${FeatureFlagEnum.DASHBOARDS}_${keyof DashboardsPermissions}`,
    `${DashboardsPermissionsEnum}`
  >
>;
