import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  LogService,
  MustMatch,
  PASSWORD_ERROR_MESSAGES,
  PASSWORD_VALIDATORS_REQUIRED,
} from '@remberg/global/ui';
import { GlobalSelectors, RootGlobalState } from '@remberg/ui-core/core';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { firstValueFrom } from 'rxjs';

interface PasswordExpirationFormGroup {
  currentPassword: FormControl<string>;
  password: FormControl<string>;
  passwordConfirm: FormControl<string>;
}

@Component({
  selector: 'app-password-expiration',
  templateUrl: './password-expiration.component.html',
})
export class PasswordExpirationComponent {
  protected readonly passwordExpiredFormGroup = new FormGroup<PasswordExpirationFormGroup>(
    {
      currentPassword: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      password: new FormControl('', {
        nonNullable: true,
        validators: PASSWORD_VALIDATORS_REQUIRED,
      }),
      passwordConfirm: new FormControl('', {
        nonNullable: true,
        validators: PASSWORD_VALIDATORS_REQUIRED,
      }),
    },
    {
      validators: [MustMatch('password', 'passwordConfirm')],
    },
  );

  protected passwordErrorMessages = PASSWORD_ERROR_MESSAGES;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly rembergUsersService: RembergUsersService,
    private readonly logger: LogService,
    private readonly store: Store<RootGlobalState>,
  ) {}

  protected get passwordExpiredForm(): PasswordExpirationFormGroup {
    return this.passwordExpiredFormGroup.controls;
  }

  protected async updatePassword(event: MouseEvent | KeyboardEvent): Promise<void> {
    if (event.type === 'click' || (event as KeyboardEvent).keyCode === 13) {
      try {
        await firstValueFrom(
          this.rembergUsersService.updateSelf({
            password: {
              currentPassword: this.passwordExpiredForm.currentPassword.value,
              newPassword: this.passwordExpiredForm.password.value,
            },
          }),
        );
        const target = this.route.snapshot.queryParamMap.get('target');
        if (target) {
          await this.router.navigateByUrl(target);
        } else {
          await this.moveToLoggedInPage();
        }
      } catch (error) {
        this.logger.error()(error);
      }
    }
  }

  protected async moveToLoggedInPage(): Promise<void> {
    const isRembergAdmin = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsRembergAdmin),
    );
    await this.router.navigateByUrl(isRembergAdmin ? '/remberg' : '/login');
  }
}
