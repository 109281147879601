import { Part } from '@remberg/parts/common/main';
import { QuotationRequestInterface } from '../definitions';

export const getQuotationRequestAsString = (
  quotationRequest: QuotationRequestInterface,
  options: {
    parts: Part[];
    externalIdTitle: string;
    unknownName: string;
  },
): string[] => {
  const { parts, externalIdTitle, unknownName } = options;
  const externalId = `${externalIdTitle}: ${quotationRequest.externalId || '-'}`;

  if (!quotationRequest.items?.length) return [`-`, externalId];

  const partMap = parts.reduce<Record<string, Part>>((acc, curr) => {
    if (curr) {
      acc[curr._id] = curr;
    }
    return acc;
  }, {});

  const qrParts = quotationRequest.items.map(
    (item) =>
      `${partMap[item.partId]?.name ?? unknownName} (${partMap[item.partId]?.serialNumber ?? item.partId}) x${item.quantity}`,
  );

  return [...qrParts, externalId];
};
