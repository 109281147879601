<div class="loader-container">
  <mat-progress-spinner
    class="example-margin"
    [diameter]="18"
    [color]="color"
    [mode]="value ? 'determinate' : 'indeterminate'"
    [value]="value ?? 0"
  >
  </mat-progress-spinner>
  <span>{{ statusLabel ?? '' }}</span>
</div>
