<mat-toolbar class="mat-elevation-z4 remberg-toolbar">
  <div class="header-toolbar">
    <span *ngIf="logo$ | async; let logo; else: rembergImgTmpl" class="logo-container">
      <a [routerLink]="['/']" class="application-icon">
        <img
          class="toolbar-element brand-logo"
          [attr.src]="logo | getIDString | fileUrl: FileSourceTypeEnum.LOGO | async"
          [src-fallback]="IMAGE_FALLBACK_URL.organization_transparent"
        />
      </a>
    </span>
    <ng-template #rembergImgTmpl>
      <span class="logo-container">
        <a [routerLink]="['/']" class="application-icon">
          <img
            class="toolbar-element brand-logo"
            src="/assets/img/remberg-darkblue.png"
            [src-fallback]="IMAGE_FALLBACK_URL.organization_transparent"
          />
        </a>
      </span>
    </ng-template>
    <span class="fill-remaining-space"></span>
  </div>
</mat-toolbar>
