import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService, MainRoutes } from '@remberg/global/ui';
import { PartPermissionsEnum } from '@remberg/parts/common/main';
import { Observable, map, withLatestFrom } from 'rxjs';
import { UserRightsService } from '../../services/user-rights.service';
import { GlobalSelectors } from '../../store';

export const partsGuardFn: CanActivateFn = (): boolean | Observable<boolean> => {
  const store = inject(Store);
  const userRightsService = inject(UserRightsService);
  const router = inject(Router);
  const logger = inject(LogService);

  return store.select(GlobalSelectors.selectIsTenantOwner).pipe(
    withLatestFrom(store.select(GlobalSelectors.selectTenantFeatures)),
    map(([isTenantOwner, tenantFeatures]) => {
      const isPartsFeatureFlagEnabled = !!tenantFeatures?.parts;
      const isPartsFeatureEnabled =
        isTenantOwner &&
        isPartsFeatureFlagEnabled &&
        userRightsService.getPermission(PartPermissionsEnum.PARTS_ENABLED) &&
        userRightsService.getPermission(PartPermissionsEnum.PARTS_VIEW_IN_OVERVIEW);

      if (isPartsFeatureEnabled) return true;

      logger.info()('Parts Guard: Redirecting from: ' + router.url);
      void router.navigate([MainRoutes.ASSETS]);
      return false;
    }),
  );
};
