/**
 * @description Enum for the activity types that can be tracked.`
 */
export enum ActivityTypeEnum {
  /** `CREATE` activity type allows us to track which objects have been created and with which initial data. */
  CREATE = 'create',
  /**
   * `CHANGE` activity type allows us to track changes on the object that are not relations to other objects.
   * @example Subject, description, priority etc...
   */
  CHANGE = 'change',
  /** `DELETE` activity type allows us to track which objects have been deleted on the global feed.
   * Since after an object is deleted we can't access it anymore, we store the object's data in the activity itself.
   */
  DELETE = 'delete',
  /**
   * `RELATION` activity type allows us to track when an object is assigned or unasigned to another.
   *  @example assetId, organizationId, workOrderStatusId etc...
   */
  RELATION = 'relation',
}
