import { Permissions } from './permissions.interface';

const NORMAL = {
  assets: {
    enabled: true,
    create: false,
    updateLocation: true,
    updateAttributes: false,
    updateOwnContactPerson: false,
    updateExtOrganization: false,
    updateExtContactPerson: true,
    delete: false,
    createType: false,
    updateType: false,
    deleteType: false,
    notesCreateUpdateDeleteOwn: true,
    notesDeleteAll: false,
    enableHierarchy: false,
    enableAiChatWithX: false,
  },
  organizations: {
    createExternal: false,
    viewExternal: true,
    updateExternal: false,
    updateOwn: false,
    deleteExternal: false,
  },
  users: {
    createOwn: false,
    viewOwn: true,
    updateOwn: false,
    deleteOwn: false,
    createExternal: false,
    viewExternal: true,
    updateExternal: false,
    deleteExternal: false,
  },
  tickets: {
    enabled: true,
    create: true,
    update: false,
    delete: false,
    addAnswer: true,
    deleteNotesAll: false,
    deleteNotesOwn: false,
    export: false,
    move: false,
    enableAi: false,
  },
  tasks: {
    enabled: true,
    updateAll: false,
    deleteAll: false,
  },
  forms: {
    enabled: false,
    fill: false,
    update: false,
    delete: false,
    send: false,
    newType: false,
    updateType: false,
    deleteType: false,
    updateAssignee: false,
    viewAll: false,
    fillOverwrite: false,
    deleteOwn: true,
    createNotes: false,
    deleteNotesAll: false,
    deleteNotesOwn: false,
    duplicateAllForms: true,
    duplicateOwnForms: true,
  },
  shop: {
    enabled: true,
    createItem: false,
    updateItem: false,
    deleteItem: false,
    placeOrder: true,
  },
  files: {
    enabled: true,
    assignAll: true,
    assignOwn: true,
    moveAll: true,
    moveOwn: true,
    removeAll: false,
    removeOwn: true,
    renameAll: false,
    renameOwn: true,
    setPublic: true,
    upload: true,
    showOnlyInAssetDetails: false,
    manageAiKnowledgeBase: false,
    enableAiChatWithFile: false,
  },
  guides: {
    enabled: true,
    create: false,
    update: false,
    delete: false,
  },
  qrcodes: {
    enabled: true,
    create: false,
    update: false,
    delete: false,
    order: false,
    assign: false,
  },
  sites: {
    manage: false,
    viewDetails: true,
  },
  iot: {
    enabled: true,
    configure: false,
  },
  settings: {
    enabled: true,
    manageGeneralInformation: false,
    manageAppearance: false,
    manageUsers: true,
    manageUserAccessRequests: false,
    manageUserRoles: false,
    manageUserGroups: false,
    manageLocalization: false,
    manageCases: false,
    manageAppointments: false,
    manageWorkOrders: false,
    manageDataExport: false,
    managePassword: false,
    manageApiKeys: false,
    manageAssets: false,
  },
  sync: {
    enabled: false,
  },
  connectors: {
    enabled: false,
  },
  workOrders: {
    enabled: true,
    create: false,
    update: false,
    delete: false,
    createNotes: false,
    deleteNotesAll: false,
    deleteNotesOwn: false,
    updateScheduling: false,
    viewScheduling: false,
  },
  maintenancePlans: {
    enabled: true,
    create: false,
    update: false,
    delete: false,
  },
  parts: {
    enabled: true,
    create: true,
    update: true,
    delete: true,
    export: true,
    viewInOverview: true,
    viewInAssetDetail: true,
  },
  appointmentsTemporary: {
    enabled: true,
    create: false,
    update: false,
    delete: false,
  },
  workOrdersTemporary: {
    enabled: true,
    create: false,
    updateDetails: false,
    updateStatusAndChecklist: false,
    updatePlanning: false,
    delete: false,
    notesCreateUpdateDeleteOwn: true,
    notesDeleteAll: false,
  },
  dashboards: {
    enabled: false,
  },
} as Permissions;

const ADMIN = {
  assets: {
    enabled: true,
    create: true,
    updateLocation: true,
    updateAttributes: true,
    updateOwnContactPerson: true,
    updateExtOrganization: true,
    updateExtContactPerson: true,
    delete: true,
    createType: true,
    updateType: true,
    deleteType: true,
    notesCreateUpdateDeleteOwn: true,
    notesDeleteAll: true,
    enableHierarchy: true,
    enableAiChatWithX: false,
  },
  organizations: {
    createExternal: true,
    viewExternal: true,
    updateExternal: true,
    updateOwn: true,
    deleteExternal: true,
  },
  users: {
    createOwn: true,
    viewOwn: true,
    updateOwn: true,
    deleteOwn: true,
    createExternal: true,
    viewExternal: true,
    updateExternal: true,
    deleteExternal: true,
  },
  tickets: {
    enabled: true,
    create: true,
    update: true,
    delete: true,
    export: true,
    move: true,
    addAnswer: true,
    deleteNotesOwn: true,
    deleteNotesAll: true,
    enableAi: false,
  },
  tasks: {
    enabled: true,
    updateAll: true,
    deleteAll: true,
  },
  forms: {
    enabled: true,
    fill: true,
    update: true,
    delete: true,
    deleteOwn: true,
    send: true,
    newType: true,
    updateType: true,
    deleteType: true,
    fillOverwrite: false,
    viewAll: true,
    updateAssignee: true,
    createNotes: true,
    deleteNotesOwn: true,
    deleteNotesAll: true,
    duplicateAllForms: true,
    duplicateOwnForms: true,
  },
  files: {
    enabled: true,
    upload: true,
    removeAll: true,
    removeOwn: true,
    renameAll: true,
    renameOwn: true,
    assignAll: true,
    assignOwn: true,
    moveAll: true,
    moveOwn: true,
    setPublic: true,
    showOnlyInAssetDetails: false,
    manageAiKnowledgeBase: false,
    enableAiChatWithFile: false,
  },
  qrcodes: {
    enabled: true,
    create: false,
    update: false,
    delete: false,
    order: true,
    assign: true,
  },
  iot: {
    enabled: true,
    configure: true,
  },
  settings: {
    enabled: true,
    manageGeneralInformation: true,
    manageAppearance: true,
    manageUsers: true,
    manageUserAccessRequests: true,
    manageUserRoles: true,
    manageUserGroups: true,
    manageLocalization: true,
    manageCases: true,
    manageAppointments: true,
    manageWorkOrders: true,
    manageDataExport: true,
    managePassword: true,
    manageApiKeys: true,
    manageAssets: true,
  },
  workOrders: {
    enabled: true,
    create: true,
    update: true,
    delete: true,
    updateScheduling: true,
    viewScheduling: true,
    createNotes: true,
    deleteNotesAll: true,
    deleteNotesOwn: true,
  },
  maintenancePlans: {
    enabled: true,
    create: true,
    update: true,
    delete: true,
  },
  parts: {
    enabled: true,
    create: true,
    update: true,
    delete: true,
    export: true,
    viewInOverview: true,
    viewInAssetDetail: true,
  },
  appointmentsTemporary: {
    enabled: true,
    create: true,
    update: true,
    delete: true,
  },
  workOrdersTemporary: {
    enabled: true,
    create: true,
    updateStatusAndChecklist: true,
    updateDetails: true,
    updatePlanning: true,
    delete: true,
    notesCreateUpdateDeleteOwn: true,
    notesDeleteAll: true,
  },
  dashboards: {
    enabled: true,
  },
} as Permissions;

const EXTERNAL = {
  assets: {
    enabled: true,
    create: false,
    updateLocation: true,
    updateAttributes: false,
    updateOwnContactPerson: false,
    updateExtOrganization: false,
    updateExtContactPerson: true,
    delete: false,
    createType: false,
    updateType: false,
    deleteType: false,
    notesCreateUpdateDeleteOwn: true,
    notesDeleteAll: false,
    enableHierarchy: false,
    enableAiChatWithX: false,
  },
  organizations: {
    createExternal: false,
    viewExternal: true,
    updateExternal: false,
    updateOwn: false,
    deleteExternal: false,
  },
  users: {
    createOwn: false,
    viewOwn: true,
    updateOwn: false,
    deleteOwn: false,
    createExternal: false,
    viewExternal: true,
    updateExternal: false,
    deleteExternal: false,
  },
  tickets: {
    enabled: true,
    create: true,
    update: true,
    delete: false,
    export: false,
    move: false,
    addAnswer: true,
    deleteNotesOwn: true,
    deleteNotesAll: false,
    enableAi: false,
  },
  tasks: {
    enabled: true,
    updateAll: false,
    deleteAll: false,
  },
  forms: {
    enabled: false,
    fill: false,
    update: false,
    delete: false,
    deleteOwn: false,
    send: false,
    newType: false,
    updateType: false,
    deleteType: false,
    fillOverwrite: false,
    viewAll: false,
    updateAssignee: false,
    createNotes: false,
    deleteNotesOwn: false,
    deleteNotesAll: false,
    duplicateAllForms: false,
    duplicateOwnForms: false,
  },
  files: {
    enabled: true,
    upload: true,
    removeAll: true,
    removeOwn: true,
    renameAll: true,
    renameOwn: true,
    assignAll: true,
    assignOwn: true,
    moveAll: true,
    moveOwn: true,
    setPublic: true,
    showOnlyInAssetDetails: false,
    manageAiKnowledgeBase: false,
    enableAiChatWithFile: false,
  },
  qrcodes: {
    enabled: true,
    create: false,
    update: false,
    delete: false,
    order: false,
    assign: false,
  },
  iot: {
    enabled: true,
    configure: false,
  },
  settings: {
    enabled: false,
    manageGeneralInformation: false,
    manageAppearance: false,
    manageUsers: true,
    manageUserAccessRequests: false,
    manageUserRoles: false,
    manageUserGroups: false,
    manageLocalization: false,
    manageCases: false,
    manageAppointments: false,
    manageWorkOrders: false,
    manageDataExport: false,
    managePassword: false,
    manageApiKeys: false,
    manageAssets: false,
  },
  workOrders: {
    enabled: true,
    create: false,
    update: false,
    delete: false,
    updateScheduling: false,
    viewScheduling: false,
    createNotes: true,
    deleteNotesOwn: true,
    deleteNotesAll: false,
  },
  maintenancePlans: {
    enabled: false,
    create: false,
    update: false,
    delete: false,
  },
  parts: {
    enabled: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    viewInOverview: false,
    viewInAssetDetail: true,
  },
  appointmentsTemporary: {
    enabled: false,
    create: false,
    update: false,
    delete: false,
  },
  workOrdersTemporary: {
    enabled: false,
    create: false,
    updateStatusAndChecklist: false,
    updateDetails: false,
    updatePlanning: false,
    delete: false,
    notesCreateUpdateDeleteOwn: false,
    notesDeleteAll: false,
  },
  dashboards: {
    enabled: false,
  },
} as Permissions;

// ATTENTION: the order is important, as it's used for the order in the permissions matrix and was defined with CS
const VIEW_PERMISSIONS_AS_OEM = {
  assets: {
    enabled: true,
    enableHierarchy: false,
    createType: false,
    create: false,
    notesCreateUpdateDeleteOwn: false,
    updateAttributes: false,
    updateOwnContactPerson: false,
    updateExtOrganization: false,
    updateExtContactPerson: false,
    updateType: false,
    updateLocation: false,
    deleteType: false,
    delete: false,
    notesDeleteAll: false,
    enableAiChatWithX: false,
  },
  organizations: {
    viewExternal: false,
    createExternal: false,
    updateOwn: false,
    // updateExternal: false, // hidden from oem
    // deleteExternal: false, // hidden from oem
  },
  users: {
    viewOwn: false,
    viewExternal: false,
    createOwn: false,
    createExternal: false,
    updateOwn: false,
    updateExternal: false,
    deleteOwn: false,
    deleteExternal: false,
  },
  tickets: {
    enabled: false,
    create: false,
    update: false,
    addAnswer: false,
    move: false,
    delete: false,
    deleteNotesOwn: false,
    deleteNotesAll: false,
    export: false,
    enableAi: false,
  },
  workOrders: {
    enabled: false,
    viewScheduling: false,
    create: false,
    createNotes: false,
    update: false,
    updateScheduling: false,
    delete: false,
    deleteNotesOwn: false,
    deleteNotesAll: false,
  },
  maintenancePlans: {
    enabled: false,
    create: false,
    update: false,
    delete: false,
  },
  workOrdersTemporary: {
    enabled: false,
    create: false,
    updateDetails: false,
    updatePlanning: false,
    updateStatusAndChecklist: false,
    delete: false,
    notesCreateUpdateDeleteOwn: false,
    notesDeleteAll: false,
  },
  appointmentsTemporary: {
    enabled: false,
    create: false,
    update: false,
    delete: false,
  },
  tasks: {
    enabled: false,
    updateAll: false,
    deleteAll: false,
  },
  parts: {
    enabled: false,
    viewInOverview: false,
    viewInAssetDetail: false,
    create: false,
    update: false,
    delete: false,
    export: false,
  },
  forms: {
    enabled: false,
    viewAll: false,
    // newType: false, // hidden from oem
    fill: false,
    fillOverwrite: false,
    createNotes: false,
    duplicateOwnForms: false,
    duplicateAllForms: false,
    // updateType: false, // hidden from oem
    update: false,
    updateAssignee: false,
    send: false,
    // deleteType: false, // hidden from oem
    deleteOwn: false,
    delete: false,
    deleteNotesOwn: false,
    deleteNotesAll: false,
  },
  files: {
    enabled: false,
    showOnlyInAssetDetails: false,
    upload: false,
    assignOwn: false,
    assignAll: false,
    moveOwn: false,
    moveAll: false,
    renameOwn: false,
    renameAll: false,
    setPublic: false,
    removeOwn: false,
    removeAll: false,
    manageAiKnowledgeBase: false,
    enableAiChatWithFile: false,
  },
  qrcodes: {
    enabled: false,
    // create: false, // hidden from oem
    assign: false,
    // update: false, // hidden from oem
    // delete: false, // hidden from oem
    order: false,
  },
  settings: {
    enabled: false,
    manageGeneralInformation: false,
    manageAppearance: false,
    manageUsers: false,
    manageUserAccessRequests: false,
    manageUserRoles: false,
    manageUserGroups: false,
    manageLocalization: false,
    manageCases: false,
    manageWorkOrders: false,
    manageAppointments: false,
    manageDataExport: false,
    managePassword: false,
    manageApiKeys: false,
    manageAssets: false,
  },
  dashboards: {
    enabled: false,
  },
} as Permissions;

// ATTENTION: the order is important, as it's used for the order in the permissions matrix and was defined with CS
const CREATE_PERMISSIONS_AS_ADMIN = {
  assets: {
    enabled: true,
    enableHierarchy: false,
    createType: false,
    create: false,
    notesCreateUpdateDeleteOwn: false,
    updateAttributes: false,
    updateOwnContactPerson: false,
    updateExtOrganization: false,
    updateExtContactPerson: false,
    updateType: false,
    updateLocation: false,
    deleteType: false,
    delete: false,
    notesDeleteAll: false,
    enableAiChatWithX: false,
  },
  organizations: {
    viewExternal: false,
    createExternal: false,
    updateOwn: false,
    updateExternal: false,
    deleteExternal: false,
  },
  users: {
    viewOwn: false,
    viewExternal: false,
    createOwn: false,
    createExternal: false,
    updateOwn: false,
    updateExternal: false,
    deleteOwn: false,
    deleteExternal: false,
  },
  tickets: {
    enabled: false,
    create: false,
    update: false,
    addAnswer: false,
    move: false,
    delete: false,
    deleteNotesOwn: false,
    deleteNotesAll: false,
    export: false,
    enableAi: false,
  },
  workOrders: {
    enabled: false,
    viewScheduling: false,
    create: false,
    createNotes: false,
    update: false,
    updateScheduling: false,
    delete: false,
    deleteNotesOwn: false,
    deleteNotesAll: false,
  },
  maintenancePlans: {
    enabled: false,
    create: false,
    update: false,
    delete: false,
  },
  workOrdersTemporary: {
    enabled: false,
    create: false,
    updateDetails: false,
    updateStatusAndChecklist: false,
    updatePlanning: false,
    delete: false,
    notesCreateUpdateDeleteOwn: false,
    notesDeleteAll: false,
  },
  appointmentsTemporary: {
    enabled: false,
    create: false,
    update: false,
    delete: false,
  },
  tasks: {
    enabled: false,
    updateAll: false,
    deleteAll: false,
  },
  parts: {
    enabled: false,
    viewInOverview: false,
    viewInAssetDetail: false,
    create: false,
    update: false,
    delete: false,
    export: false,
  },
  forms: {
    enabled: false,
    viewAll: false,
    newType: false,
    fill: false,
    fillOverwrite: false,
    createNotes: false,
    duplicateOwnForms: false,
    duplicateAllForms: false,
    updateType: false,
    update: false,
    updateAssignee: false,
    send: false,
    deleteType: false,
    deleteOwn: false,
    delete: false,
    deleteNotesOwn: false,
    deleteNotesAll: false,
  },
  files: {
    enabled: false,
    showOnlyInAssetDetails: false,
    upload: false,
    assignOwn: false,
    assignAll: false,
    moveOwn: false,
    moveAll: false,
    renameOwn: false,
    renameAll: false,
    setPublic: false,
    removeOwn: false,
    removeAll: false,
    manageAiKnowledgeBase: false,
    enableAiChatWithFile: false,
  },
  qrcodes: {
    enabled: false,
    create: false,
    assign: false,
    update: false,
    delete: false,
    order: false,
  },
  settings: {
    enabled: false,
    manageGeneralInformation: false,
    manageAppearance: false,
    manageUsers: false,
    manageUserAccessRequests: false,
    manageUserRoles: false,
    manageUserGroups: false,
    manageLocalization: false,
    manageCases: false,
    manageWorkOrders: false,
    manageAppointments: false,
    manageDataExport: false,
    managePassword: false,
    manageApiKeys: false,
    manageAssets: false,
  },
  iot: {
    enabled: false,
    configure: false,
  },
  dashboards: {
    enabled: false,
  },
} as Permissions;

export const PERMISSION_PRESETS = {
  EXTERNAL,
  NORMAL,
  ADMIN,
  VIEW_PERMISSIONS_AS_OEM,
  CREATE_PERMISSIONS_AS_ADMIN,
};
