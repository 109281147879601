import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { isAccountFeatureFlagEnabled } from '../helpers/checkFeatureHelper';
import { AppStateService } from '../services/app-state.service';

const isEnabled = (flag: FeatureFlagEnum): boolean => {
  const appState = inject(AppStateService);
  return isAccountFeatureFlagEnabled(flag, appState);
};

const getRootUrlTree = (): UrlTree => {
  const router = inject(Router);
  return router.createUrlTree(['/']);
};

export const canActivateFeatureFlagEnabled =
  (flag: FeatureFlagEnum, ifEnabled = true): CanActivateFn =>
  () =>
    (ifEnabled ? isEnabled(flag) : !isEnabled(flag)) || getRootUrlTree();

export const canMatchFeatureFlag =
  (flag: FeatureFlagEnum, ifEnabled = true): CanMatchFn =>
  () =>
    ifEnabled ? isEnabled(flag) : !isEnabled(flag);
