import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Observable, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Directive({
  selector: '[rbAppObserveElement]',
  exportAs: 'intersection',
  standalone: true,
})
export class ObserveElementDirective implements AfterViewInit, OnDestroy {
  @Input() public root: HTMLElement | null = null;
  @Input() public rootMargin = '0px 0px 0px 0px';
  @Input() public threshold = 0.5;
  @Input() public debounceTime = 100;
  @Input() public isContinuous = false;

  @Output() public isIntersecting = new EventEmitter<boolean>();

  private subscription?: Subscription;

  constructor(private element: ElementRef) {}

  public ngAfterViewInit(): void {
    this.subscription = this.createAndObserve();
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private createAndObserve(): Subscription {
    const options: IntersectionObserverInit = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };

    return new Observable<boolean>((subscriber) => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        const { isIntersecting } = entries[0];
        subscriber.next(isIntersecting);

        if (isIntersecting && !this.isContinuous) {
          intersectionObserver.disconnect();
        }
      }, options);

      intersectionObserver.observe(this.element.nativeElement);

      return {
        unsubscribe(): void {
          intersectionObserver.disconnect();
        },
      };
    })
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe((status) => {
        this.isIntersecting.emit(status);
      });
  }
}
