import { CdkCellDef, DataSource } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatCellDef } from '@angular/material/table';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matStrictCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: StrictCellDefDirective }],
})
export class StrictCellDefDirective<T> extends MatCellDef {
  @Input('matStrictCellDef')
  public dataSource?: DataSource<T>;

  public static ngTemplateContextGuard<T>(
    directive: StrictCellDefDirective<T>,
    context: unknown,
  ): context is { $implicit: T } {
    return true;
  }
}
