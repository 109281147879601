/** These strings are reserved for remberg internal usage and cannot be used as a customer subdomain. */
export const RESERVED_SUBDOMAINS = [
  // Staging
  'staging',
  'staging1',
  'staging2',
  'staging3',
  'staging01',
  'staging02',
  'staging03',
  // Testing
  'test',
  'test1',
  'test2',
  'test3',
  'test01',
  'test02',
  'test03',
  // Development
  'dev',
  'dev1',
  'dev2',
  'dev3',
  'dev01',
  'dev02',
  'dev03',
  // Production
  'app',
  'app01',
  'app02',
  'app03',
  'alpha',
  'beta',
  'next',
  'master',
  'main',
  'develop',
  // Mirror
  'mirror',
  // Local
  'local',
];
