<div
  id="rembergModalWrapper"
  [ngClass]="{ 'modal-wrapper': !styleNoMargin, 'modal-wrapper-no-margin': styleNoMargin }"
>
  <div
    *ngIf="headerShow"
    fxLayout="row"
    class="modal-header-row"
    [ngClass]="{ 'modal-header-row-with-margin': styleNoMargin }"
  >
    <button
      class="modal-back-action"
      mat-icon-button
      *ngIf="headerCloseActionShow"
      aria-label="Back"
    >
      <mat-icon (click)="closeDialog()">arrow_back</mat-icon>
    </button>

    <h1
      *ngIf="headerTitle"
      class="mat-headline-5 title-text table-column-text-ellipsis"
      [ngClass]="{ 'title-text-with-done-button': headerConfirmActionShow }"
    >
      {{ headerTitle }}
    </h1>

    <span class="fill-remaining-space"></span>

    <button
      mat-button
      color="primary"
      *ngIf="headerConfirmActionShow"
      (click)="confirmDialog()"
      [disabled]="componentRef?.instance?.disableParentConfirmButton"
      i18n="@@done"
    >
      Done
    </button>

    <button
      class="modal-menu-action"
      mat-icon-button
      *ngIf="headerThreeDotActionShow"
      aria-label="Menu"
      (click)="openThreeDotMenu()"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <div class="modal-header-divider" *ngIf="styleHeaderDivider && headerShow"></div>

  <div
    [class.height-to-scroll]="!verticalScrollDisabled"
    [class.vertical-scrolling-disabled]="verticalScrollDisabled"
    [ngStyle]="{ display: createComponentLoader ? 'block' : 'none' }"
    [ngClass]="{
      'height-to-scroll-ios': layout.isIonic && layout.isIos && headerShow,
      'height-to-scroll-android': layout.isIonic && layout.isAndroid && headerShow,
      'remberg-modal-content-wrapper': headerShow && !headerShowWithoutMargin
    }"
  >
    <template #modalContent></template>
  </div>
</div>
