import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ADMIN_SUBDOMAIN } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { handleIamForbiddenMessage } from '../../helpers/handle-iam-forbidden-message';
import { DeviceInfoService } from '../../services';
import { DialogService } from '../../services/dialog.service';
import { LayoutService } from '../../services/layout.service';
import { GlobalSelectors, RootGlobalState } from '../../store';
import { getDefaultDeletionDialogOptions } from '../dialogDefaultOptions';
import { ModalDialogWrapper } from '../modalDialogWrapper';

@Component({
  selector: 'app-token-refresh-popup',
  templateUrl: './token-refresh-popup.component.html',
  styleUrls: ['./token-refresh-popup.component.scss'],
})
export class TokenRefreshPopupComponent {
  @Input() public email?: string;
  @Input() public tenantId?: string;
  @Input() public dialogModalRef?: ModalDialogWrapper;
  private passwordBackup?: string;
  protected twoFactorCheck: boolean = false;
  protected refreshFormGroup: UntypedFormGroup;
  protected loginSent?: boolean;
  protected loginFailedNoInternet?: boolean;

  protected translations = {
    submit: $localize`:@@login:Login`,
  };

  constructor(
    public readonly layout: LayoutService,
    private readonly rembergUsersService: RembergUsersService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly logger: LogService,
    private readonly toastr: ToastrService,
    private readonly _dialog: DialogService,
    private readonly deviceInfoService: DeviceInfoService,
    private readonly store: Store<RootGlobalState>,
    private readonly router: Router,
  ) {
    this.refreshFormGroup = this._formBuilder.group({
      password: ['', Validators.required],
      twoFactorToken: [''],
    });
  }

  public async refreshToken(): Promise<void> {
    if (this.email) {
      this.refreshFormGroup.disable();
      this.loginSent = true;
      this.passwordBackup = this.refreshFormGroup.value.password as string;
      try {
        const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
        const response = await firstValueFrom(
          this.rembergUsersService.login({
            email: this.email,
            password: this.passwordBackup,
            tenantId: this.tenantId,
            subdomain: !this.tenantId ? ADMIN_SUBDOMAIN : undefined,
            twoFactorToken: this.refreshFormGroup.value.twoFactorToken,
            device: this.deviceInfoService.getDeviceIdSync(),
            mobileDevice: isIonic,
          }),
        );
        this.dialogModalRef?.closeDialogModalFromChildComponent(response);
      } catch (error: unknown) {
        this.loginSent = false;
        this.refreshFormGroup.enable();

        // clear password field
        this.refreshFormGroup.get('password')?.patchValue('');

        // two factor error maybe?
        this.logger.error()(error);
        if (error instanceof HttpErrorResponse) {
          const { statusCode, message } = error.error;
          if (statusCode === 404) {
            this.refreshFormGroup.get('password')?.setErrors({ incorrectCredentials: true });
          } else if (statusCode === 406) {
            this.twoFactorCheck = true;
            // restore password
            this.refreshFormGroup.get('password')?.patchValue(this.passwordBackup);
            this.toastr.info(
              $localize`:@@pleaseEnterTheTwoFactorToken:Please enter the two-factor token.`,
              $localize`:@@info:Info`,
            );
          } else if (statusCode === 403) {
            handleIamForbiddenMessage({
              message,
              toastr: this.toastr,
              router: this.router,
              isPortal: false,
              email: this.email,
            });
          } else if (statusCode === 0) {
            this.loginFailedNoInternet = true;
          }
        }
      }
    }
  }

  /** Function is needed to handle iOS autofill case correctly. */
  protected passwordNativeChange(data: string): void {
    if (this.layout.isIonic) {
      this.logger.debug()('Register native password change.');
      this.refreshFormGroup.get('password')?.patchValue(data);
    }
  }

  protected abort(): void {
    if (!this.layout.isIonic) {
      this.dialogModalRef?.closeDialogModalFromChildComponent();
      return;
    }
    const descriptionTexts = [
      $localize`:@@areYouSureYouWantToLogout:Are you sure you want to log out?`,
      $localize`:@@allYourLocalDataWillBeDeletedAndMightBeLost:All your local data will be deleted and might be lost!`,
    ];
    const dialogOpts = getDefaultDeletionDialogOptions(descriptionTexts, 'warning_amber', true);
    const popup = this._dialog.showDialogOrModal(dialogOpts, false);
    popup.waitForCloseData().then((data) => {
      if (data?.confirmed) {
        this.dialogModalRef?.closeDialogModalFromChildComponent();
      }
    });
  }
}
