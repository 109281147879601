import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { Observable, map, take } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class NotFoundGuardService {
  constructor(
    private store: Store<RootGlobalState>,
    private logger: LogService,
    private router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    this.logger.info()('NotFoundGuard - Redirecting from: ' + state.url);
    return this.store.select(GlobalSelectors.selectIsLoggedIn).pipe(
      take(1),
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.logger.debug()('Moving to login and setting correct target page.');
          this.router.navigate(['login'], { queryParams: { target: state.url } });
          return false;
        } else {
          this.logger.warn()(
            "Is logged in but routing didn't find a page for this URL: " + state.url,
          );
          if (
            state.url === '/undefined' ||
            state.url === '/aftermarket/assets' ||
            state.url === '/internal/assets' ||
            state.url === '/aftermarket' ||
            state.url === '/internal'
          ) {
            this.router.navigate(['assets']); // Redirect for old ARM home URLs
          } else {
            this.router.navigate(['notfound']);
          }
          return true;
        }
      }),
    );
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
