import {
  DataPropertyInfo,
  KnownDataTypeEnum,
  PREFILLABLE_TARGET_DATA_PROPERTY_INFO,
  PrefillableTargetPropertyEnum,
  PrefillableTargetType,
} from '../models';
import { StepFunctions, StepsProvider } from './interfaces';

export function getPrefillableDataPropertyName(
  target: PrefillableTargetType,
  targetProperty: PrefillableTargetPropertyEnum,
): string | undefined {
  const targetProperties: Record<string, DataPropertyInfo> | undefined =
    PREFILLABLE_TARGET_DATA_PROPERTY_INFO[target];

  return targetProperties?.[targetProperty]?.name;
}

export function getPrefillableDataPropertyType(
  target: PrefillableTargetType,
  targetProperty: PrefillableTargetPropertyEnum,
): KnownDataTypeEnum {
  const targetProperties: Record<string, DataPropertyInfo> | undefined =
    PREFILLABLE_TARGET_DATA_PROPERTY_INFO[target];

  return targetProperties?.[targetProperty]?.type ?? KnownDataTypeEnum.UNKNOWN;
}

export function createProvider<Functions extends StepFunctions>(
  steps: Partial<Functions>,
): StepsProvider<Functions> {
  return {
    getSteps: () => steps,
  };
}
