import { Injectable } from '@angular/core';
import {
  AppendFileOptions,
  Encoding,
  Filesystem,
  ReaddirResult,
  ReadFileOptions,
  WriteFileOptions,
} from '@capacitor/filesystem';
import { isPlatform, Platform } from '@ionic/angular';
import { VERSION } from '@remberg/global/common/version';
import { environment, LocalStorageKeys, MOBILE_LOG_DIRECTORY } from '@remberg/global/ui';
import { DeviceInfoService } from '../device-info.service';
import { FileZipService } from './file-zip.service';

// The following code makes sure that JSON.stringify does not
// omit the message and stack properties of error objects.
if (!('toJSON' in Error.prototype)) {
  Object.defineProperty(Error.prototype, 'toJSON', {
    value: function () {
      const alt: Record<string, string> = {
        message: '',
      };

      for (const key of Object.getOwnPropertyNames(this)) {
        alt[key] = this[key];
      }

      return alt;
    },
    configurable: true,
    writable: true,
  });
}

@Injectable({
  providedIn: 'root',
})
export class MobileLogsService {
  private static initialized: boolean = false;
  private static originalLog = window.console.log;
  private static rollingIndex = 1;
  private static lineCounter = 0;
  private static loggerBusy = false;

  private lineCounterThreshold = 100;
  private maxRollingIndex = 25;
  private maxLogSize = 3000000;
  private charactersPerLineLimit = 1000;
  private currentLogFile?: string;
  private zipper: FileZipService;
  private logsDirFolder = 'logs';
  private deviceId = '';

  constructor(
    zipper: FileZipService,
    private platform: Platform,
    private deviceInfoService: DeviceInfoService,
  ) {
    this.zipper = zipper;
  }

  public async initialize(): Promise<void> {
    await this.platform.ready();
    const isSimulatedIonic = localStorage.getItem(LocalStorageKeys.SIMULATE_IONIC);
    const isIonic = !!(isPlatform('electron') || isPlatform('capacitor') || isSimulatedIonic);
    if (!isIonic) {
      return;
    }
    // Possible cases when starting up the app:
    // - the date has changed
    // - the date has not changed (still on current day)
    // - the very first run
    if (MobileLogsService.initialized === false) {
      const logsCollector: string[] = [];

      const deviceInfo = await this.deviceInfoService.getDeviceInfoAsync();
      this.deviceId = await this.deviceInfoService.getDeviceIdAsync();

      await this.createLogsFolder(logsCollector);

      const logFiles = await this.getLogFiles();

      logsCollector.push(
        `${this.getTimestamp()} GetLogFiles returned: ${JSON.stringify(logFiles)}.`,
      );

      if (logFiles.length === 0) {
        // the very first run
        this.currentLogFile = this.createFileName(1);

        logsCollector.push(
          `${this.getTimestamp()} No log files found. Set current log file to: ${
            this.currentLogFile
          }.`,
        );
      } else {
        const currentDate = new Date().toISOString().slice(0, 10);

        logFiles.sort().reverse();

        const todaysLog = logFiles.find((item) => item.indexOf(currentDate) > -1);
        if (todaysLog) {
          // the date has not changed (still on current day)
          this.currentLogFile = todaysLog;
          const lastIndex = Number(
            todaysLog.substring(todaysLog.lastIndexOf('_') + 1, todaysLog.indexOf('.')),
          );
          MobileLogsService.rollingIndex = lastIndex;

          logsCollector.push(
            `${this.getTimestamp()} Current date has not changed. Set current log file to: ${
              this.currentLogFile
            }.`,
          );
        } else {
          // the date has changed
          this.currentLogFile = this.createFileName(1);

          logsCollector.push(
            `${this.getTimestamp()} Current date has changed. Set current log file to: ${
              this.currentLogFile
            }.`,
          );

          await this.zipLogFiles(logsCollector);

          // Some logs from the day before might still be around so we make sure to delete them
          await this.deleteOldLogFiles(logsCollector);
        }
      }

      const zipFiles = await this.getLogZipFiles();
      const filesToDelete = zipFiles.length - this.maxRollingIndex;

      logsCollector.push(
        `${this.getTimestamp()} GetLogZipFiles returned : ${JSON.stringify(
          zipFiles,
        )}. Files to delete is: ${filesToDelete}`,
      );

      if (filesToDelete > 0) {
        logsCollector.push(`${this.getTimestamp()} About to delete ${filesToDelete} files.`);
        for (let index = 0; index < filesToDelete; index++) {
          await this.deleteOldestZippedLogFile(logsCollector);
        }
      }

      window.console.log = this.appendToLog;
      window.console.warn = this.appendToLog;
      window.console.error = this.appendToLog;

      MobileLogsService.initialized = true;

      await this.appendToLog(this.getTimestamp(), 'Starting writing initialization messages');
      await this.appendToLog(
        this.getTimestamp(),
        `Remberg app v${VERSION}, commitId: ${window.__env?.commitId}`,
      );

      for (let index = 0; index < logsCollector.length; index++) {
        await this.appendToLog(logsCollector[index]);
      }

      await this.appendToLog(
        this.getTimestamp(),
        'MobileLogsService is initialized: ',
        MobileLogsService.initialized,
      );

      await this.appendToLog(
        this.getTimestamp(),
        'Device information: ',
        JSON.stringify(deviceInfo),
      );
    }
  }

  // We can't type this as any kind of object is "write-able" to the log
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  private appendToLog = async (...args: any): Promise<void> => {
    if (!MobileLogsService.initialized) {
      await this.initialize();
    }

    const allArgs = [...args];
    const logLine = [];

    if (!environment.production) {
      MobileLogsService.originalLog(...args);
    }

    for (let index = 0; index < allArgs.length; index++) {
      const argument = allArgs[index];

      if (typeof argument !== 'string') {
        logLine.push(JSON.stringify(argument));
      } else {
        logLine.push(argument);
      }
    }
    const options: AppendFileOptions = {
      data: `${logLine.join(' ').slice(0, this.charactersPerLineLimit)}\r\n`,
      path: `${this.logsDirFolder}/${this.currentLogFile}`,
      directory: MOBILE_LOG_DIRECTORY,
      encoding: Encoding.UTF8,
    };

    await Filesystem.appendFile(options);

    MobileLogsService.lineCounter++;

    if (MobileLogsService.loggerBusy) {
      return;
    }

    if (
      MobileLogsService.lineCounter > this.lineCounterThreshold &&
      MobileLogsService.loggerBusy === false
    ) {
      MobileLogsService.loggerBusy = true;
      try {
        await this.checkAndRoll();
      } catch (error) {
        MobileLogsService.originalLog(
          this.getTimestamp(),
          'Error during checkAndRoll. We are probably screwed!',
        );
      }

      MobileLogsService.lineCounter = 0;
      MobileLogsService.loggerBusy = false;
    }
  };

  public zipLogFiles = async (logsCollector?: string[]): Promise<void> => {
    try {
      const platform = await this.platform.ready();

      await this.collectOrAppend(
        `${this.getTimestamp()}, 'Platform is ready: ', ${platform}`,
        logsCollector,
      );

      const logFiles = await this.getLogFiles();

      await this.collectOrAppend(
        `${this.getTimestamp()} About to zip these files: ', ${JSON.stringify(logFiles)}`,
        logsCollector,
      );

      for (let index = 0; index < logFiles.length; index++) {
        const logFile = logFiles[index];

        await this.zipOneFile(logFile, logsCollector);
      }
    } catch (error) {
      await this.collectOrAppend(
        `${this.getTimestamp()} Error during zipping the log files: ${error}`,
        logsCollector,
      );
    }
  };

  public getLogZipFiles = async (): Promise<string[]> => {
    const readOptions: ReadFileOptions = {
      path: 'logs',
      directory: MOBILE_LOG_DIRECTORY,
    };

    const readResult = await Filesystem.readdir(readOptions);
    const logFiles = readResult && readResult.files ? readResult.files : [];
    return logFiles.filter((item) => item.name.endsWith('.zip')).map((item) => item.name);
  };

  public getLogFiles = async (): Promise<string[]> => {
    const readOptions: ReadFileOptions = {
      path: 'logs',
      directory: MOBILE_LOG_DIRECTORY,
    };

    const readResult = await Filesystem.readdir(readOptions);
    const logFiles = readResult && readResult.files ? readResult.files : [];
    return logFiles.filter((item) => item.name.endsWith('.txt')).map((item) => item.name);
  };

  public deleteFile = async (filename: string, logsCollector?: string[]): Promise<void> => {
    const fileResult = await Filesystem.deleteFile({
      path: `${this.logsDirFolder}/${filename}`,
      directory: MOBILE_LOG_DIRECTORY,
    });

    await this.collectOrAppend(
      `${this.getTimestamp()} Deleted file with name: ${filename} with result: ${fileResult}`,
      logsCollector,
    );
  };

  public deleteOldLogFiles = async (logsCollector?: string[]): Promise<void> => {
    let logFiles = await this.getLogFiles();

    logFiles = logFiles.filter((item) => item !== this.currentLogFile);

    await this.collectOrAppend(
      `${this.getTimestamp()} About to delete these log files: ${JSON.stringify(logFiles)}`,
      logsCollector,
    );

    for (let index = 0; index < logFiles.length; index++) {
      const file = logFiles[index];

      const fileResult = await Filesystem.deleteFile({
        path: `${this.logsDirFolder}/${file}`,
        directory: MOBILE_LOG_DIRECTORY,
      });
      await this.collectOrAppend(
        `${this.getTimestamp()} Deleted log file with name: ${file} with result: ${fileResult}`,
        logsCollector,
      );
    }
  };

  private zipOneFile = async (filename: string, logsCollector?: string[]): Promise<void> => {
    await this.collectOrAppend(
      `${this.getTimestamp()} Processing file: ${filename}`,
      logsCollector,
    );

    const zipFilenamePath = `${this.logsDirFolder}/${filename}.zip`;

    try {
      await Filesystem.deleteFile({
        path: zipFilenamePath,
        directory: MOBILE_LOG_DIRECTORY,
      });

      await this.collectOrAppend(
        `${this.getTimestamp()} Deleted current log file zip (path: ${zipFilenamePath}) before archiving`,
        logsCollector,
      );
    } catch (error) {
      // we don't really care if we got an error here as it might happen that the zip was not even created yet
      // we are just logging the error for brevity
      await this.collectOrAppend(
        `${this.getTimestamp()} Error when deleting the old zip file (path: ${zipFilenamePath}): ${error}`,
        logsCollector,
      );
    }

    // We cannot use fetch() here anymore instead of Filesystem.readFile() (which seemed to work better with large files)
    // since there are CORS issues on iOS
    // https://github.com/ionic-team/capacitor/issues/6177
    // https://github.com/ionic-team/capacitor/issues/6174

    const res = await Filesystem.readFile({
      path: `${this.logsDirFolder}/${filename}`,
      directory: MOBILE_LOG_DIRECTORY,
      encoding: Encoding.UTF8,
    });

    await this.collectOrAppend(
      `${this.getTimestamp()} to be zipped file was read from disk: ${zipFilenamePath})`,
      logsCollector,
    );

    const blob = await this.zipper.zipFile(filename, res.data);

    await this.collectOrAppend(
      `${this.getTimestamp()} file was zipped (still in memory): ${zipFilenamePath})`,
      logsCollector,
    );

    const blobAsString = await this.readBlob(blob);

    await this.collectOrAppend(
      `${this.getTimestamp()} zipped blob was created for file: ${zipFilenamePath})`,
      logsCollector,
    );

    const writeOptions: WriteFileOptions = {
      path: zipFilenamePath,
      directory: MOBILE_LOG_DIRECTORY,
      encoding: Encoding.UTF8,
      data: blobAsString,
      recursive: true,
    };

    const writeResult = await Filesystem.writeFile(writeOptions);

    await this.collectOrAppend(
      `${this.getTimestamp()} Zip file (path: ${zipFilenamePath}) wrote to disk with result: ${writeResult}`,
      logsCollector,
    );
  };

  private collectOrAppend = async (message: string, logsCollector?: string[]): Promise<void> => {
    if (logsCollector) {
      logsCollector.push(message);
    } else {
      await this.appendToLog(message);
    }
  };

  private checkAndRoll = async (): Promise<void> => {
    // if the current log file size is over the threshold
    // - change the log to a new file
    // - zip the old log
    // - delete the old log file
    // - if the number of zip files is over threshold delete the oldest zipped log file
    //   e.g.: from 989_2021-7-29_1.txt.zip and 989_2021-7-29_2.txt.zip the first one is older
    const currentLogFileSize = await this.getFileSize(this.currentLogFile as any as string);

    if (currentLogFileSize > this.maxLogSize) {
      MobileLogsService.rollingIndex++;

      const oldLogFilename = this.currentLogFile as any as string;

      this.currentLogFile = this.createFileName(MobileLogsService.rollingIndex);

      // eslint-disable-next-line max-len
      await this.appendToLog(
        `${this.getTimestamp()} Current log file size is over threshold. Rolled over to next index: ${
          this.currentLogFile
        }`,
      );

      await this.zipOneFile(oldLogFilename);
      await this.deleteFile(oldLogFilename);
    } else {
      await this.appendToLog(
        `${this.getTimestamp()} Current log file size is below threshold. Keeping current index: ${
          this.currentLogFile
        }`,
      );
    }

    const zipFiles = await this.getLogZipFiles();
    if (zipFiles.length > this.maxRollingIndex) {
      await this.deleteOldestZippedLogFile();
    }

    await this.appendToLog(
      `${this.getTimestamp()} Finished checking and rolling. Current log file: ${
        this.currentLogFile
      }`,
    );
  };

  private deleteOldestZippedLogFile = async (logsCollector?: string[]): Promise<void> => {
    const allFiles = await this.getLogZipFiles();
    if (allFiles.length > 0) {
      const fileToDelete = allFiles.sort()[0];

      try {
        await this.collectOrAppend(
          `${this.getTimestamp()} Deleting oldest zipped log file: ${fileToDelete}`,
          logsCollector,
        );
        await this.deleteFile(fileToDelete, logsCollector);
      } catch (error) {
        await this.collectOrAppend(
          `Error deleting the file: ${fileToDelete}. It could have been deleted when syncing.`,
          logsCollector,
        );
      }
    } else {
      await this.collectOrAppend('No zipped log files found. Nothing to delete', logsCollector);
    }
  };

  private createFileName = (index: number, extension?: string): string => {
    const date = new Date().toISOString().slice(0, 10);
    const indexString = index < 10 ? `0${index}` : index.toString();
    let filename = `${this.deviceId}_${date}_${indexString}.txt`;
    if (extension) {
      filename = `${filename}.${extension}`;
    }
    return filename;
  };

  private getFileSize = async (filename?: string): Promise<number> => {
    const fileResult = await Filesystem.stat({
      path: `${this.logsDirFolder}/${filename}`,
      directory: MOBILE_LOG_DIRECTORY,
    });
    await this.appendToLog(
      `${this.getTimestamp()} Returning filesize of ${fileResult.size} for filename: ${filename}`,
    );
    return fileResult.size;
  };

  private createLogsFolder = async (logsCollector: string[]): Promise<void> => {
    let logFiles: string[] | undefined;
    let opResult: ReaddirResult;
    try {
      opResult = await Filesystem.readdir({
        path: this.logsDirFolder,
        directory: MOBILE_LOG_DIRECTORY,
      });
      if (opResult && opResult.files) {
        logFiles = opResult.files.map((item) => item.name);
      }
    } catch (error) {
      logsCollector.push(`${this.getTimestamp()} Could not access logs directory`);
    }
    if (!logFiles) {
      try {
        await Filesystem.mkdir({
          path: this.logsDirFolder,
          directory: MOBILE_LOG_DIRECTORY,
          recursive: true,
        });
        logsCollector.push(`${this.getTimestamp()} Logs directory created successfully`);
      } catch (err) {
        logsCollector.push(`${this.getTimestamp()} Error creating logs directory.`);
      }
    }
  };

  private readBlob = (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };

      reader.onerror = () => {
        reject(null);
      };

      reader.onabort = () => {
        reject(null);
      };

      reader.readAsDataURL(blob);
    });

  private getTimestamp = () => {
    const date = new Date();
    return `${date.toISOString()} [MobileLogger]`;
  };
}
