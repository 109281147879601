export enum AppointmentFilterFieldEnum {
  CREATED_BY = 'createdBy',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TYPE = 'type',

  ASSIGNED_CONTACT = 'assignedContact',
  ASSIGNED_GROUP = 'assignedGroup',
  ASSIGNED_ORGANIZATION = 'assignedOrganization',

  ZIP_CODE = 'zipPostCode',
  CITY = 'city',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',

  // Custom logic filters
  INVOLVED_CONTACT = 'involvedContact',
  MY_GROUPS = 'myGroups',
  BOUNDING_BOX = 'boundingBox',
  HAS_RELATED_OBJECT = 'hasRelatedObject',
}
