import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
  OfflinePopulateType,
} from '@remberg/global/ui';
import {
  WorkOrder,
  WorkOrderAPIFilterFieldEnum,
  WorkOrderNoteActivity,
  WorkOrderPayload,
  WorkOrderPopulateFieldEnum,
} from '@remberg/work-orders-legacy/common/main';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  WORK_ORDER_OFFLINE_SERVICE,
  WorkOrderAdvancedFilterQuery,
  WorkOrderAdvancedStaticFilter,
  WorkOrderOfflineServiceInterface,
} from '../definitions';

export type WorkOrderOfflineModeAdvancedFilterQuery =
  AdvancedFilterQuery<WorkOrderAPIFilterFieldEnum>;

/**
 * @deprecated This is the WO1 service, use the WO2 service in @remberg/work-orders/ui/clients
 */
@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {
  public readonly workOrdersUrl = `${API_URL_PLACEHOLDER}/workorders`;

  constructor(
    private http: HttpClient,
    private logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(WORK_ORDER_OFFLINE_SERVICE)
    private workOrderOfflineService: WorkOrderOfflineServiceInterface,
  ) {}

  // ========================  List API  ===================================
  private getWorkOrdersBase(
    populate?: boolean | WorkOrder,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
    filterQuery: WorkOrderAdvancedFilterQuery | undefined = undefined,
    staticFilters: WorkOrderAdvancedStaticFilter[] | undefined = undefined,
    populateAll?: boolean,
    asset?: string,
    recentWorkOrders: boolean = false,
    populateAssetsAndContactPersons: boolean = false,
    populateContactsWithUserGroups: boolean = false,
  ): Observable<any> {
    let params = new HttpParams();

    if (populate !== undefined && populate && typeof populate === 'boolean') {
      // only populate organization, toPerformByContact and responsibleContact for the WO list
      if (populateAll) {
        params = params.set('populate', populate.toString());
      } else {
        params = params.set('populateAssignedOrganization', 'true');
        params = params.set('populateToPerformByContact', 'true');
        params = params.set('populateResponsibleContact', 'true');
        params = params.set('populateResponsibleGroup', 'true');
        params = params.set('populateToPerformByGroup', 'true');
        params = params.set('populateAttendantContacts', 'true');
        if (populateAssetsAndContactPersons) {
          params = params.set('populateAssets', 'true');
          params = params.set('populateContacts', 'true');
        }
      }
    } else if (typeof populate === 'object') {
      if (populate && (populate as WorkOrder).assignedOrganization) {
        params = params.set('populateAssignedOrganization', 'true');
      }
      if (populate && (populate as WorkOrder).toPerformByContact) {
        params = params.set('populateToPerformByContact', 'true');
      }
      if (populate && (populate as WorkOrder).responsibleContact) {
        params = params.set('populateResponsibleContact', 'true');
      }
      if (populate && (populate as WorkOrder).responsibleGroup) {
        params = params.set('populateResponsibleGroup', 'true');
      }
      if (populate && (populate as WorkOrder).toPerformByGroup) {
        params = params.set('populateToPerformByGroup', 'true');
      }
      if (populate && (populate as WorkOrder).attendantContacts) {
        params = params.set('populateAttendantContacts', 'true');
      }
      if (populate && (populate as WorkOrder).assets) {
        params = params.set('populateAssets', 'true');
      }
      if (populate && (populate as WorkOrder).contacts) {
        params = params.set('populateContacts', 'true');
      }
      if (populate && (populate as WorkOrder).relatedServiceCase) {
        params = params.set('populateRelatedServiceCase', 'true');
      }
    }

    if (populateContactsWithUserGroups) {
      params = params.set('populateContactsWithUserGroups', 'true');
    }
    if (recentWorkOrders) {
      params = params.set('recentWorkOrders', String(recentWorkOrders));
    }
    if (asset) {
      params = params.set('asset', asset);
    }
    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', encodeURIComponent(String(searchValue)));
    }
    if (filterValue) {
      params = params.set('filter', JSON.stringify(filterValue));
    }
    if (filterQuery) {
      params = params.set('filterQuery', JSON.stringify(filterQuery));
    }
    if (staticFilters) {
      params = params.set('staticFilters', JSON.stringify(staticFilters));
    }
    return this.http.get<any>(this.workOrdersUrl, { params });
  }

  public getWorkOrdersWithAdvancedFilters(
    populateFields: WorkOrderPopulateFieldEnum[],
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterQuery: WorkOrderAdvancedFilterQuery | undefined = undefined,
    staticFilters: WorkOrderAdvancedStaticFilter[] | undefined = undefined,
    recentWorkOrders: boolean = false,
  ): Observable<ApiResponse<WorkOrder[]>> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online work order request...');

      const populate: Record<string, boolean> = {};
      for (const populateField of populateFields) {
        populate[populateField] = true;
      }

      return this.getWorkOrdersBase(
        populate as any,
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        searchValue,
        undefined,
        filterQuery,
        staticFilters,
        false,
        undefined,
        recentWorkOrders,
        false,
      ).pipe(map((res) => new ApiResponse<WorkOrder[]>(res.data, res.count)));
    } else {
      this.logger.debug()('Offline work order fallback...');

      return from(
        this.workOrderOfflineService.getWorkOrdersWithCount({
          limit: pageSize,
          offset: pageIndex,
          sortColumn: sortField,
          sortDirection,
          searchValue,
          // Custom properties are not synced in offline mode. We cast filterQuery to contain only WorkOrderAPIFilterFieldEnum filter type.
          filterQuery: filterQuery as WorkOrderOfflineModeAdvancedFilterQuery,
          populate: {
            assignedOrganization: true,
            toPerformByContact: true,
            toPerformByGroup: true,
            responsibleContact: true,
            responsibleGroup: true,
          },
        }),
      );
    }
  }

  public getWorkOrdersWithCount(
    populate?: boolean,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: SortDirection = '',
    sortField: string = '',
    searchValue: string = '',
    filterValue: any = null,
    recentWorkOrders: boolean = false,
    populateAssetsAndContactPersons: boolean = false,
    populateContactsWithUserGroups: boolean = false,
  ): Observable<ApiResponse<WorkOrder[]>> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online work order request...');
      return this.getWorkOrdersBase(
        populate,
        pageSize,
        pageIndex,
        sortDirection,
        sortField,
        searchValue,
        filterValue,
        undefined,
        undefined,
        undefined,
        undefined,
        recentWorkOrders,
        populateAssetsAndContactPersons,
        populateContactsWithUserGroups,
      ).pipe(map((res) => new ApiResponse<WorkOrder[]>(res.data, res.count)));
    } else {
      this.logger.debug()('Offline work order fallback...');
      let populateOffline: OfflinePopulateType | undefined;
      if (populate) {
        populateOffline = {
          assignedOrganization: true,
          toPerformByContact: true,
          toPerformByGroup: true,
          responsibleContact: true,
          responsibleGroup: true,
        };
      }
      return from(
        this.workOrderOfflineService.getWorkOrdersWithCount({
          limit: pageSize,
          offset: pageIndex,
          sortColumn: sortField,
          sortDirection,
          searchValue,
          filters: filterValue,
          populate: populateOffline,
        }),
      );
    }
  }

  // ========================  Detail API  ===================================
  public getWorkOrder(wOrder: string, populate?: boolean): Observable<WorkOrder> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online work order instance request...');
      const url = `${this.workOrdersUrl}/${wOrder}`;
      let params = new HttpParams();
      if (populate !== undefined) {
        params = params.set('populate', populate.toString());
      }
      return this.http
        .get<ApiResponse<WorkOrder>>(url, { params: params })
        .pipe(map((res) => res.data));
    } else {
      this.logger.debug()('Offline work order instance fallback...');
      return from(this.workOrderOfflineService.getInstance(wOrder, populate));
    }
  }

  public addWorkOrder(wOrder: WorkOrderPayload, populate?: boolean): Observable<WorkOrder> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    return this.http
      .post<ApiResponse<WorkOrder>>(this.workOrdersUrl, wOrder, httpOptions)
      .pipe(map((res) => res.data));
  }

  public updateWorkOrder(payload: WorkOrderPayload, populate?: boolean): Observable<WorkOrder> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    const url = `${this.workOrdersUrl}/${payload._id}`;
    return this.http
      .put<ApiResponse<WorkOrder>>(url, payload, httpOptions)
      .pipe(map((res) => res.data));
  }

  public createNoteActivity(
    workOrderId: string,
    payload: WorkOrderNoteActivity,
    populate?: boolean,
  ): Observable<WorkOrder> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    const url = `${this.workOrdersUrl}/${workOrderId}/activity`;
    return this.http.patch<any>(url, payload, httpOptions).pipe(map((res) => res.data));
  }

  public deleteNoteActivity(
    workOrderId: string,
    noteActivityId: string,
    populate: boolean = true,
  ): Observable<WorkOrder> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    const url = `${this.workOrdersUrl}/${workOrderId}/activity/${noteActivityId}`;
    return this.http.delete<any>(url, httpOptions).pipe(map((res) => res.data));
  }

  public deleteWorkOrder(wOrder: WorkOrder | string): Observable<WorkOrder> {
    const wOrder_id = typeof wOrder === 'string' ? wOrder : wOrder._id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const url = `${this.workOrdersUrl}/${wOrder_id}`;

    return this.http.delete<ApiResponse<WorkOrder>>(url, httpOptions).pipe(map((res) => res.data));
  }

  public getLocationFilter(
    filter?:
      | WorkOrderAPIFilterFieldEnum.CITY
      | WorkOrderAPIFilterFieldEnum.COUNTRY
      | WorkOrderAPIFilterFieldEnum.COUNTRY_PROVINCE,
    pageSize?: number,
    pageIndex?: number,
    search?: string,
  ): Observable<ApiResponse<string[]>> {
    if (this.connectivityService.getConnected()) {
      let params = new HttpParams();
      if (filter) {
        params = params.set('filter', filter);
      }
      if (search) {
        params = params.set('search', search);
      }
      if (pageSize) {
        params = params.set('pageSize', String(pageSize));
      }
      if (pageIndex) {
        params = params.set('pageIndex', String(pageIndex));
      }
      return this.http
        .get<any>(this.workOrdersUrl + '/locationfilter', { params: params })
        .pipe(map((res) => new ApiResponse<string[]>(res.data, res.count)));
    } else {
      this.logger.debug()('Offline work order location filter are not supported...');
      return of(new ApiResponse([], 0));
    }
  }

  public hasWorkOrderBeenModifiedSince(
    workOrderId: string,
    lastModified?: Date,
  ): Observable<boolean> {
    if (!lastModified) return of(true);
    return this.http
      .get<ApiResponse<boolean>>(`${this.workOrdersUrl}/${workOrderId}/modifiedSince`, {
        params: { date: lastModified.toString() },
      })
      .pipe(map((res) => res.data));
  }
}
