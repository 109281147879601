import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export function dateBeforeValidator(
  startDateControlName: string,
  endDateControlName: string,
  customMsg?: string,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as UntypedFormGroup;

    const startDate = formGroup.controls[startDateControlName];
    const endDate = formGroup.controls[endDateControlName];

    if (endDate.errors && !endDate.errors['endDateIsBeforeStartDate']) {
      // return if another validator has already found an error on the matchingControl
      return null;
    }

    // set error on matchingControl if validation fails
    const endDateVal = endDate?.value;
    const startDateVal = startDate?.value;

    if (endDateVal && startDateVal) {
      const endDateTime = new Date(endDateVal).getTime();
      const startDateTime = new Date(startDateVal).getTime();

      const errors = endDate.errors;
      if (endDateTime < startDateTime) {
        const newErrors = { ...errors, endDateIsBeforeStartDate: customMsg ?? true };
        endDate.setErrors(newErrors);
      } else if (errors?.['endDateIsBeforeStartDate']) {
        delete errors['endDateIsBeforeStartDate'];
        endDate.setErrors(Object.keys(errors)?.length > 0 ? errors : null);
      }
    } else {
      endDate.setErrors(null);
    }

    return null;
  };
}
