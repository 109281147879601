export enum WorkOrderTableColumnNameEnum {
  COUNTER = 'counter',
  TITLE = 'title',
  STATUS = 'status',
  TYPE = 'type',
  ASSIGNED_ORGANIZATION = 'assignedOrganization',
  START_DATE = 'startDate',
  DUE_DATE = 'dueDate',
  RESPONSIBLE = 'responsible',
  TO_PERFORM_BY = 'toPerformBy',
  PRIORITY = 'priority',
  CREATED_AT = 'createdAt',
  LAST_MODIFIED = 'lastModified',
  END_DATE = 'endDate',
  ASSETS = 'assets',
  ESTIMATED_DURATION = 'estimatedDuration',
  LOCATION = 'location',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  CITY = 'city',
  ZIP_POST_CODE = 'zipPostCode',
  STREET = 'street',
  STREET_NUMBER = 'streetNumber',
  ERP_REFERENCE = 'erpReference',
  RELATED_SERVICE_CASE = 'relatedServiceCase',
  TOUCHED_BY_USER = 'touchedByUser',
}
