import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable, take } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class InitializationGuard {
  constructor(private store: Store<RootGlobalState>) {}

  /**
   * Makes sure that the application state has been initialized first (incl. pot. mobile migrations).
   * Note: This does not prevent the AppComponent from being loaded.
   * @returns Observable
   */
  public canActivate(): Observable<boolean> {
    return this.store
      .select(GlobalSelectors.selectIsGlobalInitializationComplete)
      .pipe(filter(Boolean), take(1));
  }
}
