import { Pipe, PipeTransform } from '@angular/core';
import {
  AllColors,
  COLOR_PALETTE,
  ColorPaletteOption,
  ColorPropertyToDisplay,
} from '@remberg/global/ui';

@Pipe({
  name: 'colorOption',
})
export class ColorOptionPipe implements PipeTransform {
  public transform(
    colorPaletteOption: ColorPaletteOption<AllColors> | undefined,
    property: ColorPropertyToDisplay,
  ): string {
    if (!colorPaletteOption) {
      return '';
    }

    const colorPaletteConfig = COLOR_PALETTE[colorPaletteOption];
    return colorPaletteConfig ? `var(--${colorPaletteConfig[property]})` : '';
  }
}
