import { RembergDate } from '@remberg/global/common/core';

export function isWorkOrderOverdue(
  dueDate: RembergDate | undefined,
  isCompleted: boolean,
): boolean {
  if (!dueDate) {
    return false;
  }

  const date = new Date(dueDate);

  return date.getTime() < Date.now() && !isCompleted;
}
