import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDangerDialogData, stringValidator } from '@remberg/global/ui';

@Component({
  selector: 'app-confirm-danger-dialog',
  templateUrl: './confirm-danger-dialog.component.html',
  styleUrls: ['./confirm-danger-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDangerDialogComponent {
  protected confirmationControl?: FormControl<string | null>;

  protected translations = {
    textFieldLabel: $localize`:@@confirmationText:Confirmation Text`,
    errorRequired: $localize`:@@confirmationTextIsRequired:Confirmation Text is required`,
    errorIsMatching: $localize`:@@confirmationTextMustMatch:Confirmation Text must match`,
  };

  constructor(@Inject(MAT_DIALOG_DATA) protected config: ConfirmDangerDialogData) {
    this.confirmationControl = new FormControl('', [
      Validators.required,
      stringValidator(config.instanceName, { isMatching: true }),
    ]);
  }

  protected get headerText(): string {
    return $localize`:@@confirmDangerDialogHeader:Delete ${this.config.entityName}`;
  }

  protected get paragraph1Text(): string {
    const description = this.config.description ? `<br />${this.config.description}<br />` : ' ';
    const confirmationMessage = $localize`:@@areYouSureYouWantToDeleteIt:Are you sure you want to delete it?`;
    return (
      $localize`:@@youAreAboutToPermanentlyDeleteBoldXAndAllItsContentsDotAreYouSureYouWantToDeleteItQuestionMark:You are about to permanently delete <b>${this.config.instanceName}</b> and all its contents.` +
      description +
      confirmationMessage
    );
  }

  protected get paragraph2Text(): string {
    return $localize`:@@TypeBoldXBelowToConfirmColon:Type <b>${this.config.instanceName}</b> below to confirm:`;
  }

  protected get placeholderText(): string {
    return $localize`:@@typeXThreeDots:Type ${this.config.instanceName} ...`;
  }
}
