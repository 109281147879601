import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AssetsPermissionEnum } from '@remberg/assets/common/main';
import { LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { UserRightsService } from '../../services/user-rights.service';
import { GlobalSelectors } from '../../store';

export const assetsGuardFn: CanActivateFn = async (): Promise<boolean> => {
  const userRightsService = inject(UserRightsService);
  const router = inject(Router);
  const logger = inject(LogService);
  const store = inject(Store);
  const hasPermission = userRightsService.getPermission(AssetsPermissionEnum.ASSETS_ENABLED);
  const isIonic = await firstValueFrom(store.select(GlobalSelectors.selectIsIonic));

  if (hasPermission && !isIonic) return true;

  logger.info()('Assets Guard: Redirecting from: ' + router.url);
  router.navigate(['/']);
  return false;
};
