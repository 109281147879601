export const DefaultColors = {
  // Primary Colors
  PrimaryThemeColor: '#facc2e', //--primaryThemeColor
  PrimaryThemeColorDark: '#c8a325', //--primaryThemeColorDark
  PrimaryThemeColorLight: '#fcdb6d', //--primaryThemeColorLight

  // Background Colors
  BackgroundThemeColor: '#FAFAFA', //--paletteBlackLight975
  BackgroundThemeColorDark: '#d6d6d9', //--paletteBlackLight800
  BackgroundThemeColorLight: '#FEFEFE', //--paletteBlackLight985

  // Calculated Text Colors
  textColorOnPrimaryBackground: '#30343f', //--black
};

export const DarkColorCalculationFactor = -15;
export const LightColorCalculationFactor = 20;
export const PrimaryColorContrastRatio = 2;
export const BackgroundColorContrastRatio = 3;

export function hexToRgb(hex: string): { r: number; g: number; b: number } | undefined {
  if (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : undefined;
  } else {
    return undefined;
  }
}

export function getArrayFromRGB(color: string): number[] {
  return color
    .substring(4, color.length - 1)
    .replace(/ /g, '')
    .split(',')
    .map((str) => parseInt(str));
}

// Ref. link: https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
export function luminance(r: number, g: number, b: number): number {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  // standard formula for the luminance
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function contrast(rgb1: number[], rgb2: number[]): number {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}
