<div class="header-wrapper">
  <div>
    <mat-icon class="mat-icon notranslate mat-icon-no-color icon-primary">{{
      headerIcon
    }}</mat-icon>
  </div>
</div>

<div>
  <div *ngIf="headerText">
    <h2 class="header-text">
      {{ headerText }}
    </h2>
  </div>
</div>

<div>
  <div *ngIf="contentText">
    <p class="content-text">
      {{ contentText }}
    </p>
  </div>

  <mat-form-field
    cdkFocusInitial
    class="preview-url-number-input my-mat-form-field my-mat-form-field-full-width"
    appearance="outline"
  >
    <mat-label>{{ label }}</mat-label>
    <input type="text" matInput [formControl]="previewServerNumFormControl" [placeholder]="label" />

    <mat-error
      *ngIf="previewServerNumFormControl.errors?.required"
      i18n="@@previewNumberIsRequired"
    >
      Preview Number is required
    </mat-error>
    <mat-error
      *ngIf="previewServerNumFormControl.errors?.pattern"
      i18n="@@previewNumberIsNotCorrect"
    >
      Preview number is not correct
    </mat-error>
  </mat-form-field>

  <p *ngIf="inputIsValid" @expandIn class="preview-url-text mat-small">
    Will result in
    <span class="preview-url">{{ previewServerURL }}</span>
  </p>
</div>

<div class="buttons-container">
  <button
    *ngIf="cancelButtonText"
    mat-stroked-button
    color="accent"
    (click)="close()"
    data-test-id="dialog-cancel-button"
  >
    {{ cancelButtonText }}
  </button>
  <button
    *ngIf="confirmButtonText"
    class="button-confirm"
    color="primary"
    mat-raised-button
    [disabled]="!inputIsValid"
    (click)="confirm()"
    data-test-id="dialog-confirm-button"
  >
    <mat-icon *ngIf="confirmButtonIcon">{{ confirmButtonIcon }}</mat-icon>
    {{ confirmButtonText }}
  </button>
</div>

<div *ngIf="footerText" class="footer-container">
  <mat-icon *ngIf="footerIcon" class="material-icons-filled footer-icon">{{ footerIcon }}</mat-icon>
  <p class="footer-text">{{ footerText }}</p>
</div>
