import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

import { LogService } from '@remberg/global/ui';

@Component({
  selector: 'app-landing-portal',
  templateUrl: './landing-portal.component.html',
  styleUrls: ['./landing-portal.component.scss'],
})
export class LandingPortalComponent implements OnInit {
  constructor(
    private readonly logger: LogService,
    @Inject(DOCUMENT) private readonly _document: HTMLDocument,
  ) {}

  public ngOnInit(): void {
    this.logger.info()('Landed on url:', this._document.location.href);
  }
}
