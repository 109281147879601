import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';

import { FormRightsEnum } from '@remberg/global/ui';
import { isAccountFeatureFlagEnabled } from '../../helpers/checkFeatureHelper';
import { AppStateService } from '../../services/app-state.service';
import { UserRightsService } from '../../services/user-rights.service';

@Injectable({
  providedIn: 'root',
})
export class FormsGuard {
  constructor(
    private userRightsService: UserRightsService,
    private router: Router,
    private logger: LogService,
    private appState: AppStateService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const lastURLPart = route.url[route.url.length - 1]
        ? route.url[route.url.length - 1].path
        : '';

      const formsFeature = isAccountFeatureFlagEnabled(FeatureFlagEnum.FORMS, this.appState);

      if (
        formsFeature &&
        lastURLPart === 'detail' &&
        this.userRightsService.getPermission(FormRightsEnum.FORMS_FILL)
      ) {
        return resolve(true);
      }

      if (formsFeature && this.userRightsService.getPermission(FormRightsEnum.FORMS_ENABLED)) {
        return resolve(true);
      }
      this.logger.info()('Forms Guard: Redirecting from: ' + state.url);
      this.router.navigate(['/']);
      return resolve(false);
    });
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
