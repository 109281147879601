import {
  AdvancedFilterConcatOperatorEnum,
  AdvancedFilterQuery,
} from '@remberg/advanced-filters/common/main';

export function getEmptyAdvancedFilterQuery<T extends string>(): AdvancedFilterQuery<T> {
  return {
    concatOperator: AdvancedFilterConcatOperatorEnum.AND,
    filters: [],
  };
}
