import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ADMIN_SUBDOMAIN, LanguageCodeEnum } from '@remberg/global/common/core';
import {
  LocalStorageKeys,
  SUBDOMAIN_REGEX,
  environment,
  getLocalBaseUrl,
} from '@remberg/global/ui';
import {
  AppStateService,
  GlobalActions,
  GlobalSelectors,
  LanguageService,
  NavigationalActions,
  RootGlobalState,
  RouterSelectors,
  ServerConfigurationService,
  TenantService,
} from '@remberg/ui-core/core';
import { Observable, firstValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-tenant-login',
  templateUrl: './tenant-login.component.html',
  styleUrls: ['./tenant-login.component.scss'],
})
export class TenantLoginComponent {
  protected tenantLoginFormControl = new FormControl<string>('', [
    Validators.required,
    Validators.pattern(SUBDOMAIN_REGEX),
  ]);
  protected isProcessingDomain: boolean = false;
  protected readonly availableServers: string[];
  protected isIonicSimulationModeDisplayed = !environment.production && !isPlatform('capacitor');
  protected readonly isIonic$ = this.store.select(GlobalSelectors.selectIsIonic);
  private applicationRootDomain?: string;

  protected transtations = {
    next: $localize`:@@next:Next`,
  };

  constructor(
    private readonly tenantService: TenantService,
    private readonly languageService: LanguageService,
    private readonly serverService: ServerConfigurationService,
    private readonly router: Router,
    private readonly appState: AppStateService,
    private readonly store: Store<RootGlobalState>,
  ) {
    this.availableServers = this.serverService.getAvailableServerOptions();
    this.store
      .select(GlobalSelectors.selectApplicationRootDomain)
      .pipe(take(1))
      .subscribe((domain) => {
        this.applicationRootDomain = domain;
      });
  }

  protected async onSubdomainSubmitted(): Promise<void> {
    const subdomain = this.tenantLoginFormControl.value?.toLowerCase().trim();
    if (!subdomain) {
      this.tenantLoginFormControl.setErrors({ required: true });
      return;
    }
    this.isProcessingDomain = true;

    if (subdomain === ADMIN_SUBDOMAIN) {
      return await this.handleAdminLogin();
    }

    try {
      const tenant = await firstValueFrom(this.tenantService.getPublicBySubdomain(subdomain));
      const isSubdomainRoutingActive = await firstValueFrom(
        this.store.select(GlobalSelectors.selectIsSubdomainRoutingActive),
      );
      if (!isSubdomainRoutingActive) {
        this.store.dispatch(GlobalActions.setTenantPublic({ tenantPublic: tenant }));
        await this.appState.setValue(LocalStorageKeys.SUBDOMAIN, subdomain);
        // Wojciech: basic support for testing /portal/ features on preview env
        const isPortal = await firstValueFrom(
          this.store.select(RouterSelectors.selectIsPortalRoute),
        );
        if (isPortal) {
          await this.router.navigate(['/portal/login']);
        } else {
          await this.router.navigate(['login']);
        }
        return;
      } else {
        const url = new URL(window.location.href);
        const targetUrl = url.searchParams.get('target') ?? undefined;
        this.store.dispatch(
          NavigationalActions.goToTenantLoginAtSubdomain({ subdomain, targetUrl }),
        );
      }
    } catch (error) {
      this.tenantLoginFormControl.setErrors({ invalidSubdomain: true });
    }
    this.isProcessingDomain = false;
  }

  protected get demoUrl(): string {
    const currentLang = this.languageService.getCurrentLanguage();
    switch (currentLang) {
      case LanguageCodeEnum.DE:
        return 'https://www.remberg.de/beratungstermin-vereinbaren/';
      case LanguageCodeEnum.EN_US:
        return 'https://www.remberg.de/en/arrange-consultation/';
      default:
        return 'https://www.remberg.de/en/arrange-consultation/';
    }
  }

  protected get domainHelpUrl(): string {
    const currentLang = this.languageService.getCurrentLanguage();
    switch (currentLang) {
      case LanguageCodeEnum.DE:
        return 'https://help.remberg.de/de/articles/30478-wo-finde-ich-den-domainnamen-meines-unternehmens';
      case LanguageCodeEnum.EN_US:
        return 'https://help.remberg.de/en/articles/30478-where-can-i-find-the-domain-name-of-my-company';
      default:
        return 'https://help.remberg.de/en/articles/30478-where-can-i-find-the-domain-name-of-my-company';
    }
  }

  protected get mainDomainUrl(): string {
    return this.serverService.currentServerUrl
      ? this.serverService.currentServerUrl.replace('https://', '')
      : (this.applicationRootDomain ?? getLocalBaseUrl());
  }

  private async handleAdminLogin(): Promise<void> {
    await this.appState.setValue(LocalStorageKeys.SUBDOMAIN, ADMIN_SUBDOMAIN);
    const isSubdomainRoutingActive = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsSubdomainRoutingActive),
    );
    if (!isSubdomainRoutingActive) {
      await this.router.navigate(['login']);
      this.isProcessingDomain = false;
      return;
    } else {
      this.store.dispatch(
        NavigationalActions.goToTenantLoginAtSubdomain({ subdomain: ADMIN_SUBDOMAIN }),
      );
      this.isProcessingDomain = false;
      return;
    }
  }

  protected get isServerPickerDisplayed$(): Observable<boolean> {
    return this.serverService.showDebugInformation$.asObservable();
  }
}
