import { Directory } from '@capacitor/filesystem';
import { VERSION } from '@remberg/global/common/version';
import { IntercomConfig } from '@supy-io/ngx-intercom';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { GlobalConfig } from 'ngx-toastr';

export const acceptedImages = 'image/png, image/tiff, image/jpeg, image/webp';
export const acceptedImagesWithGif = 'image/png, image/tiff, image/jpeg, image/gif, image/webp';

export const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: '/files/drive', // TODO: make this the final default URL for file upload
  maxFilesize: 1024, // 1 GB limit per default via dropzone.
  timeout: 3600000, // 60 min timeout instead of 5m instead of 30s
  // Commented out to accept all file types in the old remberg drive.
  /* acceptedFiles: 'image/*, video/*, audio/*, application/pdf, .docx, .doc, .xlsc, \
                    .xlsx, .xlr, .xls, .pptx, .ppt, .pps .txt, .rtf, .ai, .csv, .xml, \
                    .txt, .fff, .zip, .factory, .stl, .gcode, .html, .htm, .msg, .zap, \
                    .ecu, .eol, .7z, .dwg, .dxf, .fsp, .projectarchive, .zap17, .bct, \
                    .ics, .isc, .eml, .stp', */
  // Customer Requests for: .fff, .zip, .pdf, .html, .htm, .gcode, .msg, .zap, .ecu, .eol, .7z, .dwg, .dxf,
  // .fsp, .projectarchive, .zap17, .bct, .ics, .isc, .eml, .stp
  headers: {
    'Remberg-Version': VERSION,
  },
};

/** Only used for form file upload component now. */
export const DEFAULT_MAX_FILE_SIZE = 15; // 15 MB
export const DEFAULT_MAX_ACCUMULATED_FILE_SIZE = 50; // 50 MB

export const MAX_LIST_ITEMS_IN_COLLAPSED_VIEW = 3;

export const TOASTR_CONFIG: GlobalConfig = {
  maxOpened: 3,
  autoDismiss: false,
  iconClasses: {},
  preventDuplicates: true,
  countDuplicates: false,
  resetTimeoutOnDuplicate: true,
  includeTitleDuplicates: false,
  messageClass: 'toast-message',
  titleClass: 'toast-title',
  positionClass: 'toast-bottom-right',
  toastClass: 'ngx-toastr',
  timeOut: 4000,
  disableTimeOut: false,
  closeButton: true,
  extendedTimeOut: 1000,
  easeTime: 300,
  progressBar: true,
  progressAnimation: 'increasing',
  enableHtml: false,
  easing: 'ease-in',
  tapToDismiss: true,
  onActivateTick: true,
  newestOnTop: true,
  payload: null,
};

/**
 * Wojciech:
 * Even though @supy-io/ngx-intercom src/app/ng-intercom/intercom.module.ts forRoot method expects IntercomConfigObject and not IntercomConfig,
 * the object passed to forRoot method is directly used in underlying Intercom JS SDK as window.intercomSettings
 * which happens in @supy-io/ngx-intercom src/app/ng-intercom/intercom/intercom.ts , loadIntercom method.
 *
 * If we actually use any of IntercomConfigObject properties, when we later call Intercom method 'boot', user data will not come through properly
 * on first login. For this reason the below config needs to stay like this and Intercom 'ping' on navigation is implemented separately.
 */
export const INTERCOM_CONFIG: IntercomConfig = {
  app_id: 'kc3y53va',
  api_base: 'https://api-iam.eu.intercom.io',
};

export const DEFAULT_MAP_LOCATION = {
  DEFAULT_LAT: 48.137154 as number,
  DEFAULT_LNG: 11.576124 as number,
  DEFAULT_CENTER_OFFSET: 0 as number,
};

export const UPDATE_CHECK_INTERVAL = 1 * 60 * 1000; // every minute
export const EMAIL_STATUS_CHECK_INTERVAL = 5 * 1000; // every 5 seconds
export const ServiceWorkerUpdateInterval = 3 * 60 * 1000; // 3 minutes

// REGEX
export const EMAIL_VALIDATION_REGEX: RegExp = new RegExp(
  // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&' * +/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
  'i',
);
export const WHITE_REGEX: RegExp = /^\s$/;
export const INTEGER_REGEX: RegExp = /^[0-9]\d*$/; // Value is a positive integer
export const NON_NULL_INTEGER_REGEX: RegExp = /^[1-9]\d*$/; // Value is a non null positive integer
export const DECIMAL_REGEX: RegExp = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/; // Value is a postive decimal number
export const NUMBER_REGEX: RegExp = /^[0-9]\d*([.,]\d+)?$/; // Value is a positive number (decimal or integer with , or . )
export const TIME_REGEX: RegExp = /^(?:\d|[01]\d|2[0-3]):[0-5]\d$/; //
export const WEB_REGEX: RegExp = new RegExp(
  '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
);
export const HEX_REGEX: RegExp = /^#([0-9a-f]{3}){1,2}$/i;
export const DATE_REGEX: RegExp = /^\d{4}-\d{2}-\d{2}$/; // Value is in format: "yyyy-MM-dd"
export const ISO_DATE_REGEX: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/; // Value is in format: "yyyy-MM-ddTHH:mm:ss.SSSZ"
export const SUBDOMAIN_REGEX: RegExp = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/; // https://stackoverflow.com/a/7933253/815382
export const NO_LEADING_SPACE = /^[^\s]/;
export const SIMPLIFIED_EMAIL_REGEX: RegExp = new RegExp(
  /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g,
);

/**
 *  For numbers: 123
 *  Decimals in format: 9,999,999.99 OR 9.999.99999
 *  Decimals without hundreds separators: 999999,5 or 999999.5
 */
export const NUMBER_FORMATTED_REGEX: RegExp =
  /^\d{1,3}(((,\d{3})*(\.\d{0,2})?$)|((\.\d{3})*(,\d{0,2})?$)|(\d*([,.]\d{0,2})?$))/;

export const IDBPrefetchCacheDuration = 1000 * 60 * 60 * 6; // 6 hours
export const IDBPrefetchCheckDuration = 1000 * 15; // every 15 minutes
export const IDBPrefetchChunkSize = 1000;
export const IDBPrefetchChunkSizeAssets = 500;
export const IDBPrefetchChunkSizeForms = 25;
export const IDBPrefetchMaxRetry = 3;
export const SYNC_REMINDER_LIMIT = 1000 * 60 * 60 * 24; // 24h
export const SYNC_CHECK_INTERVAL = 1000 * 60 * 60; // 1h
export const SYNC_MAX_RETRIES_ATTEMPTS = 3;

export const MAXIMUM_DB_COUNT_LIMIT = 100000;

export const MAXIMUM_TEXTAREA_CHARACTERS = 99999;

export const MINIMUM_ITEMS_FOR_PAGINATION = 5;
export const DEFAULT_PAGESIZE = 20;
export const DEFAULT_TAB_PAGESIZE = 10;
export const DEFAULT_MAP_PAGESIZE = 500;
export const DEFAULT_ASSOCIATIONS_PAGESIZE = 3;
export const DEFAULT_PAGESIZE_OPTIONS: number[] = [20, 50, 100];

export const WORK_ORDER_MAXIMUM_ESTIMATED_DURATION = 86400000000;

export const FILE_SYNC_DIRECTORY = 'file-sync';

export enum DialogType {
  SortingDialog = 'SortingDialog',
  FilteringDialog = 'FilteringDialog',
}

export enum SortingKeys {
  NoSelection = 'noSelection',
  SerialNumber = 'serialNumber',
  Status = 'status',
  LastUpdated = 'lastUpdated',
}

export const MOBILE_LOG_DIRECTORY = Directory.Data;

export const DEFAULT_DIALOG_WIDTH = '350px';

export const SEARCH_DEBOUNCE_TIME = 500;

export const UPDATE_DEBOUNCE_TIME = 800;

export const SCROLL_LOADING_DEBOUNCE_TIME = 500;

export const IMAGE_FALLBACK_URL = {
  ai_wand: '/assets/img/placeholder/ai_wand.svg',
  user: '/assets/img/placeholder/user.svg',
  user_transparent: '/assets/img/placeholder/user_transparent.svg',
  contact: '/assets/img/placeholder/contact.svg',
  contact_transparent: '/assets/img/placeholder/contact_transparent.svg',
  user_group: '/assets/img/placeholder/user_group.svg',
  user_group_transparent: '/assets/img/placeholder/user_group_transparent.svg',
  asset: '/assets/img/placeholder/asset.svg',
  asset_transparent: '/assets/img/placeholder/asset_transparent.svg',
  case: '/assets/img/placeholder/case.svg',
  company: '/assets/img/placeholder/company.svg',
  company_transparent: '/assets/img/placeholder/company_transparent.svg',
  organization: '/assets/img/placeholder/organization.svg',
  organization_transparent: '/assets/img/placeholder/organization_transparent.svg',
  form: '/assets/img/placeholder/form.svg',
  assetType: '/assets/img/placeholder/asset_type.svg',
  assetType_transparent: '/assets/img/placeholder/asset_type_transparent.svg',
  file: '/assets/img/placeholder/file.svg',
  file_transparent: '/assets/img/placeholder/file_transparent.svg',
  appointment: '/assets/img/placeholder/appointment.svg',
  appointment_transparent: '/assets/img/placeholder/appointment_transparent.svg',
  part: '/assets/img/placeholder/part.svg',
  workorder: '/assets/img/placeholder/workorder.svg',
  unknown: '/assets/img/placeholder/unknown.svg',
  unknown_transparent: '/assets/img/placeholder/unknown_transparent.svg',
  image: '/assets/img/placeholder/image.svg',
  image_transparent: '/assets/img/placeholder/image_transparent.svg',
};

/** HTML added before adding the signature */
export const SIGNATURE_NEW_LINES = '<br><br><br>';
export const HISTORY_DIVIDER = '<br><br>';

export const DEFAULT_TIME_PICKER_INTERVAL = 30; // in minutes

// escaping of special chars is required because <input [pattern]> attr adds /v flag to regex
export const ALLOWED_SPECIAL_CHAR_PHONE_NUMBER = '\\(,\\),\\/, ,\\-';
export const POPOVER_DELAY = 500; // ms

// Time constants
export const MIN_HOURS_OR_MINUTES = 0;
export const MAX_MINUTES_PER_HOUR = 59;

// Hard coded feature flags
export const DISABLE_OR_CONCAT_OPERATOR_FLAG = false;

// Forms PDF default file name
export const DEFAULT_PDF_FILE_NAME = 'attachment.pdf';

// Table Paginator
export const MIN_PAGINATOR_COUNT = 5;

export const API_URL_PLACEHOLDER = '{apiUrl}';
