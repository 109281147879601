import { VisualizationTypeEnum } from '@remberg/global/common/core';

export type VisualizationTypes = { [key in VisualizationTypeEnum]?: VisualizationTypeOption };

export type VisualizationTypeOption = {
  label: string;
  icon: string;
};

export const VISUALIZATION_TYPES = {
  [VisualizationTypeEnum.TABLE]: {
    label: $localize`:@@tableView:Table View`,
    icon: 'view_headline',
  },
  [VisualizationTypeEnum.CARDS]: {
    label: $localize`:@@cardView:Card View`,
    icon: 'view_day',
  },
  [VisualizationTypeEnum.BOARD]: {
    label: $localize`:@@boardView:Board View`,
    icon: 'view_week',
  },
  [VisualizationTypeEnum.SPLIT]: {
    label: $localize`:@@splitView:Split View`,
    icon: 'vertical_split',
  },
  [VisualizationTypeEnum.MAP]: {
    label: $localize`:@@mapView:Map View`,
    icon: 'map',
  },
  [VisualizationTypeEnum.HIERARCHY_TABLE]: {
    label: $localize`:@@hierarchyView:Hierarchy View`,
    icon: 'account_tree',
  },
  [VisualizationTypeEnum.LIST]: {
    label: $localize`:@@listView:List View`,
    icon: 'view_headline',
  },
};

export function validateVisualizationType(
  visualizationType: VisualizationTypeEnum,
  visualizationTypes: VisualizationTypes,
): VisualizationTypeEnum | undefined {
  return visualizationTypes[visualizationType] ? visualizationType : undefined;
}
