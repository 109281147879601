import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { RootState } from './core-ui.definitions';
import { globalReducer } from './global';
import { logoutMetareducer } from './logout.metareducer';

const reducers: ActionReducerMap<RootState> = {
  router: routerReducer,
  global: globalReducer,
};

export const metaReducers: MetaReducer<RootState>[] = [logoutMetareducer];

export const allReducers = { ...routerReducer, ...reducers };
