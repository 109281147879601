import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
  getStringID,
} from '@remberg/global/ui';
import {
  UserGroup,
  UserGroupCreateOneBody,
  UserGroupUpdateOneBody,
} from '@remberg/users/common/main';
import { Observable, from } from 'rxjs';
import { UserGroupOfflineService } from '../offline/userGroup.offline.service';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  private readonly USERGROUPS_URL = `${API_URL_PLACEHOLDER}/usergroups/v1`;

  constructor(
    private http: HttpClient,
    private logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private userGroupOfflineService: UserGroupOfflineService,
  ) {}

  public getGroupsByTenant(
    tenantId?: string,
    search: string = '',
    ownGroupsOnly?: boolean,
  ): Observable<UserGroup[]> {
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Online user group instances request...');
      let params = new HttpParams();
      if (search) {
        params = params.set('search', String(search));
      }
      if (tenantId) {
        params = params.set('tenantId', tenantId);
      }
      if (ownGroupsOnly) {
        params = params.set('ownGroupsOnly', 'true');
      }
      return this.http.get<UserGroup[]>(this.USERGROUPS_URL, { params: params });
    } else {
      this.logger.debug()('Offline user group instances request fallback...');
      return from(this.userGroupOfflineService.getUserGroupsByTenant(search, ownGroupsOnly));
    }
  }

  public addGroup(group: UserGroupCreateOneBody): Observable<UserGroup> {
    return this.http.post<UserGroup>(this.USERGROUPS_URL, group);
  }

  public updateGroupByID(id: string, body: UserGroupUpdateOneBody): Observable<void> {
    return this.http.patch<void>(`${this.USERGROUPS_URL}/${id}`, body);
  }

  public getGroupByID(group: UserGroup | string): Observable<UserGroup> {
    return this.http.get<UserGroup>(`${this.USERGROUPS_URL}/${getStringID(group)}`);
  }

  public deleteGroup(group: UserGroup): Observable<void> {
    return this.http.delete<void>(`${this.USERGROUPS_URL}/${group._id}`);
  }

  public getManyByIds(ids: string[]): Observable<UserGroup[]> {
    return this.http.put<UserGroup[]>(`${this.USERGROUPS_URL}/sync`, { ids });
  }
}
