import { AssetBasic, ProductType } from '@remberg/assets/common/base';
import { Product } from '../definitions';

export function productToAssetBasic(product: Product): AssetBasic;
export function productToAssetBasic(product: Product | undefined): AssetBasic | undefined {
  return product
    ? {
        _id: getStringID(product),
        serialNumber: product.serialNumber,
        assetTypeId: getStringID(product.productType),
        assetTypeName: product.productTypeName,
        assetTypeImageId: (product.productType as ProductType)?.image,
        customerId: product.customerOrganization && getStringID(product.customerOrganization),
      }
    : undefined;
}

function getStringID(obj: string | { _id?: string; id?: string }): string {
  if (typeof obj === 'string') {
    return obj; // Return obj if it's already a string
  } else {
    // Return _id if available, otherwise return id
    return obj._id || obj.id || '';
  }
}
