import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedDialogTypeEnum } from '@remberg/global/ui';
import { ServerConfigurationService } from '@remberg/ui-core/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, firstValueFrom } from 'rxjs';
import {
  PreviewServerInputDialogComponent,
  PreviewServerInputDialogData,
  PreviewServerInputDialogResult,
} from '../preview-server-input-dialog/preview-server-input-dialog.component';

@Component({
  selector: 'app-server-picker',
  templateUrl: './server-picker.component.html',
  styleUrls: ['./server-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerPickerComponent {
  protected readonly availableServers: string[];

  constructor(
    private serverService: ServerConfigurationService,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {
    this.availableServers = this.serverService.getAvailableServerOptions();
  }

  protected async onSelectServer(server: string): Promise<void> {
    let previewServerURL: string | undefined = undefined;

    // handle special case for preview env selection
    if (server === 'preview') {
      previewServerURL = (await this.openPreviewServerInputDialog())?.previewServerURL;

      // when dialog got canceled
      if (!previewServerURL) {
        return;
      }
    }

    this.cdr.markForCheck();

    // otherwise set server directly
    this.serverService.setServerUrlOnSelection(server, previewServerURL);
    this.toastr.success('Server was updated.', 'Success');
  }

  private async openPreviewServerInputDialog(): Promise<
    PreviewServerInputDialogResult | undefined
  > {
    const dialogConfig: PreviewServerInputDialogData = {
      dialogId: SharedDialogTypeEnum.PreviewServerInput,
      confirmButtonText: $localize`:@@confirm:Confirm`,
      cancelButtonText: $localize`:@@cancel:Cancel`,
      headerText: $localize`:@@previewEnvironmentNumber:Preview environment number`,
      contentText: $localize`:@@toAccessThePreviewEnvironmentEnterTheRespectiveNumberBelow:To access the preview environment, enter the respective number below.`,
      headerIcon: 'info_outline',
    };

    const dialogRef = this.matDialog.open<
      PreviewServerInputDialogComponent,
      PreviewServerInputDialogData,
      PreviewServerInputDialogResult
    >(PreviewServerInputDialogComponent, {
      data: dialogConfig,
      panelClass: ['confirmation-dialog', 'info-dialog'],
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  protected get currentServerUrl(): string {
    return this.serverService.currentServerUrl;
  }

  protected get currentServerName(): string {
    return this.serverService.currentServerName;
  }

  protected get isServerPickerDisplayed$(): Observable<boolean> {
    return this.serverService.showDebugInformation$.asObservable();
  }
}
