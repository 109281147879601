import { Pipe, PipeTransform } from '@angular/core';
import { RembergDate, RembergTimestamp } from '@remberg/global/common/core';
import { DatetimeService, toRembergDate } from '@remberg/ui-core/core';

@Pipe({
  name: 'toRembergDate',
})
export class ToRembergDatePipe implements PipeTransform {
  constructor(private readonly datetimeService: DatetimeService) {}

  public transform(value: RembergTimestamp): RembergDate;
  public transform(value: Date): RembergDate;
  public transform(value: Date | undefined): RembergDate | undefined;
  public transform(value: undefined): undefined;
  public transform(value: RembergTimestamp | undefined): RembergDate | undefined;
  public transform(
    value: RembergTimestamp | Date | null | undefined | string,
  ): RembergDate | undefined {
    if (!value) return undefined;

    if (typeof value === 'string') {
      const tz = this.datetimeService.getUserTimezone();
      return toRembergDate(new Date(value), tz);
    }
    return toRembergDate(value);
  }
}
