import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DEFAULT_DIALOG_WIDTH, LogService } from '@remberg/global/ui';

import { parseAuthTokenPayload } from '@remberg/users/common/main';
import {
  BehaviorSubject,
  EMPTY,
  firstValueFrom,
  from,
  Observable,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { DialogOptions } from '../dialogs/dialogs';
import { TokenRefreshPopupComponent } from '../dialogs/token-refresh-popup/token-refresh-popup.component';
import { DialogService } from '../services/dialog.service';
import { GlobalActions, GlobalSelectors, RootGlobalState } from '../store';

@Injectable()
export class JwtInterceptor implements HttpInterceptor, OnDestroy {
  private refreshInProgress: boolean = false;
  private tokenRefreshedSource = new Subject<void>();
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  private readonly token$ = new BehaviorSubject<string | undefined>(undefined);
  private readonly hasUserChosenToLogout$ = new BehaviorSubject<boolean | undefined>(undefined);
  private readonly isXSmallView$ = new BehaviorSubject<boolean | undefined>(undefined);

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store<RootGlobalState>,
    private readonly router: Router,
    private readonly logger: LogService,
    private readonly _dialog: DialogService,
  ) {
    this.subscriptions.add(this.store.select(GlobalSelectors.selectToken).subscribe(this.token$));
    this.subscriptions.add(
      this.store
        .select(GlobalSelectors.selectHasUserChosenToLogout)
        .subscribe(this.hasUserChosenToLogout$),
    );
    this.subscriptions.add(
      this.store.select(GlobalSelectors.selectIsXSmallView).subscribe(this.isXSmallView$),
    );
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
          // if coming via QR code - redirect to public page
          if (this.router.url.startsWith('/link')) {
            this.router.navigateByUrl('/public/assets/detail/' + this.router.url.substr(6));

            return EMPTY;
          }

          // if there is a token but it's expired,
          // and we are not trying to logout clicking on logout menu
          // ^ which resets the isAuthenticated flag before any requests are made to avoid race condition with any outstanding requests
          // show the relogin-dialog
          const token = this.token$.getValue();
          const hasUserChosenToLogout = this.hasUserChosenToLogout$.getValue();

          if (token && !hasUserChosenToLogout) {
            return this.handleTokenRefresh(token).pipe(
              withLatestFrom(this.store.select(GlobalSelectors.selectOutgoingRequestHeaders)),
              switchMap(([success, headers]) => {
                if (success) {
                  request = request.clone({
                    setHeaders: headers,
                  });
                  return this.intercept(request, next);
                } else {
                  return throwError(error);
                }
              }),
            );
          }
        }

        return throwError(error);
      }),
    );
  }

  private handleTokenRefresh(token: string): Observable<boolean> {
    if (this.refreshInProgress) {
      // if refresh is already in progress, just return an observable that waits for
      // the refresh process to finish:
      return new Observable<boolean>((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      // if no refresh process is ongoing, open a popup to enter the credentials and fetch a new token
      this.refreshInProgress = true;
      const tokenPayload = parseAuthTokenPayload(token);
      const dialogOpts: DialogOptions<TokenRefreshPopupComponent> = {
        childComponent: TokenRefreshPopupComponent,
        dialogBackdropCloseDisabled: true,
        dialogData: {
          wrapperInput: {
            headerShow: true,
            headerCloseActionShow: false,
            headerTitle: $localize`:@@sessionExpired:Session Expired`,
            styleMinWidth: this.isXSmallView$.getValue() ? undefined : DEFAULT_DIALOG_WIDTH,
            styleMaxWidth: this.isXSmallView$.getValue() ? undefined : '500px',
            styleWidth: DEFAULT_DIALOG_WIDTH,
            styleHeight: 'auto',
          },
          factoryInput: [{ email: tokenPayload.email }, { tenantId: tokenPayload.tenantId }],
        },
      };
      return from(
        this._dialog
          .showDialogOrModal<TokenRefreshPopupComponent>(dialogOpts, false)
          .waitForCloseData()
          .then(async (data) => {
            const dialogResult = data?.data;

            if (dialogResult) {
              this.store.dispatch(GlobalActions.tokenRefreshed({ token: dialogResult.token }));
              await firstValueFrom(
                this.token$.pipe(filter((token) => token === dialogResult.token)),
              );
              this.refreshInProgress = false;
              this.tokenRefreshedSource.next();
              return true;
            } else {
              this.logger.warn()('User chose logout instead of token renewal!');
              // Close all dialogs and modals
              this._dialog.closeAll();
              this.store.dispatch(
                GlobalActions.logoutConfirmedInvalidToken({ targetUrl: this.router.url }),
              );
              await firstValueFrom(
                this.store
                  .select(GlobalSelectors.selectHasUserChosenToLogout)
                  .pipe(filter(Boolean)),
              );
              this.refreshInProgress = false;
              return false;
            }
          }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
