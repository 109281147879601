import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RembergTime } from '@remberg/global/common/core';

@Component({
  selector: 'app-time-display',
  templateUrl: './time-display.component.html',
  styleUrls: ['./time-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeDisplayComponent {
  @Input() value?: RembergTime;
  @Input() label: string = $localize`:@@time:Time`;
  @Input() required: boolean = false;
  @Input() showInline: boolean = false;
  @Input() showInForms: boolean = false;
  @Input() protected isTooltipVisible = false;
}
