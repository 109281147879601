import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RembergDate } from '@remberg/global/common/core';

@Component({
  selector: 'app-date-display',
  templateUrl: './date-display.component.html',
  styleUrls: ['./date-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateDisplayComponent {
  @Input() public value?: RembergDate;
  @Input() public label = $localize`:@@date:Date`;
  @Input() public required = false;
  @Input() public showInline = false;
  @Input() public showInForms = false;
  @Input() public isTooltipVisible = false;
}
