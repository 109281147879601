import { QRCode } from '@remberg/assets/common/base';
import { QRCodeLegacy } from '../definitions';
import { createMapper } from './create-mapper';

function mapQRCodeToLegacyOne(qrCode: QRCode): QRCodeLegacy {
  return {
    _id: qrCode._id,
    linkId: qrCode.linkId,
    target: qrCode.targetId,
    tenantId: qrCode.tenantId,
    targetType: 'asset',
    creationDate: qrCode.createdAt,
    assignmentDate: qrCode.assignedAt,
    scanCount: qrCode.scanCount,
    downloaded: qrCode.isDownloaded,
    image: qrCode.image,
    tenantLabel: qrCode.tenantLabel,
  };
}

export const mapToQRCodeLegacy = createMapper(mapQRCodeToLegacyOne);
