import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneEnum } from '@remberg/global/common/core';

export const TIMEZONE_ENUM_TRANSLATIONS: Record<TimeZoneEnum, string> = {
  'Africa/Algiers': $localize`:@@Africa/Algiers:Africa/Algiers`,
  'Atlantic/Cape_Verde': $localize`:@@Atlantic/Cape_Verde:Atlantic/Cape_Verde`,
  'Africa/Ndjamena': $localize`:@@Africa/Ndjamena:Africa/Ndjamena`,
  'Africa/Abidjan': $localize`:@@Africa/Abidjan:Africa/Abidjan`,
  'Africa/Bamako': $localize`:@@Africa/Bamako:Africa/Bamako`,
  'Africa/Banjul': $localize`:@@Africa/Banjul:Africa/Banjul`,
  'Africa/Conakry': $localize`:@@Africa/Conakry:Africa/Conakry`,
  'Africa/Dakar': $localize`:@@Africa/Dakar:Africa/Dakar`,
  'Africa/Freetown': $localize`:@@Africa/Freetown:Africa/Freetown`,
  'Africa/Lome': $localize`:@@Africa/Lome:Africa/Lome`,
  'Africa/Nouakchott': $localize`:@@Africa/Nouakchott:Africa/Nouakchott`,
  'Africa/Ouagadougou': $localize`:@@Africa/Ouagadougou:Africa/Ouagadougou`,
  'Atlantic/St_Helena': $localize`:@@Atlantic/St_Helena:Atlantic/St_Helena`,
  'Africa/Cairo': $localize`:@@Africa/Cairo:Africa/Cairo`,
  'Africa/Accra': $localize`:@@Africa/Accra:Africa/Accra`,
  'Africa/Bissau': $localize`:@@Africa/Bissau:Africa/Bissau`,
  'Africa/Nairobi': $localize`:@@Africa/Nairobi:Africa/Nairobi`,
  'Africa/Addis_Ababa': $localize`:@@Africa/Addis_Ababa:Africa/Addis_Ababa`,
  'Africa/Asmara': $localize`:@@Africa/Asmara:Africa/Asmara`,
  'Africa/Dar_es_Salaam': $localize`:@@Africa/Dar_es_Salaam:Africa/Dar_es_Salaam`,
  'Africa/Djibouti': $localize`:@@Africa/Djibouti:Africa/Djibouti`,
  'Africa/Kampala': $localize`:@@Africa/Kampala:Africa/Kampala`,
  'Africa/Mogadishu': $localize`:@@Africa/Mogadishu:Africa/Mogadishu`,
  'Indian/Antananarivo': $localize`:@@Indian/Antananarivo:Indian/Antananarivo`,
  'Indian/Comoro': $localize`:@@Indian/Comoro:Indian/Comoro`,
  'Indian/Mayotte': $localize`:@@Indian/Mayotte:Indian/Mayotte`,
  'Africa/Monrovia': $localize`:@@Africa/Monrovia:Africa/Monrovia`,
  'Africa/Tripoli': $localize`:@@Africa/Tripoli:Africa/Tripoli`,
  'Indian/Mauritius': $localize`:@@Indian/Mauritius:Indian/Mauritius`,
  'Africa/Casablanca': $localize`:@@Africa/Casablanca:Africa/Casablanca`,
  'Africa/El_Aaiun': $localize`:@@Africa/El_Aaiun:Africa/El_Aaiun`,
  'Africa/Maputo': $localize`:@@Africa/Maputo:Africa/Maputo`,
  'Africa/Blantyre': $localize`:@@Africa/Blantyre:Africa/Blantyre`,
  'Africa/Bujumbura': $localize`:@@Africa/Bujumbura:Africa/Bujumbura`,
  'Africa/Gaborone': $localize`:@@Africa/Gaborone:Africa/Gaborone`,
  'Africa/Harare': $localize`:@@Africa/Harare:Africa/Harare`,
  'Africa/Kigali': $localize`:@@Africa/Kigali:Africa/Kigali`,
  'Africa/Lubumbashi': $localize`:@@Africa/Lubumbashi:Africa/Lubumbashi`,
  'Africa/Lusaka': $localize`:@@Africa/Lusaka:Africa/Lusaka`,
  'Africa/Windhoek': $localize`:@@Africa/Windhoek:Africa/Windhoek`,
  'Africa/Lagos': $localize`:@@Africa/Lagos:Africa/Lagos`,
  'Africa/Bangui': $localize`:@@Africa/Bangui:Africa/Bangui`,
  'Africa/Brazzaville': $localize`:@@Africa/Brazzaville:Africa/Brazzaville`,
  'Africa/Douala': $localize`:@@Africa/Douala:Africa/Douala`,
  'Africa/Kinshasa': $localize`:@@Africa/Kinshasa:Africa/Kinshasa`,
  'Africa/Libreville': $localize`:@@Africa/Libreville:Africa/Libreville`,
  'Africa/Luanda': $localize`:@@Africa/Luanda:Africa/Luanda`,
  'Africa/Malabo': $localize`:@@Africa/Malabo:Africa/Malabo`,
  'Africa/Niamey': $localize`:@@Africa/Niamey:Africa/Niamey`,
  'Africa/Porto-Novo': $localize`:@@Africa/Porto-Novo:Africa/Porto-Novo`,
  'Indian/Reunion': $localize`:@@Indian/Reunion:Indian/Reunion`,
  'Africa/Sao_Tome': $localize`:@@Africa/Sao_Tome:Africa/Sao_Tome`,
  'Indian/Mahe': $localize`:@@Indian/Mahe:Indian/Mahe`,
  'Africa/Johannesburg': $localize`:@@Africa/Johannesburg:Africa/Johannesburg`,
  'Africa/Maseru': $localize`:@@Africa/Maseru:Africa/Maseru`,
  'Africa/Mbabane': $localize`:@@Africa/Mbabane:Africa/Mbabane`,
  'Africa/Khartoum': $localize`:@@Africa/Khartoum:Africa/Khartoum`,
  'Africa/Juba': $localize`:@@Africa/Juba:Africa/Juba`,
  'Africa/Tunis': $localize`:@@Africa/Tunis:Africa/Tunis`,
  'Antarctica/Casey': $localize`:@@Antarctica/Casey:Antarctica/Casey`,
  'Antarctica/Davis': $localize`:@@Antarctica/Davis:Antarctica/Davis`,
  'Antarctica/Mawson': $localize`:@@Antarctica/Mawson:Antarctica/Mawson`,
  'Indian/Kerguelen': $localize`:@@Indian/Kerguelen:Indian/Kerguelen`,
  'Antarctica/DumontDUrville': $localize`:@@Antarctica/DumontDUrville:Antarctica/DumontDUrville`,
  'Antarctica/Syowa': $localize`:@@Antarctica/Syowa:Antarctica/Syowa`,
  'Antarctica/Troll': $localize`:@@Antarctica/Troll:Antarctica/Troll`,
  'Antarctica/Vostok': $localize`:@@Antarctica/Vostok:Antarctica/Vostok`,
  'Antarctica/Rothera': $localize`:@@Antarctica/Rothera:Antarctica/Rothera`,
  'Asia/Kabul': $localize`:@@Asia/Kabul:Asia/Kabul`,
  'Asia/Yerevan': $localize`:@@Asia/Yerevan:Asia/Yerevan`,
  'Asia/Baku': $localize`:@@Asia/Baku:Asia/Baku`,
  'Asia/Dhaka': $localize`:@@Asia/Dhaka:Asia/Dhaka`,
  'Asia/Thimphu': $localize`:@@Asia/Thimphu:Asia/Thimphu`,
  'Indian/Chagos': $localize`:@@Indian/Chagos:Indian/Chagos`,
  'Asia/Brunei': $localize`:@@Asia/Brunei:Asia/Brunei`,
  'Asia/Yangon': $localize`:@@Asia/Yangon:Asia/Yangon`,
  'Asia/Shanghai': $localize`:@@Asia/Shanghai:Asia/Shanghai`,
  'Asia/Urumqi': $localize`:@@Asia/Urumqi:Asia/Urumqi`,
  'Asia/Hong_Kong': $localize`:@@Asia/Hong_Kong:Asia/Hong_Kong`,
  'Asia/Taipei': $localize`:@@Asia/Taipei:Asia/Taipei`,
  'Asia/Macau': $localize`:@@Asia/Macau:Asia/Macau`,
  'Asia/Nicosia': $localize`:@@Asia/Nicosia:Asia/Nicosia`,
  'Asia/Famagusta': $localize`:@@Asia/Famagusta:Asia/Famagusta`,
  'Europe/Nicosia': $localize`:@@Europe/Nicosia:Europe/Nicosia`,
  'Asia/Tbilisi': $localize`:@@Asia/Tbilisi:Asia/Tbilisi`,
  'Asia/Dili': $localize`:@@Asia/Dili:Asia/Dili`,
  'Asia/Kolkata': $localize`:@@Asia/Kolkata:Asia/Kolkata`,
  'Asia/Jakarta': $localize`:@@Asia/Jakarta:Asia/Jakarta`,
  'Asia/Pontianak': $localize`:@@Asia/Pontianak:Asia/Pontianak`,
  'Asia/Makassar': $localize`:@@Asia/Makassar:Asia/Makassar`,
  'Asia/Jayapura': $localize`:@@Asia/Jayapura:Asia/Jayapura`,
  'Asia/Tehran': $localize`:@@Asia/Tehran:Asia/Tehran`,
  'Asia/Baghdad': $localize`:@@Asia/Baghdad:Asia/Baghdad`,
  'Asia/Jerusalem': $localize`:@@Asia/Jerusalem:Asia/Jerusalem`,
  'Asia/Tokyo': $localize`:@@Asia/Tokyo:Asia/Tokyo`,
  'Asia/Amman': $localize`:@@Asia/Amman:Asia/Amman`,
  'Asia/Almaty': $localize`:@@Asia/Almaty:Asia/Almaty`,
  'Asia/Qyzylorda': $localize`:@@Asia/Qyzylorda:Asia/Qyzylorda`,
  'Asia/Qostanay': $localize`:@@Asia/Qostanay:Asia/Qostanay`,
  'Asia/Aqtobe': $localize`:@@Asia/Aqtobe:Asia/Aqtobe`,
  'Asia/Aqtau': $localize`:@@Asia/Aqtau:Asia/Aqtau`,
  'Asia/Atyrau': $localize`:@@Asia/Atyrau:Asia/Atyrau`,
  'Asia/Oral': $localize`:@@Asia/Oral:Asia/Oral`,
  'Asia/Bishkek': $localize`:@@Asia/Bishkek:Asia/Bishkek`,
  'Asia/Seoul': $localize`:@@Asia/Seoul:Asia/Seoul`,
  'Asia/Pyongyang': $localize`:@@Asia/Pyongyang:Asia/Pyongyang`,
  'Asia/Beirut': $localize`:@@Asia/Beirut:Asia/Beirut`,
  'Asia/Kuala_Lumpur': $localize`:@@Asia/Kuala_Lumpur:Asia/Kuala_Lumpur`,
  'Asia/Kuching': $localize`:@@Asia/Kuching:Asia/Kuching`,
  'Indian/Maldives': $localize`:@@Indian/Maldives:Indian/Maldives`,
  'Asia/Hovd': $localize`:@@Asia/Hovd:Asia/Hovd`,
  'Asia/Ulaanbaatar': $localize`:@@Asia/Ulaanbaatar:Asia/Ulaanbaatar`,
  'Asia/Choibalsan': $localize`:@@Asia/Choibalsan:Asia/Choibalsan`,
  'Asia/Kathmandu': $localize`:@@Asia/Kathmandu:Asia/Kathmandu`,
  'Asia/Karachi': $localize`:@@Asia/Karachi:Asia/Karachi`,
  'Asia/Gaza': $localize`:@@Asia/Gaza:Asia/Gaza`,
  'Asia/Hebron': $localize`:@@Asia/Hebron:Asia/Hebron`,
  'Asia/Manila': $localize`:@@Asia/Manila:Asia/Manila`,
  'Asia/Qatar': $localize`:@@Asia/Qatar:Asia/Qatar`,
  'Asia/Bahrain': $localize`:@@Asia/Bahrain:Asia/Bahrain`,
  'Asia/Riyadh': $localize`:@@Asia/Riyadh:Asia/Riyadh`,
  'Asia/Aden': $localize`:@@Asia/Aden:Asia/Aden`,
  'Asia/Kuwait': $localize`:@@Asia/Kuwait:Asia/Kuwait`,
  'Asia/Singapore': $localize`:@@Asia/Singapore:Asia/Singapore`,
  'Asia/Colombo': $localize`:@@Asia/Colombo:Asia/Colombo`,
  'Asia/Damascus': $localize`:@@Asia/Damascus:Asia/Damascus`,
  'Asia/Dushanbe': $localize`:@@Asia/Dushanbe:Asia/Dushanbe`,
  'Asia/Bangkok': $localize`:@@Asia/Bangkok:Asia/Bangkok`,
  'Asia/Phnom_Penh': $localize`:@@Asia/Phnom_Penh:Asia/Phnom_Penh`,
  'Asia/Vientiane': $localize`:@@Asia/Vientiane:Asia/Vientiane`,
  'Asia/Ashgabat': $localize`:@@Asia/Ashgabat:Asia/Ashgabat`,
  'Asia/Dubai': $localize`:@@Asia/Dubai:Asia/Dubai`,
  'Asia/Muscat': $localize`:@@Asia/Muscat:Asia/Muscat`,
  'Asia/Samarkand': $localize`:@@Asia/Samarkand:Asia/Samarkand`,
  'Asia/Tashkent': $localize`:@@Asia/Tashkent:Asia/Tashkent`,
  'Asia/Ho_Chi_Minh': $localize`:@@Asia/Ho_Chi_Minh:Asia/Ho_Chi_Minh`,
  'Australia/Darwin': $localize`:@@Australia/Darwin:Australia/Darwin`,
  'Australia/Perth': $localize`:@@Australia/Perth:Australia/Perth`,
  'Australia/Eucla': $localize`:@@Australia/Eucla:Australia/Eucla`,
  'Australia/Brisbane': $localize`:@@Australia/Brisbane:Australia/Brisbane`,
  'Australia/Lindeman': $localize`:@@Australia/Lindeman:Australia/Lindeman`,
  'Australia/Adelaide': $localize`:@@Australia/Adelaide:Australia/Adelaide`,
  'Australia/Hobart': $localize`:@@Australia/Hobart:Australia/Hobart`,
  'Australia/Currie': $localize`:@@Australia/Currie:Australia/Currie`,
  'Australia/Melbourne': $localize`:@@Australia/Melbourne:Australia/Melbourne`,
  'Australia/Sydney': $localize`:@@Australia/Sydney:Australia/Sydney`,
  'Australia/Broken_Hill': $localize`:@@Australia/Broken_Hill:Australia/Broken_Hill`,
  'Australia/Lord_Howe': $localize`:@@Australia/Lord_Howe:Australia/Lord_Howe`,
  'Antarctica/Macquarie': $localize`:@@Antarctica/Macquarie:Antarctica/Macquarie`,
  'Indian/Christmas': $localize`:@@Indian/Christmas:Indian/Christmas`,
  'Indian/Cocos': $localize`:@@Indian/Cocos:Indian/Cocos`,
  'Pacific/Fiji': $localize`:@@Pacific/Fiji:Pacific/Fiji`,
  'Pacific/Gambier': $localize`:@@Pacific/Gambier:Pacific/Gambier`,
  'Pacific/Marquesas': $localize`:@@Pacific/Marquesas:Pacific/Marquesas`,
  'Pacific/Tahiti': $localize`:@@Pacific/Tahiti:Pacific/Tahiti`,
  'Pacific/Guam': $localize`:@@Pacific/Guam:Pacific/Guam`,
  'Pacific/Saipan': $localize`:@@Pacific/Saipan:Pacific/Saipan`,
  'Pacific/Tarawa': $localize`:@@Pacific/Tarawa:Pacific/Tarawa`,
  'Pacific/Enderbury': $localize`:@@Pacific/Enderbury:Pacific/Enderbury`,
  'Pacific/Kiritimati': $localize`:@@Pacific/Kiritimati:Pacific/Kiritimati`,
  'Pacific/Majuro': $localize`:@@Pacific/Majuro:Pacific/Majuro`,
  'Pacific/Kwajalein': $localize`:@@Pacific/Kwajalein:Pacific/Kwajalein`,
  'Pacific/Chuuk': $localize`:@@Pacific/Chuuk:Pacific/Chuuk`,
  'Pacific/Pohnpei': $localize`:@@Pacific/Pohnpei:Pacific/Pohnpei`,
  'Pacific/Kosrae': $localize`:@@Pacific/Kosrae:Pacific/Kosrae`,
  'Pacific/Nauru': $localize`:@@Pacific/Nauru:Pacific/Nauru`,
  'Pacific/Noumea': $localize`:@@Pacific/Noumea:Pacific/Noumea`,
  'Pacific/Auckland': $localize`:@@Pacific/Auckland:Pacific/Auckland`,
  'Pacific/Chatham': $localize`:@@Pacific/Chatham:Pacific/Chatham`,
  'Antarctica/McMurdo': $localize`:@@Antarctica/McMurdo:Antarctica/McMurdo`,
  'Pacific/Rarotonga': $localize`:@@Pacific/Rarotonga:Pacific/Rarotonga`,
  'Pacific/Niue': $localize`:@@Pacific/Niue:Pacific/Niue`,
  'Pacific/Norfolk': $localize`:@@Pacific/Norfolk:Pacific/Norfolk`,
  'Pacific/Palau': $localize`:@@Pacific/Palau:Pacific/Palau`,
  'Pacific/Port_Moresby': $localize`:@@Pacific/Port_Moresby:Pacific/Port_Moresby`,
  'Pacific/Bougainville': $localize`:@@Pacific/Bougainville:Pacific/Bougainville`,
  'Pacific/Pitcairn': $localize`:@@Pacific/Pitcairn:Pacific/Pitcairn`,
  'Pacific/Pago_Pago': $localize`:@@Pacific/Pago_Pago:Pacific/Pago_Pago`,
  'Pacific/Midway': $localize`:@@Pacific/Midway:Pacific/Midway`,
  'Pacific/Apia': $localize`:@@Pacific/Apia:Pacific/Apia`,
  'Pacific/Guadalcanal': $localize`:@@Pacific/Guadalcanal:Pacific/Guadalcanal`,
  'Pacific/Fakaofo': $localize`:@@Pacific/Fakaofo:Pacific/Fakaofo`,
  'Pacific/Tongatapu': $localize`:@@Pacific/Tongatapu:Pacific/Tongatapu`,
  'Pacific/Funafuti': $localize`:@@Pacific/Funafuti:Pacific/Funafuti`,
  'Pacific/Wake': $localize`:@@Pacific/Wake:Pacific/Wake`,
  'Pacific/Efate': $localize`:@@Pacific/Efate:Pacific/Efate`,
  'Pacific/Wallis': $localize`:@@Pacific/Wallis:Pacific/Wallis`,
  'Africa/Asmera': $localize`:@@Africa/Asmera:Africa/Asmera`,
  'Africa/Timbuktu': $localize`:@@Africa/Timbuktu:Africa/Timbuktu`,
  'America/Argentina/ComodRivadavia': $localize`:@@America/Argentina/ComodRivadavia:America/Argentina/ComodRivadavia`,
  'America/Atka': $localize`:@@America/Atka:America/Atka`,
  'America/Buenos_Aires': $localize`:@@America/Buenos_Aires:America/Buenos_Aires`,
  'America/Catamarca': $localize`:@@America/Catamarca:America/Catamarca`,
  'America/Coral_Harbour': $localize`:@@America/Coral_Harbour:America/Coral_Harbour`,
  'America/Cordoba': $localize`:@@America/Cordoba:America/Cordoba`,
  'America/Ensenada': $localize`:@@America/Ensenada:America/Ensenada`,
  'America/Fort_Wayne': $localize`:@@America/Fort_Wayne:America/Fort_Wayne`,
  'America/Indianapolis': $localize`:@@America/Indianapolis:America/Indianapolis`,
  'America/Jujuy': $localize`:@@America/Jujuy:America/Jujuy`,
  'America/Knox_IN': $localize`:@@America/Knox_IN:America/Knox_IN`,
  'America/Louisville': $localize`:@@America/Louisville:America/Louisville`,
  'America/Mendoza': $localize`:@@America/Mendoza:America/Mendoza`,
  'America/Montreal': $localize`:@@America/Montreal:America/Montreal`,
  'America/Porto_Acre': $localize`:@@America/Porto_Acre:America/Porto_Acre`,
  'America/Rosario': $localize`:@@America/Rosario:America/Rosario`,
  'America/Santa_Isabel': $localize`:@@America/Santa_Isabel:America/Santa_Isabel`,
  'America/Shiprock': $localize`:@@America/Shiprock:America/Shiprock`,
  'America/Virgin': $localize`:@@America/Virgin:America/Virgin`,
  'Antarctica/South_Pole': $localize`:@@Antarctica/South_Pole:Antarctica/South_Pole`,
  'Asia/Ashkhabad': $localize`:@@Asia/Ashkhabad:Asia/Ashkhabad`,
  'Asia/Calcutta': $localize`:@@Asia/Calcutta:Asia/Calcutta`,
  'Asia/Chongqing': $localize`:@@Asia/Chongqing:Asia/Chongqing`,
  'Asia/Chungking': $localize`:@@Asia/Chungking:Asia/Chungking`,
  'Asia/Dacca': $localize`:@@Asia/Dacca:Asia/Dacca`,
  'Asia/Harbin': $localize`:@@Asia/Harbin:Asia/Harbin`,
  'Asia/Kashgar': $localize`:@@Asia/Kashgar:Asia/Kashgar`,
  'Asia/Katmandu': $localize`:@@Asia/Katmandu:Asia/Katmandu`,
  'Asia/Macao': $localize`:@@Asia/Macao:Asia/Macao`,
  'Asia/Rangoon': $localize`:@@Asia/Rangoon:Asia/Rangoon`,
  'Asia/Saigon': $localize`:@@Asia/Saigon:Asia/Saigon`,
  'Asia/Tel_Aviv': $localize`:@@Asia/Tel_Aviv:Asia/Tel_Aviv`,
  'Asia/Thimbu': $localize`:@@Asia/Thimbu:Asia/Thimbu`,
  'Asia/Ujung_Pandang': $localize`:@@Asia/Ujung_Pandang:Asia/Ujung_Pandang`,
  'Asia/Ulan_Bator': $localize`:@@Asia/Ulan_Bator:Asia/Ulan_Bator`,
  'Atlantic/Faeroe': $localize`:@@Atlantic/Faeroe:Atlantic/Faeroe`,
  'Atlantic/Jan_Mayen': $localize`:@@Atlantic/Jan_Mayen:Atlantic/Jan_Mayen`,
  'Australia/ACT': $localize`:@@Australia/ACT:Australia/ACT`,
  'Australia/Canberra': $localize`:@@Australia/Canberra:Australia/Canberra`,
  'Australia/LHI': $localize`:@@Australia/LHI:Australia/LHI`,
  'Australia/NSW': $localize`:@@Australia/NSW:Australia/NSW`,
  'Australia/North': $localize`:@@Australia/North:Australia/North`,
  'Australia/Queensland': $localize`:@@Australia/Queensland:Australia/Queensland`,
  'Australia/South': $localize`:@@Australia/South:Australia/South`,
  'Australia/Tasmania': $localize`:@@Australia/Tasmania:Australia/Tasmania`,
  'Australia/Victoria': $localize`:@@Australia/Victoria:Australia/Victoria`,
  'Australia/West': $localize`:@@Australia/West:Australia/West`,
  'Australia/Yancowinna': $localize`:@@Australia/Yancowinna:Australia/Yancowinna`,
  'Brazil/Acre': $localize`:@@Brazil/Acre:Brazil/Acre`,
  'Brazil/DeNoronha': $localize`:@@Brazil/DeNoronha:Brazil/DeNoronha`,
  'Brazil/East': $localize`:@@Brazil/East:Brazil/East`,
  'Brazil/West': $localize`:@@Brazil/West:Brazil/West`,
  'Canada/Atlantic': $localize`:@@Canada/Atlantic:Canada/Atlantic`,
  'Canada/Central': $localize`:@@Canada/Central:Canada/Central`,
  'Canada/Eastern': $localize`:@@Canada/Eastern:Canada/Eastern`,
  'Canada/Mountain': $localize`:@@Canada/Mountain:Canada/Mountain`,
  'Canada/Newfoundland': $localize`:@@Canada/Newfoundland:Canada/Newfoundland`,
  'Canada/Pacific': $localize`:@@Canada/Pacific:Canada/Pacific`,
  'Canada/Saskatchewan': $localize`:@@Canada/Saskatchewan:Canada/Saskatchewan`,
  'Canada/Yukon': $localize`:@@Canada/Yukon:Canada/Yukon`,
  'Chile/Continental': $localize`:@@Chile/Continental:Chile/Continental`,
  'Chile/EasterIsland': $localize`:@@Chile/EasterIsland:Chile/EasterIsland`,
  'Etc/UCT': $localize`:@@Etc/UCT:Etc/UCT`,
  'Etc/UTC': $localize`:@@Etc/UTC:Etc/UTC`,
  'Europe/Belfast': $localize`:@@Europe/Belfast:Europe/Belfast`,
  'Europe/Tiraspol': $localize`:@@Europe/Tiraspol:Europe/Tiraspol`,
  'Mexico/BajaNorte': $localize`:@@Mexico/BajaNorte:Mexico/BajaNorte`,
  'Mexico/BajaSur': $localize`:@@Mexico/BajaSur:Mexico/BajaSur`,
  'Mexico/General': $localize`:@@Mexico/General:Mexico/General`,
  'Pacific/Johnston': $localize`:@@Pacific/Johnston:Pacific/Johnston`,
  'Pacific/Ponape': $localize`:@@Pacific/Ponape:Pacific/Ponape`,
  'Pacific/Samoa': $localize`:@@Pacific/Samoa:Pacific/Samoa`,
  'Pacific/Truk': $localize`:@@Pacific/Truk:Pacific/Truk`,
  'Pacific/Yap': $localize`:@@Pacific/Yap:Pacific/Yap`,
  'US/Alaska': $localize`:@@US/Alaska:US/Alaska`,
  'US/Aleutian': $localize`:@@US/Aleutian:US/Aleutian`,
  'US/Arizona': $localize`:@@US/Arizona:US/Arizona`,
  'US/Central': $localize`:@@US/Central:US/Central`,
  'US/East-Indiana': $localize`:@@US/East-Indiana:US/East-Indiana`,
  'US/Eastern': $localize`:@@US/Eastern:US/Eastern`,
  'US/Hawaii': $localize`:@@US/Hawaii:US/Hawaii`,
  'US/Indiana-Starke': $localize`:@@US/Indiana-Starke:US/Indiana-Starke`,
  'US/Michigan': $localize`:@@US/Michigan:US/Michigan`,
  'US/Mountain': $localize`:@@US/Mountain:US/Mountain`,
  'US/Pacific': $localize`:@@US/Pacific:US/Pacific`,
  'US/Samoa': $localize`:@@US/Samoa:US/Samoa`,
  'Europe/London': $localize`:@@Europe/London:Europe/London`,
  'Europe/Jersey': $localize`:@@Europe/Jersey:Europe/Jersey`,
  'Europe/Guernsey': $localize`:@@Europe/Guernsey:Europe/Guernsey`,
  'Europe/Isle_of_Man': $localize`:@@Europe/Isle_of_Man:Europe/Isle_of_Man`,
  'Europe/Dublin': $localize`:@@Europe/Dublin:Europe/Dublin`,
  'Europe/Tirane': $localize`:@@Europe/Tirane:Europe/Tirane`,
  'Europe/Andorra': $localize`:@@Europe/Andorra:Europe/Andorra`,
  'Europe/Vienna': $localize`:@@Europe/Vienna:Europe/Vienna`,
  'Europe/Minsk': $localize`:@@Europe/Minsk:Europe/Minsk`,
  'Europe/Brussels': $localize`:@@Europe/Brussels:Europe/Brussels`,
  'Europe/Sofia': $localize`:@@Europe/Sofia:Europe/Sofia`,
  'Europe/Prague': $localize`:@@Europe/Prague:Europe/Prague`,
  'Europe/Copenhagen': $localize`:@@Europe/Copenhagen:Europe/Copenhagen`,
  'Atlantic/Faroe': $localize`:@@Atlantic/Faroe:Atlantic/Faroe`,
  'America/Danmarkshavn': $localize`:@@America/Danmarkshavn:America/Danmarkshavn`,
  'America/Scoresbysund': $localize`:@@America/Scoresbysund:America/Scoresbysund`,
  'America/Godthab': $localize`:@@America/Godthab:America/Godthab`,
  'America/Thule': $localize`:@@America/Thule:America/Thule`,
  'Europe/Tallinn': $localize`:@@Europe/Tallinn:Europe/Tallinn`,
  'Europe/Helsinki': $localize`:@@Europe/Helsinki:Europe/Helsinki`,
  'Europe/Mariehamn': $localize`:@@Europe/Mariehamn:Europe/Mariehamn`,
  'Europe/Paris': $localize`:@@Europe/Paris:Europe/Paris`,
  'Europe/Berlin': $localize`:@@Europe/Berlin:Europe/Berlin`,
  'Europe/Busingen': $localize`:@@Europe/Busingen:Europe/Busingen`,
  'Europe/Gibraltar': $localize`:@@Europe/Gibraltar:Europe/Gibraltar`,
  'Europe/Athens': $localize`:@@Europe/Athens:Europe/Athens`,
  'Europe/Budapest': $localize`:@@Europe/Budapest:Europe/Budapest`,
  'Atlantic/Reykjavik': $localize`:@@Atlantic/Reykjavik:Atlantic/Reykjavik`,
  'Europe/Rome': $localize`:@@Europe/Rome:Europe/Rome`,
  'Europe/Vatican': $localize`:@@Europe/Vatican:Europe/Vatican`,
  'Europe/San_Marino': $localize`:@@Europe/San_Marino:Europe/San_Marino`,
  'Europe/Riga': $localize`:@@Europe/Riga:Europe/Riga`,
  'Europe/Vaduz': $localize`:@@Europe/Vaduz:Europe/Vaduz`,
  'Europe/Vilnius': $localize`:@@Europe/Vilnius:Europe/Vilnius`,
  'Europe/Luxembourg': $localize`:@@Europe/Luxembourg:Europe/Luxembourg`,
  'Europe/Malta': $localize`:@@Europe/Malta:Europe/Malta`,
  'Europe/Chisinau': $localize`:@@Europe/Chisinau:Europe/Chisinau`,
  'Europe/Monaco': $localize`:@@Europe/Monaco:Europe/Monaco`,
  'Europe/Amsterdam': $localize`:@@Europe/Amsterdam:Europe/Amsterdam`,
  'Europe/Oslo': $localize`:@@Europe/Oslo:Europe/Oslo`,
  'Arctic/Longyearbyen': $localize`:@@Arctic/Longyearbyen:Arctic/Longyearbyen`,
  'Europe/Warsaw': $localize`:@@Europe/Warsaw:Europe/Warsaw`,
  'Europe/Lisbon': $localize`:@@Europe/Lisbon:Europe/Lisbon`,
  'Atlantic/Azores': $localize`:@@Atlantic/Azores:Atlantic/Azores`,
  'Atlantic/Madeira': $localize`:@@Atlantic/Madeira:Atlantic/Madeira`,
  'Europe/Bucharest': $localize`:@@Europe/Bucharest:Europe/Bucharest`,
  'Europe/Kaliningrad': $localize`:@@Europe/Kaliningrad:Europe/Kaliningrad`,
  'Europe/Moscow': $localize`:@@Europe/Moscow:Europe/Moscow`,
  'Europe/Simferopol': $localize`:@@Europe/Simferopol:Europe/Simferopol`,
  'Europe/Astrakhan': $localize`:@@Europe/Astrakhan:Europe/Astrakhan`,
  'Europe/Volgograd': $localize`:@@Europe/Volgograd:Europe/Volgograd`,
  'Europe/Saratov': $localize`:@@Europe/Saratov:Europe/Saratov`,
  'Europe/Kirov': $localize`:@@Europe/Kirov:Europe/Kirov`,
  'Europe/Samara': $localize`:@@Europe/Samara:Europe/Samara`,
  'Europe/Ulyanovsk': $localize`:@@Europe/Ulyanovsk:Europe/Ulyanovsk`,
  'Asia/Yekaterinburg': $localize`:@@Asia/Yekaterinburg:Asia/Yekaterinburg`,
  'Asia/Omsk': $localize`:@@Asia/Omsk:Asia/Omsk`,
  'Asia/Barnaul': $localize`:@@Asia/Barnaul:Asia/Barnaul`,
  'Asia/Novosibirsk': $localize`:@@Asia/Novosibirsk:Asia/Novosibirsk`,
  'Asia/Tomsk': $localize`:@@Asia/Tomsk:Asia/Tomsk`,
  'Asia/Novokuznetsk': $localize`:@@Asia/Novokuznetsk:Asia/Novokuznetsk`,
  'Asia/Krasnoyarsk': $localize`:@@Asia/Krasnoyarsk:Asia/Krasnoyarsk`,
  'Asia/Irkutsk': $localize`:@@Asia/Irkutsk:Asia/Irkutsk`,
  'Asia/Chita': $localize`:@@Asia/Chita:Asia/Chita`,
  'Asia/Yakutsk': $localize`:@@Asia/Yakutsk:Asia/Yakutsk`,
  'Asia/Vladivostok': $localize`:@@Asia/Vladivostok:Asia/Vladivostok`,
  'Asia/Khandyga': $localize`:@@Asia/Khandyga:Asia/Khandyga`,
  'Asia/Sakhalin': $localize`:@@Asia/Sakhalin:Asia/Sakhalin`,
  'Asia/Magadan': $localize`:@@Asia/Magadan:Asia/Magadan`,
  'Asia/Srednekolymsk': $localize`:@@Asia/Srednekolymsk:Asia/Srednekolymsk`,
  'Asia/Ust-Nera': $localize`:@@Asia/Ust-Nera:Asia/Ust-Nera`,
  'Asia/Kamchatka': $localize`:@@Asia/Kamchatka:Asia/Kamchatka`,
  'Asia/Anadyr': $localize`:@@Asia/Anadyr:Asia/Anadyr`,
  'Europe/Belgrade': $localize`:@@Europe/Belgrade:Europe/Belgrade`,
  'Europe/Ljubljana': $localize`:@@Europe/Ljubljana:Europe/Ljubljana`,
  'Europe/Podgorica': $localize`:@@Europe/Podgorica:Europe/Podgorica`,
  'Europe/Sarajevo': $localize`:@@Europe/Sarajevo:Europe/Sarajevo`,
  'Europe/Skopje': $localize`:@@Europe/Skopje:Europe/Skopje`,
  'Europe/Zagreb': $localize`:@@Europe/Zagreb:Europe/Zagreb`,
  'Europe/Bratislava': $localize`:@@Europe/Bratislava:Europe/Bratislava`,
  'Europe/Madrid': $localize`:@@Europe/Madrid:Europe/Madrid`,
  'Africa/Ceuta': $localize`:@@Africa/Ceuta:Africa/Ceuta`,
  'Atlantic/Canary': $localize`:@@Atlantic/Canary:Atlantic/Canary`,
  'Europe/Stockholm': $localize`:@@Europe/Stockholm:Europe/Stockholm`,
  'Europe/Zurich': $localize`:@@Europe/Zurich:Europe/Zurich`,
  'Europe/Istanbul': $localize`:@@Europe/Istanbul:Europe/Istanbul`,
  'Asia/Istanbul': $localize`:@@Asia/Istanbul:Asia/Istanbul`,
  'Europe/Kiev': $localize`:@@Europe/Kiev:Europe/Kiev`,
  'Europe/Uzhgorod': $localize`:@@Europe/Uzhgorod:Europe/Uzhgorod`,
  'Europe/Zaporozhye': $localize`:@@Europe/Zaporozhye:Europe/Zaporozhye`,
  'America/New_York': $localize`:@@America/New_York:America/New_York`,
  'America/Chicago': $localize`:@@America/Chicago:America/Chicago`,
  'America/North_Dakota/Center': $localize`:@@America/North_Dakota/Center:America/North_Dakota/Center`,
  'America/North_Dakota/New_Salem': $localize`:@@America/North_Dakota/New_Salem:America/North_Dakota/New_Salem`,
  'America/North_Dakota/Beulah': $localize`:@@America/North_Dakota/Beulah:America/North_Dakota/Beulah`,
  'America/Denver': $localize`:@@America/Denver:America/Denver`,
  'America/Los_Angeles': $localize`:@@America/Los_Angeles:America/Los_Angeles`,
  'America/Juneau': $localize`:@@America/Juneau:America/Juneau`,
  'America/Sitka': $localize`:@@America/Sitka:America/Sitka`,
  'America/Metlakatla': $localize`:@@America/Metlakatla:America/Metlakatla`,
  'America/Yakutat': $localize`:@@America/Yakutat:America/Yakutat`,
  'America/Anchorage': $localize`:@@America/Anchorage:America/Anchorage`,
  'America/Nome': $localize`:@@America/Nome:America/Nome`,
  'America/Adak': $localize`:@@America/Adak:America/Adak`,
  'Pacific/Honolulu': $localize`:@@Pacific/Honolulu:Pacific/Honolulu`,
  'America/Phoenix': $localize`:@@America/Phoenix:America/Phoenix`,
  'America/Boise': $localize`:@@America/Boise:America/Boise`,
  'America/Indiana/Indianapolis': $localize`:@@America/Indiana/Indianapolis:America/Indiana/Indianapolis`,
  'America/Indiana/Marengo': $localize`:@@America/Indiana/Marengo:America/Indiana/Marengo`,
  'America/Indiana/Vincennes': $localize`:@@America/Indiana/Vincennes:America/Indiana/Vincennes`,
  'America/Indiana/Tell_City': $localize`:@@America/Indiana/Tell_City:America/Indiana/Tell_City`,
  'America/Indiana/Petersburg': $localize`:@@America/Indiana/Petersburg:America/Indiana/Petersburg`,
  'America/Indiana/Knox': $localize`:@@America/Indiana/Knox:America/Indiana/Knox`,
  'America/Indiana/Winamac': $localize`:@@America/Indiana/Winamac:America/Indiana/Winamac`,
  'America/Indiana/Vevay': $localize`:@@America/Indiana/Vevay:America/Indiana/Vevay`,
  'America/Kentucky/Louisville': $localize`:@@America/Kentucky/Louisville:America/Kentucky/Louisville`,
  'America/Kentucky/Monticello': $localize`:@@America/Kentucky/Monticello:America/Kentucky/Monticello`,
  'America/Detroit': $localize`:@@America/Detroit:America/Detroit`,
  'America/Menominee': $localize`:@@America/Menominee:America/Menominee`,
  'America/St_Johns': $localize`:@@America/St_Johns:America/St_Johns`,
  'America/Goose_Bay': $localize`:@@America/Goose_Bay:America/Goose_Bay`,
  'America/Halifax': $localize`:@@America/Halifax:America/Halifax`,
  'America/Glace_Bay': $localize`:@@America/Glace_Bay:America/Glace_Bay`,
  'America/Moncton': $localize`:@@America/Moncton:America/Moncton`,
  'America/Blanc-Sablon': $localize`:@@America/Blanc-Sablon:America/Blanc-Sablon`,
  'America/Toronto': $localize`:@@America/Toronto:America/Toronto`,
  'America/Thunder_Bay': $localize`:@@America/Thunder_Bay:America/Thunder_Bay`,
  'America/Nipigon': $localize`:@@America/Nipigon:America/Nipigon`,
  'America/Rainy_River': $localize`:@@America/Rainy_River:America/Rainy_River`,
  'America/Atikokan': $localize`:@@America/Atikokan:America/Atikokan`,
  'America/Winnipeg': $localize`:@@America/Winnipeg:America/Winnipeg`,
  'America/Regina': $localize`:@@America/Regina:America/Regina`,
  'America/Swift_Current': $localize`:@@America/Swift_Current:America/Swift_Current`,
  'America/Edmonton': $localize`:@@America/Edmonton:America/Edmonton`,
  'America/Vancouver': $localize`:@@America/Vancouver:America/Vancouver`,
  'America/Dawson_Creek': $localize`:@@America/Dawson_Creek:America/Dawson_Creek`,
  'America/Fort_Nelson': $localize`:@@America/Fort_Nelson:America/Fort_Nelson`,
  'America/Creston': $localize`:@@America/Creston:America/Creston`,
  'America/Pangnirtung': $localize`:@@America/Pangnirtung:America/Pangnirtung`,
  'America/Iqaluit': $localize`:@@America/Iqaluit:America/Iqaluit`,
  'America/Resolute': $localize`:@@America/Resolute:America/Resolute`,
  'America/Rankin_Inlet': $localize`:@@America/Rankin_Inlet:America/Rankin_Inlet`,
  'America/Cambridge_Bay': $localize`:@@America/Cambridge_Bay:America/Cambridge_Bay`,
  'America/Yellowknife': $localize`:@@America/Yellowknife:America/Yellowknife`,
  'America/Inuvik': $localize`:@@America/Inuvik:America/Inuvik`,
  'America/Whitehorse': $localize`:@@America/Whitehorse:America/Whitehorse`,
  'America/Dawson': $localize`:@@America/Dawson:America/Dawson`,
  'America/Cancun': $localize`:@@America/Cancun:America/Cancun`,
  'America/Merida': $localize`:@@America/Merida:America/Merida`,
  'America/Matamoros': $localize`:@@America/Matamoros:America/Matamoros`,
  'America/Monterrey': $localize`:@@America/Monterrey:America/Monterrey`,
  'America/Mexico_City': $localize`:@@America/Mexico_City:America/Mexico_City`,
  'America/Ojinaga': $localize`:@@America/Ojinaga:America/Ojinaga`,
  'America/Chihuahua': $localize`:@@America/Chihuahua:America/Chihuahua`,
  'America/Hermosillo': $localize`:@@America/Hermosillo:America/Hermosillo`,
  'America/Mazatlan': $localize`:@@America/Mazatlan:America/Mazatlan`,
  'America/Bahia_Banderas': $localize`:@@America/Bahia_Banderas:America/Bahia_Banderas`,
  'America/Tijuana': $localize`:@@America/Tijuana:America/Tijuana`,
  'America/Nassau': $localize`:@@America/Nassau:America/Nassau`,
  'America/Barbados': $localize`:@@America/Barbados:America/Barbados`,
  'America/Belize': $localize`:@@America/Belize:America/Belize`,
  'Atlantic/Bermuda': $localize`:@@Atlantic/Bermuda:Atlantic/Bermuda`,
  'America/Costa_Rica': $localize`:@@America/Costa_Rica:America/Costa_Rica`,
  'America/Havana': $localize`:@@America/Havana:America/Havana`,
  'America/Santo_Domingo': $localize`:@@America/Santo_Domingo:America/Santo_Domingo`,
  'America/El_Salvador': $localize`:@@America/El_Salvador:America/El_Salvador`,
  'America/Guatemala': $localize`:@@America/Guatemala:America/Guatemala`,
  'America/Port-au-Prince': $localize`:@@America/Port-au-Prince:America/Port-au-Prince`,
  'America/Tegucigalpa': $localize`:@@America/Tegucigalpa:America/Tegucigalpa`,
  'America/Jamaica': $localize`:@@America/Jamaica:America/Jamaica`,
  'America/Martinique': $localize`:@@America/Martinique:America/Martinique`,
  'America/Managua': $localize`:@@America/Managua:America/Managua`,
  'America/Panama': $localize`:@@America/Panama:America/Panama`,
  'America/Cayman': $localize`:@@America/Cayman:America/Cayman`,
  'America/Puerto_Rico': $localize`:@@America/Puerto_Rico:America/Puerto_Rico`,
  'America/Miquelon': $localize`:@@America/Miquelon:America/Miquelon`,
  'America/Grand_Turk': $localize`:@@America/Grand_Turk:America/Grand_Turk`,
  'America/Argentina/Buenos_Aires': $localize`:@@America/Argentina/Buenos_Aires:America/Argentina/Buenos_Aires`,
  'America/Argentina/Cordoba': $localize`:@@America/Argentina/Cordoba:America/Argentina/Cordoba`,
  'America/Argentina/Salta': $localize`:@@America/Argentina/Salta:America/Argentina/Salta`,
  'America/Argentina/Tucuman': $localize`:@@America/Argentina/Tucuman:America/Argentina/Tucuman`,
  'America/Argentina/La_Rioja': $localize`:@@America/Argentina/La_Rioja:America/Argentina/La_Rioja`,
  'America/Argentina/San_Juan': $localize`:@@America/Argentina/San_Juan:America/Argentina/San_Juan`,
  'America/Argentina/Jujuy': $localize`:@@America/Argentina/Jujuy:America/Argentina/Jujuy`,
  'America/Argentina/Catamarca': $localize`:@@America/Argentina/Catamarca:America/Argentina/Catamarca`,
  'America/Argentina/Mendoza': $localize`:@@America/Argentina/Mendoza:America/Argentina/Mendoza`,
  'America/Argentina/San_Luis': $localize`:@@America/Argentina/San_Luis:America/Argentina/San_Luis`,
  'America/Argentina/Rio_Gallegos': $localize`:@@America/Argentina/Rio_Gallegos:America/Argentina/Rio_Gallegos`,
  'America/Argentina/Ushuaia': $localize`:@@America/Argentina/Ushuaia:America/Argentina/Ushuaia`,
  'America/Aruba': $localize`:@@America/Aruba:America/Aruba`,
  'America/La_Paz': $localize`:@@America/La_Paz:America/La_Paz`,
  'America/Noronha': $localize`:@@America/Noronha:America/Noronha`,
  'America/Belem': $localize`:@@America/Belem:America/Belem`,
  'America/Santarem': $localize`:@@America/Santarem:America/Santarem`,
  'America/Fortaleza': $localize`:@@America/Fortaleza:America/Fortaleza`,
  'America/Recife': $localize`:@@America/Recife:America/Recife`,
  'America/Araguaina': $localize`:@@America/Araguaina:America/Araguaina`,
  'America/Maceio': $localize`:@@America/Maceio:America/Maceio`,
  'America/Bahia': $localize`:@@America/Bahia:America/Bahia`,
  'America/Sao_Paulo': $localize`:@@America/Sao_Paulo:America/Sao_Paulo`,
  'America/Campo_Grande': $localize`:@@America/Campo_Grande:America/Campo_Grande`,
  'America/Cuiaba': $localize`:@@America/Cuiaba:America/Cuiaba`,
  'America/Porto_Velho': $localize`:@@America/Porto_Velho:America/Porto_Velho`,
  'America/Boa_Vista': $localize`:@@America/Boa_Vista:America/Boa_Vista`,
  'America/Manaus': $localize`:@@America/Manaus:America/Manaus`,
  'America/Eirunepe': $localize`:@@America/Eirunepe:America/Eirunepe`,
  'America/Rio_Branco': $localize`:@@America/Rio_Branco:America/Rio_Branco`,
  'America/Santiago': $localize`:@@America/Santiago:America/Santiago`,
  'America/Punta_Arenas': $localize`:@@America/Punta_Arenas:America/Punta_Arenas`,
  'Pacific/Easter': $localize`:@@Pacific/Easter:Pacific/Easter`,
  'Antarctica/Palmer': $localize`:@@Antarctica/Palmer:Antarctica/Palmer`,
  'America/Bogota': $localize`:@@America/Bogota:America/Bogota`,
  'America/Curacao': $localize`:@@America/Curacao:America/Curacao`,
  'America/Lower_Princes': $localize`:@@America/Lower_Princes:America/Lower_Princes`,
  'America/Kralendijk': $localize`:@@America/Kralendijk:America/Kralendijk`,
  'America/Guayaquil': $localize`:@@America/Guayaquil:America/Guayaquil`,
  'Pacific/Galapagos': $localize`:@@Pacific/Galapagos:Pacific/Galapagos`,
  'Atlantic/Stanley': $localize`:@@Atlantic/Stanley:Atlantic/Stanley`,
  'America/Cayenne': $localize`:@@America/Cayenne:America/Cayenne`,
  'America/Guyana': $localize`:@@America/Guyana:America/Guyana`,
  'America/Asuncion': $localize`:@@America/Asuncion:America/Asuncion`,
  'America/Lima': $localize`:@@America/Lima:America/Lima`,
  'Atlantic/South_Georgia': $localize`:@@Atlantic/South_Georgia:Atlantic/South_Georgia`,
  'America/Paramaribo': $localize`:@@America/Paramaribo:America/Paramaribo`,
  'America/Port_of_Spain': $localize`:@@America/Port_of_Spain:America/Port_of_Spain`,
  'America/Anguilla': $localize`:@@America/Anguilla:America/Anguilla`,
  'America/Antigua': $localize`:@@America/Antigua:America/Antigua`,
  'America/Dominica': $localize`:@@America/Dominica:America/Dominica`,
  'America/Grenada': $localize`:@@America/Grenada:America/Grenada`,
  'America/Guadeloupe': $localize`:@@America/Guadeloupe:America/Guadeloupe`,
  'America/Marigot': $localize`:@@America/Marigot:America/Marigot`,
  'America/Montserrat': $localize`:@@America/Montserrat:America/Montserrat`,
  'America/St_Barthelemy': $localize`:@@America/St_Barthelemy:America/St_Barthelemy`,
  'America/St_Kitts': $localize`:@@America/St_Kitts:America/St_Kitts`,
  'America/St_Lucia': $localize`:@@America/St_Lucia:America/St_Lucia`,
  'America/St_Thomas': $localize`:@@America/St_Thomas:America/St_Thomas`,
  'America/St_Vincent': $localize`:@@America/St_Vincent:America/St_Vincent`,
  'America/Tortola': $localize`:@@America/Tortola:America/Tortola`,
  'America/Montevideo': $localize`:@@America/Montevideo:America/Montevideo`,
  'America/Caracas': $localize`:@@America/Caracas:America/Caracas`,
};

@Pipe({
  name: 'translateTimezoneEnum',
  standalone: true,
})
export class TranslateTimezoneEnumPipe implements PipeTransform {
  public transform(value?: TimeZoneEnum): string {
    return value ? TIMEZONE_ENUM_TRANSLATIONS[value] : '';
  }
}
