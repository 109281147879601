import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  UnknownOr,
  formatToRembergNumberString,
  isUnknownObject,
} from '@remberg/global/common/core';
import {
  ApiResponse,
  EmptyListType,
  MultiSelectFunctions,
  MultiSelectModalOptionLabel,
} from '@remberg/global/ui';
import { CaseBasic, ServiceCase } from '@remberg/tickets/common/main';
import { ServiceCaseService } from '@remberg/tickets/ui/clients';
import { Observable, of } from 'rxjs';
import {
  BaseMultiselectComponent,
  BaseMultiselectComponentTranslations,
} from '../base-multiselect/base-multiselect.component';

@Component({
  selector: 'app-case-multiselect',
  templateUrl: '../base-multiselect/base-multiselect.component.html',
  styleUrls: ['../base-multiselect/base-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseMultiselectComponent extends BaseMultiselectComponent<
  ServiceCase | UnknownOr<CaseBasic>
> {
  @Input() public readonly titleIcon: string = 'forum--cases';
  @Input() public readonly translations: BaseMultiselectComponentTranslations = {
    title: $localize`:@@Cases:Cases`,
  };

  protected readonly emptyListType: EmptyListType = 'servicecases';

  private getModalOptionLabel = (
    serviceCase: ServiceCase | UnknownOr<CaseBasic>,
  ): MultiSelectModalOptionLabel => ({
    label: isUnknownObject(serviceCase) ? '' : `${serviceCase.subject}`,
    subLabel: isUnknownObject(serviceCase) ? '' : formatToRembergNumberString(serviceCase.ticketID),
  });

  private getOptionLabel = (serviceCase: ServiceCase | UnknownOr<CaseBasic>): string => {
    const optionLabel = this.getModalOptionLabel(serviceCase);
    return `${optionLabel.label} (${optionLabel.subLabel})`;
  };

  private getChipLabel = (serviceCase: ServiceCase | UnknownOr<CaseBasic>): string =>
    this.getOptionLabel(serviceCase);

  private getChipLink = (serviceCase: ServiceCase | UnknownOr<CaseBasic>): Observable<string> =>
    of(isUnknownObject(serviceCase) ? '' : `/servicecases/detail/${serviceCase._id}`);

  private fetchItemsWithCount = (
    search: string,
    pageSize: number,
    pageOffset: number,
  ): Observable<ApiResponse<ServiceCase[]>> =>
    this.caseService.getServiceCasesWithCount(
      false,
      pageSize,
      pageOffset,
      'asc',
      'createdAt',
      search,
    );

  protected readonly functions: MultiSelectFunctions<ServiceCase | UnknownOr<CaseBasic>> = {
    getChipLabel: this.getChipLabel,
    getChipLink: this.getChipLink,
    getOptionLabel: this.getOptionLabel,
    getModalOptionLabel: this.getModalOptionLabel,
    fetchListValuesWithCountFunction: this.fetchItemsWithCount,
  };

  constructor(private readonly caseService: ServiceCaseService) {
    super();
  }
}
