import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { isPlatform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FilesystemService } from '@remberg/files/ui/clients';
import { JWT_COOKIE_NAME, JWT_COOKIE_OPTIONS } from '@remberg/global/common/core';
import {
  ButtonActions,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  FILE_SYNC_DIRECTORY,
  LocalStorageKeys,
  LogService,
} from '@remberg/global/ui';
import { RembergUsersService } from '@remberg/users/ui/clients';
import Cookies from 'js-cookie';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, from, of } from 'rxjs';
import { exhaustMap, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { DialogOptions } from '../../dialogs/dialogs';
import { DynamicPopUpComponent } from '../../dialogs/dynamic-pop-up/dynamic-pop-up.component';
import { AppStateService } from '../../services/app-state.service';
import { DialogService } from '../../services/dialog.service';
import { IntercomService } from '../../services/intercom.service';
import { OfflinePushService } from '../../services/offline/offline-push.service';
import { SqlDBService } from '../../services/sqlDB.service';
import { SyncUiService } from '../../services/sync-ui.service';
import { RootGlobalState } from '../core-ui.definitions';
import { GlobalActions } from './global.actions';
import { GlobalSelectors } from './global.selectors';

@Injectable()
export class GlobalLogoutEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly logger: LogService,
    private readonly offlinePushService: OfflinePushService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly _dialog: DialogService,
    private readonly syncUiService: SyncUiService,
    private readonly store: Store<RootGlobalState>,
    private readonly intercomService: IntercomService,
    private readonly sqlDBService: SqlDBService,
    private readonly filesSystemService: FilesystemService,
    private readonly appState: AppStateService,
    private readonly rembergUsersService: RembergUsersService,
  ) {}

  public readonly logoutInitiated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.logoutInitiatedFromNavBar),
      withLatestFrom(this.store.select(GlobalSelectors.selectIsLoggedIn)),
      filter(([, isLoggedIn]) => !!isLoggedIn),
      exhaustMap(() => {
        this.logger.info()('Logout initiated');
        if (
          this.connectivityService.getConnected() &&
          this.offlinePushService.outstandingChangesCount.getValue() === 0
        ) {
          // in case of online and no un-synched changes, directly logout
          return of(true);
        }
        // in case of online and un-synched changes, ask for confirmation
        return from(this.askForLogoutConfirmation());
      }),
      filter(Boolean),
      map(() => {
        this.logger.info()('Proceeding with logout');
        return GlobalActions.logoutConfirmedInterface();
      }),
    ),
  );

  public readonly performLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.logoutConfirmedInterface, GlobalActions.logoutConfirmedInvalidToken),
      withLatestFrom(this.store.select(GlobalSelectors.selectHasToken)),
      filter(([, isLoggedIn]) => !!isLoggedIn),
      tap(async ([action]) => {
        this.logger.info()('Logging out on server');
        if (action.type !== GlobalActions.logoutConfirmedInvalidToken.type) {
          // if the token is valid, there is no point to send a logout request
          try {
            await firstValueFrom(this.rembergUsersService.logout());
            await this.intercomService.shutdownIntercom();
          } catch (error) {
            this.logger.warn()('Failed to logout on server (will still log out locally)!', error);
          }
        }
      }),
      mergeMap(([action]) => from(this.logoutFromDevice()).pipe(map(() => action))),
      withLatestFrom(
        this.store.select(GlobalSelectors.selectIsIonic),
        this.store.select(GlobalSelectors.selectIsAtPreviewDomain),
      ),
      map(([action, isIonic, isAtPreviewDomain]) =>
        GlobalActions.logoutComplete({
          targetUrl:
            action.type === GlobalActions.logoutConfirmedInvalidToken.type
              ? action.targetUrl
              : undefined,
          // Usually, we want to keep the public tenant information in the store even after the logout
          // to avoid flickering from switching to the default theme and back.
          // However, on preview environments, all tenants share the same subdomain, so we have to discard it in that case.
          discardTenantPublic: !isIonic && isAtPreviewDomain,
        }),
      ),
    ),
  );

  public readonly logoutComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.logoutComplete),
        map(async (action) => {
          await this.router.navigate(
            ['login'],
            action.targetUrl ? { queryParams: { target: action.targetUrl } } : undefined,
          );

          this.toastr.info(
            $localize`:@@youWereLoggedOut:You were logged out.`,
            $localize`:@@info:Info`,
          );
        }),
      ),
    { dispatch: false },
  );

  private async logoutFromDevice(): Promise<void> {
    this.logger.info()('Removing local data');
    await Promise.all([
      this.sqlDBService.resetTables(),
      this.resetLocalStorage(),
      this.clearSyncedFiles(),
    ]);
    Cookies.remove(JWT_COOKIE_NAME, JWT_COOKIE_OPTIONS as Cookies.CookieAttributes);

    this.logger.info()('Reset mobile app state');
    // Terminate a potentially ongoing sync process
    await this.syncUiService.reset();

    // If we do not go online again, the app will stay in offline mode and the user won't be able to log in again.
    await this.connectivityService.reset();
  }

  private async askForLogoutConfirmation(): Promise<boolean> {
    // apply #3395 logic to prevent data loss while logging out
    let popupText: string;
    let popupTitle: string;
    let popupTextItem: string | undefined;
    let abortText: string;
    let confirmationText: string;
    let online = false;

    if (this.connectivityService.getConnected()) {
      popupTitle = $localize`:@@attentionMinusRiskOfDataLoss:Attention - Risk of Data Loss`;
      // eslint-disable-next-line max-len
      popupText = $localize`:@@thereAreUnSynchronizedChangesThatWillBeLostUponLogoutHowDoYouWantToProceed:There are un-synchronized changes that will be lost upon logout. How do you want to proceed?`;
      abortText = $localize`:@@logoutAndLooseData:Log out and lose data`;
      confirmationText = $localize`:@@synchronizeChangesFirst:Synchronize changes first`;
      online = true;
      // in case of being offline, ask for confirmation before logout
    } else {
      popupTitle = $localize`:@@warning:Warning`;
      // eslint-disable-next-line max-len
      popupText = $localize`:@@youCanOnlyLoginAgainWhileYouAreOnlineHowDoYouWantToProceed:You can only login again while you are online. How do you want to proceed?`;
      // eslint-disable-next-line max-len
      popupTextItem = $localize`:@@unSynchronizedChangesWillBeLostUponLogout:Un-synchronized changes will be lost upon logout!`;
      abortText = $localize`:@@logout:Log out`;
      confirmationText = $localize`:@@stayLoggedIn:Stay logged in`;
    }
    const isXSmallView = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsXSmallView),
    );
    // open confirmation popup
    const dialogOpts: DialogOptions<DynamicPopUpComponent> = {
      childComponent: DynamicPopUpComponent,
      dialogData: {
        wrapperInput: {
          headerShow: false,
          styleMinWidth: isXSmallView ? undefined : '500px',
        },
        factoryInput: [
          { buttonsDirection: 'vertical' },
          {
            icon: {
              icon: 'autorenew',
              color: 'warning',
            },
          },
          {
            title: {
              text: popupTitle,
              position: 'center',
            },
          },
          {
            description: {
              position: 'left',
              text: [popupText, popupTextItem],
            },
          },
          { hideAbortButton: true },
          { showDoNotAskAgain: false },
          {
            buttons: [
              {
                text: abortText,
                category: 'danger',
                action: ButtonActions.ABORT,
              },
              {
                text: confirmationText,
                category: 'success',
                color: 'warning',
                action: ButtonActions.CONFIRM,
              },
            ],
          },
        ],
      },
    };
    const popup = this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts);
    const data = await popup.waitForCloseData();
    const dialogResult = data?.data;

    // Handle backdrop click, do absolutely nothing in such cases
    if (!dialogResult) {
      return false;
    }

    if (dialogResult.confirmation) {
      // open sync popup in case of online
      if (online) {
        // this.openOfflineStatusPopup();
        this.syncUiService.toggleSync();
      }
      return false;
    } else {
      if (this.offlinePushService.outstandingChangesCount.getValue() === 0) {
        return true;
      }
      const isXSmallView = await firstValueFrom(
        this.store.select(GlobalSelectors.selectIsXSmallView),
      );
      const dialogOpts3: DialogOptions<DynamicPopUpComponent> = {
        childComponent: DynamicPopUpComponent,
        dialogData: {
          wrapperInput: {
            headerShow: false,
            styleMinWidth: isXSmallView ? undefined : '500px',
          },
          factoryInput: [
            { buttonsDirection: 'vertical' },
            {
              icon: {
                icon: 'autorenew',
                color: 'primary',
              },
            },
            {
              title: {
                text: $localize`:@@attentionMinusRiskOfDataLoss:Attention - Risk of Data Loss`,
                position: 'center',
              },
            },
            {
              description: {
                position: 'left',
                text: [
                  $localize`:@@thereAreUnSynchronizedChangesThatWillBeLostUponLogoutAreYouSureYouWantToProceed:There are un-synchronized changes that will be lost upon logout. Are you sure you want to proceed?`,
                ],
              },
            },
            { hideAbortButton: true },
            { showDoNotAskAgain: false },
            {
              buttons: [
                {
                  text: $localize`:@@stayLoggedIn:Stay logged in`,
                  category: 'danger',
                  color: 'primary',
                  action: ButtonActions.ABORT,
                },
                {
                  text: $localize`:@@logoutAndLooseData:Log out and lose data`,
                  category: 'success',
                  action: ButtonActions.CONFIRM,
                },
              ],
            },
          ],
        },
      };
      // Show 3rd confirmation popup if there are changes
      const popup3 = this._dialog.showDialogOrModal<DynamicPopUpComponent>(dialogOpts3);
      const data3 = await popup3.waitForCloseData();
      if (!data3?.data?.confirmation) {
        return false;
      }
      return true;
    }
  }

  private async clearSyncedFiles(): Promise<void> {
    if (isPlatform('capacitor')) {
      try {
        await Filesystem.rmdir({
          path: FILE_SYNC_DIRECTORY,
          directory: Directory.Data,
          recursive: true,
        });
      } catch (error) {
        this.logger.warn()('Could not delete synced files', error);
      }
      // Since we just deleted both directories we need to recreate them.
      // -> since the creation only happens on application start normally.
      await this.filesSystemService.setupDirectories();
    }
  }

  private async resetLocalStorage(): Promise<void> {
    await this.appState.removeValues([
      LocalStorageKeys.TOKEN,
      LocalStorageKeys.USER_RIGHTS,
      LocalStorageKeys.USER_SETTINGS,
      LocalStorageKeys.ACCOUNT,
      LocalStorageKeys.CASES,
      LocalStorageKeys.CK_EDITOR,
      LocalStorageKeys.IONIC_CURRENT_REMBERG_USER,
      LocalStorageKeys.IONIC_CURRENT_TENANT,
      LocalStorageKeys.IONIC_CURRENT_USER_ROLE,
      LocalStorageKeys.USER_GROUPS,
      LocalStorageKeys.UI_STATE,

      // Offline
      LocalStorageKeys.INTERNAL_CONNECTIVITY_STATUS,

      LocalStorageKeys.OFFLINE_LAST_UPDATED_CONTACTS,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_ASSETS,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_ASSETTYPES,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_EMAILSTATUSES,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_FILES,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_FORMINSTANCES,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_FORMTEMPLATES,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_FORMTEMPLATEVERSIONS,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_ICONS,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_SERVICECASES,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_USERGROUPS,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_ORGANIZATIONS,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERS,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERSTATI,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERTYPES,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERS2,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERSTATI2,
      LocalStorageKeys.OFFLINE_LAST_UPDATED_WORKORDERTYPES2,
      LocalStorageKeys.OFFLINE_LAST_UPDATED,

      LocalStorageKeys.OFFLINE_FORM_DELETED_INSTANCES,
      LocalStorageKeys.OFFLINE_FORM_DRAFT_COUNT_DICT,

      LocalStorageKeys.IONIC_CURRENT_USER_PROFILE,
      LocalStorageKeys.IONIC_FORMS_FILES,
      LocalStorageKeys.IONIC_OFFLINE_CHANGES_BACKUP,
      LocalStorageKeys.IONIC_OFFLINE_FORM_DELETIONS,
      LocalStorageKeys.IONIC_INTERCOM_HASHES,
    ]);
  }
}
