import { AssetBasic } from '@remberg/assets/common/base';
import { MultiLanguageText, UnknownOr } from '@remberg/global/common/core';
import { ConfigWithPrefilling } from '../../../automation';
import { ConfigWithFilterSource } from '../config-with-filter-source';
import { Field } from '../field';
import { FormFieldTypesEnum } from '../field-type.enum';

// For JSON Schema it's important to have an interface instead of a type
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AssetMultiSelectField
  extends Field<FormFieldTypesEnum.ASSET_MULTI_SELECT, AssetMultiSelectFieldConfig> {}

export interface AssetMultiSelectFieldConfig
  extends ConfigWithPrefilling<AssetMultiSelectPrefillTargetsEnum>,
    ConfigWithFilterSource {
  disabled?: boolean;
  pdfHide?: boolean;
  pdfHideIfValueIsEmpty?: boolean;
  /**
   * The relative field width.
   * @exclusiveMinimum 0
   * @maximum 1
   */
  pdfWidth?: number;
  requiredAmountOfEntries?: number;
  label: {
    text: MultiLanguageText;
    pdfHide?: boolean;
    pdfTextColor?: string;
    pdfTextSize?: number;
  };
  value?: {
    disableAssetFormInstanceRelationship?: boolean;
    disableCreation?: boolean;
    pdfHide?: boolean;
    pdfStartInNewLine?: boolean;
    pdfTextColor?: string;
    pdfTextSize?: number;
    pdfAddLineBreaks?: boolean;
  };
}

export interface AssetMultiSelectFieldData<Populated extends boolean = false> {
  entries: (Populated extends true ? UnknownOr<AssetBasic> : string)[];
}

export enum AssetMultiSelectPrefillTargetsEnum {
  SELECTED_ASSETS = 'selectedAssets',
}
