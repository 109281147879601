import mergeWith from 'lodash/mergeWith';
import { deepCloneObject } from './deep-clone-object';

export function mergeObjects<T extends object, U extends object>(
  a: T,
  b: U,
  deepMergeArrayValues = false,
): T {
  return mergeWith(deepCloneObject(a), b, deepMergeArrayValues ? undefined : overwriteArrayValues);
}

function overwriteArrayValues(obj: unknown, src: unknown): unknown | void {
  if (Array.isArray(obj)) {
    return src ? src : obj;
  }
  return;
}
