<mat-card appearance="outlined" class="login-card center" ngClass.lt-sm="landing-card">
  <mat-card-header class="flex-row justify-center">
    <mat-card-title i18n="@@activateYourAccount">Activate your account</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form *ngIf="tokenIsValid && !sent && activationFormGroup" [formGroup]="activationFormGroup">
      <div class="form-container">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@email">Email</mat-label>
          <input matInput type="email" name="email" formControlName="email" />
        </mat-form-field>

        <div [@fadeInOnEnter] class="flex-col password-form-field">
          <mat-form-field appearance="outline">
            <mat-label i18n="@@setPassword">Set password</mat-label>
            <input matInput type="password" name="password" formControlName="password" required />
            <mat-error
              *ngIf="
                activationFormGroup.controls.password.errors
                  | getFormErrorMessage: passwordErrorMessages as errorMsg
              "
            >
              {{ errorMsg }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="password-form-field">
            <mat-label i18n="@@confirmPassword">Confirm password</mat-label>
            <input
              matInput
              type="password"
              name="passwordConfirm"
              formControlName="passwordConfirm"
              required
            />
            <mat-error
              *ngIf="
                activationFormGroup.controls.passwordConfirm.errors
                  | getFormErrorMessage: passwordErrorMessages as errorMsg
              "
            >
              {{ errorMsg }}
            </mat-error>
          </mat-form-field>
        </div>

        <button
          mat-flat-button
          (click)="activate()"
          [disabled]="activationFormGroup?.pristine || activationFormGroup?.invalid || sent"
          color="primary"
          i18n="@@activate"
          class="landing-button"
          data-test-id="activate-user-button"
        >
          Activate
        </button>

        <app-privacy-policy-text class="privacy-policy-text"></app-privacy-policy-text>
      </div>
    </form>

    <app-loader *ngIf="sent"></app-loader>
  </mat-card-content>

  <mat-card-actions>
    <a class="text-link" [class.disabled]="sent" [routerLink]="['/login']" i18n="@@backToLogin"
      >Back to Login</a
    >
  </mat-card-actions>
</mat-card>
