import { Pipe, PipeTransform } from '@angular/core';
import { RembergDateTime, TimeZoneEnum, parseRembergDateTime } from '@remberg/global/common/core';
import { DatetimeService } from '@remberg/ui-core/core';

@Pipe({
  name: 'timezoneDiffers',
})
export class TimezoneDiffersPipe implements PipeTransform {
  constructor(private readonly datetimeService: DatetimeService) {}

  public transform(value: RembergDateTime | null | undefined): boolean {
    let timezone = TimeZoneEnum['Etc/UTC'];
    const userTimezone = this.datetimeService.getUserTimezone();

    if (value) {
      const [, parsedTimezone] = parseRembergDateTime(value);
      timezone = parsedTimezone || timezone;
    }

    return userTimezone.trim().toLowerCase() !== timezone.toLowerCase();
  }
}
