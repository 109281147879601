import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeUrl',
})
export class SanitizeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(objectUrl?: string): SafeResourceUrl | null {
    return objectUrl ? this.sanitizer.bypassSecurityTrustUrl(objectUrl) : null;
  }
}
