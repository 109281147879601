export function programmaticallyDownloadFile({
  blob,
  targetFileName,
}: {
  blob: Blob;
  targetFileName: string;
}): void {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = targetFileName;
  link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

  // this is necessary for mobile iOS devices:
  // https://stackoverflow.com/questions/30694453/
  // blob-createobjecturl-download-not-working-in-firefox-but-works-when-debugging
  setTimeout(() => {
    window.URL.revokeObjectURL(link.href);
  }, 100);
}

export function getDownloadFileName(fileName: string): string {
  return fileName
    .replace(/[^a-z0-9]/gi, '_')
    .toLowerCase() // make it filename save
    .replace(/(_)(?=.*\1)/g, ''); // remove multiple occurrences of underscore
}
