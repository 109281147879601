import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_PLACEHOLDER, ApiResponse } from '@remberg/global/ui';
import { CaseMessageTemplate } from '@remberg/tickets/common/main';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CaseMessageTemplateService {
  public readonly caseMessageTemplatesUrl = `${API_URL_PLACEHOLDER}/tickets/messagetemplates`;

  constructor(private http: HttpClient) {}

  // ========================  List API  ===================================
  getCaseMessageTemplates(
    populate?: boolean | CaseMessageTemplate,
    pageSize?: number,
    pageIndex: number = 0,
    sortDirection: string = '',
    sortField: string = '',
    searchValue: string = '',
    populateAll?: boolean,
  ): Observable<ApiResponse<CaseMessageTemplate[]>> {
    let params = new HttpParams();

    if (populate !== undefined && populate && typeof populate === 'boolean') {
      if (populateAll) {
        params = params.set('populate', populate.toString());
      } else {
        // TODO - Set params responsively if needed
      }
    }

    if (pageSize) {
      params = params.set('limit', String(pageSize));
    }
    if (pageIndex) {
      params = params.set('page', String(pageIndex));
    }
    if (sortDirection) {
      params = params.set('order', String(sortDirection));
    }
    if (sortField) {
      params = params.set('sort', String(sortField));
    }
    if (searchValue) {
      params = params.set('search', encodeURIComponent(String(searchValue)));
    }
    return this.http.get<ApiResponse<CaseMessageTemplate[]>>(this.caseMessageTemplatesUrl, {
      params: params,
    });
  }

  // ========================  Detail API  ===================================
  getMessageTemplate(
    caseMessageTemplateId: string,
    populate?: boolean,
  ): Observable<CaseMessageTemplate> {
    const url = `${this.caseMessageTemplatesUrl}/${caseMessageTemplateId}`;
    let params = new HttpParams();
    if (populate !== undefined) {
      params = params.set('populate', populate.toString());
    }
    return this.http
      .get<ApiResponse<CaseMessageTemplate>>(url, { params: params })
      .pipe(map((res) => res.data));
  }

  addMessageTemplate(
    payload: { title: string; content: string },
    populate?: boolean,
  ): Observable<CaseMessageTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }

    return this.http
      .post<ApiResponse<CaseMessageTemplate>>(this.caseMessageTemplatesUrl, payload, httpOptions)
      .pipe(map((res) => res.data));
  }

  patchMessageTemplate(
    caseMessageTemplate: CaseMessageTemplate,
    populate?: boolean,
  ): Observable<CaseMessageTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
    };
    if (populate !== undefined) {
      httpOptions.params = httpOptions.params.set('populate', populate.toString());
    }
    const url = `${this.caseMessageTemplatesUrl}/${caseMessageTemplate._id}`;

    return this.http
      .patch<ApiResponse<CaseMessageTemplate>>(url, caseMessageTemplate, httpOptions)
      .pipe(map((res) => res.data));
  }

  deleteMessageTemplate(
    caseMessageTemplate: CaseMessageTemplate | string,
  ): Observable<ApiResponse<CaseMessageTemplate>> {
    const caseMessageTemplate_id =
      typeof caseMessageTemplate === 'string' ? caseMessageTemplate : caseMessageTemplate._id;
    const url = `${this.caseMessageTemplatesUrl}/${caseMessageTemplate_id}`;

    return this.http.delete<ApiResponse<CaseMessageTemplate>>(url);
  }
}
