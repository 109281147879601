import { WorkOrderBasic } from '@remberg/work-orders/common/base';

export function mapToWorkOrderBasic<T extends WorkOrderBasic>(workOrder: T): WorkOrderBasic {
  return {
    _id: workOrder._id,
    counter: workOrder.counter,
    subject: workOrder.subject,
    organizationId: workOrder.organizationId,
  };
}
