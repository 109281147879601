export enum WorkOrderActivityFieldEnum {
  COUNTER = 'counter',
  SUBJECT = 'subject',
  DESCRIPTION = 'description',
  PARENT_WORK_ORDER = 'parentWorkOrder',
  EXTERNAL_REFERENCE = 'externalReference',
  DUE_DATE = 'dueDate',
  RESPONSIBLE_CONTACT = 'responsibleContact',
  RESPONSIBLE_GROUP = 'responsibleGroup',
  PRIORITY = 'priority',
  ESTIMATED_DURATION = 'estimatedDuration',
  STATUS = 'status',
  TYPE = 'type',
  CHECKLIST = 'checklist',
  LOCATION = 'location',
  RELATED_CASE = 'relatedCase',
  RELATED_ORGANIZATION = 'relatedOrganization',
  RELATED_ASSETS = 'relatedAssets',
  RELATED_CONTACTS = 'relatedContacts',
  ADDITIONAL_CONTACT_INFORMATION = 'additionalContactInformation',
  CUSTOM_PROPERTIES = 'customProperties',

  PLANNING_START_DATE = 'planningStartDate',
  PLANNING_END_DATE = 'planningEndDate',
  PLANNING_ASSIGNED_CONTACTS = 'planningAssignedContacts',
  PLANNING_ASSIGNED_GROUPS = 'planningAssignedGroups',
  PLANNING_IS_FULL_DAY = 'planningIsFullDay',

  TOUCHED_BY_USER = 'touchedByUser',
  APPROACHING_DUE_DATE_NOTIFICATION_SENT = 'approachingDueDateNotificationSent',
  OVERDUE_NOTIFICATION_SENT = 'overdueNotificationSent',
}
