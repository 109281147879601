import { UnknownOr } from '@remberg/global/common/core';

export interface AssetTypeBasic {
  _id: string;
  label: string;
  imageId?: string;
}

export function isAssetTypeBasic(
  assetType: UnknownOr<AssetTypeBasic> | { _id: string } | undefined,
): assetType is AssetTypeBasic {
  return !!(assetType as AssetTypeBasic)?.label;
}
