import { AdvancedFilterClientConfig, AdvancedFilterFieldClientConfig } from '../definitions';

export function removeInaccessibleAdvancedFilters<T extends string>(
  filters: AdvancedFilterClientConfig<T>,
  isOEM: boolean,
  isOnline?: boolean,
): AdvancedFilterClientConfig<T> {
  const result = {} as AdvancedFilterClientConfig<T>;
  for (const filter of Object.values(filters) as AdvancedFilterFieldClientConfig<T>[]) {
    if (
      filter &&
      (isOEM || !filter?.oemOnly) &&
      filter?.featureFlagOnly !== false &&
      (isOnline || !filter?.onlineOnly) &&
      filter?.permissionOnly !== false &&
      filter?.isDisabled !== true
    ) {
      result[filter.identifier] = filter;
    }
  }
  return result;
}
