export enum FilterGenericValueEnum {
  NONE = 'none',
  ANY = 'any',
}

export interface FilterType<T> {
  identifier: T;
  value?: FilterGenericValueEnum | string;
  valueString?: string;
}
