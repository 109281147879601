import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { DynamicProgressBarConfig, LogService, SyncDataTypesEnum } from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { FormInstanceTableColumnEnum } from '../offline/formInstance.offline.service';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';
import { addColumnIfMissing } from './columnHelpers';

export async function migrateToV24(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  progressSubject: BehaviorSubject<DynamicProgressBarConfig>,
  progressWindow: number,
): Promise<void> {
  const progressValue = progressSubject.getValue();
  logger.debug()('Starting DB migration to V24...');

  await addMissingFormInstanceTableColumns(db, logger);

  await db.executeSql('PRAGMA user_version = 24;', []);
  progressValue.progress += progressWindow;
  progressSubject.next(progressValue);

  logger.debug()('Completed DB migration to V24.');
}

async function addMissingFormInstanceTableColumns(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
): Promise<void> {
  try {
    const tableExistsQuery = await db.executeSql(
      `SELECT name FROM sqlite_master WHERE type='table' AND name='${SyncDataTypesEnum.FORMINSTANCES}';`,
      [],
    );

    if (!tableExistsQuery.rows?.length) {
      logger.debug()(
        `${SyncDataTypesEnum.FORMINSTANCES} table does not exist. Skipping migration.`,
      );
      return;
    }

    logger.debug()(`FOUND ${SyncDataTypesEnum.FORMINSTANCES} table`);

    await addColumnIfMissing(
      db,
      logger,
      SyncDataTypesEnum.FORMINSTANCES,
      FormInstanceTableColumnEnum.RELATED_WORK_ORDER_ID_2,
      'TEXT',
    );
  } catch (error) {
    logger.error()('Error adding relatedWorkOrderId2 column!', error);
  }
}
