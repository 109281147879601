import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import {
  PlatformFile,
  PlatformFilesFindManyQuery,
  PlatformFilesFindManyResponse,
} from '@remberg/files/common/main';
import { OfflineService } from '@remberg/global/ui';
import { Observable } from 'rxjs';

export interface PlatformFilesOfflineServiceInterface
  extends OfflineService<PlatformFile, AdvancedFilterQuery<never>, AdvancedFilter<never>> {
  downloadFileToBlobOffline(fileId: string): Observable<Blob>;
  findMany(params: PlatformFilesFindManyQuery): Observable<PlatformFilesFindManyResponse>;
}

export const PLATFORM_FILES_OFFLINE_SERVICE =
  new InjectionToken<PlatformFilesOfflineServiceInterface>(
    'Token for injecting platform files offline service without circular dependencies',
  );
