import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SimpleSelectDialogComponent } from './modal-simple-select/simple-select-dialog.component';

@Component({
  selector: 'app-remberg-simple-select',
  templateUrl: './remberg-simple-select.component.html',
  styleUrls: ['./remberg-simple-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RembergSimpleSelectComponent implements OnInit {
  @Input() fieldLabel: string = $localize`:@@simpleSelect:Simple Select`;
  @Input() dataTestId: string = '';
  @Input() initialOption?: string;
  @Output() onOptionChanged = new EventEmitter<string>();
  @Input() options?: { label: string; identifier: string }[];

  selectedOption?: { label: string; identifier: string };

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.selectedOption = this.options?.find((opt) => opt.identifier === this.initialOption);
  }

  protected async openDialogSelect(): Promise<void> {
    const dialogConfig = {
      data: {
        selectedOption: this.selectedOption,
        options: this.options,
      },
    };

    const dialogRef = this.dialog.open(SimpleSelectDialogComponent, {
      panelClass: 'mobile-device-full-screen',
      data: dialogConfig,
    });
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (!dialogResult) {
      return;
    }
    this.selectedOption = this.options?.find(
      (opt) => opt.identifier === dialogResult.selectedOption,
    );
    this.onOptionChanged.emit(this.selectedOption?.identifier);
    this.cdRef.markForCheck();
  }
}
