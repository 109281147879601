import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AssetTypeRawOffline,
  AssetTypesFindManyOfflineQueryInterface,
  AssetTypesNewOfflineServiceInterface,
} from '@remberg/assets/ui/clients';
import {
  ApiResponse,
  LogService,
  SQLQueryParams,
  SyncDataTypesEnum,
  stringToSQLSortDirection,
} from '@remberg/global/ui';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

const enum ColumnNamesEnum {
  LABEL = 'label',
}

const params: SQLQueryParams<ColumnNamesEnum> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.ASSETTYPES2,
  columns: {
    [ColumnNamesEnum.LABEL]: {
      type: 'TEXT',
      valueFunction: (val: AssetTypeRawOffline) => val?.label?.trim(),
      sortNoCase: true,
    },
  },
};

@Injectable()
export class AssetTypesNewOfflineService
  extends BaseOfflineService<AssetTypeRawOffline, never>
  implements AssetTypesNewOfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, params, logger, store);
  }

  public async getAssetTypesWithCount(
    queryOptions: AssetTypesFindManyOfflineQueryInterface,
  ): Promise<ApiResponse<AssetTypeRawOffline[]>> {
    const { searchValue, sortColumn, sortDirection, limit, offset } = queryOptions;
    // filters:
    const filterStrings = [];

    // search:
    if (searchValue) {
      filterStrings.push(`${params.tableName}.${ColumnNamesEnum.LABEL} LIKE '%${searchValue}%'`);
    }

    // sorting (must be mapped to the right column):
    const sqlSortDirection = stringToSQLSortDirection(sortDirection);

    return await this.getInstancesWithCount(
      limit,
      offset,
      sortColumn,
      sqlSortDirection,
      filterStrings.join(' AND '),
    );
  }

  public async getManyByIds(ids: string[]): Promise<AssetTypeRawOffline[]> {
    if (!ids.length) {
      return [];
    }

    const filterString = `${params.tableName}._id IN (${ids.map((id) => `'${id}'`).join(',')})`;
    return await this.getInstances(undefined, undefined, undefined, undefined, filterString);
  }
}
