import { StepFactory, StepFn, StepFunctions, StepsProvider } from './interfaces';

export class SimpleStepFactory<Functions extends StepFunctions> implements StepFactory<Functions> {
  private readonly steps: Partial<Functions>;

  constructor(public readonly providers: StepsProvider<Functions>[]) {
    this.steps = providers
      .map((provider) => provider.getSteps())
      .reduce((allSteps, steps) => ({ ...allSteps, ...steps }));
  }

  public create<Name extends keyof Functions>(stepName: Name): StepFn | undefined {
    const stepFn: StepFn | undefined = this.steps[stepName];

    return stepFn;
  }
}
