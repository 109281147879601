import {
  GlobalBreakpointEffects,
  GlobalEffects,
  GlobalInitializationEffects,
  GlobalIntercomEffects,
  GlobalLogoutEffects,
  GlobalRegisterSessionInfoEffects,
  GlobalUserSettingsEffects,
} from './global';
import { NavigationalEffects } from './navigational';

export * from './global';
export * from './navigational';
export * from './router';

export * from './core-ui.definitions';
export * from './core-ui.reducer';

export const CORE_UI_EFFECTS = [
  GlobalBreakpointEffects,
  GlobalUserSettingsEffects,
  GlobalEffects,
  GlobalInitializationEffects,
  GlobalLogoutEffects,
  GlobalRegisterSessionInfoEffects,
  GlobalIntercomEffects,
  NavigationalEffects,
];
