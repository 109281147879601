export enum TicketActivityType {
  // This is an exception (key !== value), it is to avoid risks associated with migration and possible hard codes
  OUTBOUND_EMAIL_MESSAGE = 'message',
  NOTE = 'note',
  CHANGE = 'change',
  OEM_FEEDBACK = 'oemFeedback',
  // This is an exception (key !== value), it is to avoid risks associated with migration and possible hard codes
  INBOUND_EMAIL_MESSAGE = 'emailMessage',
  SYSTEM_MESSAGE = 'systemMessage',
  CASE_MOVED = 'caseMoved',
  CASE_MOVED_UNDO = 'caseMovedUndo',
  PORTAL_MESSAGE = 'portalMessage',
}
