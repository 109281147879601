import { InjectionToken } from '@angular/core';
import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { FilterType } from '@remberg/global/common/core';
import { ApiResponse, OfflineService } from '@remberg/global/ui';
import {
  CaseAPIFilterFieldEnum,
  CaseCustomPropertyKey,
  ServiceCase,
} from '@remberg/tickets/common/main';

export interface ServiceCaseOfflineServiceInterface
  extends OfflineService<
    ServiceCase,
    AdvancedFilterQuery<CaseAPIFilterFieldEnum | CaseCustomPropertyKey>,
    AdvancedFilter<CaseAPIFilterFieldEnum>
  > {
  getServiceCasesWithCount(
    limit?: number,
    offset?: number,
    sortColumn?: string,
    sortDirection?: string,
    searchValue?: string,
    filters?: FilterType<string>[],
  ): Promise<ApiResponse<ServiceCase[]>>;
}

export const SERVICE_CASE_OFFLINE_SERVICE = new InjectionToken<ServiceCaseOfflineServiceInterface>(
  'Token for injecting service case offline service without circular dependencies',
);
