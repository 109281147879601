type Entries<T extends object> = NonNullable<
  {
    [K in keyof T]: [K, T[K]];
  }[keyof T]
>[];

export function keysOf<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function valuesOf<T extends object>(obj: T): T[keyof T][] {
  return Object.values(obj) as T[keyof T][];
}

export function entriesOf<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}

export function hasKeys<T extends object>(obj: T | undefined): boolean {
  return !!obj && Object.keys(obj).length > 0;
}
