export type ValueType = string | number | boolean | undefined | null;

export function getUnique<T extends ValueType>(items: T[] | undefined): T[];
export function getUnique<T, K>(items: T[] | undefined, getKey: (item: T) => K): T[];
export function getUnique<T, K>(items: T[] | undefined, getKey?: (item: T) => K): T[] {
  if (getKey) {
    return getUniqueObjects(items, getKey);
  }

  return getUniqueValues(items as ValueType[]) as T[];
}

function getUniqueObjects<T, K>(items: T[] | undefined, getKey: (item: T) => K): T[] {
  const uniqueKeys = new Set<K>();

  return (items ?? []).reduce((uniqueItems, item) => {
    const key = getKey(item);
    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key);
      uniqueItems.push(item);
    }

    return uniqueItems;
  }, [] as T[]);
}

function getUniqueValues<T extends ValueType>(items: T[] | undefined): T[] {
  return [...new Set(items)];
}
