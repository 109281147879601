import {
  AdvancedFilter,
  AdvancedFilterClientConfig,
  AdvancedFilterQuery,
} from '@remberg/advanced-filters/common/main';
import {
  AssetCustomPropertyKey,
  AssetHierarchyElement,
  AssetsLegacyFilterFieldEnum,
} from '@remberg/assets/common/main';
import { CustomPropertyKey } from '@remberg/custom-properties/common/main';
import { FilterType } from '@remberg/global/common/core';
import { FindManyOfflineQuery } from '@remberg/global/ui';

export interface AssetsTree {
  root: AssetHierarchyElement;
  children: AssetsTree[];
  hasChildren: boolean;
}

export type AssetsLegacyAdvancedFilterConfigKeys =
  | AssetsLegacyFilterFieldEnum
  | AssetCustomPropertyKey
  | CustomPropertyKey;
export type AssetsLegacyAdvancedFilterConfig =
  AdvancedFilterClientConfig<AssetsLegacyAdvancedFilterConfigKeys>;
export type AssetsLegacyAdvancedStaticFilter = AdvancedFilter<AssetsLegacyFilterFieldEnum>;
export type AssetsLegacyAdvancedFilterQuery =
  AdvancedFilterQuery<AssetsLegacyAdvancedFilterConfigKeys>;
export type AssetsLegacyOfflineAdvancedFilterConfigKeys = Exclude<
  AssetsLegacyFilterFieldEnum,
  AssetsLegacyFilterFieldEnum.ASSET_ID | AssetsLegacyFilterFieldEnum.PART_ID
>;
export type AssetsLegacyOfflineAdvancedFilterColumns = Exclude<
  AssetsLegacyOfflineAdvancedFilterConfigKeys,
  | AssetsLegacyFilterFieldEnum.INVOLVED_CONTACT
  | AssetsLegacyFilterFieldEnum.GROUP_ID
  | AssetsLegacyFilterFieldEnum.BOUNDING_BOX
>;
export type AssetsLegacyOfflineAdvancedFilterQuery =
  AdvancedFilterQuery<AssetsLegacyOfflineAdvancedFilterConfigKeys>;
export type AssetsLegacyFindManyOfflineQueryInterface = FindManyOfflineQuery<
  AssetsLegacyOfflineAdvancedFilterQuery,
  AdvancedFilter<AssetsLegacyOfflineAdvancedFilterConfigKeys>
> & {
  filters?: FilterType<string>[];
};

export enum AssetsLegacyOfflinePopulate {
  CUSTOMER_ORGANIZATION = 'customerOrganization',
  CUSTOMER_CONTACT = 'customerContact',
  PRODUCT_TYPE = 'productType',
}
