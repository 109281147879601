<!-- Very bad solution, check dynamic switching progress here /https://github.com/angular/components/issues/15367 -->
<ng-container [ngSwitch]="buttonStyle">
  <!-- Base Button -->
  <button
    *ngSwitchDefault
    mat-button
    [color]="materialColor"
    [ngClass]="[additionalClass, isErrorState ? 'is-error-state' : '']"
    [ngStyle]="overrideNgStyle ?? { width: '100%', position: 'relative' }"
    [ngClass.lt-sm]="[additionalClassIsSmallView, isErrorState ? 'is-error-state' : '']"
    (click)="loaderClick($event)"
    [disabled]="disabled || loading"
    [attr.data-test-id]="dataTestId"
    [attr.data-product-tour-id]="dataProductTourId"
  >
    <mat-icon *ngIf="icon && !loading">{{ icon }}</mat-icon>
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
  <!-- Raised Button -->
  <button
    *ngSwitchCase="'mat-raised-button'"
    mat-raised-button
    [color]="materialColor"
    [ngClass]="[additionalClass, isErrorState ? 'is-error-state' : '']"
    [ngStyle]="overrideNgStyle ?? { width: '100%', position: 'relative' }"
    [ngClass.lt-sm]="[additionalClassIsSmallView, isErrorState ? 'is-error-state' : '']"
    (click)="loaderClick($event)"
    [disabled]="disabled || loading"
    [attr.data-test-id]="dataTestId"
  >
    <mat-icon *ngIf="icon && !loading">{{ icon }}</mat-icon>
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
  <!-- Stroked Button -->
  <button
    *ngSwitchCase="'mat-stroked-button'"
    mat-stroked-button
    [color]="materialColor"
    [ngClass]="[additionalClass, isErrorState ? 'is-error-state' : '']"
    [ngStyle]="overrideNgStyle ?? { width: '100%', position: 'relative' }"
    [ngClass.lt-sm]="[additionalClassIsSmallView, isErrorState ? 'is-error-state' : '']"
    (click)="loaderClick($event)"
    [disabled]="disabled || loading"
    [attr.data-test-id]="dataTestId"
  >
    <mat-icon *ngIf="icon && !loading">{{ icon }}</mat-icon>
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
  <!-- Flat Button -->
  <button
    *ngSwitchCase="'mat-flat-button'"
    mat-flat-button
    [color]="materialColor"
    [ngClass]="[additionalClass, isErrorState ? 'is-error-state' : '']"
    [ngStyle]="overrideNgStyle ?? { width: '100%', position: 'relative' }"
    [ngClass.lt-sm]="[additionalClassIsSmallView, isErrorState ? 'is-error-state' : '']"
    (click)="loaderClick($event)"
    [disabled]="disabled || loading"
    [attr.data-test-id]="dataTestId"
  >
    <mat-icon *ngIf="icon && !loading">{{ icon }}</mat-icon>
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</ng-container>

<ng-template #buttonContent>
  <ng-container *ngIf="!showTextOnLoading; else noText">
    <div *ngIf="loading" class="text-spinner">
      <mat-progress-spinner
        class="progress-spinner"
        [color]="materialColor ? materialColor : 'accent'"
        [diameter]="spinnerSize"
        mode="indeterminate"
      />
    </div>
    <span class="" [ngStyle]="loading ? { visibility: 'hidden' } : {}">
      {{ text }}
    </span>
  </ng-container>

  <ng-template #noText>
    <div class="flex-row flex-row-center flex-gap-row-10">
      <span *ngIf="!loading" [ngClass]="{ 'button-text-with-icon': icon }">{{ text }}</span>
      <ng-container *ngIf="loading">
        <mat-progress-spinner
          class="progress-spinner"
          [color]="materialColor ? materialColor : 'accent'"
          [diameter]="18"
          mode="indeterminate"
        />
        <span>{{ text }}</span>
      </ng-container>
    </div>
  </ng-template>
</ng-template>
