import { Assert, TwoWayCompareStringType } from '@remberg/global/common/core';
import { UserPermissions } from './permissions.interface';

export enum UsersPermissionEnum {
  CREATE_OWN = 'users_createOwn',
  VIEW_OWN = 'users_viewOwn',
  UPDATE_OWN = 'users_updateOwn',
  DELETE_OWN = 'users_deleteOwn',
  CREATE_EXTERNAL = 'users_createExternal',
  VIEW_EXTERNAL = 'users_viewExternal',
  UPDATE_EXTERNAL = 'users_updateExternal',
  DELETE_EXTERNAL = 'users_deleteExternal',
}

export type AssertUsersPermissionMatchingInterfaces = Assert<
  TwoWayCompareStringType<`users_${keyof UserPermissions}`, `${UsersPermissionEnum}`>
>;
