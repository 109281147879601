import { Injectable } from '@angular/core';
import {
  CustomPropertyTypesEnum,
  CustomPropertyValue,
  RembergAssetSelectCustomPropertyValue,
  RembergContactSelectCustomPropertyValue,
  RembergOrganizationSelectCustomPropertyValue,
} from '@remberg/custom-properties/common/main';
import { StepsProvider } from '@remberg/forms/common/main';
import { RembergDate, RembergDateTime } from '@remberg/global/common/core';
import { PrefillingStepFunctions } from './interfaces';

@Injectable()
export class CustomPropertyValue2PrefillingStepsProvider
  implements StepsProvider<PrefillingStepFunctions>
{
  public getSteps(): Partial<PrefillingStepFunctions> {
    return {
      customPropertyValueToString,
      customPropertyValueToNumber: (cVal: unknown | undefined) =>
        typeof (cVal as CustomPropertyValue).value === 'number'
          ? ((cVal as CustomPropertyValue).value as number)
          : undefined,
      customPropertyValueToNumberString: (cVal: unknown | undefined) =>
        typeof (cVal as CustomPropertyValue).value === 'number'
          ? (cVal as CustomPropertyValue).value + ''
          : undefined,
      customPropertyValueToBoolean: (cVal: unknown | undefined) =>
        typeof (cVal as CustomPropertyValue).value === 'boolean'
          ? ((cVal as CustomPropertyValue).value as boolean)
          : undefined,
      customPropertyValueToArrayOfStrings,
      customPropertyValueToAssetId,
      customPropertyValueToUserId,
      customPropertyValueToAccountId,
      customPropertyValueToDate,
      customPropertyValueToDateTime,
    };
  }
}

function isCustomProperty(cPropVal: unknown | undefined): cPropVal is CustomPropertyValue {
  return (
    !!cPropVal &&
    !!(cPropVal as CustomPropertyValue).value &&
    !!(cPropVal as CustomPropertyValue).propertyType
  );
}

function customPropertyValueToDateTime(cVal: unknown | undefined): RembergDateTime | undefined {
  return isCustomProperty(cVal) &&
    typeof cVal.value === 'string' &&
    cVal.propertyType === CustomPropertyTypesEnum.REMBERG_DATETIME
    ? cVal.value
    : undefined;
}

function customPropertyValueToDate(cVal: unknown | undefined): RembergDate | undefined {
  return isCustomProperty(cVal) &&
    typeof cVal.value === 'string' &&
    cVal.propertyType === CustomPropertyTypesEnum.REMBERG_DATE
    ? cVal.value
    : undefined;
}

function customPropertyValueToAccountId(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    Array.isArray((cVal as CustomPropertyValue).value) &&
    (cVal as CustomPropertyValue).propertyType ===
      CustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT
    ? (cVal as RembergOrganizationSelectCustomPropertyValue).value[0]
    : undefined;
}

function customPropertyValueToUserId(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    Array.isArray((cVal as CustomPropertyValue).value) &&
    (cVal as CustomPropertyValue).propertyType === CustomPropertyTypesEnum.REMBERG_CONTACT_SELECT
    ? (cVal as RembergContactSelectCustomPropertyValue).value[0]
    : undefined;
}

function customPropertyValueToArrayOfStrings(cVal: unknown | undefined): string[] | undefined {
  return isCustomProperty(cVal) &&
    Array.isArray(cVal.value) &&
    cVal.propertyType === CustomPropertyTypesEnum.SELECT
    ? cVal.value
    : undefined;
}

function customPropertyValueToAssetId(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    Array.isArray(cVal.value) &&
    cVal.propertyType === CustomPropertyTypesEnum.REMBERG_ASSET_SELECT
    ? (cVal as RembergAssetSelectCustomPropertyValue).value[0]
    : undefined;
}

/**
 * As long as we have to support both the old custom properties and the new custom properties,
 * we need to check if the value is an array or a string. In the new custom properties, the
 * type "select" always returns an array, but in the old custom properties, we return a string.
 * In the forms, the single static select expects a string to prefill.
 */

function customPropertyValueToString(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) && typeof cVal.value === 'string'
    ? cVal.value
    : isCustomProperty(cVal) && Array.isArray(cVal.value)
      ? cVal.value[0]
      : undefined;
}
