import { TimeZoneEnum } from '@remberg/global/common/core';
import { FormTemplateConfig } from '../models';

export const EXAMPLE_EMPTY_FORM_TEMPLATE_CONFIG: FormTemplateConfig = {
  general: {
    timezone: TimeZoneEnum['Europe/Berlin'],
    hideInstanceCounterInPdf: false,
  },
  sections: [],
};
