import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatAccordion } from '@angular/material/expansion';

export interface OptionsSelectConfig {
  name: string;
  checked: boolean;
  translation: string;
  subOptions?: SubOptionsSelectConfig[];
}

export interface SubOptionsSelectConfig {
  name: string;
  checked: boolean;
  translation: string;
}

export interface ResultingConfig {
  [id: string]: boolean | ResultingConfig;
}

@Component({
  selector: 'app-options-select',
  templateUrl: './options-select.component.html',
  styleUrls: ['./options-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

// Note: This component does not support more than 2 levels of checkboxes (indeterminate does not work after that)
export class OptionsSelectComponent implements OnInit {
  @Input() config?: OptionsSelectConfig[];
  // Return config as flat object with all of the lowest-level options or in a recursive structure (as in config)
  @Input() flattenResults?: boolean = false;
  @Output() resultingConfig: EventEmitter<ResultingConfig> = new EventEmitter<ResultingConfig>();
  @ViewChild(MatAccordion, { static: true }) accordion?: MatAccordion;

  ngOnInit(): void {
    // This is needed to prevent sub options from shortly opening during initialization
    // See: https://github.com/angular/components/issues/13870
    this.accordion?.closeAll();
  }

  updateResultingConfig(): void {
    this.resultingConfig.emit(this.getResultingConfig(this.config));
  }

  setAll(event: MatCheckboxChange, option: OptionsSelectConfig): void {
    if (option.subOptions) {
      this.setAllSubOptions(option, event.checked);
    }
    this.updateResultingConfig();
  }

  isSet(subOptions: OptionsSelectConfig[], partially: boolean): boolean {
    let isOneTrue = false;
    let isOneFalse = false;
    for (const option of subOptions) {
      if (option.checked === true) {
        isOneTrue = true;
      } else {
        isOneFalse = true;
      }
    }
    return partially ? isOneTrue && isOneFalse : isOneTrue && !isOneFalse;
  }

  setAllSubOptions(option: OptionsSelectConfig, checked: boolean): void {
    if (option.subOptions && option.subOptions.length > 0) {
      for (const subOption of option.subOptions) {
        subOption.checked = checked;
      }
    }
  }

  getResultingConfig(config?: OptionsSelectConfig[], result?: ResultingConfig): ResultingConfig {
    result = result ? result : {};
    for (const option of config ?? []) {
      if (option.subOptions && option.subOptions.length > 0) {
        if (this.flattenResults) {
          this.getResultingConfig(option.subOptions, result);
        } else {
          result[option.name] = this.getResultingConfig(option.subOptions);
        }
      } else {
        result[option.name] = option.checked;
      }
    }
    return result;
  }

  /**
   * Stops clicking checkbox from also clicking on panel
   */
  onCheckClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
