import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { IntercomHashes } from '@remberg/users/common/main';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntercomApiService {
  private readonly REMBERG_USERS_URL = `${API_URL_PLACEHOLDER}/users/v1`;

  constructor(private http: HttpClient) {}

  public fetchIntercomHashes(): Observable<IntercomHashes> {
    return this.http.get<IntercomHashes>(`${this.REMBERG_USERS_URL}/intercom`);
  }
}
