export enum WorkOrderSortFieldEnum {
  COUNTER = 'counter',
  PRIORITY = 'priority',
  SUBJECT = 'subject',
  EXTERNAL_REFERENCE = 'externalReference',
  ESTIMATED_DURATION = 'estimatedDuration',

  STATUS = 'status',
  TYPE = 'type',

  DUE_DATE = 'dueDate',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',

  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  CITY = 'city',
  ZIP_POST_CODE = 'zipPostCode',
  STREET = 'street',

  PLANNING_START_DATE = 'planningStartDate',
  PLANNING_END_DATE = 'planningEndDate',
}
