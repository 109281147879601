// pass in property to validate and list of validators to run on it
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validateProperty(property: string, validators: ValidatorFn[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // get the value and assign it to a new form control
    const propertyVal = control.value && control.value[property];
    const newFormControl = new FormControl(propertyVal);

    // run the validators on the new control and keep the ones that fail
    const failedValidators = validators
      .map((validator) => validator(newFormControl))
      .filter((v) => !!v);

    // if any fail, return that the property is invalid, else valid
    const validationErrors = failedValidators.length ? { invalidProperty: true } : null;
    control.setErrors(validationErrors);
    return validationErrors;
  };
}
