import { Parser } from 'json2csv';

const DELIMITER = ';';

export const generateCSVFile = (csvContent: Record<string, unknown>): Blob => {
  const fields = Object.keys(csvContent);
  const csvData = createCSVData(
    {
      delimiter: DELIMITER,
      fields,
    },
    csvContent,
  );
  return new Blob([csvData], { type: 'text/csv' });
};

const createCSVData = (
  options: Record<string, unknown>,
  content: Record<string, unknown>,
): string => {
  const parser = new Parser(options);
  return parser.parse(content);
};
