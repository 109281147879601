export function createMapper<T, R>(
  mapperFunc: (instance: T) => R,
): {
  (instance: T): R;
  (instance: T[]): R[];
} {
  function abstractMapper(instance: T): R;
  function abstractMapper(instance: T[]): R[];
  function abstractMapper(instance: T | T[]): R | R[] {
    if (Array.isArray(instance)) {
      return instance.map(mapperFunc);
    }
    return mapperFunc(instance);
  }
  return abstractMapper;
}
